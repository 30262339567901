import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { useCallback, useContext } from 'react'
import { StyledTooltip } from 'src/pages/Calendar/index.styles'
import { InsurancePaymentMethod } from 'src/schema-types'
import { useStore } from 'src/stores/store'
import { ThemeContext } from 'styled-components'

type PaymentOptionsProps = {}

export const enumToReadableFormat = (
  str: InsurancePaymentMethod | undefined
) => {
  return (
    str
      ?.match(/^[a-z]+|[A-Z][a-z]*/g)
      ?.map((x) => x[0].toUpperCase() + x.substr(1).toLowerCase())
      .join(' ') ?? ''
  )
}

export const PaymentOptions: React.FC<PaymentOptionsProps> = observer(() => {
  const { billingStore } = useStore()
  const theme = useContext(ThemeContext)

  const paymentOptionsArray = [
    InsurancePaymentMethod.SelfPay,
    InsurancePaymentMethod.PrimaryInsurance,
    InsurancePaymentMethod.SecondaryInsurance,
  ]

  const handlePaymentClick = (value: InsurancePaymentMethod) => {
    // If self pay is selected, set payment method to selfpay and clear the rest of the options
    if (
      value === InsurancePaymentMethod.SelfPay &&
      !billingStore.paymentOptions?.includes(value)
    ) {
      billingStore.savePaymentOption([value])
      return
    }
    // If another method is selected and selfpay is selected, set payment method to the selected value and unselect selfpay
    if (
      value !== InsurancePaymentMethod.SelfPay &&
      billingStore.paymentOptions?.includes(InsurancePaymentMethod.SelfPay)
    ) {
      billingStore.savePaymentOption([value])
      return
    }
    // If a method is already selected, remove it
    if (billingStore.paymentOptions?.includes(value)) {
      let tempValue = []
      const optionIndex = billingStore.paymentOptions.findIndex(
        (opt) => opt === value
      )
      tempValue = billingStore.paymentOptions
      tempValue.splice(optionIndex, 1)
      billingStore.savePaymentOption(tempValue)
      return
    }
    // If a Method is not in the array and selected
    if (billingStore.paymentOptions) {
      billingStore.savePaymentOption([...billingStore.paymentOptions, value])
      return
    }
    // If no methods have been selected yet and this is the first selection
    if (!billingStore.paymentOptions) {
      billingStore.savePaymentOption([value])
      return
    }
  }

  const renderOptions = useCallback(
    (
      paymentOption: InsurancePaymentMethod | undefined,
      onClick: () => void,
      paymentOptionIdx: number
    ) => {
      // data-for is set based on which option to render the correct tooltip
      let disableIns = false
      if (
        paymentOption === InsurancePaymentMethod.PrimaryInsurance &&
        billingStore.disablePrimaryInsurance
      ) {
        disableIns = true
      }
      if (
        paymentOption === InsurancePaymentMethod.SecondaryInsurance &&
        billingStore.disableSecondaryInsurance
      ) {
        disableIns = true
      }

      return (
        <>
          <div
            onClick={onClick}
            key={paymentOptionIdx}
            data-tip
            data-for={paymentOption}
            className={clsx(
              'flex justify-start items-center mt-1 rounded-lg p-2 first:ml-6 font-medium w-full mr-6 last:mr-0',
              disableIns
                ? 'text-gray-400 italic cursor-not-allowed'
                : 'cursor-pointer active:bg-primary active:bg-opacity-20 hover:bg-primary hover:bg-opacity-10',
              paymentOption &&
                billingStore?.paymentOptions?.includes(paymentOption)
                ? 'bg-primary bg-opacity-10'
                : ''
            )}
          >
            <div className="w-auto relative">
              <div
                className={clsx(
                  'w-4 h-4 rounded border flex',
                  disableIns && 'bg-gray-100',
                  paymentOption &&
                    billingStore?.paymentOptions?.includes(paymentOption)
                    ? 'bg-primary border-primary'
                    : 'border-gray-500'
                )}
              />
              {paymentOption &&
                billingStore?.paymentOptions?.includes(paymentOption) && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <svg
                      width="10"
                      height="8"
                      viewBox="0 0 10 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.20692 0.793031C9.39439 0.980558 9.49971 1.23487 9.49971 1.50003C9.49971 1.76519 9.39439 2.0195 9.20692 2.20703L4.20692 7.20703C4.01939 7.3945 3.76508 7.49982 3.49992 7.49982C3.23475 7.49982 2.98045 7.3945 2.79292 7.20703L0.792919 5.20703C0.610761 5.01843 0.509966 4.76583 0.512245 4.50363C0.514523 4.24143 0.619692 3.99062 0.8051 3.80521C0.990508 3.6198 1.24132 3.51463 1.50352 3.51236C1.76571 3.51008 2.01832 3.61087 2.20692 3.79303L3.49992 5.08603L7.79292 0.793031C7.98045 0.60556 8.23475 0.500244 8.49992 0.500244C8.76508 0.500244 9.01939 0.60556 9.20692 0.793031Z"
                        fill="#ffffff"
                      />
                    </svg>
                  </div>
                )}
            </div>
            <span className="pl-3">{enumToReadableFormat(paymentOption)}</span>
          </div>
          {disableIns && (
            <StyledTooltip
              id={paymentOption}
              effect="float"
              backgroundColor={theme.colors.primary}
              place="right"
            >
              {billingStore.tooltipValue}
            </StyledTooltip>
          )}
        </>
      )
    },
    [billingStore, theme.colors.primary]
  )

  return (
    <div className="w-full my-8 flex flex-col">
      <label className="font-bold pl-6">Payment Method</label>
      <div className="flex justify-between pr-6">
        {paymentOptionsArray.map((value, valueIdx) =>
          renderOptions(value, () => handlePaymentClick(value), valueIdx)
        )}
      </div>
    </div>
  )
})
