import * as React from 'react'
import { BinaryButtons } from 'src/pages/ClinicalNotesv2/components/fields/BinaryButtons'
import { BodyChartInput } from 'src/pages/ClinicalNotesv2/components/fields/BodyChartInput'
import { Dropdown } from 'src/pages/ClinicalNotesv2/components/fields/Dropdown'
import { MultiMeasureContainer } from 'src/pages/ClinicalNotesv2/components/MultiMeasureContainer'
import { hasLeftOrRight, justOneAmend } from 'src/pages/ClinicalNotesv2/utils'
import {
  rangeOfMotionLowerExtremeties,
  strengthUpperExtremeties,
} from 'src/pages/ClinicalNotesv2/utils/bodyChartButtonsArray'
import { ClinicalNoteElementTypes } from 'src/schema-types'
import { useStore } from 'src/stores/store'
import { AmendmentIndicator } from '../../AmendmentIndicator'
import { RangeLabel } from './styles'

const StrengthElem: React.FC<{
  name: string
  enabledBodyParts: string[]
  field: any
}> = ({ name, enabledBodyParts, field }) => {
  const {
    clinicalNotesStore: { amendments },
  } = useStore()
  const options = React.useMemo(() => {
    const data = ['5', '4+', '4', '4-', '3+', '3', '3-', '2+', '2', '2-', '1']
    return data.map((elem) => ({ label: elem, value: elem }))
  }, [])

  const oneAmend = React.useMemo(() => {
    return justOneAmend(amendments.listAmendFields, name)
  }, [amendments.listAmendFields, name])

  return (
    <div className="flex flex-col justify-start align-middle w-max">
      <BodyChartInput
        name={`${name}.body`}
        enabledBodyParts={enabledBodyParts}
        defaultValue={field?.body}
      />

      <div className="flex flex-col mt-6">
        <div className="flex items-center mb-6">
          <RangeLabel className="font-bold">Measurement</RangeLabel>
          <Dropdown
            name={`${name}.type`}
            options={options}
            defaultValue={field.type}
          />
        </div>
        {hasLeftOrRight(field.body)}
        <BinaryButtons
          name={`${name}.side`}
          rangeOfMotionButtons={true}
          noTopMargin={true}
          isVisible={hasLeftOrRight(field.body)}
          options={[
            { value: 'left', label: 'Left' },
            { value: 'right', label: 'Right' },
          ]}
        />
        <div className="mt-6">
          {!!oneAmend && (
            <AmendmentIndicator date={Number(oneAmend.createdOn)} />
          )}
        </div>
      </div>
    </div>
  )
}

export const Strength: React.FC<{ name: string; title: string }> = ({
  name,
  title,
}) => {
  const enabledBodyParts = React.useMemo(() => {
    if (title.toLowerCase().includes('upper')) {
      return strengthUpperExtremeties
    } else if (title.toLowerCase().includes('lower')) {
      return rangeOfMotionLowerExtremeties
    } else {
      return []
    }
  }, [title])

  return (
    <MultiMeasureContainer<{ enabledBodyParts: string[] }>
      type={ClinicalNoteElementTypes.Strength}
      withNotes
      title={title}
      name={name}
      ChildComponent={StrengthElem}
      enabledBodyParts={enabledBodyParts}
      extraChildComponentProps={{ enabledBodyParts }}
    />
  )
}
