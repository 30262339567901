// Handles formatting to display an appointments time and date
const dayStrings: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const formatDateToString = (startDate?: Date, endDate?: Date) => {
  if (startDate && endDate) {
    return `
        ${dayStrings[startDate.getDay()]},
        ${startDate.toLocaleString('default', { month: 'short' })}
        ${startDate.getDate()}
        ${startDate.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })} -
        ${endDate.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })}
      `
  }
}
