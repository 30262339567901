import { gql } from 'apollo-boost'
import { GetPracticeStatisticsQuery } from 'src/operations-types'
import { apolloClient } from 'src/settings/apolloClient'

const QUERY_STATISTICS = gql`
  query getPracticeStatistics(
    $id: ID!
    $startDate: AWSDate
    $endDate: AWSDate
  ) {
    getPractice(id: $id) {
      id
      statistics {
        appointemntAssignedFormSubmissionsStatus(
          startDate: $startDate
          endDate: $endDate
        ) {
          ...statistic
        }
        appointemntStatus(startDate: $startDate, endDate: $endDate) {
          ...statistic
        }
        assignedFormSubmissionsStatus {
          ...statistic
        }
        assignedFormDownloadStatus {
          ...statistic
        }
        clinicalNoteStatus {
          ...statistic
        }
        claimStatus {
          ...statistic
        }
      }
    }
  }

  fragment statistic on Statistic {
    key
    value
  }
`

export const queryStatistics = async (
  practiceId: string | undefined,
  startDate?: string,
  endDate?: string
) => {
  if (!practiceId) {
    return undefined
  }

  const res = await apolloClient.query<GetPracticeStatisticsQuery>({
    query: QUERY_STATISTICS,
    fetchPolicy: 'network-only',
    variables: {
      id: practiceId,
      startDate,
      endDate,
    },
  })

  if (res.errors?.length) {
    throw new Error(res.errors[0].message)
  }

  return res.data.getPractice || undefined
}
