import _ from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import { writeLog } from 'src/gql/mutations/writeToLog'
import {
  ClinicalNoteAnswerFragment,
  ClinicalNoteStatus,
} from 'src/operations-types'
import { TCheckAnswer } from 'src/pages/ClinicalNotesv2/types'
import { ClinicalNoteServices } from 'src/services/clinicalNote'
import { sortByOrdinance } from 'src/utils/sorting'
import { ClinicalNotesStore } from '..'

export class ClinicalNoteAmendmentStore {
  clinicalNotesStore: ClinicalNotesStore
  services: ClinicalNoteServices

  isCreating = false
  errorCreating = ''

  isSubmitting = false
  errorSubmitting = ''

  constructor(
    clinicalNotesStore: ClinicalNotesStore,
    services: ClinicalNoteServices
  ) {
    makeAutoObservable(this, { clinicalNotesStore: false })
    this.clinicalNotesStore = clinicalNotesStore
    this.services = services
  }

  get isAmendmentInProgress() {
    const data = !!this.clinicalNotesStore.clinicalNote?.amendments?.some(
      (elem) => elem?.status === ClinicalNoteStatus.InProgress
    )
    return data
  }

  get currentAmendmentId() {
    return this.clinicalNotesStore.clinicalNote?.amendments?.find(
      (elem) => elem?.status === ClinicalNoteStatus.InProgress
    )?.id
  }

  get currentAmendment() {
    return this.clinicalNotesStore.clinicalNote?.amendments?.find(
      (elem) => elem?.id === this.currentAmendmentId
    )
  }

  get listAmendFields() {
    let allAmendAnswers: TCheckAnswer = {}
    let originalAnswers: TCheckAnswer = {}

    this.clinicalNotesStore.clinicalNote?.answers?.forEach((answer) => {
      if (answer) {
        originalAnswers = {
          ...originalAnswers,
          ...this.getAnswers(answer, '', false),
        }
      }
    })

    const data = this.clinicalNotesStore.clinicalNote?.amendments
      ?.slice()
      .sort((a, b) => a?.createdOn - b?.createdOn)
      .reduce((prev, amend) => {
        let newPrev = {
          ...prev,
        }
        amend?.answers?.forEach((amendAnswer) => {
          if (amendAnswer) {
            newPrev = {
              ...newPrev,
              ...this.getAnswers(amendAnswer, amend.createdOn, true, prev),
            }
          }
        })
        return newPrev
      }, originalAnswers)

    _.toPairs(data)
      .filter(([_, value]) => value.isAmend)
      .forEach(([key, value]) => {
        allAmendAnswers[key] = value
      })

    return allAmendAnswers
  }

  private iterateAnswer = (elem: any, prevName: string) => {
    let data: { name: string; value: string }[] = []
    if (_.isPlainObject(elem)) {
      const arrayValues = _.toPairs(elem)
      arrayValues.forEach(([key, value]) => {
        if (_.isString(value) || _.isArray(value)) {
          let answerName = `${prevName}.${key}`
          data.push({
            name: answerName,
            value: value as string,
          })
        } else {
          data = [...data, ...this.iterateAnswer(value, `${prevName}.${key}`)]
        }
      })
    }
    return data
  }

  private shouldUpdateAnswer = (
    prevValue: string | undefined,
    value: string
  ) => {
    return prevValue !== value
  }

  private getAnswers = (
    answer: ClinicalNoteAnswerFragment,
    createdOn: string,
    isAmend: boolean,
    prev?: TCheckAnswer
  ) => {
    const someAnswers: TCheckAnswer = {}
    if (answer?.field && answer.answer.length) {
      const currentAnswer = JSON.parse(answer.answer)
      const fieldId = answer.field.id
      if (_.isString(currentAnswer) || _.isArray(currentAnswer)) {
        if (
          this.shouldUpdateAnswer(
            prev ? prev[fieldId]?.value : undefined,
            currentAnswer as string
          )
        ) {
          someAnswers[fieldId] = {
            value: currentAnswer as string,
            createdOn,
            isAmend,
          }
        }
      } else if (_.isPlainObject(currentAnswer)) {
        if (
          _.has(currentAnswer, 'measures') &&
          _.isArray(currentAnswer.measures)
        ) {
          ;(currentAnswer.measures as []).forEach((elem, index) => {
            const data = this.iterateAnswer(
              elem,
              `${fieldId}.measures.${index}`
            )
            data.forEach((dataElem) => {
              if (
                this.shouldUpdateAnswer(
                  prev ? prev[dataElem.name]?.value : undefined,
                  dataElem.value
                )
              ) {
                someAnswers[dataElem.name] = {
                  createdOn,
                  value: dataElem.value,
                  isAmend,
                }
              }
            })
          })
        } else {
          const data = this.iterateAnswer(currentAnswer, `${fieldId}`)
          data.forEach((dataElem) => {
            if (
              this.shouldUpdateAnswer(
                prev ? prev[dataElem.name]?.value : undefined,
                dataElem.value
              )
            ) {
              someAnswers[dataElem.name] = {
                createdOn,
                value: dataElem.value,
                isAmend,
              }
            }
          })
        }
      }
    }
    return someAnswers
  }

  createAmendment = async () => {
    try {
      runInAction(() => {
        this.isCreating = true
      })
      const amendment = await this.services.createAmendment({
        clinicalNoteModularId: this.clinicalNotesStore.clinicalNote?.id || '',
      })
      runInAction(() => {
        if (amendment && this.clinicalNotesStore.clinicalNote) {
          this.clinicalNotesStore.clinicalNote = {
            ...this.clinicalNotesStore.clinicalNote,
            amendments: [
              ...(this.clinicalNotesStore.clinicalNote?.amendments || []),
              amendment,
            ],
          }
          this.clinicalNotesStore.currentPageId =
            this.clinicalNotesStore.clinicalNote?.template?.pages
              .slice()
              .sort(sortByOrdinance)[0].id || ''
        }
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.errorCreating = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.isCreating = false
      })
    }
  }

  submitAmendment = async () => {
    try {
      runInAction(() => {
        this.isSubmitting = true
      })
      const amendmentId = await this.services.submitAmendment(
        this.currentAmendmentId || ''
      )
      runInAction(() => {
        if (amendmentId && this.clinicalNotesStore.clinicalNote) {
          this.clinicalNotesStore.clinicalNote = {
            ...this.clinicalNotesStore.clinicalNote,
            amendments: this.clinicalNotesStore.clinicalNote?.amendments?.map(
              (amendment) => {
                if (amendment?.id === amendmentId) {
                  return {
                    ...amendment,
                    status: ClinicalNoteStatus.Final,
                  }
                }
                return amendment
              }
            ),
          }
          this.clinicalNotesStore.currentPageId = 'REVIEW'
        }
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.errorSubmitting = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.isSubmitting = false
      })
    }
  }

  saveAmendmentAnswer = async (fieldId: string, answer: string) => {
    try {
      runInAction(() => {
        this.clinicalNotesStore.savingAnswer = true
      })

      const answerResponse = await this.services.saveAmendmentAnswer({
        clinicalNoteFieldId: fieldId,
        clinicalNoteModularAmendmentId: this.currentAmendmentId || '',
        answer,
      })

      const hasAnswer = this.currentAmendment?.answers?.some(
        (elem) => elem?.field.id === fieldId
      )

      let updatedAnswers = this.currentAmendment?.answers

      if (hasAnswer) {
        updatedAnswers = this.currentAmendment?.answers?.map((elem) => {
          if (answerResponse && elem?.id === answerResponse.id) {
            return answerResponse
          }
          return elem
        })
      } else {
        if (answerResponse) {
          updatedAnswers = [
            ...(this.currentAmendment?.answers || []),
            answerResponse,
          ]
        }
      }

      runInAction(() => {
        if (this.clinicalNotesStore.clinicalNote) {
          this.clinicalNotesStore.clinicalNote = {
            ...this.clinicalNotesStore.clinicalNote,
            amendments: this.clinicalNotesStore.clinicalNote?.amendments?.map(
              (elem) => {
                if (elem && elem?.id === this.currentAmendmentId) {
                  return {
                    ...elem,
                    answers: updatedAnswers,
                  }
                }
                return elem
              }
            ),
          }
        }
      })
    } catch (err) {
      runInAction(() => {
        writeLog((err as Error).message)
        this.clinicalNotesStore.errorSavingAnswer = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.clinicalNotesStore.savingAnswer = false
      })
    }
  }
  cleanErrors = () => {
    runInAction(() => {
      this.errorCreating = ''
      this.errorSubmitting = ''
    })
  }
}
