import { Flex, Typography } from '@ps-ui/components'
import React from 'react'
import { CptTextContainer } from 'src/components/molecules/BillingCodes/styles'
import { SelectedCodeContainer, StyledCard } from 'src/pages/CreateClaim/styles'
import { v4 as uuid } from 'uuid'
import { ChargeCodes } from '../../../pages/CreateClaim'

type FinalizedBillingCodesProps = {
  chargesList?: ChargeCodes[]
}

export const FinalizedBillingCodes: React.FC<FinalizedBillingCodesProps> = ({
  chargesList,
}) => {
  return (
    <Flex width="100%" margin="25px 0">
      <StyledCard>
        <Typography as="h3" fontWeight="700" padding="0 0 15px 0">
          CPT Codes
        </Typography>
        <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
          {chargesList &&
            chargesList?.map((data, dataIdx) => {
              return (
                <Flex width="49%" key={dataIdx}>
                  <SelectedCodeContainer key={uuid()}>
                    <Flex>
                      <Flex flexDirection="column" width="100%">
                        <CptTextContainer>
                          <Flex>
                            <Typography as="h4" fontWeight="700" padding="5px">
                              {data?.procedureCode?.map(
                                (d) => `${d.code} - ${d.description}`
                              )}
                            </Typography>
                          </Flex>
                        </CptTextContainer>
                        <Typography as="h4" fontWeight="700" padding="5px">
                          ICD-10 Code(s)
                        </Typography>
                        {data?.diagnosisCodes?.map((diagData, diagDataIdx) => {
                          return (
                            <Typography
                              as="p"
                              fontWeight="500"
                              padding="5px 15px 5px 20px"
                              key={diagDataIdx}
                            >
                              {diagData.code} - {diagData.description}
                            </Typography>
                          )
                        })}
                        <Flex flexDirection="column">
                          <Typography as="h4" fontWeight="700" padding="5px">
                            Units:
                          </Typography>
                          <Typography as="h4" fontWeight="700" padding="5px">
                            {data.units}
                          </Typography>
                          <Typography as="h4" fontWeight="700" padding="5px">
                            Modifier(s):
                          </Typography>
                          <Typography as="h4" fontWeight="700" padding="5px">
                            {data.modifiers && data?.modifiers?.length >= 1
                              ? data?.modifiers?.join(', ')
                              : '--'}
                          </Typography>
                        </Flex>
                      </Flex>
                    </Flex>
                  </SelectedCodeContainer>
                </Flex>
              )
            })}
        </Flex>
      </StyledCard>
    </Flex>
  )
}
