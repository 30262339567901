export const homeSetupArray = [
  {
    label: 'Entrance',
    value: 'entrance',
  },
  {
    label: 'Equipment',
    value: 'equipment',
  },
  {
    label: 'Bathroom',
    value: 'bathroom',
  },
  {
    label: 'Levels',
    value: 'levels',
  },
]

export const entranceArray = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Left',
    value: 'left',
  },
  {
    label: 'Right',
    value: 'right',
  },
  {
    label: 'L & R',
    value: 'L & R',
  },
]

export const equipmentArray = [
  {
    label: 'Cane',
    value: 'cane',
  },
  {
    label: 'Quad Cane',
    value: 'quad_cane',
  },
  {
    label: 'Walker',
    value: 'walker',
  },
  {
    label: 'FWW',
    value: 'fww',
  },
  {
    label: '4WW',
    value: '4ww',
  },
  {
    label: 'Hemi Walker',
    value: 'hemi_walker',
  },
]

export const bathroomArray = [
  {
    label: 'Tub',
    value: 'tub',
  },
  {
    label: 'Tub/Shower Combo',
    value: 'tub_shower_combo',
  },
  {
    label: 'Walk-In Shower',
    value: 'walk_in_shower',
  },
  {
    label: 'Raised Toilet',
    value: 'raised_toilet',
  },
  {
    label: 'Toilet Grab Bar',
    value: 'toilet_grab_bar',
  },
  {
    label: 'Shower/Tub Grab Bar',
    value: 'shower_tub_grab_bar',
  },
  {
    label: 'BSC',
    value: 'bsc',
  },
  {
    label: 'Toilet Handrails',
    value: 'toilet_handrails',
  },
]

export const levelsArray = [
  {
    label: 'L Handrail',
    value: 'l_handrail',
  },
  {
    label: 'R Handrail',
    value: 'r_handrail',
  },
  {
    label: 'L & R Handrail',
    value: 'l_and_r_handrail',
  },
]
