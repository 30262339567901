import { gql } from 'apollo-boost'
import {
  AssignedForm,
  PaginationInput,
  PatientProfile,
  Query,
} from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export type QueryAssignedFormsByPatientNameResponse = {
  searchPatientProfiles: Query['searchPatientProfiles']
}

export type QueryAssignedFormsByPatientNameRequest = {
  input: string
  pagination: PaginationInput
}

export const QUERY_ASSIGNED_FORMS_BY_PATIENT_NAME = gql`
  query searchPatient($input: String!, $pagination: PaginationInput) {
    searchPatientProfiles(input: $input, pagination: $pagination) {
      entities {
        ... on PatientProfile {
          assignedForms {
            id
            createdOn
            isSubmitted
            updatedOn
            patientProfile {
              id
              firstName
              lastName
            }
            form {
              name
            }
          }
        }
      }
      pageInfo {
        hasMoreResults
      }
    }
  }
`

export const queryAssignedFormsByPatientName = async ({
  input,
  pagination,
}: QueryAssignedFormsByPatientNameRequest) => {
  const response =
    await apolloClient.query<QueryAssignedFormsByPatientNameResponse>({
      query: QUERY_ASSIGNED_FORMS_BY_PATIENT_NAME,
      fetchPolicy: 'no-cache',
      variables: {
        input,
        pagination,
      },
    })
  const patientsResopnse = response.data.searchPatientProfiles
    ? (response.data.searchPatientProfiles.entities as PatientProfile[])
    : []
  const assignedForms: AssignedForm[] = []
  patientsResopnse
    .flatMap((patient) => patient.assignedForms)
    .forEach((e) => !!e && assignedForms.push(e))
  return {
    assignedForms,
    hasMore: !!response.data.searchPatientProfiles?.pageInfo.hasMoreResults,
  }
}
