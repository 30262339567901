import { FormState } from 'react-use-form-state'

export function requiredField(value: string) {
  if (!value.trim()) {
    return 'This field is required'
  }
}

export function requiredFieldNoLabel(value: string) {
  if (!value.trim()) {
    return ' '
  }
}

export const validName = (displayName: string) => (value: string) => {
  if (value.length < 2) {
    return `${displayName} should be at least 2 characters long`
  }

  const invalidChar = value.match(/[^A-Za-z-'’,. ]+/)
  if (invalidChar) {
    if (invalidChar[0].length > 1) {
      return `${invalidChar[0]} are not valid inputs for ${displayName}`
    }
    return `${invalidChar[0]} is not a valid input for ${displayName}`
  }
}

export const validSSN = (value: string) => {
  const regex =
    /^(?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}/g
  const clear = value.replace(/-/g, '')
  if (!clear.match(regex)) {
    return 'Please enter a valid SNN'
  }
}

// Will check to ensure password is at least 6 characters long, and includes one uppercase character
export const validPassword = (val: string) => {
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/gm
  if (!val || !val.match(regex)) {
    return 'Please enter a valid password'
  }
}

export const validBirthDate = (value: string) => {
  const errMsg =
    'Please enter a valid Date of Birth which is a past date and in the range of last 120 years'
  const isFullDate = value.split('/').length === 3
  const year = value.split('/').pop()
  if (!value) return
  if (!isFullDate || !year) return errMsg
  if (year && (year.length < 4 || year.startsWith('0'))) return errMsg
  const currentDate = new Date()
  const birthDate = new Date(value)
  if (
    !(
      birthDate < currentDate &&
      currentDate.getFullYear() - birthDate.getFullYear() < 120
    )
  ) {
    return errMsg
  }
}

export const validZipCode = (value: string) => {
  const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/
  if (!value) return
  if (!regex.test(value)) {
    return 'Please enter a valid zip code'
  }
}

export const trimFormField = (fieldName: string, formState: FormState<any>) => {
  let value = formState.values[fieldName].trim()
  value = value.replace(/[ ]{2,}/g, ' ')

  formState.setField(fieldName, value)
}
