import { Icon } from '@ps-ui/components'
import React, { useState } from 'react'
import { ReactComponent as OptionsIcon } from 'src/images/options-icon.svg'
import { IconContainer, OptionsCardContainer, OptionsContainer } from './styles'

export type OptionProps<T> = {
  label: string
  RightIcon?: React.FunctionComponent<any>
  LeftIcon?: React.FunctionComponent<any>
  color?: string
  onClick: (...args: T[]) => void
}

export type OptionsCardProps<T = null> = {
  TopIcon?: React.FunctionComponent<any>
  options: OptionProps<T>[]
  optionsArgs?: T[]
}

export function OptionsCard<OptionsArgsType = null>({
  TopIcon,
  options,
  optionsArgs = [],
}: OptionsCardProps<OptionsArgsType>): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  let mouseTimeout: NodeJS.Timeout
  const handleMouseLeave = () => {
    mouseTimeout = setTimeout(() => {
      setIsOpen(false)
    }, 500)
  }

  const handleMouseEnter = () => {
    clearTimeout(mouseTimeout)
  }

  return (
    <OptionsCardContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <IconContainer onClick={() => setIsOpen(!isOpen)}>
        <Icon Component={TopIcon || OptionsIcon} size="xs" />
      </IconContainer>
      {isOpen && (
        <OptionsContainer>
          {options?.map((option, index) => (
            <li
              key={index}
              onClick={() => {
                setIsOpen(false)
                option.onClick(...optionsArgs)
              }}
              style={{ color: option.color }}
            >
              {option.RightIcon && <option.RightIcon />}
              <span>{option.label}</span>
              {option.LeftIcon && <option.LeftIcon />}
            </li>
          ))}
        </OptionsContainer>
      )}
    </OptionsCardContainer>
  )
}
