import { Switch } from '@material-ui/core'
import { Button, DropdownField } from '@ps-ui/components'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components/macro'

export const dropDownStyles = `&&& {
	div.Dropdown-root {
		width: 100%;
	}
	label[class*='Label'] {
		font-weight: 700;
	}
	div.Dropdown-control {
		border-radius: 10px;
		border: 1px solid rgba(218, 219, 222, 1);
		background-color: transparent;
	}
	div.is-open {
		div.Dropdown-control {
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
		}
	}
	div.Dropdown-menu {
		border-radius: 0;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	div.Dropdown-placeholder {
		font-size: 14px;
		font-weight: 500;
	}
}`

export const DoctorDropdown = styled(DropdownField)`
  &&& {
    div.Dropdown-control {
      border: none !important;
      &:hover {
        border-radius: none;
      }
    }
    div.Dropdown-placeholder {
      &::before {
        content: '';
      }
      background-color: transparent;
      color: #000000;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
    }
  }
`

export const StyledPopover = styled.div`
  background: #ffffff;
  border: 1px solid #dadbde;
  box-shadow: 0px 4px 10px -6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  padding: 10px;
  z-index: 100;

  max-width: 260px;
  max-height: 180px;
  width: 250px;
  height: 180px;
  color: ${(props) => props.theme.colors.text};

  p {
    font-size: 14px;
  }

  button {
    background: none;
    border: none;
    height: 28px;
    padding: 6px;
    font-weight: 700;
  }
  .description {
    overflow: hidden;
    align-items: flex-start;
  }
  .details-button {
    width: 100%;
    box-shadow: 0px -5px 14px -9px rgb(0 0 0 / 25%);
    color: ${(props) => props.theme.colors.secondary};
    margin-top: auto;
  }
`

export const StyledSideBar = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  background-color: #ffffff;
  div[class*='MultiValueWrapper'] {
    border: 1px solid #cccccc;
    background-color: #f7f7f7;
    i {
      cursor: pointer;
    }
  }
`

type AppTypeColorProps = {
  color: string
}

export const AppTypeColor = styled.div<AppTypeColorProps>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  background: ${(props) => props.color};
`

export const StyledTooltip = styled(ReactTooltip)`
  &&& {
    font-weight: 700;
    border-radius: 8px;
    max-width: 350px;
  }
`
export const StyledSwitch = styled(Switch)`
  .MuiSwitch-switchBase,
  .MuiSwitch-colorSecondary.Mui-checked {
    color: ${(props) => props.theme.colors.primary};
  }
  .MuiSwitch-track {
    background-color: ${(props) => props.theme.colors.primary};
  }
`

export const ModalContents = styled.div`
  width: 335px;
  background: #fff;
  padding: 20px 30px;
  position: relative;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  min-height: 170px;
  ${dropDownStyles}

  .new-pt-name-inputs {
    input {
      width: 46%;
    }
  }
`

export const PatientRecommendations = styled.div`
  width: 100%;
  height: 150px;
  overflow-y: scroll;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 10px;
  border: 2px #eaeaec solid;
`

export const PatientInfo = styled.div`
  font-weight: 15px;
  cursor: pointer;
  margin-bottom: 10px;
  .patient-name {
    width: 55%;
  }
  .patient-dob {
    font-size: 14px;
  }
`

export const ReschedulingContainer = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  &&& {
    button {
      color: #fff;
      border-radius: 10px;
    }
  }
  padding: 100px 20px;
  text-align: center;
`

export const BorderButton = styled(Button)`
  &&& {
    border: 2px solid ${(props) => props.theme.colorPrimary};
    border-radius: 10px;
    label {
      font-weight: 700;
    }
  }
`
