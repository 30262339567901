import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import { PannelWrapper, Title } from './styles'

interface Props {
  title?: string
  borderRadius?: string
  rightBlock?: React.ReactNode
  height?: string
  width?: string
  padding?: string
  margin?: string
  className?: string
}

export const Pannel: FunctionComponent<Props> = ({
  children,
  title = '',
  rightBlock,
  borderRadius,
  height,
  width,
  padding,
  margin,
  className,
}) => {
  return (
    <PannelWrapper
      borderRadius={borderRadius}
      height={height}
      width={width}
      padding={padding}
      margin={margin}
      className="flex flex-col rounded shadow-sm bg-white overflow-hidden"
    >
      {title && (
        <div
          className={clsx(
            'flex items-center justify-between py-4 px-5 lg:px-6 w-full bg-gray-50',
            className
          )}
        >
          <Title>{title}</Title>
          {rightBlock && rightBlock}
        </div>
      )}
      {children}
    </PannelWrapper>
  )
}
