import { gql } from 'apollo-boost'
import { PatientProfile } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface QueryPatientByIdResponse {
  getPatientProfile: PatientProfile
}

export const CONVERSATION_BY_PATIENT_ID = gql`
  query conversationByPatientId($id: ID!) {
    getPatientProfile(id: $id) {
      id
      smsConversation {
        id
        updatedOn
        status
        patientProfile {
          id
          firstName
          lastName
          phoneNumber
          email
          attachments {
            downloadUrl
          }
        }
        messages {
          to
          from
          body
          sendDate
          direction
          status
        }
      }
    }
  }
`

export const queryConversationByPatientId = async (id: string) => {
  const response = await apolloClient.query<QueryPatientByIdResponse>({
    query: CONVERSATION_BY_PATIENT_ID,
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  })

  return response.data.getPatientProfile.smsConversation
}
