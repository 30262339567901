import React from 'react'
import { TTerm } from 'src/pages/ClinicalNotesv2/types'

type TermAnswerProps = {
  termAnswer: TTerm
}

export const TermAnswer: React.FC<TermAnswerProps> = ({ termAnswer }) => {
  return (
    <p className="flex flex-col">
      {termAnswer.length ? (
        <>
          <span className="font-semibold py-1">Length: </span>
          {termAnswer.length}
        </>
      ) : null}
      {termAnswer.duration ? (
        <>
          <span className="font-semibold py-1">Duration: </span>
          {termAnswer.duration}
        </>
      ) : null}
      {termAnswer.durationUnit ? (
        <>
          <span className="font-semibold py-1">Unit: </span>
          {termAnswer.durationUnit}
        </>
      ) : null}
    </p>
  )
}
