import { gql } from 'apollo-boost'
import { QueryClinicalNoteV2Query } from 'src/operations-types'
import { apolloClient } from 'src/settings/apolloClient'
import { clinicalNoteModularFragment } from '../fragments/clinicalNoteModular'

export const GET_CLINICAL_NOTE = gql`
  query queryClinicalNoteV2($id: ID!) {
    getClinicalNoteModular(id: $id) {
      ...clinicalNoteModular
    }
  }
  ${clinicalNoteModularFragment}
`

export const queryClinicalNoteById = async (id: string) => {
  const response = await apolloClient.query<QueryClinicalNoteV2Query>({
    query: GET_CLINICAL_NOTE,
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  })

  return response.data.getClinicalNoteModular
}
