import * as React from 'react'
import { OptionList } from 'src/pages/ClinicalNotesv2/components/fields/interventions/OptionList'
import { InterventionContainer } from 'src/pages/ClinicalNotesv2/components/InterventionContainer'
import { Row } from 'src/pages/ClinicalNotesv2/components/layoutForm/Row'
import * as options from 'src/pages/ClinicalNotesv2/types/intervention/painManagement'
import { ClinicalNoteElementTypes } from 'src/schema-types'
// import { InputContainer } from '../../../InputContainer'
// import { Dropdown } from '../../Dropdown'
// import { ClinicalNotesInput } from '../../Input'

// type RenderPainManagementTypeOptionsProps = {
//   type: string,
//   coreName: string,
// }

// const RenderPainManagementTypeOptions: React.FC<RenderPainManagementTypeOptionsProps> = ({
//   type,
//   coreName,
// }) => {
//   switch (type) {
//     case 'NMES/TENS':
//       return (
//         <>
//           <Row columns={1}>
//             <OptionList
//               coreName={coreName}
//               name="waveForm"
//               options={options.waveForms}
//             />
//           </Row>
//           <Row columns={4}>
//             <InputContainer name={`${coreName}.frequency`} label="Frequency">
//               <ClinicalNotesInput
//                 name={`${coreName}.frequency`}
//                 placeholder="0"
//                 type="number"
//                 isIntervention
//               />
//             </InputContainer>
//             <InputContainer
//               name={`${coreName}.intensity`}
//               label="Intensity/Amplityde"
//             >
//               <ClinicalNotesInput
//                 name={`${coreName}.intensity`}
//                 placeholder="0"
//                 type="number"
//                 isIntervention
//               />
//             </InputContainer>
//             <InputContainer
//               name={`${coreName}.pulse`}
//               label="Pulse Widht/Duration"
//             >
//               <ClinicalNotesInput
//                 name={`${coreName}.pulse`}
//                 placeholder="0"
//                 type="number"
//                 isIntervention
//               />
//             </InputContainer>
//             <Dropdown
//               name={`${coreName}.numberOfElectrons`}
//               options={options.numberOfElectrons}
//             />
//           </Row>
//         </>
//       )
//     case 'Paraffin':
//       return (
//         <>
//           <Row columns={2}>
//             <InputContainer
//               name={`${coreName}.numberOfDips`}
//               label="Number of dips"
//             >
//               <ClinicalNotesInput
//                 name={`${coreName}.numberOfDips`}
//                 placeholder="0"
//                 type="number"
//                 isIntervention
//               />
//             </InputContainer>
//           </Row>
//         </>
//       )
//     case 'Whirlpool/Hydrotherapy':
//       return (
//         <>
//           <Row columns={2}>
//             <InputContainer
//               name={`${coreName}.waterTemperature`}
//               label="Water Temperature"
//             >
//               <ClinicalNotesInput
//                 name={`${coreName}.waterTemperature`}
//                 placeholder="0"
//                 type="number"
//                 isIntervention
//               />
//             </InputContainer>
//           </Row>
//         </>
//       )
//     case 'Taping':
//       return (
//         <>
//           <Row columns={1}>
//             <Dropdown
//               label="Type"
//               name={`${coreName}.tapingType`}
//               options={options.tapingType}
//             />
//           </Row>
//           <Row columns={1}>
//             <OptionList
//               coreName={coreName}
//               label="Purpose"
//               name="tapingPurpose"
//               options={options.tapingPurpose}
//             />
//           </Row>
//         </>
//       )
//     case 'Tranction':
//       return (
//         <>
//           <Row columns={2}>
//             <Dropdown
//               label="Type"
//               name={`${coreName}.tractionType`}
//               options={options.tractionType}
//             />
//             <InputContainer name={`${coreName}.tractionDosage`} label="Dosage">
//               <ClinicalNotesInput
//                 name={`${coreName}.tractionDosage`}
//                 placeholder="0"
//                 type="number"
//                 isIntervention
//               />
//             </InputContainer>
//           </Row>
//         </>
//       )
//     case 'Fluidotherapy':
//       return (
//         <Row columns={2}>
//           <InputContainer
//             name={`${coreName}.fluidotherapyTemperature`}
//             label="Temperature"
//           >
//             <ClinicalNotesInput
//               name={`${coreName}.fluidotherapyTemperature`}
//               placeholder="0"
//               type="number"
//               isIntervention
//             />
//           </InputContainer>
//         </Row>
//       )
//     case 'Ultrasound':
//       return (
//         <Row columns={3}>
//           <InputContainer
//             name={`${coreName}.fluidotherapyTemperature`}
//             label="Temperature"
//           >
//             <ClinicalNotesInput
//               name={`${coreName}.fluidotherapyTemperature`}
//               placeholder="0"
//               type="number"
//               isIntervention
//             />
//           </InputContainer>
//           <InputContainer
//             name={`${coreName}.fluidotherapyTemperature`}
//             label="Temperature"
//           >
//             <ClinicalNotesInput
//               name={`${coreName}.fluidotherapyTemperature`}
//               placeholder="0"
//               type="number"
//               isIntervention
//             />
//           </InputContainer>
//         </Row>
//       )
//     default:
//       return <div></div>
//   }
// }

export const PainManagementElem: React.FC<{ name: string }> = ({ name }) => {
  // const { control } = useFormContext()

  // const modalityTypeValue = useWatch({
  //   name: `${name}.modalities.type`,
  //   control,
  // })

  return (
    <div className="flex flex-col">
      <Row columns={1}>
        <OptionList
          coreName={name}
          name="nonPharmacologicalMethods"
          label="Non-Pharmacological Methods"
          isMulti
          options={options.nonPharmacologicalMethods}
        />
      </Row>
      <Row columns={1}>
        <OptionList
          coreName={name}
          name="pharmacologicalMethods"
          label="Pharmacological Methods"
          isMulti
          options={options.pharmacologicalMethods}
        />
      </Row>
      <Row columns={1}>
        <OptionList
          coreName={name}
          name="patientUnderstanding"
          label="Patient Understanding"
          options={options.patientUnderstanding}
        />
      </Row>
      {/* <Row columns={2}>
        <Dropdown
          name={`${name}.modalities.location`}
          options={options.location}
          label="Modalities"
          isMulti
        />
        <Dropdown
          name={`${name}.modalities.side`}
          options={options.side}
          label="Side"
          isMulti
        />
      </Row>
      <Row columns={2}>
        <Dropdown
          name={`${name}.modalities.type`}
          options={options.type}
          label="Type"
        />
      </Row> */}
    </div>
  )
}

export const PainManagement: React.FC<{ name: string }> = ({ name }) => {
  return (
    <InterventionContainer
      name={name}
      type={ClinicalNoteElementTypes.PainEducation}
      render={({ name }) => <PainManagementElem name={name} />}
    />
  )
}
