import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { InsurancePaymentMethod } from 'src/schema-types'
import { BillingStore } from 'src/stores/domain/billing'
import { ClinicalNotesStore } from 'src/stores/domain/clinicalNotes'
import { enumToReadableFormat } from '../PaymentOptions'

type BillingHeaderProps = {
  clinicalNotesStore: ClinicalNotesStore
  toggleModal: () => void
  clinicalNoteComponent?: boolean
  billingStore: BillingStore
}

export const BillingHeader: React.FC<BillingHeaderProps> = observer(
  ({ clinicalNotesStore, clinicalNoteComponent, billingStore }) => {
    useEffect(() => {
      if (
        clinicalNoteComponent &&
        clinicalNotesStore.diagnosisId &&
        !billingStore.currentClaimData?.charges
      ) {
        billingStore.saveDiagnosis(clinicalNotesStore.diagnosisId)
      }
    }, [billingStore, clinicalNoteComponent, clinicalNotesStore.diagnosisId])

    return (
      <div
        className={clsx(
          'flex min-w-max items-center border-b-2 min-h-full pl-4 mb-6'
        )}
      >
        <div
          className={clsx(
            'flex w-full py-9 justify-between items-center font-bold text-md'
          )}
        >
          <h2 className="">Billing Information</h2>
          <div className="flex items-center">
            {!billingStore.currentClaimData?.claimId?.includes(
              '-- pending --'
            ) && billingStore.selectedPaymentOption ? (
              <div className="flex items-center mr-6">
                <h4>
                  {billingStore.selectedPaymentOption.length === 1
                    ? 'Payment Option:'
                    : 'Payment Options:'}
                </h4>
                {billingStore.selectedPaymentOption.map((opt) => (
                  <div className="flex flex-col ml-2">
                    <p className="font-medium">{enumToReadableFormat(opt)}</p>
                  </div>
                ))}
              </div>
            ) : (
              // This is included for legacy claims that only were able to use insurance and didn't have a selected payment method attached
              <h4 className="mr-6">
                Payment Method:{' '}
                <span className="font-medium">Primary Insurance</span>
              </h4>
            )}
            {billingStore.paymentOptions &&
              billingStore.paymentOptions?.length > 0 && (
                <h4 className="pr-6 font-normal ">
                  <span className="font-bold mr-1">
                    {billingStore.paymentOptions.includes(
                      InsurancePaymentMethod.SelfPay
                    )
                      ? 'Invoice Id: '
                      : 'Claim id: '}
                  </span>
                  <span
                    className={clsx(
                      billingStore.currentClaimData?.claimId?.includes(
                        '-- pending --'
                      )
                        ? 'bg-orange-200 py-1 px-3 rounded-lg text-orange-700 font-semibold'
                        : 'bg-green-200 py-1 px-3 rounded-lg text-green-700 font-semibold'
                    )}
                  >
                    {billingStore.currentClaimData?.claimId?.includes(
                      '-- pending --'
                    )
                      ? ' Pending'
                      : ` ${billingStore.currentClaimData?.claimId ?? ''}`}
                  </span>
                </h4>
              )}
            {/* Included for legacy claims before payment options were attached to the claim */}
            {billingStore.currentClaimData &&
              !billingStore.currentClaimData?.claimId?.includes(
                '-- pending --'
              ) &&
              !billingStore.paymentOptions && (
                <h4 className="pr-6 font-normal">
                  <span className="font-bold mr-1">Claim id:</span>
                  <span
                    className={clsx(
                      'bg-green-200 py-1 px-3 rounded-lg text-green-700 font-semibold'
                    )}
                  >
                    {billingStore.currentClaimData?.claimId}
                  </span>
                </h4>
              )}
          </div>
        </div>
      </div>
    )
  }
)
