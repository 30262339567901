import { gql } from 'apollo-boost'
import { ResetPasswordResponse } from 'src/schema-types'

export type PasswordResetResponse = {
  resetPassword: ResetPasswordResponse
}

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
      details
    }
  }
`
