import { observer } from 'mobx-react'
import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { justOneAmend } from 'src/pages/ClinicalNotesv2/utils'
import { useStore } from 'src/stores/store'
import { AmendmentIndicator } from '../AmendmentIndicator'
import { TabSelectionElem } from '../fields/TabSelectionElem'

interface TabModuleContainerProps {
  optionList: string[]
  name: string
  children(props: { currentTab: string }): JSX.Element
}

export const TabModuleContainer: React.FC<TabModuleContainerProps> = observer(
  ({ optionList, name, children }) => {
    const { control } = useFormContext()
    const {
      clinicalNotesStore: { amendments },
    } = useStore()
    // const amend = amendments.listAmendFields[name]
    const options = React.useMemo(() => {
      return optionList.map((option) => ({ id: option, name: option }))
    }, [optionList])

    const [currentTab, setCurrentTab] = React.useState(options[0].id)

    const values = useWatch({
      name,
      control,
    })

    const firstAmend = React.useMemo(() => {
      return justOneAmend(
        amendments.listAmendFields,
        `${name}.properties.${currentTab}`
      )
    }, [amendments.listAmendFields, name, currentTab])

    const ids = React.useMemo(() => {
      const data = optionList.filter((elem) => {
        const value = values?.properties?.[elem]
        if (value) {
          const hasData = Object.values(value).some((elem) => {
            if (Array.isArray(elem)) {
              return !!elem.length
            }
            return !!elem
          })
          return hasData
        }
        return false
      })
      return data
    }, [values, optionList])

    return (
      <div>
        <TabSelectionElem
          tabs={options}
          value={currentTab}
          onChange={(value: string) => setCurrentTab(value)}
          markedIds={ids}
        />
        {!!firstAmend && (
          <div className="my-8">
            <AmendmentIndicator date={Number(firstAmend.createdOn)} />
          </div>
        )}
        {children({ currentTab })}
      </div>
    )
  }
)
