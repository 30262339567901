import { Option } from '@ps-ui/components'
import * as React from 'react'
import { Dropdown } from 'src/pages/ClinicalNotesv2/components/fields/Dropdown'
import { ClinicalNotesInput } from 'src/pages/ClinicalNotesv2/components/fields/Input'
import { InputContainer } from 'src/pages/ClinicalNotesv2/components/InputContainer'

export const Temperature: React.FC<{ name: string; label: string }> = ({
  name,
  label,
}) => {
  const locationOptions: Option[] = React.useMemo(() => {
    return [
      {
        label: 'Axillary',
        value: 'axillary',
      },
      {
        label: 'Oral',
        value: 'oral',
      },
      {
        label: 'Temporal',
        value: 'temporal',
      },
    ]
  }, [])
  return (
    <InputContainer name={`${name}.value`} label={label}>
      <div className="flex">
        <div className="flex mr-6 items-center">
          <ClinicalNotesInput name={`${name}.value`} type="number" />
          <span className="ml-2">F</span>
        </div>
        <div className="flex items-center w-72">
          <span className="mr-2">Location</span>
          <Dropdown name={`${name}.location`} options={locationOptions} />
        </div>
      </div>
    </InputContainer>
  )
}
