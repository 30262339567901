import { Option } from '@ps-ui/components'
import * as TIP from '@ps-ui/doctor/src/pages/Billing/constants/tooltipConstants'
import { makeAutoObservable, runInAction } from 'mobx'
import { ChargesWithId } from 'src/pages/Billing/components/BillingCodes'
import * as T from 'src/schema-types'
import { BillingServices } from 'src/services/billing'
import { RootStore } from 'src/stores/store'
import { formatDate } from 'src/utils/formatDate'

export class BillingStore {
  fetching = false
  error? = ''
  loading = false
  saving = false
  loadMessage = 'Fetching Claim Data...'

  diagnosisId? = ''
  procedureIds? = [] as (string | undefined)[]
  paymentOptions?: T.InsurancePaymentMethod[]
  referringDoctorNPI? = ''
  currentCharges?: ChargesWithId[]
  currentClaimData?: T.InsuranceClaim
  submitClaimResults? = {} as any
  appointmentInfo?: T.Appointment
  allowChargesEdit = true
  serviceLocation?: {
    label: string | undefined
    value: string | undefined
  }
  parsedData?: T.InsuranceClaimCreateInput
  patientsReferringDoctor? = {} as Option
  clinicalNoteStatus?: string | null | undefined
  workersCompNumber?: string | undefined
  authorizationNumber?: string | undefined
  disableSubmit = false
  disablePrimaryInsurance = false
  disableSecondaryInsurance = false
  patientGender?: any
  primaryInsuranceObject?: T.ElementTypes.Insurance | null
  secondaryInsuranceObject?: T.ElementTypes.Insurance | null
  patientAddress?: [] | null
  tooltipValue?: string
  submitTooltipValue?: string

  rootStore: RootStore
  services: BillingServices

  constructor(rootStore: RootStore, services: BillingServices) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
    this.services = services
    this.validateInsuranceAndSubmit()
  }

  // ICD codes (diagnosis code)
  saveDiagnosis = (diagnosisId: string) => {
    // TODO - Connect to the API after the backend changes
    runInAction(() => {
      this.diagnosisId = diagnosisId
      this.saveData()
      this.validateInsuranceAndSubmit()
    })
  }

  get diagnosis() {
    return this.rootStore.practiceStore.billingCodes?.find(
      (billingCode) => billingCode.id === this.diagnosisId
    )?.description
  }

  get diagnosisCode() {
    return this.rootStore.practiceStore.billingCodes?.find(
      (billingCode) => billingCode.id === this.diagnosisId
    )?.code
  }

  get getCurrentCharges() {
    return this.currentCharges
  }

  removeDiagnosisCode = () => {
    runInAction(() => {
      try {
        this.saving = true
        this.diagnosisId = ''
        this.createChargesArray()
      } catch (error) {
        this.error = error
      } finally {
        this.saving = false
        this.saveData()
        this.validateInsuranceAndSubmit()
      }
    })
  }

  // CPT codes (procedure codes)
  saveProcedureCodes = (procedureIds: string[]) => {
    runInAction(() => {
      try {
        this.saving = true
        this.procedureIds = procedureIds
        this.createChargesArray()
      } catch (error) {
        this.error = error
      } finally {
        this.saving = false
        this.saveData()
        this.validateInsuranceAndSubmit()
      }
    })
  }

  removeProcedureCode = (procedureId: string) => {
    runInAction(() => {
      try {
        this.saving = true
        this.procedureIds = this.procedureIds?.filter(
          (code) => code !== procedureId
        )
        this.createChargesArray()
      } catch (error) {
        this.error = error
      } finally {
        this.saving = false
        this.saveData()
        this.validateInsuranceAndSubmit()
      }
    })
  }

  // Charges table
  createChargesArray = () => {
    let tempArray = [] as ChargesWithId[]

    this.rootStore.practiceStore.billingCodes
      ?.filter((code) => this.procedureIds?.includes(code.id))
      .forEach((billingCode) => {
        let currentModifiers = [] as ChargesWithId['modifiers']
        let currentUnits = {
          label: '1',
          value: 1,
        }
        if (this.currentCharges?.length) {
          this.currentCharges
            ?.filter((data) => billingCode.id === data.id)
            .forEach((charge) => {
              if (charge.id && this.procedureIds?.includes(charge.id)) {
                currentModifiers = charge.modifiers
                currentUnits = charge.units
              } else {
                currentModifiers = []
              }
            })
        }
        tempArray.push({
          id: billingCode.id,
          diagnosisCodes: this.diagnosisId,
          procedureCodes: billingCode.code,
          modifiers: currentModifiers,
          units: {
            ...currentUnits,
            id: billingCode.id,
          },
          dateOfService: formatDate(
            this.appointmentInfo?.dateTime,
            'YYYY-MM-DD'
          ),
          chargeFee: '',
          description: billingCode.description,
        })
      })
    return (this.currentCharges = tempArray)
  }

  get procedureCodes() {
    this.validateInsuranceAndSubmit()
    return this.createChargesArray()
  }

  updateModifiers = (data: any[]) => {
    const modifierId = data.map((d) => d.id)
    const editedCharge = this.currentCharges?.find((charge) =>
      modifierId.includes(charge.id)
    )
    if (editedCharge) {
      const newCharge = {
        ...editedCharge,
        modifiers: data,
      }
      runInAction(() => {
        this.currentCharges = this.currentCharges?.map((charge) => {
          if (charge.id === editedCharge.id) {
            return newCharge
          }
          return charge
        })
      })

      this.saveData()
    }
  }

  updateUnits = (data: {
    label: string
    value: number
    id: number | undefined
  }) => {
    const editedCharge = this.currentCharges?.find(
      (charge) => charge.id === data.id
    )
    if (editedCharge) {
      const newCharge = {
        ...editedCharge,
        units: data,
      }
      runInAction(() => {
        this.currentCharges = this.currentCharges?.map((charge) => {
          if (charge.id === editedCharge.id) {
            return newCharge
          }
          return charge
        })
      })

      this.saveData()
    }
  }

  // Payment options
  savePaymentOption = async (paymentOption: T.InsurancePaymentMethod[]) => {
    try {
      this.saving = true
      runInAction(() => {
        this.paymentOptions = paymentOption
      })
    } catch (error) {
      this.error = error
    } finally {
      await this.saveData()
      this.validateInsuranceAndSubmit()
      this.saving = false
    }
  }

  get selectedPaymentOption() {
    return this.paymentOptions
  }

  // Referring Doctors
  saveReferringDoctor = async (referringDoctor: Option) => {
    try {
      this.saving = true
      if (this.appointmentInfo?.patientProfile?.id) {
        await this.services.updateReferringDoctor(
          this.appointmentInfo?.patientProfile?.id,
          referringDoctor.value
        )
      }

      runInAction(() => {
        this.patientsReferringDoctor = referringDoctor
      })
    } catch (error) {
      this.error = error
    } finally {
      this.saving = false
    }
  }

  get referringDoctor() {
    const refDoc = this.appointmentInfo?.patientProfile?.referringDoctor
    if (refDoc) {
      return (this.patientsReferringDoctor = {
        label: refDoc.name,
        value: refDoc.NPI,
      })
    }
    return null
  }

  // Service Location

  saveServiceLocation = async (serviceLocation: Option) => {
    try {
      this.saving = true
      runInAction(() => {
        this.serviceLocation = serviceLocation
      })
      await this.saveData()
    } catch (error) {
      this.error = error
    } finally {
      this.validateInsuranceAndSubmit()

      this.saving = false
    }
  }

  get currentServiceLocation() {
    return this.serviceLocation
  }

  // Optional Fields

  saveWorkersCompNumber = async (compNumber: string | undefined) => {
    try {
      this.saving = true
      // TODO: Update with the backend save once it's available
      runInAction(() => {
        this.workersCompNumber = compNumber
      })
    } catch (error) {
      this.error = error
    } finally {
      await this.saveData()

      this.saving = false
    }
  }

  get workersCompNumberValue() {
    return this.workersCompNumber
  }

  saveAuthorizationNumber = async (authNumber: string | undefined) => {
    try {
      this.saving = true
      // TODO: Update with the backend save once it's available
      runInAction(() => {
        this.authorizationNumber = authNumber
      })
    } catch (error) {
      this.error = error
    } finally {
      await this.saveData()

      this.saving = false
    }
  }

  get authorizationNumberValue() {
    return this.authorizationNumber
  }

  // Reset Values on load

  resetValues() {
    // reset all values
    this.error = ''
    this.serviceLocation = undefined
    this.parsedData = undefined
    this.allowChargesEdit = true
    this.currentClaimData = undefined
    this.currentCharges = undefined
    this.procedureIds = undefined
    this.diagnosisId = undefined
    this.paymentOptions = undefined
    this.clinicalNoteStatus = undefined
    this.authorizationNumber = undefined
    this.workersCompNumber = undefined
    this.disableSubmit = false
    this.disablePrimaryInsurance = false
    this.disableSecondaryInsurance = false
  }

  resetError = () => {
    runInAction(() => {
      this.error = ''
    })
  }

  // Create patient values
  private async parsePatientDemographicData() {
    runInAction(() => {
      try {
        // set gender
        this.patientGender =
          this.rootStore.patientStore?.patientProfile?.medicalInformation?.find(
            (info) => info?.label === 'Gender'
          )

        // set primary insurance
        this.primaryInsuranceObject = this.rootStore.patientStore
          ?.getFieldsByElementType(T.ElementTypes.Insurance)
          .find(
            (ins) => ins?.fieldTemplate.id === 'primary-insurance'
          )?.valueInsurance

        //  set secondary insurance
        this.secondaryInsuranceObject = this.rootStore.patientStore
          ?.getFieldsByElementType(T.ElementTypes.Insurance)
          .find(
            (ins) => ins?.fieldTemplate.id === 'secondary-insurance'
          )?.valueInsurance

        //  set patient address
        this.patientAddress = this.rootStore.patientStore
          ?.getFieldsByElementType(T.ElementTypes.Address)
          ?.map((data) =>
            data?.valueAddress?.map((d) => {
              return {
                street1: d.street1,
                street2: d.street2,
                city: d.city,
                state: d.state,
                zip: d.zip,
              }
            })
          )
          .filter((values) => values !== undefined)[0]
      } catch (error) {
        this.error = error
      }
    })
  }

  // Submit and Insurance Validation
  private resetDisabledVals = async () => {
    runInAction(() => {
      this.disableSubmit = false
      this.disablePrimaryInsurance = false
      this.disableSecondaryInsurance = false
    })
  }
  async validateInsuranceAndSubmit() {
    try {
      // reset disable state at top of function
      await this.resetDisabledVals()
      const checkNonInsuranceValues = () => {
        if (this.procedureIds?.length === 0) {
          return true
        }
        if (!this.diagnosisId) {
          return true
        }
        return false
      }
      const checkValuesForInsurance = (insType: string) => {
        const insuranceValues = [
          'insuranceCompany',
          'insurancePolicyNumber',
          'subscriber',
          'subscriberDob',
          'subscriberFirstName',
          'subscriberRelationship',
        ]
        const addressValues = ['street1', 'city', 'zip', 'state']

        let insValue
        insType === 'primary'
          ? (insValue = this.primaryInsuranceObject)
          : (insValue = this.secondaryInsuranceObject)

        const checkObjForEmptyValues = (obj: any, keyList: Array<string>) => {
          if (obj === undefined) {
            return true
          }
          if (obj === null) {
            return true
          }
          for (const [key, value] of Object.entries(obj[0])) {
            if (keyList.includes(key) && value === '') {
              return true
            }
          }
        }

        const insuranceCheck = checkObjForEmptyValues(insValue, insuranceValues)
        const addressCheck = checkObjForEmptyValues(
          this.patientAddress,
          addressValues
        )

        // Check for multiple values first
        // Check for insurance and address
        if (insuranceCheck && addressCheck) {
          insType === 'primary'
            ? (this.disablePrimaryInsurance = true)
            : (this.disableSecondaryInsurance = true)
          this.tooltipValue = TIP.incompleteAddressAndInsurance

          return true
        }

        // check for insurance and gender
        if (insuranceCheck && !this.patientGender) {
          insType === 'primary'
            ? (this.disablePrimaryInsurance = true)
            : (this.disableSecondaryInsurance = true)
          this.tooltipValue = TIP.incompleteGenderAndInsurance
          return true
        }

        // check for gender and address
        if (!this.patientGender?.value?.length && addressCheck) {
          insType === 'primary'
            ? (this.disablePrimaryInsurance = true)
            : (this.disableSecondaryInsurance = true)
          this.tooltipValue = TIP.incompleteGenderAndAddress
          return true
        }
        //  Check for insurance
        if (insuranceCheck) {
          insType === 'primary'
            ? (this.disablePrimaryInsurance = true)
            : (this.disableSecondaryInsurance = true)
          this.tooltipValue = TIP.incompleteInsurance
          return true
        }
        //  Check for address
        if (addressCheck) {
          insType === 'primary'
            ? (this.disablePrimaryInsurance = true)
            : (this.disableSecondaryInsurance = true)
          this.tooltipValue = TIP.incompleteAddress
          return true
        }

        // check for gender
        if (!this.patientGender?.value?.length) {
          insType === 'primary'
            ? (this.disablePrimaryInsurance = true)
            : (this.disableSecondaryInsurance = true)
          this.tooltipValue = TIP.incompleteGenderField
          return true
        }

        // check for referring doctor
        if (!this.referringDoctor?.value) {
          return true
        }

        // check for service location
        if (!this.serviceLocation) {
          return true
        }
        return false
      }

      runInAction(() => {
        // Primary Insurance Validation
        if (
          this.selectedPaymentOption?.includes(
            T.InsurancePaymentMethod.PrimaryInsurance
          ) &&
          !this.selectedPaymentOption?.includes(
            T.InsurancePaymentMethod.SecondaryInsurance
          )
        ) {
          if (checkNonInsuranceValues() || checkValuesForInsurance('primary')) {
            this.disableSubmit = true
            this.submitTooltipValue = TIP.submitDisabledGeneric
          }
          checkValuesForInsurance('secondary')
        }
        // Secondary Insurance Validation
        if (
          this.selectedPaymentOption?.includes(
            T.InsurancePaymentMethod.SecondaryInsurance
          ) &&
          !this.selectedPaymentOption?.includes(
            T.InsurancePaymentMethod.PrimaryInsurance
          )
        ) {
          if (
            checkNonInsuranceValues() ||
            checkValuesForInsurance('secondary')
          ) {
            this.disableSubmit = true
            this.submitTooltipValue = TIP.submitDisabledGeneric
          }
          checkValuesForInsurance('primary')
        }

        if (
          this.selectedPaymentOption?.includes(
            T.InsurancePaymentMethod.PrimaryInsurance
          ) &&
          this.selectedPaymentOption?.includes(
            T.InsurancePaymentMethod.SecondaryInsurance
          )
        ) {
          if (
            checkNonInsuranceValues() ||
            checkValuesForInsurance('primary') ||
            checkValuesForInsurance('secondary')
          ) {
            this.disableSubmit = true
            this.submitTooltipValue = TIP.submitDisabledGeneric
          }
        }

        // Selfpay
        if (
          this.selectedPaymentOption?.includes(T.InsurancePaymentMethod.SelfPay)
        ) {
          if (checkNonInsuranceValues()) {
            this.disableSubmit = true
            this.submitTooltipValue = TIP.submitDisabledGeneric
          }
          checkValuesForInsurance('primary')
          checkValuesForInsurance('secondary')
        }

        // no payment options
        if (
          this.selectedPaymentOption?.length === 0 ||
          !this.selectedPaymentOption
        ) {
          this.disableSubmit = true
          this.submitTooltipValue = TIP.incompletePaymentMethod
          checkValuesForInsurance('primary')
          checkValuesForInsurance('secondary')
        }
      })
    } catch (error) {
      this.error = error
    }
  }

  // Creating, updating, fetching, and submitting a claim

  private async parseInsuranceInfoForClaim() {
    await this.parsePatientDemographicData()
    const insurance = this.primaryInsuranceObject?.map((ins) => {
      const gender =
        this.patientGender?.value?.length &&
        this.patientGender.value[0].toUpperCase()
      const address = this.patientAddress?.map((addr) => {
        return {
          street1: addr.street1,
          street2: addr.street2,
          city: addr.city,
          state: addr.state,
          zip: addr.zip,
        }
      })[0]
      return {
        appointmentId: this.appointmentInfo?.id,
        insuranceName: ins.insuranceCompany,
        insurancePolicyNumber: ins.insurancePolicyNumber,
        subscriber: {
          address,
          dateOfBirth: ins.subscriberDob,
          firstName: ins.subscriberFirstName,
          lastName: ins.subscriber,
          genderCode:
            this.patientGender?.value?.length &&
            this.patientGender.value[0].toUpperCase(),
        },
        patientExtendedInformation: {
          address: address,
          genderCode: gender,
          relationshipToSubscriber: ins.subscriberRelationship,
        },
        serviceLocation: this.serviceLocation?.value,
        secondaryInsurance: this.secondaryInsuranceObject
          ? this.secondaryInsuranceObject.map((ins2) => {
              return {
                insuranceName: ins2.insuranceCompany,
                insurancePolicyNumber: ins2.insurancePolicyNumber,
                patientRelationshipToSubscriber: ins2.subscriberRelationship,
                subscriber: {
                  firstName: ins2.subscriberFirstName,
                  lastName: ins2.subscriber,
                  dateOfBirth: ins2.subscriberDob,
                  genderCode: gender,
                  address,
                },
              }
            })
          : null,
      }
    })
    return insurance
  }

  async fetchClaimData(id: string) {
    try {
      this.loading = true
      this.loadMessage = 'Fetching Claim Data...'
      this.resetValues()
      await this.parseInsuranceInfoForClaim()
      this.validateInsuranceAndSubmit()

      const appointmentData = await this.services.queryClaimDataByAppointmentId(
        id
      )

      if (appointmentData) {
        runInAction(() => {
          this.appointmentInfo = appointmentData
        })
      }

      if (appointmentData.clinicalNoteModular?.status) {
        runInAction(() => {
          this.clinicalNoteStatus = appointmentData.clinicalNoteModular?.status
        })
      }
      if (appointmentData.insuranceClaim?.id) {
        const claimData = await this.services.queryClaimDataByClaimId(
          appointmentData.insuranceClaim?.id
        )
        runInAction(() => {
          this.currentClaimData = claimData
          this.appointmentInfo = appointmentData
        })
        if (claimData.status !== 'UNSUBMITTED') {
          runInAction(() => {
            this.allowChargesEdit = false
          })
        }
        if (claimData.paymentMethods) {
          runInAction(() => {
            this.paymentOptions = claimData.paymentMethods
          })
        }
        if (claimData.workerCompensationClaimNumber) {
          runInAction(() => {
            this.workersCompNumber = claimData.workerCompensationClaimNumber
          })
        }
        if (claimData.authorizationNumber) {
          runInAction(() => {
            this.authorizationNumber = claimData.authorizationNumber
          })
        }
        if (claimData.serviceLocationType) {
          runInAction(() => {
            this.serviceLocation = {
              label: `${claimData.serviceLocationType?.charAt(
                0
              )}${claimData.serviceLocationType?.slice(1).toLowerCase()}`,
              value: `${claimData?.serviceLocationType}`,
            }
          })
        }
        const parsedData = this.parseInsuranceInfoForClaim()
        if (parsedData) {
          runInAction(() => {
            this.parsedData = parsedData
          })
        }

        if (claimData.charges) {
          let claimCharges = [] as ChargesWithId[]
          claimData.charges.forEach((charge) => {
            const procedureCode =
              this.rootStore.practiceStore.billingCodes?.find(
                (code) => code.code === charge.procedureCode
              )
            const diagnosisCode =
              this.rootStore.practiceStore.billingCodes?.find(
                (code) => code.code === charge.diagnosisCodes[0]
              )
            const modCodes = charge.modifiers?.map((mod) => {
              return {
                label: mod,
                value: mod,
                id: procedureCode?.id,
              }
            })

            if (procedureCode) {
              claimCharges.push({
                id: procedureCode.id,
                diagnosisCodes: diagnosisCode?.id,
                procedureCodes: procedureCode.code,
                modifiers: modCodes ? modCodes : undefined,
                units: {
                  label: `${charge.units}`,
                  value: charge.units,
                  id: procedureCode.id,
                },
                dateOfService: charge.dateOfService,
                chargeFee: charge.chargeFee,
                description: procedureCode.description,
              })
              runInAction(() => {
                this.diagnosisId = diagnosisCode?.id
              })
            }
          })
          runInAction(() => {
            this.currentCharges = claimCharges
            this.procedureIds = claimCharges.map((charge) => charge.id)
          })
        }
      }
    } catch (error) {
      this.error = error
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
  private saveData = async () => {
    try {
      this.saving = true
      if (!this.currentClaimData?.id && this.appointmentInfo?.id) {
        const claimData = await this.services.createInsuranceClaim({
          appointmentId: this.appointmentInfo?.id,
          paymentMethods: this.paymentOptions ? this.paymentOptions : undefined,
          serviceLocationType: this.serviceLocation
            ? this.serviceLocation.value
            : null,
          authorizationNumber: this.authorizationNumber
            ? this.authorizationNumber
            : '',
          workerCompensationClaimNumber: this.workersCompNumber
            ? this.workersCompNumber
            : '',
          charges: this.currentCharges
            ? this.currentCharges?.map((charge) => {
                const diagnosisCode =
                  this.rootStore.practiceStore.billingCodes?.find(
                    (code) => code.id === charge.diagnosisCodes
                  )
                return {
                  diagnosisCodes: diagnosisCode?.code
                    ? [diagnosisCode?.code]
                    : [' '],
                  procedureCode: charge.procedureCodes,
                  modifiers: charge.modifiers?.map((mod) => mod.label),
                  units: charge.units.value,
                  dateOfService: charge.dateOfService,
                  chargeFee: charge.chargeFee,
                }
              })
            : null,
        })
        runInAction(() => {
          this.currentClaimData = claimData
        })
        return
      } else {
        const claimData = await this.services.updateInsuranceClaim(
          this.currentClaimData?.id,
          {
            appointmentId: undefined,
            paymentMethods: this.paymentOptions
              ? this.paymentOptions
              : undefined,
            charges: this.currentCharges
              ? this.currentCharges?.map((charge) => {
                  const diagnosisCode =
                    this.rootStore.practiceStore.billingCodes?.find(
                      (code) => code.id === charge.diagnosisCodes
                    )
                  return {
                    diagnosisCodes: diagnosisCode?.code
                      ? [diagnosisCode?.code]
                      : [' '],
                    procedureCode: charge.procedureCodes,
                    modifiers: charge.modifiers?.map((mod) => mod.label),
                    units: charge.units.value,
                    dateOfService: charge.dateOfService,
                    chargeFee: charge.chargeFee,
                  }
                })
              : null,
            serviceLocationType: this.serviceLocation
              ? this.serviceLocation.value
              : undefined,
            authorizationNumber: this.authorizationNumber
              ? this.authorizationNumber
              : '',
            workerCompensationClaimNumber: this.workersCompNumber
              ? this.workersCompNumber
              : '',
          }
        )
        runInAction(() => {
          this.currentClaimData = claimData
        })
        return
      }
    } catch (error) {
      this.error = error
    } finally {
      this.saving = false
    }
  }

  private saveDataForClaim = async () => {
    try {
      this.saving = true
      const parsedData = await this.parseInsuranceInfoForClaim()
      if (parsedData) {
        runInAction(() => {
          this.parsedData = parsedData
        })
      }
      let secondaryIns = undefined
      if (
        this.paymentOptions?.includes(
          T.InsurancePaymentMethod.SecondaryInsurance
        ) &&
        this.secondaryInsuranceObject
      ) {
        secondaryIns = this.parsedData[0].secondaryInsurance
      }

      let formattedClaimData = {}
      if (this.paymentOptions?.includes(T.InsurancePaymentMethod.SelfPay)) {
        formattedClaimData = {
          paymentMethods: this.paymentOptions,
          subscriber: this.parsedData
            ? this.parsedData[0].subscriber
            : undefined,
        }
      } else {
        formattedClaimData = {
          appointmentId: this.appointmentInfo?.id,
          authorizationNumber: this.authorizationNumber
            ? this.authorizationNumber
            : '',
          workerCompensationClaimNumber: this.workersCompNumber
            ? this.workersCompNumber
            : '',
          paymentMethods: this.paymentOptions,
          serviceLocationType: this.serviceLocation
            ? this.serviceLocation.value
            : undefined,
          insuranceName: this.parsedData
            ? (this.parsedData[0].insuranceName as string)
            : undefined,
          insurancePolicyNumber: this.parsedData
            ? (this.parsedData[0].insurancePolicyNumber as string)
            : undefined,
          patientExtendedInformation: this.parsedData
            ? this.parsedData[0].patientExtendedInformation
            : undefined,
          subscriber: this.parsedData
            ? this.parsedData[0].subscriber
            : undefined,
          secondaryInsurance: secondaryIns ? secondaryIns[0] : null,
        }
      }
      const claimData = await this.services.updateInsuranceClaim(
        this.currentClaimData?.id,
        {
          ...formattedClaimData,
          appointmentId: undefined,
          charges: this.currentCharges?.map((charge) => {
            const diagnosisCode =
              this.rootStore.practiceStore.billingCodes?.find(
                (code) => code.id === charge.diagnosisCodes
              )
            return {
              diagnosisCodes: [diagnosisCode?.code],
              procedureCode: charge.procedureCodes,
              modifiers: charge.modifiers?.map((mod) => mod.label),
              units: charge.units.value,
              dateOfService: charge.dateOfService,
              chargeFee: charge.chargeFee,
            }
          }),
        }
      )
      runInAction(() => {
        this.currentClaimData = claimData
      })
      return
    } catch (error) {
      this.error = error
    } finally {
      this.saving = false
    }
  }

  submitClaim = async () => {
    try {
      this.loading = true
      this.loadMessage = 'Submitting Claim Data...'
      await this.saveDataForClaim()
      if (this.currentClaimData?.id && this.parsedData) {
        const claimResults = await this.services.submitInsuranceClaim(
          this.currentClaimData.id
        )
        runInAction(() => {
          this.submitClaimResults = claimResults
          this.currentClaimData = claimResults
          this.allowChargesEdit = false
        })
      }
    } catch (error) {
      this.error = error
    } finally {
      this.loading = false
    }
  }

  disableSubmitButton = (disabled: boolean) => {
    try {
      runInAction(() => {
        this.disableSubmit = disabled
      })
    } catch (error) {
      this.error = error
    }
  }
}
