import { Option } from '@ps-ui/components'
import clsx from 'clsx'
import React, { useCallback, useMemo } from 'react'
import { Table } from 'src/components/atoms/Table'
import { FunctionalDropdown } from 'src/components/molecules/FunctionalDropdown'
import { detailsStyle } from 'src/components/templates/ClinicalNotesLayout/components/PatientHeader'
import { oneThroughTenOptions } from 'src/pages/ClinicalNotesv2/utils/radioOptionsArray'
import { Scalars } from 'src/schema-types'
import { BillingStore } from 'src/stores/domain/billing'
import { PracticeStore } from 'src/stores/domain/practice'

type BillingCodesProps = {
  toggleCptModal: () => void
  toggleIcdModal: () => void
  billingStore: BillingStore
  practiceStore: PracticeStore
}

export type ChargesWithId = {
  chargeFee: string
  dateOfService: Scalars['AWSDate']
  diagnosisCodes: string | undefined
  modifiers:
    | {
        label: string | undefined
        value: string | undefined
        id: string | undefined
      }[]
    | undefined
  procedureCodes: string
  units: { label: string; value: number; id: string | number | undefined }
  description: string | null | undefined
  id: string | undefined
}

export const BillingCodes: React.FC<BillingCodesProps> = ({
  toggleCptModal,
  toggleIcdModal,
  billingStore,
  practiceStore,
}) => {
  const handleRemoveCptCodeClick = (id: string) => {
    billingStore.removeProcedureCode(id)
  }
  const handleRemoveIcdCodeClick = () => {
    billingStore.removeDiagnosisCode()
  }

  const renderDescription = useCallback(
    ({ value }) => {
      return (
        <div
          onClick={billingStore.allowChargesEdit ? toggleCptModal : undefined}
          className={clsx(
            billingStore.allowChargesEdit && 'cursor-pointer hover:text-primary'
          )}
        >
          {value}
        </div>
      )
    },
    [toggleCptModal, billingStore.allowChargesEdit]
  )

  const renderCode = useCallback(
    ({ value }) => {
      return (
        <div
          onClick={billingStore.allowChargesEdit ? toggleCptModal : undefined}
          className={clsx(
            billingStore.allowChargesEdit && 'cursor-pointer hover:text-primary'
          )}
        >
          {value}
        </div>
      )
    },
    [toggleCptModal, billingStore.allowChargesEdit]
  )
  const renderIcdDescription = useCallback(
    ({ value }) => {
      return (
        <div
          onClick={billingStore.allowChargesEdit ? toggleIcdModal : undefined}
          className={clsx(
            billingStore.allowChargesEdit && 'cursor-pointer hover:text-primary'
          )}
        >
          {value}
        </div>
      )
    },
    [toggleIcdModal, billingStore.allowChargesEdit]
  )

  const renderIcdCode = useCallback(
    ({ value }) => {
      return (
        <div
          onClick={billingStore.allowChargesEdit ? toggleIcdModal : undefined}
          className={clsx(
            billingStore.allowChargesEdit && 'cursor-pointer hover:text-primary'
          )}
        >
          {value}
        </div>
      )
    },
    [toggleIcdModal, billingStore.allowChargesEdit]
  )

  const renderModifier = useCallback(
    ({ value }) => {
      const handleModifierClick = (opt: Option[]) => {
        billingStore.updateModifiers(opt)
      }
      return (
        <>
          {billingStore.allowChargesEdit ? (
            <div className="w-44 ">
              <FunctionalDropdown
                dropdownOption={practiceStore.cptModifierBillingCodes?.map(
                  (codeData) => {
                    return {
                      label: codeData.code,
                      value: codeData.id,
                      id: value.id,
                    }
                  }
                )}
                onOptionChange={handleModifierClick}
                placeholder="Modifier"
                isMulti={true}
                currentSelection={value.modifiers}
              />
            </div>
          ) : (
            <div className="flex flex-col">
              {value.modifiers
                ? value.modifiers.map(
                    (mod: {
                      label: string | undefined
                      value: string | undefined
                      id: string | undefined
                    }) => <span className="pb-1">{mod.label}</span>
                  )
                : '--'}
            </div>
          )}
        </>
      )
    },
    [billingStore, practiceStore.cptModifierBillingCodes]
  )

  const renderUnits = useCallback(
    ({ value }) => {
      const optionsWithId = oneThroughTenOptions.map((option) => {
        return {
          label: option.label,
          value: option.value,
          id: value.id,
        }
      })
      const handleUnitClick = (opt: {
        label: string
        value: number
        id: number | undefined
      }) => {
        billingStore.updateUnits(opt)
      }

      return (
        <>
          {billingStore.allowChargesEdit ? (
            <div className="w-44">
              <FunctionalDropdown
                dropdownOption={optionsWithId}
                onOptionChange={handleUnitClick}
                placeholder="Units"
                currentSelection={value.units}
              />
            </div>
          ) : (
            <div>{value.units.label}</div>
          )}
        </>
      )
    },
    [billingStore]
  )

  const renderRemoveCptCode = useCallback(({ value }) => {
    return (
      <div
        className="cursor-pointer flex justify-end mr-2"
        onClick={() => handleRemoveCptCodeClick(value)}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 11L11 1M1 1L11 11"
            stroke="#BDBDBD"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    )
    // eslint-disable-next-line
  }, [])
  const renderRemoveIcdCode = useCallback(() => {
    return (
      <div
        className="cursor-pointer flex justify-end mr-2"
        onClick={() => handleRemoveIcdCodeClick()}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 11L11 1M1 1L11 11"
            stroke="#BDBDBD"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    )
    // eslint-disable-next-line
  }, [])

  const procedureColumns = useMemo(() => {
    const tempColumns = [
      {
        Header: 'Code',
        accessor: 'procedureCode',
        Cell: renderCode,
      },
      {
        Header: 'Description',
        accessor: 'description',
        disableSortBy: true,
        Cell: renderDescription,
      },
      {
        Header: 'Units',
        accessor: 'units',
        disableSortBy: true,
        Cell: renderUnits,
      },
      {
        Header: 'Modifier',
        accessor: 'modifiers',
        disableSortBy: true,
        Cell: renderModifier,
      },
    ]

    if (billingStore.allowChargesEdit) {
      tempColumns.push({
        Header: '',
        accessor: 'removeCode',
        disableSortBy: true,
        Cell: renderRemoveCptCode,
      })
    }
    return tempColumns
  }, [
    renderCode,
    renderDescription,
    renderModifier,
    renderUnits,
    renderRemoveCptCode,
    billingStore.allowChargesEdit,
  ])

  const diagnosisColumns = useMemo(() => {
    const tempColumns = [
      {
        Header: 'Code',
        accessor: 'diagnosisCode',
        Cell: renderIcdCode,
      },
      {
        Header: 'Description',
        accessor: 'description',
        disableSortBy: true,
        Cell: renderIcdDescription,
      },
    ]
    if (billingStore.allowChargesEdit) {
      tempColumns.push({
        Header: '',
        accessor: 'removeCode',
        disableSortBy: true,
        Cell: renderRemoveIcdCode,
      })
    }
    return tempColumns
  }, [
    renderIcdCode,
    renderIcdDescription,
    billingStore.allowChargesEdit,
    renderRemoveIcdCode,
  ])

  return (
    <>
      <div
        onClick={
          billingStore.allowChargesEdit && !billingStore?.diagnosis
            ? toggleIcdModal
            : undefined
        }
        className={clsx(
          detailsStyle,
          'font-semibold text-gray-600 px-6 mb-10 w-full',
          billingStore.allowChargesEdit &&
            !billingStore?.diagnosis &&
            'cursor-pointer  hover:text-primary hover:opacity-50'
        )}
      >
        {billingStore?.diagnosis ? (
          <>
            <div className="font-semibold text-black mb-6">Diagnosis Code</div>
            <Table
              removeVerticalScroll={true}
              columns={diagnosisColumns}
              data={
                [
                  {
                    diagnosisCode: billingStore.diagnosisCode,
                    description: billingStore.diagnosis,
                  },
                ] || []
              }
            />
          </>
        ) : (
          <div className="border border-primary p-3 w-max rounded-lg">
            + Add a diagnosis code
          </div>
        )}
      </div>
      <div
        className={clsx(
          'font-semibold text-gray-600 px-6 mb-10 w-full',
          !billingStore.procedureIds?.length &&
            'cursor-pointer hover:text-primary'
        )}
      >
        {billingStore.procedureIds && billingStore.procedureIds?.length !== 0 && (
          <>
            <div className="font-semibold text-black mb-6">Procedure Code</div>
            <Table
              removeVerticalScroll={true}
              columns={procedureColumns}
              data={
                billingStore.procedureCodes
                  ?.filter((hasData) => hasData)
                  .map((data) => {
                    return {
                      procedureCode: data.procedureCodes,
                      description: data.description,
                      units: { units: data.units, id: data.id },
                      modifiers: {
                        modifiers: data.modifiers,
                        id: data.id,
                      },
                      removeCode: data.id,
                    }
                  }) || []
              }
            />
          </>
        )}
        {billingStore.allowChargesEdit && (
          <div
            onClick={toggleCptModal}
            className={clsx(
              'border border-gray-400 hover:border-primary p-3 mr-6 rounded-lg w-max cursor-pointer hover:text-primary bg-white',
              billingStore.procedureIds?.length && 'mt-6'
            )}
          >
            + Add procedure code(s)
          </div>
        )}
      </div>
    </>
  )
}
