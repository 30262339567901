import { anonymize, identify } from '@fullstory/browser'
import { observer } from 'mobx-react'
import React, { FunctionComponent, Suspense, useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { AuthenticatedRoute } from 'react-router-util'
import { PageContainer } from 'src/components/templates/PageContainer'
import { Login } from 'src/pages/Login'
import { PrintForm } from 'src/pages/PrintForm'
import { ResetPassword } from 'src/pages/ResetPassword'
import { useStore } from 'src/stores/store'
import { SoloBillingComponent } from './pages/Billing'
import { PrintClinicalNote } from './pages/ClinicalNotes/components/PrintClinicalNote'
import { ClinicalNotesv2 } from './pages/ClinicalNotesv2'
import { Print as PrintClinicalNoteV2 } from './pages/ClinicalNotesv2/components/Print'
import { CreateClaim } from './pages/CreateClaim'
import { SchedulingConfirmation } from './pages/SchedulingConfirmation'
import { SchedulingSuccess } from './pages/SchedulingSuccess'

const Home = React.lazy(() => import('src/pages/Home'))
const Forms = React.lazy(() => import('src/pages/Forms'))
const Messages = React.lazy(() => import('src/pages/Messages'))
const Patients = React.lazy(() => import('src/pages/Patients'))
const Patient = React.lazy(() => import('src/pages/Patient'))
const Appointments = React.lazy(() => import('src/pages/Appointments'))
const SendForm = React.lazy(() => import('src/pages/SendForm'))
const ClinicalNotes = React.lazy(() => import('src/pages/ClinicalNotes'))

const Calendar = React.lazy(() => import('src/pages/Calendar'))
const Tasks = React.lazy(() => import('src/pages/Tasks'))

export const Router: FunctionComponent = observer(() => {
  const location = useLocation()
  const [lastPath, setLastPath] = useState<string>()
  const { authStore, profileStore } = useStore()

  const notAuthed = !authStore.authed

  if (profileStore.profile?.id && authStore.authed) {
    identify(profileStore.profile.id, {
      displayName: profileStore.profile.lastName || undefined,
      email: profileStore.profile.email,
    })
  } else {
    anonymize()
  }

  useEffect(() => {
    const lastPath = localStorage.getItem('last_path') || '/'
    setLastPath(lastPath)
  }, [location])

  return (
    <>
      <Switch>
        {/* unauthenticated routes */}

        <AuthenticatedRoute
          exact
          loginPath={lastPath}
          isAuthenticated={notAuthed}
          path="/login"
          component={Login}
        />
        <AuthenticatedRoute
          exact
          loginPath="/"
          isAuthenticated={notAuthed}
          path="/reset-password"
          component={ResetPassword}
        />

        {/* Authenticated Routes */}
        <PageContainer>
          <Suspense fallback={<div>Loading</div>}>
            <AuthenticatedRoute isAuthenticated={authStore.authed}>
              <Route exact path="/" component={Home} />
              <Route exact path="/patients" component={Patients} />
              <Route exact path="/messages/:id?" component={Messages} />
              <Route exact path="/scheduling" component={Calendar} />
              <Route exact path="/appointments" component={Appointments} />
              <Route exact path="/send-form" component={SendForm} />
              <Route exact path="/new-claim/:id?" component={CreateClaim} />
              <Route exact path="/tasks" component={Tasks} />
              <Route
                exact
                path="/clinical-notes/:id?"
                component={ClinicalNotes}
              />
              <Route
                exact
                path="/v2/:clinicalNoteId"
                component={ClinicalNotesv2}
              />
              <Route
                exact
                path="/billing/:id"
                component={SoloBillingComponent}
              />
              <Route
                exact
                path="/v2/print/:clinicalNoteId"
                component={PrintClinicalNoteV2}
              />
              <Route
                exact
                path="/scheduling/success"
                component={SchedulingSuccess}
              />
              <Route
                exact
                path="/scheduling/confirm"
                component={SchedulingConfirmation}
              />
              <Route exact path="/form/print/:id" component={PrintForm} />
              <Route path="/patients/:id" component={Patient} />
              <Route
                path="/form/print-clinical-note/:id"
                component={PrintClinicalNote}
              />
              <Route path="/forms/:filter" component={Forms} />
            </AuthenticatedRoute>
          </Suspense>
        </PageContainer>
      </Switch>
    </>
  )
})
