import React from 'react'
import { TMotion } from 'src/pages/ClinicalNotesv2/types'

type RangeOfMotionAnswerProps = {
  motionAnswer: TMotion
}

export const RangeOfMotionAnswer: React.FC<RangeOfMotionAnswerProps> = ({
  motionAnswer,
}) => {
  return (
    <>
      {motionAnswer.measures.map((measure) => {
        return (
          <div className="mb-3">
            <p>
              {measure.body ? (
                <>
                  <span className="font-semibold py-1">
                    Body part: {measure.body}
                  </span>
                </>
              ) : null}
              {measure.side ? (
                <>
                  <span className="font-semibold py-1">
                    Side: {measure.side}
                  </span>
                </>
              ) : null}
            </p>
            {measure.properties ? (
              <p className="flex flex-col">
                {Object.entries(measure.properties).map(([property, value]) => {
                  if (!value) {
                    return null
                  }
                  return (
                    <>
                      <span className="font-semibold py-1">{`${property}: ${value}`}</span>
                    </>
                  )
                })}
              </p>
            ) : null}
          </div>
        )
      })}
      {motionAnswer.notes ? (
        <p>
          <span
            className="font-semibold py-1 break-words"
            style={{ wordBreak: 'break-word' }}
          >
            Notes: {motionAnswer.notes}
          </span>
        </p>
      ) : null}
    </>
  )
}
