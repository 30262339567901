import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { AlertMessage } from 'src/components/molecules/AlertMessage'
import { ClinicalNotesTemplatesList } from 'src/components/molecules/ClinicalNotesTemplatesList'
import { DialogModal } from 'src/components/molecules/DialogModal'
import { useStore } from 'src/stores/store'

type ChangeTemplateModalProps = {
  isOpen: boolean
  onClose: () => void
  cb?: () => void
}

export const ChangeTemplateModal: React.FC<ChangeTemplateModalProps> = observer(
  ({ isOpen, onClose, cb }) => {
    const { addToast } = useToasts()

    const { practiceStore, clinicalNotesStore } = useStore()
    const [selectedTemplate, setSelectedTemplate] = useState<string>()
    const [hasReadAlert, setHasReadAlert] = useState(false)

    useEffect(() => {
      practiceStore.getClinicalNoteTemplates()
    }, [practiceStore])

    const handleClose = () => {
      onClose()

      setTimeout(() => {
        setSelectedTemplate(undefined)
        setHasReadAlert(false)
      }, 500) // Wait for Modal closing transition, to avoid alert displaying again on close
    }

    const changeClinicalNoteTemplate = async () => {
      if (selectedTemplate) {
        try {
          await clinicalNotesStore.templates.changeClinicalNoteTemplate(
            selectedTemplate
          )

          if (clinicalNotesStore.templates.errorChanging) {
            throw new Error(clinicalNotesStore.templates.errorChanging)
          }

          addToast(`The Clinical Note Template was successfully changed`, {
            appearance: 'success',
          })

          cb && cb()
        } catch (err) {
          addToast(`Cannot change Clinical Note Template`, {
            appearance: 'error',
            onDismiss: clinicalNotesStore.templates.cleanError,
          })
        } finally {
          handleClose()
        }
      }
    }

    return (
      <DialogModal
        headerText={
          hasReadAlert
            ? 'Select a Clinical Note Template'
            : 'Change Clinical Note Template'
        }
        primaryButtonText={hasReadAlert ? 'Continue' : 'Change'}
        primaryButtonCallback={
          hasReadAlert
            ? changeClinicalNoteTemplate
            : () => setHasReadAlert(true)
        }
        bodyContent={
          <>
            {hasReadAlert ? (
              <ClinicalNotesTemplatesList
                pageBasedClinicalNoteTemplates={
                  practiceStore.pageBasedClinicalNoteTemplates || []
                }
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                isCreatingModal={false}
              />
            ) : (
              <AlertMessage
                hasIcon={false}
                alertMessage="By changing the notes template all data from the current note will be lost. Are you sure you want to continue?"
              />
            )}
          </>
        }
        open={isOpen}
        setOpen={() => handleClose()}
      />
    )
  }
)
