import { Option } from '@ps-ui/components'
import React from 'react'
import { ClinicalNotesInput } from 'src/pages/ClinicalNotesv2/components/fields/Input'
import { InputContainer } from 'src/pages/ClinicalNotesv2/components/InputContainer'
import { gaitOptions } from 'src/pages/ClinicalNotesv2/types'
import {
  assistiveDeviceArray,
  cadenceArray,
  deviationsArray,
  supportArray,
} from 'src/pages/ClinicalNotesv2/utils/gaitArray'
import { TabModuleContainer } from '../../TabModuleContainer'
import { HorizontalOptionsList } from '../HorizontalOptionsList'

type GaitProps = {
  name: string
  currentTab: string
}

const GaitElem: React.FC<GaitProps> = ({ name, currentTab }) => {
  const variableOptions1: Option[] | undefined = React.useMemo(() => {
    switch (currentTab) {
      case 'Measurements':
        return undefined
      case 'Support':
        return supportArray
      case 'Assistive Device':
        return assistiveDeviceArray
      default:
        return cadenceArray
    }
  }, [currentTab])

  const variableOptions2: Option[] | undefined = React.useMemo(() => {
    switch (currentTab) {
      case 'Support':
        return supportArray
      case 'Deviations':
        return deviationsArray
    }
  }, [currentTab])

  return (
    <div>
      {variableOptions1 ? (
        <div className="flex">
          {currentTab === 'Support' ? (
            <>
              <label className="font-bold flex items-center mr-3">Left</label>
              <HorizontalOptionsList
                isMulti={true}
                name={`${name}.option1`}
                options={variableOptions1}
              />
            </>
          ) : null}
          {currentTab === 'Support' || currentTab === 'Deviations' ? null : (
            <HorizontalOptionsList
              name={`${name}.option1`}
              options={variableOptions1}
              isMulti={currentTab === 'Cadence'}
            />
          )}
        </div>
      ) : null}
      {variableOptions2 ? (
        <div className="flex">
          {currentTab === 'Support' ? (
            <label className="font-bold flex items-center mr-3">Right</label>
          ) : null}
          <HorizontalOptionsList
            isMulti={true}
            name={`${name}${
              currentTab === 'Support' ? '.option2' : '.option1'
            }`}
            options={variableOptions2}
          />
        </div>
      ) : null}

      {currentTab === 'Measurements' && (
        <div className="flex flex-col content-center">
          <InputContainer
            name={`${name}.option1`}
            label="Time"
            inlineLabel
            withAmendLabel={false}
          >
            <ClinicalNotesInput
              name={`${name}.option1`}
              type="text"
              className="ml-6 my-2 w-20 mr-4"
            />
            <HorizontalOptionsList
              name={`${name}.time-label`}
              options={[
                { label: 'Seconds', value: 'seconds' },
                { label: 'Minutes', value: 'minutes' },
              ]}
            />
          </InputContainer>
          <InputContainer
            name={`${name}.option2`}
            label="Distance"
            inlineLabel
            withAmendLabel={false}
          >
            <ClinicalNotesInput
              name={`${name}.option2`}
              type="text"
              className="ml-4 my-2 w-20"
            />{' '}
            <span className="ml-3">Ft.</span>
          </InputContainer>
        </div>
      )}
      <div className="relative py-2">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300 border-dashed m-auto" />
        </div>
      </div>
    </div>
  )
}

export const Gait: React.FC<{ name: string }> = ({ name }) => {
  return (
    <TabModuleContainer
      name={name}
      optionList={gaitOptions as unknown as string[]}
    >
      {({ currentTab }) => (
        <GaitElem
          key={currentTab}
          currentTab={currentTab}
          name={`${name}.properties.${currentTab}`}
        />
      )}
    </TabModuleContainer>
  )
}
