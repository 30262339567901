import { Button, Flex, TextField, validEmail } from '@ps-ui/components'
import { observer } from 'mobx-react'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useFormState } from 'react-use-form-state'
import { Header, Image, Message, Tab } from 'semantic-ui-react'
import { Pannel } from 'src/components/atoms'
import Logo from 'src/images/new-ps-logo.png'
import { LoginInput } from 'src/schema-types'
import { useStore } from 'src/stores/store'
import { formHasEmptyValues, formHasErrors } from 'src/utils/formValidators'
import { FullWidthTab, TabPane, TabWrapper } from './styles'

export const Login: FunctionComponent = observer(() => {
  const {
    authStore,
    assginedFormStore: {
      hasError: hasAssignedFormErrors,
      resetAssignedFormStore,
    },
    appointmentsStore: { fetchError, resetAppointmentsError },
    messagingStore: { hasError: hasMessagingErrors, resetMessagingErrors },
    patientsStore: { fetchingPatientsError, resetPatientsError },
  } = useStore()

  const [loginFormState, loginInput] = useFormState<LoginInput>()

  const onLogin = () => {
    authStore.login(loginFormState.values)
  }

  const onSignInClear = () => {
    authStore.logout() // clears login state
  }

  React.useEffect(() => {
    if (
      hasAssignedFormErrors ||
      !!fetchError ||
      hasMessagingErrors ||
      !!fetchingPatientsError
    ) {
      resetAssignedFormStore()
      resetMessagingErrors()
      resetAppointmentsError()
      resetPatientsError()
    }
  }, [
    hasAssignedFormErrors,
    fetchError,
    hasMessagingErrors,
    fetchingPatientsError,
    resetAppointmentsError,
    resetAssignedFormStore,
    resetMessagingErrors,
    resetPatientsError,
  ])

  const renderLogin = (
    <form method="POST" onSubmit={(e) => e.preventDefault()}>
      <TextField
        required
        label="Email"
        margin="0 0 10px 0"
        placeholder="E-mail address"
        onFocus={onSignInClear}
        error={loginFormState.errors.username}
        inputClassName="fs-mask"
        {...loginInput.email({
          name: 'username',
          validate: validEmail,
          validateOnBlur: true,
        })}
      />
      <TextField
        required
        label="Password"
        margin="0 0 10px 0"
        placeholder="Password"
        onFocus={onSignInClear}
        error={loginFormState.errors.password}
        inputClassName="fs-mask"
        {...loginInput.password('password')}
      />
      <Button
        expand
        variant="filled"
        className="text-white"
        color="primary"
        loading={authStore.authing}
        data-testid="login-button"
        disabled={
          authStore.authing ||
          formHasErrors(loginFormState.errors) ||
          formHasEmptyValues(loginFormState.values, ['username', 'password'])
        }
        // size="large"
        onClick={onLogin}
      >
        Login
      </Button>
      <Header as="h5" textAlign="right">
        <Link to="/reset-password" onClick={onSignInClear}>
          Forgot Password?
        </Link>
      </Header>
      {authStore.authError && <Message negative>{authStore.authError}</Message>}
    </form>
  )

  const panes = [
    {
      menuItem: {
        key: 'login',
        icon: 'users',
        content: 'Login',
      },
      render: () => (
        <Pannel borderRadius="0 0 10px 10px">
          <Tab.Pane as={TabPane}>{renderLogin} </Tab.Pane>
        </Pannel>
      ),
    },
  ]

  return (
    <Flex
      margin="auto"
      padding="16px"
      height="100vh"
      flexDirection="column"
      justifyContent="space-evenly"
    >
      <Flex justifyContent="center">
        <Image
          src={Logo}
          style={{ width: '400px', marginTop: '25px' }}
          alignItems="center"
        />
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        <TabWrapper>
          <Tab as={FullWidthTab} panes={panes} />
        </TabWrapper>
      </Flex>
    </Flex>
  )
})
