import { gql } from 'apollo-boost'
import { Query } from 'src/schema-types'

export interface SearchFamilyResponse {
  searchFamily: Query['searchFamily']
}

export const SEARCH_FAMILY = gql`
  query searchFamily($input: String!) {
    searchFamily(input: $input) {
      entities {
        ... on Family {
          id
          email
          members {
            id
            firstName
            lastName
            phoneNumber
          }
        }
      }
    }
  }
`
