import { gql } from 'apollo-boost'
import {
  CreateClinicalNoteModularAmendmentMutation,
  CreateClinicalNoteModularAmendmentMutationVariables,
} from 'src/operations-types'
import { apolloClient } from 'src/settings/apolloClient'
import { clinicalNoteModularAmendmentFragment } from '../fragments/clinicalNoteModularAmendment'

const CREATE_CLINICAL_NOTE_MODULAR_AMENDMENT = gql`
  mutation createClinicalNoteModularAmendment(
    $input: ClinicalNoteModularAmendmentCreateInput!
  ) {
    createClinicalNoteModularAmendment(input: $input) {
      ...clinicalNoteModularAmendment
    }
  }
  ${clinicalNoteModularAmendmentFragment}
`

export const createClinicalNoteModularAmendment = async (
  input: CreateClinicalNoteModularAmendmentMutationVariables['input']
) => {
  const response =
    await apolloClient.mutate<CreateClinicalNoteModularAmendmentMutation>({
      mutation: CREATE_CLINICAL_NOTE_MODULAR_AMENDMENT,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        input,
      },
    })

  return response?.data?.createClinicalNoteModularAmendment
}
