import { gql } from 'apollo-boost'
import { InsuranceClaimUpdateInput, Mutation } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface UpdateExistingClaimResponse {
  updateInsuranceClaim: Mutation['updateInsuranceClaim']
}

export const UPDATE_INSURANCE_CLAIM = gql`
  mutation updateInsuranceClaim($id: ID!, $input: InsuranceClaimUpdateInput!) {
    updateInsuranceClaim(id: $id, input: $input) {
      id
      claimId
      status
      errors
    }
  }
`

export const updateInsuranceClaim = async (
  id: string,
  input: InsuranceClaimUpdateInput
) => {
  const result = await apolloClient.query<UpdateExistingClaimResponse>({
    query: UPDATE_INSURANCE_CLAIM,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      id,
      input,
    },
  })
  if (result.errors?.length) {
    throw new Error(result.errors[0].message)
  }
  return result.data.updateInsuranceClaim
}
