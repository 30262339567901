import { Typography } from '@ps-ui/components'
import clsx from 'clsx'
import parse from 'date-fns/parse'
import { observer } from 'mobx-react'
import React from 'react'
import { Avatar } from 'src/components/atoms'
import { Pannel } from 'src/components/atoms/Pannel'
import { useShowModal } from 'src/hooks/showModal'
import { useStore } from 'src/stores/store'
import { calcAge, formatDate } from 'src/utils/formatDate'
import { formatPatientNames } from 'src/utils/formatText'
import { DiagnosisModal } from '../DiagnosisModal'

type PatientHeaderProps = {}

export const detailsStyle = 'font-semibold text-gray-600 ml-10 first:ml-0'

export const PatientHeader: React.FC<PatientHeaderProps> = observer(() => {
  const [showModal, toggleModal] = useShowModal(false)
  const { clinicalNotesStore } = useStore()

  const patient = clinicalNotesStore.clinicalNote?.patient

  const dateOfBirth = patient?.dateOfBirth
    ? parse(patient?.dateOfBirth, 'yyyy-MM-dd', new Date())
    : undefined

  const gender = patient?.medicalInformation?.find(
    (item) =>
      item?.fieldTemplate.id === 'gender' ||
      item?.fieldTemplate.id === 'NewGender'
  )?.value

  const race = patient?.medicalInformation?.find(
    (item) => item?.fieldTemplate.id === 'race'
  )?.value?.[0]

  const handleAddMeasureClick = () => {
    toggleModal()
  }

  return (
    <Pannel padding="0" width="100%" margin="10px 0">
      <div className="flex align-center m-5">
        <Avatar
          url=""
          firstName={patient?.firstName}
          width="40"
          height="40"
          margin="14 0 0 0"
          borderRadius="50"
        />
        <Typography as="h2" color="dark" fontWeight="500" margin="0 0 0 14px">
          {formatPatientNames(patient?.firstName)}{' '}
          {formatPatientNames(patient?.lastName)}
        </Typography>
      </div>
      <div className="bg-gray-700 divider" />
      <div className="flex m-5">
        {gender ? (
          <div className={detailsStyle}>
            Gender: <span className="font-normal">{gender}</span>
          </div>
        ) : null}
        {dateOfBirth ? (
          <div className={detailsStyle}>
            Age:{' '}
            <span className="font-normal">
              {calcAge(dateOfBirth)} years old
            </span>
          </div>
        ) : null}
        {dateOfBirth ? (
          <div className={detailsStyle}>
            Date of birth:{' '}
            <span className="ml- font-normal">{formatDate(dateOfBirth)}</span>
          </div>
        ) : null}
        {race ? (
          <div className={detailsStyle}>
            Race: <span className="font-normal">{race}</span>
          </div>
        ) : null}
        {clinicalNotesStore.isClinicalNoteOwner &&
        clinicalNotesStore.clinicalNote?.status !== 'FINAL' ? (
          <div
            onClick={handleAddMeasureClick}
            className={clsx(
              detailsStyle,
              'cursor-pointer text-primary hover:text-primary hover:opacity-50'
            )}
          >
            {clinicalNotesStore.diagnosis ? (
              <>
                Diagnosis:
                <span className="font-normal">
                  {' '}
                  {clinicalNotesStore.diagnosis}
                </span>
              </>
            ) : (
              '+ Add a diagnosis code'
            )}
          </div>
        ) : (
          <>
            {clinicalNotesStore.diagnosis ? (
              <div className={clsx(detailsStyle)}>
                Diagnosis:
                <span className="font-normal">
                  {' '}
                  {clinicalNotesStore.diagnosis}
                </span>
              </div>
            ) : null}
          </>
        )}
      </div>
      <DiagnosisModal
        isOpen={showModal}
        onClose={toggleModal}
        isLoading={clinicalNotesStore.isSavingDiagnosis}
      />
    </Pannel>
  )
})
