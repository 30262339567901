import { Icon } from '@ps-ui/components'
import * as React from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { ModalWindow } from 'src/components/molecules'
import { ReactComponent as CloseIcon } from 'src/images/close-icon.svg'
import { ReactComponent as DuplicateIcon } from 'src/images/duplicate-icon.svg'
import { ClinicalNoteElementTypes } from 'src/schema-types'
import { getArrayDefaultValues } from '../../utils'

interface InterventionContainerProps {
  name: string
  type: ClinicalNoteElementTypes
  render: (props: { name: string }) => React.ReactNode
}

export const InterventionContainer: React.FC<InterventionContainerProps> = ({
  name,
  type,
  render,
}) => {
  const { control, setValue } = useFormContext()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false)
  const [indexToDelete, setIndexToDelete] = React.useState<null | number>(null)
  const [copyValues, setCopyValues] = React.useState<Partial<unknown> | null>(
    null
  )

  const { addToast } = useToasts()

  const { append, remove, fields } = useFieldArray({
    name: `${name}.measures`,
    control,
  })

  const allFieldsValues = useWatch({
    name: `${name}.measures`,
    control,
  })

  const onOpenDeleteModal = (fieldIndex: number) => {
    setIndexToDelete(fieldIndex)
    setIsDeleteModalOpen(true)
  }

  const onCloseDeleteModal = () => {
    setIsDeleteModalOpen(false)
    setIndexToDelete(null)
  }

  const onDeleteMeasure = () => {
    try {
      if (typeof indexToDelete === 'number') {
        remove(indexToDelete)
        addToast('Activity removed', { appearance: 'success' })
      }
    } catch (error) {
      addToast('Cannot remove activity', { appearance: 'error' })
    } finally {
      onCloseDeleteModal()
    }
  }

  const duplicateMeasure = (indexField: number) => {
    if (allFieldsValues?.[indexField]) {
      append({
        ...allFieldsValues[indexField],
      })

      setCopyValues({
        ...allFieldsValues[indexField],
      })
    } else {
      addToast('Cannot copy activity', { appearance: 'error' })
    }
  }

  const addMeasure = () => {
    const defaultValues = getArrayDefaultValues(type) as Partial<unknown>
    append(defaultValues)
  }

  React.useEffect(() => {
    if (!!copyValues) {
      console.log({ copyValues })
      Object.entries(copyValues).forEach(([key, value]) => {
        setValue(`${name}.measures.${fields.length - 1}.${key}`, value)
      })
      setCopyValues(null)
    }
  }, [copyValues, setCopyValues, setValue, fields, name])

  return (
    <div className="flex flex-col">
      {!!fields.length && (
        <div>
          {fields.map((field, index) => (
            <div
              className="flex flex-col border-b-2 first:pt-0 pt-8"
              key={field.id}
            >
              <div className="flex justify-between mb-8">
                <h2 className="text-xl font-medium text-[#828282]">
                  Activity {index + 1}
                </h2>
                <div className="grid grid-flow-col gap-2 items-center">
                  <div
                    className="cursor-pointer"
                    onClick={() => duplicateMeasure(index)}
                  >
                    <DuplicateIcon />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => onOpenDeleteModal(index)}
                  >
                    <Icon Component={CloseIcon} size="xs" color="subtext" />
                  </div>
                </div>
              </div>
              {render({ name: `${name}.measures.${index}` })}
            </div>
          ))}
        </div>
      )}
      <div className="py-5">
        <button
          className="inline-flex justify-center items-center space-x-2 rounded-lg border font-semibold focus:outline-none px-3 py-2 leading-6 border-green-200 bg-green-200 text-green-700 hover:text-green-700 hover:bg-green-300 hover:border-green-300 focus:ring focus:ring-green-500 focus:ring-opacity-50 active:bg-green-200"
          type="button"
          onClick={addMeasure}
        >
          + Add activity
        </button>
      </div>

      <ModalWindow
        isOpen={isDeleteModalOpen}
        onCloseModal={onCloseDeleteModal}
        onPrimaryButtonClick={onDeleteMeasure}
        textPrimaryButton="Confirm"
        textSecondaryButton="Cancel"
        onSecondaryButtonClick={onCloseDeleteModal}
        modalSteps={[
          {
            header: 'Remove Activity',
            content: (
              <p className="text-center">
                Are you sure you want to remove this activuty from patient
                evaluation?
              </p>
            ),
          },
        ]}
      />
    </div>
  )
}
