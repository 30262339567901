import clsx from 'clsx'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { DialogModal } from 'src/components/molecules/DialogModal'
import { Loading } from 'src/components/organisms/AddPatientForm/styles'
import { useStore } from 'src/stores/store'

interface CopyClinicalNoteModalProps {
  isOpen: boolean
  onClose: () => void
}

export const CopyClinicalNoteModal: React.FC<CopyClinicalNoteModalProps> = observer(
  ({ isOpen, onClose }) => {
    const history = useHistory()
    const { patientStore, clinicalNotesStore } = useStore()
    const { addToast } = useToasts()
    const [selectedClinicalNote, setSelectedClinicalNote] = React.useState<
      string | null
    >()

    const copyClinicalNote = async () => {
      if (!selectedClinicalNote || !clinicalNotesStore.clinicalNote) {
        return
      }
      try {
        await clinicalNotesStore.copyClinicalNote(
          selectedClinicalNote,
          clinicalNotesStore.clinicalNote.id
        )

        if (clinicalNotesStore.errorCopyingCN) {
          throw new Error(clinicalNotesStore.errorCopyingCN)
        }

        addToast(`The Clinical Note copy was successfully created`, {
          appearance: 'success',
        })
        history.push(`/v2/${clinicalNotesStore.clinicalNote?.id}`)
      } catch (error) {
        addToast(`Cannot copy Clinical Note`, {
          appearance: 'error',
          onDismiss: clinicalNotesStore.cleanError,
        })
      } finally {
        setSelectedClinicalNote(null)
        onClose()
      }
    }

    const prevClinicalNotes = () => {
      if (
        patientStore.patientProfile &&
        patientStore.patientProfile.clinicalNotesModular
      ) {
        return patientStore.patientProfile.clinicalNotesModular
          .slice()
          .sort(
            (a, b) =>
              dayjs(b?.appointment.dateTime).valueOf() -
              dayjs(a?.appointment.dateTime).valueOf()
          )
          .filter((note) => note?.id !== clinicalNotesStore.clinicalNote?.id)
      } else {
        if (clinicalNotesStore.clinicalNote) {
          patientStore.fetchById(
            clinicalNotesStore.clinicalNote.patient?.id || ''
          )
        }
      }
      return []
    }

    return (
      <DialogModal
        headerText="Import data from previous note"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        isPrimaryButtonLoading={clinicalNotesStore.copyingCN}
        primaryButtonCallback={copyClinicalNote}
        isPrimaryButtonDisabled={!selectedClinicalNote}
        open={isOpen}
        setOpen={() => {
          onClose()
          history.push(`/v2/${clinicalNotesStore.clinicalNote?.id}`)
        }}
        bodyContent={
          <>
            <p className="text-sm text-gray-500 mb-5">
              Would you like to carry forward the data from your previous note?
            </p>
            <ul className="overflow-y-scroll max-h-[450px]">
              {patientStore.fetching && <Loading />}
              {prevClinicalNotes().map((elem) => (
                <label
                  onClick={() => setSelectedClinicalNote(elem?.id)}
                  className={clsx(
                    'font-medium flex justify-start items-center space-x-2 cursor-pointer capitalize rounded-lg p-5',
                    'active:bg-primary active:bg-opacity-20',
                    'hover:bg-primary hover:bg-opacity-10'
                  )}
                >
                  <div
                    className={clsx(
                      'w-3 h-3 rounded-full border mr-3',
                      selectedClinicalNote === elem?.id
                        ? 'bg-primary border-primary'
                        : 'border-gray-500'
                    )}
                  />
                  <div className="flex justify-between w-full">
                    <span>{elem?.template?.name}</span>
                    <span>
                      {elem?.appointment.dateTime &&
                        dayjs(elem.appointment.dateTime).format('MM/DD/YYYY')}
                    </span>
                  </div>
                </label>
              ))}
            </ul>
          </>
        }
      />
    )
  }
)
