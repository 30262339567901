import { useMutation } from '@apollo/react-hooks'
import {
  Button,
  Flex,
  Icon,
  TextField,
  Typography,
  validEmail,
} from '@ps-ui/components'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { useFormState } from 'react-use-form-state'
import { Message } from 'semantic-ui-react'
import {
  PasswordResetResponse,
  RESET_PASSWORD,
} from 'src/gql/mutations/resetPassword'
import { QUERY_OTP } from 'src/gql/queries/queryOtp'
import { ReactComponent as ForgotPasswordIcon } from 'src/images/forgot.svg'
import { OtpResponse } from 'src/schema-types'
import { publicApolloClient } from 'src/settings/apolloClient'
import { useStore } from 'src/stores/store'
import { requiredField, validPassword } from 'src/utils/fieldValidators'
import { formHasEmptyValues } from 'src/utils/formValidators'
import styled from 'styled-components/macro'

interface ResetFields {
  password: string
  username?: string
  otp?: string
}

const Card = styled.div`
  width: 400px;
  padding: 20px;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`

export const ResetPassword = observer(() => {
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const { authStore } = useStore()

  const [formState, { text, email, password }] = useFormState<ResetFields>()
  const history = useHistory()
  const { addToast } = useToasts()

  const [
    resetPassword,
    { data: resetData, loading: resetting, error: resetError },
  ] = useMutation<PasswordResetResponse>(RESET_PASSWORD, {
    client: publicApolloClient,
  })

  const resetResult = resetData?.resetPassword

  useEffect(() => {
    if (authStore.authError) {
      addToast('There was an error with auto login. Please login manually', {
        appearance: 'error',
      })
    }
  }, [authStore.authError, addToast])

  useEffect(() => {
    if (resetResult?.success) {
      if (formState.values.username && formState.values.password) {
        authStore.login({
          username: formState.values.username,
          password: formState.values.password,
        })
      }
    }
    // Will clear error message whe starting to type an email again
    if (formState.values.username?.length) {
      setError('')
    }
  }, [
    resetResult,
    authStore,
    formState.values.username,
    formState.values.password,
  ])

  const onGetOtp = async () => {
    setLoading(true)
    const { errors } = await publicApolloClient.query<OtpResponse>({
      query: QUERY_OTP,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        input: { username: formState.values.username },
      },
    })
    if (errors?.length) {
      setError(errors[0]?.message)
      formState.resetField('username')
    } else {
      setStep(1)
    }
    setLoading(false)
  }

  const onResetPassword = () => {
    resetPassword({
      variables: {
        input: { ...formState.values },
      },
    })
  }

  const renderSendOtp = () => (
    <>
      <Typography as="h2" fontWeight="500" margin="2 0 0 0">
        Forgot Password?
      </Typography>
      <Typography as="h4" align="center" margin="3 0 0 0">
        Please enter the email associated with your account.
      </Typography>
      <Flex width="100%" margin="2 0 0 0">
        <TextField
          required
          label="Email"
          placeholder="johndoe@email.com"
          error={formState.errors.username}
          inputClassName="fs-mask"
          {...email({
            name: 'username',
            validate: validEmail,
            validateOnBlur: true,
          })}
        />
      </Flex>
      <Flex flexDirection="column" width="100%" justifyContent="flex-end">
        <Button
          flexible
          loading={loading}
          onClick={onGetOtp}
          variant="filled"
          color="primary"
          margin="3 0 0 0"
          disabled={!formState.values.username || loading}
        >
          Send Password
        </Button>
        <Button
          flexible
          color="subtext"
          variant="default"
          onClick={() => history.push('/login')}
          margin="1 0 0 0"
        >
          Back to Login
        </Button>
      </Flex>
      {error && (
        <Message negative>
          {error || 'This email is not associated with an account.'}
        </Message>
      )}
    </>
  )

  const renderResetPassword = () => (
    <>
      <Typography as="h2" fontWeight="500" margin="2 0 0 0">
        Reset Password
      </Typography>
      <Typography as="h4" align="center" margin="3 0 0 0">
        Please reset your password using the reset code sent to the email
        address you provided.
      </Typography>
      <Flex flexDirection="column" width="100%" margin="2 0 0 0">
        <TextField
          required
          disabled
          label="Email"
          placeholder="johndoe@email.com"
          inputClassName="fs-mask"
          // No error field due to the field being disabled
          {...email({
            name: 'username',
            validate: requiredField,
            validateOnBlur: true,
          })}
        />
        <TextField
          required
          margin="2 0 0 0"
          label="Reset Code"
          formatType="Number_Input"
          placeholder="5786991"
          inputClassName="fs-mask"
          {...text({
            name: 'otp',
            validate: requiredField,
            validateOnBlur: true,
          })}
        />
        <TextField
          required
          margin="2 0 0 0"
          label="New Password"
          error={formState.errors.password}
          placeholder="*****"
          inputClassName="fs-mask"
          {...password({
            name: 'password',
            validate: validPassword,
            validateOnBlur: true,
          })}
        />
        <Typography as="h5" align="left" margin="0.5 0 4 0" color="subtext">
          minimum of 6 characters &amp; must contain at least 1 uppercase letter
        </Typography>
      </Flex>
      <Flex width="100%" justifyContent="flex-end">
        <Button
          flexible
          loading={resetting || authStore.authing}
          disabled={
            formHasEmptyValues(formState.values, [
              'username',
              'otp',
              'password',
            ]) ||
            resetting ||
            authStore.authing
          }
          onClick={onResetPassword}
          variant="filled"
          color="primary"
          margin="3 0 0 0"
        >
          Reset Password
        </Button>
      </Flex>
      {resetResult && !resetResult?.success && (
        <Message negative>{resetResult?.details}</Message>
      )}
      {resetError?.graphQLErrors.length && (
        <Message negative>{resetError.graphQLErrors[0].message}</Message>
      )}
    </>
  )

  return (
    <Flex width="100vw" height="100vh" flexDirection="column">
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <Card>
        <Flex flexDirection="column" width="100%" alignItems="center">
          <Icon Component={ForgotPasswordIcon} size="xl" color="primary" />
          {step === 0 && renderSendOtp()}
          {step === 1 && renderResetPassword()}
        </Flex>
      </Card>
    </Flex>
  )
})
