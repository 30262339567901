import { makeAutoObservable, runInAction } from 'mobx'
import SecureLS from 'secure-ls'
import { SESSION_STORAGE } from 'src/constants'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { LoginInput } from 'src/schema-types'
import { AuthServices } from 'src/services/auth'
import { RootStore } from 'src/stores/store'

export const secureLocalStorage =
  process.env.NODE_ENV === 'development'
    ? new SecureLS({
        encodingType: 'aes',
        encryptionSecret: 'cy-testing',
      })
    : new SecureLS({
        encodingType: 'aes',
      })

export class AuthStore {
  authing = false
  authed = false
  authError = ''

  timeoutRef: NodeJS.Timeout | undefined // we don't observe this variable, it's strictly for refresh token use

  rootStore: RootStore // reference root store so we can access other stores (like profile)
  services: AuthServices // reference to auth specific services

  constructor(rootStore: RootStore, services: AuthServices) {
    /*
      in mobx, annotations like @observable are usually used
      to mark "observed state" , aka variables that trigger rerenders
      on change. makeAutoObservable does this automatically so our code is a bit cleaner.
      Notice we choose not to observe changes in rootStore and timeoutRef.
    */
    makeAutoObservable(this, { rootStore: false, timeoutRef: false })
    this.rootStore = rootStore
    this.services = services
    this.authed = Boolean(localStorage.getItem(SESSION_STORAGE.auth))
  }

  async login(input: LoginInput) {
    try {
      runInAction(() => {
        this.authing = true
      })
      const tokens = await this.services.login(input)
      secureLocalStorage.set(SESSION_STORAGE.token, tokens.AccessToken)
      secureLocalStorage.set(SESSION_STORAGE.refreshToken, tokens.RefreshToken)
      secureLocalStorage.set(
        SESSION_STORAGE.expiresAt,
        `${Date.now() + tokens.ExpiresIn * 1000}`
      )
      this.startRefreshTokenCycle()
      await this.rootStore.profileStore.fetch() // fetch profile after we get access tokens
      runInAction(() => {
        this.authed = true
      })
      localStorage.setItem(SESSION_STORAGE.auth, 'true')
    } catch (err) {
      runInAction(() => {
        this.authError = (err as Error).message
      })
      writeLog((err as Error).message, true)
    } finally {
      runInAction(() => {
        this.authing = false
      })
    }
  }

  logout() {
    const allKeys = secureLocalStorage.getAllKeys()
    console.log('logging out')
    allKeys.forEach((key) => {
      if (key !== 'demographicsLayouts') {
        secureLocalStorage.remove(key)
      }
    })
    sessionStorage.clear()
    localStorage.removeItem('ps-auth')
    runInAction(() => {
      this.authError = ''
      this.authed = false
      this.authing = false
    })
  }

  /******** Refresh token stuff lives below. Doesn't make sense to keep it separate from auth *********/

  private startRefreshTokenCycle() {
    const refreshToken = secureLocalStorage.get(SESSION_STORAGE.refreshToken)
    const expiresAt = secureLocalStorage.get(SESSION_STORAGE.expiresAt)

    if (isNaN(Number(expiresAt))) {
      return
    }
    const exp = parseInt(expiresAt)
    this.timeoutRef = setTimeout(
      this.refreshAccessToken(refreshToken),
      exp - Date.now() - 1000 * 60 //refresh 1 minute before invalidation
    )
  }

  private refreshAccessToken = (refreshToken: string) => async () => {
    try {
      const tokens = await this.services.getToken(refreshToken)
      secureLocalStorage.set(SESSION_STORAGE.token, tokens.AccessToken)
      secureLocalStorage.set(SESSION_STORAGE.refreshToken, tokens.RefreshToken)
      secureLocalStorage.set(
        SESSION_STORAGE.expiresAt,
        Date.now() + tokens.ExpiresIn * 1000
      )
      this.timeoutRef = setTimeout(
        this.refreshAccessToken(tokens.RefreshToken),
        (tokens.ExpiresIn - 60) * 1000
      )
    } catch (err) {
      writeLog((err as Error).message)
      this.timeoutRef && clearTimeout(this.timeoutRef)
    }
  }
}
