import { observer } from 'mobx-react'
import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { querySignature } from 'src/gql/queries/querySignature'
import { useStore } from 'src/stores/store'
import { formatDate } from 'src/utils/formatDate'

export const Signature: React.FC<{ name: string }> = observer(({ name }) => {
  const { control } = useFormContext()
  const {
    profileStore: { profile },
  } = useStore()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        const fullName = value ? value.split('|')[2] : ''
        const date = value ? value.split('|')[1] : ''
        const formatedDate = date ? formatDate(date, 'MM/DD/YY HH:MM') : ''
        return (
          <div className="flex flex-col">
            <div className="flex mb-3 items-center">
              <div
                className="w-5 h-5 border p-1 cursor-pointer mr-2"
                onClick={async () => {
                  if (!value) {
                    const signature = await querySignature()
                    const sigString = `${signature.ip}|${signature.dateTime}|${profile?.firstName} ${profile?.lastName}`
                    onChange(sigString)
                  } else {
                    onChange('')
                  }
                }}
              >
                {!!value && <div className="w-full h-full bg-primary" />}
              </div>
              <span>Apply Signature:</span>
              <div className="flex items-center ml-1 border p-1 min-w-[150px] bg-white min-h-[30px]">
                {fullName}
              </div>
            </div>
            <div className="flex items-center ml-7">
              <span>Signature Date:</span>
              <div className="flex items-center ml-1 border p-1 min-w-[150px] bg-white min-h-[30px]">
                {formatedDate}
              </div>
            </div>
          </div>
        )
      }}
    />
  )
})
