export const coreButtons = [
  {
    label: 'Head',
  },
  {
    label: 'Neck',
  },
  {
    label: 'Chest',
  },
  {
    label: 'Abdomen',
  },
  {
    label: 'Pelvis / Groin',
  },
]

export const armButtons = [
  {
    label: 'Shoulder',
  },
  {
    label: 'Elbow',
  },
  {
    label: 'Wrist',
  },
  {
    label: 'Hand',
  },
]

export const legButtons = [
  {
    label: 'Hip',
  },
  {
    label: 'Upper Leg',
  },
  {
    label: 'Knee',
  },
  {
    label: 'Lower Leg',
  },
  {
    label: 'Ankle',
  },
  {
    label: 'Foot',
  },
]

export type BodyPartsType =
  | 'neck'
  | 'hip'
  | 'knee'
  | 'ankle'
  | 'foot'
  | 'shoulder'
  | 'elbow'
  | 'wrist'
  | 'hand'

export const rangeOfMotionByBodyParts: Record<BodyPartsType, string[]> = {
  hip: [
    'flexion',
    'extension',
    'hyperextension',
    'abduction',
    'adduction',
    'lateral rotation',
    'medial rotation',
  ],
  knee: ['flexion', 'extension'],
  ankle: ['plantar flexion', 'dorsiflexion'],
  foot: ['inversion', 'eversion'],
  shoulder: [
    'flexion',
    'extension',
    'abduction',
    'adduction',
    'lateral rotation',
    'medial rotation',
  ],
  elbow: ['flexion', 'extension', 'pronation', 'supination'],
  wrist: ['flexion', 'extension', 'abduction', 'adduction'],
  hand: [],
  neck: [],
}

export const allRangeOfMotionByBodyParts: string[] = [
  'flexion',
  'extension',
  'abduction',
  'adduction',
  'hyperextension',
  'lateral rotation',
  'medial rotation',
  'plantar flexion',
  'dorsiflexion',
  'inversion',
  'eversion',
  'pronation',
  'supination',
]

export const strengthUpperExtremeties: BodyPartsType[] = [
  'shoulder',
  'elbow',
  'wrist',
  'hand',
]

export const rangeOfMotionUpperExtremeties: BodyPartsType[] = [
  'shoulder',
  'elbow',
  'wrist',
  'hand',
  'neck',
]
export const rangeOfMotionLowerExtremeties: BodyPartsType[] = [
  'hip',
  'knee',
  'ankle',
  'foot',
]
