import clsx from 'clsx'
import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { handleSingleOrMultiValue } from 'src/pages/ClinicalNotesv2/utils'

interface OptionsRoundedListProps {
  name: string
  isMulti?: boolean
  breaks?: number[]
  options: {
    value: string
    label: string
  }[]
}

export const HorizontalRoundedOptionsList: React.FC<OptionsRoundedListProps> =
  ({ name, options, isMulti = false, breaks }) => {
    const { control } = useFormContext()
    const checkActive = (value: string | string[], option: string) => {
      return Array.isArray(value) ? value.includes(option) : value === option
    }
    return (
      <div className="flex flex-wrap">
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => {
            let breaksCount = 0
            return (
              <>
                {options.map((option, index) => {
                  let showBreak = breaks && index === breaks[breaksCount]
                  showBreak && breaksCount++
                  return (
                    <>
                      <div
                        key={option.value}
                        onClick={() =>
                          handleSingleOrMultiValue({
                            isMulti,
                            value,
                            option: option.value,
                            handleChange: onChange,
                          })
                        }
                        className={clsx(
                          'cursor-pointer flex justify-center items-center rounded-full px-3 py-2 border border-gray-300 font-medium mx-2 last:mr-0 mt-2',
                          checkActive(value, option.value)
                            ? 'text-gray-900 bg-green-100'
                            : 'text-gray-400 hover:text-gray-900 hover:bg-green-100 active:bg-transparent'
                        )}
                      >
                        {option.label}
                      </div>
                      {showBreak && (
                        <div style={{ flexBasis: '100%', height: 0 }} />
                      )}
                    </>
                  )
                })}
              </>
            )
          }}
        />
      </div>
    )
  }
