import { Icon } from '@ps-ui/components'
import clsx from 'clsx'
import * as React from 'react'
import Lottie from 'react-lottie'
import loadingAnimation from 'src/animations/saving-progress.json'
import { ReactComponent as CheckIcon } from 'src/images/check_icon.svg'

interface SavingIndicatorProps {
  isSaving: boolean
}

export const SavingIndicator: React.FC<SavingIndicatorProps> = ({
  isSaving,
}) => {
  const [status, setStatus] = React.useState<'idle' | 'saving' | 'saved'>(
    'idle'
  )

  const updateStatus = React.useCallback(() => {
    setStatus((prev) => {
      let value: 'idle' | 'saving' | 'saved' = 'idle'
      if (prev === 'idle') {
        value = 'saving'
      } else if (prev === 'saving') {
        value = 'saved'
      }
      return value
    })
  }, [setStatus])

  React.useEffect(() => {
    if (isSaving && status === 'idle') {
      updateStatus()
    } else if (!isSaving && status === 'saving') {
      updateStatus()
    }
  }, [isSaving, updateStatus, status])

  React.useEffect(() => {
    if (status === 'saved') {
      setTimeout(() => {
        updateStatus()
      }, 500)
    }
  }, [status, updateStatus])

  return (
    <div
      className={clsx(
        'z-100 fixed bottom-5 left-0 right-0 flex justify-center w-full opacity-100',
        status === 'idle' && 'sr-only'
      )}
    >
      <div
        className={clsx(
          'px-5 py-4 rounded-md bg-black text-gray-400 flex justify-center items-center relative',
          status === 'idle' && 'sr-only'
        )}
      >
        {status === 'saving' && (
          <>
            <Lottie
              style={{ transform: 'scale(4)' }}
              options={{
                loop: true,
                autoplay: true,
                animationData: loadingAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid meet',
                },
              }}
              height={20}
              width={50}
            />
            Saving changes...
          </>
        )}
        {status === 'saved' && (
          <>
            <Icon Component={CheckIcon} size="xs" />
            <span className="ml-3">Changes saved</span>
          </>
        )}
      </div>
    </div>
  )
}
