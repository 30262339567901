import {
  LazyQueryHookOptions,
  QueryLazyOptions,
  useLazyQuery,
} from '@apollo/react-hooks'
import { DocumentNode } from 'graphql'
import { useEffect, useState } from 'react'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { PaginatedList } from 'src/schema-types'

const defaultOptions = {
  fetchPolicy: 'no-cache',
  variables: {
    pagination: {
      pageSize: 50,
    },
  },
}

type OverrideOptions = {
  clearResults?: boolean
}

export function usePaginatedQuery<T>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<T, Record<string, any>>
) {
  const [lazyQuery, { data, loading, error }] = useLazyQuery<T>(query, options)
  const [results, setResults] = useState<T[]>([])

  const loadResults = (
    opts: QueryLazyOptions<Record<string, any>> & OverrideOptions = {}
  ) => {
    if (opts.clearResults) setResults([])
    lazyQuery({ ...defaultOptions, ...opts })
  }

  useEffect(() => {
    if (data) setResults(results.concat([data]))
    // eslint-disable-next-line
  }, [data])

  if (error) {
    writeLog(error.message)
  }
  return {
    loadResults,
    results,
    loading,
    error,
  }
}

export function flattenResults<T>(results: T[], key: keyof T) {
  return results
    .map((page) => (page[key] as unknown as PaginatedList).entities)
    .flat()
}
