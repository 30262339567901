import clsx from 'clsx'
import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { BinaryButtons } from 'src/pages/ClinicalNotesv2/components/fields/BinaryButtons'
import { BodyChartInput } from 'src/pages/ClinicalNotesv2/components/fields/BodyChartInput'
import { ClinicalNotesInput } from 'src/pages/ClinicalNotesv2/components/fields/Input'
import { hasLeftOrRight } from 'src/pages/ClinicalNotesv2/utils'
import {
  allRangeOfMotionByBodyParts,
  BodyPartsType,
  rangeOfMotionByBodyParts,
} from 'src/pages/ClinicalNotesv2/utils/bodyChartButtonsArray'

interface RangeOfMotionBodyPartInputProps {
  name: string
  field: any
  enabledBodyParts: string[]
}

export const RangeOfMotionBodyPartInput: React.FC<RangeOfMotionBodyPartInputProps> =
  ({ name, enabledBodyParts, field }) => {
    const { control } = useFormContext()

    const bodyValue = useWatch({
      name: `${name}.body`,
      control,
    })

    return (
      <div className="flex flex-col">
        <div className="flex flex-col">
          <BodyChartInput
            enabledBodyParts={enabledBodyParts}
            name={`${name}.body`}
            defaultValue={field?.body}
          />
          <BinaryButtons
            name={`${name}.side`}
            rangeOfMotionButtons={true}
            defaultValue={field?.side}
            isVisible={hasLeftOrRight(field?.body)}
            options={[
              { value: 'left', label: 'Left' },
              { value: 'right', label: 'Right' },
            ]}
          />
        </div>
        <div className="grid gap-3 mt-4 max-w-max">
          {allRangeOfMotionByBodyParts.map((elem) => {
            const isVisible =
              bodyValue &&
              rangeOfMotionByBodyParts[
                bodyValue.toLowerCase() as BodyPartsType
              ].includes(elem)
            return (
              <div
                className={clsx(
                  'grid grid-flow-col gap-2 justify-start items-center',
                  !isVisible && 'hidden'
                )}
                key={elem}
              >
                {isVisible ? (
                  <label className="font-bold capitalize w-64">{elem}</label>
                ) : null}
                <ClinicalNotesInput
                  className="w-40"
                  type="number"
                  name={`${name}.properties.${elem}`}
                  isVisible={isVisible}
                  defaultValue={field?.properties?.[elem]}
                  withAmendIndicator
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
