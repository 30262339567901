import { Button, Icon, Typography } from '@ps-ui/components'
import clsx from 'clsx'
import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { ModalWindow } from 'src/components/molecules'
import { ReactComponent as Chevron } from 'src/images/chevron.svg'
import { useStore } from 'src/stores/store'
interface ModuleContainerTitleProps {
  title: string
  description?: string
  id: string
  isCollapsed: boolean
  onToggleState: (id: string) => void
  onClickContinue?: () => void
  withCollaped: boolean
  withRemove: boolean
  withContinue: boolean
}

export const ModuleContainerTitle: React.FC<ModuleContainerTitleProps> = ({
  id,
  title,
  isCollapsed = false,
  withCollaped,
  description,
  withRemove,
  withContinue,
  onToggleState,
  onClickContinue,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const {
    clinicalNotesStore: { updateVisibility, removeDefaultAnswers },
  } = useStore()

  return (
    <div className="flex justify-between items-center w-full border-b-2 py-5 px-6">
      <div className="flex">
        {withCollaped && (
          <button
            type="button"
            onClick={() => onToggleState(id)}
            className={clsx(
              'mr-3 transform transition-transform duration-200 focus:outline-none',
              !isCollapsed && 'rotate-180'
            )}
          >
            <Icon Component={Chevron} size="xs" color="subtext" />
          </button>
        )}
        <div className="flex flex-col">
          <Typography as="h2" fontWeight="700">
            {title}
          </Typography>
          {description && (
            <div className="mt-3">
              <Typography as="p" fontWeight="300">
                {description}
              </Typography>
            </div>
          )}
        </div>
      </div>
      {withRemove && (
        <button
          className="flex items-center font-medium text-red-600 text-center rounded-lg p-3 border-2 border-transparent hover:bg-error hover:text-white"
          onClick={() => setIsModalOpen(true)}
          type="button"
        >
          <FaTimes className="mr-1" /> Remove Module
        </button>
      )}
      {withContinue && (
        <Button
          variant="filled"
          onClick={() => onClickContinue && onClickContinue()}
          type="button"
        >
          Save and Continue Evaluation
        </Button>
      )}
      <ModalWindow
        isOpen={isModalOpen}
        onCloseModal={() => {
          setIsModalOpen(false)
        }}
        onPrimaryButtonClick={() => {
          setIsModalOpen(false)
          removeDefaultAnswers(id)
          updateVisibility([id], 'hide')
        }}
        textPrimaryButton="Confirm"
        textSecondaryButton="Cancel"
        onSecondaryButtonClick={() => {
          setIsModalOpen(false)
        }}
        modalSteps={[
          {
            header: 'Remove module',
            content: (
              <div className="flex flex-col">
                <p>
                  Are you sure you want to remove this module from patient's
                  evaluation
                </p>
              </div>
            ),
          },
        ]}
      />
    </div>
  )
}
