import { Option } from '@ps-ui/components'
import clsx from 'clsx'
import * as React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

export const RangeInput: React.FC<{
  name: string
  options?: Option[]
}> = ({ name, options }) => {
  const { control } = useFormContext()

  const realValue = useWatch({
    control,
    name,
  })

  const renderOptions: Option[] = React.useMemo(() => {
    return (
      options ||
      [...Array(11).keys()].map((value) => ({
        label: String(value),
        value: String(value),
      }))
    )
  }, [options])

  return (
    <div className="flex justify-between max-w-7xl">
      <Controller
        name={name}
        control={control}
        defaultValue={realValue}
        render={({ field: { value, onChange } }) => (
          <>
            {renderOptions.map((option) => (
              <div
                key={option.value}
                onClick={() => onChange(option.value)}
                className={clsx(
                  'cursor-pointer flex justify-center items-center rounded-full px-3 py-2',
                  value === option.value
                    ? 'text-indigo-500 bg-indigo-100'
                    : 'text-gray-500 hover:text-indigo-500 hover:bg-indigo-100 active:bg-transparent'
                )}
              >
                {option.label}
              </div>
            ))}
          </>
        )}
      />
    </div>
  )
}
