import React, { useEffect, useState } from 'react'
import { useStore } from 'src/stores/store'

export const AuthorizationNumbers: React.FC = () => {
  const { billingStore } = useStore()
  const labelClass = 'flex justify-between pb-2 font-medium'
  const containerClass = 'mr-6 flex flex-col w-full first:pr-6'
  const [compValue, setCompValue] = useState('')
  const [authValue, setAuthValue] = useState('')

  // Set values on page load
  useEffect(() => {
    if (billingStore.authorizationNumber) {
      setAuthValue(billingStore.authorizationNumber)
    }
    if (billingStore.workersCompNumber) {
      setCompValue(billingStore.workersCompNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInput = async (type: string, val: string) => {
    switch (type) {
      case 'authNumber':
        await billingStore.saveAuthorizationNumber(val)
        break
      case 'compNumber':
        await billingStore.saveWorkersCompNumber(val)
        break
    }
  }
  return (
    <div className="flex w-full justify-between pl-6">
      <div className={containerClass}>
        <label className={labelClass}>
          <span>Authorization Number</span>
          <span className="text-gray-500">Optional</span>
        </label>
        <input
          type="text"
          className="rounded-lg disabled:bg-gray-100"
          placeholder="Insert a Number"
          value={authValue}
          onChange={(e) => setAuthValue(e.target.value)}
          onBlur={(e) => handleInput('authNumber', e.target.value)}
          disabled={!billingStore.allowChargesEdit}
        />
      </div>
      <div className={containerClass}>
        <label className={labelClass}>
          <span>Workers Comp Number</span>
          <span className="text-gray-500">Optional</span>
        </label>
        <input
          type="text"
          className="rounded-lg disabled:bg-gray-100"
          placeholder="Insert a Number"
          value={compValue}
          onChange={(e) => setCompValue(e.target.value)}
          disabled={!billingStore.allowChargesEdit}
          onBlur={(e) => handleInput('compNumber', e.target.value)}
        />
      </div>
    </div>
  )
}
