import { gql } from 'apollo-boost'
import { Query } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface QueryReferringDoctorsByPracticeId {
  getPractice: Query['getPractice']
}

export const GET_REFERRING_DOCTOR = gql`
  query getReferringDoctorByPracticeId($id: ID!) {
    getPractice(id: $id) {
      id
      referringDoctors {
        name
        NPI
        isActive
      }
    }
  }
`

export const queryReferringDoctors = async (practiceId: string) => {
  const response = await apolloClient.query<QueryReferringDoctorsByPracticeId>({
    query: GET_REFERRING_DOCTOR,
    fetchPolicy: 'network-only',
    variables: {
      id: practiceId,
    },
  })

  return response.data.getPractice?.referringDoctors
}
