import * as React from 'react'
import { Dropdown } from 'src/pages/ClinicalNotesv2/components/fields/Dropdown'
import { ClinicalNotesInput } from 'src/pages/ClinicalNotesv2/components/fields/Input'
import { OptionList } from 'src/pages/ClinicalNotesv2/components/fields/interventions/OptionList'
import { InputContainer } from 'src/pages/ClinicalNotesv2/components/InputContainer'
import { InterventionContainer } from 'src/pages/ClinicalNotesv2/components/InterventionContainer'
import { Row } from 'src/pages/ClinicalNotesv2/components/layoutForm/Row'
import * as options from 'src/pages/ClinicalNotesv2/types/intervention/stairTraining'
import { ClinicalNoteElementTypes } from 'src/schema-types'

export const StairTrainingElem: React.FC<{ name: string }> = ({ name }) => {
  return (
    <div className="flex flex-col">
      <Row columns={1}>
        <OptionList
          label="Type"
          coreName={name}
          name="type"
          options={options.type}
          isMulti
        />
      </Row>
      <Row columns={2}>
        <Dropdown
          name={`${name}.repetitions`}
          label="Repetitions"
          options={options.repetitions}
        />
        <InputContainer name={`${name}.time`} label="Time">
          <ClinicalNotesInput
            name={`${name}.time`}
            type="time"
            isIntervention
          />
        </InputContainer>
      </Row>
      <Row columns={1}>
        <OptionList
          coreName={name}
          name="assistive"
          label="Assistive Device"
          options={options.assistiveDevice}
        />
      </Row>
      <Row columns={2}>
        <OptionList
          coreName={name}
          label="Handrail"
          options={options.handrail}
          name="andrail"
        />
      </Row>
      <Row columns={1}>
        <OptionList
          coreName={name}
          name="support"
          label="Support"
          options={options.support}
          isMulti
        />
      </Row>
      <Row columns={1}>
        <OptionList
          coreName={name}
          name="tolerance"
          label="Tolerance"
          options={options.tolerance}
          isRounded
        />
      </Row>
      <Row columns={1}>
        <OptionList
          coreName={name}
          name="levelOfAssist"
          label="Level of Assist"
          options={options.levelOfAssist}
          isMulti
          isRounded
        />
      </Row>
    </div>
  )
}

export const StairTraining: React.FC<{ name: string }> = ({ name }) => {
  return (
    <InterventionContainer
      name={name}
      type={ClinicalNoteElementTypes.StairTraining}
      render={({ name }) => <StairTrainingElem name={name} />}
    />
  )
}
