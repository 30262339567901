import { Flex } from '@ps-ui/components'
import React from 'react'
import { HiddenInput } from 'src/components/atoms'
import { StoreEvents, StoreState } from 'src/state/store'
import { useStoreon } from 'storeon/react'
import styled from 'styled-components/macro'

interface ILocation {
  name: string
  id: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  chosenId: string | null
}

const LocationItem = styled.div`
  font-size: 22px;
  color: #202020;
  border: 1px solid #eff1f4;
  border-radius: 10px;
  padding: 12px 16px;
  margin: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
`

const Location = ({ name, id, handleChange, chosenId }: ILocation) => {
  return (
    <label style={{ height: 'auto' }}>
      <HiddenInput
        onChange={handleChange}
        className="vhidden"
        checked={!!(chosenId && chosenId === id)}
        type="radio"
        name="location"
        value={id}
      />
      <LocationItem>{name}</LocationItem>
    </label>
  )
}

export const PracticeLocations = () => {
  const { locations, dispatch, chosenLocationId } = useStoreon<
    StoreState,
    StoreEvents
  >('locations', 'chosenLocationId')

  const handleLocation = (id: string) => {
    dispatch('schediling-data/set-location', id)
  }

  return (
    <Flex
      width="100%"
      margin="24px 0 0 0"
      padding="10px 24px"
      style={{
        backgroundColor: '#fff',
        borderRadius: 10,
      }}
    >
      <Flex margin="0 -12px" flexWrap="wrap">
        {locations &&
          locations.map((location) => (
            <Location
              name={location.name}
              id={location.id}
              chosenId={chosenLocationId}
              handleChange={() => handleLocation(location.id)}
            />
          ))}
      </Flex>
    </Flex>
  )
}
