import { gql } from 'apollo-boost'
import { Mutation } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface DeleteDoctorNoteRequestInput {
  id: string
}

export interface DeleteDoctorNoteResponse {
  deleteDoctorNote: Mutation['deleteDoctorNote']
}

export const DELETE_DOCTOR_NOTE = gql`
  mutation deleteDoctorNote($id: ID!) {
    deleteDoctorNote(id: $id) {
      id
    }
  }
`
export const deleteDoctorNote = async (id: string) => {
  const response = await apolloClient.mutate<DeleteDoctorNoteResponse>({
    mutation: DELETE_DOCTOR_NOTE,
    variables: {
      id,
    },
  })

  return response
}
