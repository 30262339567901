import { gql } from 'apollo-boost'
import { PatientByIdQuery } from 'src/operations-types'
import { apolloClient } from 'src/settings/apolloClient'

// export interface QueryPatientByIdResponse {
//   getPatientProfile: PatientProfile
// }

export const QUERY_PATIENT_BY_ID = gql`
  query patientById($id: ID!, $pagination: PaginationInput) {
    getPatientProfile(id: $id) {
      id
      email
      phoneNumber
      firstName
      lastName
      dateOfBirth
      createdOn
      updatedOn
      insuranceBalance {
        insuranceBalance
        patientBalance
      }
      pastElegibilitychecks {
        createdOn
        status
        isComplete
        htmlResponse
        dateOfService
        errors
      }
      medicalInformation {
        label
        isCompoundField
        value
        valueNames {
          firstName
          middleName
          lastName
          suffix
        }
        valueContact {
          name
          phoneNumber
          email
          relationship
          dateOfBirth
        }
        valueMedication {
          name
          dosage
          frequency
          startDate
          endDate
          status
          comments
        }
        valueAddress {
          street1
          street2
          city
          state
          zip
        }
        valueEmployment {
          jobPosition
          employerName
          phoneNumber
        }
        valueCareTeamMember {
          firstName
          lastName
          type
          phone
          fax
          email
          status
        }
        valueInsurance {
          subscriberFirstName
          subscriber
          subscriberDob
          subscriberRelationship
          insuranceCompany
          insurancePolicyNumber
          insuranceGroupNumber
        }
        valueSurgery {
          type
          date
        }
        fieldTemplateGroups {
          id
          displayName
        }

        fieldTemplate {
          id
          defaultLabel
          elementType
          isRequired
          answerOptions
          defaultAnswer
        }
      }
      family {
        id
        name
        email
        phoneNumber
        createdOn
        updatedOn
        members {
          id
          firstName
          lastName
          dateOfBirth
        }
      }
      attachments {
        id
        fileName
        createdOn
        downloadUrl
        category
      }
      assignedForms {
        id
        form {
          id
          name
          createdOn
        }
        isComplete
        isSubmitted
        createdOn
        updatedOn
      }
      doctorNotes {
        id
        note
        createdOn
        updatedOn
        # TODO - Temporarily disable buggy query, to umblock clinical notes testing
        # createdBy {
        #   firstName
        # }
      }
      appointments(showAll: true, pagination: $pagination) {
        pageInfo {
          hasMoreResults
          currentPageNumber
          totalEntities
          pageSize
          lastPageNumber
        }
        entities {
          ... on Appointment {
            id
            dateTime
            status

            insuranceClaim {
              id
              status
              appointment {
                id
              }
            }
            practiceLocation {
              id
              name
            }
            clinicalNote {
              id
              status
            }
            clinicalNoteModular {
              id
              status
              template {
                id
                pages {
                  sections {
                    id
                    name
                  }
                }
              }
              updatedOn
              createdOn
            }

            appointmentType {
              id
              name
              description
            }
            practiceUsers {
              id
              firstName
              lastName
            }
            payments {
              id
            }
          }
        }
      }
      clinicalNotesModular {
        id
        status
        appointment {
          dateTime
        }
        answers {
          id
          # field {
          #   id
          #   label
          #   elementType
          # }
        }
        template {
          id
          name
          pages {
            sections {
              id
              name
            }
          }
        }
        updatedOn
        createdOn
      }
      communicationHistory {
        id
        createdOn
        messageDetails {
          ... on CommunicationJourmalEmaileMessage {
            to
            from
            subject
            body
            replyTo
          }
          ... on CommunicationJourmalSmsMessage {
            to
            body
          }
        }
      }
      payments {
        id
        method
        type
        value
        comment
        createdOn
        appointment {
          id
          dateTime
          appointmentType {
            id
            description
          }
          practiceUsers {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`

export const queryPatientById = async (
  id: string,
  appointmentsPage: number
) => {
  const response = await apolloClient.query<PatientByIdQuery>({
    query: QUERY_PATIENT_BY_ID,
    fetchPolicy: 'network-only',
    variables: {
      id,
      pagination: {
        pageNumber: appointmentsPage,
        pageSize: 100,
      },
    },
  })

  return response.data.getPatientProfile
}
