import { gql } from 'apollo-boost'
import { SaveClinicalNoteDiagnosisMutation } from 'src/operations-types'
import { apolloClient } from 'src/settings/apolloClient'

export const SAVE_CLINICAL_NOTE_DIAGNOSIS = gql`
  mutation saveClinicalNoteDiagnosis(
    $id: ID!
    $input: ClinicalNoteModularUpdateInput
  ) {
    updateClinicalNoteModular(id: $id, input: $input) {
      id
      icdCode {
        id
        code
        type
        description
      }
    }
  }
`

export const saveClinicalNoteDiagnosis = async (
  clinicalNoteId: string,
  icdCodeId: string
) => {
  const response = await apolloClient.mutate<SaveClinicalNoteDiagnosisMutation>(
    {
      mutation: SAVE_CLINICAL_NOTE_DIAGNOSIS,
      variables: {
        id: clinicalNoteId,
        input: {
          icdCodeId,
        },
      },
    }
  )

  if (response.errors?.length) {
    throw new Error(response.errors[0].message)
  }
  const icdCode = response.data?.updateClinicalNoteModular.icdCode
  return icdCode
}
