import styled from 'styled-components/macro'

export const PageHeadingStyles = styled.div`
  #back-button {
    color: ${(props) => props.theme.colors.lightGrey};
    background-color: transparent;
    border: none;
    outline: none;
  }
`
