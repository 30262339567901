import { gql } from 'apollo-boost'
import { Appointment, AppointmentCreateInput, Mutation } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface CreateAppointmentRequestInput {
  practiceLocationId?: string
  practiceUserIds?: string
  patientProfileId?: string
  dateTime?: Date
  appointmentTypeId?: string
  formId?: string
}

export interface CreateAppointmentRequest {
  createAppointment: Mutation['createAppointment']
}

export interface createAppointmentResponse {
  createAppointment: Appointment
}

export const CREATE_APPOINTMENT = gql`
  mutation createAppointment($input: AppointmentCreateInput) {
    createAppointment(input: $input) {
      id
      patientProfile {
        id
        firstName
        lastName
      }
    }
  }
`

export const createNewAppointment = async (input: AppointmentCreateInput) => {
  const result = await apolloClient.query<createAppointmentResponse>({
    query: CREATE_APPOINTMENT,
    variables: { input },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })
  if (result.errors?.length) {
    throw new Error(result.errors[0].message)
  }
  return result.data.createAppointment
}
