import gql from 'graphql-tag'

export const clinicalNoteModularAnswerFragment = gql`
  fragment clinicalNoteAnswer on ClinicalNoteAnswer {
    id
    answer
    field {
      id
      elementType
    }
  }
`
