import { sendSmsMessage } from 'src/gql/mutations/sendSmsMessage'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { querySmsMessages } from 'src/gql/queries/querySmsMessages'
import { SmsMessage, SmsMessageInput } from 'src/schema-types'
import { StoreonModule } from 'storeon'

export type MessageState = {
  loadingMessages: boolean
  sendingMessage: boolean
  messages: SmsMessage[]
  sendMessageError?: string
  loadMessagesError?: string
}

export type MessageEvents = {
  'messages/load': string
  'messages/loading': undefined
  'messages/loaded': SmsMessage[]
  'messages/load/error': string
  'messages/send': SmsMessageInput
  'messages/sending': undefined
  'messages/sent': SmsMessage
  'messages/send/error': string
}

export const messageModule: StoreonModule<MessageState, MessageEvents> = (
  store
) => {
  store.on('@init', () => ({
    pageNumber: 1,
    messages: [],
    loadingMessages: false,
    sendingMessage: false,
  }))
  /************** events for loading messages  ****************/
  store.on('messages/load', async (_state, patientProfileId) => {
    store.dispatch('messages/loading')
    try {
      const messages = await querySmsMessages(patientProfileId)
      store.dispatch('messages/loaded', messages)
    } catch (err) {
      writeLog((err as Error).message)
      store.dispatch('messages/load/error', (err as Error).message)
    }
  })
  store.on('messages/loading', () => ({ loadingMessages: true, messages: [] }))
  store.on('messages/loaded', (_state, messages) => ({
    messages,
    loadingMessages: false,
  }))
  store.on('messages/load/error', (_state, loadMessagesError) => ({
    loadMessagesError,
    loadingMessages: false,
  }))

  /************** events for sending messages  ****************/
  store.on('messages/send', async (_state, input) => {
    store.dispatch('messages/sending')
    try {
      const message = await sendSmsMessage(input)
      store.dispatch('messages/sent', message)
    } catch (err) {
      writeLog((err as Error).message)
      store.dispatch('messages/send/error', (err as Error).message)
    }
  })
  store.on('messages/sending', () => ({ sendingMessage: true }))
  store.on('messages/sent', (_state, message) => ({
    sendingMessage: false,
    messages: _state.messages.concat([message]),
  }))
  store.on('messages/send/error', (_state, sendMessageError) => ({
    sendMessageError,
    sendingMessage: false,
  }))
}
