import { observer } from 'mobx-react'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { FaTimes } from 'react-icons/fa'
import { useShowModal } from 'src/hooks/showModal'
import { ClinicalNoteModuleType } from 'src/schema-types'
import { useStore } from 'src/stores/store'
import { TModule } from '../../types'
import { AddModuleModal } from '../AddModuleModal'
import { ModuleContainer } from '../ModuleContainer'
import { SpecialModulesModal } from '../SpecialModulesModal'

type TModuleTypes = {
  title: string
  type: ClinicalNoteModuleType
  itemActionLabel: string
  addButtonLabel: string
  modalTitle: string
  modalDescription: string
  modalSubmitLabel: string
}

const moduleTypes: TModuleTypes[] = [
  {
    title: 'Objective Measures and Tests',
    type: ClinicalNoteModuleType.ObjectiveMeasuresAndTests,
    itemActionLabel: 'Run test evaluation',
    addButtonLabel: 'Add a new test',
    modalTitle: 'Add a new test',
    modalDescription: `Select the tests you want to add to patient's evalutation.`,
    modalSubmitLabel: 'Add Tests',
  },
]

interface SpecialModulesProps {}

export const SpecialModules: React.FC<SpecialModulesProps> = observer(() => {
  const { unregister } = useFormContext()
  const {
    clinicalNotesStore: { specialModules },
  } = useStore()
  const [moduleToEdit, setModuleToEdit] = React.useState<TModule | null>(null)
  const [isModalVisible, toggleModal] = useShowModal()
  const [selectedType, setSelectedType] = React.useState<TModuleTypes | null>(
    null
  )

  const openModal = (type: TModuleTypes) => {
    setSelectedType(type)
    toggleModal()
  }

  const closeModal = () => {
    setSelectedType(null)
    toggleModal()
  }

  const closeModuleEditing = () => {
    setModuleToEdit(null)
  }

  const removeModule = (module: TModule) => {
    const ids = specialModules.hideModule(module)
    unregister(ids)
  }

  if (specialModules.modulesInCurrentPage) {
    return (
      <>
        {moduleTypes.map((moduleType) => {
          const usedModules = specialModules.modulesBasedOnType(moduleType.type)
          if (usedModules.length) {
            return (
              <ModuleContainer id={moduleType.title} title={moduleType.title}>
                <div className="flex flex-col mt-8 p-6">
                  {usedModules
                    .filter((module) =>
                      specialModules.visibleModules.includes(module.id)
                    )
                    .map((module) => (
                      <div className="flex justify-between pb-8 mb-8 border-b border-gray-200">
                        <p className="text-lg font-medium text-gray-700">
                          {module.label}
                        </p>
                        <div className="grid grid-flow-col gap-8 items-center">
                          <button
                            type="button"
                            className="flex items-center font-medium text-[#0E8474] text-center"
                            onClick={() => setModuleToEdit(module)}
                          >
                            {moduleType.itemActionLabel}
                          </button>
                          <div
                            className="cursor-pointer"
                            onClick={() => removeModule(module)}
                          >
                            <FaTimes className="ml-8" />
                          </div>
                        </div>
                      </div>
                    ))}
                  <button
                    type="button"
                    className="inline-flex w-max justify-center items-center space-x-2 rounded-lg border font-semibold focus:outline-none px-3 py-2 leading-6 border-green-200 bg-green-200 text-green-700 hover:text-green-700 hover:bg-green-300 hover:border-green-300 focus:ring focus:ring-green-500 focus:ring-opacity-50 active:bg-green-200"
                    onClick={() => openModal(moduleType)}
                  >
                    <span className="mr-2">+</span>
                    {moduleType.addButtonLabel}
                  </button>
                </div>
              </ModuleContainer>
            )
          }
          return null
        })}
        {moduleToEdit && (
          <SpecialModulesModal
            isOpen={!!moduleToEdit}
            closeModal={closeModuleEditing}
            module={moduleToEdit}
          />
        )}
        {selectedType?.type && (
          <AddModuleModal
            isOpen={isModalVisible}
            listOfModules={specialModules
              .modulesBasedOnType(selectedType.type)
              .filter(
                (module) => !specialModules.visibleModules.includes(module.id)
              )}
            updateListOfModules={specialModules.showModules}
            closeModal={closeModal}
            title={selectedType.modalTitle}
            description={selectedType.modalDescription}
            successButtonLabel={selectedType.modalSubmitLabel}
          />
        )}
      </>
    )
  }

  return <div></div>
})
