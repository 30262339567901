import {
  rangeOfNumbersInOptions,
  turnIntoOption,
} from 'src/pages/ClinicalNotesv2/utils'

export const treatmentFocus = turnIntoOption([
  'Line orientation',
  'Letter formation',
  'Word formation',
  'Sentence formation',
  'Reversals',
  'Letter orientation',
  'Words',
  'Sentences',
  'Letter spacing',
  'Word spacing',
  'Letter sizing',
  'Word sizing',
  'Appropriate pressure',
  'In-hand manipulation/coordination activities',
])

export const tratmentFocusDerivedOptions = turnIntoOption([
  'Translation',
  'Rotation',
  'Complex rotation',
])

export const cueingRequired = turnIntoOption(['MIN', 'MOD', 'MAX'])

export const typesOfCues = turnIntoOption(['Visual', 'Verbal', 'Tactile'])

export const graspObserved = turnIntoOption([
  'Dynamic tripod',
  'Dynamic quadruped',
  'Lateral tripod',
  'Adaptive tripod',
  'Palmar supinate',
  'Digital pronate',
])

export const trainingCompleted = turnIntoOption([
  'Dynamic tripod',
  'Dynamic quadruped',
  'Lateral tripod',
  'Adaptive tripod',
  'Palmar supinate',
  'Digital pronate',
])

export const aides = turnIntoOption([
  'Adaptive grips',
  'Adaptive paper',
  'Slantboards',
  'Weighted writing utensils',
  'Letter tracers',
  'White boards and markers',
  'Other',
])

export const positionLower = turnIntoOption([
  'Ankles, knees and hips at 90 degree',
  'Parallel to each other',
  'Feet flat on floor',
])

export const positionUpper = turnIntoOption([
  'Ankles, kneees and hips at 90 degrees',
  'Parallel to each other',
  'Wrist neutral or slight extension',
])

export const accuracy = turnIntoOption([
  '10%',
  '20%',
  '30%',
  '40%',
  '50%',
  '60%',
  '70%',
  '80%',
  '90%',
  '100%',
])

export const repetitions = rangeOfNumbersInOptions(20)

export const duration = turnIntoOption(['Activity', 'Repetition', 'Set'])

export const time = turnIntoOption(['Min', 'Sec'])

export const independeceLevel = turnIntoOption([
  'IND',
  'MIN A',
  'MOD A',
  'MAX A',
])
