import React from 'react'
import { TPain } from 'src/pages/ClinicalNotesv2/types'

type PainAnswerProps = {
  painAnswer: TPain
}

export const PainAnswer: React.FC<PainAnswerProps> = ({ painAnswer }) => {
  return (
    <>
      {painAnswer.measures.map((measure) => {
        return (
          <div className="mb-3">
            <p className="flex flex-col">
              {measure.body ? (
                <>
                  <span className="font-semibold py-1">
                    Body part: {measure.body}
                  </span>
                </>
              ) : null}
              {measure.side ? (
                <>
                  <span className="font-semibold py-1">
                    Side: {measure.side}
                  </span>
                </>
              ) : null}
              {measure.range ? (
                <>
                  <span className="font-semibold py-1">
                    Range: {measure.range}
                  </span>
                </>
              ) : null}
            </p>
            {measure.type && measure.type.length ? (
              <p>
                <span className="font-semibold py-1">Type: </span>
                {measure.type.join(', ')}
              </p>
            ) : null}
          </div>
        )
      })}
    </>
  )
}
