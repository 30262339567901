import { ApolloError } from 'apollo-boost'
import { cloneDeep } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { PatientByIdQuery } from 'src/operations-types'
import {
  Appointment,
  AppointmentStatus,
  CareTeamMemberInput,
  ClinicalNoteStatus,
  CommunicationJourmalEntry,
  ContactInput,
  DoctorNote,
  ElementTypes,
  InsuranceInput,
  Maybe,
  MedicalDataPoint,
  MedicationInput,
  PatientProfileAttachment,
  SurgeryInput,
} from 'src/schema-types'
import { PatientServices } from 'src/services/patient'
import { RootStore } from 'src/stores/store'
import { formatPatientNames } from 'src/utils/formatText'

const MESSAGES_PAGE_SIZE = 10

export class PatientStore {
  patientProfile?: PatientByIdQuery['getPatientProfile']
  fetching = false
  fetchError = ''
  rescheduling = false
  creatingNote = false
  updatingNote = false
  resendingNotificaiton = false
  currentEditing: Maybe<MedicalDataPoint>[] = []
  fetchingAppointmentsPage = 1
  fetchingMoreAppointments = false
  fetchingMorePayments = false
  paginatedMessages: CommunicationJourmalEntry[] = []

  uploadingFileCategory: string | null = ''
  removingFileCategory: string | null = ''

  rootStore: RootStore
  services: PatientServices

  constructor(rootStore: RootStore, services: PatientServices) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
    this.services = services
  }

  async fetchById(id: string) {
    try {
      runInAction(() => {
        this.fetching = true
      })

      const patient = await this.services.getPatientById(id, 1)
      const patientWithFormattedNames = {
        ...patient,
        firstName: formatPatientNames(patient?.firstName),
        lastName: formatPatientNames(patient?.lastName),
      }

      runInAction(() => {
        this.patientProfile = patientWithFormattedNames
        this.fetchingAppointmentsPage = 2
      })
    } catch (err) {
      writeLog((err as Error).message)

      runInAction(() => {
        this.fetchError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  async fetchMoreAppointments() {
    try {
      runInAction(() => {
        this.fetchingMoreAppointments = true
      })
      if (!this.patientProfile?.id) {
        return null
      }

      const patient = await this.services.getPatientById(
        this.patientProfile.id,
        this.fetchingAppointmentsPage
      )
      if (this.patientProfile && patient) {
        runInAction(() => {
          this.patientProfile = this.patientProfile && {
            ...this.patientProfile,
            appointments: {
              ...this.patientProfile.appointments,
              pageInfo: patient.appointments.pageInfo,
              entities: [
                ...this.patientProfile.appointments.entities,
                ...patient.appointments.entities,
              ],
            },
          }
          this.fetchingAppointmentsPage = this.fetchingAppointmentsPage + 1
        })
      }
    } catch (err) {
      writeLog((err as Error).message)

      runInAction(() => {
        this.fetchError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetchingMoreAppointments = false
      })
    }
  }

  async fetchMorePayments() {
    try {
      runInAction(() => {
        this.fetchingMorePayments = true
      })
      if (!this.patientProfile?.id) {
        return null
      }

      const patient = await this.services.getPatientById(
        this.patientProfile.id,
        1
      )

      if (this.patientProfile && patient) {
        runInAction(() => {
          const temp = this.patientProfile && {
            ...this.patientProfile,
            ...patient,
          }
          this.patientProfile = temp
        })
      }
    } catch (err) {
      writeLog((err as Error).message)

      runInAction(() => {
        this.fetchError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetchingMorePayments = false
      })
    }
  }

  get assignedForms() {
    return this.patientProfile?.assignedForms || []
  }

  getAttachmentsByCategory(category: string | null) {
    let files: Maybe<PatientProfileAttachment>[] | undefined
    if (category !== 'other') {
      files = this.patientProfile?.attachments?.filter(
        (file) => file?.category === category
      )
    } else {
      files = this.patientProfile?.attachments?.filter(
        (file) => file?.category === category || file?.category === null
      )
    }
    return files
  }

  get templateGroups() {
    const medicalInformation = this.patientProfile?.medicalInformation
    const templateGroupsSet = new Set<string>()

    if (medicalInformation) {
      for (const fieldTemplate of medicalInformation) {
        if (!fieldTemplate?.fieldTemplateGroups) continue
        for (const templateGroup of fieldTemplate.fieldTemplateGroups) {
          if (templateGroup) {
            templateGroupsSet.add(templateGroup.id)
          }
        }
      }
    }

    return templateGroupsSet
  }

  getFieldsByElementType(elementType: string) {
    const fields = this.patientProfile?.medicalInformation?.filter((item) => {
      return item?.fieldTemplate.elementType === elementType
    })

    return fields || []
  }

  getFieldsByTemplateGroup(groupId: string) {
    const fields = this.patientProfile?.medicalInformation?.filter((item) => {
      let keepItem = false
      item?.fieldTemplateGroups.forEach((group) => {
        if (group?.id === groupId) {
          keepItem = true
        }
      })
      return keepItem
    })

    return fields || []
  }

  restoreTemplateGroupFields() {
    if (this.patientProfile && this.currentEditing.length) {
      this.patientProfile.medicalInformation = cloneDeep(this.currentEditing)
      this.currentEditing = []
    }
  }

  backupTemplateGroupFields(dataPoints: Maybe<MedicalDataPoint>[]) {
    this.currentEditing = cloneDeep(
      this.patientProfile?.medicalInformation || []
    )
  }

  getMedicalInformationIndex = (fieldTemplateId: string) => {
    return this.patientProfile?.medicalInformation?.findIndex(
      (elem) => elem?.fieldTemplate.id === fieldTemplateId
    )
  }

  changeCompoundField(
    value: string,
    index: number,
    fieldTemplateId: string,
    fieldId:
      | keyof MedicationInput
      | keyof ContactInput
      | keyof CareTeamMemberInput
      | keyof SurgeryInput,
    elementType: ElementTypes
  ) {
    const informationIndex = this.getMedicalInformationIndex(fieldTemplateId)
    if (!informationIndex) return

    const medicalInformation =
      this.patientProfile?.medicalInformation?.[informationIndex]

    if (elementType === ElementTypes.Medication) {
      if (medicalInformation?.valueMedication) {
        medicalInformation.valueMedication[index][
          fieldId as keyof MedicationInput
        ] = value
      }
    } else if (elementType === ElementTypes.Contact) {
      if (medicalInformation?.valueContact) {
        medicalInformation.valueContact[index][fieldId as keyof ContactInput] =
          value
      }
    } else if (elementType === ElementTypes.CareTeamMember) {
      if (medicalInformation?.valueCareTeamMember) {
        medicalInformation.valueCareTeamMember[index][
          fieldId as keyof CareTeamMemberInput
        ] = value
      }
    } else if (elementType === ElementTypes.Surgery) {
      if (medicalInformation?.valueSurgery) {
        medicalInformation.valueSurgery[index][fieldId as keyof SurgeryInput] =
          value
      }
    }
  }

  async editFieldTemplate(
    fieldTemplateId: string,
    value: InsuranceInput | { [key: string]: string }[] | string,
    elementType: ElementTypes
  ) {
    try {
      const patientId = this.patientProfile?.id
      if (!patientId) return
      const response = await this.services.editDataPoint(
        patientId,
        fieldTemplateId,
        value,
        elementType
      )

      runInAction(() => {
        if (response?.data) {
          this.updateFieldTemplateValues(
            fieldTemplateId,
            response.data.saveMedicalDataPoint
          )
        }
      })
    } catch (err) {
      writeLog((err as Error).message)

      throw new Error(err)
    } finally {
    }
  }

  private updateFieldTemplateValues(
    fieldtemplateId: string,
    newMedicalDataPoint: MedicalDataPoint
  ) {
    if (this.patientProfile?.medicalInformation) {
      this.patientProfile.medicalInformation.forEach(
        (dataPoint, index, medicalInformation) => {
          if (dataPoint?.fieldTemplate.id === fieldtemplateId) {
            medicalInformation[index] = newMedicalDataPoint
          }
        }
      )
    }
  }

  async removeAttachmentById(fileId: string, category: string | null) {
    try {
      runInAction(() => {
        this.removingFileCategory = category
      })

      await this.services.deleteAttachment(fileId)

      runInAction(() => {
        if (this.patientProfile) {
          this.patientProfile.attachments =
            this.patientProfile.attachments?.filter(
              (file) => file?.id !== fileId
            )
        }
      })
    } catch (err) {
      writeLog((err as Error).message)

      throw new Error(err)
    } finally {
      runInAction(() => {
        this.removingFileCategory = ''
      })
    }
  }

  private uploadToS3 = async (url: string, file: File) => {
    await fetch(url, {
      method: 'PUT',
      body: file,
    })
  }

  async addAttachment(
    patientId: string | undefined,
    file: File,
    category: string | null
  ) {
    if (!patientId) {
      return
    }

    try {
      runInAction(() => {
        this.uploadingFileCategory = category
      })

      const response = await this.services.createAttachment(
        patientId,
        file.name,
        category
      )

      await this.uploadToS3(
        response.data?.createPatientProfileAttachment.uploadUrl,
        file
      )

      if (response.data) {
        if (this.patientProfile?.id === patientId) {
          this.patientProfile?.attachments?.push(
            response.data.createPatientProfileAttachment
          )
        }
      } else if (response.errors) {
        throw new ApolloError({
          graphQLErrors: response.errors,
        })
      }
    } catch (err) {
      writeLog((err as Error).message)

      throw new Error(err)
    } finally {
      runInAction(() => {
        this.uploadingFileCategory = ''
      })
    }
  }

  async createNote(patientId: string, note: string) {
    try {
      runInAction(() => {
        this.creatingNote = true
      })
      const createdNote = await this.services.createNote(patientId, note)

      runInAction(() => {
        if (createdNote.data) {
          this.appendNote(createdNote.data.createDoctorNote)
        } else {
          throw new ApolloError({
            graphQLErrors: createdNote.errors,
          })
        }
      })
    } catch (err) {
      writeLog((err as Error).message)

      throw new Error(err)
    } finally {
      runInAction(() => {
        this.creatingNote = false
      })
    }
  }

  async updateNote(noteId: string, note: string) {
    try {
      runInAction(() => {
        this.updatingNote = true
      })

      const updatedNote = await this.services.updateNote(noteId, note)

      runInAction(() => {
        if (updatedNote.data) {
          this.updateNoteList(updatedNote.data.updateDoctorNote)
        } else {
          throw new ApolloError({
            graphQLErrors: updatedNote.errors,
          })
        }
      })
    } catch (err) {
      writeLog((err as Error).message)
      throw new Error(err)
    } finally {
      runInAction(() => {
        this.updatingNote = false
      })
    }
  }

  async rescheduleAppointment(
    appointmentId: string,
    dateTime: string,
    practiceLocationId: string,
    patientUpdateReason: string,
    description: string
  ) {
    if (!this.patientProfile?.id) {
      return
    }
    try {
      runInAction(() => {
        this.rescheduling = true
      })

      const response = await this.services.rescheduleAppointment(
        appointmentId,
        {
          dateTime,
          patientUpdateReason,
          description,
          practiceLocationId,
        }
      )

      runInAction(() => {
        if (this.patientProfile && response.data?.rescheduleAppointment) {
          this.patientProfile.appointments.entities.push(
            response.data.rescheduleAppointment
          )

          this.patientProfile?.appointments.entities.forEach(
            (appointment, index, appointments) => {
              if (appointment.id === appointmentId) {
                const currentAppointments = appointments as Appointment[]

                currentAppointments[index].status =
                  AppointmentStatus.Rescheduled
                if (
                  currentAppointments &&
                  currentAppointments[index].clinicalNote &&
                  currentAppointments[index].clinicalNote?.status !== null
                ) {
                  currentAppointments[index].clinicalNote!.status =
                    ClinicalNoteStatus.Cancelled
                }
              }
            }
          )
        }
      })
    } catch (err) {
      writeLog((err as Error).message)

      throw new Error(err)
    } finally {
      runInAction(() => {
        this.rescheduling = false
      })
    }
  }

  async deleteNote(noteId: string) {
    try {
      const deletedNote = await this.services.deleteNote(noteId)

      runInAction(() => {
        if (deletedNote.data) {
          this.updateAfterDeleteNote(deletedNote.data.deleteDoctorNote.id)
        } else {
          throw new ApolloError({
            graphQLErrors: deletedNote.errors,
          })
        }
      })
    } catch (err) {
      writeLog((err as Error).message)
      throw new Error(err)
    }
  }

  get hasMoreMessages() {
    if (this.patientProfile) {
      return (
        this.paginatedMessages.length <
        this.patientProfile?.communicationHistory.length
      )
    } else {
      return false
    }
  }

  getPaginatedMessages() {
    runInAction(() => {
      this.paginatedMessages =
        this.patientProfile?.communicationHistory.slice(
          0,
          MESSAGES_PAGE_SIZE
        ) || []
    })
  }

  getMoreMessages() {
    runInAction(() => {
      const currentLength = this.paginatedMessages.length
      this.paginatedMessages = [
        ...this.paginatedMessages,
        ...(this.patientProfile?.communicationHistory.slice(
          currentLength,
          currentLength + MESSAGES_PAGE_SIZE
        ) || []),
      ]
    })
  }

  getMessageDetails(messageId: string) {
    const message = this.patientProfile?.communicationHistory.filter(
      (message) => message.id === messageId
    )

    return message?.length ? message[0] : undefined
  }

  async resendNotification(messageId: string) {
    try {
      runInAction(() => {
        this.resendingNotificaiton = true
      })

      await this.services.resendNotification(messageId)
    } catch (err) {
      writeLog((err as Error).message)

      throw new Error(err)
    } finally {
      this.resendingNotificaiton = false
    }
  }

  private appendNote(note: DoctorNote) {
    this.patientProfile?.doctorNotes?.unshift(note)
  }

  private updateNoteList(note: DoctorNote) {
    if (this.patientProfile?.doctorNotes) {
      this.patientProfile.doctorNotes = this.patientProfile?.doctorNotes?.map(
        (doctorNote) => {
          if (note.id === doctorNote?.id) {
            return note
          }
          return doctorNote
        }
      )
    }
  }

  private updateAfterDeleteNote(id: string) {
    if (this.patientProfile?.doctorNotes) {
      this.patientProfile.doctorNotes =
        this.patientProfile?.doctorNotes?.filter(
          (doctorNote) => id !== doctorNote?.id
        )
    }
  }
}
