import * as TOperation from 'src/operations-types'
import { ClinicalNoteElementTypes } from 'src/schema-types'

export * from './exercise'

export type TModule = NonNullable<
  NonNullable<
    TOperation.QueryClinicalNoteV2Query['getClinicalNoteModular']
  >['template']
>['pages'][number]['sections'][number]['modules'][number]

export const WFLFElementTypes = [
  ClinicalNoteElementTypes.Motion,
  ClinicalNoteElementTypes.Strength,
]

export type TCheckAnswer = Record<
  string,
  { value: string; createdOn: string; isAmend: boolean }
>

export type TSide = 'left' | 'right'

export type TAnswer =
  | TMotion
  | TPain
  | TPosture[]
  | TBalance
  | TGait
  | TExercise
  | TGaitTraining
  | TTransfers
  | THomeSetup
  | TGoal
  | TBloodPressure
  | TTemperature
  | TOxygen
  | TStrength
  | TTerm
  | THep
  | string[]
  | string

export type TMotion = {
  measures: {
    body: string
    side: TSide
    properties: Record<string, string>
  }[]
}

export type TPain = {
  measures: {
    body: string
    side: TSide
    type: string[]
    range: string
  }[]
}

export type TExercise = {
  measures: {
    category: string
    location: string
    exercise: string
    position: string
    repetitions: string
    sets: string
    side: string
    tool: string
    muscle: string
  }[]
}

export type THandwritting = {
  measures: {
    treatmentFocus: string[]
    category: string
    positionLowerExtremety: string
    positionUpperExtremity: string
    graspObserved: string
    trainingCompleted: string
    aides: string[]
    accuracy: string
    repetitions: string
    duration: string
    count: string
    time: string
    cueing: string
    cueingType: string
    independenceLevel: string
  }
}

export type TGaitTraining = {
  measures: {
    terrain: string
    weightBearing: string
    weightBearingPercentage: string
    weightBearingSide: string
    repetitions: string
    distance: string
    assistiveDevice: string
    gaitTrainingSkills: string
    levelOfAssistance: string
    time: string
    tolerance: string
    pattern: string
    cadence: string
    support: string
  }[]
}

/**
 * Balance types
 */
export const balanceOptions = [
  'Static Sitting',
  'Dynamic Sitting',
  'Static Standing',
  'Dynamic Standing',
] as const

export type TBalanceOptions = typeof balanceOptions[number]

export type TBalance = {
  properties: Record<TBalanceOptions, { value: string }>
}

/**
 * Gait types
 */
export const gaitOptions = [
  'Cadence',
  'Measurements',
  'Support',
  'Deviations',
  'Assistive Device',
] as const

export type TGaitOptions = typeof gaitOptions[number]

export type TGait = {
  properties: Record<
    TGaitOptions,
    { option1: string; option2: string; 'time-label': string }
  >
}

/**
 * Transfers types
 */
export const transferOptions = [
  'Shower',
  'Tub',
  'Toilet',
  'Chair',
  'Wheelchair',
  'Car',
]

export type TTransferOptions = typeof transferOptions[number]

export type TTransfers = {
  properties: Record<TTransferOptions, { option1: string; equipment: string }>
}

/**
 * Home Setupd types
 */
export const homeSetupOptions = [
  'Entrance',
  'Equipment',
  'Bathroom',
  'Levels',
] as const
export type THomeSetupOptions = typeof homeSetupOptions[number]
export type THomeSetup = {
  properties: Record<
    THomeSetupOptions,
    {
      option1: string | string[]
      option2: string
      option3: string
      note: string
    }
  >
}

export type TStrength = {
  measures: {
    body: string
    type: string
    side: TSide
  }[]
}

export type THep = {
  measures: {
    cue: string
    device: string
    notes: string
    term: TTerm
  }[]
}

export type TGoalMeasure = {
  term: TTerm
  goal: string
  progress: string
}

export type TGoal = {
  measures: TGoalMeasure[]
}

export type TPosture = {
  body: string
  type: string
  side: TSide
}

export type TSignature = string

export type TBloodPressure = {
  sbp: string
  dbp: string
}

export type TTemperature = {
  location: string
  value: string
}

export type TOxygen = {
  value: string
  supplemental: string
}

export type TTerm = {
  length: string
  duration: string
  durationUnit: string
}

export type TCheckbox = string[]

export type TRadioButton = string

export type TReflexes = {
  reflexes: string[]
}
