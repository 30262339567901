import React from 'react'
import { TPosture } from 'src/pages/ClinicalNotesv2/types'

type PostureAnswerProps = {
  postureAnswer: TPosture[]
}

export const PostureAnswer: React.FC<PostureAnswerProps> = ({
  postureAnswer,
}) => {
  return (
    <>
      {postureAnswer.map((answer) => (
        <div className="mb-3">
          <p className="flex flex-col">
            {answer.body ? (
              <>
                <span className="font-semibold">Body part: </span>
                {answer.body}
              </>
            ) : null}

            {answer.side ? (
              <>
                <span className="font-semibold py-1">Side: {answer.side}</span>
              </>
            ) : null}

            {answer.type ? (
              <>
                <span className="font-semibold py-1">Type: {answer.type} </span>
              </>
            ) : null}
          </p>
        </div>
      ))}
    </>
  )
}
