import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { makeAutoObservable, runInAction } from 'mobx'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { QueryPracticeClinicalNoteTemplatesQuery } from 'src/operations-types'
import { OptionWithType } from 'src/pages/Calendar/utils/calendarInterfaces'
import * as T from 'src/schema-types'
import { PracticeServices } from 'src/services/practice'
import { RootStore } from 'src/stores/store'
import { formatDate } from 'src/utils/formatDate'

dayjs.extend(customParseFormat)

export class PracticeStore {
  practice?: T.Practice
  featureConfig?: T.FeatureConfig
  statistics?: T.PracticeStatistics
  statisticsRange = 'TODAY'
  fetching = false
  fetchingStatistics = false
  fetchError = ''

  clinicalNoteTemplates?: NonNullable<
    QueryPracticeClinicalNoteTemplatesQuery['getPractice']
  >['clinicalNoteModularTemplates']
  fetchingCNTemplates = false
  fetchCNError = ''

  billingCodes?: T.BillingCode[]
  fetchingBillingCodes = false
  fetchBillingCodesError = ''

  referringDoctors?: T.ReferringDoctor[] | null
  fetchingReferringDoctors = false
  fetchReferringDoctorsError = ''

  rootStore: RootStore
  services: PracticeServices

  constructor(rootStore: RootStore, services: PracticeServices) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
    this.services = services
  }

  setStatisticsRange(range: string) {
    runInAction(() => {
      this.statisticsRange = range
    })
  }

  async getPracticeBillingCodes() {
    if (!this.rootStore.profileStore.profile?.practice.id) {
      return
    }

    try {
      this.fetchingBillingCodes = true
      const billingCodes = await this.services.getBillingCodes(
        this.rootStore.profileStore.profile.practice.id
      )
      runInAction(() => {
        this.billingCodes = billingCodes
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchBillingCodesError = (err as Error).message
      })
    } finally {
      this.fetchingBillingCodes = false
    }
  }

  async getPracticeReferringDoctors() {
    if (!this.rootStore.profileStore.profile?.practice.id) {
      return
    }

    try {
      this.fetchingReferringDoctors = true
      const referringDoctors = await this.services.getReferringDoctors(
        this.rootStore.profileStore.profile.practice.id
      )

      runInAction(() => {
        this.referringDoctors = referringDoctors
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchReferringDoctorsError = (err as Error).message
      })
    } finally {
      this.fetchingReferringDoctors = false
    }
  }

  get icdBillingCodes() {
    return this.billingCodes?.filter(
      (code) => code.type.toLowerCase() === 'icd'
    )
  }

  async getClinicalNoteTemplates() {
    try {
      this.fetchingCNTemplates = true
      const clinicalNoteTemplates =
        await this.services.getClinicalNoteTemplates(
          this.rootStore.profileStore.profile?.practice.id
        )

      runInAction(() => {
        this.clinicalNoteTemplates = clinicalNoteTemplates
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchCNError = (err as Error).message
      })
    } finally {
      this.fetchingCNTemplates = false
    }
  }

  get pageBasedClinicalNoteTemplates() {
    return this.clinicalNoteTemplates?.filter(
      (template) => template.pages.length
    )
  }

  get cptBillingCodes() {
    return this.billingCodes?.filter(
      (code) => code.type.toLowerCase() === 'cpt'
    )
  }

  get cptModifierBillingCodes() {
    return this.billingCodes?.filter(
      (code) => code.type.toLowerCase() === 'cpt_modifier'
    )
  }

  get activeReferringDoctors() {
    return this.referringDoctors?.filter((doctor) => doctor.isActive)
  }

  async getStatistics(startDate?: string, endDate?: string) {
    try {
      this.fetchingStatistics = true

      const practiceWithStatistics = await this.services.getStatistics(
        this.rootStore.profileStore.profile?.practice.id,
        startDate,
        endDate
      )

      if (practiceWithStatistics) {
        runInAction(() => {
          this.statistics = practiceWithStatistics.statistics
        })
      }
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchError = (err as Error).message
      })
    } finally {
      this.fetchingStatistics = false
    }
  }

  async getFeatureFlags() {
    try {
      this.fetching = true

      const practiceWithFeature = await this.services.getFeatureConfig(
        this.rootStore.profileStore.profile?.practice.id
      )

      if (practiceWithFeature) {
        runInAction(() => {
          this.featureConfig = practiceWithFeature.featureConfig
        })
      }
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchError = (err as Error).message
      })
    } finally {
      this.fetching = false
    }
  }

  getPaginatedPracticeData(pageNumber: number) {
    let currentAppointments: Array<T.Appointment[]> = []

    this.practice?.users?.forEach((user) => {
      currentAppointments.push(user?.appointments.entities as T.Appointment[])
    })

    let result = this.practice

    if (pageNumber > 1) {
      result?.users?.forEach((user, index) => {
        if (user && currentAppointments[index].length) {
          const oldEntities = user?.appointments.entities as T.Appointment[]
          const currentEntities = currentAppointments[index]
          const combinedEntities: T.Appointment[] = [
            ...oldEntities,
            ...currentEntities,
          ]
          user.appointments.entities = combinedEntities
        }
      })
    }

    return result
  }

  /**
   * List of active practice doctors
   */
  get doctorsList() {
    const result: OptionWithType[] = []

    this.allDoctorOptions.forEach((doctor) => {
      if (doctor) {
        result.push({
          label: `${doctor.firstName} ${doctor.lastName}`,
          value: doctor.id,
          type: 'Doctor',
        })
      }
    })

    return result
  }

  /**
   * List of practice's offices
   */
  get officesList() {
    const result: OptionWithType[] = []

    this.practice?.locations.forEach((location) => {
      location.name &&
        result.push({
          label: location.name,
          value: location.id,
          type: 'Location',
        })
    })

    return result
  }

  /**
   * Return the earliest and latest schedulable hours among locations
   */
  get schedulableHoursRange() {
    let min = '30:00:00'
    let max = '00:00:00'

    this.practice?.locations.forEach((location) => {
      if (location?.schedulableHours) {
        for (const weekDayScheduleHoursList of Object.values(
          location.schedulableHours
        )) {
          if (Array.isArray(weekDayScheduleHoursList)) {
            ;[min, max] = minMaxDayHours(weekDayScheduleHoursList, min, max)
          }
        }
      }
    })

    if (min === '30:00:00') {
      min = '00:00:00'
    }

    if (max === '00:00:00') {
      max = '24:00:00'
    }

    return { min, max }
  }

  /**
   * Return the earliest and latest schedulable hours for the selected day
   */
  schedulableRangeByDay(day: keyof T.ScheduleHours) {
    let min = '30:00:00'
    let max = '00:00:00'

    this.practice?.locations.forEach((location) => {
      if (location) {
        const hours = location.schedulableHours?.[day]

        if (Array.isArray(hours)) {
          ;[min, max] = minMaxDayHours(hours, min, max)
        }
      }
    })

    if (min === '30:00:00' || max === '00:00:00') {
      const newMinMax = this.schedulableHoursRange
      min = newMinMax.min
      max = newMinMax.max
    }

    return { min, max }
  }

  /**
   * List of doctor options
   */
  get allDoctorOptions() {
    let result
    result = this.practice?.users
      ?.filter((user) => user?.account.isActive)
      .filter((staff) => staff?.staffType === 'MEDICAL')

    return result || []
  }

  /**
   * List of offices options
   */
  get allOfficeOptions() {
    let result
    result = this.practice?.locations

    return result || []
  }
}

/**
 * Return the min and max time from the array of ScheduleHours
 */
const minMaxDayHours = (
  scheduleHoursList: Array<T.ScheduleHoursElement | null>,
  initialMin: string,
  initialMax: string
) => {
  const timeFormat = 'HH:mm:ss'
  let min = dayjs(initialMin, timeFormat)
  let max = dayjs(initialMax, timeFormat)

  for (const scheduleHoursElement of scheduleHoursList) {
    if (scheduleHoursElement) {
      if (dayjs(scheduleHoursElement.start, timeFormat).isBefore(min)) {
        min = dayjs(scheduleHoursElement.start, timeFormat)
      }
      if (dayjs(scheduleHoursElement.end, timeFormat).isAfter(max)) {
        max = dayjs(scheduleHoursElement.end, timeFormat)
      }
    }
  }

  return [formatDate(min, timeFormat), formatDate(max, timeFormat)]
}
