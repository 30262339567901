import { gql } from 'apollo-boost'
import { ClinicalNote } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface SubmitClinicalNoteResponse {
  updateClinicalNote: ClinicalNote
}

export const SUBMIT_CLINICAL_NOTE = gql`
  mutation submitClinicalNote($id: ID!) {
    updateClinicalNoteModular(id: $id, input: { status: FINAL }) {
      id
    }
  }
`

export const submitClinicalNote = async (id: string) => {
  const response = await apolloClient.mutate<SubmitClinicalNoteResponse>({
    mutation: SUBMIT_CLINICAL_NOTE,
    variables: {
      id,
    },
  })

  return response
}
