import { Option } from '@ps-ui/components'
import * as React from 'react'
import { Dropdown } from 'src/pages/ClinicalNotesv2/components/fields/Dropdown'
import { ClinicalNotesInput } from 'src/pages/ClinicalNotesv2/components/fields/Input'
import { InputContainer } from 'src/pages/ClinicalNotesv2/components/InputContainer'

export const Oxygen: React.FC<{ name: string; label: string }> = ({
  name,
  label,
}) => {
  const supplementalOptions: Option[] = React.useMemo(() => {
    return [
      {
        label: '1',
        value: '1',
      },
      {
        label: '2',
        value: '2',
      },
      {
        label: '3',
        value: '3',
      },
      {
        label: '4',
        value: '4',
      },
      {
        label: '5',
        value: '5',
      },
    ]
  }, [])
  return (
    <InputContainer name={name} isAmendContainer label={label}>
      <div className="flex">
        <div className="flex mr-6 items-center">
          <ClinicalNotesInput name={`${name}.value`} type="number" />
          <span className="ml-2">mm Hg</span>
        </div>
        <div className="flex items-center w-72">
          <span className="mr-2">Supplemental</span>
          <Dropdown
            name={`${name}.supplemental`}
            options={supplementalOptions}
          />
        </div>
      </div>
    </InputContainer>
  )
}
