import clsx from 'clsx'
import { debounce } from 'lodash'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIsMount } from 'src/hooks/isMount'
import * as TOperation from 'src/operations-types'
import { Billing } from 'src/pages/Billing'
import { Balance } from 'src/pages/ClinicalNotesv2/components/fields/Balance'
import { Dropdown } from 'src/pages/ClinicalNotesv2/components/fields/Dropdown'
import { Gait } from 'src/pages/ClinicalNotesv2/components/fields/Gait'
import { Goal } from 'src/pages/ClinicalNotesv2/components/fields/Goal'
import { Hep } from 'src/pages/ClinicalNotesv2/components/fields/Hep'
import { HomeSetup } from 'src/pages/ClinicalNotesv2/components/fields/HomeSetup'
import { HorizontalOptionsList } from 'src/pages/ClinicalNotesv2/components/fields/HorizontalOptionsList'
import { ClinicalNotesInput } from 'src/pages/ClinicalNotesv2/components/fields/Input'
import {
  Exercise,
  GaitTrainingIntervention,
  Handwritting,
  PainManagement,
  StairTraining,
  TrainingEducation,
  TransferTraining,
} from 'src/pages/ClinicalNotesv2/components/fields/interventions'
import { OptionsList } from 'src/pages/ClinicalNotesv2/components/fields/OptionsList'
import { Pain } from 'src/pages/ClinicalNotesv2/components/fields/Pain'
import { Posture } from 'src/pages/ClinicalNotesv2/components/fields/Posture'
import { RangeOfMotionInput } from 'src/pages/ClinicalNotesv2/components/fields/RangeOfMotion/RangeOfMotionInput'
import { Reflexes } from 'src/pages/ClinicalNotesv2/components/fields/Reflexes'
import { Signature } from 'src/pages/ClinicalNotesv2/components/fields/Signature'
import { Strength } from 'src/pages/ClinicalNotesv2/components/fields/Strength'
import { Term } from 'src/pages/ClinicalNotesv2/components/fields/Term'
import { TimeRecorder } from 'src/pages/ClinicalNotesv2/components/fields/TimeRecorder'
import { Transfers } from 'src/pages/ClinicalNotesv2/components/fields/Transfers'
import { BloodPressure } from 'src/pages/ClinicalNotesv2/components/fields/vitalSigns/BloodPressure'
import { Oxygen } from 'src/pages/ClinicalNotesv2/components/fields/vitalSigns/Oxygen'
import { Temperature } from 'src/pages/ClinicalNotesv2/components/fields/vitalSigns/Temperature'
import { InputContainer } from 'src/pages/ClinicalNotesv2/components/InputContainer'
import { turnIntoOption } from 'src/pages/ClinicalNotesv2/utils'
import { ClinicalNoteElementTypes, ClinicalNoteStatus } from 'src/schema-types'
import { useStore } from 'src/stores/store'
import { GoalProgress } from '../fields/GoalProgress'

interface RenderFieldsProps {
  field: NonNullable<
    NonNullable<
      NonNullable<
        TOperation.QueryClinicalNoteV2Query['getClinicalNoteModular']
      >['template']
    >['pages']
  >[number]['sections'][number]['modules'][number]['fields'][number]
  groupTitle: string
}

export const RenderFields: React.FC<RenderFieldsProps> = observer(
  ({ field }) => {
    const {
      clinicalNotesStore: {
        clinicalNote,
        saveClinicalNoteAnswer,
        amendments,
        progressFieldId,
        goalFieldId,
      },
    } = useStore()
    const isMounted = useIsMount()
    const { control, handleSubmit } = useFormContext()

    const value = useWatch({
      name: field.id,
      control,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const save = React.useCallback(
      debounce((fieldId: string) => {
        handleSubmit((data) => {
          if (amendments.isAmendmentInProgress) {
            amendments.saveAmendmentAnswer(
              fieldId,
              JSON.stringify(data[field.id])
            )
          } else if (clinicalNote?.status === ClinicalNoteStatus.InProgress) {
            saveClinicalNoteAnswer(fieldId, JSON.stringify(data[field.id]))
            if (
              field.elementType === ClinicalNoteElementTypes.Goal &&
              !!progressFieldId
            ) {
              saveClinicalNoteAnswer(
                progressFieldId,
                JSON.stringify(data[field.id])
              )
            }
            if (
              field.elementType ===
                ClinicalNoteElementTypes.FormattedTextBlock &&
              goalFieldId
            ) {
              saveClinicalNoteAnswer(
                goalFieldId,
                JSON.stringify(data[field.id])
              )
            }
          }
        })()
      }, 1500),
      [saveClinicalNoteAnswer, handleSubmit]
    )

    React.useEffect(() => {
      if (isMounted) {
        save(field.id)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, field])

    const options = React.useMemo(() => {
      return turnIntoOption(field.answerOptions || [])
    }, [field])

    if (field.elementType === ClinicalNoteElementTypes.Motion) {
      return <RangeOfMotionInput name={field.id} title={field.label} />
    } else if (field.elementType === ClinicalNoteElementTypes.Strength) {
      return <Strength name={field.id} title={field.label} />
    } else if (field.elementType === ClinicalNoteElementTypes.Billing) {
      return <Billing clinicalNoteComponent={true} />
    } else if (field.elementType === ClinicalNoteElementTypes.Term) {
      return <Term name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.Pain) {
      return <Pain name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.Balance) {
      return <Balance name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.HomeSetup) {
      return <HomeSetup name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.Signature) {
      return <Signature name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.Reflexes) {
      return <Reflexes name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.Hep) {
      return <Hep name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.Exercise) {
      return <Exercise name={field.id} />
    } else if (
      field.elementType === ClinicalNoteElementTypes.EducationTraining
    ) {
      return <TrainingEducation name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.StairTraining) {
      return <StairTraining name={field.id} />
    } else if (
      field.elementType === ClinicalNoteElementTypes.TransfersTraining
    ) {
      return <TransferTraining name={field.id} />
    } else if (
      field.elementType === ClinicalNoteElementTypes.HandwritingSkills
    ) {
      return <Handwritting name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.PainEducation) {
      return <PainManagement name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.Transfers) {
      return <Transfers name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.Gait) {
      return <Gait name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.Goal) {
      return <Goal name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.GaitTraining) {
      return <GaitTrainingIntervention name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.StartEndTimes) {
      return <TimeRecorder name={field.id} />
    } else if (
      field.elementType === ClinicalNoteElementTypes.FormattedTextBlock
    ) {
      return <GoalProgress name={field.id} />
    } else if (
      field.elementType === ClinicalNoteElementTypes.CheckboxesHorizontal ||
      field.elementType === ClinicalNoteElementTypes.RadioButtonsHorizontal
    ) {
      return (
        <InputContainer
          name={field.id}
          label={field.label}
          className="max-w-max"
        >
          <HorizontalOptionsList
            name={field.id}
            isMulti={
              field.elementType ===
              ClinicalNoteElementTypes.CheckboxesHorizontal
            }
            options={options}
          />
        </InputContainer>
      )
    } else if (field.elementType === ClinicalNoteElementTypes.Posture) {
      return <Posture name={field.id} />
    } else if (field.elementType === ClinicalNoteElementTypes.BloodPressure) {
      return <BloodPressure name={field.id} label={field.label} />
    } else if (field.elementType === ClinicalNoteElementTypes.Temperature) {
      return <Temperature name={field.id} label={field.label} />
    } else if (field.elementType === ClinicalNoteElementTypes.Oxygen) {
      return <Oxygen name={field.id} label={field.label} />
    } else if (field.elementType === ClinicalNoteElementTypes.RadioButtons) {
      return (
        <InputContainer
          name={field.id}
          label={field.label}
          className="max-w-max"
        >
          <OptionsList name={field.id} options={options} />
        </InputContainer>
      )
    } else if (field.elementType === ClinicalNoteElementTypes.Checkboxes) {
      return (
        <InputContainer
          name={field.id}
          label={field.label}
          className="max-w-max"
        >
          <OptionsList name={field.id} options={options} isMulti />
        </InputContainer>
      )
    } else if (field.elementType === ClinicalNoteElementTypes.Dropdown) {
      return (
        <InputContainer
          name={field.id}
          label={field.label}
          className="max-w-screen-sm"
        >
          <Dropdown name={field.id} options={options} />
        </InputContainer>
      )
    } else if (
      field.elementType === ClinicalNoteElementTypes.TextInput ||
      field.elementType === ClinicalNoteElementTypes.TextArea
    ) {
      return (
        <InputContainer name={field.id} label={field.label}>
          <ClinicalNotesInput
            name={field.id}
            className={clsx(
              field.elementType === ClinicalNoteElementTypes.TextInput &&
                'max-w-xs'
            )}
            type={
              field.elementType === ClinicalNoteElementTypes.TextArea
                ? 'textarea'
                : 'text'
            }
          />
        </InputContainer>
      )
    }
    return null
  }
)
