import { Button } from '@ps-ui/components'
import React from 'react'

type NotificationModalStepProps = {
  header: string
  textPrimaryButton: string
  onPrimaryButtonClick?: () => void
  onClose: () => void
  isLoading: boolean
  isDisabled: boolean
}

export const NotificationModalStep: React.FC<NotificationModalStepProps> = ({
  header,
  isLoading,
  isDisabled,
  textPrimaryButton,
  onPrimaryButtonClick,
  onClose,
  children,
}) => {
  return (
    <div
      className="flex flex-col rounded shadow-sm bg-white overflow-hidden w-full max-w-md mx-auto"
      role="document"
    >
      <div className="p-5 lg:p-6 flex-grow w-full text-center">
        <div className="w-16 h-16 mx-auto mb-5 flex items-center justify-center rounded-full bg-green-100">
          <svg
            className="hi-solid hi-check inline-block w-8 h-8 text-green-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div>
          <h4 className="text-xl font-semibold mb-1">{header}</h4>
          <p className="text-gray-600">{children}</p>
        </div>
      </div>
      <div className="py-4 px-auto lg:px-6 flex justify-center">
        <Button
          id="primary-btn"
          variant="filled"
          color="primary"
          onClick={onPrimaryButtonClick ? onPrimaryButtonClick : onClose}
          loading={isLoading}
          disabled={isDisabled}
        >
          {textPrimaryButton}
        </Button>
      </div>
    </div>
  )
}
