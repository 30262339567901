import { Flex, OptionsField, TextField, Typography } from '@ps-ui/components'
import { observer } from 'mobx-react'
import React, { FC, useCallback, useEffect, useRef } from 'react'
import { Select, SelectRef } from 'react-functional-select'
import { Avatar } from 'src/components/atoms'
import { PatientSearchBox } from 'src/components/molecules/Search'
import {
  Appointment,
  PaymentMethod as $PaymentMethod,
  PaymentType as $PaymentType,
} from 'src/schema-types'
import { formatDate } from 'src/utils/formatDate'
import { Fields } from '.'

type ContentProps = {
  fields: Fields
  onChangeFields: (id: string, value: string) => void
  appointments: Appointment[]
  fullName: string
  allowSearchPatient: boolean
  isFetchingPatient: boolean
}

export const ModalContent: FC<ContentProps> = observer(
  ({
    fields,
    onChangeFields,
    appointments,
    allowSearchPatient,
    isFetchingPatient,
    fullName,
  }) => {
    const appointmentsRef = useRef<SelectRef>(null)

    useEffect(() => {
      if (!appointments.length) {
        appointmentsRef.current?.clearValue()
      }
    }, [appointments])

    const onPaymentMethodChange = useCallback(
      (e) => onChangeFields('paymentMethod', e?.value),
      // for some reason if I add onChangeFields to the dependencies, it
      // keeps re-rendering infinitely. Didn't successed to debug.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const onAppointmentId = useCallback(
      (e) => onChangeFields('appointmentId', e?.value),
      // for some reason if I add onChangeFields to the dependencies, it
      // keeps re-rendering infinitely. Didn't successed to debug.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    return (
      <div className="space-y-4">
        {allowSearchPatient ? (
          <PatientSearchBox />
        ) : (
          <div>
            <label className="text-[#4b5563] font-medium">Patient</label>
            <div className="flex items-center mt-2">
              <Avatar
                url={''}
                firstName={fullName.split(' ')[0]}
                height="40"
                width="40"
                borderRadius="50"
                margin="0 10px 0 0"
              />
              <p>{fullName}</p>
            </div>
          </div>
        )}
        <OptionsField
          type="Radio_Buttons"
          direction="row"
          id="opts"
          label="Payment Type"
          required={true}
          options={Object.values($PaymentType)}
          value={fields.paymentType}
          onSave={(e: string) => onChangeFields('paymentType', e)}
        />
        {fields.paymentType === $PaymentType.Copay && (
          <Flex flexDirection="column" width="100%">
            <Typography as="h4" fontWeight="700" padding="0 0 8px 0">
              <span className="text-[#DA6C6C] mr-1">*</span>Appointment
            </Typography>
            <Select
              ref={appointmentsRef}
              isMulti={false}
              closeMenuOnSelect={true}
              placeholder="Select an appointment"
              required={true}
              isLoading={isFetchingPatient}
              isDisabled={isFetchingPatient}
              options={getAppointmentOptions(appointments)}
              initialValue={fields.appointmentId}
              noOptionsMsg={'No More Options Available'}
              renderOptionLabel={(opt: any) => <p>{opt.label}</p>}
              onOptionChange={onAppointmentId}
              menuMaxHeight={150}
            />
          </Flex>
        )}
        <Flex flexDirection="column" width="100%">
          <Typography as="h4" fontWeight="700" padding="0 0 8px 0">
            <span className="text-[#DA6C6C] mr-1">*</span>Payment Method
          </Typography>
          <Select
            isMulti={false}
            closeMenuOnSelect={true}
            placeholder="Select a method"
            required={true}
            options={Object.entries($PaymentMethod).map(([value, label]) => ({
              value,
              label,
            }))}
            initialValue={fields.paymentMethod}
            noOptionsMsg={'No More Options Available'}
            renderOptionLabel={(opt: any) => <p>{opt.label}</p>}
            onOptionChange={onPaymentMethodChange}
            menuMaxHeight={150}
          />
        </Flex>
        <TextField
          id="value"
          value={(fields.value || '').toString()}
          onChange={({ target }) => onChangeFields(target.id, target?.rawValue)}
          width="100%"
          formatType="Currency_Input"
          margin="0 5px 0 0"
          label="Value"
          required
          autoComplete={false}
        />
        <TextField
          id="note"
          value={fields.note}
          onChange={({ target }) => onChangeFields(target.id, target?.rawValue)}
          width="100%"
          margin="0 5px 0 0"
          label="Note"
          placeholder="Insert a quick note"
        />
      </div>
    )
  }
)

const getAppointmentOptions = (appointments: Appointment[]) => {
  return appointments.map((appointment) => {
    const practiceUser = appointment?.practiceUsers[0]
    const date = formatDate(appointment.dateTime, 'ddd, MMM DD hh:mm A')
    const provider = `${practiceUser?.firstName}`
    return {
      label: `${date} - ${provider}`,
      value: appointment.id,
    }
  })
}
