import { Option } from '@ps-ui/components'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import {
  FunctionalDropdown,
  OptionWithType,
} from 'src/components/molecules/FunctionalDropdown'
import { ReferringDoctor } from 'src/schema-types'
import { BillingStore } from 'src/stores/domain/billing'

type ReferringDoctorDropdownProps = {
  referringDoctors: ReferringDoctor[] | null | undefined
  billingStore: BillingStore
}

const getLastName = (label: string) => {
  //Grab the length of the name when it is split out
  const length = label.split(' ').length
  // return the last name in the array, using length - 1
  return label.split(' ')[length - 1]
}

const sortByLastName = (a: { label: string }, b: { label: string }) => {
  const name1 = getLastName(a.label)
  const name2 = getLastName(b.label)
  if (name1 < name2) {
    return -1
  }
  if (name1 > name2) {
    return 1
  }
  return 0
}

const formatReferringDoctors = (referringDoctors: ReferringDoctor[]) => {
  return referringDoctors
    .map(({ name, NPI }) => {
      return {
        value: NPI,
        label: name,
      }
    })
    .sort(sortByLastName)
}

export const ReferringDoctorDropdown: React.FC<ReferringDoctorDropdownProps> =
  observer(({ referringDoctors, billingStore }) => {
    const handleOptionChange = async (
      opt: Option | OptionWithType[] | Option[]
    ) => {
      await billingStore.saveReferringDoctor(opt)
    }

    const formattedReferringDoctors =
      referringDoctors && formatReferringDoctors(referringDoctors)

    const [selectedReferringDoc, setSelectedReferringDoc] = useState<
      Option | undefined
    >(undefined)

    useEffect(() => {
      if (
        billingStore.referringDoctor &&
        Object.values(billingStore.referringDoctor).length
      ) {
        setSelectedReferringDoc(billingStore.referringDoctor)
      }
    }, [billingStore.referringDoctor])

    return (
      <div className="w-full pl-6 pr-6">
        <FunctionalDropdown
          dropdownOption={formattedReferringDoctors}
          onOptionChange={handleOptionChange}
          label="Referring Provider:"
          placeholder="Select a Referring Provider"
          currentSelection={selectedReferringDoc}
          disabled={!billingStore.allowChargesEdit}
          required={true}
        />
      </div>
    )
  })
