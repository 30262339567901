import { queryFeatureConfig } from 'src/gql/queries/queryFeatureConfig'
import { queryPracticeBillingCodes } from 'src/gql/queries/queryPracticeBillingCodes'
import { queryClinicalNoteTemplates } from 'src/gql/queries/queryPracticeClinicalNoteTemplates'
import { queryReferringDoctors } from 'src/gql/queries/queryReferringDoctors'
import { queryStatistics } from 'src/gql/queries/queryStatistics'

export const practiceServices = {
  getFeatureConfig: queryFeatureConfig,
  getStatistics: queryStatistics,
  getClinicalNoteTemplates: queryClinicalNoteTemplates,
  getBillingCodes: queryPracticeBillingCodes,
  getReferringDoctors: queryReferringDoctors,
}

export type PracticeServices = typeof practiceServices
