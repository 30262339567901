export const surfaceOptions = [
  { label: 'Even', value: 'even' },
  { label: 'Uneven', value: 'uneven' },
  { label: 'Carpet', value: 'carpet' },
  { label: 'Outdoor', value: 'outdoor' },
]
export const balanceStaticOptions = [
  { label: 'Static', value: 'static' },
  { label: 'Dynamic', value: 'dynamic' },
]
export const balancePositionOptions = [
  { label: 'Sitting Balance', value: 'sitting_balance' },
  { label: 'Standing Balance', value: 'standing_balance' },
]
export const balanceRatingOptions = [
  { label: 'Good+', value: 'good_plus' },
  { label: 'Good', value: 'good' },
  { label: 'Good-', value: 'good_minus' },
  { label: 'Fair+', value: 'fair_plus' },
  { label: 'Fair', value: 'fair' },
  { label: 'Fair-', value: 'fair_minus' },
  { label: 'Poor+', value: 'poor_plus' },
  { label: 'Poor', value: 'poor' },
]

export const durationUnitOptions = [
  {
    label: 'Day(s)',
    value: 'day',
  },
  {
    label: 'Week(s)',
    value: 'week',
  },
  {
    label: 'Month(s)',
    value: 'month',
  },
]

export const handrailOptions = [
  {
    label: 'No Handrail',
    value: 'no_handrail',
  },
  {
    label: 'Left Handrail',
    value: 'left_handrail',
  },
  {
    label: 'Right Handrail',
    value: 'right_handrail',
  },
  {
    label: 'Bilateral Handrail',
    value: 'bilateral_handrail',
  },
]

export const strengthOptions = [
  {
    label: 'WNL',
    value: 'wnl',
  },
  {
    label: 'WFL',
    value: 'wfl',
  },
  {
    label: '4+',
    value: 'four_plus',
  },
  {
    label: '4',
    value: 'four',
  },
  {
    label: '4-',
    value: 'four_minus',
  },
  {
    label: '3+',
    value: 'three_plus',
  },
  {
    label: '3',
    value: 'three',
  },
  {
    label: '3-',
    value: 'three_minus',
  },
  {
    label: '2+',
    value: 'two_plus',
  },
  {
    label: '2',
    value: 'two',
  },
  {
    label: '2-',
    value: 'two_minus',
  },
  {
    label: '1',
    value: 'one',
  },
]

export const rangeOfMotionOptions = [
  {
    label: 'PROM',
    value: 'prom',
  },
  {
    label: 'AAROM',
    value: 'aarom',
  },
  {
    label: 'AROM',
    value: 'arom',
  },
]

export const hepDeviceOptions = [
  {
    label: 'PROM HEP',
    value: 'prom_hep',
  },
  {
    label: 'AAROM HEP',
    value: 'aarom_hep',
  },
  {
    label: 'AROM HEP',
    value: 'arom_hep',
  },
  {
    label: 'Theraband HEP',
    value: 'theraband_hep',
  },
  {
    label: 'Strengthening HEP',
    value: 'strengthening_hep',
  },
]

export const hepCuesOptions = [
  {
    label: 'No Cues',
    value: 'no_cues',
  },
  {
    label: 'MIN Cues',
    value: 'min_cues',
  },
  {
    label: 'MOD Cues',
    value: 'mod_cues',
  },
  {
    label: 'MAX Cues',
    value: 'max_cues',
  },
]

export const transfersOptions = [
  {
    label: 'Sit to Stand',
    value: 'sit_to_stand',
  },
  {
    label: 'Chair',
    value: 'chair',
  },
  {
    label: 'Wheelchair',
    value: 'wheel_chair',
  },
  {
    label: 'Toilet',
    value: 'toilet',
  },
  {
    label: 'Car',
    value: 'car',
  },
]
