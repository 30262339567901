import { gql } from 'apollo-boost'
import {
  UpdateClinicalNoteTemplateMutation,
  UpdateClinicalNoteTemplateMutationVariables,
} from 'src/operations-types'
import { apolloClient } from 'src/settings/apolloClient'
import { clinicalNoteModularFragment } from '../fragments/clinicalNoteModular'

export const UPDATE_CLINICAL_NOTE_TEMPLATE = gql`
  mutation updateClinicalNoteTemplate($id: ID!, $clinicalNoteTemplateId: ID!) {
    updateClinicalNoteModular(
      id: $id
      input: { clinicalNoteModularTemplateId: $clinicalNoteTemplateId }
    ) {
      ...clinicalNoteModular
    }
  }
  ${clinicalNoteModularFragment}
`

export const updateClinicalNoteTemplate = async (
  clinicalNoteId: string,
  clinicalNoteTemplateId: string
) => {
  const updateClinicalNoteTemplateVariables: UpdateClinicalNoteTemplateMutationVariables =
    {
      id: clinicalNoteId,
      clinicalNoteTemplateId,
    }

  const response =
    await apolloClient.mutate<UpdateClinicalNoteTemplateMutation>({
      mutation: UPDATE_CLINICAL_NOTE_TEMPLATE,
      variables: updateClinicalNoteTemplateVariables,
    })

  if (response.errors?.length) {
    throw new Error(response.errors[0].message)
  }

  const clinicalNoteTemplate = response.data?.updateClinicalNoteModular.template

  return clinicalNoteTemplate
}
