import differenceInYears from 'date-fns/differenceInYears'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

//Formats date to local time if formatting is extended to time
export const formatDate = (
  date?: dayjs.ConfigType,
  format: string = 'MMM DD, YYYY'
): string => {
  return date ? dayjs(date).local().format(format) : ''
}

export const serviceDateFormat = (date: dayjs.ConfigType) => {
  return formatDate(date, 'YYYY-MM-DD')
}

export const formatUnixDate = (
  unixDate: number,
  format: string = 'MMM DD, YYYY'
): string => {
  return dayjs.unix(unixDate).format(format)
}

/**
 * Format date from input to AWS Date type
 * @param date
 * @param time
 */
export const formatDateAndTimeAWS = (date?: string, time?: string) => {
  const dateArr = date ? date.split('/') : []
  let formatDate = dateArr.length
    ? `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`
    : ''
  if (time && !!formatDate) {
    formatDate = `${formatDate}T${time}:00Z`
  }
  return formatDate
}

/**
 * Calculate age based on birth date
 * @param birthDate
 */
export const calcAge = (birthDate: Date): number => {
  return differenceInYears(new Date(), birthDate)
}

/**
 * Format date from AWS date type to regular date
 * @param date
 */
export const formatDateFromAWS = (date?: string) => {
  const dateArr = date ? date.split('-') : []
  let formatDate = dateArr.length
    ? `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`
    : ''
  return formatDate
}
