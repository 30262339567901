import {
  rangeOfNumbersInOptions,
  turnIntoOption,
} from 'src/pages/ClinicalNotesv2/utils'

export const type = turnIntoOption(['Ascending', 'Descending'])
export const repetitions = rangeOfNumbersInOptions(15)
export const assistiveDevice = turnIntoOption([
  '4WW',
  'FWW',
  'Platform walker',
  'SPC',
  'QC',
  'Hemi-walker',
  'Axillary Crutch',
  'Lofstrand crutches',
])

export const handrail = turnIntoOption(['Left', 'Right'])
export const support = turnIntoOption([
  'AFO-L or R',
  'KAFO-L or R',
  'Knee brace-L or R',
])
export const tolerance = turnIntoOption(['Excellent', 'Good', 'Fair', 'Poor'])
export const levelOfAssist = turnIntoOption([
  'IND',
  'MOD I',
  'SUP',
  'SBA',
  'CGA',
  'MIN A',
  'MOD A',
  'MAX A',
  'DEP',
])
