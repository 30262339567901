import clsx from 'clsx'
import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ClinicalNotesInput } from '../Input'

const reflexesOptions = [
  {
    value: 'Babinski',
    action: 'Sole of foot stroked',
    response: 'Fans out toes and twists foot in',
  },
  {
    value: 'Blinking',
    action: 'Flashing light or puff of air',
    response: 'Closes eyes',
  },
  {
    value: 'Sucking',
    action: 'Mouth touched by object',
    response: 'Suck on object',
  },
  {
    value: 'Moro',
    action: 'Sudden move, loud noise',
    response: 'Startles, throws out arms and legs',
  },
  {
    value: 'Grasping',
    action: 'Palms touched',
    response: 'Grasps tightly',
  },
  {
    value: 'Rooting',
    action: 'Cheek stroked',
    response: 'Turns toward source, mouth opens',
  },
  {
    value: 'ANTR',
    action: 'Rotation of the head',
    response:
      'Arm and leg of the jaw side extends Arm and leg of skull side flexes',
  },
  {
    value: 'SNTR',
    action: 'Flexion and extension of the neck',
    response:
      'With flexion: Neck will flex and legs extend With extension: Neck will extend and legs will flex',
  },
  {
    value: 'Landau',
    action: 'Holding child in vertical extension',
    response: 'Extension of head, spide and legs',
  },
  {
    value: 'Parachute',
    action: 'Chid is in vertical position and lowered quickly',
    response: 'Arms extend as reaction',
  },
]

export const Reflexes: React.FC<{ name: string }> = ({ name }) => {
  const { control } = useFormContext()
  return (
    <div className="flex flex-col">
      <Controller
        name={`${name}.reflexes`}
        control={control}
        render={({ field: { value, onChange } }) => (
          <div className="flex flex-col">
            <li className="flex w-full py-3 px-5">
              <span className="w-full">REFLEX</span>
              <span className="w-full">ACTION</span>
              <span className="w-full">RESPONSE</span>
            </li>
            {reflexesOptions.map((reflex) => (
              <div className="border-b" key={reflex.value}>
                <li
                  className={clsx(
                    'flex w-full py-3 my-3 px-5 cursor-pointer',
                    value &&
                      value.includes(reflex.value) &&
                      'bg-primary bg-opacity-30'
                  )}
                  onClick={() => {
                    if (!!value) {
                      if (value.includes(reflex.value)) {
                        onChange(
                          value.filter((elem: string) => elem !== reflex.value)
                        )
                      } else {
                        onChange([...value, reflex.value])
                      }
                    } else {
                      onChange([reflex.value])
                    }
                  }}
                >
                  <span className="w-full flex items-center font-bold">
                    <div
                      className={clsx(
                        'w-3 h-3 border mr-3',
                        value && value.includes(reflex.value)
                          ? 'bg-primary border-primary'
                          : 'border-gray-500'
                      )}
                    ></div>
                    {reflex.value}
                  </span>
                  <span className="w-full">{reflex.action}</span>
                  <span className="w-full">{reflex.response}</span>
                </li>
              </div>
            ))}
          </div>
        )}
      />
      <div className="flex flex-col mb-8 mt-3">
        <label className="mb-3 font-bold">Additional notes</label>
        <ClinicalNotesInput
          name={`${name}.notes`}
          className="w-full h-28"
          type="textarea"
        />
      </div>
    </div>
  )
}
