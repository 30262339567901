'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.RELATIONSHIP_METHODS =
  exports.MedicationStatus =
  exports.MedicationFrequency =
  exports.CareTeamMemberStatus =
    void 0
var MedicationFrequency
exports.MedicationFrequency = MedicationFrequency
;(function (MedicationFrequency) {
  MedicationFrequency['qd'] = 'Daily'
  MedicationFrequency['bid'] = 'Two times daily'
  MedicationFrequency['tid'] = 'Three times daily'
  MedicationFrequency['qid'] = 'Four times daily'
  MedicationFrequency['q6h'] = 'Every 6 hours'
  MedicationFrequency['q8h'] = 'Every 8 hours'
  MedicationFrequency['q12h'] = 'Every 12 hours'
  MedicationFrequency['q24h'] = 'Every 24 hours'
  MedicationFrequency['qod'] = 'Every other day'
  MedicationFrequency['qm'] = 'Once a month'
  MedicationFrequency['an'] = 'As needed'
  MedicationFrequency['other'] = 'Other'
})(MedicationFrequency || (exports.MedicationFrequency = MedicationFrequency = {}))

var MedicationStatus
exports.MedicationStatus = MedicationStatus
;(function (MedicationStatus) {
  MedicationStatus['true'] = 'active'
  MedicationStatus['false'] = 'deactive'
})(MedicationStatus || (exports.MedicationStatus = MedicationStatus = {}))

var CareTeamMemberStatus
exports.CareTeamMemberStatus = CareTeamMemberStatus
;(function (CareTeamMemberStatus) {
  CareTeamMemberStatus['true'] = 'I\u2019m an active patient'
  CareTeamMemberStatus['false'] = 'I\u2019m a former patient'
})(CareTeamMemberStatus || (exports.CareTeamMemberStatus = CareTeamMemberStatus = {}))

var RELATIONSHIP_METHODS = [
  {
    value: 'SELF',
    label: 'Self',
  },
  {
    value: 'SPOUSE',
    label: 'Spouse',
  },
  {
    value: 'CHILD',
    label: 'Child',
  },
  {
    value: 'EMPLOYEE',
    label: 'Employee',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
]
exports.RELATIONSHIP_METHODS = RELATIONSHIP_METHODS
