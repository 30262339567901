import gql from 'graphql-tag'
import { clinicalNoteModularAmendmentFragment } from './clinicalNoteModularAmendment'
import { clinicalNoteModularAnswerFragment } from './clinicalNoteModularAnswer'

export const clinicalNoteModularFragment = gql`
  fragment clinicalNoteModular on ClinicalNoteModular {
    id
    createdBy {
      id
    }
    patient {
      id
      firstName
      lastName
      dateOfBirth
      medicalInformation {
        label
        value
        fieldTemplate {
          id
          defaultLabel
        }
      }
    }
    icdCode {
      id
      code
      type
      description
    }
    status
    amendments {
      ...clinicalNoteModularAmendment
    }
    answers {
      ...clinicalNoteAnswer
    }
    template {
      id
      name
      practice {
        id
        name
        email
        phone
        branding {
          logo {
            downloadUrl
          }
        }
      }
      pages {
        id
        title
        ordinal
        sections {
          id
          name
          description
          ordinal
          isModulePicker
          modules {
            id
            label
            description
            type
            fields {
              id
              label
              elementType
              answerOptions
              defaultAnswer
            }
          }
        }
      }
    }
  }
  ${clinicalNoteModularAmendmentFragment}
  ${clinicalNoteModularAnswerFragment}
`
