export const adlTabs = [
  {
    value: '1',
    label: 'Feeding',
  },
  {
    value: '2',
    label: 'Bathing',
  },
  {
    value: '3',
    label: 'Grooming',
  },
  {
    value: '4',
    label: 'Toileting',
  },
  {
    value: '5',
    label: 'LB Dressing',
  },
  {
    value: '6',
    label: 'UB Dressing',
  },
]
export const iadlTabs = [
  {
    value: '1',
    label: 'Housekeeping',
  },
  {
    value: '2',
    label: 'Laundry',
  },
  {
    value: '3',
    label: 'Dishwashing',
  },
  {
    value: '4',
    label: 'Meal Preparation',
  },
]

export const TabFieldLabels = {
  cadence: {
    option1: 'Cadence',
  },
  support: {
    option1: 'Orthotics Left',
    option2: 'Orthotics Right',
  },
  measurements: {
    option1: 'Duration',
    option2: 'Length',
    'time-label': '',
  },
  deviations: {
    option1: 'Deviations',
  },
  'assistive device': {
    option1: 'Equipment',
  },
  entrance: {
    option1: 'Entrance',
    option2: 'Location',
    option3: 'Number',
  },
  equipment: {
    option1: 'Assistive devices',
  },
  bathroom: {
    option1: 'Equipment',
  },
  levels: {
    option1: 'Handrail',
    option2: 'Number',
    option3: 'Steps',
  },
  shower: {
    option1: 'Levels',
  },
  tub: {
    option1: 'Levels',
  },
  toilet: {
    option1: 'Levels',
  },
  chair: {
    option1: 'Levels',
  },
  wheelchair: {
    option1: 'Levels',
  },
  car: {
    option1: 'Levels',
  },
}
