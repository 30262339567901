import { gql } from 'apollo-boost'
import { Query } from 'src/schema-types'

export interface QueryAssignedFormResponse {
  getAssignedForm: Query['getAssignedForm']
}

export const QUERY_ASSIGNED_FORM = gql`
  query getAssignedForm($id: ID!) {
    getAssignedForm(id: $id) {
      id
      patientProfile {
        id
        firstName
        lastName
        dateOfBirth
      }
      form {
        id
        name
        practice {
          id
          name
          phone
          fax
          branding {
            logo {
              downloadUrl
            }
          }
        }
        pages {
          id
          title
          header
          ordinal
          createdOn
          sections {
            id
            title
            ordinal
            createdOn
            fields {
              id
              elementType
              ordinal
              label
              isRequired
              minimumLength
              maximumLength
              answerOptions
              defaultAnswer
              visibilityCondition {
                fieldId
                answer
              }
              updatedOn
            }
            updatedOn
          }
          updatedOn
        }
      }
      isComplete
      isSubmitted
      answers {
        id
        isCompoundField
        answerType
        field {
          id
          elementType
        }
        answer
        answerNames {
          firstName
          lastName
          middleName
          suffix
        }
        answerAddress {
          street1
          street2
          city
          state
          zip
        }
        answerContact {
          name
          phoneNumber
          email
          dateOfBirth
          relationship
        }
        answerSurgery {
          type
          date
        }
        answerInsurance {
          subscriberFirstName
          subscriber
          subscriberDob
          insuranceCompany
          insuranceGroupNumber
          insurancePolicyNumber
          subscriberRelationship
        }
        answerEmployment {
          jobPosition
          phoneNumber
          employerName
        }
        answerMedication {
          name
          dosage
          startDate
          endDate
          comments
          status
          frequency
        }
        answerCareTeamMember {
          firstName
          lastName
          type
          status
          fax
          email
          phone
        }
      }
      updatedOn
    }
  }
`
