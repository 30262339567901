import { Link } from 'react-router-dom'
import { ReactComponent as PopperIcon } from 'src/images/popper.svg'
import styled from 'styled-components'

export const Wrappper = styled.div`
  max-width: 750px;
  margin: 0 auto;
  padding: 30px 80px;
  text-align: center;
  background: transparent linear-gradient(233deg, #08b175 0%, #098b5d 100%) 0%
    0% no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  box-sizing: border-box;
`

export const Popper = styled(PopperIcon)`
  min-width: 80px;
`

export const SuccessHeader = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const Subtitle = styled.h2`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 32px;
  margin-bottom: 24px;
  ${(p) => p.theme.breakpoints.tablets} {
    font-size: 24px;
  }
`

export const PatientLink = styled(Link)`
  display: inline-block;
  font-size: 24px;
  color: #fff;
  text-decoration: underline;
  margin-bottom: 32px;
  line-height: 1.4;
`
export const InfoList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  & > li {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 16px;
  }
  & > li:before {
    content: '- ';
  }
`
