import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import React from 'react'
import { ParsedFieldAnswer } from 'src/pages/ClinicalNotesv2/components/ParsedFieldAnswer'
import { ParsedClinicalNoteField } from 'src/stores/domain/clinicalNotes'
import { useStore } from 'src/stores/store'
import { sortByOrdinance } from 'src/utils/sorting'
import { WFLFElementTypes } from '../../types'

type ParsedClinicalNoteProps = {}

export const ParsedClinicalNote: React.FC<ParsedClinicalNoteProps> = observer(
  () => {
    const { clinicalNotesStore } = useStore()

    const moduleHasAnswers = (
      fieldsWithParsedAnswer: ParsedClinicalNoteField[]
    ) => {
      return fieldsWithParsedAnswer.some(fieldHasAnswers)
    }

    const fieldHasAnswers = (field: ParsedClinicalNoteField) => {
      const hasWFL = WFLFElementTypes.includes(field.elementType)
      const hasAnswer = field.answer
      const hasAmend = field.amends?.some((amend) => amend?.newField.answer)

      return hasAnswer || hasAmend || hasWFL
    }

    return (
      <>
        {clinicalNotesStore.clinicalNoteSections
          .slice()
          .sort(sortByOrdinance)
          .map((section) => {
            return (
              <div className="ml-2.5 mb-3 w-full">
                <h3 className="font-bold p-2 mb-5 w-full bg-gray-300">
                  {section.name}
                </h3>
                {section.modules.map((module) => {
                  const fieldsWithParsedAnswers =
                    clinicalNotesStore.fieldsWithParsedAnswers[module.id]

                  return moduleHasAnswers(fieldsWithParsedAnswers) ? (
                    <div className="pl-5 pr-1">
                      <h4 className="font-bold rounded-lg p-2 mb-5 bg-gray-200 w-full">
                        {module.label}
                      </h4>
                      <div className="ml-2.5 mb-3 grid grid-cols-2 col-auto w-full">
                        {fieldsWithParsedAnswers.map((field) => {
                          return fieldHasAnswers(field) ? (
                            <div className="pr-5 w-full">
                              <div className="flex flex-col ">
                                <ParsedFieldAnswer field={field} />
                                {field.amends?.map((amend) => (
                                  <div className="flex-flex-col">
                                    <p>
                                      Amended on{' '}
                                      {dayjs
                                        .unix(amend?.createdOn)
                                        .format('MM/DD/YYYY')}
                                    </p>
                                    {amend && (
                                      <ParsedFieldAnswer
                                        field={amend.newField}
                                      />
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : null
                        })}
                      </div>
                    </div>
                  ) : null
                })}
              </div>
            )
          })}
      </>
    )
  }
)
