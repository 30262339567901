import { gql } from 'apollo-boost'
import { QueryAllPatientsQuery } from 'src/operations-types'
import { PaginationInput, PatientProfile } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export const SEARCH_PATIENTS = gql`
  query queryAllPatients($input: String!, $pagination: PaginationInput) {
    searchPatientProfiles(input: $input, pagination: $pagination) {
      entities {
        ... on PatientProfile {
          id
          email
          phoneNumber
          firstName
          lastName
          dateOfBirth
          family {
            id
            name
            email
            phoneNumber
            createdOn
            updatedOn
            members {
              id
              firstName
              lastName
              dateOfBirth
            }
          }
          attachments {
            id
            fileName
            createdOn
            downloadUrl
          }
          assignedForms {
            id
            form {
              id
              name
              createdOn
            }
            isComplete
            isSubmitted
            createdOn
            updatedOn
          }
          appointments {
            entities {
              ... on Appointment {
                id
                dateTime
                status
                appointmentType {
                  name
                  description
                }
                practiceUsers {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
      pageInfo {
        currentPageNumber
        hasMoreResults
        lastPageNumber
        pageSize
        totalEntities
      }
    }
  }
`

export type QueryPatientsBySearchResp = {
  patients: PatientProfile[] | undefined
  hasMore: boolean | undefined
}

export const queryPatientsBySearch = async (
  input: string,
  pagination: PaginationInput
): Promise<QueryPatientsBySearchResp> => {
  const response = await apolloClient.query<QueryAllPatientsQuery>({
    query: SEARCH_PATIENTS,
    fetchPolicy: 'no-cache',
    variables: {
      input,
      pagination,
    },
  })

  return {
    patients: response.data.searchPatientProfiles?.entities.map(
      (patient) => patient as PatientProfile
    ),
    hasMore: response.data.searchPatientProfiles?.pageInfo.hasMoreResults,
  }
}
