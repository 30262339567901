import styled from 'styled-components/macro'

export const HiddenInput = styled.input<InputProps>`
  &:checked + div {
    color: #fff;
    background-color: ${({ bgColor, theme }: InputProps) =>
      bgColor ? bgColor : theme.colors.primary};
  }
  &:focus + div {
    box-shadow: 0 0 0 2px rgba(8, 177, 117, 1);
  }
  &:disabled + div {
    opacity: 0.5;
    pointer-events: none;
  }
`

type InputProps = {
  theme: any
  bgColor?: string
}

export default HiddenInput
