import { queryLogin } from 'src/gql/queries/login'
import { queryToken } from 'src/gql/queries/queryRefreshTokens'

/*
  The big question: why are we creating service objects if we already
  have queries and mutations?

  Queries and mutations are necessary to stay in sync with the API schema.
  Service objects allow us to group these queries and mutations by domain and
  give them context that makes sense for the UI.

  It's pretty much plug-and-play, since we can easily add, remove, and swap
  the definition of our services without messing with our stores much. This
  also makes unit testing & configuring storybook much easier, since we can
  mock any service we need to.
  
*/
export const authServices = {
  login: queryLogin,
  getToken: queryToken,
}

// TypeScript is great at type inferencing, so we let it do all the heavy lifting here.
export type AuthServices = typeof authServices
