import styled from 'styled-components/macro'

type Props = {
  isExpand: boolean
  margin?: string
  maxWidth?: string
}

export const Wrapper = styled.div<Props>`
  box-shadow: 0px 4px 10px -6px rgba(0, 0, 0, 0.25);
  max-height: ${(props) => (props.isExpand ? 'auto' : '320px')};
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
`
export const ToggleButtonContainer = styled.div<Props>`
  position: ${(props) => (props.isExpand ? '' : 'absolute')};
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  align-items: center;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  padding: ${(props) => (props.isExpand ? '10px 0' : '10px 0')};
  box-shadow: ${(props) =>
    props.isExpand ? 'none' : '0px -5px 14px -9px rgba(0,0,0,0.25)'};
  cursor: pointer;
`
