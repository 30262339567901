import {
  ConversationEvents,
  conversationModule,
  ConversationState,
} from 'src/state/conversations'
import { MessageEvents, messageModule, MessageState } from 'src/state/messages'
import {
  SchedulingEvents,
  SchedulingState,
  schedulingStateModule,
} from 'src/state/scheduling/schedulingState'
import {
  schedulingModule,
  SchedulingPracticeEvents,
  SchedulingPracticeState,
} from 'src/state/scheduling/schedulingState/practiceData'
import { createStoreon } from 'storeon'
import { storeonDevtools } from 'storeon/devtools'

export type StoreState = SchedulingPracticeState &
  SchedulingState &
  MessageState &
  ConversationState
export type StoreEvents = SchedulingPracticeEvents &
  SchedulingEvents &
  MessageEvents &
  ConversationEvents

export const store = createStoreon<StoreState, StoreEvents>([
  schedulingModule,
  schedulingStateModule,
  messageModule,
  conversationModule,
  process.env.NODE_ENV !== 'production' && storeonDevtools,
])
