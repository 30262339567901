import * as T from 'src/schema-types'

export const fakeClinicalNotes = [
  {
    id: 'ce683d6d-b3c0-48b8-9ec7-66b802420fd6',
    createdOn: '',
    updatedOn: '',
    clinicalNoteTemplate: {
      name: 'testing template',
      sections: [
        {
          id: '642220bc-f791-490c-9ab3-8fd329006a82',
          name: 'Assesment',
          description: 'Just a section for testing',
          clinicalNoteFieldGroups: [
            {
              id: 'asdasdasd',
              title: 'Range of Motion',
              fields: [
                {
                  id: 'asdasdasdkk',
                  label: 'Upper extremities WFL',
                  elementType: T.ElementTypes.TextInput,
                },
                {
                  id: 'asdasdasdadd',
                  label: 'Lower extremities WFL',
                  elementType: T.ElementTypes.TextInput,
                },
              ],
            },
            {
              id: 'alsdmaslvhgfhjgh',
              title: 'Pain',
              fields: [
                {
                  id: 'asdasdasdkk',
                  label: 'Pain',
                  elementType: T.ElementTypes.TextInput,
                },
              ],
            },
            {
              id: 'asdasdasdafsdf',
              title: 'Balance',
              fields: [
                {
                  id: 'asdasfsdgdfg',
                  label: 'Balance',
                  elementType: T.ElementTypes.TextInput,
                },
              ],
            },
            {
              id: 'asfkljsfgpqerasdbbn',
              title: 'Posture',
              fields: [
                {
                  id: 'dfsdlifnmncvx',
                  label: 'Posture',
                  elementType: T.ElementTypes.TextInput,
                },
              ],
            },
            {
              id: 'knlfsdkfnlsd',
              title: 'Vital Signs',
              fields: [
                {
                  id: 'asdasdasdkkasf',
                  label: 'Blood Pressure',
                  elementType: T.ElementTypes.TextInput,
                },
                {
                  id: 'asdasdasdkkasfsaf',
                  label: 'Heart Rate',
                  elementType: T.ElementTypes.TextInput,
                },
                {
                  id: 'fgdfjhgjgh',
                  label: 'Oxygen',
                  elementType: T.ElementTypes.TextInput,
                },
                {
                  id: 'lksjfglkdf',
                  label: 'Respiration Rate',
                  elementType: T.ElementTypes.TextInput,
                },
                {
                  id: 'asdasdasdkkasfghjgh',
                  label: 'Temperature',
                  elementType: T.ElementTypes.TextInput,
                },
                {
                  id: 'asdasflppmaieud9233',
                  label: 'Additional Notes',
                  elementType: T.ElementTypes.TextArea,
                },
              ],
            },
          ],
        },
        {
          id: '642220bc-f791-490c-9ab3-8fd329006a82',
          name: 'Goals',
          description: 'Just a section for testing',
          clinicalNoteFieldGroups: [],
        },
      ],
    },
  },
]
