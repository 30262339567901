import { DateSelectArg, EventApi } from '@fullcalendar/react'
import { Option } from '@ps-ui/components'
import { ReactNode } from 'react'
import { AppearanceTypes, Options } from 'react-toast-notifications'
import { rescheduleAppointment } from 'src/gql/mutations/rescheduleAppointment'
import { cancelExistingAppointment } from 'src/gql/mutations/updateAppointment'
import { AppointmentStatus } from 'src/schema-types'
import { formatDateToString } from '../transforms/formatDateToString'
import {
  AppointmentTypeData,
  CalendarAppointment,
  ModalType,
  NewAppointmentTimes,
} from './calendarInterfaces'

// Runs when an open time slot is clicked. Populates data required for rendering the CreateAppointment Modal and EditAppointment Modal
export const beginAppointmentCreation = (
  selectInfo: DateSelectArg,
  isRescheduleMode: boolean,
  appointmentType: AppointmentTypeData | undefined,
  selectedAppointment: CalendarAppointment | undefined,
  setNewAppointmentTimes: (
    value: React.SetStateAction<NewAppointmentTimes | undefined>
  ) => void,
  setActiveModal: (value: React.SetStateAction<ModalType>) => void,
  setModalIsOpen: (value: React.SetStateAction<boolean>) => void
) => {
  let duration
  if (!isRescheduleMode) {
    duration = appointmentType?.activeAppointmentType
      ? appointmentType?.activeAppointmentType?.duration
      : 30
  } else {
    duration =
      appointmentType &&
      appointmentType.allAppointmentTypes &&
      appointmentType.allAppointmentTypes.find(
        (appType) => appType?.id === selectedAppointment?.appointmentTypeId
      )?.duration
    duration = duration ? duration : 30
  }
  let endDate = new Date(selectInfo.start.getTime() + duration * 60000)
  const newAppointmentTime: NewAppointmentTimes = {
    start: selectInfo.start,
    timeString: formatDateToString(selectInfo.start, endDate),
    end: endDate,
  }
  setNewAppointmentTimes(newAppointmentTime)
  if (isRescheduleMode) {
    setActiveModal(ModalType.RescheduleAppointmentReason)
  } else {
    setActiveModal(ModalType.CreateAppointment)
  }
  setModalIsOpen(true)
}

// Runs when 'Confirm' button is clicked in ConfirmAppointmentCancellation Modal
export const cancelAppointment = async (
  setModalLoading: (value: React.SetStateAction<boolean>) => void,
  selectedAppointment: CalendarAppointment | undefined,
  reasonIsLate: boolean,
  activeReason: Option,
  addToast: (
    content: ReactNode,
    options?: Options | undefined,
    callback?: ((id: string) => void) | undefined
  ) => void,
  setAppointmentActionLoading: (value: React.SetStateAction<boolean>) => void
) => {
  setModalLoading(true)
  let toastMessage
  let toastStatus: AppearanceTypes = 'error'
  setAppointmentActionLoading(true)
  try {
    if (selectedAppointment) {
      await cancelExistingAppointment(selectedAppointment.id, {
        status: reasonIsLate
          ? AppointmentStatus.LateCancelled
          : AppointmentStatus.Cancelled,
        patientUpdateReason: activeReason.value,
      })
    }
  } catch (e) {
    console.error(e)
    toastMessage = e.message
    toastStatus = 'error'
  } finally {
    setModalLoading(false)
    setAppointmentActionLoading(false)
    toastMessage && addToast(toastMessage, { appearance: toastStatus })
  }
}

export const rescheduleCurrentAppointment = async (
  setModalLoading: (value: React.SetStateAction<boolean>) => void,
  reasonIsLate: boolean,
  activeReason: Option,
  selectedAppointment: CalendarAppointment | undefined,
  newAppointmentTimes: NewAppointmentTimes | undefined,
  addToast: (
    content: ReactNode,
    options?: Options | undefined,
    callback?: ((id: string) => void) | undefined
  ) => void,
  setAppointmentActionLoading: (value: React.SetStateAction<boolean>) => void
) => {
  setModalLoading(true)
  let toastMessage
  let toastStatus: AppearanceTypes = 'error'
  try {
    setAppointmentActionLoading(true)
    if (selectedAppointment) {
      await rescheduleAppointment(selectedAppointment.id, {
        dateTime: newAppointmentTimes?.start,
        isLate: reasonIsLate,
        patientUpdateReason: activeReason.value,
      })
    }
  } catch (e) {
    console.error(e)
    toastMessage = e.message
    toastStatus = 'error'
  } finally {
    setModalLoading(false)
    setAppointmentActionLoading(false)
    toastMessage && addToast(toastMessage, { appearance: toastStatus })
  }
}
// Runs when an appointment is clicked. Opens EditAppointment Modal
export const editAppointmentClick = (
  event: EventApi,
  allCalendarEvents: CalendarAppointment[] | undefined,
  setSelectedAppointment: (
    value: React.SetStateAction<CalendarAppointment | undefined>
  ) => void,
  setActiveModal: (value: React.SetStateAction<ModalType>) => void,
  setModalIsOpen: (value: React.SetStateAction<boolean>) => void
) => {
  const clickedAppointment = allCalendarEvents?.find(
    (calendarAppointment) => calendarAppointment.id === event.id
  )
  setSelectedAppointment(clickedAppointment)
  setActiveModal(ModalType.EditAppointment)
  setModalIsOpen(true)
}

// Confirms an appointment on click
export const toggleAppointmentIsConfirmed = async (
  selectedAppointment: CalendarAppointment | undefined,
  setAppointmentConfirmationError: (
    value: React.SetStateAction<boolean>
  ) => void,
  setSelectedAppointment: (
    value: React.SetStateAction<CalendarAppointment | undefined>
  ) => void
) => {
  if (selectedAppointment && !selectedAppointment.isConfirmed) {
    try {
      const newAppointment: CalendarAppointment = {
        ...selectedAppointment,
        isConfirmed: true,
      }
      setSelectedAppointment(newAppointment)
      await cancelExistingAppointment(selectedAppointment.id, {
        isConfirmed: !selectedAppointment.isConfirmed,
      })
    } catch (e) {
      console.error(e)
    }
  } else {
    setAppointmentConfirmationError(true)
  }
}
