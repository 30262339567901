import { createDoctorNote } from 'src/gql/mutations/createDoctorNote'
import { createPatientProfileAttachment } from 'src/gql/mutations/createPatientProfileAttachment'
import { deleteDoctorNote } from 'src/gql/mutations/deleteDoctorNote'
import { deletePatientProfileAttachment } from 'src/gql/mutations/deletePatientProfileAttachment'
import { updateDoctorNote } from 'src/gql/mutations/editDoctorNote'
import { rescheduleAppointment } from 'src/gql/mutations/rescheduleAppointment'
import { notificationResend } from 'src/gql/mutations/resendNotification'
import { editMedicalDataPoint } from 'src/gql/mutations/saveMedicalDataPoint'
import { queryPatientById } from 'src/gql/queries/queryPatientById'

export const patientServices = {
  getPatientById: queryPatientById,
  editDataPoint: editMedicalDataPoint,
  createNote: createDoctorNote,
  updateNote: updateDoctorNote,
  deleteNote: deleteDoctorNote,
  rescheduleAppointment,
  createAttachment: createPatientProfileAttachment,
  deleteAttachment: deletePatientProfileAttachment,
  resendNotification: notificationResend,
}

export type PatientServices = typeof patientServices
