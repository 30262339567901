import styled from 'styled-components/macro'

export const Title = styled.h3`
  font-size: 35px;
  font-weight: 400;
  margin-top: 0;

  ${(props) => props.theme.breakpoints.tablets} {
    font-size: 30px;
  }
`
