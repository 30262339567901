import {
  AssignedForm,
  AssignedFormAnswer,
  ElementTypes,
  FormField,
} from 'src/schema-types'
import {
  buildAddressFields,
  buildCareTeamMemberFields,
  buildContactFields,
  buildEmploymentFields,
  buildInsuranceFields,
  buildMedicationFields,
  buildNamesFields,
  buildSurgeryFields,
  PrintableAnswer,
} from './buid-compound-answer'

const compoundElements = [
  'Address',
  'CareTeamMember',
  'Employment',
  'Insurance',
  'Medication',
  'Names',
  'Surgery',
  'Contact',
]

/**
 * Check if the field is a compound field
 * @param elementType
 */
export const checkIsCompound = (elementType?: ElementTypes) => {
  let isCompound = false
  if (elementType) {
    if (compoundElements.includes(elementType)) {
      isCompound = true
    }
  }
  return isCompound
}

/**
 * Get the answer object from assigned form using the field id
 * @param fieldId
 * @param assignedForm
 */
export const getFullAnswers = (
  fieldId: string,
  assignedForm?: AssignedForm | null
) => {
  return assignedForm?.answers?.find((answer) => answer?.field.id === fieldId)
}

/**
 * Get form fields to render base base on elementType
 * @param field
 * @param shouldBeRequired
 */
export const getCompoundAnswersBasedOnType = (
  field: FormField,
  answer?: AssignedFormAnswer | null
): PrintableAnswer[] => {
  let answerFields: PrintableAnswer[] = []
  switch (field.elementType) {
    case ElementTypes.Address:
      answerFields = buildAddressFields(
        field,
        answer?.answerAddress?.length ? answer.answerAddress[0] : undefined
      )
      break
    case ElementTypes.Names:
      answerFields = buildNamesFields(
        field,
        answer?.answerNames?.length ? answer.answerNames[0] : undefined
      )
      break
    case ElementTypes.Employment:
      answerFields = buildEmploymentFields(
        field,
        answer?.answerEmployment?.length
          ? answer.answerEmployment[0]
          : undefined
      )
      break
    case ElementTypes.Insurance:
      answerFields = buildInsuranceFields(
        field,
        answer?.answerInsurance?.length ? answer.answerInsurance[0] : undefined
      )
      break
    case ElementTypes.Contact:
      answerFields = buildContactFields(
        field,
        answer?.answerContact?.length ? answer.answerContact[0] : undefined
      )
      break
    case ElementTypes.Medication:
      answerFields =
        (answer?.answerMedication?.length &&
          answer.answerMedication.flatMap((answerMedication) =>
            buildMedicationFields(field, answerMedication || undefined)
          )) ||
        []
      break
    case ElementTypes.CareTeamMember:
      answerFields = buildCareTeamMemberFields(
        field,
        answer?.answerCareTeamMember?.length
          ? answer.answerCareTeamMember[0]
          : undefined
      )
      break
    case ElementTypes.Surgery:
      answerFields = buildSurgeryFields(
        field,
        answer?.answerSurgery?.length ? answer.answerSurgery[0] : undefined
      )
      break
    default:
      answerFields = [
        {
          id: field.id,
          label: field.label,
          value: answer?.answer?.length ? answer.answer : [''],
          options: field.answerOptions,
          elementType: field.elementType,
          defaultValue: field.defaultAnswer,
        },
      ]
      break
  }
  return answerFields
}
