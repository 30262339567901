import { gql } from 'apollo-boost'
import { GetClinicalNoteByIdQuery } from 'src/operations-types'
import { Appointment } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface QueryClinicalNoteResponse {
  getAppointment: Appointment
}

export const GET_CLINICAL_NOTE_BY_ID = gql`
  query getClinicalNoteById($id: ID!) {
    getClinicalNote(id: $id) {
      id
      status
      body
      createdOn
      updatedOn
      __typename
      amendmentComponents {
        id
        body
        createdOn
        __typename
      }
    }
  }
`

export const queryOldClinicalNoteById = async (id: string) => {
  const response = await apolloClient.query<GetClinicalNoteByIdQuery>({
    query: GET_CLINICAL_NOTE_BY_ID,
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  })

  return response.data.getClinicalNote
}

export const GET_CLINICAL_NOTE = gql`
  query clinicalNoteByID($id: ID!) {
    getAppointment(id: $id) {
      id
      dateTime
      description
      createdOn
      patientProfile {
        id
        firstName
        lastName
        dateOfBirth
        profilePicture {
          downloadUrl
        }
      }
      insuranceClaim {
        id
        status
        charges {
          procedureCode
          chargeFee
          dateOfService
          diagnosisCodes
          units
        }
      }
      practice {
        name
        featureConfig {
          id
          doctorPortal_hasInsuranceClaims
        }
        branding {
          logo {
            downloadUrl
          }
        }
        clinicalNoteTemplates {
          id
          name
          body
          isActive
          description
        }
        clinicalNoteModularTemplates {
          id
          name
          pages {
            id
            sections {
              id
              name
            }
          }
        }
      }
      practiceUsers {
        id
        firstName
        lastName
      }
      clinicalNote {
        id
        status
        body
        createdOn
        updatedOn
        __typename
        amendmentComponents {
          id
          body
          createdOn
          __typename
        }
      }
    }
  }
`
