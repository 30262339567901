import { gql } from 'apollo-boost'
import { GetSmsConversationQuery } from 'src/operations-types'
import { apolloClient } from 'src/settings/apolloClient'

export const QUERY_SMS_CONVERSATION = gql`
  query getSmsConversation($id: ID!) {
    getSmsConversation(id: $id) {
      id
      patientProfile {
        id
        firstName
        lastName
      }
      messages {
        to
        from
        body
        sendDate
        direction
        status
        practiceUser {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const querySmsMessages = async (patientProfileId: string) => {
  const res = await apolloClient.query<GetSmsConversationQuery>({
    query: QUERY_SMS_CONVERSATION,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    variables: {
      id: patientProfileId,
    },
  })

  if (res.errors?.length) {
    throw new Error(res.errors[0].message)
  }
  return res.data.getSmsConversation.messages
}
