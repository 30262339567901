//Formats the passed phone number to be more readable
export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber?.length >= 11) {
    const newNumber = phoneNumber
      .replace(/-/g, '')
      .replace(/\+/g, '')
      .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4')
    return newNumber
  }
  const newNumber = phoneNumber
    ?.replace(/-/g, '')
    .replace(/\+/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  return newNumber
}
