import { gql } from 'apollo-boost'
import { AppointmentRescheduleInput, Mutation } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface RescheduleAppointmentInput {
  appointmentId: string
  dateTime: string
}

export interface RescheduleAppointmentResponse {
  rescheduleAppointment: Mutation['rescheduleAppointment']
}

export const RESCHEDULE_APPOINTMENT = gql`
  mutation rescheduleAppointment($id: ID!, $input: AppointmentRescheduleInput) {
    rescheduleAppointment(id: $id, input: $input) {
      id
      dateTime
      status
      practiceLocation {
        name
      }
      appointmentType {
        name
        description
      }
      practiceUsers {
        firstName
        lastName
      }
    }
  }
`
export const rescheduleAppointment = async (
  appointmentId: string,
  input: AppointmentRescheduleInput
) => {
  const response = await apolloClient.mutate<RescheduleAppointmentResponse>({
    mutation: RESCHEDULE_APPOINTMENT,
    variables: {
      id: appointmentId,
      input,
    },
  })

  return response
}
