import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useStore } from 'src/stores/store'

interface ClinicalNotesFormProps {
  defaultValues?: Record<string, string | string[]> | null
  onSubmit: (data: Record<string, string>) => void
}

export const ClinicalNotesForm: React.FC<ClinicalNotesFormProps> = ({
  defaultValues,
  children,
  onSubmit,
}) => {
  const {
    clinicalNotesStore: { shouldResetAnswers, toggleShouldResetAnswer },
  } = useStore()
  const methods = useForm({
    defaultValues: React.useMemo(() => {
      return defaultValues || {}
    }, [defaultValues]),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldUnregister: false,
  })

  React.useEffect(() => {
    if (defaultValues && shouldResetAnswers) {
      methods.reset(defaultValues)
      toggleShouldResetAnswer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldResetAnswers])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  )
}
