import { observer } from 'mobx-react'
import React from 'react'
import { Pannel } from 'src/components/atoms/Pannel'
import { ParsedClinicalNote } from 'src/pages/ClinicalNotesv2/components/ParsedClinicalNote'

type ReviewProps = {}

export const Review: React.FC<ReviewProps> = observer(() => {
  return (
    <div>
      <div>
        <Pannel title="" height="528px" padding="20px" width="5px%">
          <div className="pt-1 h-full overflow-y-auto w-full pr-5">
            <ParsedClinicalNote />
          </div>
        </Pannel>
      </div>
    </div>
  )
})
