import { MedicationFrequency, MedicationStatus } from '@ps-ui/components'
import {
  Address,
  CareTeamMember,
  Contact,
  ElementTypes,
  Employment,
  FormField,
  Insurance,
  Medication,
  Names,
  SubscriberRelationshipTypes,
  Surgery,
} from 'src/schema-types'

export interface PrintableAnswer {
  id: string
  elementType: ElementTypes
  label: string
  value: string[]
  options?: string[] | null
  defaultValue?: string | null
}

export const buildAddressFields = (
  field: FormField,
  answers?: Address
): PrintableAnswer[] => {
  return [
    {
      id: `${field.id}:street1`,
      elementType: ElementTypes.TextInput,
      label: 'Street 1',
      value: [answers?.street1 || ''],
      // isRequired: shouldBeRequired,
    },
    {
      id: `${field.id}:street2`,
      elementType: ElementTypes.TextInput,
      label: 'Street 2',
      value: [answers?.street2 || ''],
    },
    {
      id: `${field.id}:city`,
      elementType: ElementTypes.TextInput,
      label: 'City',
      value: [answers?.city || ''],
    },
    {
      id: `${field.id}:state`,
      elementType: ElementTypes.State,
      label: 'State',
      value: [answers?.state || ''],
    },
    {
      id: `${field.id}:zip`,
      elementType: ElementTypes.TextInput,
      label: 'ZIP',
      value: [answers?.zip || ''],
    },
  ]
}

export const buildNamesFields = (
  field: FormField,
  answers?: Names
): PrintableAnswer[] => {
  return [
    {
      id: `${field.id}:firstName`,
      elementType: ElementTypes.TextInput,
      label: 'First name',
      value: [answers?.firstName || ''],
    },
    {
      id: `${field.id}:middleName`,
      elementType: ElementTypes.TextInput,
      label: 'Middle name',
      value: [answers?.middleName || ''],
    },
    {
      id: `${field.id}:lastName`,
      elementType: ElementTypes.TextInput,
      label: 'Last name',
      value: [answers?.lastName || ''],
    },
    {
      id: `${field.id}:suffix`,
      elementType: ElementTypes.TextInput,
      label: 'Suffix',
      value: [answers?.suffix || ''],
    },
  ]
}

export const buildEmploymentFields = (
  field: FormField,
  answers?: Employment
): PrintableAnswer[] => {
  return [
    {
      id: `${field.id}:employerName`,
      elementType: ElementTypes.TextInput,
      label: 'Employer name',
      value: [answers?.employerName || ''],
    },
    {
      id: `${field.id}:jobPosition`,
      elementType: ElementTypes.TextInput,
      label: 'Job position',
      value: [answers?.jobPosition || ''],
    },
    {
      id: `${field.id}:phoneNumber`,
      // TODO: Check if theres and input field for numbers
      elementType: ElementTypes.PhoneNumber,
      label: 'Phone number',
      value: [answers?.phoneNumber || ''],
    },
  ]
}

export const buildInsuranceFields = (
  field: FormField,
  answers?: Insurance
): PrintableAnswer[] => {
  let answerSubscriberRelationship = answers?.subscriberRelationship || ''
  const relationshipOptions = Object.entries(SubscriberRelationshipTypes).map(
    ([label, value]) => {
      if (value === answers?.subscriberRelationship) {
        answerSubscriberRelationship = label
      }
      return label
    }
  )
  return [
    {
      id: `${field.id}:subscriberFirstName`,
      elementType: ElementTypes.TextInput,
      label: 'Subscriber first name',
      value: [answers?.subscriberFirstName || ''],
    },
    {
      id: `${field.id}:subscriber`,
      elementType: ElementTypes.TextInput,
      label: 'Subscriber last name',
      value: [answers?.subscriber || ''],
    },
    {
      id: `${field.id}:subscriberDob`,
      elementType: ElementTypes.DateInput,
      label: 'Subscriber date of birth',
      value: [answers?.subscriberDob || ''],
    },
    {
      id: `${field.id}:subscriberRelationship`,
      elementType: ElementTypes.Dropdown,
      options: relationshipOptions,
      label: 'Relationship to patient',
      value: [answerSubscriberRelationship || ''],
    },
    {
      id: `${field.id}:insuranceCompany`,
      elementType: ElementTypes.TextInput,
      label: 'Insurance company',
      value: [answers?.insuranceCompany || ''],
    },
    {
      id: `${field.id}:insurancePolicyNumber`,
      elementType: ElementTypes.TextInput,
      label: 'Insurance Policy Number',
      value: [answers?.insurancePolicyNumber || ''],
    },
    {
      id: `${field.id}:insuranceGroupNumber`,
      elementType: ElementTypes.TextInput,
      label: 'Insurance group number',
      value: [answers?.insurancePolicyNumber || ''],
    },
  ]
}

export const buildContactFields = (
  field: FormField,
  answers?: Contact
): PrintableAnswer[] => {
  return [
    {
      id: `${field.id}:name`,
      elementType: ElementTypes.TextInput,
      label: 'Name',
      value: [answers?.name || ''],
    },
    {
      id: `${field.id}:email`,
      elementType: ElementTypes.Email,
      label: 'Email',
      value: [answers?.email || ''],
    },
    {
      id: `${field.id}:dateOfBirth`,
      elementType: ElementTypes.DateInput,
      label: 'Date of birth',
      value: [answers?.dateOfBirth],
    },
    {
      id: `${field.id}:phoneNumber`,
      elementType: ElementTypes.PhoneNumber,
      label: 'Phone number',
      value: [answers?.phoneNumber || ''],
    },
    {
      id: `${field.id}:relationship`,
      elementType: ElementTypes.TextInput,
      label: 'Relationship',
      value: [answers?.relationship || ''],
    },
  ]
}

export const buildMedicationFields = (
  field: FormField,
  answers?: Medication
): PrintableAnswer[] => {
  let answerFrequency = answers?.frequency
  let answerStatus = answers?.status
  const frequencyOptionsLabel = Object.entries(MedicationFrequency).map(
    ([label, value]) => {
      if (label === answers?.frequency) {
        answerFrequency = value
      }
      return value
    }
  )
  const statusOptions = Object.entries(MedicationStatus).map(
    ([label, value]) => {
      if (label === answers?.status) {
        answerStatus = value
      }
      return value
    }
  )
  return [
    {
      id: `${field.id}:name`,
      elementType: ElementTypes.TextInput,
      label: 'Name',
      value: [answers?.name || ''],
    },
    {
      id: `${field.id}:dosage`,
      elementType: ElementTypes.TextInput,
      label: 'Dosage',
      value: [answers?.dosage || ''],
    },
    {
      id: `${field.id}:frequency`,
      elementType: ElementTypes.TextInput,
      options: frequencyOptionsLabel,
      label: 'Frequency',
      value: [answerFrequency || ''],
    },
    {
      id: `${field.id}:startDate`,
      elementType: ElementTypes.DateInput,
      label: 'Start date',
      value: [answers?.startDate],
    },
    {
      id: `${field.id}:endDate`,
      elementType: ElementTypes.DateInput,
      label: 'End date',
      value: [answers?.endDate],
    },
    {
      id: `${field.id}:status`,
      elementType: ElementTypes.Dropdown,
      options: statusOptions,
      label: 'Status',
      value: [answerStatus || ''],
    },
    {
      id: `${field.id}:comments`,
      elementType: ElementTypes.TextArea,
      label: 'Comments',
      value: [answers?.comments || ''],
    },
  ]
}

export const buildCareTeamMemberFields = (
  field: FormField,
  answers?: CareTeamMember
): PrintableAnswer[] => {
  const answerOptions = ['I’m an active patient', 'I’m a former patient']
  return [
    {
      id: `${field.id}:firstName`,
      elementType: ElementTypes.TextInput,
      label: 'First name',
      value: [answers?.firstName || ''],
    },
    {
      id: `${field.id}:lastName`,
      elementType: ElementTypes.TextInput,
      label: 'Last name',
      value: [answers?.lastName || ''],
    },
    {
      id: `${field.id}:type`,
      elementType: ElementTypes.TextInput,
      label: 'Provider type',
      value: [answers?.type || ''],
    },
    {
      id: `${field.id}:status`,
      elementType: ElementTypes.Dropdown,
      options: answerOptions,
      label: 'Status',
      value: [answers?.status || ''],
    },
    {
      id: `${field.id}:phone`,
      elementType: ElementTypes.PhoneNumber,
      label: 'Provider phone',
      value: [answers?.phone || ''],
    },
    {
      id: `${field.id}:email`,
      elementType: ElementTypes.Email,
      label: 'Provider email',
      value: [answers?.email || ''],
    },
    {
      id: `${field.id}:fax`,
      elementType: ElementTypes.PhoneNumber,
      label: 'Provider fax',
      value: [answers?.fax || ''],
    },
  ]
}

export const buildSurgeryFields = (
  field: FormField,
  answers?: Surgery
): PrintableAnswer[] => {
  return [
    {
      id: `${field.id}:type`,
      elementType: ElementTypes.TextInput,
      label: 'Type of Surgery',
      value: [answers?.type || ''],
    },
    {
      id: `${field.id}:date`,
      elementType: ElementTypes.DateInput,
      label: 'Date of Surgery',
      value: [answers?.date],
    },
  ]
}
