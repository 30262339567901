import { copyClinicalNoteModularAnswers } from 'src/gql/mutations/copyClinicalNoteModularAnswers'
import { createClinicalNoteModular } from 'src/gql/mutations/createClinicalNoteModular'
import { createClinicalNoteModularAmendment } from 'src/gql/mutations/createCNMAmendment'
import { saveClinicalNoteAnswer } from 'src/gql/mutations/saveClinicalNoteAnswer'
import { saveClinicalNoteDiagnosis } from 'src/gql/mutations/saveClinicalNoteDiagnosis'
import { saveClinicalNoteAmendmentAnswer } from 'src/gql/mutations/saveCNMAmendmentAnswer'
import { submitClinicalNote } from 'src/gql/mutations/submitClinicalNoteModular'
import { submitClinicalNoteModularAmendment } from 'src/gql/mutations/submitCNMAmendment'
import { updateClinicalNoteTemplate } from 'src/gql/mutations/updateClinicalNoteTemplate'
import { queryClinicalNoteById } from 'src/gql/queries/queryClinicalNoteV2'
import { querySpecialModules } from 'src/gql/queries/querySpecialModules'

export const clinicalNoteServices = {
  copyClinicalNote: copyClinicalNoteModularAnswers,
  createClinicalNote: createClinicalNoteModular,
  getClinicalNote: queryClinicalNoteById,
  saveAnswer: saveClinicalNoteAnswer,
  saveAmendmentAnswer: saveClinicalNoteAmendmentAnswer,
  saveDiagnosis: saveClinicalNoteDiagnosis,
  changeTemplate: updateClinicalNoteTemplate,
  createAmendment: createClinicalNoteModularAmendment,
  submitAmendment: submitClinicalNoteModularAmendment,
  getSpecialModules: querySpecialModules,
  submitClinicalNote,
}

export type ClinicalNoteServices = typeof clinicalNoteServices
