import { Option } from '@ps-ui/components'
import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  FunctionalDropdown,
  OptionWithType,
} from 'src/components/molecules/FunctionalDropdown'
import { useStore } from 'src/stores/store'

interface DropdownProps {
  options: Option[]
  name: string
  isMulti?: boolean
  label?: string
  placeholder?: string
  defaultValue?: any
  cb?: (opt: Option | Option[] | OptionWithType) => void
  disabled?: boolean
}

export const Dropdown: React.FC<DropdownProps> = ({
  name,
  defaultValue,
  ...props
}) => {
  const {
    clinicalNotesStore: { amendments },
  } = useStore()
  const { control } = useFormContext()
  const amend = amendments.listAmendFields[name]
  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { value, onChange } }) => (
          <DropdownElem
            {...props}
            onChange={onChange}
            value={value}
            name={name}
            defaultValue={defaultValue}
            amendDate={amend ? Number(amend.createdOn) : 0}
          />
        )}
      />
    </>
  )
}

interface DropdownElemProps extends DropdownProps {
  onChange: (...envent: any[]) => void
  value: any
  amendDate?: number
}

const DropdownElem: React.FC<DropdownElemProps> = React.memo(
  ({
    cb,
    isMulti,
    label,
    options,
    value,
    onChange,
    placeholder,
    disabled,
    amendDate,
  }) => {
    const handleChange = React.useCallback(
      (opt: Option | Option[] | OptionWithType) => {
        const newValue = Array.isArray(opt)
          ? opt.map((elem) => elem.value)
          : opt.value
        onChange(newValue)
        cb && cb(opt)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [cb]
    )

    const currentSelection = React.useMemo(() => {
      if (Array.isArray(value)) {
        return options.filter((opt) => value.includes(opt.value))
      } else {
        return options.find((opt) => opt.value === value)
      }
    }, [options, value])

    return (
      <FunctionalDropdown
        radiusValue={'.5rem'}
        isMulti={isMulti}
        label={label}
        onOptionChange={handleChange}
        currentSelection={currentSelection}
        dropdownOption={options}
        placeholder={placeholder}
        disabled={disabled}
        amendDate={amendDate}
      />
    )
  }
)
