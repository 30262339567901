import { gql } from 'apollo-boost'
import { PatientAccount, PatientAccountInput } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'
import { CreateAppointmentRequestInput } from './createAppointment'

export interface CreatePatientAccountRequest {
  firstName: string
  lastName: string
  dateOfBirth?: string
  phoneNumber?: string
  email: string
  password?: string
  formId?: string | null
  practiceId?: string
  appointment?: CreateAppointmentRequestInput
}

export interface CreatePatientAccountResponse {
  createPatientAccount: PatientAccount
}

export const CREATE_PATIENT_ACCOUNT = gql`
  mutation createPatientAccount($input: PatientAccountInput!) {
    createPatientAccount(input: $input) {
      id
      firstName
      lastName
      email
    }
  }
`

export const createPatientAccount = async (input: PatientAccountInput) => {
  const result = await apolloClient.query<CreatePatientAccountResponse>({
    query: CREATE_PATIENT_ACCOUNT,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      input,
    },
  })
  if (result.errors?.length) {
    throw new Error(result.errors[0].message)
  }
  return result.data.createPatientAccount
}
