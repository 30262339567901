import styled from 'styled-components'
import { StyledInsuranceCard } from '../../styles'

export const StatusCard = styled(StyledInsuranceCard)`
  overflow-x: auto;

  .status-text {
    text-transform: capitalize;
  }
`
