import styled from 'styled-components/macro'

export const PatientCardContainer = styled.div`
  margin: 30px 0;
  min-width: 35%;
`

export const CardStyling = styled.div`
  padding: 15px 15px;
  border-radius: 20px;
  box-shadow: 0px 4px 10px -6px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  overflow: hidden;
  max-height: 320px;
  max-width: 317px;
  margin: 10px 0 40px 0;
`

export const InnerCardContainer = styled.div`
  display: flex;
  width: 317px;
`
export const TypographyContainer = styled.div`
  display: flex;
  flex-direction: column;
`
