import { Flex } from '@ps-ui/components'
import styled from 'styled-components/macro'

export const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`

export const Doctor = styled(Flex)`
  padding: 18px 0;
  margin: 12px;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.2s ease-in-out;
`
