import { Icon } from '@ps-ui/components'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ReactComponent as BodyIcon } from 'src/images/body-icon.svg'
import { BodyChartModal } from 'src/pages/ClinicalNotesv2/components/BodyChartModal'

type BodyChartProps = {
  enabledBodyParts?: string[]
  name: string
  defaultValue?: string
}

export const BodyChartInput: React.FC<BodyChartProps> = ({
  enabledBodyParts,
  name,
  defaultValue,
}) => {
  const { control } = useFormContext()
  const [isBodyChartOpen, setIsBodyChartOpen] = useState(false)

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          {/* UI Input */}
          <div
            id="body-chart"
            className="bg-white flex justify-between items-center cursor-pointer border-2 border-gray-400 border-opacity-60 rounded-lg max-w-max p-3 h-14 mx-5 first:ml-0"
            onClick={() => setIsBodyChartOpen(true)}
          >
            <p className="text-lg whitespace-nowrap mx-auto pr-2">{value}</p>
            <Icon Component={BodyIcon} size="sm" color="#34d399" />
          </div>

          {/* Body Modal */}
          <BodyChartModal
            prevValue={value}
            isShow={isBodyChartOpen}
            setBodyPart={(value: string) => onChange(value)}
            hide={() => setIsBodyChartOpen(false)}
            enabledBodyParts={enabledBodyParts}
          />
        </>
      )}
    />
  )
}
