import { gql } from 'apollo-boost'
import { Appointment } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface QueryClaimDataResponse {
  getAppointment: Appointment
}

export const GET_CLAIM_DATA = gql`
  query getClaimDataById($id: ID!) {
    getAppointment(id: $id) {
      id
      dateTime
      practiceUsers {
        firstName
        lastName
        aithozProviderNPI
      }
      insuranceClaim {
        id
        status
      }
      clinicalNoteModular {
        id
        status
      }
      patientProfile {
        id
        firstName
        lastName
        dateOfBirth
        referringDoctor {
          name
          NPI
          isActive
        }
        profilePicture {
          downloadUrl
        }
      }
    }
  }
`

export const queryClaimDataByAppointmentId = async (id: string) => {
  const response = await apolloClient.query<QueryClaimDataResponse>({
    query: GET_CLAIM_DATA,
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  })
  return response.data.getAppointment
}
