import React from 'react'
import { durationUnitOptions } from 'src/pages/ClinicalNotesv2/utils/goalsArrayOptions'
import {
  oneThroughTenOptions,
  termOptions,
} from 'src/pages/ClinicalNotesv2/utils/radioOptionsArray'
import { BinaryButtons } from '../BinaryButtons'
import { Dropdown } from '../Dropdown'

type TermProps = {
  name: string
  isDisabled?: boolean
}

export const Term: React.FC<TermProps> = ({ name, isDisabled = false }) => {
  return (
    <div className="flex items-center mb-12">
      <p className="text-sm font-medium">Term</p>
      <BinaryButtons
        name={`${name}.length`}
        options={termOptions}
        isDisabled={isDisabled}
      />
      <div className="flex items-center w-72">
        <p className="text-sm font-medium mx-3">Duration</p>
        <Dropdown
          name={`${name}.duration`}
          options={oneThroughTenOptions}
          placeholder="Amount of time"
          disabled={isDisabled}
        />
      </div>
      <div className="flex items-center ml-3 w-48">
        <Dropdown
          disabled={isDisabled}
          name={`${name}.durationUnit`}
          options={durationUnitOptions}
          placeholder="days, weeks"
        />
      </div>
    </div>
  )
}
