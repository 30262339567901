import * as React from 'react'
import { OptionList } from 'src/pages/ClinicalNotesv2/components/fields/interventions/OptionList'
import { InterventionContainer } from 'src/pages/ClinicalNotesv2/components/InterventionContainer'
import { Row } from 'src/pages/ClinicalNotesv2/components/layoutForm/Row'
import * as options from 'src/pages/ClinicalNotesv2/types/intervention/trainingEducation'
import { ClinicalNoteElementTypes } from 'src/schema-types'

export const TrainingEducationElem: React.FC<{ name: string }> = ({ name }) => {
  return (
    <div className="flex flex-col">
      <Row columns={1}>
        <OptionList
          coreName={name}
          label="Category"
          name="category"
          options={options.categoriesOptions}
          isMulti
        />
      </Row>
      <Row columns={1}>
        <OptionList
          coreName={name}
          label="Participants"
          name="participants"
          options={options.participantsOptions}
          isMulti
        />
      </Row>
      <Row columns={1}>
        <OptionList
          coreName={name}
          label="Compliant"
          name="compliant"
          options={options.compliantOptions}
          isRounded
        />
      </Row>
      <Row columns={1}>
        <OptionList
          coreName={name}
          label="Demonstrates Understanding"
          name="understanding"
          options={options.understandingOptions}
          isMulti
        />
      </Row>
    </div>
  )
}

export const TrainingEducation: React.FC<{ name: string }> = ({ name }) => {
  return (
    <InterventionContainer
      name={name}
      type={ClinicalNoteElementTypes.EducationTraining}
      render={({ name }) => <TrainingEducationElem name={name} />}
    />
  )
}
