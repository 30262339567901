import clsx from 'clsx'
import * as React from 'react'
import { CSSTransition } from 'react-transition-group' // ES6
import { ReactComponent as CaretIcon } from 'src/images/caret-icon.svg'
import { TModule } from '../../types'
import { ModuleContainer } from '../ModuleContainer'
import { RenderFields } from '../RenderFields'

interface SpecialModulesModalProps {
  isOpen: boolean
  closeModal: () => void
  module: TModule
}

export const SpecialModulesModal: React.FC<SpecialModulesModalProps> = ({
  isOpen,
  closeModal,
  module,
}) => {
  const appearanceTime = 200

  return (
    <CSSTransition
      in={isOpen}
      appear
      timeout={appearanceTime}
      classNames={{
        enter: 'transition ease-out duration-200 transform opacity-0',
        enterActive: 'transform opacity-100',
        exit:
          'transition ease-in duration-200 block visible transform opacity-0',
      }}
    >
      <div
        className={clsx(
          'z-90 fixed inset-0 overflow-y-auto overflow-x-hidden bg-[#f7f7f7] top-16',
          !isOpen && 'hidden'
        )}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="tk-modal-simple"
        aria-hidden="false"
      >
        <div className="max-w-5xl mx-auto py-9 flex flex-col">
          <button
            className="flex items-center mb-6 w-min"
            id="back-button"
            onClick={closeModal}
          >
            <CaretIcon
              style={{
                transform: 'rotate(90deg)',
              }}
            />
            Back
          </button>
          <ModuleContainer
            id={module.id}
            title={module.label}
            description={module.description || ''}
            withCollaped={false}
            withRemove={false}
            onClickContinue={closeModal}
            withContinue
          >
            <div className="flex flex-col mt-8 p-6">
              {module.fields.map((field) => (
                <div className="mb-4">
                  <RenderFields
                    key={field.id}
                    groupTitle={module.label}
                    field={field}
                  />
                </div>
              ))}
            </div>
          </ModuleContainer>
        </div>
      </div>
    </CSSTransition>
  )
}
