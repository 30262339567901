import styled, { keyframes } from 'styled-components/macro'

const rotateAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

enum Sizes {
  xs = 'xs',
  base = 'base',
}

const getSize = {
  [Sizes.xs]: { width: 20, height: 20 },
  [Sizes.base]: { width: 30, height: 30 },
}

type SpinnerProps = {
  size?: keyof typeof Sizes
}

export const Spinner = styled.div<SpinnerProps>`
  display: inline-block;
  box-sizing: border-box;
  width: ${({ size = 'base' }) => `${getSize[size].width}px`};
  height: ${({ size = 'base' }) => `${getSize[size].height}px`};
  border-radius: 50%;
  color: ${(p) => (p.color ? p.color : '#fff')};
  border-left: 4px solid currentColor;
  border-top: 4px solid currentColor;
  border-right: 4px solid currentColor;
  border-bottom: 4px solid transparent;
  animation: ${rotateAnim} 0.5s linear normal infinite;
`
