import { gql } from 'apollo-boost'
import { apolloClient } from 'src/settings/apolloClient'

export interface QuerySignatureResponse {
  getMySignature: {
    ip: string
    dateTime: string
    accountId: string
  }
}

/** gets all the form entries */
export const QUERY_SIGNATURE = gql`
  query getMySignature {
    getMySignature {
      ip
      accountId
      dateTime
    }
  }
`

export const querySignature = async () => {
  const res = await apolloClient.query<QuerySignatureResponse>({
    query: QUERY_SIGNATURE,
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  })
  if (res.errors?.length) {
    throw new Error(res.errors[0].message)
  }

  return res.data.getMySignature
}
