import { makeAutoObservable, runInAction } from 'mobx'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { AssignedForm } from 'src/schema-types'
import { AssignedFormsServices } from 'src/services/assignedForms'
import { RootStore } from 'src/stores/store'

export class AssginedFormStore {
  assignedForms?: AssignedForm[]
  isFetchingAssignedForms = false
  isFetchingMore = false
  fetchingPageNumber = 1
  fetchAssignedFormsError?: string
  hasMoreAssignedForms = false

  assignedFormsByPatientName?: AssignedForm[]
  isFetchingAssginedFormsByPatientName = false
  isFetchingMoreAssignedFormsByPatientName = false
  assignedFormsByPatientNamePageNumber = 1
  fetchAssignedFormsByPatientNameError?: string
  hasMoreAssignedFormsByPatientName = false

  rootStore: RootStore
  services: AssignedFormsServices

  constructor(rootStore: RootStore, services: AssignedFormsServices) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
    this.services = services
  }

  fetchAssginedForms = async (
    wasDownloaded?: boolean,
    isSubmitted?: boolean
  ) => {
    try {
      runInAction(() => {
        this.isFetchingAssignedForms = true
      })
      const assginedFormsResponse = await this.services.getAllAssignedForms({
        wasDownloaded,
        isSubmitted,
        pagination: { pageNumber: 1, pageSize: 50 },
      })
      runInAction(() => {
        this.assignedForms = assginedFormsResponse.assignedForms
        this.hasMoreAssignedForms = !!assginedFormsResponse.hasMore
        this.fetchingPageNumber = 2
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchAssignedFormsError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.isFetchingAssignedForms = false
      })
    }
  }

  fetchMoreAssginedForms = async (
    wasDownloaded?: boolean,
    isSubmitted?: boolean
  ) => {
    try {
      runInAction(() => {
        this.isFetchingMore = true
      })
      const assginedFormsResponse = await this.services.getAllAssignedForms({
        wasDownloaded,
        isSubmitted,
        pagination: { pageNumber: this.fetchingPageNumber, pageSize: 50 },
      })
      runInAction(() => {
        this.assignedForms =
          this.assignedForms && assginedFormsResponse.assignedForms
            ? [...this.assignedForms, ...assginedFormsResponse.assignedForms]
            : assginedFormsResponse.assignedForms
        this.fetchingPageNumber = this.fetchingPageNumber + 1
        this.hasMoreAssignedForms = !!assginedFormsResponse.hasMore
      })
    } catch (err) {
      writeLog((err as Error).message)

      runInAction(() => {
        this.fetchAssignedFormsError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.isFetchingMore = false
      })
    }
  }

  fetchAssignedFormsByPatientName = async (input: string) => {
    try {
      runInAction(() => {
        this.isFetchingAssginedFormsByPatientName = true
      })
      const assginedFormsResponse =
        await this.services.getAssignedFormsByPatientName({
          input,
          pagination: { pageNumber: 1, pageSize: 50 },
        })
      runInAction(() => {
        this.assignedFormsByPatientName = assginedFormsResponse.assignedForms
        this.hasMoreAssignedFormsByPatientName = !!assginedFormsResponse.hasMore
        this.assignedFormsByPatientNamePageNumber = 2
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchAssignedFormsByPatientNameError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.isFetchingAssginedFormsByPatientName = false
      })
    }
  }

  fetchMoreAssginedFormsByPatientName = async (input: string) => {
    try {
      runInAction(() => {
        this.isFetchingMoreAssignedFormsByPatientName = true
      })
      const assginedFormsResponse =
        await this.services.getAssignedFormsByPatientName({
          input,
          pagination: {
            pageNumber: this.assignedFormsByPatientNamePageNumber,
            pageSize: 50,
          },
        })
      runInAction(() => {
        this.assignedFormsByPatientName =
          this.assignedFormsByPatientName && assginedFormsResponse.assignedForms
            ? [
                ...this.assignedFormsByPatientName,
                ...assginedFormsResponse.assignedForms,
              ]
            : assginedFormsResponse.assignedForms
        this.assignedFormsByPatientNamePageNumber =
          this.assignedFormsByPatientNamePageNumber + 1
        this.hasMoreAssignedFormsByPatientName = !!assginedFormsResponse.hasMore
      })
    } catch (err) {
      writeLog((err as Error).message)

      runInAction(() => {
        this.fetchAssignedFormsByPatientNameError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.isFetchingMoreAssignedFormsByPatientName = false
      })
    }
  }

  resetAssignedFormsByPatientName = () => {
    runInAction(() => {
      this.assignedFormsByPatientName = undefined
    })
  }

  resetAssignedFormStore = () => {
    runInAction(() => {
      this.assignedForms = undefined
      this.assignedFormsByPatientName = undefined
      this.fetchAssignedFormsError = undefined
      this.fetchAssignedFormsByPatientNameError = undefined
    })
  }

  get hasError() {
    return (
      !!this.fetchAssignedFormsError ||
      !!this.fetchAssignedFormsByPatientNameError
    )
  }
}
