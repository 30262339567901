import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import React, { Fragment, useRef } from 'react'

type DialogModalProps = {
  headerText: string
  bodyContent: string | JSX.Element
  primaryButtonText: string
  secondaryButtonText?: string
  primaryButtonCallback: () => void
  isPrimaryButtonDisabled?: boolean
  isPrimaryButtonLoading?: boolean
  open: boolean
  setOpen: () => void | React.Dispatch<React.SetStateAction<boolean>>
}

/*
Example: 
        <DialogModal
        open={open}
        setOpen={setOpen}
        headerText="Remove This Module?"
        bodyContent="Are you sure you want to remove this module"
        primaryButtonText="Confirm"
        primaryButtonCallback={() => setOpen(false)}
        />
*/

export const DialogModal: React.FC<DialogModalProps> = ({
  headerText,
  bodyContent,
  primaryButtonCallback,
  primaryButtonText,
  isPrimaryButtonDisabled,
  isPrimaryButtonLoading,
  secondaryButtonText,
  open,
  setOpen,
}) => {
  const cancelButtonRef = useRef(null)
  let refDiv = useRef(null)
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        initialFocus={refDiv}
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div
          ref={refDiv}
          className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5
              pb-4 text-left overflow-hidden shadow-xl transform transition-all
              sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            >
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-xl leading-6 font-bold text-gray-900"
                  >
                    {headerText}
                  </Dialog.Title>
                  <div className="mt-2">
                    {typeof bodyContent === 'string' ? (
                      <p className="text-sm text-gray-500">{bodyContent}</p>
                    ) : (
                      bodyContent
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className={clsx(
                    'w-full inline-flex justify-center rounded-md border px-4 py-2 sm:col-start-2 sm:text-sm',
                    isPrimaryButtonDisabled || isPrimaryButtonLoading
                      ? 'border-gray-300 bg-white text-gray-800 shadow-sm hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none cursor-not-allowed'
                      : 'border-transparent shadow-sm bg-primary text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-primary'
                  )}
                  disabled={!!isPrimaryButtonDisabled}
                  onClick={primaryButtonCallback}
                >
                  {primaryButtonText}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-primary hover:bg-primary focus:text-white hover:text-white sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={setOpen}
                  ref={cancelButtonRef}
                >
                  {secondaryButtonText || 'Cancel'}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
