import { Button, Flex } from '@ps-ui/components'
import React from 'react'
import { Header, Loader } from 'semantic-ui-react'

type PrintPageWrapperProps = {
  isLoading: boolean
  loadingText: string
  buttonText: string
  hidePrintButton: boolean
  onButtonClick: () => void
}

export const PrintPageWrapper: React.FC<PrintPageWrapperProps> = ({
  isLoading,
  loadingText,
  buttonText,
  hidePrintButton,
  onButtonClick,
  children,
}) => {
  return isLoading ? (
    <Loader active={isLoading} size="large">
      <Header as="h4" color="grey">
        {loadingText}
      </Header>
    </Loader>
  ) : (
    <>
      <Flex
        justifyContent="flex-end"
        margin="3 3 0 0"
        style={{
          opacity: hidePrintButton ? 0 : 1,
          position: 'absolute',
          right: 25,
          zIndex: 2000,
        }}
      >
        <Button color="primary" variant="filled" onClick={onButtonClick}>
          {buttonText}
        </Button>
      </Flex>
      {children}
    </>
  )
}
