import * as FullStory from '@fullstory/browser'
import { Flex } from '@ps-ui/components'
import { observer } from 'mobx-react'
import React, { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Header } from 'src/components/organisms/Header'
import { useStore } from 'src/stores/store'
import { Content } from './styles'

const FSCapturePaths = ['v2', 'clinical-notes', 'billing']

export const PageContainer: FC = observer(({ children }) => {
  const location = useLocation()
  const hideNav =
    location.pathname.includes('/form/print') ||
    location.pathname.includes('/v2/print')

  const isFSCaptureRoute = FSCapturePaths.map((path) =>
    location.pathname.includes(path)
  ).some((item) => item)

  if (isFSCaptureRoute) {
    FullStory.restart()
  } else {
    FullStory.shutdown()
  }

  useEffect(() => {
    localStorage.setItem('last_path', location.pathname)
  }, [location])

  const { profileStore, authStore } = useStore()
  return (
    <>
      {hideNav ? (
        children
      ) : (
        <>
          <Header
            logout={() => authStore.logout()}
            name={`${profileStore.profile?.firstName} ${profileStore.profile?.lastName}`}
          />
          <Flex>
            <Content>{children}</Content>
          </Flex>
        </>
      )}
    </>
  )
})
