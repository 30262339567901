import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { InterventionContainer } from 'src/pages/ClinicalNotesv2/components/InterventionContainer'
import { Row } from 'src/pages/ClinicalNotesv2/components/layoutForm/Row'
import * as options from 'src/pages/ClinicalNotesv2/types/intervention/handwritting'
import { ClinicalNoteElementTypes } from 'src/schema-types'
import { Dropdown } from '../../Dropdown'
import { HorizontalOptionsList } from '../../HorizontalOptionsList'
import { ClinicalNotesInput } from '../../Input'
import { OptionList } from '../OptionList'

export const HandwrittingElem: React.FC<{ name: string }> = ({ name }) => {
  const { control } = useFormContext()
  const treatmentFocusValue = useWatch({
    name: `${name}.treatmentFocus`,
    control,
  })
  return (
    <div className="flex flex-col">
      <Row columns={2}>
        <Dropdown
          name={`${name}.treatmentFocus`}
          options={options.treatmentFocus}
          label="Treatment Focus"
          isMulti
        />
        {treatmentFocusValue &&
          treatmentFocusValue.includes(
            'In-hand manipulation/coordination activities'
          ) && (
            <div className="flex items-end">
              <HorizontalOptionsList
                name={`${name}.category`}
                options={options.tratmentFocusDerivedOptions}
              />
            </div>
          )}
      </Row>
      <Row columns={1}>
        <OptionList
          coreName={name}
          name="positionLowerExtremity"
          label="Positioning - Lower Extremity"
          options={options.positionLower}
          isMulti
        />
      </Row>

      <Row columns={1}>
        <OptionList
          coreName={name}
          name="positionUpperExtremity"
          label="Positioning - Upper Extremity"
          options={options.positionUpper}
          isMulti
        />
      </Row>

      <Row columns={1}>
        <OptionList
          coreName={name}
          name="graspObserved"
          options={options.graspObserved}
          label="Grasp Pattern - Grasp Observed"
        />
      </Row>
      <Row columns={1}>
        <OptionList
          coreName={name}
          name="trainingCompleted"
          label="Grasp Pattern - Training Completed for Attaining the Following Grasp Pattern"
          options={options.trainingCompleted}
        />
      </Row>

      <Row columns={1}>
        <OptionList
          coreName={name}
          name="aides"
          label="Aides"
          options={options.aides}
          isMulti
        />
      </Row>

      <Row columns={3}>
        <Dropdown
          name={`${name}.accuracy`}
          label="Accuracy"
          options={options.accuracy}
        />
        <Dropdown
          name={`${name}.repetitions`}
          label="Repetitions"
          options={options.repetitions}
        />
      </Row>

      <Row columns={1}>
        <div className="grid w-full grid-flow-col gap-6 justify-start items-end">
          <OptionList
            coreName={name}
            name="duration"
            label="Duration per"
            options={options.duration}
          />
          <div className="flex w-[200px]">
            <ClinicalNotesInput
              name={`${name}.count`}
              isIntervention
              placeholder="0"
              type="number"
            />
            <Dropdown
              name={`${name}.time`}
              options={options.time}
              placeholder="Min"
            />
          </div>
        </div>
      </Row>
      <Row columns={2}>
        <OptionList
          coreName={name}
          name="cueing"
          label="Cueing Required"
          options={options.cueingRequired}
          isRounded
        />
        <div className="flex items-end">
          <OptionList
            coreName={name}
            name="cueingType"
            inlineLabel
            label="Options:"
            options={options.typesOfCues}
          />
        </div>
      </Row>
      <Row columns={1}>
        <OptionList
          coreName={name}
          name="independenceLevel"
          label="Independence Level"
          options={options.independeceLevel}
          isRounded
        />
      </Row>
    </div>
  )
}

export const Handwritting: React.FC<{ name: string }> = ({ name }) => {
  return (
    <InterventionContainer
      name={name}
      type={ClinicalNoteElementTypes.HandwritingSkills}
      render={({ name }) => <HandwrittingElem name={name} />}
    />
  )
}
