import Maybe from 'graphql/tsutils/Maybe'
import React from 'react'
import { AssignedForm, FormField } from 'src/schema-types'
import {
  checkIsCompound,
  getCompoundAnswersBasedOnType,
  getFullAnswers,
} from '../lib'
import { RenderField } from '../RenderField'
import { CompoundLabel } from './styles'

interface RenderCompoundFieldProps {
  field: Maybe<FormField>
  assignedForm?: AssignedForm | null
}

export const RenderCompoundField: React.FC<RenderCompoundFieldProps> = ({
  field,
  assignedForm,
}) => {
  if (!field) {
    return null
  }

  const answer = getFullAnswers(field.id, assignedForm)
  const allAnswers = getCompoundAnswersBasedOnType(field, answer)

  const allRenderFields = allAnswers.map((printAnswer) => (
    <RenderField
      field={field}
      printAnswer={printAnswer}
      assignedForm={assignedForm}
    />
  ))

  if (checkIsCompound(field.elementType)) {
    return (
      <>
        <CompoundLabel>{field.label}</CompoundLabel>
        {allRenderFields}
      </>
    )
  }

  return <>{allRenderFields}</>
}
