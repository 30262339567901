import { useQuery } from '@apollo/react-hooks'
import { Flex, PrintableSection, Typography } from '@ps-ui/components'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PracticeLogo } from 'src/components/atoms/PracticeLogo'
import { PrintPageContainer } from 'src/components/atoms/PrintPageContainer'
import { PrintPageWrapper } from 'src/components/molecules/PrintPageWrapper'
import { downloadAssignedForm } from 'src/gql/mutations/downloadAssignedForm'
import {
  QueryAssignedFormResponse,
  QUERY_ASSIGNED_FORM,
} from 'src/gql/queries/queryAssignedForm'
import { usePrint } from 'src/hooks/print'
import { formatPhoneNumber } from 'src/utils/formatPhoneNumbers'
import { sortByOrdinance } from 'src/utils/sorting'
import { RenderCompoundField } from './RenderCompoundField'

export const PrintForm = () => {
  const { id } = useParams<{ id?: string }>()
  const [hidePrintButton, setIsPrinting, isPrinting] = usePrint()

  const { data, loading } = useQuery<QueryAssignedFormResponse>(
    QUERY_ASSIGNED_FORM,
    {
      variables: {
        id,
      },
    }
  )

  const [assignedForm, setAssignedForm] = useState(data?.getAssignedForm)

  useEffect(() => {
    setAssignedForm(data?.getAssignedForm)
  }, [data, loading])
  const form = assignedForm?.form

  useEffect(() => {
    if (isPrinting && hidePrintButton) {
      downloadAssignedForm(id || '')
    }
  }, [hidePrintButton, isPrinting, id])

  return (
    <PrintPageWrapper
      isLoading={loading}
      loadingText="Generating Forms..."
      hidePrintButton={hidePrintButton}
      buttonText="SAVE PDF"
      onButtonClick={() => {
        setIsPrinting(true)
      }}
    >
      {form?.pages.sort(sortByOrdinance).map((page) => (
        <PrintPageContainer key={page.id}>
          <Flex
            justifyContent="space-between"
            width="100%"
            alignItems="flex-start"
          >
            <Flex>
              <PracticeLogo src={form.practice.branding?.logo?.downloadUrl} />
              <Flex flexDirection="column">
                <Typography as="h3" margin="0 0 5px 0">
                  {form.practice.name}
                </Typography>
                {/* Checks for phone and fax number, will display if populated */}
                <Flex flexDirection="column">
                  <Typography as="h4">
                    {form.practice.phone &&
                      `Phone: ${formatPhoneNumber(form.practice.phone)}`}
                  </Typography>
                  <Typography as="h4" margin="5px 0 0 0">
                    {form.practice.fax &&
                      `Fax: ${formatPhoneNumber(form.practice.fax)}`}
                  </Typography>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDirection="column">
              <Typography as="h3" fontWeight="700">
                Patient:
              </Typography>
              <Typography as="p" padding="5px 0 0 0">
                {assignedForm?.patientProfile?.firstName}{' '}
                {assignedForm?.patientProfile?.lastName}
              </Typography>
              {dayjs(assignedForm?.patientProfile?.dateOfBirth).format('') !==
              'Invalid Date' ? (
                <Typography as="p" padding="5px 0 0 0">
                  {dayjs(assignedForm?.patientProfile?.dateOfBirth).format(
                    'MM-DD-YYYY'
                  )}{' '}
                  {`( ${dayjs(new Date()).diff(
                    assignedForm?.patientProfile?.dateOfBirth,
                    'year'
                  )} years old )`}
                </Typography>
              ) : (
                ''
              )}
            </Flex>
          </Flex>
          {page.sections.sort(sortByOrdinance).map((section) => (
            <React.Fragment key={section.id}>
              <PrintableSection type="sub">{section.title}</PrintableSection>
              {section.fields?.sort(sortByOrdinance).map((field) => (
                <React.Fragment key={field?.id}>
                  <RenderCompoundField
                    field={field}
                    assignedForm={assignedForm}
                  />
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </PrintPageContainer>
      ))}
    </PrintPageWrapper>
  )
}
