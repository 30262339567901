import { gql } from 'apollo-boost'
import { MutationCreatePaymentArgs, PaymentCreateInput } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export const CREATE_PAYMENT = gql`
  mutation CreatePayment($input: PaymentCreateInput!) {
    createPayment(input: $input) {
      id
    }
  }
`

export const createPayment = async (input: PaymentCreateInput) => {
  const response = await apolloClient.mutate<MutationCreatePaymentArgs>({
    mutation: CREATE_PAYMENT,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      input,
    },
  })

  if (response.errors?.length) {
    throw new Error(response.errors[0].message)
  }

  return response.data?.input
}
