import { gql } from 'apollo-boost'
import { Mutation } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface NotificationResendResponse {
  notificationResend: Mutation['notificationResend']
}

const NOTIFICATION_RESEND = gql`
  mutation notificationResend($messageId: ID!) {
    notificationResend(messageId: $messageId) {
      id
      createdOn
    }
  }
`

export const notificationResend = async (messageId: string) => {
  const response = await apolloClient.mutate<NotificationResendResponse>({
    mutation: NOTIFICATION_RESEND,
    variables: {
      messageId,
    },
  })

  return response
}
