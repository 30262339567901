import { makeAutoObservable, runInAction } from 'mobx'
import { writeLog } from 'src/gql/mutations/writeToLog'
import {
  AppointmentTypeOption,
  OptionWithType,
} from 'src/pages/Calendar/utils/calendarInterfaces'
import { Appointment, Maybe, Scalars, UserSchedule } from 'src/schema-types'
import { CalendarFilterServices } from 'src/services/calendarFilter'
import { RootStore } from 'src/stores/store'

const WeekDay = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
}

type DayTime = {
  day: number
  startTime: string
  endTime: string
}

export class CalendarFilterStore {
  filteredAppointmentList: Appointment[] | null = null
  fetchError = ''
  fetching = false
  doctorOptions = [] as OptionWithType[] | undefined
  locationOptions = [] as OptionWithType[] | undefined
  appointmentTypeOptions = [] as AppointmentTypeOption[] | undefined

  rootStore: RootStore
  services: CalendarFilterServices

  constructor(rootStore: RootStore, services: CalendarFilterServices) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
    this.services = services
  }

  // Pull allAppointments from the appointments Store

  get unfilteredAppointments() {
    return this.rootStore.appointmentsStore.allAppointments as Appointment[]
  }

  // These run in order from top to bottom
  // Doctor will default the list to all appointments on page load
  // After any selection is updated the list will be filtered based on passed parameters
  private filterByDoctors(doctorsList: Scalars['ID'][]) {
    try {
      runInAction(() => {
        this.fetching = true
      })
      // Extracts the doctor ids from the passed in list
      // Extracts active doctors
      const activeDoctors = this.unfilteredAppointments?.flatMap((d) =>
        d.practiceUsers.map((d) => d.id)
      )
      // Filters the appointment list based on the active doctors
      const filteredDoctorList = activeDoctors?.filter((data) =>
        doctorsList.includes(data)
      )
      const filteredDocAppointments = this.unfilteredAppointments?.filter(
        (data) => {
          return filteredDoctorList.some((id) =>
            data.practiceUsers?.flatMap((d) => d.id).includes(id)
          )
        }
      )
      // updates the list to reflect the current filter applied
      runInAction(() => {
        this.filteredAppointmentList = doctorsList.length
          ? filteredDocAppointments
          : this.filteredAppointmentList
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  private filterByPracticeLocations(practiceLocationList: Scalars['ID'][]) {
    try {
      runInAction(() => {
        this.fetching = true
      })
      const allLocations = this.unfilteredAppointments?.map(
        (data) => data.practiceLocation
      )

      const filteredLocationsList = allLocations
        ?.filter((data) => practiceLocationList.includes(data.id))
        ?.flatMap((data) => data.id)

      const filteredLocationAppointments =
        this.filteredAppointmentList &&
        this.filteredAppointmentList.filter((data) =>
          filteredLocationsList.includes(data.practiceLocation.id)
        )

      runInAction(() => {
        this.filteredAppointmentList = practiceLocationList.length
          ? filteredLocationAppointments
          : this.filteredAppointmentList
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  private filterByAppointmentTypes(appointmentTypesList: Scalars['ID'][]) {
    try {
      runInAction(() => {
        this.fetching = true
      })
      const activeAppointmentTypes = this.unfilteredAppointments?.flatMap(
        (user) => user?.appointmentType
      )

      const filteredAppointmentTypes = activeAppointmentTypes
        ?.filter((info) => info && appointmentTypesList.includes(info.id))
        ?.flatMap((d) => d.id)

      const filteredAppointmentsByTypes = this.filteredAppointmentList
        ? this.filteredAppointmentList?.filter((data) =>
            filteredAppointmentTypes.includes(data.appointmentType.id)
          )
        : this.unfilteredAppointments?.filter((data) =>
            filteredAppointmentTypes.includes(data.appointmentType.id)
          )

      runInAction(() => {
        this.filteredAppointmentList = appointmentTypesList.length
          ? filteredAppointmentsByTypes
          : this.filteredAppointmentList
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  filterAllAppointments(options: OptionWithType[], optionType: string) {
    runInAction(() => {
      // The switch stores the option selection to state based on the type passed from the sidebar selection
      switch (optionType) {
        case 'doctor':
          this.doctorOptions = options

          break
        case 'location':
          this.locationOptions = options

          break
        case 'appointmentType':
          this.appointmentTypeOptions = options

          break
      }
      // Resets the list every time an option is changed, this way we always have allAppointments and filter based on that
      this.filteredAppointmentList = this.unfilteredAppointments
      // Subsequently runs all of the filters after the list is reset with their options passed from state.
      // If the options are blank, it will just return the filtered list in it's current state and move to the next
      this.doctorOptions &&
        this.filterByDoctors(this.doctorOptions.map((opt) => opt.value))
      this.locationOptions &&
        this.filterByPracticeLocations(
          this.locationOptions.map((opt) => opt.value)
        )
      this.appointmentTypeOptions &&
        this.filterByAppointmentTypes(
          this.appointmentTypeOptions.map((opt) => opt.value)
        )
    })
  }

  get filteredDoctors() {
    return (
      this.doctorOptions?.map((filterDoctor) => {
        return this.rootStore.practiceStore.practice?.users?.find(
          (user) => user?.id === filterDoctor.value
        )
      }) || []
    )
  }

  get selectedDoctorsSchedule() {
    return this.filteredDoctors.map((doctor) => doctor?.schedule || [])
  }

  get selectedDoctorsScheduleFilteredByLocation() {
    return this.selectedDoctorsSchedule.flatMap((doctorScheduleList) => {
      let filteredDoctorsScheduleList: Maybe<UserSchedule>[]

      if (this.selectedLocationsIds?.length) {
        filteredDoctorsScheduleList = doctorScheduleList?.filter((location) => {
          if (location?.practiceLocation) {
            return this.selectedLocationsIds?.includes(
              location.practiceLocation.id
            )
          }
          return false
        })
      } else {
        filteredDoctorsScheduleList = doctorScheduleList
      }
      return filteredDoctorsScheduleList
    })
  }

  get selectedLocationsIds() {
    return this.locationOptions?.map((location) => location.value) || []
  }

  filteredSchedulableHours = (): Array<DayTime | undefined> => {
    const result = this.selectedDoctorsScheduleFilteredByLocation
      // eslint-disable-next-line array-callback-return
      ?.flatMap((locationSchedule) => {
        const scheduleHours = locationSchedule?.schedulableHours
        if (scheduleHours) {
          // map over weekDays for the location
          // eslint-disable-next-line array-callback-return
          return Object.entries(scheduleHours).flatMap(([weekDay, hours]) => {
            if (weekDay in WeekDay && Array.isArray(hours)) {
              // map over schedule ranges for the day
              return hours.flatMap((scheduleHoursElement) => {
                return {
                  day: WeekDay[weekDay as keyof typeof WeekDay],
                  startTime: scheduleHoursElement?.start,
                  endTime: scheduleHoursElement?.end,
                }
              })
            }
          })
        }
      })
      .filter((dayRange) => dayRange?.startTime)

    return result?.length ? result : []
  }
}
