import { gql } from 'apollo-boost'
import {
  AssignedForm,
  PaginationInput,
  PracticeUser,
  Query,
} from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface QueryAllAssignedFormsResopnse {
  whoAmI: Query['whoAmI']
}

export interface QueryAllAssignedFormsRequest {
  pagination: PaginationInput
  wasDownloaded?: boolean
  isSubmitted?: boolean
}

export const QUERY_ALL_ASSIGNED_FORMS = gql`
  query listAllNewAssignedForms(
    $pagination: PaginationInput
    $wasDownloaded: Boolean
    $isSubmitted: Boolean
  ) {
    whoAmI {
      ... on PracticeUser {
        practice {
          assignedForm(
            wasDownloaded: $wasDownloaded
            isSubmitted: $isSubmitted
            pagination: $pagination
          ) {
            entities {
              ... on AssignedForm {
                id
                createdOn
                isSubmitted
                updatedOn
                patientProfile {
                  id
                  firstName
                  lastName
                }
                form {
                  name
                }
              }
            }
            pageInfo {
              hasMoreResults
            }
          }
        }
      }
    }
  }
`

export const queryAllAssignedForms = async ({
  pagination,
  wasDownloaded,
  isSubmitted,
}: QueryAllAssignedFormsRequest) => {
  const response = await apolloClient.query<QueryAllAssignedFormsResopnse>({
    query: QUERY_ALL_ASSIGNED_FORMS,
    fetchPolicy: 'no-cache',
    variables: {
      pagination,
      wasDownloaded,
      isSubmitted,
    },
  })
  const practiceResponse = response.data.whoAmI as PracticeUser
  return {
    assignedForms: practiceResponse.practice.assignedForm
      .entities as AssignedForm[],
    hasMore: practiceResponse.practice.assignedForm.pageInfo.hasMoreResults,
  }
}
