import { gql } from 'apollo-boost'
import {
  ClinicalNoteModule,
  GetSpecialModulesQuery,
} from 'src/operations-types'
import { apolloClient } from 'src/settings/apolloClient'

export const GET_SPECIAL_MODULES = gql`
  query getSpecialModules {
    listClinicalNoteModules(filters: { type: OBJECTIVE_MEASURES_AND_TESTS }) {
      entities {
        ... on ClinicalNoteModule {
          id
          label
          description
          type
          fields {
            id
            label
            elementType
            answerOptions
            defaultAnswer
          }
        }
      }
    }
  }
`

export const querySpecialModules = async () => {
  const response = await apolloClient.query<GetSpecialModulesQuery>({
    query: GET_SPECIAL_MODULES,
    fetchPolicy: 'network-only',
  })

  return response.data.listClinicalNoteModules.entities as ClinicalNoteModule[]
}
