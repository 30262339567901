import { updateSmsConversation } from 'src/gql/mutations/updateSmsConversation'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { querySmsConversations } from 'src/gql/queries/querySmsConversations'
import { SmsConversation, SmsConversationStatus } from 'src/schema-types'
import { StoreonModule } from 'storeon'

export type ConversationState = {
  practiceId?: string
  activeConversation?: SmsConversation
  conversations: SmsConversation[]
  loadingConversations?: boolean
  updatingConversation?: boolean
  pageNumber: number
  status: SmsConversationStatus
  loadConversationsError?: string
  updateConversationError?: string
}

type LoadConversationsEvent = {
  practiceId?: string
  status?: SmsConversationStatus
}

type LoadedConversationsEvent = {
  practiceId: string
  conversations: SmsConversation[]
  status?: SmsConversationStatus
  pageNumber?: number
}

type UpdateConversationEvent = {
  id: string
  status: SmsConversationStatus
  markRead?: boolean
}

export type ConversationEvents = {
  'conversations/load': LoadConversationsEvent
  'conversations/loading': undefined
  'conversations/loading-more': undefined
  'conversations/loaded': LoadedConversationsEvent
  'conversations/load/error': string
  'conversations/load-more': undefined
  'conversations/update': UpdateConversationEvent
  'conversations/updating': undefined
  'conversations/updated': SmsConversation[]
  'conversations/update/error': string
  'conversations/active/set': SmsConversation
}

export const conversationModule: StoreonModule<
  ConversationState,
  ConversationEvents
> = (store) => {
  store.on('@init', () => ({
    pageNumber: 1,
    conversations: [],
    loadingConversations: false,
  }))

  /******** events for loading conversations *******/
  store.on('conversations/load', async (_state, { practiceId, status }) => {
    store.dispatch('conversations/loading')
    try {
      // fallback to stored practice ID as a convenience,
      // but need to at least pass practiceId on the first load
      const id = practiceId || _state.practiceId || ''
      const conversations = await querySmsConversations(
        id,
        _state.pageNumber,
        status
      )
      store.dispatch('conversations/loaded', {
        conversations,
        status,
        practiceId: id,
      })
    } catch (err) {
      writeLog((err as Error).message)
      store.dispatch('conversations/load/error', (err as Error).message)
    }
  })
  store.on('conversations/loading', () => ({
    conversations: [],
    //Force the state to reset on component load
    pageNumber: 1,
    loadingConversations: true,
  }))
  store.on(
    'conversations/loaded',
    (_state, { conversations, status, practiceId, pageNumber }) => ({
      conversations: conversations,
      status,
      practiceId,
      pageNumber: pageNumber || 1,
      loadingConversations: false,
    })
  )

  store.on('conversations/load/error', (_state, loadConversationsError) => ({
    loadConversationsError,
    loadingConversations: false,
  }))

  /********* loading additional pages of conversations  *********/
  store.on('conversations/load-more', async (_state) => {
    store.dispatch('conversations/loading-more')
    try {
      if (!_state.practiceId)
        throw new Error(
          'no practice id set... you probably didnt call conversations/load first'
        )
      const conversations = await querySmsConversations(
        _state.practiceId,
        _state.pageNumber + 1,
        _state.status
      )
      store.dispatch('conversations/loaded', {
        conversations: conversations.concat(_state.conversations),
        status: _state.status,
        pageNumber: _state.pageNumber + 1,
        practiceId: _state.practiceId,
      })
    } catch (err) {
      writeLog((err as Error).message)
      store.dispatch('conversations/load/error', (err as Error).message)
    }
  })
  store.on('conversations/loading-more', () => ({ loadingConversations: true }))

  /********* updating a conversation  *********/
  store.on('conversations/update', async (_state, { id, status }) => {
    store.dispatch('conversations/updating')
    try {
      const res = await updateSmsConversation(id, status)

      store.dispatch(
        'conversations/updated',
        replaceConversation(res, _state.conversations)
      )
      //Conversations should only reset if convo is removed from the list
      //Convos are only removed if they are archived
      //If we change from archived, it will get a status of unread
      //we also need to clear the convo if that is the case
      if (status === 'archived' || status === 'unread') {
        store.dispatch('conversations/active/set', undefined)
      }
    } catch (err) {
      writeLog((err as Error).message)
      store.dispatch('conversations/update/error', (err as Error).message)
    }
  })
  store.on('conversations/updating', () => ({ updatingConversation: true }))
  store.on('conversations/updated', (_state, conversations) => ({
    conversations,
    updatingConversation: false,
  }))
  store.on('conversations/update/error', (_state, updateConversationError) => ({
    updateConversationError,
    updatingConversation: false,
  }))
  /********* setting an active conversation  *********/
  store.on('conversations/active/set', (_state, activeConversation) => ({
    activeConversation,
  }))
}

const replaceConversation = (
  updatedConvo: SmsConversation,
  conversations: SmsConversation[]
) => {
  const convosCopy = [...conversations]
  const existingConvoIndex = convosCopy.findIndex(
    (convo) => convo.id === updatedConvo.id
  )
  if (existingConvoIndex > -1) {
    convosCopy[existingConvoIndex] = updatedConvo
  }

  return convosCopy
}
