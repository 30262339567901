import {
  PrintableTextBlock,
  PrintedCheckboxField,
  PrintedDateField,
  PrintedDropdownField,
  PrintedPhoneField,
  PrintedRadioField,
  PrintedSignatureField,
  PrintedSSNField,
  PrintedTextAreaField,
  PrintedTextField,
  PrintedUploadField,
} from '@ps-ui/components'
import dayjs from 'dayjs'
import React from 'react'
import { AssignedForm, ElementTypes, FormField } from 'src/schema-types'
import { formatDateFromAWS } from 'src/utils/formatDate'
import { PrintableAnswer } from '../lib/buid-compound-answer'
interface RenderFieldProps {
  field: FormField
  printAnswer: PrintableAnswer
  assignedForm?: AssignedForm | null
}

export const RenderField: React.FC<RenderFieldProps> = ({
  field,
  printAnswer,
  assignedForm,
}) => {
  switch (printAnswer.elementType) {
    case ElementTypes.Checkboxes:
      return (
        <PrintedCheckboxField
          label={printAnswer.label}
          options={printAnswer.options || []}
          value={printAnswer.value ? printAnswer.value : ['']}
        />
      )
    case ElementTypes.RadioButtons:
      return (
        <PrintedRadioField
          label={printAnswer.label}
          options={printAnswer.options || []}
          value={printAnswer.value[0] || ''}
        />
      )
    case ElementTypes.Signature:
      // This will extract the date the form was signed from the electronic signature
      const signDate = (sig: string) => {
        if (sig !== undefined) {
          const date = sig.match(/\|([^|]*)\|/g)
          if (date !== null) {
            return date[0].replace(/[|]+/g, '')
          }
        }
      }

      return (
        <PrintedSignatureField
          label={printAnswer.label}
          patientName={`${assignedForm?.patientProfile?.firstName} ${assignedForm?.patientProfile?.lastName}`}
          signature={printAnswer.value[0]}
          dateSigned={
            printAnswer.value[0]
              ? dayjs(signDate(printAnswer.value[0])).format(
                  'MMM-DD-YYYY h:mma'
                )
              : undefined
          }
        />
      )
    case ElementTypes.DateInput: {
      const formatedDate = formatDateFromAWS(printAnswer.value[0])
      return <PrintedDateField label={printAnswer.label} value={formatedDate} />
    }
    case ElementTypes.Dropdown:
      return (
        <PrintedDropdownField
          label={printAnswer.label}
          options={printAnswer.options || []}
          value={printAnswer.value[0] || ''}
        />
      )
    case ElementTypes.Ssn:
      return (
        <PrintedSSNField
          label={printAnswer.label}
          value={printAnswer.value[0] || ''}
        />
      )
    case ElementTypes.PhoneNumber:
      return (
        <PrintedPhoneField
          label={printAnswer.label}
          value={printAnswer.value[0] || ''}
        />
      )
    case ElementTypes.FormattedTextBlock:
      return <PrintableTextBlock html={printAnswer.defaultValue || ''} />
    case ElementTypes.TextArea:
      return (
        <PrintedTextAreaField
          size="sm"
          label={printAnswer.label}
          value={printAnswer.value[0] || ''}
        />
      )
    case ElementTypes.State:
    case ElementTypes.Email:
    case ElementTypes.TextInput:
      return (
        <PrintedTextField
          size="sm"
          label={printAnswer.label}
          value={printAnswer.value[0] || ''}
        />
      )
    case ElementTypes.FileUpload:
      return (
        printAnswer.value && (
          <PrintedUploadField label={field.label} url={printAnswer.value[0]} />
        )
      )
    default:
      return null
  }
}
