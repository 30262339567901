import dayjs from 'dayjs'
import * as React from 'react'

interface AmendmentIndicatorProps {
  date: number
}

export const AmendmentIndicator: React.FC<AmendmentIndicatorProps> = ({
  date,
}) => {
  return (
    <div className="bg-green-100 px-[9px] flex items-center ml-3 w-max rounded">
      <div className="bg-green-400 mr-[7px] rounded-full w-[6px] h-[6px]"></div>
      <p className="text-green-800">
        Amended on {dayjs.unix(date).format('MM/DD/YYYY')}
      </p>
    </div>
  )
}
