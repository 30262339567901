'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.validPhoneNumber =
  exports.validPastDate =
  exports.validFutureDate =
  exports.validEmail =
  exports.validDateOfBirth =
    void 0

var _libphonenumberJs = require('libphonenumber-js')

var validDateOfBirth = function validDateOfBirth(value) {
  if (value.length > 0) {
    var now = new Date().getTime()
    var dateOfBirth = Date.parse(value)
    var yearMatch = value.match(/\/[0-9]{4}$/)
    var maxYearAge = 120
    var minDateOfBirth = new Date()
    minDateOfBirth.setFullYear(minDateOfBirth.getFullYear() - maxYearAge)
    minDateOfBirth = minDateOfBirth.getTime()

    if (isNaN(dateOfBirth) || !yearMatch || dateOfBirth > now || dateOfBirth < minDateOfBirth) {
      return 'Please enter a valid Date of Birth'
    }
  }
}

exports.validDateOfBirth = validDateOfBirth

var validPastDate = function validPastDate(message) {
  return function (value) {
    var now = new Date().getTime()
    var current = Date.parse(value)
    var yearMatch = value.match(/\/[0-9]{4}$/)

    if (isNaN(current) || !yearMatch || current >= now) {
      return message || 'Please enter a valid past date'
    }
  }
}

exports.validPastDate = validPastDate

var validFutureDate = function validFutureDate(message) {
  return function (value) {
    var now = new Date().getTime()
    var current = Date.parse(value)
    var yearMatch = value.match(/\/[0-9]{4}$/)

    if (isNaN(current) || !yearMatch || current <= now) {
      return message || 'Please enter a valid future date'
    }
  }
}

exports.validFutureDate = validFutureDate

var validPhoneNumber = function validPhoneNumber(value) {
  if (!value) return
  var msg = 'Please enter a valid phone number'
  var isValid = (0, _libphonenumberJs.isValidPhoneNumber)(value, 'US')

  if (!isValid) {
    return msg
  }
}

exports.validPhoneNumber = validPhoneNumber

var validEmail = function validEmail(val, options) {
  if (options !== null && options !== void 0 && options.allowEmpty && !val) return
  var regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi

  if (!val || !val.match(regex)) {
    return 'Please enter a valid email'
  }
}

exports.validEmail = validEmail
