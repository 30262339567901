import { gql } from 'apollo-boost'
import { EditMedicalDataPointMutation } from 'src/operations-types'
import { ElementTypes, InsuranceInput } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export const EDIT_MEDICAL_DATA_POINT = gql`
  mutation editMedicalDataPoint(
    $fieldTemplateId: ID!
    $input: MedicalDataPointUpdateInput
  ) {
    saveMedicalDataPoint(fieldTemplateId: $fieldTemplateId, input: $input) {
      label
      value
      valueNames {
        firstName
        middleName
        lastName
        suffix
      }
      valueContact {
        name
        phoneNumber
        email
        relationship
        dateOfBirth
      }
      valueMedication {
        name
        dosage
        frequency
        startDate
        endDate
        status
        comments
      }
      valueAddress {
        street1
        street2
        city
        state
        zip
      }
      valueEmployment {
        jobPosition
        employerName
        phoneNumber
      }
      valueCareTeamMember {
        firstName
        lastName
        type
        phone
        fax
        email
        status
      }
      valueInsurance {
        subscriberFirstName
        subscriber
        subscriberDob
        subscriberRelationship
        insuranceCompany
        insurancePolicyNumber
        insuranceGroupNumber
      }
      valueSurgery {
        type
        date
      }
      fieldTemplateGroups {
        id
        displayName
      }

      fieldTemplate {
        id
        defaultLabel
        elementType
        isRequired
        answerOptions
        defaultAnswer
      }
    }
  }
`
export const editMedicalDataPoint = async (
  patientProfileId: string,
  fieldTemplateId: string,
  value: string | InsuranceInput | { [key: string]: string }[],
  elementType: ElementTypes
) => {
  let key = 'value'

  if (Array.isArray(value) && value.length === 0) {
    value = ''
  } else {
    switch (elementType) {
      case ElementTypes.Address:
        key = 'valueAddress'
        break
      case ElementTypes.Employment:
        key = 'valueEmployment'
        break
      case ElementTypes.Insurance:
        key = 'valueInsurance'
        break
      case ElementTypes.Contact:
        key = 'valueContact'
        break
      case ElementTypes.Medication:
        key = 'valueMedication'
        break
      case ElementTypes.CareTeamMember:
        key = 'valueCareTeamMember'
        break
      case ElementTypes.Surgery:
        key = 'valueSurgery'
        break
      case ElementTypes.Names:
        key = 'valueNames'
        break
    }
  }

  const response = await apolloClient.mutate<EditMedicalDataPointMutation>({
    mutation: EDIT_MEDICAL_DATA_POINT,
    variables: {
      fieldTemplateId,
      input: {
        patientProfileId,
        [key]: value,
      },
    },
  })

  return response
}
