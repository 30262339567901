import { makeAutoObservable, runInAction } from 'mobx'
import { SESSION_STORAGE } from 'src/constants'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { PracticeUser } from 'src/schema-types'
import { ProfileServices } from 'src/services/profile'
import { secureLocalStorage } from 'src/stores/domain/auth'
import { RootStore } from 'src/stores/store'

export class ProfileStore {
  profile?: PracticeUser
  fetching = false
  fetchError = ''

  rootStore: RootStore
  services: ProfileServices

  constructor(rootStore: RootStore, services: ProfileServices) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
    this.services = services
    this.profile = secureLocalStorage.get(SESSION_STORAGE.activeUser)
  }

  async fetch() {
    try {
      const practiceUser = await this.services.getProfile()
      secureLocalStorage.set(SESSION_STORAGE.activeUser, practiceUser)
      runInAction(() => {
        this.profile = practiceUser
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchError = (err as Error).message
      })
    } finally {
      this.fetching = false
    }
  }
}
