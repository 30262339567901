import React from 'react'
import { TBloodPressure } from 'src/pages/ClinicalNotesv2/types'

type BloodPressureAnswerProps = {
  bloodPressureAnswer: TBloodPressure
}

export const BloodPressureAnswer: React.FC<BloodPressureAnswerProps> = ({
  bloodPressureAnswer,
}) => {
  return (
    <p>
      {bloodPressureAnswer.sbp} / {bloodPressureAnswer.dbp}
    </p>
  )
}
