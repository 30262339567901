import React from 'react'
import { Dropdown } from 'src/pages/ClinicalNotesv2/components/fields/Dropdown'
import { Term } from 'src/pages/ClinicalNotesv2/components/fields/Term'
import {
  hepCuesOptions,
  hepDeviceOptions,
} from 'src/pages/ClinicalNotesv2/utils/goalsArrayOptions'
import { ClinicalNoteElementTypes } from 'src/schema-types'
import { MultiMeasureContainer } from '../../MultiMeasureContainer'

type HepProps = {
  name: string
}

const HepElem: React.FC<HepProps> = ({ name }) => {
  return (
    <div className="flex flex-col mb-5">
      <Term name={`${name}.term`} />
      <div className="flex items-center min-w-full flex-wrap mb-5">
        <p className="min-w-max mr-4">Patient will demonstrate</p>
        <div className="w-60 min-w-max">
          <Dropdown name={`${name}.device`} options={hepDeviceOptions} />
        </div>
        <p className="min-w-max mx-4">with</p>
        <div className="w-60 min-w-max">
          <Dropdown
            name={`${name}.cue`}
            options={hepCuesOptions}
            placeholder="Select a Cue"
          />
        </div>
        <p className="min-w-max my-2">
          in preparation for completing functional activities
        </p>
      </div>
    </div>
  )
}

export const Hep: React.FC<{ name: string }> = ({ name }) => {
  return (
    <MultiMeasureContainer
      type={ClinicalNoteElementTypes.TextInput}
      withNotes
      withBodyPart={false}
      name={name}
      ChildComponent={HepElem}
    />
  )
}
