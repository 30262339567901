import { gql } from 'apollo-boost'
import { CreateClinicalNoteModularMutation } from 'src/operations-types'
import { ClinicalNoteModularCreateInput } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'
import { clinicalNoteModularFragment } from '../fragments/clinicalNoteModular'

export const CREATE_CLINICAL_NOTE_MODULAR = gql`
  mutation CreateClinicalNoteModular($input: ClinicalNoteModularCreateInput!) {
    createClinicalNoteModular(input: $input) {
      ...clinicalNoteModular
    }
  }
  ${clinicalNoteModularFragment}
`

export const createClinicalNoteModular = async ({
  patientProfileId,
  appointmentId,
  clinicalNoteModularTemplateId,
}: ClinicalNoteModularCreateInput) =>
  // clinicalNoteTemplateId: string | undefined,
  // patientProfileId: string,
  // appointmentId: string
  {
    const response =
      await apolloClient.mutate<CreateClinicalNoteModularMutation>({
        mutation: CREATE_CLINICAL_NOTE_MODULAR,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          input: {
            clinicalNoteModularTemplateId,
            patientProfileId,
            appointmentId,
          },
        },
      })

    return response.data?.createClinicalNoteModular
  }
