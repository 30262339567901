'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.Header = exports.Content = void 0

var _styledComponents = _interopRequireDefault(require('styled-components'))

var _templateObject, _templateObject2

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0)
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }))
}

var Header = _styledComponents['default'].h3(
  _templateObject ||
    (_templateObject = _taggedTemplateLiteral([
      '\n  margin-top: 10px;\n  font-size: 16px;\n  font-weight: 300;\n  color: ',
      ';\n',
    ])),
  function (props) {
    return props.theme.colors.lightGrey
  }
)

exports.Header = Header

var Content = _styledComponents['default'].div(
  _templateObject2 ||
    (_templateObject2 = _taggedTemplateLiteral([
      '\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin: 5rem 0;\n',
    ]))
)

exports.Content = Content
