import clsx from 'clsx'
import * as React from 'react'

interface RowProps {
  columns: number
  className?: string
}

export const Row: React.FC<RowProps> = ({ children, columns, className }) => {
  return (
    <div
      className={clsx('grid grid-flow-col gap-8 w-full mb-8', className)}
      style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
    >
      {children}
    </div>
  )
}
