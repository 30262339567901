import styled from 'styled-components/macro'
import { Spinner } from '../Spinner'

export const PatientsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 46px;
  left: 0;
  border: 1px solid #eff1f4;
  /* border-top: none; */
  width: 100%;
  background-color: #fff;
  z-index: 11;
  transition: all 0.2s ease-in-out;
  transform: translateY(-10px);
  pointer-events: none;
  opacity: 0;
  &.active {
    transform: translateY(0px);
    pointer-events: auto;
    opacity: 1;
  }
`

export const Patient = styled.li`
  padding: 12px;
  box-sizing: border-box;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  text-transform: capitalize;
  &:not(:last-child) {
    border-bottom: 1px solid #eff1f4;
  }
  &:hover,
  &:focus {
    color: #08b175;
  }
`

export const StyledInput = styled.input`
  display: inline-block;
  font-size: 18px;
  border: 1px solid #eff1f4;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  padding: 8px 12px;
  box-sizing: border-box;
  outline: none;
  &:focus {
    border-color: #08b175;
  }
  &:placeholder {
    color: #eff1f4;
  }
`

export const SearchContainer = styled.div`
  position: relative;
  width: 430px;
`

export const SearchSpinner = styled(Spinner)`
  position: absolute;
  right: 8px;
  top: 7px;
`
