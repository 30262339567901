import { PrintablePage } from '@ps-ui/components'
import React from 'react'
import { StyledPrintPageContainer } from './styles'

type PrintPageContainerProps = {}

export const PrintPageContainer: React.FC<PrintPageContainerProps> = ({
  children,
}) => {
  return (
    <StyledPrintPageContainer justifyContent="center" className="fs-mask">
      <PrintablePage>{children}</PrintablePage>
    </StyledPrintPageContainer>
  )
}
