import { turnIntoOption } from 'src/pages/ClinicalNotesv2/utils'

export const categoriesOptions = turnIntoOption([
  'Assitive Device',
  'DME',
  'Fall Prevention',
  'Home safety',
  'HEP',
  'Precautions',
  'Treatmen Regimen',
])

export const participantsOptions = turnIntoOption(['Parents', 'Caregiver'])

export const compliantOptions = turnIntoOption(['Yes', 'Limited', 'No'])

export const understandingOptions = turnIntoOption([
  'Needs Further Training',
  'Verbalizes',
  'Return Demonstrates',
])
