import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
// NOTE not using boost...
// //https://github.com/apollographql/apollo-client/issues/3560#issuecomment-395748422
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import { SESSION_STORAGE } from 'src/constants'
import introspectionQueryResultData from 'src/fragmentTypes.json'
import { secureLocalStorage } from 'src/stores/domain/auth'
import { store } from 'src/stores/store'
import { GQL_ENDPOINT, GQL_PUBLIC_ENDPOINT } from './constants'

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP || GQL_ENDPOINT, // XXX
})

const publicHttpLink = createHttpLink({ uri: GQL_PUBLIC_ENDPOINT })

// const token = process.env.VUE_APP_TOKEN || DEV_LOGIN_TOKEN.AccessToken

const authLink = setContext((_: any, { headers }: any) => {
  // return the headers to the context so httpLink can read them

  // uncomment this line once access tokens work
  const token = secureLocalStorage.get(SESSION_STORAGE.token)

  const modifiedHeaders = {
    ...headers, // has the auth header from httpLink?
    authorization: token || '---MISSING---',
    // 'X-API-Key': process.env.REACT_APP_PUBLIC_API_KEY,
  }

  // we only need the API key when we don't have a token (ex. login or signup)
  if (token) {
    delete modifiedHeaders['X-API-Key']
  }
  return {
    headers: modifiedHeaders,
  }
})

const logoutLink = onError(({ networkError }) => {
  if (networkError && 'statusCode' in networkError) {
    if (networkError.statusCode === 401) {
      store.authStore.logout()
    }
  }
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const cache = new InMemoryCache({ fragmentMatcher })

export const link = authLink.concat(logoutLink).concat(httpLink)
export const publicLink = authLink.concat(logoutLink).concat(publicHttpLink)

export const apolloClient = new ApolloClient({
  link,
  cache,
})

export const publicApolloClient = new ApolloClient({
  link: publicLink,
  cache,
})
