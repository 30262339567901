import dayjs from 'dayjs'
import { CreatePatientFormFields } from 'src/components/organisms'
import { PatientByIdQuery } from 'src/operations-types'
import { AppointmentType, PatientProfile, PracticeUser } from 'src/schema-types'
import { StoreonModule } from 'storeon'

export interface SchedulingState {
  chosenPatient: PatientProfile | null
  chosenDoctor: PracticeUser | null
  chosenService: AppointmentType | null
  dateTo: string | null
  currentDate: dayjs.Dayjs
  noRules: boolean
  newPatient: CreatePatientFormFields | null
}

export interface SchedulingEvents {
  'scheduling-patient/set': PatientByIdQuery['getPatientProfile']
  'scheduling-patient/reset': undefined
  'scheduling-doctor/set': PracticeUser
  'scheduling-doctor/reset': undefined
  'scheduling-service/set': AppointmentType
  'scheduling-service/reset': undefined
  'scheduling-date/set': string
  'scheduling-date/reset': undefined
  'scheduling-date/next': undefined
  'scheduling-date/prev': undefined
  'scheduling/reset-form': undefined
  'scheduling/set-current-date': Date | undefined
  'scheduling/set-rules': boolean
  'scheduling/set-new-patient': CreatePatientFormFields
}

export const schedulingStateModule: StoreonModule<
  SchedulingState,
  SchedulingEvents
> = (store) => {
  store.on('@init', () => ({
    chosenPatient: null,
    chosenDoctor: null,
    chosenService: null,
    currentDate: dayjs.utc(),
    noRules: false,
    newPatient: null,
  }))
  store.on('scheduling-patient/set', (state, chosenPatient) => ({
    ...state,
    newPatient: null,
    chosenPatient,
  }))
  store.on('scheduling-patient/reset', (state) => ({
    ...state,
    chosenPatient: null,
  }))

  store.on('scheduling-doctor/set', (state, chosenDoctor) => ({
    ...state,
    chosenDoctor,
  }))
  store.on('scheduling-doctor/reset', (state) => ({
    ...state,
    chosenDoctor: null,
  }))

  store.on('scheduling-service/set', (state, chosenService) => ({
    ...state,
    chosenService,
  }))
  store.on('scheduling-service/reset', (state) => ({
    ...state,
    chosenService: null,
  }))

  store.on('scheduling-doctor/set', (state, chosenDoctor) => ({
    ...state,
    chosenDoctor,
  }))
  store.on('scheduling-doctor/reset', (state) => ({
    ...state,
    chosenDoctor: null,
  }))

  store.on('scheduling-date/set', (state, dateTo) => ({
    ...state,
    dateTo,
  }))
  store.on('scheduling-date/reset', (state) => ({
    ...state,
    dateTo: null,
  }))

  store.on('scheduling-date/next', (state) => {
    const next = state.currentDate.add(1, 'w')
    return {
      ...state,
      currentDate: next,
    }
  })

  store.on('scheduling/set-current-date', (state, newDate) => {
    return {
      ...state,
      currentDate: dayjs.utc(newDate),
    }
  })

  store.on('scheduling/set-new-patient', (state, newPatient) => {
    console.log('dispatched')
    return {
      ...state,
      chosenPatient: null,
      newPatient,
    }
  })

  store.on('scheduling/reset-form', (state) => ({
    ...state,
    chosenPatient: null,
    chosenDoctor: null,
    chosenService: null,
    currentDate: dayjs.utc(),
    dateTo: null,
    noRules: false,
    newPatient: null,
  }))

  store.on('scheduling-date/prev', (state) => {
    const next = state.currentDate.subtract(1, 'w')
    if (next.format() < dayjs().format()) return
    return {
      ...state,
      currentDate: next,
    }
  })
  store.on('scheduling/set-rules', (state, rules) => {
    return {
      ...state,
      noRules: rules,
    }
  })
}
