import dayjs from 'dayjs'
import { makeAutoObservable, runInAction } from 'mobx'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { CalendarAppointment } from 'src/pages/Calendar/utils/calendarInterfaces'
import {
  Appointment,
  Maybe,
  ScheduleHours,
  ScheduleHoursElement,
} from 'src/schema-types'
import { AppointmentsServices } from 'src/services/appointments'
import { RootStore } from 'src/stores/store'

export class AppointmentsStore {
  fetching = false
  fetchError = ''
  allAppointments: Appointment[] | null = null

  selectedDoctorId: string | undefined
  selectedOfficeId: string | undefined

  rootStore: RootStore
  services: AppointmentsServices

  constructor(rootStore: RootStore, services: AppointmentsServices) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
    this.services = services
  }

  async fetchPracticeWithAppointments(
    startDate: string,
    endDate: string,
    pageNumber: number
  ) {
    try {
      runInAction(() => {
        this.fetching = true
      })
      const practice = await this.services.getPracticeWithAppointments(
        this.rootStore.profileStore.profile?.practice.id,
        startDate,
        endDate,
        { pageNumber, pageSize: 100 }
      )

      const appointments = practice?.users?.flatMap(
        (d) => d?.appointments?.entities
      ) as Appointment[]

      runInAction(() => {
        this.rootStore.practiceStore.practice = practice
      })
      runInAction(() => {
        this.allAppointments = appointments
      })
    } catch (err) {
      writeLog((err as Error).message)

      runInAction(() => {
        this.fetchError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  // Check each doctor's appointments to see if more pages need to be retrieved
  get hasMorePages() {
    let result = false

    this.rootStore.practiceStore.practice?.users?.forEach((user) => {
      if (user?.appointments.pageInfo.hasMoreResults) {
        result = true
      }
    })

    return result
  }

  getDoctorSchedule(doctorId: string) {
    return this.rootStore.practiceStore.practice?.users?.find(
      (doctor) => doctor?.id === doctorId
    )?.schedule
  }

  doctorLocationDaySchedule(
    doctorId: string,
    locationId: string,
    appointmentDay: keyof ScheduleHours
  ) {
    const locationSchedule = this.getDoctorSchedule(doctorId)?.find(
      (locationSchedule) =>
        locationSchedule?.practiceLocation?.id === locationId
    )

    return (
      (locationSchedule?.schedulableHours?.[
        appointmentDay
      ] as Maybe<ScheduleHoursElement>[]) || []
    )
  }

  isDoubleBooking = (
    startDate: Date | undefined,
    endDate: Date | undefined,
    allCalendarEvents: CalendarAppointment[] | undefined,
    selectedDoctorId: string
  ) => {
    let result
    const overlappingAppointments = allCalendarEvents?.filter((event) => {
      return (
        dayjs(startDate).isBetween(
          dayjs(event.start).subtract(1, 'minute'),
          dayjs(event.end)
        ) || dayjs(endDate).isBetween(dayjs(event.start), dayjs(event.end))
      )
    })

    result =
      overlappingAppointments?.some((appointment) => {
        return appointment.doctors.some(
          (doctor) => doctor.id === selectedDoctorId
        )
      }) || false

    return result
  }

  setSelectedDoctorId(id: string | undefined) {
    this.selectedDoctorId = id
  }

  get activeDoctor() {
    let result
    result = this.rootStore.practiceStore.allDoctorOptions.find(
      (doctor) => doctor?.id === this.selectedDoctorId
    )

    return result
  }

  get activeDoctorOption() {
    let result

    result = this.rootStore.practiceStore.doctorsList.find(
      (doctor) => doctor.value === this.selectedDoctorId
    )

    return result
  }

  setSelectedOfficeId(id: string | undefined) {
    this.selectedOfficeId = id
  }

  get activeOffice() {
    let result
    result = this.rootStore.practiceStore.allOfficeOptions.find(
      (office) => office.id === this.selectedOfficeId
    )

    return result
  }

  get activeOfficeOption() {
    let result
    result = this.rootStore.practiceStore.officesList.find(
      (office) => office.value === this.selectedOfficeId
    )

    return result
  }

  resetAppointmentsError = () => {
    runInAction(() => {
      this.fetchError = ''
    })
  }
}
