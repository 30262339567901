import * as React from 'react'
import { RangeOfMotionBodyPartInput } from 'src/pages/ClinicalNotesv2/components/fields/RangeOfMotion/RangeOfMotionBodyPartInput'
import { MultiMeasureContainer } from 'src/pages/ClinicalNotesv2/components/MultiMeasureContainer'
import {
  rangeOfMotionLowerExtremeties,
  rangeOfMotionUpperExtremeties,
} from 'src/pages/ClinicalNotesv2/utils/bodyChartButtonsArray'
import { ClinicalNoteElementTypes } from 'src/schema-types'

interface RangeOfMotionInputProps {
  title: string
  name: string
}

export const RangeOfMotionInput: React.FC<RangeOfMotionInputProps> = ({
  title,
  name,
}) => {
  const enabledBodyParts = React.useMemo(() => {
    if (title.toLowerCase().includes('upper')) {
      return rangeOfMotionUpperExtremeties
    } else if (title.toLowerCase().includes('lower')) {
      return rangeOfMotionLowerExtremeties
    } else {
      return []
    }
  }, [title])

  return (
    <MultiMeasureContainer<{ enabledBodyParts: string[] }>
      type={ClinicalNoteElementTypes.Motion}
      title={title}
      name={name}
      enabledBodyParts={enabledBodyParts}
      ChildComponent={RangeOfMotionBodyPartInput}
      extraChildComponentProps={{ enabledBodyParts }}
    />
  )
}
