import { ClassValue } from 'clsx'
import * as React from 'react'
import { InputContainer } from '../../../InputContainer'
import { HorizontalOptionsList } from '../../HorizontalOptionsList'
import { HorizontalRoundedOptionsList } from '../../HorizontalRoundedOptionsList'

interface OptionListProps {
  name: string
  className?: ClassValue
  isMulti?: boolean
  label?: string
  coreName: string
  inlineLabel?: boolean
  isRounded?: boolean
  breaks?: number[]
  isFlex?: boolean
  options: {
    value: string
    label: string
  }[]
}

export const OptionList: React.FC<OptionListProps> = ({
  name,
  className,
  options,
  isMulti = false,
  isRounded = false,
  inlineLabel = false,
  coreName,
  isFlex,
  breaks,
  label = '',
}) => {
  return (
    <InputContainer name={name} label={label} inlineLabel={inlineLabel}>
      {isRounded ? (
        <HorizontalRoundedOptionsList
          name={`${coreName}.${name}`}
          options={options}
          isMulti={isMulti}
          breaks={breaks}
        />
      ) : (
        <HorizontalOptionsList
          isFlex={isFlex}
          name={`${coreName}.${name}`}
          options={options}
          isMulti={isMulti}
          breaks={breaks}
          className={className}
        />
      )}
    </InputContainer>
  )
}
