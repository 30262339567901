'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.Typography = void 0

var _styledComponents = _interopRequireDefault(require('styled-components'))

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _templateObject() {
  var data = _taggedTemplateLiteral([
    '\n  display: flex;\n  align-items: center;\n  color: ',
    ';\n  padding: ',
    ';\n  margin: ',
    ';\n  justify-content: ',
    ';\n  text-align: ',
    ';\n  font-weight: ',
    ';\n  line-height: ',
    ';\n',
  ])

  _templateObject = function _templateObject() {
    return data
  }

  return data
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0)
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }))
}

var Typography = _styledComponents['default'].h1(
  _templateObject(),
  function (props) {
    return props.color
      ? props.color in props.theme.colors
        ? props.theme.colors[props.color]
        : props.color
      : 'inherit'
  },
  function (props) {
    return props.theme.spacingToPx(props.padding) || 'initial'
  },
  function (props) {
    return props.theme.spacingToPx(props.margin) || 'initial'
  },
  function (props) {
    return props.align === 'center' ? 'center' : props.align === 'right' ? 'flex-end' : 'flex-start'
  },
  function (props) {
    return props.align || 'left'
  },
  function (props) {
    return props.fontWeight || 'initial'
  },
  function (props) {
    return props.lineHeight || 'normal'
  }
)

exports.Typography = Typography
