import clsx from 'clsx'
import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { handleSingleOrMultiValue } from 'src/pages/ClinicalNotesv2/utils'

interface OptionsListProps {
  name: string
  cb?: (value: string) => void
  isMulti?: boolean
  options: {
    value: string
    label: string
  }[]
}

export const OptionsList: React.FC<OptionsListProps> = ({
  name,
  options,
  cb,
  isMulti = false,
}) => {
  const { control } = useFormContext()
  return (
    <div className="grid gap-3">
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <>
            {options.map((option) => (
              <label
                key={option.value}
                onClick={() =>
                  handleSingleOrMultiValue({
                    isMulti,
                    value,
                    option: option.value,
                    handleChange: onChange,
                  })
                }
                className={clsx(
                  'font-medium flex justify-start items-center space-x-2 cursor-pointer active:bg-green-100 active:text-green-500'
                )}
              >
                <div
                  className={clsx(
                    'w-3 h-3 rounded-full border mr-3',
                    (
                      Array.isArray(value)
                        ? value.includes(option.value)
                        : value === option.value
                    )
                      ? 'bg-primary border-primary'
                      : 'border-gray-500'
                  )}
                ></div>
                {option.label}
              </label>
            ))}
          </>
        )}
      />
    </div>
  )
}
