import styled from 'styled-components/macro'

type PracticeLogoProps = {
  src?: string
}

export const PracticeLogo = styled.div<PracticeLogoProps>`
  background-image: ${(props) => `url(${props.src})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100px;
  height: 65px;
`
