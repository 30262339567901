import * as React from 'react'
import { ClinicalNotesInput } from 'src/pages/ClinicalNotesv2/components/fields/Input'
import { Term } from 'src/pages/ClinicalNotesv2/components/fields/Term'
import { InputContainer } from 'src/pages/ClinicalNotesv2/components/InputContainer'
import { MultiMeasureContainer } from 'src/pages/ClinicalNotesv2/components/MultiMeasureContainer'
import { ClinicalNoteElementTypes } from 'src/schema-types'

export const GoalElem: React.FC<{ name: string }> = ({ name }) => {
  return (
    <div className="flex flex-col">
      <Term name={`${name}.term`} />
      <InputContainer name={`${name}.goal`} label="Goal">
        <ClinicalNotesInput name={`${name}.goal`} type="textarea" />
      </InputContainer>
    </div>
  )
}

export const Goal: React.FC<{ name: string }> = ({ name }) => {
  return (
    <MultiMeasureContainer
      name={name}
      type={ClinicalNoteElementTypes.Goal}
      withBodyPart={false}
      ChildComponent={GoalElem}
    />
  )
}
