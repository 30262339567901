import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'
import { Title } from './styles'

interface Props {
  title: string
}

export const PageTitle: FunctionComponent<Props> = ({
  title = '',
  ...rest
}) => {
  return (
    <Title {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {title}
    </Title>
  )
}
