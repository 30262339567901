// This file is for all the text constants around the submit button tooltips

export const incompleteAddress =
  'Please complete all Address information for the patient to use this insurance option'

export const incompleteInsurance =
  "Please ensure all required fields are filled out for the patient's Insurance to use this insurance option"

export const incompleteGenderField =
  "Please ensure the patient's gender is filled out in the patient demographics to use this insurance option"

export const incompleteAddressAndInsurance =
  "Please ensure all required fields for the patient's Address and Insurance are filled out to use this insurance option"

export const incompleteGenderAndInsurance =
  "Please ensure all required fields for the patient's Insurance, and Gender are filled out to use this insurance option"

export const incompleteGenderAndAddress =
  "Please ensure all required fields for the patient's Address and Gender are filled out to use this insurance option"

export const incompleteDiagnosisCode =
  'Please ensure a diagnosis code is selected to submit this claim'

export const incompletePaymentMethod =
  'Please ensure a Payment Method is selected to submit this claim/invoice'

export const submitDisabledGeneric =
  'Please ensure all required fields and charges are filled out prior to submitting this claim'
