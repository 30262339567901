import clsx from 'clsx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useStore } from 'src/stores/store'
import { AmendmentIndicator } from '../../AmendmentIndicator'
// import { useStore } from 'src/stores/store'

interface ClinicalNotesInputProps {
  name: string
  type?:
    | 'text'
    | 'email'
    | 'number'
    | 'radio'
    | 'textarea'
    | 'datetime-local'
    | 'time'
  className?: string
  placeholder?: string
  defaultValue?: string
  isVisible?: boolean
  disabled?: boolean
  min?: string
  withAmendIndicator?: boolean
  isIntervention?: boolean
}

export const ClinicalNotesInput: React.FC<ClinicalNotesInputProps> = observer(
  ({
    name,
    className,
    isVisible = true,
    placeholder,
    defaultValue,
    disabled = false,
    withAmendIndicator = false,
    isIntervention = false,
    type,
    min,
  }) => {
    const { control } = useFormContext()
    const {
      clinicalNotesStore: { amendments },
    } = useStore()
    const amend = amendments.listAmendFields[name]
    if (type === 'textarea') {
      return (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div className="flex">
              <textarea
                className={clsx('w-full h-28 rounded-lg', className)}
                placeholder={placeholder}
                {...field}
              />
              {withAmendIndicator && amend && field.value && (
                <AmendmentIndicator date={Number(amend.createdOn)} />
              )}
            </div>
          )}
        />
      )
    }
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) =>
          isVisible ? (
            <div className="flex">
              <input
                type={type}
                min={min}
                className={clsx(
                  'rounded-lg',
                  isIntervention &&
                    'border-[#D1D5DB] border py-2 px-3 h-[38px] mb-3 flex mr-2 w-full',
                  className
                )}
                placeholder={placeholder}
                disabled={disabled}
                {...field}
              />
              {withAmendIndicator && amend && field.value && (
                <AmendmentIndicator date={Number(amend.createdOn)} />
              )}
            </div>
          ) : (
            <div className="hidden" />
          )
        }
      />
    )
  }
)
