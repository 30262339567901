/**
 
 */

export const GQL_ENDPOINT = '/graphql'
export const GQL_PUBLIC_ENDPOINT = '/public-graphql'

/*

  XXX laziness below...

  curl -X POST \
    https://qp4u7eb6a9.execute-api.us-east-1.amazonaws.com/dev/login \
    -H 'authorization: Basic bW9yZmljdXMrcHNhZG1pbkBnbWFpbC5jb206UEBzc3dvcmQx' \
    -H 'cache-control: no-cache' \
    -d '{
      "username": "morficus+psadmin@gmail.com",
      "password": "P@ssword1"
  }'

  TODO add proper auth...

  */
export const DEV_LOGIN_TOKEN = {
  AccessToken:
    'eyJraWQiOiJOVXM2NFwvM0JUVEpSUk5pUlhXM0N3Y1RySWtyTXRoZjZpcm1wVnFGYURTYz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJhM2VhZTk5Zi01YjlhLTQwN2MtODRlMy0yOGZlZTRiYzc2Y2YiLCJjb2duaXRvOmdyb3VwcyI6WyJQU0FkbWlucyJdLCJldmVudF9pZCI6ImQwNGU5OGRmLTU3MDItNGUyMC1iMmEyLTdjNjdhZDdlMWUzNCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE1NzUwMDEwMjMsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX1FzeVpFME03NyIsImV4cCI6MTU3NTAwNDYyMywiaWF0IjoxNTc1MDAxMDIzLCJqdGkiOiIzZmRiZGI1Ni1jY2MwLTQzNzItYTBjYy0yODYzNWRhNmJkZWEiLCJjbGllbnRfaWQiOiIxdDRjczQwZGMza3FmZTJmcWhqa2o1MjhzcyIsInVzZXJuYW1lIjoiYTNlYWU5OWYtNWI5YS00MDdjLTg0ZTMtMjhmZWU0YmM3NmNmIn0.qdIroLB_iBjoK85KAOcQXcHm9Pv0qZV8z_HCcGknjte5ujdoxbohnXzqtjgYw2L_bq-Npa6r36wiwgtR1LwhrDMHOMSboXbgx6TGwbdIoWg7pJOyaf3vCcJ9wqcKinV8Q8Dxo1K5sw5PjWepr7z4MQTsVnc3h__Oehqb81Kr4-l-RZsAECD5ettM09lKopQKHmrPvYVzpL523uM9l2ysAmLhDK4Uk_Gjh-hKfXyaqXE6CVixtsQyKB7NLDexK7RwTVHtE9mCUN2FCpbDdlPliU-PLYj31qMgZyH6eJZs1ArWM210hNOJYwB-YOTEuyNGDK14Ku_KyqEmwZcgN-9vEw',
  ExpiresIn: 3600,
  TokenType: 'Bearer',
  RefreshToken:
    'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.iekG3J7bG2ot6--NQtbGsUFH9ZZIjRWDf0ot20ngex4LS1Lja3f5aoNZkeVlfXjHTdAElV3EObLWEc15FtMJ03Y2sGF7m68haC5jyeUFcifvubIVS9U6K3SHmrku-w17s9LEIckfWgZzLSU4mDjz_6n-u5dOfeJQOoOeES64z8FB28R2A5hdczxeltBUSIw9fgyFr96GcClghkCoYvhAUsMhA1ihJ5ZlUHZax_bs029fJWKYi2gZ8jouXENoRLXG-FvOAORlwo3Lzj83cUuZrKVzz0RTEW1T5jNhzFKRuIPacfrnIIcNIjEU02LgY8zSg9biSICJUwTEJfIRUcZwOQ.D-UhKIJxP4AdLPym.sc8aR7EFnCOMpL8c8krM_dF36rhqhcfWKs4MlZ4LkYyqsWn58CVpcXUWSNUxwCVS-C76bZrrrXbtUTeJhXjqDpJQlGpEThOltKSEtRyfB3rWa8XVUhTetF9leXS7nYEdgm_Mu1YvEXvvNRYLRKJM0baQiW8ed_SKpL4kOnVHFmDD9sC04GvDQcXTDoERRA-_a6M9xjvtyZt7zxKQ5GZPBPsyaFIWCoM259e75gGIsIXN9Tb6PN_69WMLrxDJwmKEoyOjVwCXwFK2CcZKW2lb3pd_naB3nDiTJNa2tLPAMwBmSzCLrJPGysUIENv0ZN8We5CWiBSIPdXawlcbYk4h9KoXaFEF6Wb2mJuoQ5x6_HwJIDo5VAxgabMH_lBQMs-lRYkPQMNdORNzyWT61qN-KZ0SYkP6PD_YyBal65f0HlMIV9SDJd_AvCYsXkuNGvtVTsbE5QgMp-MeVAAf9g5o_zwRFXXL7nk54Agd2i4zRfS_KScYBNUcLb1GM8DIAuIScrXVCyclQFJEvg-xlFwNRAg6rTCzZwfqtPJILlU3TgTMC-jQC9TSYCoR8R-XAalw8LRuoXtezwQos1gWog5R9VBqtIcvWAsy13b7uU7dBQWCFymeRnkH1gdr7NeerTyT_MY5rp_Tvd7IQNC-cvb-dDxsDAq8PzxsqE_eZwuY5rKWlrBJ90HAI0ADnn1i4iDowj_QJdC0_4S6f90kIiV9eNA_EgnYcS2Z0PkK2rspa0eH3TTTf-4MlVdo3dgzzmq5PMW6mhQa78V8ZoYXPi9LZknlccQTaXLBp30UjvmHqfAVZWrzv6WOuCE7S2zphWl8hXRzc1_oIEFaEFZisZojw7INh_QygZY3tFTA38Jz2LhjM51MdJpzVA5aTJj38CwvGCv5hYq4BdnkpjOGtFmmga-FnniaQata9VkCSI9wwAzn5bU-ccG5KpsvkX_HWp7sxWXO2k2isp4vyMt-p8MfewGxV8nbs5wBy0yL7RR50Si0r1Z7uG58uvHRT7ZCPFjlrlZt1SUlsF97GZda54YX7k6meIjouoUYfrwEIwnBePOYyFCVFT7QyrB6bldALei9vDW-6OCNjK-zRAdCtc_AZB46Dem0CYNBlC_rIiQxGj3HNJLfB0rMNLHpKSVg81qga490EPSKkmEyrPVplZOoxN0gSBnemGxJFE5lNkjAMxv0eLpe2C3D5Jb6dHOXVDc9x90AnuRvabFBZb3e7kHhSdh7O6zS2o7VWX7aPgdJXawcBzWYB-7clAwzKbNznnZvr5oZLt6RRZIx8CceddktRYiKQ574f_HlIQELQp24H0u4uxeo5piAICGyf9lmBQ.JKH9klsFzXhO-ODnKf5k1Q',
  IdToken:
    'eyJraWQiOiJQMVA1Z29mTlRwRWp5M1czQm81alNYbDlrV1ZMNVVvcjRhYWNpM2ZnS2U4PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJhM2VhZTk5Zi01YjlhLTQwN2MtODRlMy0yOGZlZTRiYzc2Y2YiLCJjb2duaXRvOmdyb3VwcyI6WyJQU0FkbWlucyJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfUXN5WkUwTTc3IiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjp0cnVlLCJjb2duaXRvOnVzZXJuYW1lIjoiYTNlYWU5OWYtNWI5YS00MDdjLTg0ZTMtMjhmZWU0YmM3NmNmIiwiYXVkIjoiMXQ0Y3M0MGRjM2txZmUyZnFoamtqNTI4c3MiLCJldmVudF9pZCI6ImQwNGU5OGRmLTU3MDItNGUyMC1iMmEyLTdjNjdhZDdlMWUzNCIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNTc1MDAxMDIzLCJwaG9uZV9udW1iZXIiOiIrMTIxNDcwNzI5MzUiLCJleHAiOjE1NzUwMDQ2MjMsImlhdCI6MTU3NTAwMTAyMywiZW1haWwiOiJtb3JmaWN1cytwc2FkbWluQGdtYWlsLmNvbSJ9.Ue62ziWn_4pNPLtxbK-iBVHNzkvB30J6Ri-PjduCkpz6wGQ-EEEiLnUnJ29ooVLVM2ORA_kVekOPpZFw1CH4_tV20NJRxNHMCAPeZ5P6UT_jZNAId-dR_uBHMxawVFeItHC4KgRWdxFhRU8GzyE19Lv29c6SAJoDSvuZ7aUyAMcArLu-V_yziFVcZMb1kfcw_XudvCNtt4mmkoZj4OKf9NUFK72ESVrvbCCePs7ncqbZowW21kJs-oBkSLGSuNa4QAXpbsskYa7l5d2PX-8b0gQ7LqtrpOcQBFIARbEGXCWbFq9gfxVCvaxXIsu71Z6I-8MB7wjJRjXQ4ABLd8w-CA',
}
