import { gql } from 'apollo-boost'
import { Mutation } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface UpdateDoctorNoteRequestInput {
  input: string
  id: string
}

export interface UpdateDoctorNoteResponse {
  updateDoctorNote: Mutation['updateDoctorNote']
}

export const UPDATE_DOCTOR_NOTE = gql`
  mutation updateDoctorNote($id: ID!, $note: String!) {
    updateDoctorNote(id: $id, note: $note) {
      id
      note
      createdOn
      createdBy {
        firstName
      }
    }
  }
`
export const updateDoctorNote = async (id: string, note: string) => {
  const response = await apolloClient.mutate<UpdateDoctorNoteResponse>({
    mutation: UPDATE_DOCTOR_NOTE,
    variables: {
      id,
      note,
    },
  })

  return response
}
