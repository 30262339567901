// tslint:disable:ordered-imports
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { writeLog } from 'src/gql/mutations/writeToLog'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import * as FullStory from '@fullstory/browser'

window.onerror = function globalErrorHandler(msg) {
  writeLog(msg as string)
}

const FSDisableEnvironments = ['qa', 'dev']

FullStory.init({
  orgId: '11VKMC',
  devMode: FSDisableEnvironments.includes(process.env.REACT_APP_API_ENV || ''),
})

FullStory.shutdown()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
