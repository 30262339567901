import React from 'react'
import { HorizontalOptionsList } from 'src/pages/ClinicalNotesv2/components/fields/HorizontalOptionsList'
import { ClinicalNotesInput } from 'src/pages/ClinicalNotesv2/components/fields/Input'
import { InputContainer } from 'src/pages/ClinicalNotesv2/components/InputContainer'
import { TabModuleContainer } from 'src/pages/ClinicalNotesv2/components/TabModuleContainer'
import { homeSetupOptions } from 'src/pages/ClinicalNotesv2/types'
import { turnIntoOption } from 'src/pages/ClinicalNotesv2/utils'
import * as subArr from 'src/pages/ClinicalNotesv2/utils/subjectiveArrays'

const HomeSetupElem: React.FC<{ name: string; currentTab: string }> = ({
  name,
  currentTab,
}) => {
  const renderOptionPerTab = React.useMemo(() => {
    switch (currentTab) {
      case 'Entrance':
        return subArr.entranceArray
      case 'Equipment':
        return subArr.equipmentArray
      case 'Bathroom':
        return subArr.bathroomArray
      case 'Levels':
        return subArr.levelsArray
      default:
        return []
    }
  }, [currentTab])

  const locationOptions = React.useMemo(() => {
    return turnIntoOption(['Front', 'Garage', 'Side', 'Back'])
  }, [])

  return (
    <div className="flex flex-col mb-5">
      {currentTab === 'Entrance' && (
        <>
          <InputContainer name={`${name}.option2`} label="Location" inlineLabel>
            <HorizontalOptionsList
              name={`${name}.option2`}
              options={locationOptions}
            />
          </InputContainer>
          <InputContainer name={`${name}.option3`} label="Steps" inlineLabel>
            <ClinicalNotesInput
              name={`${name}.option3`}
              type="text"
              className="ml-4 my-2 w-12"
            />
          </InputContainer>
          <InputContainer name={`${name}.option1`} label="Handrail" inlineLabel>
            <HorizontalOptionsList
              name={`${name}.option1`}
              options={renderOptionPerTab}
            />
          </InputContainer>
        </>
      )}
      {currentTab === 'Levels' && (
        <>
          <InputContainer
            name={`${name}.option2`}
            label="Number of Levels"
            inlineLabel
          >
            <ClinicalNotesInput
              name={`${name}.option2`}
              type="text"
              className="ml-6 my-2 w-12"
            />
          </InputContainer>
          <InputContainer
            name={`${name}.option3`}
            label="Steps to 2nd Floor"
            inlineLabel
          >
            <ClinicalNotesInput
              name={`${name}.option3`}
              type="text"
              className="ml-4 my-2 w-12"
            />
          </InputContainer>
        </>
      )}
      {currentTab !== 'Entrance' && (
        <HorizontalOptionsList
          name={`${name}.option1`}
          isMulti={currentTab === 'Equipment' || currentTab === 'Bathroom'}
          options={renderOptionPerTab}
        />
      )}
      <div className="my-3" />
      <InputContainer name={`${name}.note`} label="Additional Notes">
        <ClinicalNotesInput
          name={`${name}.note`}
          type="textarea"
          className="max-w-xs"
        />
      </InputContainer>
    </div>
  )
}

export const HomeSetup: React.FC<{ name: string }> = ({ name }) => {
  return (
    <TabModuleContainer
      name={name}
      optionList={homeSetupOptions as unknown as string[]}
    >
      {({ currentTab }) => {
        return (
          <HomeSetupElem
            key={currentTab}
            name={`${name}.properties.${currentTab}`}
            currentTab={currentTab}
          />
        )
      }}
    </TabModuleContainer>
  )
}
