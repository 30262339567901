import { Option } from '@ps-ui/components'
import React, { useEffect, useState } from 'react'
import { FunctionalDropdown } from 'src/components/molecules/FunctionalDropdown'
import { BillingStore } from 'src/stores/domain/billing'

type ServiceLocationDropdownProps = {
  billingStore: BillingStore
}

export const ServiceLocationDropdown: React.FC<ServiceLocationDropdownProps> =
  ({ billingStore }) => {
    const options = [
      { value: 'OFFICE', label: 'Office' },
      { value: 'TELEHEALTH', label: 'Telehealth' },
    ] as Option[]
    const handleOptionChange = (opt) => {
      billingStore.saveServiceLocation(opt)
    }

    const [selectedServiceLocation, setSelectedServiceLocation] = useState<
      Option | undefined
    >(undefined)

    useEffect(() => {
      if (
        billingStore.serviceLocation &&
        Object.values(billingStore.serviceLocation).length
      ) {
        setSelectedServiceLocation(billingStore.serviceLocation)
      }
    }, [billingStore.serviceLocation])
    return (
      <div className="w-full pl-6 pr-6">
        <FunctionalDropdown
          dropdownOption={options}
          onOptionChange={handleOptionChange}
          label="Service Location:"
          placeholder="Select a Service Location"
          currentSelection={selectedServiceLocation}
          disabled={!billingStore.allowChargesEdit}
          required={true}
        />
      </div>
    )
  }
