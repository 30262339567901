import styled from 'styled-components/macro'

type ScrollViewProps = {
  offsetX?: number
}

export const StyledStepsContainer = styled.div<ScrollViewProps>`
  width: fit-content;
  transform: translateX(${(props) => Number(props.offsetX)}px);
`
export const StyledSingleStepContainer = styled.div`
  min-width: 30vw;

  @media (max-width: 1500px) {
    width: 50vw;
  }

  @media (max-width: 1000px) {
    width: 75vw;
  }

  @media (max-width: 800px) {
    width: 100vw;
  }
`
