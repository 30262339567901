import { ApolloProvider } from '@apollo/react-hooks'
import { createTheme } from '@ps-ui/components'
import React from 'react'
import { hot } from 'react-hot-loader/root'
import { BrowserRouter } from 'react-router-util'
import { ToastProvider } from 'react-toast-notifications'
import 'semantic-ui-css/semantic.min.css'
import { CustomToast } from 'src/components/molecules/CustomToast'
import { Router } from 'src/Router'
import { apolloClient } from 'src/settings/apolloClient'
import { store } from 'src/state/store'
import { StoreProvider } from 'src/stores/store'
import { StoreContext } from 'storeon/react'
import { ThemeProvider } from 'styled-components'

// pretty much useless right now but will need later
const App: React.FC = () => {
  const theme = createTheme()

  return (
    <div data-testid="app-root">
      <ApolloProvider client={apolloClient}>
        <StoreProvider>
          <StoreContext.Provider value={store}>
            <ThemeProvider theme={theme}>
              <ToastProvider
                components={{ Toast: CustomToast }}
                autoDismiss
                autoDismissTimeout={3000}
              >
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </ToastProvider>
            </ThemeProvider>
          </StoreContext.Provider>
        </StoreProvider>
      </ApolloProvider>
    </div>
  )
}

export default hot(App)
