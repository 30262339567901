import {
  rangeOfNumbersInOptions,
  turnIntoOption,
} from 'src/pages/ClinicalNotesv2/utils'

export const nonPharmacologicalMethods = turnIntoOption([
  'Distraction',
  'Massage',
  'Prayer',
  'Relaxation techniques',
  'Repositioning',
])
export const pharmacologicalMethods = turnIntoOption([
  'Non narcotic medications',
  'Narcotic medications',
  'When to call',
  'Around the clock dosing',
])

export const patientUnderstanding = turnIntoOption([
  'verbalizes',
  'needs further review',
  'no further education needed',
  'min/mod/max cues to complete',
])

export const location = turnIntoOption([
  'Shoulder',
  'Elbow',
  'Forearm',
  'wrist',
  'hand',
  'hip',
  'thigh',
  'Knee',
  'Calf',
  'Ankle',
  'Foot',
])

export const side = turnIntoOption(['L', 'R', 'Bilateral'])

export const type = turnIntoOption([
  'NMES/TENS',
  'Cold',
  'Heat',
  'Paraffin',
  'Contrast Bath',
  'Whirlpool/Hydrotherapy',
  'Taping',
  'Traction',
  'Fluidotherapy',
  'Ultrasound',
])

export const waveForms = turnIntoOption(['Monophasic', 'Bisphasic'])
export const numberOfElectrons = rangeOfNumbersInOptions(6)
export const tapingType = turnIntoOption([
  'K tape',
  'Rock Tape',
  'Athletic Tape',
])
export const tapingPurpose = turnIntoOption([
  'Pain management',
  'stabilization',
  'mobilization',
  'lymph management',
])
export const tractionType = turnIntoOption(['Intermittent', 'Sustained'])
export const ultrasoundDuration = rangeOfNumbersInOptions(30)
export const ultrasoundFrequency = turnIntoOption(['1', '3'])
export const ultrasoundIntensity = turnIntoOption(['.25', '3.0'])
export const ultrasoundWaveLength = turnIntoOption(['.5', '1.5'])
export const ultrasoundDepth = turnIntoOption([
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
])
export const ultrasoundMode = turnIntoOption(['continuous', 'pulsed'])

export const purpose = turnIntoOption([
  'decreased pain',
  'decreased swelling',
  'increased range of motion',
])
