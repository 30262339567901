import { useEffect, useState } from 'react'

export const usePrint = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  boolean
] => {
  const [isPrinting, setIsPrinting] = useState(false)
  const [hidePrintButton, setHidePrintButton] = useState(false)

  useEffect(() => {
    const appRoot = document.getElementById('app-root')
    if (appRoot) appRoot.style.background = '#FFF'
    if (isPrinting) {
      setHidePrintButton(true)
    }
    if (isPrinting && hidePrintButton) {
      window.print()
      setIsPrinting(false)
      setHidePrintButton(false)
    }
  }, [hidePrintButton, isPrinting])

  return [hidePrintButton, setIsPrinting, isPrinting]
}
