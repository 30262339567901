import { gql } from 'apollo-boost'
import { Mutation } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface CreateDoctorNoteRequestInput {
  note: string
  patientProfileId: string
}

export interface CreateDoctorNoteResponse {
  createDoctorNote: Mutation['createDoctorNote']
}

export const CREATE_DOCTOR_NOTE = gql`
  mutation createDoctorNote($patientProfileId: ID!, $note: String!) {
    createDoctorNote(patientProfileId: $patientProfileId, note: $note) {
      id
      note
      createdOn
      createdBy {
        firstName
      }
    }
  }
`
export const createDoctorNote = async (
  patientProfileId: string,
  note: string
) => {
  const response = await apolloClient.mutate<CreateDoctorNoteResponse>({
    mutation: CREATE_DOCTOR_NOTE,
    variables: {
      patientProfileId,
      note,
    },
  })

  return response
}
