import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { Flex } from '@ps-ui/components'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { history } from 'react-router-util'
import { PageTitle, ScheduleButton, Spinner } from 'src/components/atoms'
import {
  createAppointmentResponse,
  CREATE_APPOINTMENT,
} from 'src/gql/mutations/createAppointment'
import {
  CreatePatientAccountResponse,
  CREATE_PATIENT_ACCOUNT,
} from 'src/gql/mutations/createPatientAccount'
import {
  CreatePatientProfileResponse,
  CREATE_PATIENT_PROFILE,
} from 'src/gql/mutations/createPatientProfile'
import {
  SearchFamilyResponse,
  SEARCH_FAMILY,
} from 'src/gql/queries/searchFamily'
import { Family } from 'src/schema-types'
import { StoreEvents, StoreState } from 'src/state/store'
import { useStoreon } from 'storeon/react'
import {
  ActionLabel,
  Info,
  Label,
  Row,
  SubTitle,
  TextArea,
  Wrapper,
} from './styles'

interface IForm {
  id: string
  name: string
}

type FormsList = IForm[]

export const SchedulingConfirmation = () => {
  const [note, setNote] = useState('')
  const [chosenFormId, setChosenFormId] = useState('')
  const [formsList, setFormsList] = useState<FormsList>([])
  const [isSms, setIsSms] = useState(false)
  const [isEmail, setIsEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const client = useApolloClient()

  const {
    chosenDoctor,
    chosenPatient,
    chosenService,
    dateTo,
    forms,
    chosenLocationId,
    newPatient,
  } = useStoreon<StoreState, StoreEvents>(
    'chosenDoctor',
    'chosenPatient',
    'chosenService',
    'chosenLocationId',
    'dateTo',
    'forms',
    'newPatient'
  )

  useEffect(() => {
    if (forms) {
      setFormsList(forms)
      setChosenFormId(forms[0].id)
    }
  }, [forms])

  const [createPatientAccount] = useMutation<CreatePatientAccountResponse>(
    CREATE_PATIENT_ACCOUNT
  )

  const [createApp] = useMutation<createAppointmentResponse>(CREATE_APPOINTMENT)

  const [createProfile] = useMutation<CreatePatientProfileResponse>(
    CREATE_PATIENT_PROFILE
  )

  const handleSearchFamily = useCallback(
    async (email: string) => {
      const { data } = await client.query<SearchFamilyResponse>({
        query: SEARCH_FAMILY,
        fetchPolicy: 'no-cache',
        variables: {
          input: email,
        },
      })
      return data.searchFamily?.entities as Family[] | undefined
    },
    [client]
  )

  const handleNote = useCallback(
    (e) => {
      setNote(e.target.value)
    },
    [setNote]
  )

  const handleFormSelect = useCallback(
    (e) => {
      setChosenFormId(e.target.value)
    },
    [setChosenFormId]
  )

  const handleAppointment = async (patientId: string) => {
    return createApp({
      variables: {
        input: {
          practiceLocationId: chosenLocationId,
          practiceUserIds: chosenDoctor?.id,
          patientProfileId: patientId,
          dateTime: dateTo,
          appointmentTypeId: chosenService?.id,
          formId: chosenFormId,
          description: note ? note : undefined,
          sendSmsNotifications: isSms ? isSms : undefined,
          sendEmailNotifications: isEmail ? isEmail : undefined,
        },
      },
    })
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    if (newPatient) {
      try {
        const searchResults = await handleSearchFamily(newPatient.email)
        /* family/account doesn't exist, create one with appointment */
        if (!searchResults?.length) {
          await createPatientAccount({
            variables: {
              input: {
                firstName: newPatient.firstName.toLowerCase(),
                lastName: newPatient.lastName.toLowerCase(),
                email: newPatient.email,
                phoneNumber: newPatient.phoneNumber,
                dateOfBirth: newPatient.dateOfBirth,
                formCode: chosenFormId,
                appointment: {
                  practiceLocationId: chosenLocationId,
                  dateTime: dateTo,
                  practiceUserIds: [chosenDoctor?.id],
                  appointmentTypeId: chosenService?.id,
                  description: note ? note : undefined,
                  sendSmsNotifications: isSms ? isSms : undefined,
                  sendEmailNotifications: isEmail ? isEmail : undefined,
                },
              },
            },
          })
          history.push('/scheduling/success')
          return
        }
        /* family exist, create profle and appointment */
        if (searchResults?.length) {
          const { data: createProfileData } = await createProfile({
            variables: {
              input: {
                firstName: newPatient.firstName.toLowerCase(),
                lastName: newPatient.lastName.toLowerCase(),
                email: newPatient.email,
                familyId: searchResults[0].id,
                dateOfBirth: newPatient.dateOfBirth,
                phoneNumber: newPatient.phoneNumber
                  ? newPatient.phoneNumber
                  : undefined,
              },
            },
          })
          await handleAppointment(
            createProfileData?.createPatientProfile.id || ''
          )
          history.push('/scheduling/success')
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      try {
        await handleAppointment(chosenPatient?.id || '')
        history.push('/scheduling/success')
      } catch (e) {
        console.error(e)
      }
    }
  }

  return (
    <div>
      <PageTitle title="Appointment Confirmation" />
      <Wrapper>
        <SubTitle>Appointment Details</SubTitle>
        <Row>
          <Info>
            <Label>Who:</Label>
            {chosenPatient?.firstName} {chosenPatient?.lastName}
            {newPatient?.firstName} {newPatient?.lastName}
          </Info>
          <Info>
            <Label>What:</Label>
            {chosenService?.name}
          </Info>
          <Info>
            <Label>When:</Label>
            {dateTo ? dayjs.utc(dateTo).format('MMMM D HH:mm a') : ''}
          </Info>
          <Info>
            <Label>With:</Label>
            Dr. {chosenDoctor?.lastName}
          </Info>
        </Row>
      </Wrapper>
      <Wrapper>
        <SubTitle>Paperwork &amp; Communication</SubTitle>
        <Row>
          <Info>
            <Label>Who:</Label>
            {chosenPatient?.firstName} {chosenPatient?.lastName}
            {newPatient?.firstName} {newPatient?.lastName}
          </Info>
          <Info>
            <Label>What:</Label>
            <select value={chosenFormId} onChange={handleFormSelect}>
              {formsList.map((form) => (
                <option key={form.id} value={form.id}>
                  {form.name}
                </option>
              ))}
            </select>
          </Info>
        </Row>
        <Row>
          <ActionLabel>
            <input
              checked={isEmail}
              onChange={(e) => setIsEmail(e.target.checked)}
              name="email"
              type="checkbox"
            />
            <span>Email</span>
          </ActionLabel>
          <ActionLabel>
            <input
              checked={isSms}
              onChange={(e) => setIsSms(e.target.checked)}
              name="sms"
              type="checkbox"
            />
            <span>SMS Text</span>
          </ActionLabel>
        </Row>
        <SubTitle style={{ margin: '12px 0 12px 0' }}>Admission Note:</SubTitle>
        <TextArea onChange={handleNote} />
      </Wrapper>
      <Flex alignItems="center" margin="24px 0 0">
        <ScheduleButton onClick={handleSubmit} style={{ margin: 0 }}>
          Schedule &amp; Send Paperwork
        </ScheduleButton>
        {isLoading && <Spinner style={{ marginLeft: 10, color: '#08B175' }} />}
      </Flex>
    </div>
  )
}
