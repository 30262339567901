import { gql } from 'apollo-boost'
import { PracticeUser, Query } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface QueryUserProfileResponse {
  whoAmI: Query['whoAmI']
}

export const QUERY_USER_PROFILE = gql`
  {
    whoAmI {
      __typename
      ... on PSAdminAccount {
        id
      }
      ... on PracticeUser {
        id
        firstName
        lastName
        practice {
          id
        }
      }
      ... on Family {
        members {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
    }
  }
`
export const queryUserProfile = async () => {
  const fetchUserRes = await apolloClient.query<QueryUserProfileResponse>({
    query: QUERY_USER_PROFILE,
    fetchPolicy: 'network-only',
  })
  const user = fetchUserRes.data.whoAmI
  if (user?.__typename === 'Family') {
    window.location.href = 'https://test.patients.qa.patientstudio.com/login'
    return
  } else {
    return user as PracticeUser
  }
}
