import clsx from 'clsx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { justOneAmend } from 'src/pages/ClinicalNotesv2/utils'
import { useStore } from 'src/stores/store'
import { AmendmentIndicator } from '../AmendmentIndicator'

interface InputContainerProps {
  label: string
  name: string
  inlineLabel?: boolean
  className?: string
  withAmendLabel?: boolean
  isAmendContainer?: boolean
}

export const InputContainer: React.FC<InputContainerProps> = observer(
  ({
    children,
    label,
    inlineLabel,
    className,
    name,
    withAmendLabel = true,
    isAmendContainer = false,
  }) => {
    const {
      clinicalNotesStore: { amendments },
    } = useStore()
    const amend = isAmendContainer
      ? justOneAmend(amendments.listAmendFields, name)
      : amendments.listAmendFields[name || '']
    const isAnAmend = !!amend
    return (
      <div
        className={clsx(
          'flex mb-3',
          inlineLabel ? 'items-center' : 'flex-col',
          className
        )}
      >
        <label
          className={clsx(
            'flex font-medium text-[#374151]',
            inlineLabel ? '' : 'mb-3'
          )}
          style={{ minWidth: '100px' }}
        >
          {label}
          {!inlineLabel && isAnAmend && withAmendLabel && (
            <AmendmentIndicator date={Number(amend.createdOn)} />
          )}
        </label>
        {children}
        {inlineLabel && isAnAmend && withAmendLabel && (
          <AmendmentIndicator date={Number(amend.createdOn)} />
        )}
      </div>
    )
  }
)
