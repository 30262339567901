export const sideOptions = [
  {
    label: 'LEFT',
    value: 'LEFT',
  },
  {
    label: 'RIGHT',
    value: 'RIGHT',
  },
]
export const termOptions = [
  {
    label: 'SHORT',
    value: 'short',
  },
  {
    label: 'LONG',
    value: 'long',
  },
]

export const oneThroughTenOptions = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
]
export const adlOptions = [
  {
    label: 'IND',
    value: '0',
  },
  {
    label: 'MOD I',
    value: '1',
  },
  {
    label: 'SUP',
    value: '2',
  },
  {
    label: 'CGA',
    value: '3',
  },
  {
    label: 'SBA',
    value: '4',
  },
  {
    label: 'MIN A',
    value: '5',
  },
  {
    label: 'MOD A',
    value: '6',
  },
  {
    label: 'MAX A',
    value: '7',
  },
  {
    label: 'DEP',
    value: '8',
  },
]
