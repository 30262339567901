import { Flex } from '@ps-ui/components'
import styled from 'styled-components'

export const StyledPrintPageContainer = styled(Flex)`
  page-break-before: always;
  width: 210mm;
  margin: 0 auto;
  min-height: 297mm;
  /* fixes text overflow on PDFs */
  word-break: break-all;
  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child {
    margin-bottom: 14px;
  }
  /* Fixes issues with the text editor spans line height smashing words together */
  span {
    line-height: initial;
  }
`
