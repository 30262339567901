export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `AWSDate` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Date](https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates) string. In other words, this scalar type accepts date strings of the form `YYYY-MM-DD`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-05-01**" and "**-9999-01-01**" are both valid dates.  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**1970-01-01**", "**1970-01-01Z**", "**1970-01-01-07:00**" and "**1970-01-01+05:30**" are all valid dates. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: any
  /** The `AWSTimestamp` scalar type provided by AWS AppSync, represents the number of seconds that have elapsed since `1970-01-01T00:00Z`. Negative values are also accepted and these represent the number of seconds till `1970-01-01T00:00Z`.  Timestamps are serialized and deserialized as integers. The minimum supported timestamp value is **`-31557014167219200`** which corresponds to `-1000000000-01-01T00:00Z`. The maximum supported timestamp value is **`31556889864403199`** which corresponds to `1000000000-12-31T23:59:59.999999999Z`. */
  AWSTimestamp: any
  /** The `AWSEmail` scalar type provided by AWS AppSync, represents an Email address string that complies with [RFC 822](https://www.ietf.org/rfc/rfc822.txt). For example, "**username@example.com**" is a valid Email address. */
  AWSEmail: any
  /** The `AWSPhone` scalar type provided by AWS AppSync, represents a valid Phone Number. Phone numbers are serialized and deserialized as Strings. Segments of the phone number may be whitespace delimited or hyphenated.  The number can specify a country code at the beginning. However, United States numbers without country codes are still considered to be valid. */
  AWSPhone: any
  /** The `AWSURL` scalar type provided by AWS AppSync, represents a valid URL string (Ex: <https://www.amazon.com/>). The URL may use any scheme and may also be a local URL (Ex: <http://localhost/>).  URLs without schemes like "**amazon.com**" or "**www.amazon.com**" are considered invalid. URLs which contain double slashes (two consecutive forward slashes) in their path are also considered invalid. */
  AWSURL: any
  /** The `AWSTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Time](https://en.wikipedia.org/wiki/ISO_8601#Times) string. In other words, this scalar type accepts time strings of the form `hh:mm:ss.SSS`.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**12:00:00.2**", "**12:00:00.277**" and "**12:00:00.123456789**" are all valid time strings. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**12:30**", "**12:30Z**", "**12:30:24-07:00**" and "**12:30:24.500+05:30**" are all valid time strings. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSTime: any
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: any
}

export type Account = {
  id: Scalars['ID']
  accountId: Scalars['ID']
  status: Scalars['String']
  isActive: Scalars['Boolean']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email: Scalars['AWSEmail']
  phoneNumber?: Maybe<Scalars['AWSPhone']>
  /**   timestamp when this form was first created (expressed in seconds since the epoch) */
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  /**   timestamp when this form was last updated (expressed in seconds since the epoch) */
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type AccountInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber?: Maybe<Scalars['AWSPhone']>
  email: Scalars['AWSEmail']
  password?: Maybe<Scalars['String']>
}

export type Address = {
  __typename?: 'Address'
  city: Scalars['String']
  state: Scalars['String']
  street1: Scalars['String']
  street2?: Maybe<Scalars['String']>
  zip: Scalars['String']
}

export type AddressInput = {
  city: Scalars['String']
  state: Scalars['String']
  street1: Scalars['String']
  street2?: Maybe<Scalars['String']>
  zip: Scalars['String']
}

export enum AnswerType {
  String = 'String',
  Address = 'Address',
  Employment = 'Employment',
  Insurance = 'Insurance',
  Contact = 'Contact',
  Medication = 'Medication',
  CareTeamMember = 'CareTeamMember',
  Surgery = 'Surgery',
  Names = 'Names',
}

export type Appointment = Entity & {
  __typename?: 'Appointment'
  id: Scalars['ID']
  practiceLocation: PracticeLocation
  practice: Practice
  patientProfile?: Maybe<PatientProfile>
  status: AppointmentStatus
  description?: Maybe<Scalars['String']>
  /**   Short text describing why an appoint was canceled or rescheduled */
  patientUpdateReason?: Maybe<Scalars['String']>
  dateTime: Scalars['AWSDateTime']
  payments?: Maybe<Array<Maybe<Payment>>>
  /**   Duration of the appointemnt type (expressed in minutes) */
  duration?: Maybe<Scalars['Int']>
  previousRelatedAppointment?: Maybe<Appointment>
  practiceUsers: Array<PracticeUser>
  appointmentType: AppointmentType
  /**   Patient has confirmed that they will show up */
  isConfirmed: Scalars['Boolean']
  assignedForm?: Maybe<AssignedForm>
  reminders?: Maybe<Array<Maybe<AppointmentReminder>>>
  insuranceClaim?: Maybe<InsuranceClaim>
  /**   method of documentation employed by healthcare providers to write out notes in a patient's chart (SOAP notes) */
  clinicalNote?: Maybe<ClinicalNote>
  /**   Clinical Notes using the modular templates */
  clinicalNoteModular?: Maybe<ClinicalNoteModular>
  createdOn: Scalars['AWSTimestamp']
  updatedOn: Scalars['AWSTimestamp']
}

export type AppointmentCreateInlineInput = {
  practiceLocationId: Scalars['ID']
  dateTime: Scalars['AWSDateTime']
  description?: Maybe<Scalars['String']>
  practiceUserIds: Array<Scalars['ID']>
  /**   Optional form ID that the patient needs to fill out as part of this appointment */
  formId?: Maybe<Scalars['ID']>
  /**   Duration of the appointemnt type (expressed in minutes) */
  duration?: Maybe<Scalars['Int']>
  appointmentTypeId: Scalars['ID']
  sendSmsNotifications?: Maybe<Scalars['Boolean']>
  sendEmailNotifications?: Maybe<Scalars['Boolean']>
}

export type AppointmentCreateInput = {
  practiceLocationId: Scalars['ID']
  patientProfileId?: Maybe<Scalars['ID']>
  dateTime: Scalars['AWSDateTime']
  /**   Duration of the appointemnt type (expressed in minutes) */
  duration?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  practiceUserIds: Array<Scalars['ID']>
  /**   Optional form ID that the patient needs to fill out as part of this appointment */
  formId?: Maybe<Scalars['ID']>
  appointmentTypeId: Scalars['ID']
  sendSmsNotifications?: Maybe<Scalars['Boolean']>
  sendEmailNotifications?: Maybe<Scalars['Boolean']>
}

export type AppointmentReasonTemplate = {
  __typename?: 'AppointmentReasonTemplate'
  id: Scalars['ID']
  /**   display name of the template */
  name: Scalars['String']
  /**   template content */
  body: Scalars['String']
  /**   date this template was created */
  createdOn: Scalars['AWSTimestamp']
  /**   date this template was last updated */
  updatedOn: Scalars['AWSTimestamp']
}

export type AppointmentReasonTemplateCreateInput = {
  /**   display name of the template */
  name: Scalars['String']
  /**   template content */
  body: Scalars['String']
}

export type AppointmentReasonTemplateUpdateInput = {
  /**   display name of the template */
  name?: Maybe<Scalars['String']>
  /**   template content */
  body?: Maybe<Scalars['String']>
}

export type AppointmentReminder = {
  __typename?: 'AppointmentReminder'
  id: Scalars['ID']
  /**   The appointment assocaited with this reminder */
  appointment?: Maybe<Appointment>
  /**   date when the reminder will be triggered */
  triggerDate?: Maybe<Scalars['AWSDate']>
  /**   time when the reminder will be triggered (valid only for SMS) */
  triggerTime?: Maybe<Scalars['AWSTimestamp']>
  /**   Technical notes about the delivery of the email or sms */
  deliveryStatus?: Maybe<Scalars['String']>
  sendVia?: Maybe<CommunicationMedium>
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type AppointmentReminderInputCreate = {
  /**   ID of the assocaited appointment */
  appointmentId: Scalars['ID']
  /**   date when the reminder will be triggered (populated only for email notifications) */
  triggerDate?: Maybe<Scalars['AWSDate']>
  /**   date when the reminder will be triggered (populated only for SMS notificaitons) */
  triggerTime?: Maybe<Scalars['AWSTimestamp']>
  sendVia: CommunicationMedium
}

export type AppointmentRescheduleInput = {
  dateTime: Scalars['AWSDateTime']
  /**   Duration of the appointemnt type (expressed in minutes) */
  duration?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  practiceLocationId?: Maybe<Scalars['ID']>
  /**   Short text describing why an appoint was canceled or rescheduled */
  patientUpdateReason?: Maybe<Scalars['String']>
  isLate?: Maybe<Scalars['Boolean']>
}

export enum AppointmentStatus {
  /**  The default status of a newly created appointment */
  Scheduled = 'SCHEDULED',
  /**  appointment has been rescheduled to another date */
  Rescheduled = 'RESCHEDULED',
  /**  appointment has been rescheduled to another date 24H or less before the appointment date */
  LateRescheduled = 'LATE_RESCHEDULED',
  /**  past, succefull appointment */
  Completed = 'COMPLETED',
  /**  Patient cancelled the appointment */
  Cancelled = 'CANCELLED',
  /**  Patient cancelled the appointment 24H or less before the appointment date */
  LateCancelled = 'LATE_CANCELLED',
  /**  Patient did not show up */
  Noshow = 'NOSHOW',
}

export type AppointmentType = {
  __typename?: 'AppointmentType'
  id: Scalars['ID']
  /**   flag indicating if this form is active, and able to be assigned to patients */
  isActive: Scalars['Boolean']
  /**
   *  Color to be used to visually idenitfy this particular appointmet type.
   * The value is a expressed as an 6-bit hex value
   */
  displayColor?: Maybe<Scalars['String']>
  /**   Display name to help humans identify a type of appointment */
  name: Scalars['String']
  /**   Brief description of the appointment type */
  description?: Maybe<Scalars['String']>
  /**   Typical duration of the appointemnt type (expressed in minutes) */
  duration: Scalars['Int']
  isInternal: Scalars['Boolean']
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type AppointmentTypeCreateInput = {
  /**   Display name to help humans identify a type of appointment */
  name: Scalars['String']
  /**   flag indicating if this form is active, and able to be assigned to patients */
  isActive: Scalars['Boolean']
  /**
   *  Color to be used to visually idenitfy this particular appointmet type.
   * The value is a expressed as an 6-bit hex value
   */
  displayColor?: Maybe<Scalars['String']>
  /**   Brief description of the appointment type */
  description?: Maybe<Scalars['String']>
  /**   Typical duration of the appointemnt type (expressed in minutes) */
  duration: Scalars['Int']
  /**   The Practice that this appointment type belongs to */
  practiceId: Scalars['ID']
  isInternal: Scalars['Boolean']
}

export type AppointmentTypeUpdateInput = {
  /**   Display name to help humans identify a type of appointment */
  name?: Maybe<Scalars['String']>
  /**   flag indicating if this form is active, and able to be assigned to patients */
  isActive: Scalars['Boolean']
  /**
   *  Color to be used to visually idenitfy this particular appointmet type.
   * The value is a expressed as an 6-bit hex value
   */
  displayColor?: Maybe<Scalars['String']>
  /**   Brief description of the appointment type */
  description?: Maybe<Scalars['String']>
  /**   Typical duration of the appointemnt type (expressed in minutes) */
  duration?: Maybe<Scalars['Int']>
  isInternal: Scalars['Boolean']
}

export type AppointmentUpdateInput = {
  description?: Maybe<Scalars['String']>
  /**   Duration of the appointemnt type (expressed in minutes) */
  duration?: Maybe<Scalars['Int']>
  practiceLocationId?: Maybe<Scalars['ID']>
  status?: Maybe<AppointmentStatus>
  /**   Short text describing why an appoint was canceled or rescheduled */
  patientUpdateReason?: Maybe<Scalars['String']>
  /**   Patient has confirmed that they will show up */
  isConfirmed?: Maybe<Scalars['Boolean']>
  appointmentTypeId?: Maybe<Scalars['ID']>
}

export type AssignedForm = Entity & {
  __typename?: 'AssignedForm'
  id: Scalars['ID']
  form: Form
  /**   The patient that provided these answers */
  patientProfile: PatientProfile
  /**   Flag indicating if all fields on the form have been filled out */
  isComplete?: Maybe<Scalars['Boolean']>
  /**   Flag indicating if these answers have been submitted by the patient */
  isSubmitted?: Maybe<Scalars['Boolean']>
  /**   Flag indicating if a form has been downlaoded or not (this is only set manually) */
  wasDownloaded?: Maybe<Scalars['Boolean']>
  /**   Answers that have been provided to the form */
  answers?: Maybe<Array<Maybe<AssignedFormAnswer>>>
  createdOn: Scalars['AWSTimestamp']
  updatedOn: Scalars['AWSTimestamp']
}

export type AssignedFormAnswer = {
  __typename?: 'AssignedFormAnswer'
  id: Scalars['ID']
  answerType: AnswerType
  isCompoundField?: Maybe<Scalars['Boolean']>
  /**   ID of the associated form field */
  field: FormField
  /**   answer provided to this form field. will only be populated if the assocaited field type is NOT a compound field */
  answer?: Maybe<Array<Scalars['String']>>
  /**   only populated for fields that are related to Address information */
  answerAddress?: Maybe<Array<Address>>
  /**   only populated for fields that are related to Employment information */
  answerEmployment?: Maybe<Array<Employment>>
  /**   only populated for fields that are related to Insurance information */
  answerInsurance?: Maybe<Array<Insurance>>
  /**   only populated for fields that are related to Contact information */
  answerContact?: Maybe<Array<Contact>>
  /**   only populated for fields that are related to Medication information */
  answerMedication?: Maybe<Array<Medication>>
  /**   only populated for fields that are related to CareTeamMember information */
  answerCareTeamMember?: Maybe<Array<CareTeamMember>>
  /**   only populated for field that are related to Surgery information */
  answerSurgery?: Maybe<Array<Surgery>>
  /**   only populated for fields that are related to Names information */
  answerNames?: Maybe<Array<Names>>
}

export type AssignedFormAnswerInput = {
  /**   ID of the associated assigned form */
  assignedFormId: Scalars['ID']
  /**   ID of the associated form field */
  fieldId: Scalars['ID']
  answerType: AnswerType
  /**   answers provided by a patient to this form field. only be populated if the assocaited field type is NOT a compound field */
  answer?: Maybe<Array<Scalars['String']>>
  /**   only populated for field that are related to Address information */
  answerAddress?: Maybe<Array<AddressInput>>
  /**   only populated for field that are related to Employment information */
  answerEmployment?: Maybe<Array<EmploymentInput>>
  /**   only populated for field that are related to Insurance information */
  answerInsurance?: Maybe<Array<InsuranceInput>>
  /**   only populated for field that are related to Contact information */
  answerContact?: Maybe<Array<ContactInput>>
  /**   only populated for field that are related to Medication information */
  answerMedication?: Maybe<Array<MedicationInput>>
  /**   only populated for field that are related to CareTeamMember information */
  answerCareTeamMember?: Maybe<Array<CareTeamMemberInput>>
  /**   only populated for field that are related to Surgery information */
  answerSurgery?: Maybe<Array<SurgeryInput>>
  /**   only populated for field that are related to Names information */
  answerNames?: Maybe<Array<NamesInput>>
}

export type AssignedFormInput = {
  formId: Scalars['ID']
  patientProfileId: Scalars['ID']
}

export type BillingCode = {
  __typename?: 'BillingCode'
  id: Scalars['ID']
  /**   the value of the billing code */
  code: Scalars['String']
  /**   type of billing code */
  type: BillingCodeType
  /**   short description of the code */
  description?: Maybe<Scalars['String']>
}

export type BillingCodeCreateInput = {
  /**   the value of the billing code */
  code: Scalars['String']
  /**   type of billing code */
  type: BillingCodeType
  /**   short description of the code */
  description?: Maybe<Scalars['String']>
  /**   ID of the owning Practice */
  practiceId: Scalars['ID']
}

export enum BillingCodeType {
  /**  Current Procedural Terminology */
  Cpt = 'CPT',
  /**  Modifier for a CPT code */
  CptModifier = 'CPT_MODIFIER',
  /**  International Classification of Diseases */
  Icd = 'ICD',
  /**  Healthcare Common Procedures Coding System */
  Hcpcs = 'HCPCS',
}

export type BillingCodeUpdateInput = {
  /**   the value of the billing code */
  code: Scalars['String']
  /**   type of billing code */
  type: BillingCodeType
  /**   short description of the code */
  description?: Maybe<Scalars['String']>
}

export type Branding = {
  __typename?: 'Branding'
  id: Scalars['ID']
  /**   URL from which to retrive the logo */
  logo: BrandingLogo
  /**   Custom URL segment for this particular brand */
  subdomain: Scalars['String']
  /**   Practice tagline */
  tagLine?: Maybe<Scalars['String']>
  colorPrimary: Scalars['String']
  colorSecondary?: Maybe<Scalars['String']>
  colorTertiary?: Maybe<Scalars['String']>
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type BrandingLogo = {
  __typename?: 'BrandingLogo'
  /**   Public URL that can be used to download or display the file */
  downloadUrl: Scalars['AWSURL']
  /**   Signed URL that will accept a binary file upload via a PUT request until expiration */
  uploadUrl: Scalars['AWSURL']
}

export type BrandingUpdateInput = {
  /**   Custom URL segment for this particular brand */
  subdomain?: Maybe<Scalars['String']>
  /**   Practice tagline */
  tagLine?: Maybe<Scalars['String']>
  colorPrimary?: Maybe<Scalars['String']>
  colorSecondary?: Maybe<Scalars['String']>
  colorTertiary?: Maybe<Scalars['String']>
}

export type CareTeamMember = {
  __typename?: 'CareTeamMember'
  email?: Maybe<Scalars['AWSEmail']>
  fax?: Maybe<Scalars['AWSPhone']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['AWSPhone']>
  status?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type CareTeamMemberInput = {
  email?: Maybe<Scalars['AWSEmail']>
  fax?: Maybe<Scalars['AWSPhone']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['AWSPhone']>
  status?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type ClientSignature = {
  __typename?: 'ClientSignature'
  ip: Scalars['String']
  dateTime: Scalars['AWSDateTime']
  accountId: Scalars['ID']
}

/**   method of documentation employed by healthcare providers to write out notes in a patient's chart */
export type ClinicalNote = {
  __typename?: 'ClinicalNote'
  /**   unique identifier */
  id: Scalars['ID']
  /**   patient that the clinical note is being filled about */
  patient?: Maybe<PatientProfile>
  /**   The Practice user that created the Clinical note */
  createdBy?: Maybe<PracticeUser>
  /**   content of the Clinical Note */
  body?: Maybe<Scalars['String']>
  amendmentComponents?: Maybe<Array<Maybe<ClinicalNoteAmendment>>>
  /**   Once a Clinical Note is "final", any future amendments will fail */
  status?: Maybe<ClinicalNoteStatus>
  /**   timestamp when this clinical note was last updated */
  updatedOn: Scalars['AWSTimestamp']
  /**   timestamp when this clinical note was first created */
  createdOn: Scalars['AWSTimestamp']
}

export type ClinicalNoteAmendment = {
  __typename?: 'ClinicalNoteAmendment'
  id: Scalars['ID']
  body?: Maybe<Scalars['String']>
  /**   timestamp when this clinical note was last updated */
  updatedOn: Scalars['AWSTimestamp']
  /**   timestamp when this form was first created (expressed in seconds since the epoch) */
  createdOn: Scalars['AWSTimestamp']
}

export type ClinicalNoteAmendmentAnswerInput = {
  /**   ID of the associated clinical note amendment */
  clinicalNoteModularAmendmentId: Scalars['ID']
  /**   ID of the associated clinical note field */
  clinicalNoteFieldId: Scalars['ID']
  /**   answer provided to this clinical note field */
  answer: Scalars['String']
}

export type ClinicalNoteAmendmentCreateInput = {
  clinicalNoteId: Scalars['ID']
  body: Scalars['String']
}

export type ClinicalNoteAnswer = {
  __typename?: 'ClinicalNoteAnswer'
  id: Scalars['ID']
  /**   associated clinical note field */
  field: ClinicalNoteModuleField
  /**   answer provided to this clinical note field */
  answer: Scalars['String']
  /**   timestamp when this answer was last updated */
  updatedOn: Scalars['AWSTimestamp']
  /**   timestamp when this answer was first created */
  createdOn: Scalars['AWSTimestamp']
}

export type ClinicalNoteAnswerInput = {
  /**   ID of the associated clinical note */
  clinicalNoteModularId: Scalars['ID']
  /**   ID of the associated clinical note field */
  clinicalNoteFieldId: Scalars['ID']
  /**   answer provided to this clinical note field */
  answer: Scalars['String']
}

export type ClinicalNoteCreateInput = {
  /**   the id of the patient that the clinical note is about */
  patientProfileId: Scalars['ID']
  /**   the id of the appointment this clinical note is attached to  */
  appointmentId: Scalars['ID']
}

export enum ClinicalNoteElementTypes {
  Checkboxes = 'Checkboxes',
  DateInput = 'Date_Input',
  Dropdown = 'Dropdown',
  FormattedTextBlock = 'Formatted_Text_Block',
  RadioButtons = 'Radio_Buttons',
  TextArea = 'TextArea',
  TextInput = 'Text_Input',
  /**  Term with length, duration, unit */
  Term = 'Term',
  /**  Blood pressure with systole, diastole */
  BloodPressure = 'BloodPressure',
  /**  Oxygen with value, supplemental */
  Oxygen = 'Oxygen',
  /**  Temperature with value, location */
  Temperature = 'Temperature',
  /**  Balance goal */
  Balance = 'Balance',
  /**  Motion with body part, range, side */
  Motion = 'Motion',
  /**  Pain with body part, type, level */
  Pain = 'Pain',
  /**  Pain Education */
  PainEducation = 'Pain_Education',
  /**  Posture */
  Posture = 'Posture',
  /**  Strength */
  Strength = 'Strength',
  /**  Doctor Signature */
  Signature = 'Signature',
  /**  Reflexes */
  Reflexes = 'Reflexes',
  /**  HEP */
  Hep = 'HEP',
  /**  Transfers */
  Transfers = 'Transfers',
  /**  Transfers Training */
  TransfersTraining = 'Transfers_Training',
  /**  Gait */
  Gait = 'Gait',
  /**  Gait Training */
  GaitTraining = 'Gait_Training',
  /**  Home Setup */
  HomeSetup = 'Home_Setup',
  /**  Exercise */
  Exercise = 'Exercise',
  /**  Billing */
  Billing = 'Billing',
  /**  Education */
  Education = 'Education',
  /**  Education Training */
  EducationTraining = 'Education_Training',
  /**  Goal */
  Goal = 'Goal',
  /**  Stair Training */
  StairTraining = 'Stair_Training',
  /**  Handwriting Skills */
  HandwritingSkills = 'Handwriting_Skills',
  /**  StartEndTimes */
  StartEndTimes = 'Start_End_Times',
  /**  Horizontal Checkbox */
  CheckboxesHorizontal = 'Checkboxes_Horizontal',
  /**  Horizontal Radio buttons */
  RadioButtonsHorizontal = 'Radio_Buttons_Horizontal',
}

/**   The new Clinical Note using page based templates  */
export type ClinicalNoteModular = {
  __typename?: 'ClinicalNoteModular'
  /**   unique identifier */
  id: Scalars['ID']
  /**   patient that the clinical note is being filled about */
  patient: PatientProfile
  /**   Appointment related to the Clinical Note */
  appointment: Appointment
  /**   the template used for this clinical note */
  icdCode?: Maybe<BillingCode>
  /**   The Practice user that created the Clinical note */
  createdBy?: Maybe<PracticeUser>
  /**   the template used for this clinical note */
  template?: Maybe<ClinicalNoteModularTemplate>
  /**   Answers that have been provided to the clinical note */
  answers?: Maybe<Array<Maybe<ClinicalNoteAnswer>>>
  /**   Amendments added to the clinical note */
  amendments?: Maybe<Array<Maybe<ClinicalNoteModularAmendment>>>
  /**   Once a Clinical Note is "final", any future amendments will fail */
  status?: Maybe<ClinicalNoteStatus>
  /**   timestamp when this clinical note was last updated */
  updatedOn: Scalars['AWSTimestamp']
  /**   timestamp when this clinical note was first created */
  createdOn: Scalars['AWSTimestamp']
}

export type ClinicalNoteModularAmendment = {
  __typename?: 'ClinicalNoteModularAmendment'
  id: Scalars['ID']
  /**   Once an ammendtment is "final", any future changes will fail */
  status?: Maybe<ClinicalNoteStatus>
  /**   Answers that have been provided to the clinical note amendment */
  answers?: Maybe<Array<Maybe<ClinicalNoteAnswer>>>
  /**   timestamp of expiry, after this the amendment is deleted in case status not "FINAL" */
  expiryOn?: Maybe<Scalars['AWSTimestamp']>
  /**   timestamp when this clinical note was last updated */
  updatedOn: Scalars['AWSTimestamp']
  /**   timestamp when this form was first created (expressed in seconds since the epoch) */
  createdOn: Scalars['AWSTimestamp']
}

export type ClinicalNoteModularAmendmentCreateInput = {
  clinicalNoteModularId: Scalars['ID']
}

export type ClinicalNoteModularCreateInput = {
  /**   the id of the template used for this clinical note */
  clinicalNoteModularTemplateId: Scalars['ID']
  /**   the id of the patient that the clinical note is about */
  patientProfileId: Scalars['ID']
  /**   the id of the appointment this clinical note is attached to  */
  appointmentId: Scalars['ID']
}

export type ClinicalNoteModularImportInput = {
  /**   ID of the ClinicalNoteModular to copy the answers from, if no source is passed it defaults to the most recent Cnt */
  sourceCntId?: Maybe<Scalars['ID']>
  /**   ID of the ClinicalNoteModular to copy the answers to */
  targetCntId: Scalars['ID']
}

export type ClinicalNoteModularTemplate = {
  __typename?: 'ClinicalNoteModularTemplate'
  id: Scalars['ID']
  /**   display name of the template */
  name: Scalars['String']
  /**   text to help identify a a particular tempalte. usually intended for internal use but couled be for anything. */
  description?: Maybe<Scalars['String']>
  /**   flag indicating if this template is active, and able to be assigned to Modular Clinical Notes */
  isActive: Scalars['Boolean']
  /**   list of pages that make up the Modular Clinical Note */
  pages: Array<ClinicalNoteTemplatePage>
  /**   the practice that owns this template */
  practice: Practice
  /**   date this template was created */
  createdOn: Scalars['AWSTimestamp']
  /**   date this template was last updated */
  updatedOn: Scalars['AWSTimestamp']
}

export type ClinicalNoteModularTemplateCreateInput = {
  /**   ID of the Practice that will own the new template */
  practiceId: Scalars['ID']
  /**   display name of the template */
  name: Scalars['String']
  /**   text to help identify a a particular template. */
  description?: Maybe<Scalars['String']>
  /**   flag indicating if this form is active, and able to be assigned to patients */
  isActive: Scalars['Boolean']
}

export type ClinicalNoteModularTemplateUpdateInput = {
  /**   display name of the template */
  name?: Maybe<Scalars['String']>
  /**   text to help identify a a particular tempalte. usually intended for internal use but couled be for anything. */
  description?: Maybe<Scalars['String']>
  /**   flag indicating if this form is active, and able to be assigned to patients */
  isActive?: Maybe<Scalars['Boolean']>
}

export type ClinicalNoteModularUpdateInput = {
  /**   the id of the template used for this clinical note */
  clinicalNoteModularTemplateId?: Maybe<Scalars['ID']>
  /**   Once a Clinical Note is "final", any future amendments will fail */
  status?: Maybe<ClinicalNoteStatus>
  /**   icd code id of the primary diagnosis */
  icdCodeId?: Maybe<Scalars['ID']>
}

export type ClinicalNoteModule = Entity & {
  __typename?: 'ClinicalNoteModule'
  id: Scalars['ID']
  label: Scalars['String']
  description?: Maybe<Scalars['String']>
  /**   list of fields */
  fields: Array<ClinicalNoteModuleField>
  type: ClinicalNoteModuleType
  category?: Maybe<Scalars['String']>
  createdOn: Scalars['AWSTimestamp']
  updatedOn: Scalars['AWSTimestamp']
}

export type ClinicalNoteModuleCreateInput = {
  label: Scalars['String']
  /**   Array of `ClinicalNoteModuleField` IDs */
  fieldIds: Array<Scalars['String']>
  description?: Maybe<Scalars['String']>
  type: ClinicalNoteModuleType
  category?: Maybe<Scalars['String']>
}

export type ClinicalNoteModuleField = Entity & {
  __typename?: 'ClinicalNoteModuleField'
  id: Scalars['ID']
  /**   only populated when requeted in the context of a specific ClinicalNoteModule */
  baseFieldId?: Maybe<Scalars['String']>
  /**
   *   flag if the field answers should be copied forward, only populated when
   * requeted in the context of a specific ClinicalNoteModule
   */
  isCopyForward?: Maybe<Scalars['Boolean']>
  /**   list of modules this field is used in */
  modules: Array<ClinicalNoteModule>
  /**   display name of the field */
  label: Scalars['String']
  /**
   *   Name of the type of element that should be used to represent this question
   * (ie: input, masked input, dropdown, radio, etc.)
   */
  elementType: ClinicalNoteElementTypes
  /**   Flag indicating if this field is required or not */
  isRequired?: Maybe<Scalars['Boolean']>
  /**   List of possible answers the user can pick from */
  answerOptions?: Maybe<Array<Scalars['String']>>
  /**   The default answer to this question if the user does not provide any */
  defaultAnswer?: Maybe<Scalars['String']>
  /**   date this field was created */
  createdOn: Scalars['AWSTimestamp']
  /**   date this field was last updated */
  updatedOn: Scalars['AWSTimestamp']
}

export type ClinicalNoteModuleFieldCreateInput = {
  /**   display name of the template */
  label: Scalars['String']
  /**
   *   Name of the type of element that should be used to represent this question
   * (ie: input, masked input, dropdown, radio, etc.)
   */
  elementType: ClinicalNoteElementTypes
  /**   Flag indicating if this field is required or not */
  isRequired?: Maybe<Scalars['Boolean']>
  /**   List of possible answers the user can pick from */
  answerOptions?: Maybe<Array<Scalars['String']>>
  /**   The default answer to this question if the user does not provide any */
  defaultAnswer?: Maybe<Scalars['String']>
}

export type ClinicalNoteModuleFieldFilterInput = {
  label?: Maybe<Scalars['String']>
  elementType?: Maybe<Array<Maybe<ClinicalNoteElementTypes>>>
}

export type ClinicalNoteModuleFieldUpdateInput = {
  /**   display name of the template */
  label: Scalars['String']
  /**
   *   Name of the type of element that should be used to represent this question
   * (ie: input, masked input, dropdown, radio, etc.)
   */
  elementType: ClinicalNoteElementTypes
  /**   Flag indicating if this field is required or not */
  isRequired?: Maybe<Scalars['Boolean']>
  /**   List of possible answers the user can pick from */
  answerOptions?: Maybe<Array<Scalars['String']>>
  /**   The default answer to this question if the user does not provide any */
  defaultAnswer?: Maybe<Scalars['String']>
}

export type ClinicalNoteModuleFilterInput = {
  label?: Maybe<Scalars['String']>
  type?: Maybe<ClinicalNoteModuleType>
  category?: Maybe<Scalars['String']>
}

export enum ClinicalNoteModuleType {
  ObjectiveMeasuresAndTests = 'OBJECTIVE_MEASURES_AND_TESTS',
  Generic = 'GENERIC',
  Intervention = 'INTERVENTION',
}

export type ClinicalNoteModuleUpdateInput = {
  label: Scalars['String']
  /**   Array of `ClinicalNoteModuleField` IDs */
  fieldIds?: Maybe<Array<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  type?: Maybe<ClinicalNoteModuleType>
  category?: Maybe<Scalars['String']>
}

export enum ClinicalNoteStatus {
  InProgress = 'IN_PROGRESS',
  Final = 'FINAL',
  Cancelled = 'CANCELLED',
}

export type ClinicalNoteTemplate = {
  __typename?: 'ClinicalNoteTemplate'
  id: Scalars['ID']
  /**   display name of the template */
  name: Scalars['String']
  /**   template content */
  body?: Maybe<Scalars['String']>
  /**   text to help identify a a particular tempalte. usually intended for internal use but couled be for anything. */
  description?: Maybe<Scalars['String']>
  /**   flag indicating if this form is active, and able to be assigned to patients */
  isActive: Scalars['Boolean']
  /**   the practice that owns this template */
  practice: Practice
  /**   date this template was created */
  createdOn: Scalars['AWSTimestamp']
  /**   date this template was last updated */
  updatedOn: Scalars['AWSTimestamp']
}

export type ClinicalNoteTemplateCreateInput = {
  /**   ID of the Practice that will own the new template */
  practiceId: Scalars['ID']
  /**   display name of the template */
  name: Scalars['String']
  /**   template content */
  body?: Maybe<Scalars['String']>
  /**   text to help identify a a particular tempalte. usually intended for internal use but couled be for anything. */
  description?: Maybe<Scalars['String']>
  /**   flag indicating if this form is active, and able to be assigned to patients */
  isActive: Scalars['Boolean']
}

export type ClinicalNoteTemplatePage = {
  __typename?: 'ClinicalNoteTemplatePage'
  id: Scalars['ID']
  /**   user-friendly, short text to differenciate one page from another */
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
  /**   the position/order the page should be in */
  ordinal: Scalars['Int']
  /**   list of sections that make up this clinical note */
  sections: Array<ClinicalNoteTemplateSection>
  /**   date this template was created */
  createdOn: Scalars['AWSTimestamp']
  /**   date this template was last updated */
  updatedOn: Scalars['AWSTimestamp']
}

export type ClinicalNoteTemplatePageCreateInput = {
  /**   ID of the Modular CNT that owns/holds this page */
  clinicalNoteModularTemplateId: Scalars['ID']
  /**   user-friendly, short text to differenciate one page from another */
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
  /**   the position/order the page should be in */
  ordinal: Scalars['Int']
}

export type ClinicalNoteTemplatePageUpdateInput = {
  /**   user-friendly, short text to differenciate one page from another */
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /**   the position/order the page should be in */
  ordinal?: Maybe<Scalars['Int']>
}

export type ClinicalNoteTemplateSection = {
  __typename?: 'ClinicalNoteTemplateSection'
  /**   unique identifyer for a section */
  id: Scalars['ID']
  /**   section e.g.: Goals, Assessment, Evaluation */
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  isModulePicker?: Maybe<Scalars['Boolean']>
  /**   The order the section should appear on the clinical note */
  ordinal: Scalars['Int']
  /**   list of clinical note modules that need to appear in this section */
  modules: Array<ClinicalNoteModule>
  /**   timestamp when this section was first created (expressed in seconds since the epoch) */
  createdOn: Scalars['AWSTimestamp']
  /**   timestamp when this section was last updated (expressed in seconds since the epoch) */
  updatedOn: Scalars['AWSTimestamp']
}

export type ClinicalNoteTemplateSectionCreateInput = {
  /**   section e.g.: Goals, Assessment, Evaluation */
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  isModulePicker?: Maybe<Scalars['Boolean']>
  /**   the ID of the CNT page this section goes in */
  clinicalNoteTemplatePageId: Scalars['ID']
  /**   the order the item should appear on the clinical note */
  ordinal: Scalars['Int']
}

export type ClinicalNoteTemplateSectionUpdateInput = {
  /**   section e.g.: Goals, Assessment, Evaluation */
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  clinicalNoteTemplatePageId?: Maybe<Scalars['ID']>
  isModulePicker?: Maybe<Scalars['Boolean']>
  moduleIds?: Maybe<Array<Scalars['ID']>>
  /**   the order the item should appear on the clinical note */
  ordinal?: Maybe<Scalars['Int']>
}

export type ClinicalNoteTemplateUpdateInput = {
  /**   display name of the template */
  name?: Maybe<Scalars['String']>
  /**   text to help identify a a particular tempalte. usually intended for internal use but couled be for anything. */
  description?: Maybe<Scalars['String']>
  /**   flag indicating if this form is active, and able to be assigned to patients */
  isActive?: Maybe<Scalars['Boolean']>
}

export type ClinicalNoteUpdateAmendmentStatusInput = {
  status?: Maybe<ClinicalNoteStatus>
}

export type ClinicalNoteUpdateInput = {
  /**   content of the Clinical Note */
  body?: Maybe<Scalars['String']>
  /**   Once a Clinical Note is "final", any future amendments will fail */
  status?: Maybe<ClinicalNoteStatus>
}

export type CnModuleFieldRelationshipUpdateInput = {
  isCopyForward?: Maybe<Scalars['Boolean']>
}

export type CommunicationJourmalEmaileMessage = {
  __typename?: 'CommunicationJourmalEmaileMessage'
  /**   address to which the email was sent */
  to: Scalars['String']
  /**   address that will appear that it as sent from */
  from: Scalars['AWSEmail']
  /**   email subject line */
  subject: Scalars['String']
  /**   email content/body */
  body: Scalars['String']
  /**   address that will show up when the recipient clicks "reply" */
  replyTo: Array<Scalars['String']>
}

export type CommunicationJourmalEntry = {
  __typename?: 'CommunicationJourmalEntry'
  id: Scalars['ID']
  messageDetails: CommunicationMessage
  /**   date when this entry was created */
  createdOn: Scalars['AWSTimestamp']
}

export type CommunicationJourmalSmsMessage = {
  __typename?: 'CommunicationJourmalSmsMessage'
  /**   content of the text message */
  body: Scalars['String']
  /**   text message recipient phone umber */
  to: Scalars['String']
}

export enum CommunicationMedium {
  Sms = 'SMS',
  Email = 'EMAIL',
}

export type CommunicationMessage =
  | CommunicationJourmalEmaileMessage
  | CommunicationJourmalSmsMessage

export type CompoundFieldValue =
  | Address
  | Employment
  | Insurance
  | Contact
  | Medication
  | CareTeamMember
  | Surgery
  | Names

export type Contact = {
  __typename?: 'Contact'
  dateOfBirth?: Maybe<Scalars['AWSDate']>
  email?: Maybe<Scalars['AWSEmail']>
  name: Scalars['String']
  phoneNumber: Scalars['AWSPhone']
  relationship: Scalars['String']
}

export type ContactInput = {
  dateOfBirth?: Maybe<Scalars['AWSDate']>
  email?: Maybe<Scalars['AWSEmail']>
  name: Scalars['String']
  phoneNumber: Scalars['AWSPhone']
  relationship: Scalars['String']
}

/**   Properties neceasry for createing a Practice */
export type CreatePracticeInput = {
  isActive?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  email: Scalars['AWSEmail']
  phone: Scalars['AWSPhone']
  fax?: Maybe<Scalars['AWSPhone']>
  /**   a practices NPI */
  nationalProviderIdentifier?: Maybe<Scalars['String']>
  /**   unique ID to identify a practice in the Aithoz system */
  aithozClientId?: Maybe<Scalars['String']>
  billingAddress?: Maybe<AddressInput>
  /**   the type of practice - dental, mendical, chiropractic, etc */
  specialty?: Maybe<DoctorSpecialty>
  website: Scalars['String']
  practiceManagementSoftware?: Maybe<Scalars['String']>
  referringDoctors?: Maybe<Array<ReferringDoctorInput>>
}

export type Currentuser = Family | PracticeUser | PsAdminAccount

export type DoctorNote = {
  __typename?: 'DoctorNote'
  id: Scalars['ID']
  createdBy: PracticeUser
  note: Scalars['String']
  createdOn: Scalars['AWSTimestamp']
  updatedOn: Scalars['AWSTimestamp']
}

/**   These values/names are the same as what is defined in the CRM as a pick-list */
export enum DoctorSpecialty {
  Acupuncturist = 'ACUPUNCTURIST',
  Allergy = 'ALLERGY',
  AllergyImmunologist = 'ALLERGY_IMMUNOLOGIST',
  CardiovascularDisease = 'CARDIOVASCULAR_DISEASE',
  Chiropractic = 'CHIROPRACTIC',
  Dermatologist = 'DERMATOLOGIST',
  Endodontist = 'ENDODONTIST',
  FamilyPractice = 'FAMILY_PRACTICE',
  Gastroenterologist = 'GASTROENTEROLOGIST',
  GeneralDentist = 'GENERAL_DENTIST',
  InternalMedicine = 'INTERNAL_MEDICINE',
  Neurologist = 'NEUROLOGIST',
  Obgyn = 'OBGYN',
  Ophthalmologist = 'OPHTHALMOLOGIST',
  OralMaxillofacialSurgeon = 'ORAL_MAXILLOFACIAL_SURGEON',
  Orthodontist = 'ORTHODONTIST',
  OrthopedicSurgeon = 'ORTHOPEDIC_SURGEON',
  OsteopathicMedicine = 'OSTEOPATHIC_MEDICINE',
  Other = 'OTHER',
  OtolaryngologistEnt = 'OTOLARYNGOLOGIST_ENT',
  PainManagement = 'PAIN_MANAGEMENT',
  Pediatrician = 'PEDIATRICIAN',
  Pedodontist = 'PEDODONTIST',
  Periodontist = 'PERIODONTIST',
  Phychologist = 'PHYCHOLOGIST',
  PhysicalTherapist = 'PHYSICAL_THERAPIST',
  PlasticSurgeon = 'PLASTIC_SURGEON',
  Podiatrist = 'PODIATRIST',
  Psychiatrist = 'PSYCHIATRIST',
  Psychologist = 'PSYCHOLOGIST',
  Radiologist = 'RADIOLOGIST',
  Urologist = 'UROLOGIST',
}

export enum ElementTypes {
  Address = 'Address',
  CareTeamMember = 'CareTeamMember',
  Checkboxes = 'Checkboxes',
  Contact = 'Contact',
  DateInput = 'Date_Input',
  Dropdown = 'Dropdown',
  Email = 'Email',
  Employment = 'Employment',
  FileUpload = 'File_Upload',
  FormattedTextBlock = 'Formatted_Text_Block',
  Insurance = 'Insurance',
  Medication = 'Medication',
  Names = 'Names',
  PhoneNumber = 'Phone_Number',
  RadioButtons = 'Radio_Buttons',
  Ssn = 'SSN',
  Signature = 'Signature',
  State = 'State',
  Surgery = 'Surgery',
  TextArea = 'TextArea',
  TextInput = 'Text_Input',
}

export type Employment = {
  __typename?: 'Employment'
  employerName: Scalars['String']
  jobPosition?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['AWSPhone']>
}

export type EmploymentInput = {
  employerName: Scalars['String']
  jobPosition?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['AWSPhone']>
}

export type Entity = {
  /**   date the entity was created */
  createdOn: Scalars['AWSTimestamp']
  /**   unique identifier */
  id: Scalars['ID']
  /**   date the entity was last updated */
  updatedOn: Scalars['AWSTimestamp']
}

export type Family = Entity & {
  __typename?: 'Family'
  id: Scalars['ID']
  /**   The family name (usually the last name/surname) */
  name: Scalars['String']
  email?: Maybe<Scalars['AWSEmail']>
  phoneNumber?: Maybe<Scalars['AWSPhone']>
  /**   The Cognito account that is responsible for this family */
  account?: Maybe<PatientAccount>
  members: Array<Maybe<PatientProfile>>
  /**
   *   list of appointments, if no `startDate` is specified will default to today and only show future appointments.
   *  if `showAll` is set to true, any provided date range will be ignored and both
   * past and future appointments will be included
   */
  appointments?: Maybe<Array<Maybe<Appointment>>>
  /**   list of assigned forms associated with this family */
  assignedForms?: Maybe<Array<Maybe<AssignedForm>>>
  createdOn: Scalars['AWSTimestamp']
  updatedOn: Scalars['AWSTimestamp']
}

export type FamilyAppointmentsArgs = {
  startDate?: Maybe<Scalars['AWSDate']>
  endDate?: Maybe<Scalars['AWSDate']>
  showAll?: Maybe<Scalars['Boolean']>
}

export type FamilyAssignedFormsArgs = {
  limit?: Maybe<Scalars['Int']>
  isSubmitted?: Maybe<Scalars['Boolean']>
}

export type FamilyInputUpdate = {
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['AWSEmail']>
  phoneNumber?: Maybe<Scalars['AWSPhone']>
}

export type FeatureConfig = {
  __typename?: 'FeatureConfig'
  id: Scalars['ID']
  /**   flag indicating if the patient portal should have the ability to self-schedule appointments */
  patientPortal_hasScheduling?: Maybe<Scalars['Boolean']>
  /**   flag indicating if the doctor portal should have the ability to create appointments */
  doctorPortal_hasScheduling?: Maybe<Scalars['Boolean']>
  /**   flag indicating if the doctor portal should have the ability to create and submit insurance claims */
  doctorPortal_hasInsuranceClaims?: Maybe<Scalars['Boolean']>
  /**   flag indicating if the practice is an EHR practice */
  doctorPortal_isEHR?: Maybe<Scalars['Boolean']>
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type FeatureConfigUpdateInput = {
  patientPortal_hasScheduling?: Maybe<Scalars['Boolean']>
  doctorPortal_hasScheduling?: Maybe<Scalars['Boolean']>
  doctorPortal_hasInsuranceClaims?: Maybe<Scalars['Boolean']>
  doctorPortal_isEHR?: Maybe<Scalars['Boolean']>
}

export type FieldDependency = {
  __typename?: 'FieldDependency'
  answer: Scalars['String']
  /**   question: FormField! */
  fieldId: Scalars['ID']
}

export type FieldDependencyInput = {
  fieldId: Scalars['ID']
  answer: Scalars['String']
}

export type FieldTemplate = Entity & {
  __typename?: 'FieldTemplate'
  id: Scalars['ID']
  fieldTemplateGroups?: Maybe<PaginatedList>
  /**   The default text for the question */
  defaultLabel: Scalars['String']
  /**
   *   Name of the type of element that should be used to represent this question
   * (ie: input, masked input, dropdown, radio, etc.)
   */
  elementType: ElementTypes
  /**   Flag indicating if this field is required or not */
  isRequired?: Maybe<Scalars['Boolean']>
  /**   Flag indicating of this field should be hidden from patients */
  isInternalUseOnly?: Maybe<Scalars['Boolean']>
  /**   Minimum number of characters or selections needed */
  minimumLength?: Maybe<Scalars['Int']>
  /**   Maximum number of characters or selections possible */
  maximumLength?: Maybe<Scalars['Int']>
  /**   List of possible answers the user can pick from */
  answerOptions?: Maybe<Array<Scalars['String']>>
  /**   The default answer to this question if the user does not provide any */
  defaultAnswer?: Maybe<Scalars['String']>
  /**   timestamp when this form was first created (expressed in seconds since the epoch) */
  createdOn: Scalars['AWSTimestamp']
  /**   timestamp when this form was last updated (expressed in seconds since the epoch) */
  updatedOn: Scalars['AWSTimestamp']
}

export type FieldTemplateFieldTemplateGroupsArgs = {
  pagination?: Maybe<PaginationInput>
}

export type FieldTemplateCreateInput = {
  id: Scalars['String']
  fieldTemplateGroupIds?: Maybe<Array<Maybe<Scalars['ID']>>>
  /**   The default text for the question */
  defaultLabel: Scalars['String']
  /**
   *   Name of the type of element that should be used to represent this question
   * (ie: input, masked input, dropdown, radio, etc.)
   */
  elementType: ElementTypes
  /**   Flag indicating if this field is required or not */
  isRequired?: Maybe<Scalars['Boolean']>
  isInternalUseOnly?: Maybe<Scalars['Boolean']>
  /**   Minimum number of characters or selections needed */
  minimumLength?: Maybe<Scalars['Int']>
  /**   Maximum number of characters or selections possible */
  maximumLength?: Maybe<Scalars['Int']>
  /**   List of possible answers the user can pick from */
  answerOptions?: Maybe<Array<Maybe<Scalars['String']>>>
  /**   The default answer to this question if the user does not provide any */
  defaultAnswer?: Maybe<Scalars['String']>
}

export type FieldTemplateFilterInput = {
  defaultLabel?: Maybe<Scalars['String']>
  elementType?: Maybe<Array<Maybe<ElementTypes>>>
}

export type FieldTemplateGroup = Entity & {
  __typename?: 'FieldTemplateGroup'
  id: Scalars['ID']
  displayName: Scalars['String']
  fieldTemplates?: Maybe<PaginatedList>
  /**   timestamp when this form was first created (expressed in seconds since the epoch) */
  createdOn: Scalars['AWSTimestamp']
  /**   timestamp when this form was last updated (expressed in seconds since the epoch) */
  updatedOn: Scalars['AWSTimestamp']
}

export type FieldTemplateGroupFieldTemplatesArgs = {
  pagination?: Maybe<PaginationInput>
}

export type FieldTemplateGroupCreateInput = {
  id: Scalars['ID']
  displayName: Scalars['String']
}

export type FieldTemplateGroupFilterInput = {
  displayName?: Maybe<Scalars['String']>
}

export type FieldTemplateGroupUpdateInput = {
  displayName?: Maybe<Scalars['String']>
}

export type FieldTemplateUpdateInput = {
  fieldTemplateGroupIds?: Maybe<Array<Maybe<Scalars['ID']>>>
  /**   The default text for the question */
  defaultLabel?: Maybe<Scalars['String']>
  /**
   *   Name of the type of element that should be used to represent this question
   * (ie: input, masked input, dropdown, radio, etc.)
   */
  elementType?: Maybe<ElementTypes>
  /**   Flag indicating if this field is required or not */
  isRequired?: Maybe<Scalars['Boolean']>
  isInternalUseOnly?: Maybe<Scalars['Boolean']>
  /**   Minimum number of characters or selections needed */
  minimumLength?: Maybe<Scalars['Int']>
  /**   Maximum number of characters or selections possible */
  maximumLength?: Maybe<Scalars['Int']>
  /**   List of possible answers the user can pick from */
  answerOptions?: Maybe<Array<Maybe<Scalars['String']>>>
  /**   The default answer to this question if the user does not provide any */
  defaultAnswer?: Maybe<Scalars['String']>
}

export type Form = {
  __typename?: 'Form'
  /**   unique form identifyer */
  id: Scalars['ID']
  /**   user-friendly name to help identify a particular form */
  name: Scalars['String']
  /**   text to help identify a form. usually intended for internal use but couled be for anything. */
  description?: Maybe<Scalars['String']>
  /**   list of pages that make up this form */
  pages: Array<FormPage>
  /**   the practice that owns this form */
  practice: Practice
  /**   flag indicating if this form is active, and able to be assigned to patients */
  isActive: Scalars['Boolean']
  /**   timestamp when this form was first created (expressed in seconds since the epoch) */
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  /**   timestamp when this form was last updated (expressed in seconds since the epoch) */
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type FormCreateInput = {
  /**   user-friendly name to help identify a particular form */
  name: Scalars['String']
  /**   text to help identify a form. usually intended for internal use but couled be for anything. */
  description?: Maybe<Scalars['String']>
  /**   ID of the practice that will own this form */
  practiceId: Scalars['ID']
  /**   flag indicating if this form is active, and able to be assigned to patients */
  isActive: Scalars['Boolean']
}

export type FormField = {
  __typename?: 'FormField'
  id: Scalars['ID']
  fieldTemplate?: Maybe<FieldTemplate>
  /**
   *   Name of the type of element that should be used to represent this question
   * (ie: input, masked input, dropdown, radio, etc.)
   */
  elementType: ElementTypes
  /**   The order the question should appear on the page */
  ordinal?: Maybe<Scalars['Int']>
  /**   The text for the question */
  label: Scalars['String']
  /**   Flag indicating if this field is required or not */
  isRequired?: Maybe<Scalars['Boolean']>
  /**   Flag indicating of this field should be hidden from patients */
  isInternalUseOnly?: Maybe<Scalars['Boolean']>
  /**   Minimum number of characters or selections needed */
  minimumLength?: Maybe<Scalars['Int']>
  /**   Maximum number of characters or selections possible */
  maximumLength?: Maybe<Scalars['Int']>
  /**   List of possible answers the user can pick from */
  answerOptions?: Maybe<Array<Scalars['String']>>
  /**   The default answer to this question if the user does not provide any */
  defaultAnswer?: Maybe<Scalars['String']>
  /**   the condition that much be met for this question to be visible */
  visibilityCondition?: Maybe<FieldDependency>
  /**   timestamp when this page was first created (expressed in seconds since the epoch) */
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  /**   timestamp when this page was last updated (expressed in seconds since the epoch) */
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type FormFieldCreateInput = {
  pageSectionId: Scalars['ID']
  fieldTemplateId?: Maybe<Scalars['ID']>
  formId?: Maybe<Scalars['ID']>
  /**
   *   Name of the type of element that should be used to represent this question
   * (ie: input, masked input, dropdown, radio, etc.)
   */
  elementType: ElementTypes
  /**   The order the question should appear on the page */
  ordinal?: Maybe<Scalars['Int']>
  /**   The text for the question */
  label: Scalars['String']
  /**   Flag indicating if this field is required or not */
  isRequired?: Maybe<Scalars['Boolean']>
  /**   Flag indicating of this field should be hidden from patients */
  isInternalUseOnly?: Maybe<Scalars['Boolean']>
  /**   Minimum number of characters or selections needed */
  minimumLength?: Maybe<Scalars['Int']>
  /**   Maximum number of characters or selections possible */
  maximumLength?: Maybe<Scalars['Int']>
  /**   List of possible answers the user can pick from */
  answerOptions?: Maybe<Array<Scalars['String']>>
  /**   The default answer to this question if the user does not provide any */
  defaultAnswer?: Maybe<Scalars['String']>
  /**   the condition that much be met for this question to be visible */
  visibilityCondition?: Maybe<FieldDependencyInput>
}

export type FormFieldUpdateInput = {
  fieldTemplateId?: Maybe<Scalars['ID']>
  pageSectionId?: Maybe<Scalars['ID']>
  elementType: ElementTypes
  /**   The order the question should appear on the page */
  ordinal?: Maybe<Scalars['Int']>
  /**   The text for the question */
  label?: Maybe<Scalars['String']>
  /**   Flag indicating if this field is required or not */
  isRequired?: Maybe<Scalars['Boolean']>
  /**   Flag indicating of this field should be hidden from patients */
  isInternalUseOnly?: Maybe<Scalars['Boolean']>
  /**   Minimum number of characters or selections needed */
  minimumLength?: Maybe<Scalars['Int']>
  /**   Maximum number of characters or selections possible */
  maximumLength?: Maybe<Scalars['Int']>
  /**   List of possible answers the user can pick from */
  answerOptions?: Maybe<Array<Scalars['String']>>
  /**   The default answer to this question if the user does not provide any */
  defaultAnswer?: Maybe<Scalars['String']>
  /**   the condition that much be met for this question to be visible */
  visibilityCondition?: Maybe<FieldDependencyInput>
}

export enum FormIsActiveValue {
  True = 'TRUE',
  False = 'FALSE',
  All = 'ALL',
}

export type FormPage = {
  __typename?: 'FormPage'
  /**   unique identifyer for a page */
  id: Scalars['ID']
  /**   user-friendly, short text to differenciate one page from another */
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
  header?: Maybe<Scalars['String']>
  footer?: Maybe<Scalars['String']>
  /**   The order the page should appear on the form */
  ordinal?: Maybe<Scalars['Int']>
  /**   The form that owns this page */
  form: Form
  /**   list of sections */
  sections: Array<FormPageSection>
  /**   timestamp when this page was first created (expressed in seconds since the epoch) */
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  /**   timestamp when this page was last updated (expressed in seconds since the epoch) */
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type FormPageCreateInput = {
  /**   user-friendly, short text to differenciate one page from another */
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
  header?: Maybe<Scalars['String']>
  footer?: Maybe<Scalars['String']>
  /**   ID to the form that this page will go on */
  formId: Scalars['ID']
  /**   The order the page should appear on the form */
  ordinal?: Maybe<Scalars['Int']>
}

export type FormPageSection = {
  __typename?: 'FormPageSection'
  /**   unique identifier for a page */
  id: Scalars['ID']
  /**   user-friendly, short text to differentiate one page from another */
  title: Scalars['String']
  /**   The order the page should appear on the form */
  ordinal?: Maybe<Scalars['Int']>
  /**   The form that owns this page */
  page: FormPage
  /**   Flag indicating of this field should be hidden from patients */
  isInternalUseOnly?: Maybe<Scalars['Boolean']>
  /**   list of fields */
  fields?: Maybe<Array<Maybe<FormField>>>
  /**   timestamp when this page was first created (expressed in seconds since the epoch) */
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  /**   timestamp when this page was last updated (expressed in seconds since the epoch) */
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type FormPageSectionCopyInput = {
  formPageSectionId: Scalars['ID']
  title?: Maybe<Scalars['String']>
  ordinal?: Maybe<Scalars['Int']>
}

export type FormPageSectionCreateInput = {
  title: Scalars['String']
  pageId: Scalars['ID']
  formId?: Maybe<Scalars['ID']>
  /**   Flag indicating of this field should be hidden from patients */
  isInternalUseOnly?: Maybe<Scalars['Boolean']>
  /**   The order the page should appear on the form */
  ordinal?: Maybe<Scalars['Int']>
}

export type FormPageSectionPopulateInput = {
  formPageSectionId: Scalars['ID']
  fieldTemplateGroupId: Scalars['ID']
}

export type FormPageSectionUpdateInput = {
  title?: Maybe<Scalars['String']>
  pageId?: Maybe<Scalars['ID']>
  /**   Flag indicating of this field should be hidden from patients */
  isInternalUseOnly?: Maybe<Scalars['Boolean']>
  /**   The order the page should appear on the form */
  ordinal?: Maybe<Scalars['Int']>
}

export type FormPageUpdateInput = {
  /**   user-friendly, short text to differenciate one page from another */
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  header?: Maybe<Scalars['String']>
  footer?: Maybe<Scalars['String']>
  /**   The order the page should appear on the form */
  ordinal?: Maybe<Scalars['Int']>
}

export type FormUpdateInput = {
  /**   user-friendly name to help identify a particular form */
  name: Scalars['String']
  /**   text to help identify a form. usually intended for internal use but couled be for anything. */
  description?: Maybe<Scalars['String']>
  /**   flag indicating if this form is active, and able to be assigned to patients */
  isActive?: Maybe<Scalars['Boolean']>
}

export enum GenderCode {
  Male = 'MALE',
  Female = 'FEMALE',
  NotSpecified = 'NOT_SPECIFIED',
}

export type Insurance = {
  __typename?: 'Insurance'
  insuranceCompany: Scalars['String']
  insuranceGroupNumber?: Maybe<Scalars['String']>
  insurancePolicyNumber?: Maybe<Scalars['String']>
  subscriber: Scalars['String']
  subscriberDob?: Maybe<Scalars['AWSDate']>
  subscriberFirstName: Scalars['String']
  subscriberRelationship?: Maybe<SubscriberRelationshipTypes>
}

export type InsuranceClaim = {
  __typename?: 'InsuranceClaim'
  id: Scalars['ID']
  appointment: Appointment
  subscriber?: Maybe<InsuranceClaimSubscriber>
  patientExtendedInformation?: Maybe<InsuranceClaimPatient>
  insuranceName?: Maybe<Scalars['String']>
  /**
   *  Ploicy or Plan ID taken from the users insurance card
   * Ploicy or Plan ID taken from the users insurance card
   */
  insurancePolicyNumber?: Maybe<Scalars['String']>
  primaryInsurance?: Maybe<InsuranceInfomration>
  secondaryInsurance?: Maybe<InsuranceInfomration>
  paymentMethods?: Maybe<Array<InsurancePaymentMethod>>
  status: InsuranceClaimStatus
  serviceLocationType?: Maybe<ServiceLocationType>
  charges?: Maybe<Array<InsuranceClaimCharge>>
  claimId: Scalars['String']
  /**
   *  list of errors encountered when submitting the claim (sent back from Aithoz)
   * list of errors encountered when submitting the claim (sent back from Aithoz)
   */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>
  processor?: Maybe<Scalars['String']>
  authorizationNumber?: Maybe<Scalars['String']>
  workerCompensationClaimNumber?: Maybe<Scalars['String']>
  /**
   *  timestamp when this record was last updated
   * timestamp when this record was last updated
   */
  updatedOn: Scalars['AWSTimestamp']
  /**
   *  timestamp when this record was first created
   * timestamp when this record was first created
   */
  createdOn: Scalars['AWSTimestamp']
}

export type InsuranceClaimCharge = {
  __typename?: 'InsuranceClaimCharge'
  /**
   *  CTP or HCPCS code
   * CTP or HCPCS code
   */
  procedureCode: Scalars['String']
  /**
   *  number of units associated with CTP or HCPCS code
   * number of units associated with CTP or HCPCS code
   */
  units: Scalars['Int']
  /**
   *  ICD codes, max of 4. All others will be ignored
   * ICD codes, max of 4. All others will be ignored
   */
  diagnosisCodes: Array<Scalars['String']>
  /**
   *  Date that the service was or will be performed
   * Date that the service was or will be performed
   */
  dateOfService: Scalars['AWSDate']
  /**
   *  CPT modifiers
   * CPT modifiers
   */
  modifiers?: Maybe<Array<Scalars['String']>>
  chargeFee: Scalars['String']
}

export type InsuranceClaimChargeInput = {
  /**
   *  CTP or HCPCS code
   * CTP or HCPCS code
   */
  procedureCode: Scalars['String']
  /**
   *  number of units associated with CTP or HCPCS code
   * number of units associated with CTP or HCPCS code
   */
  units: Scalars['Int']
  /**
   *  ICD codes, max of 4. All others will be ignored
   * ICD codes, max of 4. All others will be ignored
   */
  diagnosisCodes: Array<Scalars['String']>
  /**
   *  Date that the service was or will be performed
   * Date that the service was or will be performed
   */
  dateOfService: Scalars['AWSDate']
  /**
   *  CPT modifiers
   * CPT modifiers
   */
  modifiers?: Maybe<Array<Scalars['String']>>
  chargeFee: Scalars['String']
}

export type InsuranceClaimCreateInput = {
  /**
   *  ID of appointment for which the claim will be created
   * ID of appointment for which the claim will be created
   */
  appointmentId: Scalars['ID']
  patientExtendedInformation?: Maybe<InsuranceClaimPatientInput>
  subscriber?: Maybe<InsuranceClaimSubscriberInput>
  insuranceName?: Maybe<Scalars['String']>
  /**
   *  Ploicy or Plan ID taken from the users insurance card
   * Ploicy or Plan ID taken from the users insurance card
   */
  insurancePolicyNumber?: Maybe<Scalars['String']>
  serviceLocationType?: Maybe<ServiceLocationType>
  charges?: Maybe<Array<InsuranceClaimChargeInput>>
  authorizationNumber?: Maybe<Scalars['String']>
  workerCompensationClaimNumber?: Maybe<Scalars['String']>
  paymentMethods?: Maybe<Array<InsurancePaymentMethod>>
  /**
   *  DO NOT USE THIS YET, OMG NO - this is a placeholder and has not been implemented (and data needs to be migrated)
   * DO NOT USE THIS YET, OMG NO - this is a placeholder and has not been implemented (and data needs to be migrated)
   */
  primaryInsurance?: Maybe<InsuranceInfomrationInput>
  secondaryInsurance?: Maybe<InsuranceInfomrationInput>
}

export type InsuranceClaimPatient = {
  __typename?: 'InsuranceClaimPatient'
  genderCode: GenderCode
  address: Address
  relationshipToSubscriber: SubscriberRelationshipTypes
}

export type InsuranceClaimPatientInput = {
  genderCode: GenderCode
  address: AddressInput
  relationshipToSubscriber: SubscriberRelationshipTypes
}

export enum InsuranceClaimStatus {
  Unsubmitted = 'UNSUBMITTED',
  New = 'NEW',
  Review = 'REVIEW',
  Hold = 'HOLD',
  ReadyToBillPrimaryInsurance = 'READY_TO_BILL_PRIMARY_INSURANCE',
  ReadyToBillSecondaryInsurance = 'READY_TO_BILL_SECONDARY_INSURANCE',
  ReadyToBillPatient = 'READY_TO_BILL_PATIENT',
  BilledToPrimaryOrSecondaryInsurance = 'BILLED_TO_PRIMARY_OR_SECONDARY_INSURANCE',
  BilledToPatient = 'BILLED_TO_PATIENT',
  InvalidRejectedByExcelarev = 'INVALID_REJECTED_BY_EXCELAREV',
  RejectedByClearinghouse = 'REJECTED_BY_CLEARINGHOUSE',
  RejectedByPayer = 'REJECTED_BY_PAYER',
  AcknowledgeByPayer = 'ACKNOWLEDGE_BY_PAYER',
  AcceptedByClearinghouse = 'ACCEPTED_BY_CLEARINGHOUSE',
  Denied = 'DENIED',
  DeniedAppeal = 'DENIED_APPEAL',
  DeniedPartiallyPaid = 'DENIED_PARTIALLY_PAID',
  HoldByAr = 'HOLD_BY_AR',
  ReadyToAppeal = 'READY_TO_APPEAL',
  ReadyToRebill = 'READY_TO_REBILL',
  AppealedToPrimaryOrSecondaryInsurance = 'APPEALED_TO_PRIMARY_OR_SECONDARY_INSURANCE',
  RebilledToPrimaryOrSecondaryInsurance = 'REBILLED_TO_PRIMARY_OR_SECONDARY_INSURANCE',
  ClosedSettledBySystemWriteOff = 'CLOSED_SETTLED_BY_SYSTEM_WRITE_OFF',
  Closed = 'CLOSED',
  OpenWithAnOutstandingBalance = 'OPEN_WITH_AN_OUTSTANDING__BALANCE',
  CompletedClaimPaid = 'COMPLETED_CLAIM_PAID',
  AppealPaid = 'APPEAL_PAID',
  Void = 'VOID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PatientResponsibility = 'PATIENT_RESPONSIBILITY',
}

export type InsuranceClaimSubscriber = {
  __typename?: 'InsuranceClaimSubscriber'
  genderCode: GenderCode
  firstName?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  dateOfBirth: Scalars['AWSDate']
  address: Address
}

export type InsuranceClaimSubscriberInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  dateOfBirth: Scalars['AWSDate']
  genderCode: GenderCode
  address: AddressInput
}

export type InsuranceClaimUpdateInput = {
  patientExtendedInformation?: Maybe<InsuranceClaimPatientInput>
  subscriber?: Maybe<InsuranceClaimSubscriberInput>
  insuranceName?: Maybe<Scalars['String']>
  /**
   *  Ploicy or Plan ID taken from the users insurance card
   * Ploicy or Plan ID taken from the users insurance card
   */
  insurancePolicyNumber?: Maybe<Scalars['String']>
  serviceLocationType?: Maybe<ServiceLocationType>
  charges?: Maybe<Array<Maybe<InsuranceClaimChargeInput>>>
  authorizationNumber?: Maybe<Scalars['String']>
  workerCompensationClaimNumber?: Maybe<Scalars['String']>
  paymentMethods?: Maybe<Array<InsurancePaymentMethod>>
  /**
   *  DO NOT USE THIS YET, OMG NO - this is a placeholder and has not been implemented (and data needs to be migrated)
   * DO NOT USE THIS YET, OMG NO - this is a placeholder and has not been implemented (and data needs to be migrated)
   */
  primaryInsurance?: Maybe<InsuranceInfomrationInput>
  secondaryInsurance?: Maybe<InsuranceInfomrationInput>
}

export type InsuranceEligibility = {
  __typename?: 'InsuranceEligibility'
  /**
   *  Flag indicating if the check is complete or still in-progress
   * Flag indicating if the check is complete or still in-progress
   */
  isComplete: Scalars['Boolean']
  status: Scalars['String']
  htmlResponse: Scalars['String']
  errors?: Maybe<Scalars['String']>
  dateOfService: Scalars['AWSDate']
  createdOn: Scalars['AWSTimestamp']
}

export type InsuranceEligibilityInput = {
  insuranceName: Scalars['String']
  insuranceId?: Maybe<Scalars['String']>
  practiceId: Scalars['ID']
  patientProfileId?: Maybe<Scalars['ID']>
  providerFirstName: Scalars['String']
  providerLastName: Scalars['String']
  providerNPI: Scalars['String']
  /**
   *  insurance owners first name
   * insurance owners first name
   */
  subscriberFirstName: Scalars['String']
  /**
   *  insurance owners last name
   * insurance owners last name
   */
  subscriberLasttName: Scalars['String']
  /**
   *  insurance owners date of birth
   * insurance owners date of birth
   */
  subscriberDateOfBirth: Scalars['AWSDate']
  /**
   *  subscriber ID from insurance
   * subscriber ID from insurance
   */
  subscriberId: Scalars['String']
  /**
   *  Date that the service was or will be performed
   * Date that the service was or will be performed
   */
  dateOfService: Scalars['AWSDate']
}

export type InsuranceInfomration = {
  __typename?: 'InsuranceInfomration'
  insuranceName?: Maybe<Scalars['String']>
  /**
   *  Ploicy or Plan ID taken from the users insurance card
   * Ploicy or Plan ID taken from the users insurance card
   */
  insurancePolicyNumber?: Maybe<Scalars['String']>
  subscriber?: Maybe<InsuranceClaimSubscriber>
  patientRelationshipToSubscriber?: Maybe<SubscriberRelationshipTypes>
}

export type InsuranceInfomrationInput = {
  insuranceName?: Maybe<Scalars['String']>
  /**
   *  Ploicy or Plan ID taken from the users insurance card
   * Ploicy or Plan ID taken from the users insurance card
   */
  insurancePolicyNumber?: Maybe<Scalars['String']>
  subscriber?: Maybe<InsuranceClaimSubscriberInput>
  patientRelationshipToSubscriber?: Maybe<SubscriberRelationshipTypes>
}

export type InsuranceInput = {
  insuranceCompany: Scalars['String']
  insuranceGroupNumber?: Maybe<Scalars['String']>
  insurancePolicyNumber?: Maybe<Scalars['String']>
  subscriber: Scalars['String']
  subscriberDob?: Maybe<Scalars['AWSDate']>
  subscriberFirstName: Scalars['String']
  subscriberRelationship?: Maybe<SubscriberRelationshipTypes>
}

export type InsurancePatientBalance = {
  __typename?: 'InsurancePatientBalance'
  insuranceCurrent?: Maybe<Scalars['String']>
  insurance30Plus?: Maybe<Scalars['String']>
  insurance60Plus?: Maybe<Scalars['String']>
  insurance90Plus?: Maybe<Scalars['String']>
  insurance120Plus?: Maybe<Scalars['String']>
  insurance180Plus?: Maybe<Scalars['String']>
  insuranceBalance?: Maybe<Scalars['String']>
  insuranceTotalPayment?: Maybe<Scalars['String']>
  patientCurrent?: Maybe<Scalars['String']>
  patient30Plus?: Maybe<Scalars['String']>
  patient60Plus?: Maybe<Scalars['String']>
  patient90Plus?: Maybe<Scalars['String']>
  patient120Plus?: Maybe<Scalars['String']>
  patient180Plus?: Maybe<Scalars['String']>
  patientBalance?: Maybe<Scalars['String']>
  patientTotalPayment?: Maybe<Scalars['String']>
}

export enum InsurancePaymentMethod {
  SelfPay = 'SelfPay',
  PrimaryInsurance = 'PrimaryInsurance',
  SecondaryInsurance = 'SecondaryInsurance',
}

export type LoginInput = {
  /**   this can be the users email address or phone numbers */
  username: Scalars['String']
  password: Scalars['String']
  newPassword?: Maybe<Scalars['String']>
}

export enum LogType {
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING',
}

export type MedicalDataPoint = {
  __typename?: 'MedicalDataPoint'
  /**   display name / label */
  label: Scalars['String']
  isCompoundField?: Maybe<Scalars['Boolean']>
  /**   the Field Template that this Medical Data Point is based off on */
  fieldTemplate: FieldTemplate
  /**   list of Field Template Groups that this Medical Data Point belongs to */
  fieldTemplateGroups: Array<Maybe<FieldTemplateGroup>>
  /**   the value for this Medical Data Point */
  value?: Maybe<Array<Scalars['String']>>
  /**   only populated for data points that are related to Address information */
  valueAddress?: Maybe<Array<Address>>
  /**   only populated for data points that are related to Employment information */
  valueEmployment?: Maybe<Array<Employment>>
  /**   only populated for data points that are related to Insurance information */
  valueInsurance?: Maybe<Array<Insurance>>
  /**   only populated for data points that are related to Contact information */
  valueContact?: Maybe<Array<Contact>>
  /**   only populated for data points that are related to Medication information */
  valueMedication?: Maybe<Array<Medication>>
  /**   only populated for data points that are related to CareTeamMember information */
  valueCareTeamMember?: Maybe<Array<CareTeamMember>>
  /**   only populated for data points that are related to Surgery information */
  valueSurgery?: Maybe<Array<Surgery>>
  /**   only populated for data points that are related to Names information */
  valueNames?: Maybe<Array<Names>>
  /**   the timestamp of when this was last updated */
  updatedOn: Scalars['AWSTimestamp']
}

export type MedicalDataPointUpdateInput = {
  patientProfileId: Scalars['ID']
  value?: Maybe<Array<Scalars['String']>>
  /**   only populated for field that are related to Address information */
  valueAddress?: Maybe<Array<AddressInput>>
  /**   only populated for field that are related to Employment information */
  valueEmployment?: Maybe<Array<EmploymentInput>>
  /**   only populated for field that are related to Insurance information */
  valueInsurance?: Maybe<Array<InsuranceInput>>
  /**   only populated for field that are related to Contact information */
  valueContact?: Maybe<Array<ContactInput>>
  /**   only populated for field that are related to Medication information */
  valueMedication?: Maybe<Array<MedicationInput>>
  /**   only populated for field that are related to CareTeamMember information */
  valueCareTeamMember?: Maybe<Array<CareTeamMemberInput>>
  /**   only populated for field that are related to Surgery information */
  valueSurgery?: Maybe<Array<SurgeryInput>>
  /**   only populated for field that are related to Names information */
  valueNames?: Maybe<Array<NamesInput>>
}

export type Medication = {
  __typename?: 'Medication'
  comments?: Maybe<Scalars['String']>
  dosage: Scalars['String']
  endDate?: Maybe<Scalars['AWSDate']>
  frequency: Scalars['String']
  name: Scalars['String']
  startDate?: Maybe<Scalars['AWSDate']>
  status?: Maybe<Scalars['String']>
}

export type MedicationInput = {
  comments?: Maybe<Scalars['String']>
  dosage: Scalars['String']
  endDate?: Maybe<Scalars['AWSDate']>
  frequency: Scalars['String']
  name: Scalars['String']
  startDate?: Maybe<Scalars['AWSDate']>
  status?: Maybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  /**   copy an existing Clinical Note Modular Template  */
  copyClinicalNoteModularTemplate: ClinicalNoteModularTemplate
  /**   make a copy of an existing form, along with all of its pages, sections and fields */
  copyForm?: Maybe<Form>
  copyFormPageSection: FormPageSection
  /**   Create a new appointent for an existing patient */
  createAppointment?: Maybe<Appointment>
  /**   cerate a new pre-defined appointment cancelation reason */
  createAppointmentReasonTemplate: AppointmentReasonTemplate
  createAppointmentReminder?: Maybe<AppointmentReminder>
  createAppointmentType?: Maybe<AppointmentType>
  /**   create a new Billing Code for use with a particular Practice */
  createBillingCode: BillingCode
  /**   Create a new Clinical Note */
  createClinicalNote: ClinicalNote
  /**   Create a new ammendment for a Clincal Note */
  createClinicalNoteAmendment: ClinicalNoteAmendment
  /**   Create a new Clinical Note */
  createClinicalNoteModular: ClinicalNoteModular
  /**   Create an amendment for a Modular Clinical Note */
  createClinicalNoteModularAmendment: ClinicalNoteModularAmendment
  /**   create a new Clinical Note Modular Template */
  createClinicalNoteModularTemplate: ClinicalNoteModularTemplate
  createClinicalNoteModule: ClinicalNoteModule
  /**   create a new field on a field group */
  createClinicalNoteModuleField: ClinicalNoteModuleField
  /**   create a new template to be used with Clinical Notes */
  createClinicalNoteTemplate: ClinicalNoteTemplate
  /**   create a new template to be used with Clinical Notes */
  createClinicalNoteTemplatePage?: Maybe<ClinicalNoteTemplatePage>
  createClinicalNoteTemplateSection: ClinicalNoteTemplateSection
  /**   Create a new Patient profile. This does NOT create an account. */
  createDoctorNote: DoctorNote
  /**   Creates an "empty" answered form - intended for use when inviting a patient to answer a form */
  createEmptyAssignedForm?: Maybe<AssignedForm>
  createFieldTemplate: FieldTemplate
  createFieldTemplateGroup: FieldTemplateGroup
  /**   create a single form */
  createForm?: Maybe<Form>
  /**   create a new question on a specific page section */
  createFormField?: Maybe<FormField>
  /**   add a page to a form */
  createFormPage?: Maybe<FormPage>
  /**   create a new section on a page */
  createFormPageSection: FormPageSection
  /**
   *  Create a new insurance claim for the specified appointment
   * Create a new insurance claim for the specified appointment
   */
  createInsuranceClaim: InsuranceClaim
  /**   Create a new account of type "Patient Studio Administrator" */
  createPSAdminAccount: PracticeAccount
  /**   Create a new accout of type "patient" along with a PatientProfile and a new Family */
  createPatientAccount: PatientAccount
  /**   Create a new Patient profile. This does NOT create an account. */
  createPatientProfile: PatientProfile
  createPatientProfileAttachment?: Maybe<PatientProfileAttachment>
  /**   Create a new Payment */
  createPayment: Payment
  createPractice: Practice
  /**   Create a new account of type "office administrator" or "office staff" and seeds internal profile information */
  createPracticeAccount: PracticeAccount
  createPracticeLocation?: Maybe<PracticeLocation>
  createTwilioSubaccountForPractice?: Maybe<Practice>
  /**   delete an existing appointment cancelation reason */
  deleteAppointmentReasonTemplate: AppointmentReasonTemplate
  deleteAppointmentReminder?: Maybe<AppointmentReminder>
  deleteAppointmentType?: Maybe<AppointmentType>
  /**   Delete a Billing Code */
  deleteBillingCode: BillingCode
  /**   delete an existing Clinical Note Modular Template */
  deleteClinicalNoteModularTemplate?: Maybe<ClinicalNoteModularTemplate>
  deleteClinicalNoteModule: ClinicalNoteModule
  /**   delete a specific field */
  deleteClinicalNoteModuleField: ClinicalNoteModuleField
  /**   delete an existing Clinical Note Template */
  deleteClinicalNoteTemplate?: Maybe<ClinicalNoteTemplate>
  /**   delete an existing Clinical Note Template */
  deleteClinicalNoteTemplatePage?: Maybe<ClinicalNoteTemplatePage>
  deleteClinicalNoteTemplateSection: ClinicalNoteTemplateSection
  /**   Delete an existing Doctor Note */
  deleteDoctorNote: DoctorNote
  deleteFamily?: Maybe<Family>
  deleteFamilyByEmail?: Maybe<Family>
  deleteFieldTemplate: FieldTemplate
  deleteFieldTemplateGroup: FieldTemplateGroup
  /**   deletes a form that matches the given ID */
  deleteForm?: Maybe<Form>
  /**   delete a specific question */
  deleteFormField?: Maybe<FormField>
  /**   delete a page along with all of its sections and questions */
  deleteFormPage?: Maybe<FormPage>
  /**   delete a page section and all related fields */
  deleteFormPageSection: FormPageSection
  deletePatientProfile: PatientProfile
  deletePatientProfileAttachment?: Maybe<PatientProfileAttachment>
  deletePractice: Practice
  deletePracticeLocation: PracticeLocation
  deletePracticeUser?: Maybe<PracticeUser>
  deregisterPracticeSmsNumber?: Maybe<Practice>
  disableAccount?: Maybe<Account>
  enableAccount?: Maybe<Account>
  /**
   *   Import a Clinical Note Modular. Copy CN answers and existing appointment
   * Insurance Claims. It clears all existing answers on the target
   */
  importClinicalNoteModular?: Maybe<ClinicalNoteModular>
  /**   submit the filled out patient form */
  markAssignedFormAsDownloaded?: Maybe<AssignedForm>
  /**   Create a new Patient profile. This does NOT create an account. */
  notificationResend: CommunicationJourmalEntry
  /**   Populates the specified Form Page Section with all fields from a specified Field Template Group */
  populateFormPageSectionFromFieldTemplateGroup: FormPageSection
  registerPracticeSmsNumber?: Maybe<Practice>
  /**   Reschedule an existing appointment_0 */
  rescheduleAppointment?: Maybe<Appointment>
  resetNotificationTemplatesToDefault?: Maybe<NotificationTemplateTypes>
  /**   Set new account passsword */
  resetPassword?: Maybe<ResetPasswordResponse>
  /**   drop and recreate all specified ES indices followed by syncing DynamoDB data for specified` tables */
  resyncIndices?: Maybe<Array<Maybe<SyncResults>>>
  /**   update or create an existing answer */
  saveAssignedFormAnswer?: Maybe<AssignedFormAnswer>
  /**   save an amendment answer  */
  saveClinicalNoteAmendmentAnswer?: Maybe<ClinicalNoteAnswer>
  /**   create or update existing answer */
  saveClinicalNoteAnswer?: Maybe<ClinicalNoteAnswer>
  /**   Update the value of a particular Medical Data Point */
  saveMedicalDataPoint?: Maybe<MedicalDataPoint>
  selfAssignEmptyAssignedForm?: Maybe<AssignedForm>
  /**   Create a new accout of type "patient" along with a PatientProfile and a new Family */
  selfRegistrationPatientAccount: PatientAccount
  /**   Send an SMS mesasge to a particular patient profile */
  sendSmsMessage: SmsMessage
  /**   submit the filled out patient form */
  submitAssignedForm?: Maybe<AssignedForm>
  /**
   *  Submit an existing insurance claim
   * Submit an existing insurance claim
   */
  submitInsuranceClaim: InsuranceClaim
  /**   Change a limited set of properties on an Appointment (not intended for use to reschedule appointments) */
  updateAppointment?: Maybe<Appointment>
  /**   update an existing appointment cancelation reason */
  updateAppointmentReasonTemplate: AppointmentReasonTemplate
  updateAppointmentType?: Maybe<AppointmentType>
  /**   update an existing Billing Code */
  updateBillingCode: BillingCode
  /**   Update an existing Branding object */
  updateBranding?: Maybe<Branding>
  /**   Update an existing Clinical Note. Will fail if the status of the existing note is set to "FINAL" */
  updateClinicalNote: ClinicalNote
  /**   Update an existing Clinical Note. Will fail if the status of the existing note is set to "FINAL" */
  updateClinicalNoteModular: ClinicalNoteModular
  /**   Update a Clinical Note Ammentment status. Will fail if the status of the existing note is set to "FINAL" or "CANCELLED" */
  updateClinicalNoteModularAmendmentStatus: ClinicalNoteModularAmendment
  /**   update an existing Clinical Note Modular Template */
  updateClinicalNoteModularTemplate?: Maybe<ClinicalNoteModularTemplate>
  updateClinicalNoteModule: ClinicalNoteModule
  /**   update an existing field */
  updateClinicalNoteModuleField: ClinicalNoteModuleField
  /**   update an existing Clinical Note Template */
  updateClinicalNoteTemplate?: Maybe<ClinicalNoteTemplate>
  /**   update an existing Clinical Note Template */
  updateClinicalNoteTemplatePage?: Maybe<ClinicalNoteTemplatePage>
  updateClinicalNoteTemplateSection: ClinicalNoteTemplateSection
  /**   update module field relationship properties  */
  updateCnModuleFieldRelationshipProperties: ClinicalNoteModuleField
  /**   Updating an existing Doctor Note with a new comment */
  updateDoctorNote: DoctorNote
  updateFamily?: Maybe<Family>
  updateFeatureConfig: FeatureConfig
  updateFieldTemplate: FieldTemplate
  updateFieldTemplateGroup: FieldTemplateGroup
  /**   update an existing form */
  updateForm?: Maybe<Form>
  /**   update an existing question */
  updateFormField?: Maybe<FormField>
  /**   update an existing page on a form (does NOT include adding or removing sections) */
  updateFormPage?: Maybe<FormPage>
  /**   update an existing page on a form (does NOT include adding or removing fields) */
  updateFormPageSection: FormPageSection
  /**
   *  Submit an existing insurance claim
   * Submit an existing insurance claim
   */
  updateInsuranceClaim: InsuranceClaim
  updateNotificationTemplates?: Maybe<NotificationTemplateTypes>
  updatePatientProfile: PatientProfile
  updatePractice: Practice
  /**   add or remove an assocaition between a Practice and a Appointment Cancelation Reason */
  updatePracticeAppointmentReasonTemplates: Array<AppointmentReasonTemplate>
  /**   add or remove an assocaition between a Practice and a Field Template Group */
  updatePracticeFieldTemplateGroups: Array<FieldTemplateGroup>
  updatePracticeLocation?: Maybe<PracticeLocation>
  updatePracticeUser?: Maybe<PracticeUser>
  /**   Update an SMS converastion, limited to only its status */
  updateSmsConversation: SmsConversation
  /**   Write a text string to the logs */
  writeToLog?: Maybe<Scalars['Boolean']>
}

export type MutationCopyClinicalNoteModularTemplateArgs = {
  sourceCntId: Scalars['ID']
  input: ClinicalNoteModularTemplateCreateInput
}

export type MutationCopyFormArgs = {
  formId: Scalars['ID']
  input?: Maybe<FormCreateInput>
}

export type MutationCopyFormPageSectionArgs = {
  input?: Maybe<FormPageSectionCopyInput>
}

export type MutationCreateAppointmentArgs = {
  input?: Maybe<AppointmentCreateInput>
}

export type MutationCreateAppointmentReasonTemplateArgs = {
  input: AppointmentReasonTemplateCreateInput
}

export type MutationCreateAppointmentReminderArgs = {
  input: AppointmentReminderInputCreate
}

export type MutationCreateAppointmentTypeArgs = {
  input: AppointmentTypeCreateInput
}

export type MutationCreateBillingCodeArgs = {
  input: BillingCodeCreateInput
}

export type MutationCreateClinicalNoteArgs = {
  input?: Maybe<ClinicalNoteCreateInput>
}

export type MutationCreateClinicalNoteAmendmentArgs = {
  input: ClinicalNoteAmendmentCreateInput
}

export type MutationCreateClinicalNoteModularArgs = {
  input?: Maybe<ClinicalNoteModularCreateInput>
}

export type MutationCreateClinicalNoteModularAmendmentArgs = {
  input: ClinicalNoteModularAmendmentCreateInput
}

export type MutationCreateClinicalNoteModularTemplateArgs = {
  input: ClinicalNoteModularTemplateCreateInput
}

export type MutationCreateClinicalNoteModuleArgs = {
  input: ClinicalNoteModuleCreateInput
}

export type MutationCreateClinicalNoteModuleFieldArgs = {
  input: ClinicalNoteModuleFieldCreateInput
}

export type MutationCreateClinicalNoteTemplateArgs = {
  input: ClinicalNoteTemplateCreateInput
}

export type MutationCreateClinicalNoteTemplatePageArgs = {
  input: ClinicalNoteTemplatePageCreateInput
}

export type MutationCreateClinicalNoteTemplateSectionArgs = {
  input?: Maybe<ClinicalNoteTemplateSectionCreateInput>
}

export type MutationCreateDoctorNoteArgs = {
  patientProfileId: Scalars['ID']
  note: Scalars['String']
}

export type MutationCreateEmptyAssignedFormArgs = {
  input: AssignedFormInput
}

export type MutationCreateFieldTemplateArgs = {
  input: FieldTemplateCreateInput
}

export type MutationCreateFieldTemplateGroupArgs = {
  input: FieldTemplateGroupCreateInput
}

export type MutationCreateFormArgs = {
  input?: Maybe<FormCreateInput>
}

export type MutationCreateFormFieldArgs = {
  input: FormFieldCreateInput
}

export type MutationCreateFormPageArgs = {
  input?: Maybe<FormPageCreateInput>
}

export type MutationCreateFormPageSectionArgs = {
  input: FormPageSectionCreateInput
}

export type MutationCreateInsuranceClaimArgs = {
  input: InsuranceClaimCreateInput
}

export type MutationCreatePsAdminAccountArgs = {
  input: AccountInput
}

export type MutationCreatePatientAccountArgs = {
  input: PatientAccountInput
}

export type MutationCreatePatientProfileArgs = {
  input?: Maybe<PatientProfileCreateInput>
}

export type MutationCreatePatientProfileAttachmentArgs = {
  input: PatientProfileAttachmentCreateInput
}

export type MutationCreatePaymentArgs = {
  input: PaymentCreateInput
}

export type MutationCreatePracticeArgs = {
  input: CreatePracticeInput
}

export type MutationCreatePracticeAccountArgs = {
  input: PracticeAccountInput
}

export type MutationCreatePracticeLocationArgs = {
  input: PracticeLocationInput
}

export type MutationCreateTwilioSubaccountForPracticeArgs = {
  id: Scalars['ID']
}

export type MutationDeleteAppointmentReasonTemplateArgs = {
  id: Scalars['ID']
}

export type MutationDeleteAppointmentReminderArgs = {
  id: Scalars['ID']
}

export type MutationDeleteAppointmentTypeArgs = {
  id: Scalars['ID']
}

export type MutationDeleteBillingCodeArgs = {
  id: Scalars['ID']
}

export type MutationDeleteClinicalNoteModularTemplateArgs = {
  id: Scalars['ID']
}

export type MutationDeleteClinicalNoteModuleArgs = {
  id: Scalars['ID']
}

export type MutationDeleteClinicalNoteModuleFieldArgs = {
  id: Scalars['ID']
}

export type MutationDeleteClinicalNoteTemplateArgs = {
  id: Scalars['ID']
}

export type MutationDeleteClinicalNoteTemplatePageArgs = {
  id: Scalars['ID']
}

export type MutationDeleteClinicalNoteTemplateSectionArgs = {
  id: Scalars['ID']
}

export type MutationDeleteDoctorNoteArgs = {
  id: Scalars['ID']
}

export type MutationDeleteFamilyArgs = {
  id: Scalars['ID']
}

export type MutationDeleteFamilyByEmailArgs = {
  email: Scalars['AWSEmail']
}

export type MutationDeleteFieldTemplateArgs = {
  id: Scalars['ID']
}

export type MutationDeleteFieldTemplateGroupArgs = {
  id: Scalars['ID']
}

export type MutationDeleteFormArgs = {
  id: Scalars['ID']
}

export type MutationDeleteFormFieldArgs = {
  id: Scalars['ID']
}

export type MutationDeleteFormPageArgs = {
  id: Scalars['ID']
}

export type MutationDeleteFormPageSectionArgs = {
  id: Scalars['ID']
}

export type MutationDeletePatientProfileArgs = {
  id: Scalars['ID']
}

export type MutationDeletePatientProfileAttachmentArgs = {
  id: Scalars['ID']
}

export type MutationDeletePracticeArgs = {
  id: Scalars['ID']
}

export type MutationDeletePracticeLocationArgs = {
  id: Scalars['ID']
}

export type MutationDeletePracticeUserArgs = {
  id: Scalars['ID']
}

export type MutationDeregisterPracticeSmsNumberArgs = {
  id: Scalars['ID']
}

export type MutationDisableAccountArgs = {
  accountId: Scalars['ID']
}

export type MutationEnableAccountArgs = {
  accountId: Scalars['ID']
}

export type MutationImportClinicalNoteModularArgs = {
  input: ClinicalNoteModularImportInput
}

export type MutationMarkAssignedFormAsDownloadedArgs = {
  id: Scalars['ID']
}

export type MutationNotificationResendArgs = {
  messageId: Scalars['ID']
}

export type MutationPopulateFormPageSectionFromFieldTemplateGroupArgs = {
  input: FormPageSectionPopulateInput
}

export type MutationRegisterPracticeSmsNumberArgs = {
  id: Scalars['ID']
}

export type MutationRescheduleAppointmentArgs = {
  id: Scalars['ID']
  input?: Maybe<AppointmentRescheduleInput>
}

export type MutationResetNotificationTemplatesToDefaultArgs = {
  input: NotificationTemplateResetInput
}

export type MutationResetPasswordArgs = {
  input?: Maybe<ResetPasswordInput>
}

export type MutationResyncIndicesArgs = {
  tables: Array<TableNames>
}

export type MutationSaveAssignedFormAnswerArgs = {
  input: AssignedFormAnswerInput
}

export type MutationSaveClinicalNoteAmendmentAnswerArgs = {
  input: ClinicalNoteAmendmentAnswerInput
}

export type MutationSaveClinicalNoteAnswerArgs = {
  input: ClinicalNoteAnswerInput
}

export type MutationSaveMedicalDataPointArgs = {
  fieldTemplateId: Scalars['ID']
  input?: Maybe<MedicalDataPointUpdateInput>
}

export type MutationSelfAssignEmptyAssignedFormArgs = {
  input: AssignedFormInput
}

export type MutationSelfRegistrationPatientAccountArgs = {
  input: SelfRegistrationInput
}

export type MutationSendSmsMessageArgs = {
  input: SmsMessageInput
}

export type MutationSubmitAssignedFormArgs = {
  id: Scalars['ID']
}

export type MutationSubmitInsuranceClaimArgs = {
  id: Scalars['ID']
}

export type MutationUpdateAppointmentArgs = {
  id: Scalars['ID']
  input?: Maybe<AppointmentUpdateInput>
}

export type MutationUpdateAppointmentReasonTemplateArgs = {
  id: Scalars['ID']
  input: AppointmentReasonTemplateUpdateInput
}

export type MutationUpdateAppointmentTypeArgs = {
  id: Scalars['ID']
  input: AppointmentTypeUpdateInput
}

export type MutationUpdateBillingCodeArgs = {
  id: Scalars['ID']
  input: BillingCodeUpdateInput
}

export type MutationUpdateBrandingArgs = {
  id: Scalars['ID']
  input: BrandingUpdateInput
}

export type MutationUpdateClinicalNoteArgs = {
  id: Scalars['ID']
  input?: Maybe<ClinicalNoteUpdateInput>
}

export type MutationUpdateClinicalNoteModularArgs = {
  id: Scalars['ID']
  input?: Maybe<ClinicalNoteModularUpdateInput>
}

export type MutationUpdateClinicalNoteModularAmendmentStatusArgs = {
  id: Scalars['ID']
  input: ClinicalNoteUpdateAmendmentStatusInput
}

export type MutationUpdateClinicalNoteModularTemplateArgs = {
  id: Scalars['ID']
  input: ClinicalNoteModularTemplateUpdateInput
}

export type MutationUpdateClinicalNoteModuleArgs = {
  id: Scalars['ID']
  input: ClinicalNoteModuleUpdateInput
}

export type MutationUpdateClinicalNoteModuleFieldArgs = {
  id: Scalars['ID']
  input?: Maybe<ClinicalNoteModuleFieldUpdateInput>
}

export type MutationUpdateClinicalNoteTemplateArgs = {
  id: Scalars['ID']
  input: ClinicalNoteTemplateUpdateInput
}

export type MutationUpdateClinicalNoteTemplatePageArgs = {
  id: Scalars['ID']
  input: ClinicalNoteTemplatePageUpdateInput
}

export type MutationUpdateClinicalNoteTemplateSectionArgs = {
  id: Scalars['ID']
  input?: Maybe<ClinicalNoteTemplateSectionUpdateInput>
}

export type MutationUpdateCnModuleFieldRelationshipPropertiesArgs = {
  id: Scalars['ID']
  input: CnModuleFieldRelationshipUpdateInput
}

export type MutationUpdateDoctorNoteArgs = {
  id: Scalars['ID']
  note: Scalars['String']
}

export type MutationUpdateFamilyArgs = {
  id: Scalars['ID']
  input: FamilyInputUpdate
}

export type MutationUpdateFeatureConfigArgs = {
  id: Scalars['ID']
  input: FeatureConfigUpdateInput
}

export type MutationUpdateFieldTemplateArgs = {
  id: Scalars['ID']
  input: FieldTemplateUpdateInput
}

export type MutationUpdateFieldTemplateGroupArgs = {
  id: Scalars['ID']
  input: FieldTemplateGroupUpdateInput
}

export type MutationUpdateFormArgs = {
  id: Scalars['ID']
  input?: Maybe<FormUpdateInput>
}

export type MutationUpdateFormFieldArgs = {
  id: Scalars['ID']
  input: FormFieldUpdateInput
}

export type MutationUpdateFormPageArgs = {
  id: Scalars['ID']
  input?: Maybe<FormPageUpdateInput>
}

export type MutationUpdateFormPageSectionArgs = {
  id: Scalars['ID']
  input: FormPageSectionUpdateInput
}

export type MutationUpdateInsuranceClaimArgs = {
  id: Scalars['ID']
  input?: Maybe<InsuranceClaimUpdateInput>
}

export type MutationUpdateNotificationTemplatesArgs = {
  id: Scalars['ID']
  input: NotificationTemplateTypesInput
}

export type MutationUpdatePatientProfileArgs = {
  id: Scalars['ID']
  input?: Maybe<PatientProfileUpdateInput>
}

export type MutationUpdatePracticeArgs = {
  id: Scalars['ID']
  input: UpdatePracticeInput
}

export type MutationUpdatePracticeAppointmentReasonTemplatesArgs = {
  practiceId: Scalars['ID']
  appointmentReasonTemplateId: Array<Scalars['ID']>
}

export type MutationUpdatePracticeFieldTemplateGroupsArgs = {
  practiceId: Scalars['ID']
  fieldTemplateGroupIds: Array<Scalars['ID']>
}

export type MutationUpdatePracticeLocationArgs = {
  id: Scalars['ID']
  input: PracticeLocationUpdateInput
}

export type MutationUpdatePracticeUserArgs = {
  id: Scalars['ID']
  input?: Maybe<PracticeUserUpdateInput>
}

export type MutationUpdateSmsConversationArgs = {
  id: Scalars['ID']
  input?: Maybe<SmsConversationUpdateInput>
}

export type MutationWriteToLogArgs = {
  text: Scalars['String']
  type?: Maybe<LogType>
}

export type Names = {
  __typename?: 'Names'
  firstName: Scalars['String']
  lastName: Scalars['String']
  middleName?: Maybe<Scalars['String']>
  suffix?: Maybe<Scalars['String']>
}

export type NamesInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  middleName?: Maybe<Scalars['String']>
  suffix?: Maybe<Scalars['String']>
}

export type NotificationTemplate = {
  __typename?: 'NotificationTemplate'
  emailSubject?: Maybe<Scalars['String']>
  sms?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type NotificationTemplateInput = {
  emailSubject?: Maybe<Scalars['String']>
  sms?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type NotificationTemplateResetInput = {
  practiceId: Scalars['ID']
  type: NotificationTemplateTypeNames
}

export enum NotificationTemplateTypeNames {
  AccountCreatedPatient = 'accountCreatedPatient',
  AccountCreatedUser = 'accountCreatedUser',
  AppointmentConfirmation = 'appointmentConfirmation',
  AppointmentReminder = 'appointmentReminder',
  AppointmentCancellation = 'appointmentCancellation',
  AppointmentRescheduled = 'appointmentRescheduled',
  FormAssignment = 'formAssignment',
  FormComplete = 'formComplete',
}

export type NotificationTemplateTypes = {
  __typename?: 'NotificationTemplateTypes'
  id: Scalars['ID']
  accountCreatedPatient?: Maybe<NotificationTemplate>
  accountCreatedUser?: Maybe<NotificationTemplate>
  appointmentConfirmation?: Maybe<NotificationTemplate>
  appointmentReminder?: Maybe<NotificationTemplate>
  appointmentCancellation?: Maybe<NotificationTemplate>
  appointmentRescheduled?: Maybe<NotificationTemplate>
  formAssignment?: Maybe<NotificationTemplate>
  formComplete?: Maybe<NotificationTemplate>
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type NotificationTemplateTypesInput = {
  accountCreatedPatient?: Maybe<NotificationTemplateInput>
  accountCreatedUser?: Maybe<NotificationTemplateInput>
  appointmentConfirmation?: Maybe<NotificationTemplateInput>
  appointmentReminder?: Maybe<NotificationTemplateInput>
  appointmentCancellation?: Maybe<NotificationTemplateInput>
  appointmentRescheduled?: Maybe<NotificationTemplateInput>
  formAssignment?: Maybe<NotificationTemplateInput>
  formComplete?: Maybe<NotificationTemplateInput>
}

export type OtpInput = {
  username: Scalars['String']
}

export type OtpResponse = {
  __typename?: 'OtpResponse'
  /**   the phone number of email address where the one-time password was sent */
  destination: Scalars['String']
  /**   how the one-time password was sent (either "EMAIL" or "PHONE") */
  medium: Scalars['String']
}

export type PaginatedList = {
  __typename?: 'PaginatedList'
  /**   list of results */
  entities: Array<Entity>
  /**   infomration about the paginated set */
  pageInfo: PaginationInfo
}

export type PaginationInfo = {
  __typename?: 'PaginationInfo'
  /**   the number/count of the current page */
  currentPageNumber: Scalars['Int']
  /**   boolean indicating if there are more pages available */
  hasMoreResults: Scalars['Boolean']
  /**   the maximum number of pages available */
  lastPageNumber: Scalars['Int']
  /**   size of the current page */
  pageSize: Scalars['Int']
  /**   total items available */
  totalEntities: Scalars['Int']
}

export type PaginationInput = {
  /**   which page should be returned (defaults to 1) */
  pageNumber?: Maybe<Scalars['Int']>
  /**   number of items to return in each paginated result (defaults to 20, max is 100) */
  pageSize?: Maybe<Scalars['Int']>
}

export type PatientAccount = {
  __typename?: 'PatientAccount'
  accountId: Scalars['ID']
  /**   timestamp when this form was first created (expressed in seconds since the epoch) */
  createdOn: Scalars['AWSTimestamp']
  email?: Maybe<Scalars['AWSEmail']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  /**   timestamp when this form was last updated (expressed in seconds since the epoch) */
  updatedOn: Scalars['AWSTimestamp']
}

export type PatientAccountInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber?: Maybe<Scalars['AWSPhone']>
  email?: Maybe<Scalars['AWSEmail']>
  dateOfBirth?: Maybe<Scalars['AWSDate']>
  /**
   *  Unique ID of the form that the user should be auto-assigned upon creating an account
   * only pass in `formId` or `appointment`, but never both
   */
  formId?: Maybe<Scalars['String']>
  /**
   *  Detials about appointment. When speified, a new appointment will be created for the new user account
   * only pass in `formId` or `appointment`, but never both
   */
  appointment?: Maybe<AppointmentCreateInlineInput>
}

export type PatientProfile = Entity & {
  __typename?: 'PatientProfile'
  id: Scalars['ID']
  family: Family
  firstName: Scalars['String']
  middleName?: Maybe<Scalars['String']>
  lastName: Scalars['String']
  suffix?: Maybe<Scalars['String']>
  /** @deprecated Use `Family.email` instead */
  dateOfBirth?: Maybe<Scalars['AWSDate']>
  /** @deprecated Use `Family.phoneNumber` instead */
  email?: Maybe<Scalars['AWSEmail']>
  phoneNumber?: Maybe<Scalars['AWSPhone']>
  patientType?: Maybe<PatientType>
  profilePicture: ProfilePicture
  attachments?: Maybe<Array<Maybe<PatientProfileAttachment>>>
  assignedForms?: Maybe<Array<Maybe<AssignedForm>>>
  clinicalNotes?: Maybe<Array<Maybe<ClinicalNote>>>
  clinicalNotesModular?: Maybe<Array<Maybe<ClinicalNoteModular>>>
  insuranceBalance?: Maybe<InsurancePatientBalance>
  medicalInformation?: Maybe<Array<Maybe<MedicalDataPoint>>>
  /**
   *   list of appointments, if no `startDate` is specified will default to today and only show future appointments.
   *  if `showAll` is set to true, any provided date range will be ignored and both
   * past and future appointments will be included
   */
  appointments: PaginatedList
  payments?: Maybe<Array<Maybe<Payment>>>
  communicationHistory: Array<CommunicationJourmalEntry>
  doctorNotes?: Maybe<Array<Maybe<DoctorNote>>>
  smsConversation?: Maybe<SmsConversation>
  referringDoctor?: Maybe<ReferringDoctor>
  pastElegibilitychecks?: Maybe<Array<Maybe<InsuranceEligibility>>>
  createdOn: Scalars['AWSTimestamp']
  updatedOn: Scalars['AWSTimestamp']
}

export type PatientProfileAppointmentsArgs = {
  pagination?: Maybe<PaginationInput>
  startDate?: Maybe<Scalars['AWSDate']>
  endDate?: Maybe<Scalars['AWSDate']>
  showAll?: Maybe<Scalars['Boolean']>
}

export type PatientProfileAttachment = {
  __typename?: 'PatientProfileAttachment'
  id: Scalars['ID']
  /**   file name */
  fileName: Scalars['String']
  /**   file category */
  category?: Maybe<Scalars['String']>
  /**   date the file was uploaded */
  createdOn: Scalars['AWSTimestamp']
  /**   date the file was last updated */
  updatedOn: Scalars['AWSTimestamp']
  /**   ID of the user account that uploaded this file */
  createdBy: UserAccount
  /**   URL to download the file */
  downloadUrl: Scalars['AWSURL']
  /**   Signed URL that will accept a binary file upload via a PUT request until expiration (5 minutes) */
  uploadUrl: Scalars['AWSURL']
  /**   short description about the uplaoded file */
  description?: Maybe<Scalars['String']>
}

export type PatientProfileAttachmentCreateInput = {
  /**   ID of the patient profile that this attachment should be assocaited to */
  patientProfileId: Scalars['ID']
  /**   name to give uploaded file (including the file extension) */
  fileName: Scalars['String']
  /**   short description about the uplaoded file */
  description?: Maybe<Scalars['String']>
  /**   ID of the practice the attachment should be linked to */
  practiceId?: Maybe<Scalars['ID']>
  category?: Maybe<Scalars['String']>
}

export type PatientProfileCreateInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  email?: Maybe<Scalars['AWSEmail']>
  phoneNumber?: Maybe<Scalars['AWSPhone']>
  patientType?: Maybe<PatientType>
  familyId: Scalars['ID']
  dateOfBirth?: Maybe<Scalars['AWSDate']>
  referringDoctorNpi?: Maybe<Scalars['String']>
}

export type PatientProfileUpdateInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  patientType?: Maybe<PatientType>
  dateOfBirth?: Maybe<Scalars['AWSDate']>
  referringDoctorNpi?: Maybe<Scalars['String']>
}

export enum PatientType {
  /**  adult patient, independent decision-maker */
  AdultIndependent = 'ADULT_INDEPENDENT',
  /**  adult patient, but has a primary caretaker who a makes the decisions or has to be consulted with */
  AdultDependent = 'ADULT_DEPENDENT',
  /**  patient under the legal age, not legally allowed to make their own decisions */
  Minor = 'MINOR',
}

export type Payment = {
  __typename?: 'Payment'
  /**   unique identifier */
  id: Scalars['ID']
  /**   patient that is making the payment */
  patient: PatientProfile
  /**   Appointment related to the payment */
  appointment?: Maybe<Appointment>
  /**   Payment type (Copay or Payment) */
  type: PaymentType
  /**   Payment method (Credit, Debit) */
  method: PaymentMethod
  /**   Payment value including cents, e.g., $ 10.59 = 1059 */
  value: Scalars['Int']
  /**   A comment about the payment */
  comment?: Maybe<Scalars['String']>
  /**   timestamp when this clinical note was first created */
  createdOn: Scalars['AWSTimestamp']
}

export type PaymentCreateInput = {
  /**   the id of the patient making the payment */
  patientProfileId: Scalars['ID']
  /**   the id of the appointment this relates to  */
  appointmentId?: Maybe<Scalars['ID']>
  /**   Payment type (Copay or Payment) */
  type: PaymentType
  /**   Payment method (Credit, Debit) */
  method: PaymentMethod
  /**   Payment value including cents, e.g., $ 10.59 = 1059 */
  value: Scalars['Int']
  /**   A comment about the payment */
  comment?: Maybe<Scalars['String']>
}

export enum PaymentMethod {
  Cash = 'Cash',
  Credit = 'Credit',
  Check = 'Check',
  Other = 'Other',
}

export enum PaymentType {
  Copay = 'Copay',
  Payment = 'Payment',
}

/**   A doctors business. Can have a number of locations. */
export type Practice = Entity & {
  __typename?: 'Practice'
  id: Scalars['ID']
  statistics: PracticeStatistics
  /**   a practices NPI */
  nationalProviderIdentifier?: Maybe<Scalars['String']>
  /**   unique ID to identify a practice in the Aithoz system */
  aithozClientId?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  email?: Maybe<Scalars['AWSEmail']>
  phone?: Maybe<Scalars['AWSPhone']>
  fax?: Maybe<Scalars['AWSPhone']>
  billingAddress?: Maybe<Address>
  branding?: Maybe<Branding>
  forms?: Maybe<Array<Form>>
  appointmentTypes?: Maybe<Array<Maybe<AppointmentType>>>
  /**   the type of practice - dental, mendical, chiropractic, etc */
  specialty?: Maybe<DoctorSpecialty>
  /**   Billing ocdes avilable for selection by this practice */
  billingCodes: Array<BillingCode>
  hasBalancePastDue: Scalars['Boolean']
  /**   set of flags indicating which features should be enabled or disabled */
  featureConfig: FeatureConfig
  users?: Maybe<Array<Maybe<PracticeUser>>>
  locations: Array<PracticeLocation>
  stripeCustomerId?: Maybe<Scalars['String']>
  twilioAccountId?: Maybe<Scalars['String']>
  smsPhoneNumber?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
  practiceManagementSoftware?: Maybe<Scalars['String']>
  /**   list of assigned forms assocaited with this practice, across all locations */
  assignedForm: PaginatedList
  /**
   *   list of appointments, if no `startDate` is specified will default to today;
   * if no status is provided will efault to `[SCHEDULED]`
   */
  appointments: PaginatedList
  /**   list of individual patient profiles (not families) assocaited with this practice */
  patients: PaginatedList
  notificationTemplates?: Maybe<NotificationTemplateTypes>
  smsConversations: PaginatedList
  fieldTemplateGroups: Array<FieldTemplateGroup>
  clinicalNoteTemplates: Array<ClinicalNoteTemplate>
  clinicalNoteModularTemplates: Array<ClinicalNoteModularTemplate>
  appointmentReasonTemplates: Array<AppointmentReasonTemplate>
  referringDoctors?: Maybe<Array<ReferringDoctor>>
  createdOn: Scalars['AWSTimestamp']
  updatedOn: Scalars['AWSTimestamp']
}

/**   A doctors business. Can have a number of locations. */
export type PracticeFormsArgs = {
  isActive?: Maybe<FormIsActiveValue>
}

/**   A doctors business. Can have a number of locations. */
export type PracticeUsersArgs = {
  staffType?: Maybe<StaffType>
}

/**   A doctors business. Can have a number of locations. */
export type PracticeAssignedFormArgs = {
  pagination?: Maybe<PaginationInput>
  isSubmitted?: Maybe<Scalars['Boolean']>
  wasDownloaded?: Maybe<Scalars['Boolean']>
}

/**   A doctors business. Can have a number of locations. */
export type PracticeAppointmentsArgs = {
  pagination?: Maybe<PaginationInput>
  startDate?: Maybe<Scalars['AWSDate']>
  endDate?: Maybe<Scalars['AWSDate']>
  statuses?: Maybe<Array<Maybe<AppointmentStatus>>>
}

/**   A doctors business. Can have a number of locations. */
export type PracticePatientsArgs = {
  pagination?: Maybe<PaginationInput>
}

/**   A doctors business. Can have a number of locations. */
export type PracticeSmsConversationsArgs = {
  pagination?: Maybe<PaginationInput>
  status?: Maybe<SmsConversationStatus>
}

export type PracticeAccount = Account & {
  __typename?: 'PracticeAccount'
  id: Scalars['ID']
  accountId: Scalars['ID']
  status: Scalars['String']
  isActive: Scalars['Boolean']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email: Scalars['AWSEmail']
  staffType?: Maybe<StaffType>
  isAdmin: Scalars['Boolean']
  phoneNumber?: Maybe<Scalars['AWSPhone']>
  profile?: Maybe<PracticeUser>
  /**   timestamp when this form was first created (expressed in seconds since the epoch) */
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  /**   timestamp when this form was last updated (expressed in seconds since the epoch) */
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type PracticeAccountInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber?: Maybe<Scalars['AWSPhone']>
  jobFunction?: Maybe<Scalars['String']>
  staffType?: Maybe<StaffType>
  email: Scalars['AWSEmail']
  practiceId: Scalars['ID']
  password?: Maybe<Scalars['String']>
  isAdmin?: Maybe<Scalars['Boolean']>
  /**   unique ID to idenitfy a practice in the Aithoz billing service */
  aithozProviderNPI?: Maybe<Scalars['String']>
}

export type PracticeLocation = {
  __typename?: 'PracticeLocation'
  id: Scalars['ID']
  isActive?: Maybe<Scalars['Boolean']>
  practice?: Maybe<Practice>
  address?: Maybe<Address>
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['AWSEmail']>
  phone: Scalars['AWSPhone']
  fax?: Maybe<Scalars['AWSPhone']>
  timezone?: Maybe<Scalars['String']>
  /**   times which this practice location is open */
  operatingHours?: Maybe<ScheduleHours>
  /**   times during which appoitments can be scheduled */
  schedulableHours?: Maybe<ScheduleHours>
  /**   list of one-off dates that are to be blocked off in the calendar */
  blackoutDates?: Maybe<Array<Maybe<Scalars['AWSDate']>>>
  /**
   *  # list of rooms avilable at this location. optionally can filter by an appoint type ID
   * # practiceLocationRooms(appointmentTypeId: ID): [PracticeLocationRoom]
   *  list of appointments, if no `startDate` is specified will default to today
   */
  appointments: PaginatedList
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type PracticeLocationAppointmentsArgs = {
  pagination?: Maybe<PaginationInput>
  startDate?: Maybe<Scalars['AWSDate']>
  endDate?: Maybe<Scalars['AWSDate']>
}

export type PracticeLocationInput = {
  isActive?: Maybe<Scalars['Boolean']>
  practiceId: Scalars['ID']
  /**   times which this practice location is open */
  operatingHours: ScheduleHoursInput
  /**   times during which appointsment can be scheduled */
  schedulableHours: ScheduleHoursInput
  address: AddressInput
  timezone?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['AWSEmail']>
  phone: Scalars['AWSPhone']
  fax?: Maybe<Scalars['AWSPhone']>
}

export type PracticeLocationUpdateInput = {
  isActive?: Maybe<Scalars['Boolean']>
  /**   times which this practice location is open */
  operatingHours?: Maybe<ScheduleHoursInput>
  /**   times during which appointsment can be scheduled */
  schedulableHours?: Maybe<ScheduleHoursInput>
  address: AddressInput
  timezone?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['AWSEmail']>
  phone?: Maybe<Scalars['AWSPhone']>
  fax?: Maybe<Scalars['AWSPhone']>
}

export type PracticeStatistics = {
  __typename?: 'PracticeStatistics'
  /**   count of assigned forms by isSubmitted status for appointments in the given date range */
  appointemntAssignedFormSubmissionsStatus: Array<Statistic>
  /**   count of appointments by status for appointments in the given date range */
  appointemntStatus: Array<Statistic>
  /**   count of ALL assigned forms by isSubmitted status */
  assignedFormSubmissionsStatus: Array<Statistic>
  /**   count of ALL assigned forms by isDownloaded status */
  assignedFormDownloadStatus: Array<Statistic>
  /**   count of ALL clinical notes by their status */
  clinicalNoteStatus: Array<Statistic>
  /**   count of ALL claims notes by their status */
  claimStatus: Array<Statistic>
}

export type PracticeStatisticsAppointemntAssignedFormSubmissionsStatusArgs = {
  startDate?: Maybe<Scalars['AWSDate']>
  endDate?: Maybe<Scalars['AWSDate']>
}

export type PracticeStatisticsAppointemntStatusArgs = {
  startDate?: Maybe<Scalars['AWSDate']>
  endDate?: Maybe<Scalars['AWSDate']>
}

/**   A user who is an employee for a doctors offices */
export type PracticeUser = {
  __typename?: 'PracticeUser'
  id: Scalars['ID']
  firstName: Scalars['String']
  lastName?: Maybe<Scalars['String']>
  email: Scalars['AWSEmail']
  phoneNumber?: Maybe<Scalars['AWSPhone']>
  account: PracticeAccount
  practice: Practice
  jobFunction?: Maybe<Scalars['String']>
  staffType?: Maybe<StaffType>
  profilePicture: ProfilePicture
  isAdmin?: Maybe<Scalars['Boolean']>
  /**   unique ID to idenitfy a practice in the Aithoz billing service */
  aithozProviderNPI?: Maybe<Scalars['String']>
  /**   times during which appoitments can be scheduled with this user */
  schedule?: Maybe<Array<Maybe<UserSchedule>>>
  /**
   *   list of appointments, if no `startDate` is specified will default to today;
   * if no status is provided will efault to `[SCHEDULED]`
   */
  appointments: PaginatedList
  /**   List of appointment type names that this practice user can participate in */
  appointmentTypes?: Maybe<Array<Maybe<AppointmentType>>>
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

/**   A user who is an employee for a doctors offices */
export type PracticeUserAppointmentsArgs = {
  pagination?: Maybe<PaginationInput>
  startDate?: Maybe<Scalars['AWSDate']>
  endDate?: Maybe<Scalars['AWSDate']>
  statuses?: Maybe<Array<Maybe<AppointmentStatus>>>
}

export enum PracticeUserGroup {
  OfficeStaff = 'OfficeStaff',
  OfficeAdmin = 'OfficeAdmin',
}

/**   # Properties for updating a PracticeUser */
export type PracticeUserUpdateInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  jobFunction?: Maybe<Scalars['String']>
  staffType?: Maybe<StaffType>
  email?: Maybe<Scalars['AWSEmail']>
  phoneNumber?: Maybe<Scalars['AWSPhone']>
  schedule?: Maybe<Array<Maybe<UserScheduleInput>>>
  isAdmin: Scalars['Boolean']
  /**   List of appointment type names that this practice user can participate in */
  appointmentTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>
  /**   unique ID to idenitfy a practice in the Aithoz billing service */
  aithozProviderNPI?: Maybe<Scalars['String']>
}

export type ProfilePicture = {
  __typename?: 'ProfilePicture'
  downloadUrl: Scalars['AWSURL']
  uploadUrl: Scalars['AWSURL']
}

export type PsAdminAccount = Account & {
  __typename?: 'PSAdminAccount'
  id: Scalars['ID']
  accountId: Scalars['ID']
  firstName?: Maybe<Scalars['String']>
  status: Scalars['String']
  isActive: Scalars['Boolean']
  lastName?: Maybe<Scalars['String']>
  email: Scalars['AWSEmail']
  phoneNumber?: Maybe<Scalars['AWSPhone']>
  /**   timestamp when this form was first created (expressed in seconds since the epoch) */
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  /**   timestamp when this form was last updated (expressed in seconds since the epoch) */
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type PublicBranding = {
  __typename?: 'PublicBranding'
  id: Scalars['ID']
  /**   URL from which to retrive the logo */
  logo: PublicBrandingLogo
  /**   Custom URL segment for this particular brand */
  subdomain: Scalars['String']
  /**   Practice tagline */
  tagLine?: Maybe<Scalars['String']>
  colorPrimary: Scalars['String']
  colorSecondary?: Maybe<Scalars['String']>
  colorTertiary?: Maybe<Scalars['String']>
  practice: PublicPractice
}

export type PublicBrandingLogo = {
  __typename?: 'PublicBrandingLogo'
  /**   Public URL that can be used to download or display the file */
  downloadUrl: Scalars['AWSURL']
}

export type PublicForm = {
  __typename?: 'PublicForm'
  /**   unique form identifyer */
  id: Scalars['ID']
  /**   user-friendly name to help identify a particular form */
  name: Scalars['String']
  /**   text to help identify a form. usually intended for internal use but couled be for anything. */
  description?: Maybe<Scalars['String']>
  /**   list of pages that make up this form */
  pages: Array<PublicFormPage>
  /**   flag indicating if this form is active, and able to be assigned to patients */
  isActive: Scalars['Boolean']
  /**   timestamp when this form was first created (expressed in seconds since the epoch) */
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  /**   timestamp when this form was last updated (expressed in seconds since the epoch) */
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type PublicFormField = {
  __typename?: 'PublicFormField'
  id: Scalars['ID']
  /**
   *   Name of the type of element that should be used to represent this question
   * (ie: input, masked input, dropdown, radio, etc.)
   */
  elementType: ElementTypes
  /**   The order the question should appear on the page */
  ordinal?: Maybe<Scalars['Int']>
  /**   The text for the question */
  label: Scalars['String']
  /**   Flag indicating if this field is requeired or not */
  isRequired?: Maybe<Scalars['Boolean']>
  /**   Flag indicating of this field should be hidden from patients */
  isInternalUseOnly?: Maybe<Scalars['Boolean']>
  /**   Minimum number of characters or selections needed */
  minimumLength?: Maybe<Scalars['Int']>
  /**   Maximum number of characters or selections posible */
  maximumLength?: Maybe<Scalars['Int']>
  /**   List of posible answers the user can pick from */
  answerOptions?: Maybe<Array<Scalars['String']>>
  /**   The default answer to this question if the user does not provide any */
  defaultAnswer?: Maybe<Scalars['String']>
  /**   the condition that much be met for this question to be visible */
  visibilityCondition?: Maybe<FieldDependency>
  /**   timestamp when this page was first created (expressed in seconds since the epoch) */
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  /**   timestamp when this page was last updated (expressed in seconds since the epoch) */
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type PublicFormPage = {
  __typename?: 'PublicFormPage'
  /**   unique identifyer for a page */
  id: Scalars['ID']
  /**   user-friendly, short text to differenciate one page from another */
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
  header?: Maybe<Scalars['String']>
  footer?: Maybe<Scalars['String']>
  /**   The order the page should appear on the form */
  ordinal?: Maybe<Scalars['Int']>
  /**   list of sections */
  sections: Array<PublicFormPageSection>
  /**   timestamp when this page was first created (expressed in seconds since the epoch) */
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  /**   timestamp when this page was last updated (expressed in seconds since the epoch) */
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type PublicFormPageSection = {
  __typename?: 'PublicFormPageSection'
  /**   unique identifyer for a page */
  id: Scalars['ID']
  /**   user-friendly, short text to differenciate one page from another */
  title: Scalars['String']
  /**   The order the page should appear on the form */
  ordinal?: Maybe<Scalars['Int']>
  /**   Flag indicating of this field should be hidden from patients */
  isInternalUseOnly?: Maybe<Scalars['Boolean']>
  /**   list of fields */
  fields?: Maybe<Array<Maybe<PublicFormField>>>
  /**   timestamp when this page was first created (expressed in seconds since the epoch) */
  createdOn?: Maybe<Scalars['AWSTimestamp']>
  /**   timestamp when this page was last updated (expressed in seconds since the epoch) */
  updatedOn?: Maybe<Scalars['AWSTimestamp']>
}

export type PublicPractice = {
  __typename?: 'PublicPractice'
  id: Scalars['ID']
  name: Scalars['String']
  email?: Maybe<Scalars['AWSEmail']>
  phone?: Maybe<Scalars['AWSPhone']>
  fax?: Maybe<Scalars['AWSPhone']>
  billingAddress?: Maybe<Address>
}

export type Query = {
  __typename?: 'Query'
  checkInsuranceEligibility: InsuranceEligibility
  getAppointment?: Maybe<Appointment>
  /**   get a details for a single form that a patient has started filling out */
  getAssignedForm?: Maybe<AssignedForm>
  /**   Get a specific Branding */
  getBranding?: Maybe<Branding>
  /**   get the details of a clinical note being filled by a doctor */
  getClinicalNote?: Maybe<ClinicalNote>
  /**   get the details of a modular clinical note being filled by a doctor */
  getClinicalNoteModular?: Maybe<ClinicalNoteModular>
  /**   get a Clinical Note Modular Template */
  getClinicalNoteModularTemplate?: Maybe<ClinicalNoteModularTemplate>
  getClinicalNoteModule: ClinicalNoteModule
  getClinicalNoteModuleField: ClinicalNoteModuleField
  /**   get a clinical note template */
  getClinicalNoteTemplate?: Maybe<ClinicalNoteTemplate>
  /**   Get a Family based on their ID */
  getFamily?: Maybe<Family>
  getFieldTemplate: FieldTemplate
  getFieldTemplateGroup: FieldTemplateGroup
  /**   get a single form */
  getForm?: Maybe<Form>
  /**   get a specific form question */
  getFormField?: Maybe<FormField>
  /**   get a specific form page */
  getFormPage?: Maybe<FormPage>
  /**   get a specific page section by its ID */
  getFormPageSection?: Maybe<FormPageSection>
  getInsuranceClaim: InsuranceClaim
  /**   Get the corresponding Branding for the current site */
  getMyBranding?: Maybe<PublicBranding>
  getMySignature?: Maybe<ClientSignature>
  /**   Get a new AccessToken by passing in a valid RefreshToken */
  getNewToken: Tokens
  getNotificationTemplates?: Maybe<NotificationTemplateTypes>
  /**   Send the user an email with a confirmation code that will allow them to reset their password */
  getOtp?: Maybe<OtpResponse>
  /**   Get a Patient Profile based on their ID */
  getPatientProfile?: Maybe<PatientProfile>
  getPractice?: Maybe<Practice>
  getPracticeByOrigin?: Maybe<Practice>
  getPracticeLocation?: Maybe<PracticeLocation>
  getPracticeUser?: Maybe<PracticeUser>
  /**   Get a specific SMS conversation */
  getSmsConversation: SmsConversation
  listAppointmentReasonTemplates: Array<AppointmentReasonTemplate>
  listClinicalNoteModuleField: PaginatedList
  listClinicalNoteModules: PaginatedList
  /**   Paginated list of Field Templates groups */
  listFieldTemplateGroups: PaginatedList
  /**   Paginated list of all Field Templates */
  listFieldTemplates?: Maybe<PaginatedList>
  listPractices: PaginatedList
  /**   Log in with the given user creds */
  login: Tokens
  previewForm?: Maybe<PublicForm>
  /**   Search Families against "name" and "email" */
  searchFamily?: Maybe<PaginatedList>
  /**   Search for a Patient Profile against "firstName" and "lastName" */
  searchPatientProfiles?: Maybe<PaginatedList>
  searchPractices?: Maybe<Array<Maybe<Practice>>>
  /**   Get the profile information for the current user */
  whoAmI?: Maybe<Currentuser>
}

export type QueryCheckInsuranceEligibilityArgs = {
  input: InsuranceEligibilityInput
}

export type QueryGetAppointmentArgs = {
  id: Scalars['ID']
}

export type QueryGetAssignedFormArgs = {
  id: Scalars['ID']
}

export type QueryGetBrandingArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryGetClinicalNoteArgs = {
  id: Scalars['ID']
}

export type QueryGetClinicalNoteModularArgs = {
  id: Scalars['ID']
}

export type QueryGetClinicalNoteModularTemplateArgs = {
  id: Scalars['ID']
}

export type QueryGetClinicalNoteModuleArgs = {
  id: Scalars['ID']
}

export type QueryGetClinicalNoteModuleFieldArgs = {
  id: Scalars['ID']
}

export type QueryGetClinicalNoteTemplateArgs = {
  id: Scalars['ID']
}

export type QueryGetFamilyArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryGetFieldTemplateArgs = {
  id: Scalars['ID']
}

export type QueryGetFieldTemplateGroupArgs = {
  id: Scalars['ID']
}

export type QueryGetFormArgs = {
  id: Scalars['ID']
}

export type QueryGetFormFieldArgs = {
  id: Scalars['ID']
}

export type QueryGetFormPageArgs = {
  id: Scalars['ID']
}

export type QueryGetFormPageSectionArgs = {
  id: Scalars['ID']
}

export type QueryGetInsuranceClaimArgs = {
  id: Scalars['ID']
}

export type QueryGetNewTokenArgs = {
  input: Scalars['String']
}

export type QueryGetNotificationTemplatesArgs = {
  id?: Maybe<Scalars['ID']>
}

export type QueryGetOtpArgs = {
  input?: Maybe<OtpInput>
}

export type QueryGetPatientProfileArgs = {
  id: Scalars['ID']
}

export type QueryGetPracticeArgs = {
  id: Scalars['ID']
}

export type QueryGetPracticeLocationArgs = {
  id: Scalars['ID']
}

export type QueryGetPracticeUserArgs = {
  id: Scalars['ID']
}

export type QueryGetSmsConversationArgs = {
  id: Scalars['ID']
}

export type QueryListClinicalNoteModuleFieldArgs = {
  pagination?: Maybe<PaginationInput>
  filters?: Maybe<ClinicalNoteModuleFieldFilterInput>
}

export type QueryListClinicalNoteModulesArgs = {
  pagination?: Maybe<PaginationInput>
  filters?: Maybe<ClinicalNoteModuleFilterInput>
}

export type QueryListFieldTemplateGroupsArgs = {
  pagination?: Maybe<PaginationInput>
  filters?: Maybe<FieldTemplateGroupFilterInput>
}

export type QueryListFieldTemplatesArgs = {
  pagination?: Maybe<PaginationInput>
  filters?: Maybe<FieldTemplateFilterInput>
}

export type QueryListPracticesArgs = {
  pagination?: Maybe<PaginationInput>
}

export type QueryLoginArgs = {
  input: LoginInput
}

export type QueryPreviewFormArgs = {
  id: Scalars['ID']
}

export type QuerySearchFamilyArgs = {
  pagination?: Maybe<PaginationInput>
  input: Scalars['String']
}

export type QuerySearchPatientProfilesArgs = {
  pagination?: Maybe<PaginationInput>
  input: Scalars['String']
}

export type QuerySearchPracticesArgs = {
  input: Scalars['String']
}

export type ReferringDoctor = {
  __typename?: 'ReferringDoctor'
  name: Scalars['String']
  NPI: Scalars['String']
  isActive: Scalars['Boolean']
}

export type ReferringDoctorInput = {
  name: Scalars['String']
  NPI: Scalars['String']
  isActive: Scalars['Boolean']
}

export enum ReminderStatus {
  Scheduled = 'SCHEDULED',
  Sent = 'SENT',
  Error = 'ERROR',
}

export type ResetPasswordInput = {
  /**   this can be the users email address or phone numbers */
  username: Scalars['String']
  /**   the one-time password that was sent to the user */
  otp: Scalars['String']
  /**   new permanent password */
  password: Scalars['String']
}

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse'
  success: Scalars['Boolean']
  details: Scalars['String']
}

export type ScheduleHours = {
  __typename?: 'ScheduleHours'
  monday?: Maybe<Array<Maybe<ScheduleHoursElement>>>
  tuesday?: Maybe<Array<Maybe<ScheduleHoursElement>>>
  wednesday?: Maybe<Array<Maybe<ScheduleHoursElement>>>
  thursday?: Maybe<Array<Maybe<ScheduleHoursElement>>>
  friday?: Maybe<Array<Maybe<ScheduleHoursElement>>>
  saturday?: Maybe<Array<Maybe<ScheduleHoursElement>>>
  sunday?: Maybe<Array<Maybe<ScheduleHoursElement>>>
}

export type ScheduleHoursElement = {
  __typename?: 'ScheduleHoursElement'
  start?: Maybe<Scalars['AWSTime']>
  end?: Maybe<Scalars['AWSTime']>
}

export type ScheduleHoursElementInput = {
  start: Scalars['AWSTime']
  end: Scalars['AWSTime']
}

/**   input for creating or updated ScheduleHours (value can be an empty array) */
export type ScheduleHoursInput = {
  monday: Array<Maybe<ScheduleHoursElementInput>>
  tuesday: Array<Maybe<ScheduleHoursElementInput>>
  wednesday: Array<Maybe<ScheduleHoursElementInput>>
  thursday: Array<Maybe<ScheduleHoursElementInput>>
  friday: Array<Maybe<ScheduleHoursElementInput>>
  saturday: Array<Maybe<ScheduleHoursElementInput>>
  sunday: Array<Maybe<ScheduleHoursElementInput>>
}

export type SelfRegistrationInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber?: Maybe<Scalars['AWSPhone']>
  email: Scalars['AWSEmail']
  password: Scalars['String']
  /**   Unique ID of the form that the user should be auto-assigned upon creating an account */
  formCode?: Maybe<Scalars['String']>
}

export enum ServiceLocationType {
  Telehealth = 'TELEHEALTH',
  Office = 'OFFICE',
  Home = 'HOME',
  AssistedLivingFacility = 'ASSISTED_LIVING_FACILITY',
  GroupHome = 'GROUP_HOME',
  MobileUnit = 'MOBILE_UNIT',
  TemporaryLodging = 'TEMPORARY_LODGING',
  WalkInRetailHealthClinic = 'WALK_IN_RETAIL_HEALTH_CLINIC',
  UrgentCareFacility = 'URGENT_CARE_FACILITY',
  InpatientHospital = 'INPATIENT_HOSPITAL',
  OutpatientHospital = 'OUTPATIENT_HOSPITAL',
  EmergencyRoomHospital = 'EMERGENCY_ROOM_HOSPITAL',
  AmbulatorySurgicalCenter = 'AMBULATORY_SURGICAL_CENTER',
  BirthingCenter = 'BIRTHING_CENTER',
  MilitaryTreatmentFacility = 'MILITARY_TREATMENT_FACILITY',
  SkilledNursingFacility = 'SKILLED_NURSING_FACILITY',
  NursingFacility = 'NURSING_FACILITY',
  CustodialCareFacility = 'CUSTODIAL_CARE_FACILITY',
  Hospice = 'HOSPICE',
  AmbulanceLand = 'AMBULANCE_LAND',
  AmbulanceAirOrWater = 'AMBULANCE_AIR_OR_WATER',
  FederallyQualifiedHealthCenter = 'FEDERALLY_QUALIFIED_HEALTH_CENTER',
  InpatientPsychiatricFacility = 'INPATIENT_PSYCHIATRIC_FACILITY',
  PsychiatricFacilityPartialHospitalization = 'PSYCHIATRIC_FACILITY_PARTIAL_HOSPITALIZATION',
  CommunityMentalHealthCenter = 'COMMUNITY_MENTAL_HEALTH_CENTER',
  IntermediateCareFacilityMentallyRetarded = 'INTERMEDIATE_CARE_FACILITY_MENTALLY_RETARDED',
  ResidentialSubstanceAbuseTreatmentFacility = 'RESIDENTIAL_SUBSTANCE_ABUSE_TREATMENT_FACILITY',
  PsychiatricResidentialTreatmentCenter = 'PSYCHIATRIC_RESIDENTIAL_TREATMENT_CENTER',
  MassImmunizationCenter = 'MASS_IMMUNIZATION_CENTER',
  ComprehensiveInpatientRehabilitationFacility = 'COMPREHENSIVE_INPATIENT_REHABILITATION_FACILITY',
  ComprehensiveOutpatientRehabilitationFacility = 'COMPREHENSIVE_OUTPATIENT_REHABILITATION_FACILITY',
  EndStageRenalDiseaseTreatmentFacility = 'END_STAGE_RENAL_DISEASE_TREATMENT_FACILITY',
  StateOrLocalPublicHealthClinic = 'STATE_OR_LOCAL_PUBLIC_HEALTH_CLINIC',
  RuralHealthClinic = 'RURAL_HEALTH_CLINIC',
  IndependentLaboratory = 'INDEPENDENT_LABORATORY',
  OtherUnlistedFacility = 'OTHER_UNLISTED_FACILITY',
}

/**   A group of SMS messages sent between a patient and a pratice */
export type SmsConversation = Entity & {
  __typename?: 'SmsConversation'
  id: Scalars['ID']
  /**   the patient profile involved in this converastion */
  patientProfile: PatientProfile
  /**   phone number the message was sent to */
  patientPhoneNumber: Scalars['String']
  /**   phone number the message came from */
  practicePhoneNumber: Scalars['String']
  /**   converastion status */
  status: SmsConversationStatus
  /**   list of SMS messages that make up this conversation */
  messages: Array<SmsMessage>
  /**   date the conversation was started (aka: when the first message was sent) */
  createdOn: Scalars['AWSTimestamp']
  /**   date the conversation was last updated (aka: when the last message recived) */
  updatedOn: Scalars['AWSTimestamp']
}

/**   A group of SMS messages sent between a patient and a pratice */
export type SmsConversationMessagesArgs = {
  pageSize?: Maybe<Scalars['Int']>
}

export enum SmsConversationStatus {
  Unread = 'unread',
  Read = 'read',
  FollowUp = 'follow_up',
  Archived = 'archived',
  All = 'all',
}

export type SmsConversationUpdateInput = {
  status?: Maybe<SmsConversationStatus>
}

/**   The direction of the message - https://www.twilio.com/docs/sms/api/message-resource#message-properties */
export enum SmsDirection {
  /**  incoming messages */
  Inbound = 'inbound',
  /**  initiated by a REST API, */
  OutboundApi = 'outbound_api',
  /**  messages initiated during a call */
  OutboundCall = 'outbound_call',
  /**  messages initiated in response to an incoming message */
  OutboundReply = 'outbound_reply',
}

/**   A single SMS message, can be either sent (outbound) or recived (inbound) */
export type SmsMessage = {
  __typename?: 'SmsMessage'
  /**   phone number the message was sent to */
  to: Scalars['String']
  /**   phone number the message came from */
  from: Scalars['String']
  /**   message body */
  body: Scalars['String']
  /**   the date the message was sent */
  sendDate?: Maybe<Scalars['AWSDateTime']>
  /**   message direction */
  direction: SmsDirection
  /**   message status */
  status: SmsStatus
  /**   the PracticeUser that sent this message (only applicable to outbound messages) */
  practiceUser?: Maybe<PracticeUser>
}

export type SmsMessageInput = {
  patientProfileId: Scalars['ID']
  body: Scalars['String']
}

/**   Possible message status from twilio - https://www.twilio.com/docs/sms/api/message-resource#message-status-values */
export enum SmsStatus {
  Accepted = 'accepted',
  Queued = 'queued',
  Sending = 'sending',
  Sent = 'sent',
  Failed = 'failed',
  Delivered = 'delivered',
  Undelivered = 'undelivered',
  Receiving = 'receiving',
  Received = 'received',
}

export enum StaffType {
  /**  Office staff */
  Administrative = 'ADMINISTRATIVE',
  /**  Medical practitioners */
  Medical = 'MEDICAL',
  /**  For anything that is none of the above */
  Other = 'OTHER',
}

export type Statistic = {
  __typename?: 'Statistic'
  key: Scalars['String']
  value: Scalars['Float']
}

export enum SubscriberRelationshipTypes {
  Child = 'CHILD',
  Employee = 'EMPLOYEE',
  Other = 'OTHER',
  Self = 'SELF',
  Spouse = 'SPOUSE',
}

export type Surgery = {
  __typename?: 'Surgery'
  date?: Maybe<Scalars['AWSDate']>
  type: Scalars['String']
}

export type SurgeryInput = {
  date?: Maybe<Scalars['AWSDate']>
  type: Scalars['String']
}

export type SyncResults = {
  __typename?: 'SyncResults'
  success: Scalars['Boolean']
  itemCount: Scalars['Int']
  indexName: Scalars['String']
  tableName: Scalars['String']
  error?: Maybe<Scalars['String']>
}

export enum TableNames {
  SmsConversations = 'SmsConversations',
  AssignedForms = 'AssignedForms',
  Appointments = 'Appointments',
  Families = 'Families',
  PatientProfiles = 'PatientProfiles',
  Practices = 'Practices',
  Reminders = 'Reminders',
  FieldTemplates = 'FieldTemplates',
  FieldTemplateGroups = 'FieldTemplateGroups',
  ClinicalNoteModules = 'ClinicalNoteModules',
  ClinicalNoteModuleFields = 'ClinicalNoteModuleFields',
  InsuranceClaims = 'InsuranceClaims',
  ClinicalNotes = 'ClinicalNotes',
  ClinicalNotesModular = 'ClinicalNotesModular',
}

export type Tokens = {
  __typename?: 'Tokens'
  AccessToken: Scalars['String']
  ExpiresIn: Scalars['Int']
  IdToken: Scalars['String']
  RefreshToken: Scalars['String']
  TokenType: Scalars['String']
}

export type UpdatePracticeInput = {
  name: Scalars['String']
  isActive?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['AWSEmail']>
  phone?: Maybe<Scalars['AWSPhone']>
  fax?: Maybe<Scalars['AWSPhone']>
  /**   a practices NPI */
  nationalProviderIdentifier?: Maybe<Scalars['String']>
  /**   unique ID to identify a practice in the Aithoz system */
  aithozClientId?: Maybe<Scalars['String']>
  billingAddress?: Maybe<AddressInput>
  /**   the type of practice - dental, mendical, chiropractic, etc */
  specialty?: Maybe<DoctorSpecialty>
  website: Scalars['String']
  practiceManagementSoftware?: Maybe<Scalars['String']>
  referringDoctors?: Maybe<Array<ReferringDoctorInput>>
}

export type UserAccount = Family | PracticeUser | PsAdminAccount

export type UserSchedule = {
  __typename?: 'UserSchedule'
  schedulableHours?: Maybe<ScheduleHours>
  practiceLocation?: Maybe<PracticeLocation>
}

export type UserScheduleInput = {
  schedulableHours?: Maybe<ScheduleHoursInput>
  practiceLocationId?: Maybe<Scalars['ID']>
}
