import styled from 'styled-components/macro'

export const FooterSegment = styled.footer`
  background: #fff;
  padding: 20px;

  ${(p) => p.theme.breakpoints.medium} {
    margin-left: 150px;
  }
`
