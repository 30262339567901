import * as React from 'react'

export const AmendmentWarning: React.FC<{ name: string }> = ({ name }) => {
  return (
    <div className="p-4 rounded-md bg-[rgb(211,75,78)] max-w-[385px] mx-auto mt-5">
      <p className="text-white text-sm">
        You are amending {name}'s note. <br />
        After you finish, you need{' '}
        <span className="font-bold">submit the amendments</span>.
      </p>
    </div>
  )
}
