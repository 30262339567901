import React from 'react'
import { TStrength } from 'src/pages/ClinicalNotesv2/types'

type StrengthAnswerProps = {
  strengthAnswer: TStrength
}

export const StrengthAnswer: React.FC<StrengthAnswerProps> = ({
  strengthAnswer,
}) => {
  return (
    <>
      {strengthAnswer.measures.map((measure) => {
        return (
          <div className="mb-3">
            <p className="flex flex-col">
              {measure.body ? (
                <>
                  <span className="font-semibold py-1">
                    Body part: {measure.body}
                  </span>
                </>
              ) : null}
              {measure.type ? (
                <>
                  <span className="font-semibold py-1">
                    Type: {measure.type}
                  </span>
                </>
              ) : null}
              {measure.side ? (
                <>
                  <span className="font-semibold py-1">
                    Side: {measure.side}
                  </span>
                </>
              ) : null}
            </p>
          </div>
        )
      })}
      {strengthAnswer.notes ? (
        <p>
          <span
            className="font-semibold break-words"
            style={{ wordBreak: 'break-word' }}
          >
            Notes:{' '}
          </span>
          {strengthAnswer.notes}
        </p>
      ) : null}
    </>
  )
}
