import React from 'react'
import { THep } from 'src/pages/ClinicalNotesv2/types'
import { TermAnswer } from '../TermAnswer'

type HepAnswerProps = {
  hepAnswer: THep
}

export const HepAnswer: React.FC<HepAnswerProps> = ({ hepAnswer }) => {
  return (
    <>
      {hepAnswer.measures.map((measure) => {
        return (
          <div className="mb-3">
            <p className="flex flex-col">
              {measure.cue ? (
                <>
                  <span className="font-semibold py-1">Cue: {measure.cue}</span>
                </>
              ) : null}
              {measure.device ? (
                <>
                  <span className="font-semibold py-1">
                    Device: {measure.device}
                  </span>
                </>
              ) : null}
              {measure.term ? <TermAnswer termAnswer={measure.term} /> : null}
            </p>
          </div>
        )
      })}
      {hepAnswer.notes ? (
        <p>
          <span
            className="font-semibold break-words"
            style={{ wordBreak: 'break-word' }}
          >
            Notes:{' '}
          </span>
          {hepAnswer.notes}
        </p>
      ) : null}
    </>
  )
}
