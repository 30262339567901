import { makeAutoObservable, runInAction } from 'mobx'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { ClinicalNoteServices } from 'src/services/clinicalNote'
import { sortByOrdinance } from 'src/utils/sorting'
import { ClinicalNotesStore } from '..'

export class ClinicalNoteTemplatesStore {
  clinicalNotesStore: ClinicalNotesStore
  services: ClinicalNoteServices

  constructor(
    clinicalNotesStore: ClinicalNotesStore,
    services: ClinicalNoteServices
  ) {
    makeAutoObservable(this, { clinicalNotesStore: false })
    this.clinicalNotesStore = clinicalNotesStore
    this.services = services
  }
  isChanging? = false
  errorChanging? = ''

  async changeClinicalNoteTemplate(templateId: string) {
    try {
      const clinicalNoteId = this.clinicalNotesStore.clinicalNote?.id
      if (!clinicalNoteId) {
        return
      }

      runInAction(() => {
        this.isChanging = true
      })

      const clinicalNoteTemplate = await this.services.changeTemplate(
        clinicalNoteId,
        templateId
      )

      runInAction(() => {
        if (this.clinicalNotesStore.clinicalNote?.template) {
          this.clinicalNotesStore.clinicalNote = {
            ...this.clinicalNotesStore.clinicalNote,
            answers: [],
            template: {
              ...this.clinicalNotesStore.clinicalNote.template,
              ...clinicalNoteTemplate,
            },
          }
          this.clinicalNotesStore.currentPageId =
            clinicalNoteTemplate?.pages.slice().sort(sortByOrdinance)[0].id ||
            ''
        }
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.errorChanging = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.isChanging = false
      })
    }
  }

  cleanError() {
    this.errorChanging = ''
  }
}
