export const constantOptions = [
  {
    label: 'IND',
    value: 'IND',
  },
  {
    label: 'MOD I',
    value: 'MOD_I',
  },
  {
    label: 'SUP',
    value: 'SUP',
  },
  {
    label: 'SBA',
    value: 'SBA',
  },
  {
    label: 'CGA',
    value: 'CGA',
  },
  {
    label: 'MIN A',
    value: 'MIN A',
  },
  {
    label: 'MOD A',
    value: 'MOD A',
  },
  {
    label: 'MAX A',
    value: 'MAX A',
  },
  {
    label: 'DEP',
    value: 'DEP',
  },
]

export const showerOptions = [
  {
    label: 'Grab Bar',
    value: 'GRAB_BAR',
  },
  {
    label: 'Shower Chair',
    value: 'SHOWER_CHAIR',
  },
]

export const tubOptions = [
  {
    label: 'Grab Bar',
    value: 'GRAB_BAR',
  },
  {
    label: 'Shower Chair',
    value: 'SHOWER_CHAIR',
  },
  {
    label: 'Tub Transfer Bench',
    value: 'TUB_TRANSFER_BENCH',
  },
]
export const toiletOptions = [
  {
    label: 'Grab Bar',
    value: 'GRAB_BAR',
  },
  {
    label: 'Bedside Commode',
    value: 'BEDSIDE_COMMODE',
  },
  {
    label: 'Drop Arm Commode',
    value: 'DROP_ARM_COMMODE',
  },
  {
    label: 'Handrails',
    value: 'HANDRAILS',
  },
  {
    label: 'Raised Toilet Seat',
    value: 'RAISED_TOILET_SEAT',
  },
  {
    label: 'FWW',
    value: 'FWW',
  },
  {
    label: '4WW',
    value: '4WW',
  },
  {
    label: 'SPC',
    value: 'SPC',
  },
]
export const chairOptions = [
  {
    label: 'FWW',
    value: 'FWW',
  },
  {
    label: '4WW',
    value: '4WW',
  },
  {
    label: 'Hemi Walker',
    value: 'HEMI_WALKER',
  },
  {
    label: 'SPC',
    value: 'SPC',
  },
]
