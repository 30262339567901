import { gql } from 'apollo-boost'
import { SubmitClinicalNoteModularAmendmentMutation } from 'src/operations-types'
import { apolloClient } from 'src/settings/apolloClient'
// import { clinicalNoteModularAmendmentFragment } from '../fragments/clinicalNoteModularAmendment'

const SUBMIT_CLINICAL_NOTE_MODULAR_AMENDMENT = gql`
  mutation submitClinicalNoteModularAmendment($id: ID!) {
    updateClinicalNoteModularAmendmentStatus(
      id: $id
      input: { status: FINAL }
    ) {
      id
    }
  }
`

export const submitClinicalNoteModularAmendment = async (id: string) => {
  const response =
    await apolloClient.mutate<SubmitClinicalNoteModularAmendmentMutation>({
      mutation: SUBMIT_CLINICAL_NOTE_MODULAR_AMENDMENT,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    })

  return response?.data?.updateClinicalNoteModularAmendmentStatus.id
}
