import { makeAutoObservable, runInAction } from 'mobx'
import { writeLog } from 'src/gql/mutations/writeToLog'
import {
  SmsConversation,
  SmsConversationStatus,
  SmsMessage,
  SmsMessageInput,
} from 'src/schema-types'
import { MessagingServices } from 'src/services/messaging'
import { RootStore } from 'src/stores/store'

export class MessagingStore {
  // Messages
  messages?: SmsMessage[]
  isFetchinMessages = false
  fetchMessagesError = ''
  isSendingMessage = false
  sendMessageError = ''

  // Conversations
  conversations?: SmsConversation[]
  isNewChatActive = false
  isFetchingConversations = false
  fetchConversationsError = ''
  activeConversation?: SmsConversation
  isLoadingMoreConversations = false
  conversationsPageNumber = 1
  isUpdatingConversation = false
  conversationStatus: SmsConversationStatus = SmsConversationStatus.All

  rootStore: RootStore
  services: MessagingServices

  constructor(rootStore: RootStore, services: MessagingServices) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
    this.services = services
  }

  getMessages = async () => {
    try {
      runInAction(() => {
        this.isFetchinMessages = true
      })
      if (this.activeConversation) {
        const messages = await this.services.getMessages(
          this.activeConversation.id
        )
        runInAction(() => {
          this.messages = messages
        })
      }
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchMessagesError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.isFetchinMessages = false
      })
    }
  }

  sendMessage = async (input: SmsMessageInput) => {
    try {
      runInAction(() => {
        this.isSendingMessage = true
      })
      await this.services.sendMessage(input)
      if (this.isNewChatActive) {
        const smsConversation = await this.services.getConversationByPatientId(
          this.activeConversation?.patientProfile.id || ''
        )
        runInAction(() => {
          if (!!smsConversation) {
            this.activeConversation = smsConversation
          }
        })
      }
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.sendMessageError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.isSendingMessage = false
      })
    }
  }

  getConversations = async (
    practiceId: string,
    currentStatus: SmsConversationStatus = SmsConversationStatus.All,
    isLoadingMore: boolean = false
  ) => {
    try {
      runInAction(() => {
        if (isLoadingMore) {
          this.isLoadingMoreConversations = true
        } else {
          this.isFetchingConversations = true
          this.conversationsPageNumber = 1
        }
        if (this.conversationStatus !== currentStatus) {
          this.conversationStatus = currentStatus
        }
      })
      const conversations = await this.services.getConversation(
        practiceId,
        this.conversationsPageNumber,
        this.conversationStatus
      )
      runInAction(() => {
        if (isLoadingMore) {
          this.conversations = this.conversations?.length
            ? [...this.conversations, ...conversations]
            : [...conversations]
        } else {
          this.conversations = conversations
        }
        this.conversationsPageNumber = this.conversationsPageNumber + 1
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchConversationsError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        if (isLoadingMore) {
          this.isLoadingMoreConversations = false
        } else {
          this.isFetchingConversations = false
        }
      })
    }
  }

  updateConversation = async (id: string, status: SmsConversationStatus) => {
    const oldConversations = !!this.conversations ? [...this.conversations] : []
    try {
      runInAction(() => {
        this.isUpdatingConversation = true
      })
      runInAction(() => {
        this.conversations = this.conversations?.map((convo) =>
          convo.id === id ? { ...convo, status: status } : convo
        )
        if (id === this.activeConversation?.id) {
          if (
            status === SmsConversationStatus.Unread ||
            status === SmsConversationStatus.Archived
          ) {
            this.activeConversation = undefined
          }
        }
      })
      const updatedConversation = await this.services.updateConversation(
        id,
        status
      )
      runInAction(() => {
        this.conversations = this.conversations?.map((convo) =>
          convo.id === updatedConversation.id ? updatedConversation : convo
        )
      })
    } catch (error) {
      writeLog(error)
      runInAction(() => {
        this.isUpdatingConversation = false
        this.conversations = oldConversations
      })
    } finally {
      runInAction(() => {
        this.isUpdatingConversation = false
      })
    }
  }

  setActiveConversation = (conversation?: SmsConversation) => {
    runInAction(() => {
      this.activeConversation = conversation
    })
  }

  resetMessaging = () => {
    runInAction(() => {
      this.activeConversation = undefined
      this.conversationsPageNumber = 1
      this.conversations = undefined
      this.messages = undefined
      this.isNewChatActive = false
    })
  }

  resetMessagingErrors = () => {
    runInAction(() => {
      this.fetchMessagesError = ''
      this.fetchConversationsError = ''
    })
  }

  // cleanMessages = () => {
  //   runInAction(() => {
  //     this.messages = []
  //   })
  // }

  setIsNewChatActive = (value?: boolean) => {
    runInAction(() => {
      this.isNewChatActive = !!value
      this.messages = undefined
    })
  }

  get hasError() {
    return !!this.fetchMessagesError || !!this.fetchConversationsError
  }
}
