import gql from 'graphql-tag'
import { clinicalNoteModularAnswerFragment } from './clinicalNoteModularAnswer'

export const clinicalNoteModularAmendmentFragment = gql`
  fragment clinicalNoteModularAmendment on ClinicalNoteModularAmendment {
    id
    status
    createdOn
    answers {
      ...clinicalNoteAnswer
    }
  }
  ${clinicalNoteModularAnswerFragment}
`
