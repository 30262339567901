import { Typography } from '@ps-ui/components'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { Dropdown } from 'src/pages/ClinicalNotesv2/components/fields/Dropdown'
import { HorizontalOptionsList } from 'src/pages/ClinicalNotesv2/components/fields/HorizontalOptionsList'
import { Term } from 'src/pages/ClinicalNotesv2/components/fields/Term'
import { MultiMeasureContainer } from 'src/pages/ClinicalNotesv2/components/MultiMeasureContainer'
import { ClinicalNoteElementTypes } from 'src/schema-types'

export const GoalProgressElem: React.FC<{ name: string }> = ({ name }) => {
  const { watch } = useFormContext()
  const percentageOptions = React.useMemo(() => {
    const data = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
    return data.map((elem) => ({ label: `${elem}`, value: `${elem}` }))
  }, [])

  return (
    <div className="flex flex-col justify-between">
      <div className="flex flex-col">
        <Term name={`${name}.term`} isDisabled />
        <div className="-mt-4">
          <Typography as="p">{watch(`${name}.goal`)}</Typography>
        </div>
      </div>
      <div className="flex flex-col mt-5">
        <div className="flex justify-between w-[300px] mb-3">
          <div className="w-[180px]">
            <Dropdown
              options={percentageOptions}
              name={`${name}.metPercentage`}
            />
          </div>
          <Typography as="p">% Goal Met</Typography>
        </div>
        <div className="flex justify-start">
          <HorizontalOptionsList
            name={`${name}.isMet`}
            options={[{ label: 'isMet', value: 'isMet' }]}
            isLabelVisible={false}
            hasSelectedBg={false}
            isFlex
          />
          <Typography as="p">Goal Met</Typography>
        </div>
      </div>
    </div>
  )
}

export const GoalProgress: React.FC<{ name: string }> = ({ name }) => {
  const { watch } = useFormContext()
  return (
    <MultiMeasureContainer
      key={JSON.stringify(watch(name))}
      name={name}
      type={ClinicalNoteElementTypes.Goal}
      withBodyPart={false}
      ChildComponent={GoalProgressElem}
      canAddMore={false}
    />
  )
}
