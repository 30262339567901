import {
  rangeOfNumbersInOptions,
  turnIntoOption,
} from 'src/pages/ClinicalNotesv2/utils'

export const terrain = turnIntoOption([
  'Grass',
  'Gravel',
  'Pavement',
  'Ramp',
  'Carpet',
  'Uneven surface',
  'Even surface',
])

export const weightBearing = turnIntoOption(['FWB', 'NWB', 'TTWB', 'PWB'])

export const percentageWeightBearing = turnIntoOption([
  '10%',
  '20%',
  '30%',
  '40%',
  '50%',
  '60%',
  '70%',
  '80%',
  '90%',
])

export const repetitions = rangeOfNumbersInOptions(20)

export const equipment = turnIntoOption([
  '4WW',
  'FWW',
  'Platform walker',
  'SPC',
  'QC',
  'hemi-walker',
  'Axillary Crutch',
  'Lofstrand crutches',
])

export const gaitTrainingSkills = turnIntoOption([
  'Abrupt stopping',
  'Avoiding obstacles',
  'Side stepping',
  'Turning',
  'Backward walking',
  'Forward walking',
  'Long stride',
  'Short stride',
])

export const levelOfAssist = turnIntoOption([
  'IND',
  'MOD I',
  'SUP',
  'SBA',
  'CGA',
  'MIN A',
  'MOD A',
  'MAX A',
  'DEP',
])

export const tolerance = turnIntoOption(['Excellent', 'Good', 'Fair', 'Poor'])

export const pattern = turnIntoOption([
  'Antalgic L and R',
  'Festinating',
  'Wide base',
  'Narrow base',
  'Decreased step length L and R',
  'Shuffling',
  'Slow',
  'Staggering',
  'Limping',
  'Hobbling',
  'Vaulting',
  'Waddling',
  'Stiff',
  'Unsteady',
  'Hip extension L and R',
  'Hip hiking L and R',
])

export const cadence = turnIntoOption([
  'WNL',
  'WFL',
  'Asymmetrical',
  'Decreased timing L and R',
  'Fast',
  'Increased timing L and R',
  'Shuffling',
  'Slow',
  'Symmetrical',
])

export const support = turnIntoOption([
  'AFO',
  'KAFO',
  'Ankle Brace',
  'Knee Immobilizer',
  'Hinged Knee Brace',
])
