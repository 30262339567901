import { Typography } from '@ps-ui/components'
import React, { useState } from 'react'
import { Avatar } from 'src/components/atoms/'
import { ProfilePicture } from 'src/schema-types'
import {
  CardStyling,
  InnerCardContainer,
  PatientCardContainer,
  TypographyContainer,
} from './styles'

type PatientCardProps = {
  url?: ProfilePicture | undefined | null
  patientName: string
  patientDOB: string
  patientAge: number
  onError?: () => void
}

export const PatientCard: React.FC<PatientCardProps> = ({
  url,
  patientName,
  patientDOB,
  patientAge,
}) => {
  const [imageUrl, setImageUrl] = useState(url?.downloadUrl)

  return (
    <PatientCardContainer>
      <Typography as="h3" fontWeight="700">
        Patient
      </Typography>
      <CardStyling>
        <InnerCardContainer className="fs-mask">
          <Avatar
            url={imageUrl}
            onError={() => setImageUrl('')}
            firstName={patientName}
            height="107"
            width="107"
            borderRadius="13"
          />
          <TypographyContainer>
            <Typography as="h3" fontWeight="700" margin="0 0 0 10px">
              {patientName}
            </Typography>
            <Typography as="p" margin="40px 0 0 10px" color="#92949D">
              {patientDOB}
            </Typography>
            <Typography as="p" margin="0 0 0 10px" color="#92949D">
              ({patientAge} years old)
            </Typography>
          </TypographyContainer>
        </InnerCardContainer>
      </CardStyling>
    </PatientCardContainer>
  )
}
