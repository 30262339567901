import { makeAutoObservable, runInAction } from 'mobx'
import * as TOperation from 'src/operations-types'
import { TModule } from 'src/pages/ClinicalNotesv2/types'
import { ClinicalNoteModuleType } from 'src/schema-types'
import { ClinicalNoteServices } from 'src/services/clinicalNote'
import { ClinicalNotesStore } from '..'

export class ClinicalNoteSpecialModulesStore {
  visibleModules: string[] = []
  clinicalNotesStore: ClinicalNotesStore
  services: ClinicalNoteServices
  removingAnswers = false
  constructor(
    clinicalNotesStore: ClinicalNotesStore,
    services: ClinicalNoteServices
  ) {
    makeAutoObservable(this, { clinicalNotesStore: false })
    this.clinicalNotesStore = clinicalNotesStore
    this.services = services
  }

  get modulesForEachPage() {
    const value: Record<string, TModule[]> = {}
    if (this.clinicalNotesStore.clinicalNote?.template) {
      this.clinicalNotesStore.clinicalNote.template.pages.forEach((page) => {
        page.sections.forEach((section) => {
          section.modules.forEach((module) => {
            if (module.type !== TOperation.ClinicalNoteModuleType.Generic) {
              value[page.id] = [...(value[page.id] || []), module]
            }
          })
        })
      })
    }
    return value
  }

  get modulesInCurrentPage() {
    return !!this.modulesForEachPage[this.clinicalNotesStore.currentPageId]
      ?.length
  }

  setFirstVisualModules = () => {
    if (this.clinicalNotesStore.clinicalNote?.answers) {
      const listOfModulesWithAnswer: string[] = []
      const fieldAnswerIds = this.clinicalNotesStore.clinicalNote.answers
        .filter((answer) => !!answer?.answer.length)
        .map((answer) => answer?.field.id)
      console.log({ fieldAnswerIds, modules: this.modulesForEachPage })
      Object.entries(this.modulesForEachPage).forEach(([_, listModule]) => {
        listModule.forEach((module) => {
          const fieldsId = module.fields.map((field) => field.id)
          const someFieldsHasAnswer = fieldsId.some((fieldId) =>
            fieldAnswerIds.includes(fieldId)
          )
          if (someFieldsHasAnswer) {
            listOfModulesWithAnswer.push(module.id)
          }
        })
      })
      runInAction(() => {
        this.visibleModules = listOfModulesWithAnswer
      })
    }
  }

  showModules = (ids: string[]) => {
    runInAction(() => {
      this.visibleModules = [...this.visibleModules, ...ids]
    })
  }

  hideModule = (module: TModule) => {
    const fieldIds = module.fields.map((field) => field.id)
    fieldIds.forEach((id) => {
      this.services.saveAnswer({
        answer: '',
        clinicalNoteModularId: this.clinicalNotesStore.clinicalNote?.id || '',
        clinicalNoteFieldId: id,
      })
    })

    const updatedAnswers = this.clinicalNotesStore.clinicalNote?.answers?.map(
      (elem) => {
        if (elem?.field && fieldIds.includes(elem.field.id)) {
          return {
            ...elem,
            answer: '',
          }
        }
        return elem
      }
    )

    runInAction(() => {
      if (this.clinicalNotesStore.clinicalNote) {
        this.clinicalNotesStore.clinicalNote = {
          ...this.clinicalNotesStore.clinicalNote,
          answers: updatedAnswers,
        }
      }
      this.visibleModules = this.visibleModules.filter(
        (moduleId) => moduleId !== module.id
      )
    })

    return fieldIds
  }

  modulesBasedOnType = (type: ClinicalNoteModuleType | null) => {
    if (type) {
      return (
        this.modulesForEachPage[this.clinicalNotesStore.currentPageId]?.filter(
          (module) => module.type === type
        ) || []
      )
    }
    return []
  }
}
