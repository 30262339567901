import { gql } from 'apollo-boost'
import { ImportClinicalNoteModularMutation } from 'src/operations-types'
import { ClinicalNoteModularImportInput } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'
import { clinicalNoteModularFragment } from '../fragments/clinicalNoteModular'

export const COPY_CLINICAL_NOTE_MODULAR_ANSWERS = gql`
  mutation importClinicalNoteModular($input: ClinicalNoteModularImportInput!) {
    importClinicalNoteModular(input: $input) {
      ...clinicalNoteModular
    }
  }
  ${clinicalNoteModularFragment}
`

export const copyClinicalNoteModularAnswers = async (
  input: ClinicalNoteModularImportInput
) => {
  const response = await apolloClient.mutate<ImportClinicalNoteModularMutation>(
    {
      mutation: COPY_CLINICAL_NOTE_MODULAR_ANSWERS,
      variables: {
        input,
      },
    }
  )
  return response.data?.importClinicalNoteModular
}
