import { Flex, Icon, Typography } from '@ps-ui/components'
import React from 'react'
import { ReactComponent as HazardIcon } from 'src/images/hazard.svg'

type AlertMessageProps = {
  alertMessage: string
  hasIcon?: boolean
}

export const AlertMessage: React.FC<AlertMessageProps> = ({
  alertMessage,
  hasIcon = true,
}) => {
  return (
    <div className="mx-auto px-0 py-10 flex justify-center align-middle">
      <Flex flexDirection="column" alignItems="center">
        {hasIcon ? (
          <Icon Component={HazardIcon} size="xxl" color="#FF5C00" />
        ) : null}
        <Typography as="h3" fontWeight="700" align="center" padding="2 0 2 0">
          {alertMessage}
        </Typography>
      </Flex>
    </div>
  )
}
