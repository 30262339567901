import { useCallback, useState } from 'react'

export const useShowModal = (initialState = false): [boolean, () => void] => {
  const [showModal, setShowModal] = useState(initialState)

  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [setShowModal, showModal])

  return [showModal, toggleModal]
}
