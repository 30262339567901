import * as React from 'react'
import { PainBodyPart } from 'src/pages/ClinicalNotesv2/components/fields/Pain/PainBodypart'
import { ClinicalNoteElementTypes } from 'src/schema-types'
import { MultiMeasureContainer } from '../../MultiMeasureContainer'

interface PainProps {
  name: string
}

export const Pain: React.FC<PainProps> = ({ name }) => {
  return (
    <MultiMeasureContainer
      type={ClinicalNoteElementTypes.Pain}
      withNotes
      name={name}
      ChildComponent={PainBodyPart}
    />
  )
}
