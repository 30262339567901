import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PrintPageContainer } from 'src/components/atoms/PrintPageContainer'
import { PrintPageWrapper } from 'src/components/molecules/PrintPageWrapper'
import { usePrint } from 'src/hooks/print'
import { ParsedClinicalNote } from 'src/pages/ClinicalNotesv2/components/ParsedClinicalNote'
import { PrintHeader } from 'src/pages/ClinicalNotesv2/components/PrintHeader'
import { useStore } from 'src/stores/store'

type PrintProps = {}

export const Print: React.FC<PrintProps> = observer(() => {
  const [hidePrintButton, setIsPrinting] = usePrint()

  const { clinicalNoteId } = useParams<{ clinicalNoteId?: string }>()

  const { clinicalNotesStore } = useStore()

  useEffect(() => {
    if (clinicalNoteId) {
      clinicalNotesStore.fetchClinicalNote(clinicalNoteId)
    }
  }, [clinicalNoteId, clinicalNotesStore])

  return (
    <PrintPageWrapper
      isLoading={clinicalNotesStore.fetching}
      loadingText="Generating Note..."
      hidePrintButton={hidePrintButton}
      buttonText="SAVE OR PRINT PDF"
      onButtonClick={() => {
        setIsPrinting(true)
      }}
    >
      <PrintPageContainer>
        <PrintHeader
          practice={clinicalNotesStore.clinicalNote?.template?.practice}
        />
        <ParsedClinicalNote />
      </PrintPageContainer>
    </PrintPageWrapper>
  )
})
