import styled from 'styled-components/macro'

export const TabPane = styled.div`
  margin: 0;
  padding: 16px;
  padding-top: 24px;
  width: 100%;
  background-color: #fff;
  border-top: none !important;
`

export const TabWrapper = styled.div`
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .ui.attached.menu {
    border-bottom: 0;

    .item {
      border: 0;
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }
`

export const FullWidthTab = styled.div`
  width: 400px;
  @media only screen and (max-width: 400px) {
    width: 100%;
  }
`
