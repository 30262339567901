import { Flex, Typography } from '@ps-ui/components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { memo } from 'react'
import DatePicker from 'react-datepicker'
import { HiddenInput } from 'src/components/atoms'
import { ReactComponent as CalendarIcon } from 'src/images/calendar_icon.svg'
import { StoreEvents, StoreState } from 'src/state/store'
import { useStoreon } from 'storeon/react'
import {
  Day,
  DayHeading,
  DayList,
  NavButton,
  PickerWrapper,
  Table,
  TimeSlot,
  Wrapper,
} from './styles'
import { useTimeSlots } from './useTimeSlots'

dayjs.extend(utc)

export const AppointmentTime = memo(() => {
  const { schedule } = useTimeSlots()
  const { chosenDoctor, chosenService, dateTo, dispatch, noRules } = useStoreon<
    StoreState,
    StoreEvents
  >('chosenDoctor', 'chosenService', 'chosenPatient', 'dateTo', 'noRules')

  if (!(chosenDoctor && chosenService)) {
    return null
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch('scheduling-date/set', e.target.value)
  }

  return (
    <Wrapper>
      <Typography as="h2">Date & Time</Typography>
      <Flex padding="20px 0" alignItems="center">
        <NavButton
          style={{
            marginRight: '20px',
          }}
          onClick={() => dispatch('scheduling-date/prev')}
        >
          prev
        </NavButton>
        <NavButton onClick={() => dispatch('scheduling-date/next')}>
          next
        </NavButton>
        <PickerWrapper>
          <CalendarIcon />

          <DatePicker
            tabIndex={-1}
            className="vhidden"
            value={''}
            id=""
            onChange={(date) => {
              date && dispatch('scheduling/set-current-date', date)
            }}
          />
        </PickerWrapper>
      </Flex>
      <Table>
        <thead>
          <tr>
            {schedule.map((day) => {
              if (day[0].holiday) {
                return (
                  <DayHeading key={day[0].weekDay}>
                    <Typography as="h2" color="#384364" fontWeight="500">
                      {day[0].weekDay}
                    </Typography>
                    <Typography as="h4" margin="12px 0 0 0" fontWeight="500">
                      {day[0].slot}
                    </Typography>
                  </DayHeading>
                )
              }
              return (
                <th key={day[0].weekDay}>
                  <Typography as="h2" color="#384364" fontWeight="500">
                    {day[0].weekDay}
                  </Typography>
                  <Typography as="h4" margin="12px 0 0 0" fontWeight="500">
                    {dayjs(day[0].slot).format('MMM D')}
                  </Typography>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {schedule.map((day, idx) =>
              day[0].holiday ? (
                <Day style={{ height: 200 }} />
              ) : (
                <Day key={idx}>
                  <DayList>
                    {day.map((val, idx: number) => (
                      <li style={{ width: 'auto' }} key={idx}>
                        <label>
                          <HiddenInput
                            type="radio"
                            name="time"
                            value={val.slot}
                            className="vhidden"
                            disabled={val.booked && !noRules}
                            onChange={handleChange}
                            checked={dateTo === val.slot}
                          />
                          <TimeSlot className={val.booked ? 'booked' : ''}>
                            {dayjs.utc(val.slot).format('hh:mm a')}
                          </TimeSlot>
                        </label>
                      </li>
                    ))}
                  </DayList>
                </Day>
              )
            )}
          </tr>
        </tbody>
      </Table>
    </Wrapper>
  )
})
