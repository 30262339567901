import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  exerciseCategories,
  exerciseOptions,
  locationsByCategory,
  tools,
} from 'src/pages/ClinicalNotesv2/types'
import { ClinicalNoteElementTypes } from 'src/schema-types'
import { InputContainer } from '../../../InputContainer'
import { InterventionContainer } from '../../../InterventionContainer'
import { Row } from '../../../layoutForm/Row'
import { Dropdown } from '../../Dropdown'
import { HorizontalOptionsList } from '../../HorizontalOptionsList'
import { ClinicalNotesInput } from '../../Input'

export const ExerciseElem: React.FC<{ name: string }> = ({ name }) => {
  const { control } = useFormContext()

  const categoryValue = useWatch({
    name: `${name}.category`,
    control,
  })

  const locationValue = useWatch({
    name: `${name}.location`,
    control,
  })

  const exerciseValue = useWatch({
    name: `${name}.exercise`,
    control,
  })

  const allCategories = React.useMemo(() => {
    return exerciseCategories.map((elem) => ({ label: elem, value: elem }))
  }, [])

  const allExercises = React.useMemo(() => {
    if (locationValue && categoryValue) {
      return exerciseOptions
        .filter(
          (elem) =>
            elem.categories.includes(categoryValue) &&
            elem.location.includes(locationValue)
        )
        .map((elem) => ({ label: elem.name, value: elem.name }))
    }
    return []
  }, [locationValue, categoryValue])

  const allLocations = React.useMemo(() => {
    if (categoryValue) {
      return locationsByCategory[categoryValue].map((elem) => ({
        label: elem,
        value: elem,
      }))
    }
    return []
  }, [categoryValue])

  const allPositions = React.useMemo(() => {
    if (exerciseValue) {
      return (
        exerciseOptions
          .find((elem) => elem.name === exerciseValue)
          ?.position.map((elem) => ({ label: elem, value: elem })) || []
      )
    }
    return []
  }, [exerciseValue])

  const sideOptions = React.useMemo(() => {
    return ['Left', 'Right', 'Both'].map((elem) => ({
      label: elem,
      value: elem,
    }))
  }, [])

  const toolOptions = React.useMemo(() => {
    return tools.map((elem) => ({ label: elem, value: elem }))
  }, [])

  return (
    <div className="flex flex-col">
      <Row columns={2}>
        <Dropdown
          name={`${name}.category`}
          options={allCategories}
          label="Categories of exercise"
        />
        <Dropdown
          name={`${name}.location`}
          options={allLocations}
          label="Location"
          disabled={!categoryValue}
        />
      </Row>
      <Row columns={2}>
        <Dropdown
          name={`${name}.exercise`}
          options={allExercises}
          label="Exercise"
          disabled={!locationValue || !categoryValue}
        />
        <Dropdown
          name={`${name}.position`}
          options={allPositions}
          label="Position"
          disabled={!exerciseValue}
          isMulti
        />
      </Row>
      <Row columns={1}>
        <InputContainer name={`${name}.tool`} label="Tool/Medium Used">
          <HorizontalOptionsList name={`${name}.tool`} options={toolOptions} />
        </InputContainer>
      </Row>
      <Row columns={2}>
        <InputContainer name={`${name}.repetitions`} label="Repetitions">
          <ClinicalNotesInput
            name={`${name}.repetitions`}
            className="w-full border border-[#D1D5DB] py-2 px-3"
            placeholder="Insert information"
            type="number"
          />
        </InputContainer>
        <InputContainer name={`${name}.sets`} label="Sets">
          <ClinicalNotesInput
            name={`${name}.sets`}
            className="w-full border border-[#D1D5DB] py-2 px-3"
            placeholder="Insert information"
            type="number"
          />
        </InputContainer>
      </Row>
      <Row columns={2}>
        <Dropdown name={`${name}.side`} options={sideOptions} label="Side" />
      </Row>
      <Row columns={2}>
        <InputContainer name={`${name}.muscle`} label="Muscle">
          <ClinicalNotesInput
            name={`${name}.muscle`}
            className="w-full border border-[#D1D5DB] py-2 px-3"
            placeholder="Insert information"
          />
        </InputContainer>
      </Row>
    </div>
  )
}

export const Exercise: React.FC<{ name: string }> = ({ name }) => {
  return (
    <InterventionContainer
      name={name}
      type={ClinicalNoteElementTypes.Exercise}
      render={({ name }) => <ExerciseElem name={name} />}
    />
  )
}
