'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.Flex = void 0

var _styledComponents = _interopRequireDefault(require('styled-components'))

var _templateObject

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0)
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }))
}

/*
  REASONS THIS COMPONENT EXISTS:
  
  - Sometimes flexbox gets the job done cleaner than SUI grids.
  - Creating styled components to only use as flex containers bloats code
  - No existing component with an API that corresponds to flexbox 1 to 1
  - It is a short and simple component thanks to emotion
*/
var Flex = _styledComponents['default'].div(
  _templateObject ||
    (_templateObject = _taggedTemplateLiteral([
      '\n  display: flex;\n  position: relative;\n  ',
      '\n',
    ])),
  function (props) {
    return '\n    justify-content: '
      .concat(props.justifyContent || 'flex-start', ';\n    flex-direction: ')
      .concat(props.flexDirection || 'row', ';\n    flex-grow: ')
      .concat(props.flexGrow || 0, ';\n    flex-basis: ')
      .concat(props.flexBasis || 'auto', ';\n    flex-shrink: ')
      .concat(props.flexShrink || 1, ';\n    flex-wrap: ')
      .concat(props.flexWrap || 'nowrap', ';\n    flex: ')
      .concat(props.flex || '0 1 auto', ';\n    align-items: ')
      .concat(props.alignItems || 'stretch', ';\n    margin: ')
      .concat(props.theme.spacingToPx(props.margin) || '0', ';\n    padding: ')
      .concat(props.theme.spacingToPx(props.padding) || '0', ';\n    width: ')
      .concat(props.width || 'auto', ';\n    height: ')
      .concat(props.height || 'auto', ';\n    max-width: ')
      .concat(props.maxWidth || 'none', ';\n    max-height: ')
      .concat(props.maxHeight || 'none', ';\n    min-width: ')
      .concat(props.minWidth || 'none', ';\n    min-height: ')
      .concat(props.minHeight || 'none', ';\n  ')
  }
)

exports.Flex = Flex
