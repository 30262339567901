import dayjs from 'dayjs'
import { Maybe } from 'src/schema-types'

export const sortByOrdinance = (
  a: { ordinal?: Maybe<number> } | null,
  b: { ordinal?: Maybe<number> } | null
) => {
  const ordA = a?.ordinal
  const ordB = b?.ordinal
  if (ordA && ordB) return ordA - ordB
  return 0
}

export const sortUnixDates = (a: number, b: number) => {
  if (dayjs.unix(a).isAfter(dayjs.unix(b))) {
    return 1
  }
  return -1
}

export const sortByCode = (a: { code: string }, b: { code: string }) => {
  if (a.code < b.code) {
    return -1
  }
  if (a.code > b.code) {
    return 1
  }
  return 0
}
