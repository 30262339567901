import constate from 'constate'
import { useMemo } from 'react'
import { services, Services } from 'src/services'
import { AppointmentsStore } from 'src/stores/domain/appointments'
import { AuthStore } from 'src/stores/domain/auth'
import { PatientStore } from 'src/stores/domain/patient'
import { PatientsStore } from 'src/stores/domain/patients'
import { PracticeStore } from 'src/stores/domain/practice'
import { ProfileStore } from 'src/stores/domain/profile'
import { AssginedFormStore } from './domain/assignedForms'
import { BillingStore } from './domain/billing'
import { CalendarFilterStore } from './domain/calendarFilter'
import { ClinicalNotesStore } from './domain/clinicalNotes'
import { MessagingStore } from './domain/messaging'
import { ReportingFilterStore } from './domain/reportingFilter'
import { TasksStore } from './domain/tasks'

export class RootStore {
  authStore: AuthStore
  profileStore: ProfileStore
  patientStore: PatientStore
  appointmentsStore: AppointmentsStore
  practiceStore: PracticeStore
  calendarFilterStore: CalendarFilterStore
  reportingFilterStore: ReportingFilterStore
  messagingStore: MessagingStore
  patientsStore: PatientsStore
  assginedFormStore: AssginedFormStore
  tasksStore: TasksStore
  clinicalNotesStore: ClinicalNotesStore
  billingStore: BillingStore

  constructor(services: Services) {
    this.authStore = new AuthStore(this, services.auth)
    this.patientsStore = new PatientsStore(this, services.patients)
    this.profileStore = new ProfileStore(this, services.profile)
    this.patientStore = new PatientStore(this, services.patient)
    this.appointmentsStore = new AppointmentsStore(this, services.appointments)
    this.practiceStore = new PracticeStore(this, services.practice)
    this.messagingStore = new MessagingStore(this, services.messaging)
    this.assginedFormStore = new AssginedFormStore(this, services.assignedForms)
    this.tasksStore = new TasksStore(this)
    this.clinicalNotesStore = new ClinicalNotesStore(
      this,
      services.clinicalNote
    )
    this.reportingFilterStore = new ReportingFilterStore(
      this,
      services.reportingFilter
    )
    this.calendarFilterStore = new CalendarFilterStore(
      this,
      services.calendarFilter
    )
    this.billingStore = new BillingStore(this, services.billingServices)
  }
}

// by default, we use this global store in our application
// state. this gives us the flexibility to reference it outside of hooks as well
export const store = new RootStore(services)

type UseStoreProps = {
  customServices?: Services
}

const useStoreHook = ({ customServices }: UseStoreProps) => {
  const localStore = useMemo(
    () => (customServices ? new RootStore(customServices) : store),
    [customServices]
  )
  return localStore
}

export const [StoreProvider, useStore] = constate(useStoreHook)
