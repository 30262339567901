import { sendSmsMessage } from 'src/gql/mutations/sendSmsMessage'
import { updateSmsConversation } from 'src/gql/mutations/updateSmsConversation'
import { queryConversationByPatientId } from 'src/gql/queries/queryConversationByPatientId'
import { querySmsConversations } from 'src/gql/queries/querySmsConversations'
import { querySmsMessages } from 'src/gql/queries/querySmsMessages'

export const messagingServices = {
  getMessages: querySmsMessages,
  sendMessage: sendSmsMessage,
  getConversation: querySmsConversations,
  updateConversation: updateSmsConversation,
  getConversationByPatientId: queryConversationByPatientId,
}

export type MessagingServices = typeof messagingServices
