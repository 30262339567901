import { Typography } from '@ps-ui/components'
import React, { useCallback, useEffect, useState } from 'react'
import { Select } from 'react-functional-select'
import { Avatar } from 'src/components/atoms'
import { queryPatientsBySearch } from 'src/gql/queries/queryAllPatients'
import { PatientProfile } from 'src/schema-types'
import { useStore } from 'src/stores/store'

type SelectedPatient = {
  value: string
  label: string
}

/**
 * This Searchbox is meant to **search patients only**. It's a good fit if
 * you need a list of patients dynamically fetched as the user types in.
 * When a patient is selected, **it updates the global PatientStore with new
 * selected patient**. Then it can be accesed by consuming the PatientStore
 * throughout the whole application
 *
 * **A limitation of it is it just shows the first 20 records**. react-functional-select
 * has limitations regarding paginated data & infinite lazyloading on scroll. We thought
 * and there's no need to show more than 20 records for now as the user usually types in
 * at least the full firstname. A possible solution would be to use react-select though
 *
 */
export const PatientSearchBox = () => {
  const [selectedPatient, setSelectedPatient] = useState<SelectedPatient>()
  const [patients, setPatients] = useState<PatientProfile[] | undefined>([])
  const [searching, setSearching] = useState(false)
  const [term, setTerm] = useState<string | undefined>()

  const { patientStore } = useStore()

  useEffect(() => {
    if (!selectedPatient) return
    // update PatientStore with new Patient
    patientStore.fetchById(selectedPatient?.value)
  }, [selectedPatient, patientStore])

  const searchPatient = useCallback(async () => {
    if (!term) return
    try {
      const response = await queryPatientsBySearch(term, {
        pageNumber: 1,
        pageSize: 20,
      })
      setPatients(response.patients)
      setSearching(false)
    } catch (error) {
      setSearching(false)
    }
  }, [term])

  useEffect(() => {
    setPatients([])
    searchPatient()
  }, [term, searchPatient])

  const getOptionLabel = useCallback((opt: any) => opt.label, [])
  const onOptionChange = useCallback((e) => setSelectedPatient(e), [])
  const onSearchChange = useCallback((e) => setTerm(e), [])
  const onInputChange = useCallback(() => setSearching(true), [])

  return (
    <div className="flex flex-col">
      <Typography as="h4" fontWeight="700" padding="0 0 8px 0">
        <span className="text-[#DA6C6C] mr-1">*</span>Select Patient
      </Typography>
      <div className="flex">
        {selectedPatient && (
          <Avatar
            url={''}
            firstName={selectedPatient?.label}
            height="40"
            width="40"
            borderRadius="50"
            margin="0 10px 0 0"
          />
        )}
        <div className="w-full">
          <Select
            required={true}
            isLoading={searching}
            inputDelay={500}
            menuMaxHeight={150}
            placeholder="Select a patient"
            noOptionsMsg={'No More Options Available'}
            initialValue={selectedPatient}
            onSearchChange={onSearchChange}
            getOptionLabel={getOptionLabel}
            onInputChange={onInputChange}
            onOptionChange={onOptionChange}
            options={patients?.map((patient) => ({
              value: patient.id,
              label: `${patient.firstName} ${patient.lastName}`,
            }))}
          />
        </div>
      </div>
    </div>
  )
}
