import { Button } from '@ps-ui/components'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { useToasts } from 'react-toast-notifications'
import { ModalWindow } from 'src/components/molecules'
import { StyledTooltip } from 'src/pages/Calendar/index.styles'
import { ClinicalNoteStatus } from 'src/schema-types'
import { useStore } from 'src/stores/store'
import { ThemeContext } from 'styled-components'

type NavigationHeaderProps = {}

const baseTitleStyle =
  'inline-flex items-center mx-2 first:ml-0 px-4 py-2 text-lg font-medium rounded-md text-gray-700 hover:bg-gray-300 focus:outline-none'
const activeTitleStyle = 'font-semibold bg-gray-200'

export const NavigationHeader: React.FC<NavigationHeaderProps> = observer(
  () => {
    const { addToast } = useToasts()
    const { clinicalNotesStore, billingStore } = useStore()
    const [isSubmitModalOpen, setIsSubmitModalOpen] = React.useState(false)
    const [isSubmitAmendModalOpen, setIsSubmitAmendModalOpen] =
      React.useState(false)
    const theme = useContext(ThemeContext)

    const handleChangePage = (pageId: string) => () => {
      if (pageId === 'BILLING') {
        if (billingStore.currentClaimData) {
          clinicalNotesStore.setCurrentPage(pageId)
          return
        }

        if (!billingStore.currentClaimData) {
          billingStore.resetValues()
          billingStore.validateInsuranceAndSubmit()
        }
      }
      clinicalNotesStore.setCurrentPage(pageId)
    }

    const handleSubmitButton = () => {
      if (clinicalNotesStore.amendments.isAmendmentInProgress) {
        setIsSubmitAmendModalOpen(true)
      } else if (
        clinicalNotesStore.clinicalNote?.status ===
        ClinicalNoteStatus.InProgress
      ) {
        setIsSubmitModalOpen(true)
      }
    }

    const handleSubmitClaimButton = () => {
      billingStore.submitClaim()
    }

    const onSubmitAmendment = async () => {
      try {
        await clinicalNotesStore.amendments.submitAmendment()
        if (clinicalNotesStore.amendments.errorSubmitting) {
          addToast('Error submitting amendment', {
            appearance: 'error',
            onDismiss: () => clinicalNotesStore.amendments.cleanErrors,
          })
        } else {
          addToast('Amendment was submitted', { appearance: 'success' })
        }
      } catch (error) {
        addToast('Error submitting amendment', {
          appearance: 'error',
          onDismiss: () => clinicalNotesStore.amendments.cleanErrors,
        })
      } finally {
        setIsSubmitAmendModalOpen(false)
      }
    }

    const onSubmitClinicalNote = async () => {
      try {
        billingStore.resetValues()
        billingStore.validateInsuranceAndSubmit()
        await clinicalNotesStore.submitClinicalNote()
        if (clinicalNotesStore.error) {
          addToast('Error submitting clinical note', {
            appearance: 'error',
            onDismiss: () => clinicalNotesStore.cleanError,
          })
        } else {
          addToast('Clinical note was submitted', { appearance: 'success' })
        }
      } catch (error) {
        addToast('Error submitting clinical note', {
          appearance: 'error',
          onDismiss: () => clinicalNotesStore.cleanError,
        })
      } finally {
        setIsSubmitModalOpen(false)
      }
    }

    const isInProgress =
      clinicalNotesStore.clinicalNote?.status ===
        ClinicalNoteStatus.InProgress ||
      clinicalNotesStore.amendments.isAmendmentInProgress

    return (
      <div className="my-7 items-center justify-between flex w-full relative">
        {clinicalNotesStore.isClinicalNoteOwner ? (
          <>
            <div className="flex justify-items-start">
              {clinicalNotesStore.clinicalNotePages.map((page) => {
                return (
                  <>
                    <button
                      key={page.id}
                      type="button"
                      disabled={!isInProgress}
                      className={clsx(
                        !isInProgress && 'cursor-not-allowed',
                        baseTitleStyle,
                        'rounded-lg',
                        clinicalNotesStore.currentPage?.id === page.id &&
                          activeTitleStyle
                      )}
                      onClick={handleChangePage(page.id)}
                    >
                      {page.title}
                    </button>
                  </>
                )
              })}
              {clinicalNotesStore?.clinicalNote?.status === 'FINAL' && (
                <button
                  type="button"
                  className={clsx(
                    baseTitleStyle,
                    clinicalNotesStore.currentPage?.id === 'BILLING' &&
                      activeTitleStyle
                  )}
                  onClick={handleChangePage('BILLING')}
                >
                  Billing
                </button>
              )}
            </div>
            {isInProgress && (
              <Button variant="filled" onClick={handleSubmitButton}>
                Submit{' '}
                {clinicalNotesStore.amendments.isAmendmentInProgress
                  ? 'Amendment'
                  : 'Evaluation'}
              </Button>
            )}
            {!isInProgress &&
              billingStore.currentClaimData?.status === 'UNSUBMITTED' && (
                <div data-tip data-for="submitButton">
                  <Button
                    variant="filled"
                    onClick={handleSubmitClaimButton}
                    disabled={billingStore.disableSubmit}
                  >
                    Submit to Billing
                  </Button>

                  {billingStore.disableSubmit && (
                    <StyledTooltip
                      id="submitButton"
                      effect="float"
                      backgroundColor={theme.colors.primary}
                      place="right"
                    >
                      {billingStore.submitTooltipValue}
                    </StyledTooltip>
                  )}
                </div>
              )}
          </>
        ) : null}

        <ModalWindow
          isOpen={isSubmitModalOpen}
          onCloseModal={() => {
            setIsSubmitModalOpen(false)
          }}
          onPrimaryButtonClick={onSubmitClinicalNote}
          textPrimaryButton="Confirm"
          isLoading={clinicalNotesStore.isSubmittingClinicalNote}
          onSecondaryButtonClick={() => {
            setIsSubmitModalOpen(false)
          }}
          textSecondaryButton="Cancel"
          modalSteps={[
            {
              header: 'Submit evaluation',
              content: (
                <p className="text-center">{`Are you sure you want to submit patient's evaluation?`}</p>
              ),
            },
          ]}
        />

        <ModalWindow
          isOpen={isSubmitAmendModalOpen}
          onCloseModal={() => {
            setIsSubmitAmendModalOpen(false)
          }}
          onPrimaryButtonClick={onSubmitAmendment}
          textPrimaryButton="Confirm"
          isLoading={clinicalNotesStore.amendments.isSubmitting}
          onSecondaryButtonClick={() => {
            setIsSubmitAmendModalOpen(false)
          }}
          textSecondaryButton="Cancel"
          modalSteps={[
            {
              header: 'Submit amendment',
              content: (
                <p className="text-center">{`Are you sure you want to submit patient's amendment?`}</p>
              ),
            },
          ]}
        />
      </div>
    )
  }
)
