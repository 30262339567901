import styled from 'styled-components/macro'

export const PickerWrapper = styled.label`
  margin-left: 20px;
  display: inline-block;
  &:focus,
  &:active {
    background-color: #08b175;
  }
  .react-datepicker {
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 20px rgba(83, 65, 142, 0.1);

    .react-datepicker__header {
      background: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker-popper[data-placement^='bottom'] {
      margin-top: 0;
    }

    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous {
      top: 16px;
      outline: none;
    }

    .react-datepicker__navigation--next {
      border-left-color: ${(props) => props.theme.colors.primary};
    }

    .react-datepicker__navigation--previous {
      border-right-color: ${(props) => props.theme.colors.primary};
    }

    .react-datepicker__current-month {
      color: ${(props) => props.theme.colors.grey};
      font-weight: 500;
      font-size: 20px;
      padding: 6px 0px;
    }

    .react-datepicker__day {
      color: ${(props) => props.theme.colors.grey};
      width: 30px;
      line-height: 30px;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--today {
      background-color: ${(props) => props.theme.colors.primary};
      border-radius: 50%;
      color: #fff;
    }
  }
`
