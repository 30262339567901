import { gql } from 'apollo-boost'
import { Mutation, SmsConversationStatus } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface UpdateSmsConversationResponse {
  updateSmsConversation: Mutation['updateSmsConversation']
}

export const UPDATE_SMS_CONVERSATION = gql`
  mutation updateSmsConversation($id: ID!, $input: SmsConversationUpdateInput) {
    updateSmsConversation(id: $id, input: $input) {
      id
      updatedOn
      status
      patientProfile {
        id
        firstName
        lastName
        phoneNumber
        email
        attachments {
          downloadUrl
        }
      }
      messages {
        to
        from
        body
        sendDate
        direction
        status
      }
    }
  }
`

export const updateSmsConversation = async (
  id: string,
  status: SmsConversationStatus
) => {
  const res = await apolloClient.query<UpdateSmsConversationResponse>({
    query: UPDATE_SMS_CONVERSATION,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      id,
      input: {
        status,
      },
    },
  })

  if (res.errors?.length) {
    throw new Error(res.errors[0].message)
  }
  return res.data.updateSmsConversation
}
