import {
  rangeOfNumbersInOptions,
  turnIntoOption,
} from 'src/pages/ClinicalNotesv2/utils'

export const location = turnIntoOption([
  'Shower',
  'Tub',
  'Chair',
  'Wheelchair',
  'Car',
  'Bed',
  'Toilet',
  'Sofa',
])

export const assistNeeded = turnIntoOption([
  'IND',
  'MOD I',
  'SUP',
  'SBA',
  'CGA',
  'MIN A',
  'MOD A',
  'MAX A',
  'DEP',
])

export const device = turnIntoOption([
  '4WW',
  'FWW',
  'Platform walker',
  'SPC',
  'QC',
  'hemi-walker',
  'Axillary Crutch',
  'lofstrand crutches',
  'slide board',
  'mechanical lift',
])

export const equipment = turnIntoOption([
  'Shower chair (shower)',
  'Tub transfer bench (tub)',
  'Grab bars',
  'Handrails L and R',
  'Bedside commode',
  'Raised toilet seat',
])

export const repetitions = rangeOfNumbersInOptions(15)

export const tolerance = turnIntoOption(['Excellent', 'Good', 'Fair', 'Poor'])
