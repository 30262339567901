import { gql } from 'apollo-boost'
import { QuerySmsConversationsQuery } from 'src/operations-types'
import { SmsConversation, SmsConversationStatus } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export const QUERY_SMS_CONVERSATIONS = gql`
  query querySmsConversations(
    $id: ID!
    $pagination: PaginationInput
    $status: SmsConversationStatus
  ) {
    getPractice(id: $id) {
      id
      smsConversations(pagination: $pagination, status: $status) {
        entities {
          ... on SmsConversation {
            id
            updatedOn
            status
            patientProfile {
              id
              firstName
              lastName
              phoneNumber
              email
              attachments {
                downloadUrl
              }
            }
            messages {
              to
              from
              body
              sendDate
              direction
              status
            }
          }
        }
      }
    }
  }
`

export const querySmsConversations = async (
  practiceId: string,
  pageNumber: number,
  status?: SmsConversationStatus
) => {
  const res = await apolloClient.query<QuerySmsConversationsQuery>({
    query: QUERY_SMS_CONVERSATIONS,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    variables: {
      id: practiceId,
      pagination: {
        pageNumber,
        pageSize: 25,
      },
      status,
    },
  })

  if (res.errors?.length) {
    throw new Error(res.errors[0].message)
  }
  return res.data.getPractice?.smsConversations.entities as SmsConversation[]
}
