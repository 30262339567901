import { makeAutoObservable, runInAction } from 'mobx'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { OptionWithType } from 'src/pages/Calendar/utils/calendarInterfaces'
import { Appointment, Scalars } from 'src/schema-types'
import { ReportingFilterServices } from 'src/services/reportingFilter'
import { RootStore } from 'src/stores/store'

const mapOptionsToValues = (options: OptionWithType[]) => {
  return options.map((opt) => opt.value)
}

export class ReportingFilterStore {
  filteredAppointmentList: Appointment[] | null = null
  fetchError = ''
  fetching = false
  doctorOptions = [] as string[] | undefined
  appointmentStatusOptions = [] as string[] | undefined
  paperworkStatusOptions = [] as string[] | undefined
  clinicalNoteStatusOptions = [] as string[] | undefined
  insuranceClaimStatusOptions = [] as string[] | undefined
  searchTerm = [] as string[] | undefined

  rootStore: RootStore
  services: ReportingFilterServices

  constructor(rootStore: RootStore, services: ReportingFilterServices) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
    this.services = services
  }

  // Pull a copy of allAppointments from the appointments Store
  get unfilteredAppointments() {
    return this.rootStore.appointmentsStore.allAppointments as Appointment[]
  }

  private filterByDoctors(doctorsList: Scalars['ID'][]) {
    try {
      this.fetching = true
      const activeDoctors = this.unfilteredAppointments?.flatMap((d) =>
        d.practiceUsers.map((d) => d.id)
      )
      // Filters the appointment list based on the active doctors
      const filteredDoctorList = activeDoctors?.filter((data) =>
        doctorsList.includes(data)
      )
      const filteredDocAppointments = this.unfilteredAppointments?.filter(
        (data) => {
          return filteredDoctorList.some((id) =>
            data.practiceUsers?.flatMap((d) => d.id).includes(id)
          )
        }
      )
      runInAction(() => {
        this.filteredAppointmentList = doctorsList.length
          ? filteredDocAppointments
          : this.filteredAppointmentList
      })
    } catch (error) {
      console.error(error)
      runInAction(() => {
        this.fetchError = (error as Error).message
      })
      writeLog((error as Error).message)
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }
  private filterByAppointmentStatus(appointmentStatusList: string[]) {
    try {
      this.fetching = true

      const filteredAppointmentStatus = this.filteredAppointmentList
        ? this.filteredAppointmentList?.filter((data) =>
            appointmentStatusList.includes(data.status)
          )
        : this.unfilteredAppointments?.filter((data) =>
            appointmentStatusList.includes(data.status)
          )

      runInAction(() => {
        this.filteredAppointmentList = appointmentStatusList.length
          ? filteredAppointmentStatus
          : this.filteredAppointmentList
      })
    } catch (error) {
      console.error(error)
      writeLog((error as Error).message)
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  private filterByPaperworkStatus(paperworkStatusList: string[]) {
    try {
      this.fetching = true
      const completed = paperworkStatusList.includes('COMPLETED')
      const filteredPaperworkStatus = this.filteredAppointmentList
        ? this.filteredAppointmentList
            ?.filter((data) => data.assignedForm !== null)
            .filter((data) => {
              if (paperworkStatusList.length > 1) {
                return data.assignedForm
              }
              if (completed) {
                return data.assignedForm?.isSubmitted
              } else {
                return !data.assignedForm?.isSubmitted
              }
            })
        : this.unfilteredAppointments
            ?.filter((data) => data.assignedForm !== null)
            .filter((data) => {
              if (paperworkStatusList.length > 1) {
                return data.assignedForm
              }
              return false
            })
      runInAction(() => {
        this.filteredAppointmentList = paperworkStatusList.length
          ? filteredPaperworkStatus
          : this.filteredAppointmentList
      })
    } catch (error) {
      console.error(error)
      writeLog((error as Error).message)
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  private filterByPatientName(searchTerm: string[]) {
    const filteredAppointmentsByName = this.filteredAppointmentList
      ? this.filteredAppointmentList?.filter((data) =>
          `${data?.patientProfile?.firstName}${data?.patientProfile?.lastName}`
            .toLowerCase()
            .includes(searchTerm[0]?.toLowerCase())
        )
      : this.unfilteredAppointments?.filter((data) =>
          `${data?.patientProfile?.firstName}${data?.patientProfile?.lastName}`
            .toLowerCase()
            .includes(searchTerm[0]?.toLowerCase())
        )
    runInAction(() => {
      this.filteredAppointmentList = searchTerm.length
        ? filteredAppointmentsByName
        : this.filteredAppointmentList
    })
  }

  private filterByClinicalNoteStatus(clinicalNoteStatusList: string[]) {
    try {
      this.fetching = true
      const filteredInsuranceClaimStatus = this.filteredAppointmentList
        ? this.filteredAppointmentList?.filter(
            (data) =>
              data?.clinicalNote?.status &&
              clinicalNoteStatusList.includes(data.clinicalNote?.status)
          )
        : this.unfilteredAppointments?.filter(
            (data) =>
              data?.clinicalNote?.status &&
              clinicalNoteStatusList.includes(data.clinicalNote?.status)
          )
      runInAction(() => {
        this.filteredAppointmentList = clinicalNoteStatusList.length
          ? filteredInsuranceClaimStatus
          : this.filteredAppointmentList
      })
    } catch (error) {
      console.error(error)
      writeLog((error as Error).message)
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  private filterByInsuranceClaimStatus(insuranceClaimStatusList: string[]) {
    try {
      this.fetching = true
      const filteredInsuranceClaimStatus = this.filteredAppointmentList
        ? this.filteredAppointmentList?.filter(
            (data) =>
              data?.insuranceClaim &&
              insuranceClaimStatusList.includes(data.insuranceClaim?.status)
          )
        : this.unfilteredAppointments?.filter(
            (data) =>
              data?.insuranceClaim &&
              insuranceClaimStatusList.includes(data.insuranceClaim?.status)
          )
      runInAction(() => {
        this.filteredAppointmentList = insuranceClaimStatusList.length
          ? filteredInsuranceClaimStatus
          : this.filteredAppointmentList
      })
    } catch (error) {
      console.error(error)
      writeLog((error as Error).message)
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  clearFilters() {
    this.doctorOptions = []
    this.appointmentStatusOptions = []
    this.paperworkStatusOptions = []
    this.clinicalNoteStatusOptions = []
    this.insuranceClaimStatusOptions = []
    this.searchTerm = []
  }

  initialFiltering(initialFilterValues: {
    [key: string]: OptionWithType[] | undefined
  }) {
    this.clearFilters()

    Object.entries(initialFilterValues).forEach(([key, value]) => {
      if (value) {
        this.setFilterOption(value, key)
      }
    })
  }

  setFilterOption(options: OptionWithType[], optionType: string) {
    switch (optionType) {
      case 'doctor':
        runInAction(() => {
          this.doctorOptions = mapOptionsToValues(options)
        })
        break

      case 'appointment-status':
        runInAction(() => {
          this.appointmentStatusOptions = mapOptionsToValues(options)
        })
        break

      case 'paperwork-status':
        runInAction(() => {
          this.paperworkStatusOptions = mapOptionsToValues(options)
        })
        break

      case 'search-term':
        runInAction(() => {
          this.searchTerm = mapOptionsToValues(options)
        })
        break

      case 'claim-status':
        runInAction(() => {
          this.insuranceClaimStatusOptions = mapOptionsToValues(options)
        })
        break

      case 'note-status':
        runInAction(() => {
          this.clinicalNoteStatusOptions = mapOptionsToValues(options)
        })
        break

      default:
        break
    }
  }

  filterAllAppointments() {
    // Resets the list every time an option is changed, this way we always have allAppointments and filter based on that
    runInAction(() => {
      this.filteredAppointmentList = this.unfilteredAppointments
    })

    // Subsequently runs all of the filters after the list is reset with their options passed from state.
    // If the options are blank, it will just return the filtered list in it's current state and move to the next
    this.doctorOptions && this.filterByDoctors(this.doctorOptions)

    this.appointmentStatusOptions &&
      this.filterByAppointmentStatus(this.appointmentStatusOptions)

    this.paperworkStatusOptions &&
      this.filterByPaperworkStatus(this.paperworkStatusOptions)

    this.searchTerm && this.filterByPatientName(this.searchTerm)

    this.clinicalNoteStatusOptions &&
      this.filterByClinicalNoteStatus(this.clinicalNoteStatusOptions)

    this.insuranceClaimStatusOptions &&
      this.filterByInsuranceClaimStatus(this.insuranceClaimStatusOptions)
  }
}
