import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { BinaryButtons } from 'src/pages/ClinicalNotesv2/components/fields/BinaryButtons'
import { Dropdown } from 'src/pages/ClinicalNotesv2/components/fields/Dropdown'

export type TPostureBody =
  | 'head'
  | 'cervical'
  | 'thoracic / lumbar'
  | 'pelvis'
  | 'hip'
  | 'knee'
  | 'foot'

const options: Record<TPostureBody, string[]> = {
  head: ['WNL', 'Forward Head', 'Lateral Tilt', 'Rotation'],
  cervical: ['WNL', 'Rounded Shoulders', 'Kyphosis', 'Flat Back'],
  'thoracic / lumbar': ['WNL', 'Lordosis', 'Scoliosis', 'Sway Back'],
  pelvis: [
    'WNL',
    'Anterior Pelvic Tilt',
    'Posterior Pelvic Tilt',
    'Obliquity',
    'Rotation',
  ],
  hip: [
    'WNL',
    'Abduction',
    'Adduction',
    'Internal Rotation',
    'External Rotation',
  ],
  knee: ['WNL', 'Genu Varum', 'Genu Valgum', 'Flexion', 'Hyperextension'],
  foot: ['Flat foot', 'High Arch', 'Pronation', 'Supination'],
}

interface PostureElemProps {
  name: string
  onClickRemove: () => void
  openModal: () => void
}

export const PostureElem: React.FC<PostureElemProps> = ({
  name,
  onClickRemove,
  openModal,
}) => {
  const { control } = useFormContext()
  const currentValue = useWatch({
    name,
    control,
  })

  const elemOptions = currentValue.body
    ? options[currentValue.body as TPostureBody].map((opt) => ({
        label: opt,
        value: opt,
      }))
    : []
  return (
    <div className="grid grid-flow-col grid-cols-4 gap-8">
      <div className="flex justify-between items-center border rounded-sm px-2">
        <span className="font-bold capitalize">{currentValue.body}</span>
      </div>
      <Dropdown
        name={`${name}.type`}
        options={elemOptions}
        defaultValue={currentValue.type}
      />
      <div className="flex items-center">
        <span>SIDE</span>
        <BinaryButtons
          name={`${name}.side`}
          defaultValue={currentValue.side}
          options={[
            { value: 'left', label: 'Left' },
            { value: 'right', label: 'Right' },
          ]}
        />
      </div>
      <div
        className="text-primary font-semibold cursor-pointer ml-5 p-3 rounded-lg h-full hover:bg-primary hover:bg-opacity-10"
        onClick={() => {
          onClickRemove()
          openModal()
        }}
      >
        Remove Measure
      </div>
    </div>
  )
}
