'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.createTheme = createTheme

var _tinycolor = require('@ctrl/tinycolor')

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object)
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object)
    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable
      })
    }
    keys.push.apply(keys, symbols)
  }
  return keys
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {}
    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key])
      })
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source))
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key))
      })
    }
  }
  return target
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true,
    })
  } else {
    obj[key] = value
  }
  return obj
}

function createTheme(branding) {
  return branding
    ? _objectSpread(
        _objectSpread({}, defaultTheme),
        {},
        {
          colors: _objectSpread(
            _objectSpread({}, defaultTheme.colors),
            {},
            {
              primary: branding.colorPrimary || defaultTheme.colors.primary,
              secondary: branding.colorSecondary || defaultTheme.colors.secondary,
              tertiary: branding.colorTertiary || defaultTheme.colors.tertiary,
            }
          ),
          logo: branding.logo.downloadUrl,
        }
      )
    : defaultTheme
}

var defaultTheme = {
  colors: {
    primary: '#08B175',
    secondary: '#2085D0',
    tertiary: '#F0F3F5',
    text: '#3C3C3C',
    subtext: '#777777',
    error: '#DA6C6C',
    success: '#74C270',
    input: {
      background: '#EEEEEE',
    },
    grey: '#384364',
    lightGrey: '#707891',
    white: '#FFFFFF',
  },
  layers: {
    top: 500,
  },
  breakpoints: {
    phones: '@media (max-width: 420px)',
    tablets: '@media (max-width: 1024px) and (hover: none), (max-width: 768px)',
    medium: '@media (max-width: 1000px)',
  },
  profilePlaceholder:
    'https://www.interplayit.com/wp-content/uploads/2018/10/profile-placeholder.jpg',
  logo: 'https://patientstudio.com/wp-content/uploads/2015/11/patient-studio-logo-590x117.png',
  // use a standard spacing formula (multiples of 8px)
  spacing: function spacing(value) {
    return ''.concat(value * 8, 'px')
  },
  // converts spacing given in standard units to px (converts "1 0 0 1" to "8px 0 0 8px")
  spacingToPx: function spacingToPx(spacing) {
    if (!spacing) return ''

    if (spacing.includes('px')) {
      return spacing
    }

    var splitSpacing = spacing.split(' ')
    var pxSpacing = splitSpacing.map(function (val) {
      return ''.concat(Number(val) * 8, 'px')
    })
    return pxSpacing.reduce(function (cur, next) {
      return ''.concat(cur, ' ').concat(next)
    })
  },
  lighten: function lighten(color, amount) {
    return new _tinycolor.TinyColor(color).setAlpha(amount || 0.2).toRgbString()
  },
  darken: function darken(color, amount) {
    return new _tinycolor.TinyColor(color).darken(amount || 0.3).toRgbString()
  },
}
