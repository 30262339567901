import { Icon } from '@ps-ui/components'
import React, { FC } from 'react'
import { ToastProps } from 'react-toast-notifications'
import { ReactComponent as SuccessIcon } from 'src/images/check-circle.svg'
import { ReactComponent as WarningIcon } from 'src/images/exclamation.svg'
import { ReactComponent as InfoIcon } from 'src/images/information-circle.svg'
import { ReactComponent as ErrorIcon } from 'src/images/x-circle.svg'

export const CustomToast: FC<ToastProps> = ({ appearance, children }) => {
  const renderToast = () => {
    if (appearance === 'warning') {
      return (
        <div className="flex w-auto max-w-4xl bg-yellow-100 py-4">
          <div className="pt-1 pl-4">
            <Icon Component={WarningIcon} size="xs" color="#FBBF24" />
          </div>
          <div className="pl-4">{children}</div>
        </div>
      )
    } else if (appearance === 'success') {
      return (
        <div className="flex w-auto max-w-4xl bg-green-100 py-4">
          <div className="pt-1 pl-4">
            <Icon Component={SuccessIcon} size="xs" color="#34D399" />
          </div>
          <div className="pl-4">{children}</div>
        </div>
      )
    } else if (appearance === 'error') {
      return (
        <div className="flex w-auto max-w-4xl bg-red-100 py-4">
          <div className="pt-1 pl-4">
            <Icon Component={ErrorIcon} size="xs" color="#F87171" />
          </div>
          <div className="pl-4">{children}</div>
        </div>
      )
    } else if (appearance === 'info') {
      return (
        <div className="flex w-auto max-w-4xl bg-blue-100 py-4">
          <div className="pt-1 pl-4">
            <Icon Component={InfoIcon} size="xs" color="#60A5FA" />
          </div>
          <div className="pl-4">{children}</div>
        </div>
      )
    }
  }

  return <>{renderToast()}</>
}
