import { gql } from 'apollo-boost'
import { GetPracticeFeatureFlagsQuery } from 'src/operations-types'
import { apolloClient } from 'src/settings/apolloClient'

const QUERY_FEATURE_CONFIG = gql`
  query getPracticeFeatureFlags($id: ID!) {
    getPractice(id: $id) {
      id
      featureConfig {
        id
        patientPortal_hasScheduling
        doctorPortal_hasScheduling
        doctorPortal_hasInsuranceClaims
        doctorPortal_isEHR
      }
    }
  }
`

export const queryFeatureConfig = async (practiceId: string | undefined) => {
  if (!practiceId) {
    return undefined
  }

  const res = await apolloClient.query<GetPracticeFeatureFlagsQuery>({
    query: QUERY_FEATURE_CONFIG,
    fetchPolicy: 'network-only',
    variables: {
      id: practiceId,
    },
  })

  if (res.errors?.length) {
    throw new Error(res.errors[0].message)
  }

  return res.data.getPractice || undefined
}
