import { Typography } from '@ps-ui/components'
import { formatDistanceStrict } from 'date-fns'
import dayjs from 'dayjs'
import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ClinicalNotesInput } from 'src/pages/ClinicalNotesv2/components/fields/Input'
import { InputContainer } from 'src/pages/ClinicalNotesv2/components/InputContainer'

export const TimeRecorder: React.FC<{ name: string }> = ({ name }) => {
  const { control, setValue } = useFormContext()
  const [errorMsg, setErrorMsg] = React.useState('')
  const { startName, endName, totalName } = React.useMemo(() => {
    return {
      startName: `${name}.start`,
      endName: `${name}.end`,
      totalName: `${name}.total`,
    }
  }, [name])

  const values = useWatch({
    name,
    control,
  })

  const startValue = values?.start
  const endValue = values?.end

  React.useEffect(() => {
    if (startValue && endValue) {
      const startDate = new Date(startValue)
      const endDate = new Date(endValue)
      const newTotalValue = formatDistanceStrict(startDate, endDate, {
        unit: 'minute',
      })
      if (endDate < startDate) {
        setErrorMsg(
          `Please select a date that comes after ${dayjs(startDate).format(
            'MMMM D, YYYY h:mm A'
          )}`
        )
      } else {
        setErrorMsg('')
        setValue(totalName, newTotalValue.replace('minutes', '').trim())
      }
    }
  }, [startValue, endValue, setValue, totalName, setErrorMsg])

  const minEndValue = React.useMemo(() => {
    if (startValue) {
      return dayjs(startValue).format('YYYY-MM-DDThh:mm')
    }
  }, [startValue])

  return (
    <div>
      <InputContainer name={startName} inlineLabel label="Start time:">
        <ClinicalNotesInput name={startName} type="datetime-local" />
      </InputContainer>
      <InputContainer name={endName} inlineLabel label="End time:">
        <div className="flex items-center">
          <ClinicalNotesInput
            name={endName}
            type="datetime-local"
            disabled={!startValue}
            min={minEndValue}
          />
          {errorMsg && (
            <div className="ml-2">
              <Typography as="p">{errorMsg}</Typography>
            </div>
          )}
        </div>
      </InputContainer>
      <InputContainer name={totalName} inlineLabel label="Total time:">
        <ClinicalNotesInput name={totalName} type="text" disabled />
        <span className="ml-2">minutes</span>
      </InputContainer>
    </div>
  )
}
