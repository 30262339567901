import { Flex, Icon, Typography } from '@ps-ui/components'
import React from 'react'
import { ReactComponent as Chevron } from '../../../images/chevron.svg'
import { ToggleButtonContainer, Wrapper } from './styles'

type CardProps = {
  title: string
  subHeader?: string
  TopIcon?: React.FunctionComponent<any>
  TopLeftIcon?: React.FunctionComponent<any>
  expandable?: boolean
  margin?: string
  maxWidth?: string
} & React.HTMLAttributes<HTMLDivElement>

export const Card: React.FC<CardProps> = ({
  title,
  subHeader,
  children,
  TopIcon,
  TopLeftIcon,
  expandable = true,
  ...rest
}) => {
  const [height, setHeight] = React.useState(0)
  const [isExpand, setIsExpand] = React.useState(false)

  const childrenContainer = (node: HTMLDivElement) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
    }
  }

  return (
    <Wrapper
      isExpand={isExpand || !expandable}
      className="flex flex-col rounded shadow-sm bg-white relative"
      {...rest}
    >
      <div className="flex items-center justify-between py-4 px-5 lg:px-6 w-full bg-gray-50">
        <Flex>
          {!!TopLeftIcon && <TopLeftIcon />}
          <Flex flexDirection="column">
            <Typography as="h4">{title}</Typography>
            {subHeader && (
              <Typography as="h5" fontWeight="300" color={'subtext'}>
                {subHeader}
              </Typography>
            )}
          </Flex>
        </Flex>
        {!!TopIcon && <TopIcon />}
      </div>
      <div className="p-5 lg:p-6 flex-grow w-full" ref={childrenContainer}>
        {children}
      </div>
      {height >= 240 && expandable && (
        <ToggleButtonContainer
          isExpand={isExpand}
          onClick={() => setIsExpand(!isExpand)}
        >
          <Typography
            as="h6"
            color="primary"
            style={{ marginRight: '12px', fontSize: '16px' }}
          >
            Expand
          </Typography>
          <Icon
            Component={Chevron as unknown as React.FC<any>}
            color="primary"
            angle={isExpand ? 180 : 360}
            size="xs"
          />
        </ToggleButtonContainer>
      )}
    </Wrapper>
  )
}
