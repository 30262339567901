import { Option } from '@ps-ui/components'
import React from 'react'
import { RangeInput } from 'src/pages/ClinicalNotesv2/components/fields/RangeInput'
import { transferOptions } from 'src/pages/ClinicalNotesv2/types'
import {
  chairOptions,
  constantOptions,
  showerOptions,
  toiletOptions,
  tubOptions,
} from 'src/pages/ClinicalNotesv2/utils/transfersArrays'
import { TabModuleContainer } from '../../TabModuleContainer'
import { HorizontalOptionsList } from '../HorizontalOptionsList'

type TransfersProps = {
  name: string
  currentTab: string
}

export const TransfersElem: React.FC<TransfersProps> = ({
  name,
  currentTab,
}) => {
  const variableOptions: Option[] | undefined = React.useMemo(() => {
    switch (currentTab) {
      case 'Tub':
        return tubOptions
      case 'Toilet':
        return toiletOptions
      case 'Shower':
        return showerOptions
    }
  }, [currentTab])

  const variableOptions2: Option[] | undefined = React.useMemo(() => {
    switch (currentTab) {
      case 'Chair':
        return chairOptions
      case 'Wheelchair':
        return chairOptions
      case 'Car':
        return [
          {
            label: 'Car Handle',
            value: 'CAR_HANDLE',
          },
        ]
    }
  }, [currentTab])

  return (
    <div>
      <RangeInput name={`${name}.option1`} options={constantOptions} />
      <div className="relative py-2">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300 border-dashed m-auto" />
        </div>
      </div>
      {variableOptions2 ? (
        <RangeInput name={`${name}.equipment`} options={variableOptions2} />
      ) : null}
      {variableOptions ? (
        <HorizontalOptionsList
          isMulti={true}
          name={`${name}.equipment`}
          options={variableOptions}
        />
      ) : null}
    </div>
  )
}

export const Transfers: React.FC<{ name: string }> = ({ name }) => {
  return (
    <TabModuleContainer name={name} optionList={transferOptions}>
      {({ currentTab }) => (
        <TransfersElem
          key={currentTab}
          name={`${name}.properties.${currentTab}`}
          currentTab={currentTab}
        />
      )}
    </TabModuleContainer>
  )
}
