import * as React from 'react'
import { Dropdown } from 'src/pages/ClinicalNotesv2/components/fields/Dropdown'
import { OptionList } from 'src/pages/ClinicalNotesv2/components/fields/interventions/OptionList'
import { InterventionContainer } from 'src/pages/ClinicalNotesv2/components/InterventionContainer'
import { Row } from 'src/pages/ClinicalNotesv2/components/layoutForm/Row'
import * as options from 'src/pages/ClinicalNotesv2/types/intervention/transferTraining'
import { ClinicalNoteElementTypes } from 'src/schema-types'

export const TransferTrainingElem: React.FC<{ name: string }> = ({ name }) => {
  return (
    <div className="flex flex-col">
      <Row columns={1}>
        <Dropdown
          label="Location"
          name={`${name}.location`}
          isMulti
          options={options.location}
          placeholder="Select a Location"
        />
      </Row>

      <Row columns={1}>
        <OptionList
          label="Assistive Needed"
          coreName={name}
          name="assistNeeded"
          options={options.assistNeeded}
          isRounded
        />
      </Row>

      <Row columns={1}>
        <OptionList
          label="Assistive Device"
          coreName={name}
          name="assistiveDevice"
          options={options.assistNeeded}
          isRounded
        />
      </Row>

      <Row columns={2}>
        <Dropdown
          label="Repetitions"
          options={options.repetitions}
          name={`${name}.repetitions`}
        />
      </Row>

      <Row columns={1}>
        <OptionList
          coreName={name}
          name="adaptiveEquipment"
          label="Adaptive Equipment"
          options={options.equipment}
          isMulti
        />
      </Row>

      <Row columns={1}>
        <OptionList
          coreName={name}
          name="tolerance"
          label="Tolerance"
          options={options.tolerance}
          isRounded
        />
      </Row>
    </div>
  )
}

export const TransferTraining: React.FC<{ name: string }> = ({ name }) => {
  return (
    <InterventionContainer
      name={name}
      type={ClinicalNoteElementTypes.EducationTraining}
      render={({ name }) => <TransferTrainingElem name={name} />}
    />
  )
}
