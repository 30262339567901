"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mmToPixel = void 0;

var mmToPixel = function mmToPixel(mm) {
  return mm * 3.7795275591;
};

exports.mmToPixel = mmToPixel;