import styled from 'styled-components/macro'

export const PannelWrapper = styled.div<PannelWrapperProps>`
  background: #fff;
  padding: ${(props) => props.padding || '20px 25px'};
  margin-bottom: 25px;
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width};
  box-shadow: 0px 3px 15px rgba(39, 52, 69, 0.03);

  ${({ borderRadius }: PannelWrapperProps) =>
    borderRadius && ` border-radius: ${borderRadius}`}
`

export const Title = styled.p`
  margin: 0;
  font-size: 24px;
  color: ${(props) => props.theme.colors.grey};
`

type PannelWrapperProps = {
  borderRadius?: string
  height?: string
  width?: string
  padding?: string
  margin?: string
}
