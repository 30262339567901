'use strict'

function _typeof(obj) {
  '@babel/helpers - typeof'
  if (typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol') {
    _typeof = function _typeof(obj) {
      return typeof obj
    }
  } else {
    _typeof = function _typeof(obj) {
      return obj &&
        typeof Symbol === 'function' &&
        obj.constructor === Symbol &&
        obj !== Symbol.prototype
        ? 'symbol'
        : typeof obj
    }
  }
  return _typeof(obj)
}

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.Spinner = void 0

var _styledComponents = _interopRequireWildcard(require('styled-components'))

var _templateObject, _templateObject2

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== 'function') return null
  var cacheBabelInterop = new WeakMap()
  var cacheNodeInterop = new WeakMap()
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop
  })(nodeInterop)
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj
  }
  if (obj === null || (_typeof(obj) !== 'object' && typeof obj !== 'function')) {
    return { default: obj }
  }
  var cache = _getRequireWildcardCache(nodeInterop)
  if (cache && cache.has(obj)) {
    return cache.get(obj)
  }
  var newObj = {}
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor
  for (var key in obj) {
    if (key !== 'default' && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc)
      } else {
        newObj[key] = obj[key]
      }
    }
  }
  newObj['default'] = obj
  if (cache) {
    cache.set(obj, newObj)
  }
  return newObj
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0)
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }))
}

var spinAnim = (0, _styledComponents.keyframes)(
  _templateObject ||
    (_templateObject = _taggedTemplateLiteral([
      '\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n',
    ]))
)

var Spinner = _styledComponents['default'].div(
  _templateObject2 ||
    (_templateObject2 = _taggedTemplateLiteral([
      '\n  margin-right: ',
      ';\n  font-size: 10px;\n  text-indent: -9999em;\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  background: #ffffff;\n  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);\n  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);\n  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);\n  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);\n  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);\n  position: relative;\n  animation: ',
      " 1s infinite linear;\n  transform: translateZ(0);\n  &:before {\n    width: 50%;\n    height: 50%;\n    background: #ffffff;\n    border-radius: 100% 0 0 0;\n    position: absolute;\n    top: 0;\n    left: 0;\n    content: '';\n  }\n  &:after {\n    background: lightgrey;\n    width: 75%;\n    height: 75%;\n    border-radius: 50%;\n    content: '';\n    margin: auto;\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n  }\n",
    ])),
  function (props) {
    return props.theme.spacing(1)
  },
  spinAnim
)

exports.Spinner = Spinner
