import { gql } from 'apollo-boost'
import { QueryAllAppointmentsQuery } from 'src/operations-types'
import { PaginationInput } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export const QUERY_ALL_APPOINTMENTS = gql`
  query queryAllAppointments(
    $id: ID!
    $startDate: AWSDate!
    $endDate: AWSDate!
    $pagination: PaginationInput
  ) {
    getPractice(id: $id) {
      id
      locations {
        id
        name
        schedulableHours {
          ...scheduleHours
        }
      }
      forms {
        id
        name
        isActive
      }
      appointmentReasonTemplates {
        name
      }
      users {
        id
        firstName
        lastName
        staffType
        schedule {
          schedulableHours {
            ...scheduleHours
          }
          practiceLocation {
            id
            name
          }
        }
        account {
          isActive
        }
        appointmentTypes {
          id
          name
          duration
          displayColor
          isActive
          isInternal
        }
        appointments(
          startDate: $startDate
          endDate: $endDate
          pagination: $pagination
          statuses: [
            SCHEDULED
            RESCHEDULED
            LATE_RESCHEDULED
            COMPLETED
            CANCELLED
            LATE_CANCELLED
            NOSHOW
          ]
        ) {
          pageInfo {
            hasMoreResults
          }
          entities {
            ... on Appointment {
              id
              dateTime
              status
              isConfirmed
              duration
              description
              insuranceClaim {
                id
                status
              }
              clinicalNote {
                id
                status
              }
              appointmentType {
                id
                name
                duration
                displayColor
                isActive
              }
              practiceLocation {
                id
              }
              patientProfile {
                id
                firstName
                lastName
              }
              practiceUsers {
                id
                firstName
                lastName
              }
              assignedForm {
                id
                isSubmitted
                form {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }

  fragment scheduleHours on ScheduleHours {
    monday {
      ...startEndHours
    }
    tuesday {
      ...startEndHours
    }
    wednesday {
      ...startEndHours
    }
    thursday {
      ...startEndHours
    }
    friday {
      ...startEndHours
    }
    saturday {
      ...startEndHours
    }
    sunday {
      ...startEndHours
    }
  }

  fragment startEndHours on ScheduleHoursElement {
    start
    end
  }
`

export const queryAllAppointments = async (
  practiceId: string | undefined,
  startDate: string,
  endDate: string,
  pagination: PaginationInput
) => {
  const res = await apolloClient.query<QueryAllAppointmentsQuery>({
    query: QUERY_ALL_APPOINTMENTS,
    fetchPolicy: 'no-cache',
    variables: {
      id: practiceId,
      startDate,
      endDate,
      pagination,
      v: Math.random(),
    },
  })

  if (res.errors?.length) {
    throw new Error(res.errors[0].message)
  }

  return res.data.getPractice || undefined
}
