'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.PrintableSection = void 0

var _styledComponents = _interopRequireDefault(require('styled-components'))

var _templateObject

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0)
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }))
}

var PrintableSection = _styledComponents['default'].h3(
  _templateObject ||
    (_templateObject = _taggedTemplateLiteral([
      '\n  width: 100%;\n  border-top: 2px solid black;\n  text-align: ',
      ';\n',
    ])),
  function (props) {
    return props.type === 'main' ? 'center' : 'left'
  }
)

exports.PrintableSection = PrintableSection
