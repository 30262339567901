import { gql } from 'apollo-boost'
import { Appointment } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface DeletePatientProfileAttachmentResponse {
  deletePatientProfileAttachment: Appointment
}

export const DELETE_PATIENT_PROFILE_ATTACHMENT = gql`
  mutation deletePatientProfileAttachment($id: ID!) {
    deletePatientProfileAttachment(id: $id) {
      id
    }
  }
`
export const deletePatientProfileAttachment = async (id: string) => {
  const response =
    await apolloClient.mutate<DeletePatientProfileAttachmentResponse>({
      mutation: DELETE_PATIENT_PROFILE_ATTACHMENT,
      variables: {
        id,
      },
    })

  return response
}
