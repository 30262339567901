import clsx from 'clsx'
import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface BinaryButtonsProps {
  name: string
  cb?: (value: string) => void
  isVisible?: boolean
  options: {
    value: string
    label: string
  }[]
  defaultValue?: string
  rangeOfMotionButtons?: boolean
  noTopMargin?: boolean
  isDisabled?: boolean
}

export const BinaryButtons: React.FC<BinaryButtonsProps> = ({
  name,
  options,
  isDisabled = false,
  cb,
  defaultValue,
  isVisible = true,
  rangeOfMotionButtons,
  noTopMargin = false,
}) => {
  const { control } = useFormContext()
  return (
    <div
      className={clsx('flex', rangeOfMotionButtons && !noTopMargin && 'mt-10')}
    >
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { value, onChange } }) => {
          return isVisible ? (
            <div className="flex items-center">
              {rangeOfMotionButtons && (
                <label className="mr-56 pr-2 font-bold">Side</label>
              )}
              <div className="flex">
                {options.map((option) => (
                  <label
                    key={option.value}
                    className={clsx(
                      'border-gray-400 border hover:border-primary px-3 py-2 w-max h-full cursor-pointer hover:text-primary bg-white',
                      'font-bold text-center w-20 cursor-pointer active:bg-green-100 active:text-green-500 capitalize',
                      'first:rounded-l-lg last:rounded-r-lg border-gray-400',
                      value === option.value
                        ? 'text-green-600 bg-green-100'
                        : 'text-gray-500 hover:text-green-500 hover:bg-green-100 active:bg-transparent',
                      !rangeOfMotionButtons && 'first:ml-4',
                      isDisabled && 'cursor-not-allowed'
                    )}
                  >
                    <input
                      type="radio"
                      className="hidden"
                      disabled={isDisabled}
                      checked={value === option.value}
                      onClick={(e) => {
                        if (e.currentTarget.value === value) onChange(null)
                      }}
                      onChange={(e) => {
                        if (value === e.target.value) {
                          return
                        }
                        onChange(e)
                        cb && cb(e.target.value)
                      }}
                      value={option.value}
                    />
                    {option.label}
                  </label>
                ))}
              </div>
            </div>
          ) : (
            <div className="hidden" />
          )
        }}
      />
    </div>
  )
}
