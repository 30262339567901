import { Flex, Icon, TextField } from '@ps-ui/components'
import React, { FunctionComponent, useEffect } from 'react'
import { useFormState } from 'react-use-form-state'
import { ReactComponent as SearchIcon } from 'src/images/search-icon.svg'
import { useDebounce } from 'use-debounce'
import { IconContainer, InputStyles } from './styles'

interface SearchState {
  searchValue: string
}

interface Props {
  onSearchChange: (value: string) => void
  onChangeLabelSearch: (value: string) => void
  debounceTime?: number
  placeholder?: string
}

/**
 *
 * This is a generic Search Box component. It doesn't handle
 * any logic, It just renders an input with a search icon to the left.
 *
 * It returns two callback functions: One of the input change and another
 * of the debounced search term. All the logic must be dealt on the
 * parent component.
 *
 * If you're looking for a decoupled searchable listbox with all logic
 * within, see ./PatientSearchBox.tsx for eg.
 *
 */
export const GenericSearchBox: FunctionComponent<Props> = ({
  onSearchChange,
  onChangeLabelSearch,
  debounceTime,
  placeholder,
}) => {
  const [searchState, searchInput] = useFormState<SearchState>(null, {
    onChange(e) {
      if (!searchState.errors.searchValue) {
        const { value } = e.target
        onChangeLabelSearch(value)
      }
    },
  })
  const [debouncedText] = useDebounce(
    searchState.values.searchValue,
    debounceTime ? debounceTime : 1500
  )

  useEffect(() => {
    onSearchChange(debouncedText)
  }, [debouncedText, onSearchChange])

  return (
    <>
      <Flex
        alignItems="center"
        width="100%"
        justifyContent="flex-end"
        style={{ position: 'relative' }}
      >
        <IconContainer>
          <Icon Component={SearchIcon} size="xs" color="lightGrey" />
        </IconContainer>
        <TextField
          placeholder={placeholder}
          customcss={InputStyles}
          noIcons
          inputClassName="fs-mask"
          {...searchInput.text('searchValue')}
        />
      </Flex>
    </>
  )
}
