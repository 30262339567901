import { gql } from 'apollo-boost'
import { PatientProfileAttachment } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface CreatePatientProfileAttachmentResponse {
  createPatientProfileAttachment: PatientProfileAttachment
}

export const CREATE_PATIENT_PROFILE_ATTACHMENT = gql`
  mutation createPatientProfileAttachment(
    $input: PatientProfileAttachmentCreateInput!
  ) {
    createPatientProfileAttachment(input: $input) {
      id
      fileName
      uploadUrl
      createdOn
      downloadUrl
      category
    }
  }
`

export const createPatientProfileAttachment = async (
  patientProfileId: string,
  fileName: string,
  category: string | null
) => {
  const response =
    await apolloClient.mutate<CreatePatientProfileAttachmentResponse>({
      mutation: CREATE_PATIENT_PROFILE_ATTACHMENT,
      variables: {
        input: {
          patientProfileId,
          fileName,
          category,
        },
      },
    })

  return response
}
