import styled from 'styled-components/macro'

type LabelProps = {
  required?: boolean | null
  disabled?: boolean
  error?: boolean
}

export const DropdownLabel = styled.label<LabelProps>`
  display: block;
  font-weight: 500;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  color: ${(props) =>
    props.error ? props.theme.colors.error : props.theme.colors.text};
  ${(props) =>
    props.required &&
    `  
    &::before {
      content: '* ';
      color: ${props.theme.colors.error};
    }
  `}
`
