import { Icon } from '@ps-ui/components'
import { observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  PageHeading,
  PageHeadingOption,
} from 'src/components/organisms/PageHeading'
import { ReactComponent as EditIcon } from 'src/images/edit_icon.svg'
import { ClinicalNoteStatus } from 'src/operations-types'
import { AmendmentWarning } from 'src/pages/ClinicalNotesv2/components/AmendmentWarning'
import { useStore } from 'src/stores/store'
import { PagePadding } from '../PagePadding'
import { NavigationHeader } from './components/NavigationHeader'
import { PatientHeader } from './components/PatientHeader'

type ClinicalNotesLayoutProps = {
  patient?: string
  intakeOpen: boolean
  toggleIntake: () => void
  toggleAddModule: () => void
  toggleAddAmend: () => void
  toggleCopyModal: () => void
  toggleShowChangeTemplateModal: () => void
}

export const ClinicalNotesLayout: React.FC<ClinicalNotesLayoutProps> = observer(
  ({
    children,
    patient,
    intakeOpen,
    toggleIntake,
    toggleCopyModal,
    toggleAddModule,
    toggleAddAmend,
    toggleShowChangeTemplateModal,
  }) => {
    const history = useHistory()
    const { clinicalNotesStore } = useStore()

    const handlePDF = () => {
      history.push(`/v2/print/${clinicalNotesStore.clinicalNote?.id}`)
    }

    const pageHeadingOptions: PageHeadingOption[] = [
      // TODO: Not in MVP, add back later
      // {
      //   title: `${intakeOpen ? 'Hide Patient intake' : 'View Patient Intake'}`,
      //   onClick: toggleIntake,
      //   buttonType: `${intakeOpen ? 'secondary' : 'default'}`,
      // },
      // TODO: Not in MVP, add back later
      // {
      //   title: 'View Plan',
      //   onClick: () => console.log('View Plan'),
      //   buttonType: 'default',
      // },
    ]

    const cardOptions = [{ label: 'Preview PDF', onClick: handlePDF }]

    if (clinicalNotesStore.isClinicalNoteOwner) {
      if (
        clinicalNotesStore.clinicalNote?.status ===
        ClinicalNoteStatus.InProgress
      ) {
        cardOptions.unshift({
          label: 'Change Template',
          onClick: toggleShowChangeTemplateModal,
        })

        cardOptions.unshift({
          label: 'Import from Previous Note',
          onClick: toggleCopyModal,
        })
      }

      if (
        clinicalNotesStore.amendments.isAmendmentInProgress ||
        clinicalNotesStore.clinicalNote?.status ===
          ClinicalNoteStatus.InProgress
      ) {
        pageHeadingOptions.push({
          title: 'Add modules',
          onClick: toggleAddModule,
          buttonType: 'default',
          iconLeft: () => (
            <Icon Component={EditIcon} size="xs" color="transparent" />
          ),
        })
      } else {
        pageHeadingOptions.push({
          title: 'Add Amendments',
          onClick: toggleAddAmend,
          buttonType: 'default',
          iconLeft: () => (
            <Icon Component={EditIcon} size="xs" color="transparent" />
          ),
        })
      }
    }

    cardOptions.unshift({
      label: 'See Review',
      onClick: () => clinicalNotesStore.setCurrentPage('REVIEW'),
    })

    return (
      <PagePadding>
        <div className="max-w-5xl mx-auto">
          <PageHeading
            title={patient || 'Clinical Note'}
            backButton
            options={pageHeadingOptions}
            removeDivider={true}
            cardOptions={cardOptions}
          />
          <PatientHeader />
          {clinicalNotesStore.amendments.isAmendmentInProgress && (
            <AmendmentWarning
              name={`${clinicalNotesStore.clinicalNote?.patient?.firstName} ${clinicalNotesStore.clinicalNote?.patient?.lastName}`}
            />
          )}
          <NavigationHeader />
          {children}
          {clinicalNotesStore.amendments.isAmendmentInProgress && (
            <AmendmentWarning
              name={`${clinicalNotesStore.clinicalNote?.patient?.firstName} ${clinicalNotesStore.clinicalNote?.patient?.lastName}`}
            />
          )}
        </div>
      </PagePadding>
    )
  }
)
