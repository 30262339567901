import { appointmentsServices } from 'src/services/appointments'
import { authServices } from 'src/services/auth'
import { patientServices } from 'src/services/patient'
import { practiceServices } from 'src/services/practice'
import { profileServices } from 'src/services/profile'
import { assignedFormsServices } from './assignedForms'
import { billingServices } from './billing'
import { calendarFilterServices } from './calendarFilter'
import { clinicalNoteServices } from './clinicalNote'
import { messagingServices } from './messaging'
import { patientsServices } from './patients'
import { reportingFilterServices } from './reportingFilter'

export const services = {
  profile: profileServices,
  auth: authServices,
  patient: patientServices,
  patients: patientsServices,
  practice: practiceServices,
  calendarFilter: calendarFilterServices,
  appointments: appointmentsServices,
  messaging: messagingServices,
  assignedForms: assignedFormsServices,
  reportingFilter: reportingFilterServices,
  clinicalNote: clinicalNoteServices,
  billingServices: billingServices,
}

export type Services = typeof services
