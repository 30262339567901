import { createInsuranceClaim } from '../../gql/mutations/createNewClaim'
import { submitInsuranceClaim } from '../../gql/mutations/submitInsuranceClaim'
import { updateInsuranceClaim } from '../../gql/mutations/updateExistingClaim'
import { updateReferringDoctor } from '../../gql/mutations/updateReferringDoctor'
import { queryClaimDataByAppointmentId } from '../../gql/queries/queryClaimDataByAppointmentId'
import { queryClaimDataByClaimId } from '../../gql/queries/queryClaimDataByClaimId'

export const billingServices = {
  createInsuranceClaim,
  updateInsuranceClaim,
  queryClaimDataByAppointmentId,
  queryClaimDataByClaimId,
  submitInsuranceClaim,
  updateReferringDoctor,
}

export type BillingServices = typeof billingServices
