import { Typography } from '@ps-ui/components'
import * as React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { ModalWindow } from 'src/components/molecules'
import { PostureElem, TPostureBody } from './PostureElem'

const bodyParts: TPostureBody[] = [
  'head',
  'cervical',
  'thoracic / lumbar',
  'pelvis',
  'hip',
  'knee',
  'foot',
]

export const Posture: React.FC<{ name: string }> = ({ name }) => {
  const { control, watch } = useFormContext()
  const [indexItemToDelete, setIndexItemToDelete] = React.useState<
    number | null
  >(null)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const { fields, append, insert, remove } = useFieldArray({
    name,
    control,
  })

  const addBodyPart = (bodyPart: TPostureBody) => {
    setIsModalOpen(false)
    const values = watch(name) as Record<string, string>[]
    const firstReverseIndex = values
      .reverse()
      .findIndex((elem) => elem.body === bodyPart)
    if (firstReverseIndex >= 0) {
      const lastIndex = values.length - firstReverseIndex
      insert(lastIndex, {
        body: bodyPart,
      })
    } else {
      append({
        body: bodyPart,
        side: '',
        type: '',
      })
    }
  }

  return (
    <div className="grid gap-3">
      {fields.map((field, index) => (
        <PostureElem
          key={field.id}
          name={`${name}.${index}`}
          onClickRemove={() => setIndexItemToDelete(index)}
          openModal={() => setIsConfirmationModalOpen(true)}
        />
      ))}
      <div className="py-5">
        <button
          className={
            'inline-flex justify-center items-center space-x-2 rounded-lg border font-semibold focus:outline-none px-3 py-2 leading-6 border-green-200 bg-green-200 text-green-700 hover:text-green-700 hover:bg-green-300 hover:border-green-300 focus:ring focus:ring-green-500 focus:ring-opacity-50 active:bg-green-200'
          }
          onClick={() => setIsModalOpen(true)}
        >
          + Add a measure
        </button>
      </div>

      <ModalWindow
        isOpen={isModalOpen}
        onSecondaryButtonClick={() => setIsModalOpen(false)}
        onCloseModal={() => setIsModalOpen(false)}
        modalSteps={[
          {
            header: 'Select a body part',
            disableMaxWidth: false,
            content: (
              <div>
                <ul className="grid gap-3">
                  {bodyParts.map((elem) => (
                    <li
                      key={elem}
                      className="cursor-pointer"
                      onClick={() => addBodyPart(elem)}
                    >
                      <Typography as="p" fontWeight="500">
                        <span className="capitalize">{elem}</span>
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ),
          },
        ]}
      />

      <ModalWindow
        isOpen={isConfirmationModalOpen}
        onCloseModal={() => {
          setIsConfirmationModalOpen(false)
          setIndexItemToDelete(null)
        }}
        onPrimaryButtonClick={() => {
          if (typeof indexItemToDelete === 'number') {
            remove(indexItemToDelete)
          }
          setIndexItemToDelete(null)
          setIsConfirmationModalOpen(false)
        }}
        textPrimaryButton="Confirm"
        textSecondaryButton="Cancel"
        onSecondaryButtonClick={() => {
          setIsConfirmationModalOpen(false)
          setIndexItemToDelete(null)
        }}
        modalSteps={[
          {
            header: 'Remove Measure',
            content: (
              <p className="text-center">
                Are you sure you want to remove this measure from patient
                evaluation?
              </p>
            ),
          },
        ]}
      />
    </div>
  )
}
