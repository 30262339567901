import { Button, Typography } from '@ps-ui/components'
import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { ModalWindow } from 'src/components/molecules'
import { BodyChartModal } from 'src/pages/ClinicalNotesv2/components/BodyChartModal'
import { ClinicalNoteElementTypes } from 'src/schema-types'
import { buttonStyles } from '../..'
import { getArrayDefaultValues, getLabelByType } from '../../utils'

interface MultiMeasureContainerProps<T> {
  name: string
  title?: string
  ChildComponent: React.ComponentType<any>
  enabledBodyParts?: string[]
  withBodyPart?: boolean
  type: ClinicalNoteElementTypes
  extraChildComponentProps?: T
  isExercise?: boolean
  canAddMore?: boolean
}

export function MultiMeasureContainer<ExtraChildComponentProps = null>({
  name,
  title,
  isExercise = false,
  ChildComponent,
  enabledBodyParts,
  withBodyPart = true,
  extraChildComponentProps,
  canAddMore = true,
  type,
}: MultiMeasureContainerProps<ExtraChildComponentProps>): React.ReactElement {
  const [isBodyModalOpen, setIsBodyModalOpen] = React.useState(false)
  const [isConfirmationModalOpen, setisConfirmationModalOpen] = React.useState(
    false
  )
  const [indexItemToDelete, setIndexItemToDelete] = React.useState<
    number | null
  >(null)

  const { fields, append, remove } = useFieldArray({
    name: `${name}.measures`,
    // control,
    shouldUnregister: false,
  })

  const addMeasure = (value?: string) => {
    const defaultValues = getArrayDefaultValues(type, value) as Partial<unknown>
    append(defaultValues)
  }

  const typeTitle = React.useMemo(() => {
    return getLabelByType(fields.length, type)
  }, [fields, type])

  const extraProps = extraChildComponentProps || {}

  return (
    <div className="flex flex-col">
      {title && (
        <div className="flex mb-8">
          <div className="flex flex-col">
            <Typography as="h3" fontWeight="700">
              {title}
            </Typography>
            {!!typeTitle && <Typography as="p">{typeTitle}</Typography>}
          </div>
          {!fields.length && withBodyPart && (
            <div className="ml-5">
              <Button
                type="button"
                className="rounded-lg"
                onClick={() => setIsBodyModalOpen(true)}
              >
                Edit
              </Button>
            </div>
          )}
        </div>
      )}
      {!!fields.length && (
        <div>
          {fields.map((field, index) => (
            <div
              className="flex justify-between border-b-2 py-8 align-top"
              key={field.id}
            >
              {React.isValidElement(<ChildComponent />) && (
                <div className="flex-1">
                  <ChildComponent
                    name={`${name}.measures.${index}`}
                    {...extraProps}
                    field={field}
                  />
                </div>
              )}
              {canAddMore && (
                <div
                  className={buttonStyles}
                  onClick={() => {
                    setIndexItemToDelete(index)
                    setisConfirmationModalOpen(true)
                  }}
                >
                  Remove Measure
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {canAddMore &&
        (!!fields.length || (!withBodyPart && !fields.length) || !title) && (
          <div className="py-5">
            <button
              className={
                'inline-flex justify-center items-center space-x-2 rounded-lg border font-semibold focus:outline-none px-3 py-2 leading-6 border-green-200 bg-green-200 text-green-700 hover:text-green-700 hover:bg-green-300 hover:border-green-300 focus:ring focus:ring-green-500 focus:ring-opacity-50 active:bg-green-200'
              }
              onClick={() => {
                if (withBodyPart) {
                  setIsBodyModalOpen(true)
                } else {
                  addMeasure()
                }
              }}
            >
              + {'Add '} {isExercise ? 'exercise' : 'measure'}
            </button>
          </div>
        )}

      {isBodyModalOpen && (
        <BodyChartModal
          isShow={isBodyModalOpen}
          setBodyPart={addMeasure}
          prevValue="Select a Body Part"
          enabledBodyParts={enabledBodyParts}
          hide={() => setIsBodyModalOpen(false)}
        />
      )}

      <ModalWindow
        isOpen={isConfirmationModalOpen}
        onCloseModal={() => {
          setisConfirmationModalOpen(false)
          setIndexItemToDelete(null)
        }}
        onPrimaryButtonClick={() => {
          if (typeof indexItemToDelete === 'number') {
            remove(indexItemToDelete)
          }
          setIndexItemToDelete(null)
          setisConfirmationModalOpen(false)
        }}
        textPrimaryButton="Confirm"
        textSecondaryButton="Cancel"
        onSecondaryButtonClick={() => {
          setisConfirmationModalOpen(false)
          setIndexItemToDelete(null)
        }}
        modalSteps={[
          {
            header: 'Remove Measure',
            content: (
              <p className="text-center">
                Are you sure you want to remove this measure from patient
                evaluation?
              </p>
            ),
          },
        ]}
      />
    </div>
  )
}
