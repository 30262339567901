import styled from 'styled-components/macro'

export const Content = styled.main`
  position: relative;

  /* padding: 35px 24px; */
  flex: 1;
  height: calc(100vh - 60px);
  overflow-y: auto;

  .divider {
    width: 100%;
    height: 0.5px;
    margin: 10px 0;
    background-color: #dadbde;
  }
`
