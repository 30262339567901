import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { PatientProfile } from 'src/schema-types'
import {
  Patient,
  PatientsList,
  SearchContainer,
  SearchSpinner,
  StyledInput,
} from './styles'
const debounce = require('lodash.debounce')

interface ISearchProps {
  patients: PatientProfile[]
  setSearch: (val: string) => void
  patientHandler: (val: PatientProfile) => void
  width?: number | string
  loading?: boolean
}

/**
 * @deprecated use `GenericSearchBox` at
 * `src/molecules/Search/GenericSearchBox.tsx` instead
 */
export const Search = memo(
  ({ patients, setSearch, patientHandler, width, loading }: ISearchProps) => {
    const [isActive, setIsActive] = useState(false)
    const listRef = useRef<HTMLUListElement>(null)

    const debauncedSetSearch = debounce((val: string) => {
      setSearch(val)
    }, 300)

    const handle = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value
      debauncedSetSearch(val)
    }

    const choosePatient = (value: PatientProfile) => {
      patientHandler(value)
      setIsActive(false)
      setSearch('')
    }

    const handleKeyDown = (
      e: React.KeyboardEvent<HTMLLIElement>,
      value: PatientProfile
    ) => {
      if (e.keyCode === 13) {
        choosePatient(value)
      }
    }

    useEffect(() => {
      setIsActive(!!patients.length)
    }, [patients])

    const handleFocus = useCallback(() => {
      setIsActive(!!patients.length)
    }, [setIsActive, patients])

    useEffect(() => {
      const handleClick = (e: MouseEvent) => {
        // @ts-ignore
        if (
          isActive &&
          listRef.current &&
          !listRef.current.contains(e.target)
        ) {
          setIsActive(false)
        }
      }
      document.addEventListener('click', handleClick)
      return () => {
        document.removeEventListener('click', handleClick)
      }
    }, [isActive])

    return (
      <SearchContainer
        style={{
          width: width ? width : 430,
        }}
      >
        <div
          style={{
            position: 'relative',
          }}
        >
          <StyledInput
            onChange={handle}
            onFocus={handleFocus}
            type="text"
            placeholder="Patient name"
          />
          {loading && <SearchSpinner color="#08B175" />}
        </div>
        <PatientsList ref={listRef} className={isActive ? 'active' : ''}>
          {patients.map((p) => (
            <Patient
              onClick={() => choosePatient(p)}
              key={p.id}
              tabIndex={0}
              onKeyDown={(e) => {
                handleKeyDown(e, p)
              }}
            >
              {`${p.lastName}, ${p.firstName}`}
            </Patient>
          ))}
        </PatientsList>
      </SearchContainer>
    )
  }
)
