import styled from 'styled-components/macro'

export const ScheduleButton = styled.button`
  display: inline-block;
  border: none;
  border-radius: 10px;
  background: #08b175 0% 0% no-repeat padding-box;
  font-size: 22px;
  color: #fff;
  padding: 12px 16px;
  margin-top: ${(p) => p.theme.spacing(3)};
  &:disabled {
    opacity: 0.6;
  }
`
