import { Option } from '@ps-ui/components'
import {
  AppointmentReasonTemplate,
  AppointmentType,
  Form,
  Maybe,
  PatientProfile,
  PracticeUser,
  Scalars,
} from 'src/schema-types'

export interface SchedulingPracticeState {
  practiceId: Scalars['ID'] | undefined
  reasons: AppointmentReasonTemplate[] | undefined
  forms: Form[] | null | undefined
  staff: Maybe<PracticeUser>[] | null | undefined
}

export interface CalendarAppointment {
  id: Scalars['ID']
  title: string
  start: Scalars['AWSDate']
  end: Scalars['AWSDate']
  allDay: boolean
  backgroundColor: string
  //Value should be 'background' to gray out boxes
  borderColor?: string
  display?: string
  doctors: PracticeUser[]
  patient: PatientProfile | null | undefined
  officeId: Scalars['ID'] | null
  formName: String | null
  formId: Scalars['ID'] | null
  appointmentTypeId: Scalars['ID'] | null
  appointmentTypeName: string
  formStatus: boolean | null
  isConfirmed: boolean
  description: string | null | undefined
}

export interface DateFilters {
  startDate: Scalars['AWSDate']
  endDate: Scalars['AWSDate']
}

export interface AppointmentTypeOption {
  label: string
  value: string
  color?: string
  type?: string
  internal?: string
}
export interface OptionWithType {
  label: string
  value: string
  type?: string
}

export interface AppointmentTypeData {
  activeAppointmentTypeOption: Option | undefined
  appointmentTypeOptions: AppointmentTypeOption[] | undefined
  activeAppointmentType: AppointmentType | null | undefined
  allAppointmentTypes: Maybe<AppointmentType>[] | null | undefined
}

export interface AppointmentForm {
  activeFormOption?: Option
  formOptions?: Option[] | undefined
  activeForm?: Form | null | undefined
  allForms?: Form[] | null | undefined
}

export enum OptionType {
  Doctor = 'DOCTOR',
  Office = 'OFFICE',
  AppointmentType = 'APPOINTMENT_TYPE',
  Form = 'FORM',
}

export enum ModalType {
  None = 'NONE',
  SelectAppointmentType = 'SELECT_APPOINTMENT_TYPE',
  CreateAppointment = 'CREATE_APPOINTMENT',
  EditAppointment = 'EDIT_APPOINTMENT',
  CancelAppointmentReason = 'CANCEL_APPOINTMENT_REASON',
  RescheduleAppointmentReason = 'RESCHEDULE_APPOINTMENT_REASON',
  ConfirmAppointmentCancellation = 'CONFIRM_APPOINTMENT_CANCELLATION',
  CreatePatient = 'CREATE_PATIENT',
  Congratulations = 'CONGRATULATIONS',
  InternalAppointmentModal = 'INTERNAL_APPOINTMENT_MODAL',
  RescheduleModeModal = 'RESCHEDULE_MODE_MODAL',
}

export enum PatientType {
  Adult = 'Adult',
  Minor = 'Minor',
}

export interface NewAppointmentTimes {
  start: Date
  timeString: string | undefined
  end: Date
}

export interface TextFieldValues {
  [id: string]: string
}
