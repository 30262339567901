import { gql } from 'apollo-boost'
import { QueryPracticeClinicalNoteTemplatesQuery } from 'src/operations-types'
import { apolloClient } from 'src/settings/apolloClient'

const QUERY_CN_TEMPLATES = gql`
  query queryPracticeClinicalNoteTemplates($id: ID!) {
    getPractice(id: $id) {
      id
      name
      clinicalNoteModularTemplates {
        id
        name
        isActive
        pages {
          id
          sections {
            id
            name
          }
        }
      }
      __typename
    }
  }
`

export const queryClinicalNoteTemplates = async (
  practiceId: string | undefined
) => {
  if (!practiceId) {
    return undefined
  }

  const res = await apolloClient.query<QueryPracticeClinicalNoteTemplatesQuery>(
    {
      query: QUERY_CN_TEMPLATES,
      fetchPolicy: 'network-only',
      variables: {
        id: practiceId,
      },
    }
  )

  if (res.errors?.length) {
    throw new Error(res.errors[0].message)
  }

  return res.data.getPractice?.clinicalNoteModularTemplates
}
