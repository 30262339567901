import { gql } from 'apollo-boost'
import { AssignedForm } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface DownloadAssignedFormResopnse {
  downloadedAssignedForm: AssignedForm
}

export const DOWNLOAD_ASSIGNED_FORM = gql`
  mutation downloadAssignedForm($id: ID!) {
    markAssignedFormAsDownloaded(id: $id) {
      id
    }
  }
`

export const downloadAssignedForm = async (id: string) => {
  const response = await apolloClient.mutate<DownloadAssignedFormResopnse>({
    mutation: DOWNLOAD_ASSIGNED_FORM,
    variables: {
      id,
    },
  })

  return response
}
