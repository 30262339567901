import { Typography } from '@ps-ui/components'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 24px auto 0 auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px;
`

export const SubTitle = styled(Typography)`
  color: #384364;
  font-size: 24px;
  margin-bottom: 32px;
`

export const Info = styled.div`
  font-size: 18px;
  color: #707891;
  margin-right: 24px;
  margin-bottom: 12px;
  text-transform: capitalize;
`

export const Label = styled.div`
  color: #384364;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 18px;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ActionLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 30px;
  user-select: none;
  cursor: pointer;
  margin-top: 12px;
  margin-bottom: 30px;
  input {
    margin-right: 10px;
  }
`
export const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid #eff1f4;
  height: 100px;
  padding: 12px;
  font-size: 16px;
`
