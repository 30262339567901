import clsx, { ClassValue } from 'clsx'
import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { handleSingleOrMultiValue } from 'src/pages/ClinicalNotesv2/utils'
import { classNames } from 'src/utils/classNames'

interface OptionsListProps {
  name: string
  cb?: (value: string) => void
  isMulti?: boolean
  options: {
    value: string
    label: string
  }[]
  isLabelVisible?: boolean
  hasSelectedBg?: boolean
  isFlex?: boolean
  breaks?: number[]
  className?: ClassValue
}

export const HorizontalOptionsList: React.FC<OptionsListProps> = ({
  name,
  className,
  options,
  isMulti = false,
  isLabelVisible = true,
  hasSelectedBg = true,
  isFlex = false,
  breaks,
}) => {
  const { control } = useFormContext()
  const checkActive = (value: string | string[], option: string) => {
    return Array.isArray(value) ? value.includes(option) : value === option
  }
  return (
    <div
      className={classNames(
        isFlex ? 'flex flex-wrap' : 'grid gap-6 grid-cols-3 w-full',
        className
      )}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => {
          let breaksCount = 0
          return (
            <>
              {options.map((option, index) => {
                let showBreak = breaks && index === breaks[breaksCount]
                showBreak && breaksCount++
                return (
                  <>
                    <label
                      key={option.value}
                      onClick={() =>
                        handleSingleOrMultiValue({
                          isMulti,
                          value,
                          option: option.value,
                          handleChange: onChange,
                        })
                      }
                      className={clsx(
                        'font-medium flex justify-start items-center space-x-2 h-[38px] cursor-pointer mr-4 px-2 py-1',
                        'active:bg-green-100 active:text-green-500 rounded',
                        !isFlex && 'w-full',
                        isFlex && 'mb-2',
                        checkActive(value, option.value) &&
                          hasSelectedBg &&
                          'bg-green-100'
                      )}
                    >
                      <div
                        className={clsx(
                          'w-3 h-3 rounded-sm border mr-3',
                          checkActive(value, option.value)
                            ? 'bg-primary border-primary'
                            : 'border-gray-500'
                        )}
                      ></div>
                      {isLabelVisible && option.label}
                    </label>
                    {showBreak && (
                      <div style={{ flexBasis: '100%', height: 0 }} />
                    )}
                  </>
                )
              })}
            </>
          )
        }}
      />
    </div>
  )
}
