import { gql } from 'apollo-boost'
import { SaveClinicalNoteAmendmentAnswerMutation } from 'src/operations-types'
import { ClinicalNoteAmendmentAnswerInput } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'
import { clinicalNoteModularAnswerFragment } from '../fragments/clinicalNoteModularAnswer'

export const SAVE_CLINICAL_NOTE_AMENDMENT_ANSWER = gql`
  mutation saveClinicalNoteAmendmentAnswer(
    $input: ClinicalNoteAmendmentAnswerInput!
  ) {
    saveClinicalNoteAmendmentAnswer(input: $input) {
      ...clinicalNoteAnswer
    }
  }
  ${clinicalNoteModularAnswerFragment}
`

export const saveClinicalNoteAmendmentAnswer = async (
  input: ClinicalNoteAmendmentAnswerInput
) => {
  const response =
    await apolloClient.mutate<SaveClinicalNoteAmendmentAnswerMutation>({
      mutation: SAVE_CLINICAL_NOTE_AMENDMENT_ANSWER,
      variables: {
        input,
      },
    })

  return response.data?.saveClinicalNoteAmendmentAnswer
}
