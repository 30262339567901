import { gql } from 'apollo-boost'
import { Mutation } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface SubmitClaimResponse {
  submitInsuranceClaim: Mutation['submitInsuranceClaim']
}

export const SUBMIT_INSURANCE_CLAIM = gql`
  mutation submitInsuranceClaim($id: ID!) {
    submitInsuranceClaim(id: $id) {
      id
      claimId
      status
      errors
    }
  }
`

export const submitInsuranceClaim = async (id: string) => {
  const result = await apolloClient.query<SubmitClaimResponse>({
    query: SUBMIT_INSURANCE_CLAIM,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      id,
    },
  })
  if (result.errors?.length) {
    throw new Error(result.errors[0].message)
  }
  return result.data.submitInsuranceClaim
}
