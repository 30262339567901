import { useQuery } from '@apollo/react-hooks'
import { Flex, PrintableSection, Typography } from '@ps-ui/components'
import parse from 'date-fns/parse'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PracticeLogo } from 'src/components/atoms/PracticeLogo'
import { PrintPageContainer } from 'src/components/atoms/PrintPageContainer'
import { PrintPageWrapper } from 'src/components/molecules/PrintPageWrapper'
import {
  GET_CLINICAL_NOTE,
  QueryClinicalNoteResponse,
} from 'src/gql/queries/queryClinicalNote'
import { usePrint } from 'src/hooks/print'
import { calcAge } from 'src/utils/formatDate'

export const PrintClinicalNote = () => {
  const { id } = useParams()
  const [hidePrintButton, setIsPrinting] = usePrint()

  const { data, loading } = useQuery<QueryClinicalNoteResponse>(
    GET_CLINICAL_NOTE,
    {
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    }
  )

  const noteData = useMemo(
    () => (!loading ? data?.getAppointment : undefined),
    [data, loading]
  )

  const patientAge = parse(
    noteData?.patientProfile?.dateOfBirth,
    'yyyy-MM-dd',
    new Date()
  )

  const appointmentDate = dayjs(noteData?.dateTime).format(
    'MMMM DD, YYYY - hh:mmA'
  )

  const practiceUser = `${noteData?.practiceUsers[0].firstName} ${noteData?.practiceUsers[0].lastName}`

  const practiceInfo = noteData?.practice

  return (
    <PrintPageWrapper
      isLoading={loading}
      loadingText="Generating Note..."
      hidePrintButton={hidePrintButton}
      buttonText="SAVE OR PRINT PDF"
      onButtonClick={() => {
        setIsPrinting(true)
      }}
    >
      <PrintPageContainer>
        <Flex
          justifyContent="space-between"
          width="100%"
          alignItems="flex-start"
        >
          <Flex>
            <PracticeLogo src={practiceInfo?.branding?.logo?.downloadUrl} />
            <Flex flexDirection="column">
              <Typography as="h2">{practiceInfo?.name}</Typography>
              <Typography as="h4">{practiceUser}</Typography>
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <Typography as="h2" fontWeight="700">
              Clinical Note
            </Typography>
            <Typography as="h4" fontWeight="700" margin="10px 0 0 0">
              Appointment Date and Time:
            </Typography>
            <Typography as="p">{appointmentDate}</Typography>
          </Flex>
        </Flex>

        <React.Fragment>
          <PrintableSection type="sub">Patient Information</PrintableSection>
          <>
            Patient Name: {noteData?.patientProfile?.firstName}{' '}
            {noteData?.patientProfile?.lastName}
            <br />
            {noteData?.patientProfile?.dateOfBirth && (
              <>
                Patient Date of Birth:{' '}
                {dayjs(noteData.patientProfile.dateOfBirth).format(
                  'MMM DD, YYYY'
                )}
                <br />
                {'('}
                {calcAge(patientAge)} years old{')'}
              </>
            )}
          </>
          <PrintableSection type="sub">Document</PrintableSection>
          {noteData?.clinicalNote?.body && (
            <div
              dangerouslySetInnerHTML={{
                __html: noteData?.clinicalNote.body,
              }}
            />
          )}
          {noteData?.clinicalNote?.amendmentComponents &&
            noteData.clinicalNote?.amendmentComponents.map((data) => {
              return (
                <>
                  <PrintableSection type="sub">
                    Amendment{' '}
                    {dayjs
                      .unix(data?.createdOn)
                      .format('MMMM DD, YYYY - hh:mmA')}
                    <br />
                    {practiceUser}
                  </PrintableSection>
                  <Typography as="p">{data?.body}</Typography>
                </>
              )
            })}
        </React.Fragment>
      </PrintPageContainer>
    </PrintPageWrapper>
  )
}
