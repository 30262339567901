import { gql } from 'apollo-boost'
import { PracticeUser } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface queryPracticeDataResponse {
  whoAmI: PracticeUser
}

export const QUERY_ALL_DOCTORS = gql`
  {
    whoAmI {
      __typename
      ... on PracticeUser {
        practice {
          id
          appointmentTypes {
            id
            duration
            name
            isActive
          }
          locations {
            id
            name
          }
          forms {
            id
            name
          }
          users(staffType: MEDICAL) {
            firstName
            lastName
            id
            aithozProviderNPI
            appointmentTypes {
              id
              name
              duration
            }
            appointments {
              entities {
                ... on Appointment {
                  id
                  dateTime
                  appointmentType {
                    duration
                  }
                }
              }
            }
            schedule {
              practiceLocation {
                id
              }
              schedulableHours {
                monday {
                  start
                  end
                }
                tuesday {
                  start
                  end
                }
                wednesday {
                  start
                  end
                }
                thursday {
                  start
                  end
                }
                friday {
                  start
                  end
                }
                saturday {
                  start
                  end
                }
                sunday {
                  start
                  end
                }
              }
            }
          }
        }
      }
    }
  }
`

export const queryPracticeData = async (): Promise<any> => {
  const res = await apolloClient.query<queryPracticeDataResponse>({
    query: QUERY_ALL_DOCTORS,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
  if (res.errors?.length) {
    throw new Error(res.errors[0].message)
  }
  const practice = res?.data?.whoAmI?.practice
  const appointmentTypes = practice?.appointmentTypes?.filter(
    (type) => type?.isActive
  )
  const doctors = practice?.users
  const forms = practice?.forms
  const practiceId = practice.id
  const locations = practice.locations
  const chosenLocationId = locations && locations[0]?.id
  return {
    appointmentTypes,
    doctors,
    forms,
    practiceId,
    locations,
    chosenLocationId,
  }
}
