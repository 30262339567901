'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.Label = void 0

var _styledComponents = _interopRequireDefault(require('styled-components'))

var _templateObject

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0)
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }))
}

var Label = _styledComponents['default'].label(
  _templateObject ||
    (_templateObject = _taggedTemplateLiteral([
      '\n  display: block;\n  font-weight: 500;\n  opacity: ',
      ';\n  margin-bottom: ',
      ';\n  color: ',
      ';\n  ',
      '\n  ',
      '\n',
    ])),
  function (props) {
    return props.disabled ? 0.6 : 1
  },
  function (props) {
    return props.theme.spacing(1)
  },
  function (props) {
    return props.error ? props.theme.colors.error : props.theme.colors.text
  },
  function (props) {
    return (
      props.required &&
      "  \n    &::before {\n      content: '* ';\n      color: ".concat(
        props.theme.colors.error,
        ';\n    }\n  '
      )
    )
  },
  '.sub-label { font-weight: 400 }'
)

exports.Label = Label
