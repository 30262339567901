import React, { FunctionComponent } from 'react'
import { FaFolderOpen } from 'react-icons/fa'
import { Empty } from './styles'

interface Props {
  title?: string
}

export const EmptyBlock: FunctionComponent<Props> = ({
  title = 'There is no data',
  ...rest
}) => {
  return (
    <Empty {...rest}>
      <FaFolderOpen size="2em" />
      <span>{title}</span>
    </Empty>
  )
}
