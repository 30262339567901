import { Button } from '@ps-ui/components'
import clsx from 'clsx'
import React from 'react'
import { CloseButton } from 'src/components/atoms/CloseButton'
import { NotificationModalStep } from '../NotificationModalStep'
import { StyledModalStep } from './styles'

type ModalStepProps = {
  header: string
  subheader: string
  textPrimaryButton: string
  textSecondaryButton: string
  onPrimaryButtonClick?: () => void
  onSecondaryButtonClick: () => void
  onClose: () => void
  type?: 'notification'
  isLoading?: boolean
  isDisabled?: boolean
  disableMaxWidth?: boolean
}

export const ModalStep: React.FC<ModalStepProps> = ({
  header,
  subheader,
  isLoading = false,
  isDisabled = false,
  textPrimaryButton,
  textSecondaryButton,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onClose,
  type,
  children,
  disableMaxWidth,
}) => {
  if (type === 'notification') {
    return (
      <NotificationModalStep
        header={header}
        onClose={onClose}
        textPrimaryButton={textPrimaryButton}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        {children}
      </NotificationModalStep>
    )
  }

  return (
    <StyledModalStep
      className={clsx(
        'flex flex-col rounded shadow-sm bg-white w-full max-w-md mx-auto',
        disableMaxWidth && 'max-w-none'
      )}
      role="document"
    >
      <div className="py-4 px-5 lg:px-6 w-full bg-gray-50 flex flex-col justify-start sticky z-50 top-0 rounded-t">
        <div className="flex justify-between">
          <h2 id="modal-header" className="font-medium text-xl">
            {header}
          </h2>
          <div>
            <CloseButton onClick={onClose} />
          </div>
        </div>

        <h3 id="modal-subheader" className="font-medium text-gray-600">
          {subheader}
        </h3>
      </div>
      <div className="p-5 lg:p-6 flex-grow w-full text-gray-600">
        {children}
      </div>
      <div
        id="modal-footer"
        className="py-4 px-5 lg:px-6 w-full bg-gray-50 text-right space-x-1 flex justify-end items-center rounded-b"
      >
        <Button type="button" onClick={onSecondaryButtonClick}>
          {textSecondaryButton}
        </Button>
        {onPrimaryButtonClick ? (
          <Button
            id="primary-btn"
            variant="filled"
            color="primary"
            onClick={onPrimaryButtonClick}
            loading={isLoading}
            disabled={isDisabled}
            type="button"
          >
            {textPrimaryButton}
          </Button>
        ) : null}
      </div>
    </StyledModalStep>
  )
}
