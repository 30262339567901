import { useMutation, useQuery } from '@apollo/react-hooks'
import { Option } from '@ps-ui/components'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { useFormState } from 'react-use-form-state'
import { Dimmer, Loader } from 'semantic-ui-react'
import { PageHeading } from 'src/components/organisms/PageHeading'
import { PagePadding } from 'src/components/templates/PagePadding'
import {
  CreateNewClaimResponse,
  CREATE_INSURANCE_CLAIM,
} from 'src/gql/mutations/createNewClaim'
import {
  SubmitClaimResponse,
  SUBMIT_INSURANCE_CLAIM,
} from 'src/gql/mutations/submitInsuranceClaim'
import {
  UpdateExistingClaimResponse,
  UPDATE_INSURANCE_CLAIM,
} from 'src/gql/mutations/updateExistingClaim'
import {
  UpdateReferringDoctorResponse,
  UPDATE_REFERRING_DOCTOR,
} from 'src/gql/mutations/updateReferringDoctor'
import {
  GET_CLAIM_DATA,
  QueryClaimDataResponse,
} from 'src/gql/queries/queryClaimDataByAppointmentId'
import {
  GET_INSURANCE_CLAIM,
  QueryExistingClaimResponse,
} from 'src/gql/queries/queryClaimDataByClaimId'
import {
  QueryPracticeBillingCodesResponse,
  QUERY_PRACTICE_BILLING_CODES,
} from 'src/gql/queries/queryPracticeBillingCodes'
import { ElementTypes, InsuranceClaimCharge, Scalars } from 'src/schema-types'
import { useStore } from 'src/stores/store'
import {
  formatDateAndTimeAWS,
  formatDateFromAWS,
  serviceDateFormat,
} from 'src/utils/formatDate'
import { formHasEmptyValues } from 'src/utils/formValidators'
import { BillingCodes } from '../../components/molecules/BillingCodes'
import { FinalizedBillingCodes } from '../../components/molecules/FinalizedBillingCodes'
import { ClaimStatus } from './components/ClaimStatus'
import { InsuranceDisplay } from './components/InsuranceDisplay'

export interface TextFieldValues {
  [id: string]: string
}

export interface ListedBillingCodes {
  id: Scalars['ID']
  code: string
  description: string | undefined | null
  type: string
}

export interface ChargeCodes {
  procedureCode: ListedBillingCodes[] | undefined
  diagnosisCodes: ListedBillingCodes[] | undefined
  dateOfService: Scalars['AWSDate']
  chargeFee: string
  units: number
  modifiers?: string[]
}

export interface formattedChargeList {
  procedureCode: string | undefined
  diagnosisCodes: string[] | undefined
  dateOfService: Scalars['AWSDate']
  chargeFee: string
  modifiers?: string[]
}

export const CreateClaim: React.FC = () => {
  const [formState, { text }] = useFormState<TextFieldValues>()
  const [genderSelection, setGenderSelection] = useState<Option>({
    // Default to most common value
    value: 'NOT_SPECIFIED',
    label: 'Not Specified',
  })
  const [locationSelection, setLocationSelection] = useState<Option>({
    // Default to most common value
    value: 'OFFICE',
    label: 'Office',
  })
  const [relationshipSelection, setRelationshipSelection] = useState<Option>({
    // Default to most common value
    value: 'SELF',
    label: 'Self',
  })
  const [referringDoctorsOptions, setReferringDoctorsOptions] =
    useState<Option[]>()
  const [selectedReferringDoc, setSelectedReferringDoc] = useState<Option>({
    label: '',
    value: '',
  })
  const { addToast } = useToasts()
  const dummy = useRef<null | HTMLElement>(null)
  const { profileStore, patientStore } = useStore()
  const [patientId, setPatientId] = useState<Scalars['ID']>()
  const [chargesList, setChargesList] = useState<ChargeCodes[]>([])
  const [cptSelected, setCptSelected] = useState<ListedBillingCodes[]>([])
  const [icdSelected, setIcdSelected] = useState<ListedBillingCodes[]>([])
  const [formattedCharges, setFormattedCharges] = useState<
    formattedChargeList[]
  >([])
  const [claimSubmittedStatus, setClaimSubmittedStatus] =
    useState<boolean>(false)
  const [claimExistStatus, setClaimExistStatus] = useState<boolean>(false)
  const [selectedUnit, setSelectedUnit] = useState<Option>({
    // Default to 1
    value: '1',
    label: '1',
  })
  const [selectedModifier, setSelectedModifier] = useState<
    Option[] | undefined
  >()

  const { id } = useParams()

  const [createNewClaim, { loading: newClaimLoading }] =
    useMutation<CreateNewClaimResponse>(CREATE_INSURANCE_CLAIM, {
      onCompleted: (data) => {
        //clears form on submission
        const returned = data.createInsuranceClaim
        formState.reset()
        formState.setField('submitted', returned.status)
        formState.setField('returnedClaimID', returned.claimId)
        setCptSelected([])
        setIcdSelected([])
        setSelectedUnit({ value: '1', label: '1' })
        setRelationshipSelection({ value: '', label: '' })
        setLocationSelection({ value: '', label: '' })
        setGenderSelection({ value: '', label: '' })
      },
      onError: (err) => {
        formState.setField('submitted', 'Failed, see error(s) in console')
        if (formState.values.returnedID) {
          formState.clearField('returnedID')
          formState.clearField('returnedClaimID')
          formState.clearField('returnedStatus')
        }
        console.error(err)
        addToast((err as Error).message, {
          appearance: 'error',
        })
      },
    })
  const [updateInsuranceClaim, { loading: claimUpdateLoading }] =
    useMutation<UpdateExistingClaimResponse>(UPDATE_INSURANCE_CLAIM, {
      onCompleted: () => {
        setFormattedCharges([])
        setCptSelected([])
        setIcdSelected([])
        setChargesList([])
      },
      onError: (err) => {
        console.error(err)
        addToast((err as Error).message, {
          appearance: 'error',
        })
      },
    })

  const [updateReferringDoctor] = useMutation<UpdateReferringDoctorResponse>(
    UPDATE_REFERRING_DOCTOR,
    {
      onError: (err) => {
        console.error(err)
        addToast((err as Error).message, {
          appearance: 'error',
        })
      },
    }
  )

  const [submitClaim, { loading: submitClaimLoading, error: submitError }] =
    useMutation<SubmitClaimResponse>(SUBMIT_INSURANCE_CLAIM, {
      onCompleted: (data) => {
        const returned = data.submitInsuranceClaim
        formState.setField('submitted', returned.status)
        formState.setField('returnedClaimID', returned.claimId)
        setClaimExistStatus(true)
        addToast('Submitted', {
          appearance: 'success',
        })
      },
      onError: (err) => {
        if (formState.values.returnedID) {
          formState.clearField('returnedID')
          formState.clearField('returnedClaimID')
          formState.clearField('returnedStatus')
        }
        console.error(err)
        addToast((err as Error).message, {
          appearance: 'error',
        })
      },
    })
  //Pulls the service date and patient info
  const { data: claimData, loading: claimDataLoading } =
    useQuery<QueryClaimDataResponse>(GET_CLAIM_DATA, {
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    })

  //Pulls data if claim has already been created on a clinical note
  const { data: createdClaimData, loading: createdClaimDataLoading } =
    useQuery<QueryExistingClaimResponse>(GET_INSURANCE_CLAIM, {
      fetchPolicy: 'network-only',
      variables: {
        id: claimData?.getAppointment?.insuranceClaim?.id,
      },
    })

  //Pulls info needed for the billing codes and PracticeNPI
  const { data: billingCodes } = useQuery<QueryPracticeBillingCodesResponse>(
    QUERY_PRACTICE_BILLING_CODES,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: profileStore.profile && profileStore.profile.practice.id,
      },
    }
  )

  //Pulls the info needed for the patient portion
  const [profilePictureUrl, setProfilePictureUrl] = useState(
    claimData?.getAppointment.patientProfile?.profilePicture
  )

  const formatChargesList = (charges: ChargeCodes[]) => {
    charges.forEach((data) => {
      //Format data to a readable string
      const procedureString =
        data.procedureCode && `${data.procedureCode[0].code}`

      const diagnosisCodesString =
        data.diagnosisCodes &&
        data.diagnosisCodes.map((diagCode) => {
          return `${diagCode.code}`
        })

      const modifiers = data.modifiers

      let currentList = formattedCharges
      const newItem = [
        {
          procedureCode: procedureString,
          diagnosisCodes: diagnosisCodesString,
          dateOfService: serviceDate,
          chargeFee: '',
          units: Number(data.units),
          modifiers,
        },
      ]
      currentList.push(...newItem)
      setFormattedCharges([...currentList])
    })
  }
  const billCodes = billingCodes?.getPractice?.billingCodes?.map((data) => {
    return {
      code: data.code,
      description: data.description,
      id: data.id,
      type: data.type,
    }
  })

  //Extracts existing claim data if it exists
  const existingClaim = createdClaimData && createdClaimData.getInsuranceClaim
  const scrubExistingCptCodes = (data: InsuranceClaimCharge[]) => {
    //Helper function
    const getCode = (fullCode: string) =>
      fullCode.split('-')[0].replace(/\s/g, '')
    data?.forEach((info) => {
      const procedureCode = billCodes
        ?.filter((type) => type.type === 'CPT')
        .filter((data) => data.code === getCode(info.procedureCode))

      const existingIcd = info.diagnosisCodes.map((data) => getCode(data))

      const diagnosisCodes = billCodes?.filter((billCode) =>
        existingIcd.includes(billCode.code)
      )
      let currentList = chargesList
      const scrubbedCharge = [
        {
          procedureCode,
          diagnosisCodes,
          dateOfService: info.dateOfService,
          chargeFee: info.chargeFee,
          units: info.units,
          modifiers: info.modifiers,
        },
      ] as ChargeCodes[]
      currentList.push(...scrubbedCharge)
      setChargesList(currentList)
    })
  }

  const activeReferringDoctors =
    claimData?.getAppointment?.practice?.referringDoctors?.filter(
      (doc) => doc.isActive === true
    )
  const patientReferringDoc =
    claimData?.getAppointment.patientProfile?.referringDoctor

  const serviceDate = serviceDateFormat(claimData?.getAppointment.dateTime)

  useEffect(() => {
    //Set multiple items/states on page load
    setPatientId(claimData?.getAppointment.patientProfile?.id)
    if (patientId) {
      setProfilePictureUrl(
        claimData?.getAppointment.patientProfile?.profilePicture
      )
      patientStore.fetchById(patientId)
    }

    if (activeReferringDoctors) {
      let refDocs: Option[] = []
      activeReferringDoctors.map((doc) =>
        refDocs.push({
          label: doc.name,
          value: doc.NPI.toString(),
        })
      )
      setReferringDoctorsOptions(refDocs)
    }
    if (patientReferringDoc) {
      setSelectedReferringDoc({
        label: patientReferringDoc.name,
        value: patientReferringDoc.NPI.toString(),
      })
    }

    // eslint-disable-next-line
  }, [patientId, claimData, formattedCharges])

  // Pull information from the patientStore
  useEffect(() => {
    if (patientStore.getFieldsByElementType(ElementTypes.Insurance)) {
      patientStore
        ?.getFieldsByElementType(ElementTypes.Insurance)
        .find((ins) => ins?.fieldTemplate.id === 'primary-insurance')
        ?.valueInsurance?.forEach((d) => {
          formState.setField('firstName', d.subscriberFirstName)

          formState.setField('lastName', d.subscriber)

          formState.setField('dateOfBirth', formatDateFromAWS(d.subscriberDob))

          formState.setField('insuranceName', d.insuranceCompany)

          d.insurancePolicyNumber &&
            formState.setField('policyNumber', d.insurancePolicyNumber)
          d.subscriberRelationship &&
            setRelationshipSelection({
              value: d.subscriberRelationship,
              label: d.subscriberRelationship
                .split('_')
                .map((s) => s.charAt(0) + s.substring(1).toLowerCase())
                .join(' '),
            })
        })
    }

    if (
      patientStore &&
      patientStore.getFieldsByElementType(ElementTypes.Address)
    ) {
      patientStore
        ?.getFieldsByElementType(ElementTypes.Address)
        .forEach((data) =>
          data?.valueAddress?.forEach((d) => {
            formState.setField('street1', d.street1)
            formState.setField('city', d.city)
            formState.setField('state', d.state)
            formState.setField('zip', d.zip)
          })
        )
    }
    if (
      patientStore &&
      patientStore.getFieldsByElementType('Dropdown')[0]?.value
    ) {
      patientStore.getFieldsByElementType('Dropdown')[0]?.value?.forEach((d) =>
        setGenderSelection({
          value: `${d?.toUpperCase()}`,
          label: `${d}`,
        })
      )
    }
    // eslint-disable-next-line
  }, [patientStore, patientId, claimData])

  // will replace data with existing claim values if they exist on page load
  useEffect(() => {
    if (existingClaim && existingClaim?.subscriber) {
      existingClaim.subscriber.firstName &&
        formState.setField('firstName', existingClaim.subscriber.firstName)
      existingClaim.subscriber.lastName &&
        formState.setField('lastName', existingClaim.subscriber.lastName)
      existingClaim.subscriber.dateOfBirth &&
        formState.setField(
          'dateOfBirth',
          formatDateFromAWS(existingClaim.subscriber.dateOfBirth)
        )
      existingClaim.insuranceName &&
        formState.setField('insuranceName', existingClaim.insuranceName)
      existingClaim.insurancePolicyNumber &&
        formState.setField('policyNumber', existingClaim.insurancePolicyNumber)
    }

    if (existingClaim && existingClaim.patientExtendedInformation) {
      const address = existingClaim?.patientExtendedInformation?.address
      setGenderSelection({
        value: existingClaim.patientExtendedInformation.genderCode,
        label:
          existingClaim.patientExtendedInformation.genderCode.charAt(0) +
          existingClaim.patientExtendedInformation.genderCode
            .substring(1)
            .toLowerCase(),
      })
      formState.setField('street1', address.street1)
      formState.setField('city', address.city)
      formState.setField('state', address.state)
      formState.setField('zip', address.zip)
    }
    if (existingClaim?.status && existingClaim?.status !== 'UNSUBMITTED') {
      setClaimSubmittedStatus(true)
      formState.setField('submitted', existingClaim.status)
      formState.setField('returnedClaimID', existingClaim.claimId)
    }
    if (existingClaim?.status && existingClaim?.status === 'UNSUBMITTED') {
      setClaimExistStatus(true)
      formState.setField('submitted', existingClaim.status)
      formState.setField('returnedClaimID', existingClaim.claimId)
    }
    // eslint-disable-next-line
  }, [existingClaim])

  useEffect(() => {
    setChargesList([])
    if (existingClaim) {
      scrubExistingCptCodes(existingClaim && existingClaim.charges)
    }
    //eslint-disable-next-line
  }, [submitError, existingClaim])

  const handleClaimSubmit = async () => {
    await handleUpdateClaim()
    await submitClaim({
      variables: {
        id: existingClaim && existingClaim.id,
      },
    })
  }
  const handleCreateClaimClick = async () => {
    const inputData = formState.values
    formatChargesList(chargesList)
    await createNewClaim({
      variables: {
        updatedField: {
          appointmentId: claimData?.getAppointment.id,
          subscriber: {
            firstName: inputData?.firstName,
            lastName: inputData?.lastName,
            dateOfBirth: formatDateAndTimeAWS(inputData?.dateOfBirth),
            address: {
              street1: inputData.street1,
              city: inputData.city,
              state: inputData.state,
              zip: inputData.zip,
            },
            genderCode: genderSelection.value,
          },
          patientExtendedInformation: {
            genderCode: genderSelection.value,
            address: {
              street1: inputData.street1,
              city: inputData.city,
              state: inputData.state,
              zip: inputData.zip,
            },
            relationshipToSubscriber: relationshipSelection.value,
          },
          insuranceName: inputData?.insuranceName,
          insurancePolicyNumber: inputData?.policyNumber,
          serviceLocationType: locationSelection.value,
          charges: formattedCharges,
        },
      },
    })
  }

  const handleUpdateClaim = async () => {
    const inputData = formState.values
    formatChargesList(chargesList)
    const patientProfile = claimData?.getAppointment.patientProfile
    if (selectedReferringDoc) {
      await updateReferringDoctor({
        variables: {
          id: patientProfile?.id,
          updatedFields: {
            referringDoctorNpi: selectedReferringDoc?.value,
          },
        },
      })
    }
    await updateInsuranceClaim({
      variables: {
        id: claimData?.getAppointment?.insuranceClaim?.id,
        updatedField: {
          subscriber: {
            firstName: inputData?.firstName,
            lastName: inputData?.lastName,
            dateOfBirth: formatDateAndTimeAWS(inputData?.dateOfBirth),
            address: {
              street1: inputData.street1,
              city: inputData.city,
              state: inputData.state,
              zip: inputData.zip,
            },
            genderCode: genderSelection.value,
          },
          patientExtendedInformation: {
            genderCode: genderSelection.value,
            address: {
              street1: inputData.street1,
              city: inputData.city,
              state: inputData.state,
              zip: inputData.zip,
            },
            relationshipToSubscriber: relationshipSelection.value,
          },
          insuranceName: inputData?.insuranceName,
          insurancePolicyNumber: inputData?.policyNumber,
          serviceLocationType: locationSelection.value,
          charges: formattedCharges,
        },
      },
    })
  }

  const formStateNames = [
    'firstName',
    'lastName',
    'dateOfBirth',
    'insuranceName',
    'policyNumber',
    'street1',
    'city',
    'state',
    'zip',
  ]

  const isLoading =
    claimDataLoading ||
    createdClaimDataLoading ||
    claimUpdateLoading ||
    newClaimLoading ||
    submitClaimLoading

  const buttonDisabled =
    !genderSelection ||
    !selectedReferringDoc ||
    !genderSelection ||
    !locationSelection ||
    chargesList.length < 1 ||
    formHasEmptyValues(formState.values, formStateNames)

  const patientProfile = claimData?.getAppointment.patientProfile

  return (
    <PagePadding>
      {isLoading && (
        <Dimmer active page={true}>
          <Loader size="large" active>
            Loading data...
          </Loader>
        </Dimmer>
      )}
      {newClaimLoading && (
        <Dimmer active>
          <Loader size="large" active>
            Sending Claim...
          </Loader>
        </Dimmer>
      )}
      <PageHeading
        title="Insurance Claims"
        backButton={true}
        options={
          claimSubmittedStatus
            ? undefined
            : [
                {
                  title: `${
                    claimExistStatus ? 'Send to billing' : 'Create Claim'
                  }`,
                  onClick: claimExistStatus
                    ? handleClaimSubmit
                    : handleCreateClaimClick,
                  disabled: buttonDisabled,
                },
              ]
        }
      />
      <ClaimStatus
        formState={formState}
        patientProfile={patientProfile}
        profilePictureUrl={profilePictureUrl}
        setProfilePictureUrl={setProfilePictureUrl}
        text={text}
        serviceDate={serviceDate}
        claimData={claimData}
      />
      <InsuranceDisplay
        formState={formState}
        text={text}
        loading={isLoading}
        claimStatus={claimSubmittedStatus}
        genderSelection={genderSelection}
        setGenderSelection={setGenderSelection}
        relationshipSelection={relationshipSelection}
        setRelationshipSelection={setRelationshipSelection}
        locationSelection={locationSelection}
        setLocationSelection={setLocationSelection}
        setSelectedReferringDoc={setSelectedReferringDoc}
        selectedReferringDoc={selectedReferringDoc}
        referringDoctorsOptions={referringDoctorsOptions}
      />

      {!claimSubmittedStatus ? (
        <BillingCodes
          cptSelected={cptSelected}
          icdSelected={icdSelected}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          selectedModifier={selectedModifier}
          setSelectedModifier={setSelectedModifier}
          dummy={dummy}
          billingCodes={billingCodes}
          setCptSelected={setCptSelected}
          setIcdSelected={setIcdSelected}
          chargesList={chargesList}
          setChargesList={setChargesList}
          serviceDate={serviceDate}
        />
      ) : (
        <FinalizedBillingCodes
          chargesList={existingClaim && existingClaim?.charges && chargesList}
        />
      )}
    </PagePadding>
  )
}
