'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.getDropdownValue = exports.getDropdownOptions = void 0

var _schemaTypes = require('src/schema-types')

var _compoundFieldsOptions = require('./compoundFieldsOptions.js')

var _statesUs = require('./statesUs.js')

function _slicedToArray(arr, i) {
  return (
    _arrayWithHoles(arr) ||
    _iterableToArrayLimit(arr, i) ||
    _unsupportedIterableToArray(arr, i) ||
    _nonIterableRest()
  )
}

function _nonIterableRest() {
  throw new TypeError(
    'Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.'
  )
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return
  if (typeof o === 'string') return _arrayLikeToArray(o, minLen)
  var n = Object.prototype.toString.call(o).slice(8, -1)
  if (n === 'Object' && o.constructor) n = o.constructor.name
  if (n === 'Map' || n === 'Set') return Array.from(o)
  if (n === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen)
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length
  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i]
  }
  return arr2
}

function _iterableToArrayLimit(arr, i) {
  var _i =
    arr == null
      ? null
      : (typeof Symbol !== 'undefined' && arr[Symbol.iterator]) || arr['@@iterator']
  if (_i == null) return
  var _arr = []
  var _n = true
  var _d = false
  var _s, _e
  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value)
      if (i && _arr.length === i) break
    }
  } catch (err) {
    _d = true
    _e = err
  } finally {
    try {
      if (!_n && _i['return'] != null) _i['return']()
    } finally {
      if (_d) throw _e
    }
  }
  return _arr
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr
}

/**
 * Use to get the options that would be displayed in the dropdown input.
 * @param options
 */
var getDropdownOptions = function getDropdownOptions(options) {
  if (options) {
    return options.map(function (opt) {
      try {
        // Check if the opction string can be parse to have access to a label and a value
        var optionData = JSON.parse(opt) // Check if opt is a number

        if (!isNaN(Number(opt))) {
          // just returns opt for label and value
          return {
            label: opt,
            value: opt,
          }
        }

        return {
          label: optionData.label,
          value: optionData.value,
        }
      } catch (_err) {
        // If it cant be parse them both label and value would be the same string.
        return {
          label: opt,
          value: opt,
        }
      }
    })
  }

  return []
}
/**
 * Use it to get the correct label for the selected value in the dropdown
 * @param formValue
 */

exports.getDropdownOptions = getDropdownOptions

var getDropdownValue = function getDropdownValue(elementType, formValue) {
  var value

  if (formValue) {
    if (
      elementType === _schemaTypes.ElementTypes.Medication &&
      formValue in _compoundFieldsOptions.MedicationFrequency
    ) {
      var label = formValue
      value = {
        label: _compoundFieldsOptions.MedicationFrequency[label],
        value: formValue,
      }
    } else if (
      elementType === _schemaTypes.ElementTypes.Medication &&
      formValue in _compoundFieldsOptions.MedicationStatus
    ) {
      var _label = formValue
      value = {
        label: _compoundFieldsOptions.MedicationStatus[_label],
        value: formValue,
      }
    } else if (elementType === _schemaTypes.ElementTypes.CareTeamMember) {
      var _label2 = _compoundFieldsOptions.CareTeamMemberStatus[formValue]
      value = {
        label: _label2,
        value: formValue,
      }
    } else if (
      elementType === _schemaTypes.ElementTypes.Address ||
      elementType === _schemaTypes.ElementTypes.State
    ) {
      var _Object$entries$find

      var _label3 =
        ((_Object$entries$find = Object.entries(_statesUs.StatesUS).find(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
            value = _ref2[1]

          return value === formValue
        })) === null || _Object$entries$find === void 0
          ? void 0
          : _Object$entries$find[0]) || ''

      value = {
        label: _label3,
        value: formValue,
      }
    } else {
      value = {
        label: formValue,
        value: formValue,
      }
    }
  }

  return value
}

exports.getDropdownValue = getDropdownValue
