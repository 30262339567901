import React from 'react'
import { formatDate } from 'src/utils/formatDate'

type SignatureAnswerProps = {
  signatureAnswer: string
}

export const SignatureAnswer: React.FC<SignatureAnswerProps> = ({
  signatureAnswer,
}) => {
  let [, signatureDate, signature] = signatureAnswer.split('|')
  signatureDate = formatDate(signatureDate)

  return (
    <div>
      <p>
        <span className="font-semibold">Date: </span>
        {signatureDate}
      </p>
      <p>
        <span className="font-semibold">Signature: </span>
        {signature}
      </p>
    </div>
  )
}
