import { gql } from 'apollo-boost'
import { Query } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface RefreshTokenResponse {
  getNewToken: Query['getNewToken']
}

export const QUERY_NEW_TOKEN = gql`
  query getNewToken($input: String!) {
    getNewToken(input: $input) {
      AccessToken
      ExpiresIn
      RefreshToken
    }
  }
`
export const queryToken = async (refreshToken: string) => {
  const res = await apolloClient.query<RefreshTokenResponse>({
    query: QUERY_NEW_TOKEN,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    variables: {
      input: refreshToken,
    },
  })

  if (res.errors?.length) {
    throw new Error(res.errors[0].message)
  }
  return res.data.getNewToken
}
