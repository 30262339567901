import { LoaderContainer } from '@ps-ui/components'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { ClinicalNotesLayout } from 'src/components/templates/ClinicalNotesLayout'
import { useShowModal } from 'src/hooks/showModal'
import { AddModuleModal } from 'src/pages/ClinicalNotesv2/components/AddModuleModal'
import { ClinicalNotesForm } from 'src/pages/ClinicalNotesv2/components/Form'
import { RenderFields } from 'src/pages/ClinicalNotesv2/components/RenderFields'
import { SavingIndicator } from 'src/pages/ClinicalNotesv2/components/SavingIndicator'
import { useStore } from 'src/stores/store'
import { Billing } from '../Billing'
import { ChangeTemplateModal } from '../Patient/components/ChangeTemplateModal'
import { CopyClinicalNoteModal } from '../Patient/components/CopyClinicalNoteModal'
import { AddAmendmentModal } from './components/AddAmendmentModal'
import { ModuleContainer } from './components/ModuleContainer'
import { Review } from './components/Review'
import { SpecialModules } from './components/SpecialModules'

export const buttonStyles =
  'border border-gray-400 hover:border-primary p-3 rounded-lg w-max h-full cursor-pointer hover:text-primary bg-white'

export const ClinicalNotesv2: React.FC = observer(() => {
  const { addToast } = useToasts()

  const {
    clinicalNotesStore: {
      fetching,
      isClinicalNoteOwner,
      error,
      defaultValues,
      currentPageId,
      visibleModulesByPage,
      savingAnswer,
      hiddenModulesByPage,
      updateVisibility,
      fetchClinicalNote,
      cleanError,
    },
  } = useStore()

  const { clinicalNoteId } = useParams<{ clinicalNoteId?: string }>()
  const [showCopyModal, toggleCopyModal] = useShowModal(false)

  const [showAddAmendModal, toggleAddAmendModal] = useShowModal(false)
  const [showChangeTemplateModal, toggleShowChangeTemplateModal] = useShowModal(
    false
  )

  const [
    isAddingModalFieldGroupOpen,
    setIsAddingModalFieldGroupOpen,
  ] = React.useState(false)

  const onSubmitForm = (data: Record<string, string>) => {
    console.log('data', data)
  }

  useEffect(() => {
    if (!fetching && error) {
      addToast(`Cannot load Clinical Note: ${error}`, {
        appearance: 'error',
        onDismiss: cleanError,
      })
    }
  }, [error, fetching, addToast, cleanError])

  useEffect(() => {
    if (clinicalNoteId) {
      fetchClinicalNote(clinicalNoteId)
    }
  }, [fetchClinicalNote, clinicalNoteId])

  const renderComponent = (currentPageId: string) => {
    if (!isClinicalNoteOwner) return <Review />
    switch (currentPageId) {
      case 'BILLING':
        return <Billing clinicalNoteComponent={true} />
      case 'REVIEW':
        return <Review />
      default:
        return (
          <>
            <ClinicalNotesForm
              key={currentPageId}
              onSubmit={onSubmitForm}
              defaultValues={defaultValues}
            >
              <SpecialModules />
              {visibleModulesByPage.map((module) => (
                <ModuleContainer
                  key={module.id}
                  id={module.id}
                  title={module.label}
                >
                  <div className="flex flex-col mt-8 p-6">
                    {module.fields.map((field) => (
                      <div className="mb-4">
                        <RenderFields
                          key={field.id}
                          groupTitle={module.label}
                          field={field}
                        />
                      </div>
                    ))}
                  </div>
                </ModuleContainer>
              ))}
            </ClinicalNotesForm>
          </>
        )
    }
  }

  return (
    <LoaderContainer loading={fetching} loadingText="Fetching clinical note">
      <ClinicalNotesLayout
        intakeOpen={false}
        toggleIntake={() => console.log('clicked')}
        toggleAddModule={() => setIsAddingModalFieldGroupOpen(true)}
        toggleCopyModal={toggleCopyModal}
        toggleAddAmend={toggleAddAmendModal}
        toggleShowChangeTemplateModal={toggleShowChangeTemplateModal}
      >
        {renderComponent(currentPageId)}
      </ClinicalNotesLayout>

      <ChangeTemplateModal
        isOpen={showChangeTemplateModal}
        onClose={() => toggleShowChangeTemplateModal()}
        cb={toggleCopyModal}
      />

      <AddModuleModal
        title="Add a module to evaluation"
        description="Select the modules you want to add to the patien's evaluation."
        successButtonLabel="Add Module"
        isOpen={isAddingModalFieldGroupOpen}
        closeModal={() => setIsAddingModalFieldGroupOpen(false)}
        listOfModules={hiddenModulesByPage}
        updateListOfModules={updateVisibility}
      />

      <CopyClinicalNoteModal isOpen={showCopyModal} onClose={toggleCopyModal} />

      <AddAmendmentModal
        isOpen={showAddAmendModal}
        onClose={toggleAddAmendModal}
      />

      <SavingIndicator isSaving={savingAnswer} />
    </LoaderContainer>
  )
})
