import { Button } from '@ps-ui/components'
import clsx from 'clsx'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { OptionProps, OptionsCard } from 'src/components/molecules/OptionsCard'
import { ReactComponent as CaretIcon } from 'src/images/caret-icon.svg'
import { PageHeadingStyles } from './styles'

export type PageHeadingOption = {
  title: string
  onClick: () => void
  buttonType?: string
  disabled?: boolean
  iconLeft?: () => JSX.Element
  iconRight?: () => JSX.Element
}

type PageHeadingProps = {
  title: string
  subTitle?: string | JSX.Element
  options?: PageHeadingOption[]
  backButton?: boolean
  textField?: any
  removeDivider?: boolean
  cardOptions?: OptionProps[]
}

export const PageHeading: React.FC<PageHeadingProps> = ({
  title,
  subTitle,
  options = [],
  backButton,
  textField,
  removeDivider = false,
  cardOptions,
}) => {
  let history = useHistory()
  return (
    <PageHeadingStyles
      className={clsx(
        'text-center h-20 sm:text-left sm:flex sm:items-center sm:justify-between sm:border-gray-200 mb-4 lg:mb-8',
        !removeDivider && 'sm:border-b-2'
      )}
    >
      <div className="flex-col flex">
        {backButton && !subTitle && (
          <button
            className="flex items-center"
            id="back-button"
            onClick={() => history.goBack()}
          >
            <CaretIcon
              style={{
                transform: 'rotate(90deg)',
              }}
            />
            Back
          </button>
        )}
        <h2 className="text-4xl font-bold py-3">
          {title}
          {subTitle && (
            <span className="block text-lg text-gray-600 font-normal">
              {subTitle}
            </span>
          )}
        </h2>
        {/* If there is a subtitle, render the back button in the component.
        There isn't enough room for both without looking cramped and out of place*/}
      </div>
      <div className="w-1/3 ml-auto mr-3">{textField}</div>
      {options && (
        <div className="flex items-center min-w-max justify-between sm:justify-end space-x-2 py-3 bg-gray-50 rounded sm:bg-transparent px-2 sm:px-0">
          {options.map((option, index) => (
            <Button
              key={`${option.title}-${index}`}
              variant={
                (option.buttonType as
                  | 'filled'
                  | 'default'
                  | 'secondary'
                  | undefined) || 'filled'
              }
              onClick={option.onClick}
              margin="0 8px 0 0 "
              disabled={option.disabled}
            >
              {option.iconLeft ? (
                <div className="mr-2">
                  <option.iconLeft />
                </div>
              ) : null}
              {option.title}
              {option.iconRight ? (
                <div className="ml-2">
                  <option.iconRight />
                </div>
              ) : null}
            </Button>
          ))}
          {cardOptions && <OptionsCard options={cardOptions} />}
        </div>
      )}
    </PageHeadingStyles>
  )
}
