import styled from 'styled-components/macro'

export const OptionsCardContainer = styled.div`
  position: relative;
`

export const IconContainer = styled.div`
  cursor: pointer;
`

export const OptionsContainer = styled.ul`
  background: #ffffff;
  border: 1px solid #dadbde;
  box-shadow: 0px 4px 10px -6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 20px;
  margin: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: max-content;
  gap: 15px;
  z-index: 100;
  li {
    list-style: none;
    width: max-content;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
  }
`
