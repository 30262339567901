import React from 'react'
import { TBalance } from 'src/pages/ClinicalNotesv2/types'

type BalanceAnswerProps = {
  balanceAnswer: TBalance
}

export const BalanceAnswer: React.FC<BalanceAnswerProps> = ({
  balanceAnswer,
}) => {
  const properties =
    (balanceAnswer?.properties && Object.entries(balanceAnswer.properties)) ||
    []
  return (
    <>
      {properties.length ? (
        <div className="mb-3">
          <p className="flex flex-col">
            {properties.map(([key, value]) => {
              if (!value) {
                return null
              }
              return (
                <>
                  <span className="font-semibold py-1">{`${key}: ${value.value}`}</span>
                </>
              )
            })}
          </p>
        </div>
      ) : null}
    </>
  )
}
