import { gql } from 'apollo-boost'
import { Mutation } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface UpdateReferringDoctorResponse {
  updateReferringDoctor: Mutation['updatePatientProfile']
}

export const UPDATE_REFERRING_DOCTOR = gql`
  mutation updatePatientProfile(
    $id: ID!
    $updatedFields: PatientProfileUpdateInput!
  ) {
    updatePatientProfile(id: $id, input: $updatedFields) {
      id
    }
  }
`

export const updateReferringDoctor = async (
  id: string,
  updatedFields: string
) => {
  const result = await apolloClient.query<UpdateReferringDoctorResponse>({
    query: UPDATE_REFERRING_DOCTOR,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      id,
      updatedFields: {
        referringDoctorNpi: updatedFields,
      },
    },
  })
  if (result.errors?.length) {
    throw new Error(result.errors[0].message)
  }
  return result.data.updateReferringDoctor
}
