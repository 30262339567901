import { LoaderContainer } from '@ps-ui/components'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { PageHeading } from 'src/components/organisms/PageHeading'
import {
  CodeType,
  DiagnosisModal,
} from 'src/components/templates/ClinicalNotesLayout/components/DiagnosisModal'
import { PagePadding } from 'src/components/templates/PagePadding'
import { useShowModal } from 'src/hooks/showModal'
import { InsurancePaymentMethod } from 'src/schema-types'
import { useStore } from 'src/stores/store'
import { SavingIndicator } from '../ClinicalNotesv2/components/SavingIndicator'
import { AuthorizationNumbers } from './components/AuthorizationNumbers'
import { BillingCodes } from './components/BillingCodes'
import { BillingHeader } from './components/BillingHeader'
import { PaymentOptions } from './components/PaymentOptions'
import { ReferringDoctorDropdown } from './components/ReferringDoctorDropdown'
import { ServiceLocationDropdown } from './components/ServiceLocationDropdown'

type BillingProps = {
  clinicalNoteComponent?: boolean
}

export const Billing: React.FC<BillingProps> = observer(
  ({ clinicalNoteComponent = true }) => {
    const { id } = useParams()
    const { addToast } = useToasts()
    const { practiceStore, billingStore, clinicalNotesStore } = useStore()
    const [showCptModal, toggleCptModal] = useShowModal(false)
    const [showIcdModal, toggleIcdModal] = useShowModal(false)

    useEffect(() => {
      if (billingStore.error) {
        addToast(`${billingStore.error}`, { appearance: 'error' })
        setTimeout(() => {
          billingStore.resetError()
        }, 500)
      }
    }, [billingStore.error, billingStore, addToast])

    useEffect(() => {
      if (!clinicalNoteComponent) {
        billingStore.resetValues()
        billingStore.fetchClaimData(id)
      }
    }, [billingStore, id, clinicalNoteComponent])

    useEffect(() => {
      practiceStore.getPracticeReferringDoctors()
    }, [practiceStore])

    return (
      <LoaderContainer
        loading={billingStore.loading}
        loadingText={billingStore.loadMessage}
      >
        <div className="w-full bg-white">
          <BillingHeader
            clinicalNotesStore={clinicalNotesStore}
            billingStore={billingStore}
            toggleModal={toggleIcdModal}
            clinicalNoteComponent={clinicalNoteComponent}
          />
          {billingStore.allowChargesEdit && clinicalNoteComponent && (
            <div className="w-full">
              <PaymentOptions />
            </div>
          )}
          <div
            className={clsx(
              'flex w-full py-2',
              clinicalNoteComponent && 'flex-col'
            )}
          >
            <div className={clsx('flex flex-col w-full')}>
              <BillingCodes
                toggleCptModal={toggleCptModal}
                toggleIcdModal={toggleIcdModal}
                billingStore={billingStore}
                practiceStore={practiceStore}
              />
            </div>
            {billingStore.paymentOptions &&
              billingStore.paymentOptions?.length > 0 &&
              !billingStore.paymentOptions?.includes(
                InsurancePaymentMethod.SelfPay
              ) && (
                <div className="flex flex-col w-full justify-between items-end pb-5">
                  <div className="flex w-full">
                    <div className={clsx('flex w-full items-center mb-6')}>
                      <ReferringDoctorDropdown
                        referringDoctors={practiceStore.activeReferringDoctors}
                        billingStore={billingStore}
                      />
                      <ServiceLocationDropdown billingStore={billingStore} />
                    </div>
                  </div>
                  <AuthorizationNumbers />
                </div>
              )}
          </div>
        </div>
        <SavingIndicator isSaving={billingStore.saving} />
        <DiagnosisModal
          isOpen={showCptModal}
          onClose={toggleCptModal}
          codeType={CodeType.CPT}
        />
        <DiagnosisModal
          isOpen={showIcdModal}
          onClose={toggleIcdModal}
          clinicalNoteComponent={clinicalNoteComponent}
        />
      </LoaderContainer>
    )
  }
)

// Used for single page billing and not within a clinical note component
export const SoloBillingComponent: React.FC<{
  clinicalNoteComponent: boolean
}> = ({ clinicalNoteComponent = false }) => {
  return (
    <PagePadding>
      <PageHeading title="Billing" backButton={true} />
      <Billing clinicalNoteComponent={clinicalNoteComponent} />
    </PagePadding>
  )
}
