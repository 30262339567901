import { Flex, Option } from '@ps-ui/components'
import React, { useCallback, useContext } from 'react'
import { Select } from 'react-functional-select'
import { AmendmentIndicator } from 'src/pages/ClinicalNotesv2/components/AmendmentIndicator'
import { ThemeContext } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { DropdownLabel } from './styles'

export interface OptionWithType {
  label: string
  value: string
  type?: string
}

type FunctionalDropdownProps = {
  onOptionChange: (opt: Option | Option[] | OptionWithType[]) => void
  label?: string
  dropdownOption: Option[] | OptionWithType[] | undefined
  currentSelection?: Option | OptionWithType[] | Option[] | undefined
  backgroundColor?: string
  isMulti?: boolean
  disabled?: boolean
  required?: boolean | null
  placeholder?: string
  width?: string
  radiusValue?: string
  amendDate?: number
  color?: string
  menuMaxHeight?: number
}

export const FunctionalDropdown: React.FC<FunctionalDropdownProps> = React.memo(
  ({
    onOptionChange,
    isMulti,
    label,
    dropdownOption,
    amendDate,
    currentSelection,
    backgroundColor,
    disabled,
    required,
    placeholder,
    width,
    radiusValue,
    color,
    menuMaxHeight = 150,
  }) => {
    const styledTheme = useContext(ThemeContext)
    //Multiselect Values
    const getOptionValue = useCallback(
      (option: Option | OptionWithType): string => option.value,
      []
    )
    const getOptionLabel = useCallback(
      (option: Option | OptionWithType): string => option.label,
      []
    )
    const onSelectChange = useCallback(
      (option: Option | OptionWithType[] | undefined): void => {
        option && onOptionChange(option)
      },
      // eslint-disable-next-line
      []
    )
    const optionCardStyles = {
      color: color ? color : `color: '#ffffff'`,
      fontWeight: 500,
      width: 'fit-content',
      padding: '1px 8px',
      borderRadius: '3px',
      margin: '2px 0',
      fontSize: '16px',
    }

    return (
      <Flex
        flexDirection="column"
        width={width || '100%'}
        key={JSON.stringify(currentSelection)}
      >
        {label && (
          <div className="flex">
            <DropdownLabel disabled={disabled} required={required}>
              {label}
            </DropdownLabel>
            {!!amendDate && <AmendmentIndicator date={amendDate} />}
          </div>
        )}
        <Select
          key={isMulti ? undefined : uuidv4()}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          placeholder={placeholder ? placeholder : 'Select a value'}
          options={dropdownOption}
          themeConfig={{
            control: {
              minHeight: '38px',
              backgroundColor: backgroundColor ? backgroundColor : '#ffffff',
              borderRadius: `${radiusValue}`,
            },
            menu: {
              borderRadius: `${radiusValue}`,
            },
          }}
          initialValue={currentSelection}
          isDisabled={disabled}
          noOptionsMsg={'No More Options Available'}
          renderOptionLabel={(opt) => {
            return (
              <p
                style={{
                  backgroundColor: backgroundColor
                    ? backgroundColor
                    : `${styledTheme.colors.primary}`,
                  ...optionCardStyles,
                }}
              >
                {opt.label}
              </p>
            )
          }}
          onOptionChange={onSelectChange}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          menuMaxHeight={menuMaxHeight}
        />
      </Flex>
    )
  }
)
