import { Modal } from 'semantic-ui-react'
import styled, { keyframes } from 'styled-components/macro'

export const Form = styled.form`
  box-sizing: border-box;
  padding: 2rem;
  background-color: #fff;
`

export const Input = styled.input`
  border: 1px solid #eff1f4;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  height: 45px;
  padding: 8px 12px;
  outline: none;
  &:focus {
    border-color: #08b175;
  }
  &.error {
    animation: shake 0.72s ease-in-out both;
    border-color: crimson;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
`

export const SubmitButton = styled.button`
  display: inline-block;
  outline: none;
  font-size: 16px;
  color: #222;
  border: 1px solid ${(p) => p.theme.defaultBorderColor};
  border-radius: 8px;
  padding: 10px 14px;
  margin-right: 20px;
  transition: transform 0.2s ease-in-out;
  &:focus {
    border: 1px solid ${(p) => p.theme.focusColor};
  }
  &:active {
    transform: scale(0.95);
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`

export const Label = styled.label<LabelProps>`
  display: block;
  color: ${(props) => props.theme.colors.text};
  font-size: 14px;
  width: 100%;
  margin-bottom: 15px;

  ${({ margin }: LabelProps) => `
    margin: ${margin};
  `}

  & > span {
    display: inline-block;
    margin-bottom: 8px;
  }
`

export const Title = styled(Modal.Header)`
  text-align: center;
`

type LabelProps = {
  margin?: string
}

const load = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Loading = styled.div`
  display: inline-block;
  animation: ${load} 1s linear infinite;
`
