import { Button } from '@ps-ui/components'
import styled from 'styled-components/macro'
import Modal, { BaseModalBackground } from 'styled-react-modal'
import {
  CptContainer,
  SelectedCodeContainer,
} from '../../../pages/CreateClaim/styles'

export const ModalStyled = Modal.styled`
  text-align: left;
  border-radius: 5px;
  display: inline-block;
  grid-row: 2;
`

export const IcdCodeContainer = styled(SelectedCodeContainer)`
  margin: 5px;
  width: 100%;
`

export const IcdContainer = styled(CptContainer)`
  position: relative;
  padding: 2px 20px;
  max-height: 130px;
  border: 1px solid #000000;
  border-radius: 12px;
  margin-bottom: 15px;
`

export const ModalBackground = styled(BaseModalBackground)`
  background-color: rgba(0, 0, 0, 0.25);
  overflow: auto;
  height: calc(100vh - 60px);
  top: auto;
  bottom: 0;
  overflow-y: auto;
  text-align: center;
  display: grid;
  grid-template-rows: 30px 1fr;
`

export const StyledCodesModal = styled.div`
  background-color: white;
  min-width: 400px;
  min-height: 406px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
`

export const InnerModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 0 auto;
`

export const ModalButtons = styled(Button)`
  &&& {
    border: 2px solid ${(props) => props.theme.colorPrimary};
    border-radius: 10px;
    label {
      font-weight: 700;
    }
  }
`

export const CptTextContainer = styled.div`
  border-bottom: 1px solid #000000;
  width: 100%;
`
