import { gql } from 'apollo-boost'
import { InsuranceClaimCreateInput, Mutation } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface CreateNewClaimResponse {
  createInsuranceClaim: Mutation['createInsuranceClaim']
}

export const CREATE_INSURANCE_CLAIM = gql`
  mutation createInsuranceClaim($input: InsuranceClaimCreateInput!) {
    createInsuranceClaim(input: $input) {
      id
      claimId
      status
      errors
    }
  }
`

export const createInsuranceClaim = async (
  input: InsuranceClaimCreateInput
) => {
  const result = await apolloClient.query<CreateNewClaimResponse>({
    query: CREATE_INSURANCE_CLAIM,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: { input },
  })
  if (result.errors?.length) {
    throw new Error(result.errors[0].message)
  }
  return result.data.createInsuranceClaim
}
