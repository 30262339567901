import { gql } from 'apollo-boost'
import { SaveClinicalNoteAnswerMutation } from 'src/operations-types'
import { ClinicalNoteAnswerInput } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'
import { clinicalNoteModularAnswerFragment } from '../fragments/clinicalNoteModularAnswer'

export const SAVE_CLINICAL_NOTE_ANSWER = gql`
  mutation saveClinicalNoteAnswer($input: ClinicalNoteAnswerInput!) {
    saveClinicalNoteAnswer(input: $input) {
      ...clinicalNoteAnswer
    }
  }
  ${clinicalNoteModularAnswerFragment}
`

export const saveClinicalNoteAnswer = async (
  input: ClinicalNoteAnswerInput
) => {
  const response = await apolloClient.mutate<SaveClinicalNoteAnswerMutation>({
    mutation: SAVE_CLINICAL_NOTE_ANSWER,
    variables: {
      input,
    },
  })

  return response.data?.saveClinicalNoteAnswer
}
