import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  background-color: #fff;
  padding: 20px 20px 40px 20px;
  margin-top: ${(p) => p.theme.spacing(3)};
  border-radius: 10px;
`

export const Table = styled.table`
  margin: 0 auto;
`

export const DayList = styled.ul`
  margin: 16px 0 0 0;
  padding: 0;
  list-style: none;
`

export const TimeSlot = styled.div`
  display: inline-block;
  color: #707891;
  padding: 8px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #eff1f4;
  border-radius: 7px;
  margin-top: 10px;
  cursor: pointer;
  &.booked {
    background-color: #dbe9ff;
    color: #1458bc;
  }
  ${(p) => p.theme.breakpoints.tablets} {
    padding: 5px;
  }
`
export const Day = styled.td`
  padding: 0 5px;
  min-width: 70px;
  ${(p) => p.theme.breakpoints.medium} {
    padding: 0 2px;
  }
`
export const DayHeading = styled.th`
  min-width: 70px;
  padding: 0 5px;
  text-align: center;
  ${(p) => p.theme.breakpoints.medium} {
    padding: 0 2px;
    min-width: auto;
  }
`

export const NavButton = styled.button`
  display: inline-block;
  background-color: #08b175;
  color: #fff;
  border: none;
  padding: 12px 20px;
  text-transform: uppercase;
  border-radius: 8px;
`

export const PickerWrapper = styled.label`
  margin-left: 20px;
  display: inline-block;
  &:focus,
  &:active {
    background-color: #08b175;
  }
  .react-datepicker {
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 20px rgba(83, 65, 142, 0.1);

    .react-datepicker__header {
      background: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker-popper[data-placement^='bottom'] {
      margin-top: 0;
    }

    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous {
      top: 16px;
      outline: none;
    }

    .react-datepicker__navigation--next {
      border-left-color: ${(props) => props.theme.colors.primary};
    }

    .react-datepicker__navigation--previous {
      border-right-color: ${(props) => props.theme.colors.primary};
    }

    .react-datepicker__current-month {
      color: ${(props) => props.theme.colors.grey};
      font-weight: 500;
      font-size: 20px;
      padding: 6px 0px;
    }

    .react-datepicker__day {
      color: ${(props) => props.theme.colors.grey};
      width: 30px;
      line-height: 30px;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--today {
      background-color: ${(props) => props.theme.colors.primary};
      border-radius: 50%;
      color: #fff;
    }
  }
`
