export const cadenceArray = [
  {
    label: 'WNL',
    value: 'wnl',
  },
  {
    label: 'Slow',
    value: 'slow',
  },
  {
    label: 'Fast',
    value: 'fast',
  },
  {
    label: 'Shuffling',
    value: 'shuffling',
  },
  {
    label: 'Asymmetrical',
    value: 'asymmetrical',
  },
  {
    label: 'Increased Timing on Left',
    value: 'increased timing on left',
  },
  {
    label: 'Increased Timing on Right',
    value: 'increased timing on right',
  },
  {
    label: 'Decreased Timing on Left',
    value: 'decreased timing on left',
  },
  {
    label: 'Decreased Timing on Right',
    value: 'decreased timing on right',
  },
]

export const supportArray = [
  {
    label: 'AFO',
    value: 'afo',
  },
  {
    label: 'Knee Immobilizer',
    value: 'knee immobilizer',
  },
  {
    label: 'Knee Brace',
    value: 'knee brace',
  },
  {
    label: 'Ankle Brace',
    value: 'ankle brace',
  },
]

export const deviationsArray = [
  {
    label: 'Ataxic Gait',
    value: 'ataxic gait',
  },
  {
    label: 'Antalgic Gait',
    value: 'antalgic gait',
  },
  {
    label: 'Bradykinesia',
    value: 'bradykinesia',
  },
  {
    label: 'Festinating Gait',
    value: 'festinating gait',
  },
  {
    label: 'Vaulting',
    value: 'vaulting',
  },
  {
    label: 'Hip Circumduction',
    value: 'hip circumduction',
  },
  {
    label: 'Hip Hiking',
    value: 'hip hiking',
  },
  {
    label: 'Knee Hyperextension',
    value: 'knee hyperextension',
  },
  {
    label: 'Foot Drop',
    value: 'foot drop',
  },
]

export const assistiveDeviceArray = [
  {
    label: 'Cane',
    value: 'cane',
  },
  {
    label: 'Quad Cane',
    value: 'quad cane',
  },
  {
    label: 'Walker',
    value: 'walker',
  },
  {
    label: 'FWW',
    value: 'fww',
  },
  {
    label: '4WW',
    value: '4ww',
  },
  {
    label: 'Hemi Walker',
    value: 'hemi walker',
  },
]
