import React from 'react'
import { FaPlus } from 'react-icons/fa'
import styled from 'styled-components/macro'

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  min-height: 45px;
  margin-left: 14px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: 1px solid #eff1f4;
  &:active,
  &:focus {
    border: 1px solid #08b175;
  }
  &:hover {
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  }
`

export function AddButton(props: any) {
  return (
    <Button {...props}>
      <FaPlus />
    </Button>
  )
}
