import { makeAutoObservable, runInAction } from 'mobx'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { PatientProfile } from 'src/schema-types'
import { PatientsServices } from 'src/services/patients'
import { RootStore } from 'src/stores/store'

export class PatientsStore {
  // All patients
  patientsList?: PatientProfile[]
  fetchingPatients = false
  fetchingMorePatients = false
  fetchingPatientsError?: string
  fetchingPatientsPageNumber = 1
  hasMorePatients = false

  // Patients search
  patientsSearched?: PatientProfile[]
  fetchingBySearch = false
  fetchingMoreBySearch = false
  fetchingBySearchError?: string
  fetchingBySearchPageNumber = 1
  hasMorePatientsBySearch = false

  rootStore: RootStore
  services: PatientsServices

  constructor(rootStore: RootStore, services: PatientsServices) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
    this.services = services
  }

  fetchPatients = async (practiceId: string) => {
    try {
      runInAction(() => {
        this.fetchingPatients = true
      })
      const patientsResponse = await this.services.getAllPatients(practiceId, {
        pageNumber: 1,
        pageSize: 50,
      })
      runInAction(() => {
        this.patientsList = patientsResponse.patients
        this.hasMorePatients = !!patientsResponse.hasMore
        this.fetchingPatientsPageNumber = 2
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchingPatientsError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetchingPatients = false
      })
    }
  }

  fetchMorePatients = async (practiceId: string) => {
    try {
      runInAction(() => {
        this.fetchingMorePatients = true
      })
      const patientsResponse = await this.services.getAllPatients(practiceId, {
        pageNumber: this.fetchingPatientsPageNumber,
        pageSize: 50,
      })

      runInAction(() => {
        this.patientsList =
          this.patientsList && patientsResponse.patients
            ? [...this.patientsList, ...patientsResponse.patients]
            : patientsResponse.patients
        this.fetchingPatientsPageNumber = this.fetchingPatientsPageNumber + 1
        this.hasMorePatients = !!patientsResponse.hasMore
      })
    } catch (err) {
      writeLog((err as Error).message)

      runInAction(() => {
        this.fetchingPatientsError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetchingMorePatients = false
      })
    }
  }

  fetchSearchPatients = async (input: string) => {
    try {
      runInAction(() => {
        this.fetchingBySearch = true
      })
      const patientsSearchResponse = await this.services.getPatientBySearch(
        input,
        { pageNumber: 1, pageSize: 50 }
      )

      runInAction(() => {
        this.patientsSearched = patientsSearchResponse.patients
        this.fetchingBySearchPageNumber = 2
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchingBySearchError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetchingBySearch = false
      })
    }
  }

  fetchMorePatientsBySearch = async (input: string) => {
    try {
      runInAction(() => {
        this.fetchingMorePatients = true
      })
      const patientsSearchResponse = await this.services.getPatientBySearch(
        input,
        { pageNumber: this.fetchingBySearchPageNumber, pageSize: 50 }
      )

      runInAction(() => {
        this.patientsSearched =
          this.patientsSearched && patientsSearchResponse.patients
            ? [...this.patientsSearched, ...patientsSearchResponse.patients]
            : patientsSearchResponse.patients
        this.fetchingBySearchPageNumber = this.fetchingBySearchPageNumber + 1
        this.hasMorePatientsBySearch = !!patientsSearchResponse.hasMore
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.fetchingPatientsError = err
      })
    } finally {
      runInAction(() => {
        this.fetchingMorePatients = false
      })
    }
  }

  resetPatientsError = () => {
    runInAction(() => {
      this.fetchingPatientsError = ''
      this.fetchingBySearchError = ''
    })
  }

  resetPatientsBySearch = () => {
    runInAction(() => {
      this.patientsSearched = undefined
    })
  }
}
