import { gql } from 'apollo-boost'
import { apolloClient, publicApolloClient } from 'src/settings/apolloClient'

export interface WriteToLogResponse {
  writeToLog: boolean
}

export const WRITE_TO_LOG = gql`
  mutation writeToLog($text: String!, $type: LogType) {
    writeToLog(text: $text, type: $type)
  }
`

export const writeLog = (
  text: string,
  isPublic?: boolean,
  type: 'ERROR' | 'WARNING' | 'INFO' = 'ERROR'
) => {
  switch (type) {
    case 'ERROR':
      console.error(text)
      break
    case 'WARNING':
      console.warn(text)
      break
    case 'INFO':
      console.info(text)
      break
    default:
      console.error(text)
  }
  if (process.env.NODE_ENV === 'production') {
    const client = isPublic ? publicApolloClient : apolloClient
    client.mutate<WriteToLogResponse>({
      mutation: WRITE_TO_LOG,
      variables: {
        text,
        type,
      },
    })
  } else {
    console.error(text)
  }
}
