'use strict'

function _typeof(obj) {
  '@babel/helpers - typeof'
  if (typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol') {
    _typeof = function _typeof(obj) {
      return typeof obj
    }
  } else {
    _typeof = function _typeof(obj) {
      return obj &&
        typeof Symbol === 'function' &&
        obj.constructor === Symbol &&
        obj !== Symbol.prototype
        ? 'symbol'
        : typeof obj
    }
  }
  return _typeof(obj)
}

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.StyledButton = exports.ButtonLabel = void 0

var _styledComponents = _interopRequireWildcard(require('styled-components'))

var _index = require('../Typography/index.js')

var _templateObject, _templateObject2, _templateObject3, _templateObject4

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== 'function') return null
  var cacheBabelInterop = new WeakMap()
  var cacheNodeInterop = new WeakMap()
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop
  })(nodeInterop)
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj
  }
  if (obj === null || (_typeof(obj) !== 'object' && typeof obj !== 'function')) {
    return { default: obj }
  }
  var cache = _getRequireWildcardCache(nodeInterop)
  if (cache && cache.has(obj)) {
    return cache.get(obj)
  }
  var newObj = {}
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor
  for (var key in obj) {
    if (key !== 'default' && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc)
      } else {
        newObj[key] = obj[key]
      }
    }
  }
  newObj['default'] = obj
  if (cache) {
    cache.set(obj, newObj)
  }
  return newObj
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0)
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }))
}

// handles the styling for the "filled" version of a button
var FilledVariant = (0, _styledComponents.css)(
  _templateObject ||
    (_templateObject = _taggedTemplateLiteral([
      '\n  border-color: ',
      ';\n  background-color: ',
      ';\n  &:hover {\n    cursor: pointer;\n    background-color: ',
      ';\n  }\n  &:focus {\n    background-color: ',
      ';\n  }\n  &:active {\n    background-color: ',
      ';\n  }\n',
    ])),
  function (props) {
    if (props.isDashed) {
      if (props.disabled) {
        return 'grey'
      }

      return props.theme.darken(props.theme.colors[props.color || 'primary'], 8)
    }

    return props.disabled ? '' : props.theme.darken(props.theme.colors[props.color || 'primary'], 3)
  },
  function (props) {
    return props.theme.colors[props.color || 'primary']
  },
  function (props) {
    return props.theme.darken(props.theme.colors[props.color || 'primary'], 5)
  },
  function (props) {
    return props.theme.darken(props.theme.colors[props.color || 'primary'], 5)
  },
  function (props) {
    return props.theme.darken(props.theme.colors[props.color || 'primary'], 8)
  }
) // handles the styling for the non-filled version of a button

var DefaultVariant = (0, _styledComponents.css)(
  _templateObject2 ||
    (_templateObject2 = _taggedTemplateLiteral([
      '\n  color: ',
      ';\n\n  &:hover {\n    cursor: pointer;\n    background-color: ',
      ';\n  }\n  &:focus {\n    background-color: ',
      ';\n  }\n  &:active {\n    background-color: ',
      ';\n  }\n',
    ])),
  function (props) {
    return props.color || !(props.variant === 'secondary')
      ? props.theme.colors[props.color || 'text']
      : props.theme.darken(props.theme.colors['primary'], 8)
  },
  function (props) {
    return props.theme.lighten(props.theme.colors[props.color || 'primary'], 0.2)
  },
  function (props) {
    return props.theme.lighten(props.theme.colors[props.color || 'primary'], 0.2)
  },
  function (props) {
    return props.theme.lighten(props.theme.colors[props.color || 'primary'], 0.3)
  }
)

var StyledButton = _styledComponents['default'].button(
  _templateObject3 ||
    (_templateObject3 = _taggedTemplateLiteral([
      '\n  &&& {\n    ',
      ';\n    ',
      ';\n    ',
      ';\n\n    flex-direction: ',
      ';\n    justify-content: ',
      ';\n    ',
      '\n    padding: ',
      ';\n    margin: ',
      ';\n    transition: transform 0.2s;\n    ',
      ';\n    border-radius: 5px;\n\n    label {\n      padding-left: ',
      ';\n      padding-right: ',
      ';\n    }\n\n    &:disabled {\n      background-color: lightgrey;\n      color: white;\n      cursor: default;\n    }\n  }\n',
    ])),
  function (props) {
    return props.opacity ? 'opacity: '.concat(props.opacity, ';') : ''
  },
  function (props) {
    return props.expand ? 'width: 100%;' : ''
  },
  function (props) {
    return props.border ? 'border: '.concat(props.border, ';') : ''
  },
  function (props) {
    return props.iconPosition === 'right' ? 'row-reverse' : 'row'
  },
  function (props) {
    return props.iconSpacing === 'space-between' ? 'space-between' : 'center'
  },
  function (props) {
    return props.flexible ? 'flex: 1;' : ''
  },
  function (props) {
    return props.padding ? props.theme.spacingToPx(props.padding) : props.theme.spacing(1.5)
  },
  function (props) {
    return props.margin ? props.theme.spacingToPx(props.margin) : 0
  },
  function (props) {
    return props.variant === 'filled' ? FilledVariant : DefaultVariant
  },
  function (props) {
    return !!props.Icon && props.iconPosition === 'left' ? props.theme.spacing(1.5) : 0
  },
  function (props) {
    return !!props.Icon && props.iconPosition === 'right' ? props.theme.spacing(1.5) : 0
  }
)

exports.StyledButton = StyledButton
var ButtonLabel = (0, _styledComponents['default'])(_index.Typography)(
  _templateObject4 ||
    (_templateObject4 = _taggedTemplateLiteral([
      '\n  pointer-events: none;\n  margin: 0 !important;\n',
    ]))
)
exports.ButtonLabel = ButtonLabel
