import {
  Flex,
  Option,
  RELATIONSHIP_METHODS,
  TextField,
  Typography,
} from '@ps-ui/components'
import React from 'react'
import {
  Args,
  BaseInputProps,
  FormState,
  InputInitializer,
  StateErrors,
} from 'react-use-form-state'
import { FunctionalDropdown } from 'src/components/molecules/FunctionalDropdown'
import { ElementTypes } from 'src/schema-types'
import { requiredFieldNoLabel } from 'src/utils/fieldValidators'
import { TextFieldValues } from '../..'
import { StyledInsuranceCard } from '../../styles'

type InsuranceDisplayProps = {
  text: InputInitializer<
    TextFieldValues,
    Args<string | number, void>,
    BaseInputProps<TextFieldValues>
  >
  formState: FormState<TextFieldValues, StateErrors<TextFieldValues, string>>
  loading: boolean
  genderSelection: Option | undefined
  setGenderSelection: React.Dispatch<React.SetStateAction<Option>>
  relationshipSelection: Option
  setRelationshipSelection: React.Dispatch<React.SetStateAction<Option>>
  locationSelection: Option
  setLocationSelection: React.Dispatch<React.SetStateAction<Option>>
  claimStatus: boolean
  setSelectedReferringDoc: React.Dispatch<React.SetStateAction<Option>>
  selectedReferringDoc: Option
  referringDoctorsOptions: Option[] | undefined
}

export const InsuranceDisplay: React.FC<InsuranceDisplayProps> = ({
  text,
  formState,
  loading,
  genderSelection,
  setGenderSelection,
  relationshipSelection,
  setRelationshipSelection,
  locationSelection,
  setLocationSelection,
  claimStatus,
  setSelectedReferringDoc,
  selectedReferringDoc,
  referringDoctorsOptions,
}) => {
  const disableFields = loading || claimStatus
  const isRequired = claimStatus ? false : true

  return (
    <StyledInsuranceCard className="fs-mask">
      {!loading && (
        <>
          <Typography as="h3" fontWeight="700" padding="0 0 15px 0">
            Insurance Info
          </Typography>
          {/* Patient Demographics */}
          <Flex
            justifyContent="flex-start"
            alignItems="flex-end"
            flexWrap="wrap"
            className="row"
          >
            <TextField
              label="Subscriber first name"
              placeholder="Subscriber first name"
              white={isRequired}
              error={formState.errors.firstName}
              required={isRequired}
              {...text({
                name: 'firstName',
                validate: requiredFieldNoLabel,
                validateOnBlur: true,
              })}
              border
              disabled={disableFields}
            />
            <TextField
              label="Subscriber last name"
              placeholder="Subscriber last name"
              white={isRequired}
              error={formState.errors.lastName}
              required={isRequired}
              {...text({
                name: 'lastName',
                validate: requiredFieldNoLabel,
                validateOnBlur: true,
              })}
              border
              disabled={disableFields}
            />

            <TextField
              label="Date of birth"
              placeholder={'mm/dd/yyyy'}
              formatType={ElementTypes.DateInput}
              border
              white={isRequired}
              error={formState.errors.dateOfBirth}
              required={isRequired}
              {...text({
                name: 'dateOfBirth',
                validate: requiredFieldNoLabel,
                validateOnBlur: true,
              })}
              disabled={disableFields}
            />

            <FunctionalDropdown
              onOptionChange={setGenderSelection}
              label="Gender"
              currentSelection={genderSelection}
              disabled={disableFields}
              required={isRequired}
              dropdownOption={[
                { value: 'MALE', label: 'Male' },
                { value: 'FEMALE', label: 'Female' },
                { value: 'NOT_SPECIFIED', label: 'Not Specified' },
              ]}
            />
          </Flex>
          {/* Insurance Information */}
          <Flex
            justifyContent="flex-start"
            alignItems="flex-end"
            flexWrap="wrap"
            className="row"
          >
            <FunctionalDropdown
              onOptionChange={setRelationshipSelection}
              label="Patient's relationship to subscriber"
              currentSelection={relationshipSelection}
              disabled={disableFields}
              required={isRequired}
              dropdownOption={RELATIONSHIP_METHODS.map(({ label, value }) => ({
                value,
                label,
              }))}
            />
            <TextField
              label="Insurance company name"
              placeholder="Insurance company name"
              border
              white={isRequired}
              error={formState.errors.insuranceName}
              required={isRequired}
              {...text({
                name: 'insuranceName',
                validate: requiredFieldNoLabel,
                validateOnBlur: true,
              })}
              disabled={disableFields}
            />
            <TextField
              label="Insurance policy number"
              placeholder="Insurance policy number"
              border
              white={isRequired}
              error={formState.errors.policyNumber}
              required={isRequired}
              {...text({
                name: 'policyNumber',
                validate: requiredFieldNoLabel,
                validateOnBlur: true,
              })}
              disabled={disableFields}
            />
            <FunctionalDropdown
              onOptionChange={setLocationSelection}
              label="Service Location"
              currentSelection={locationSelection}
              disabled={disableFields}
              required={isRequired}
              dropdownOption={[
                { value: 'OFFICE', label: 'Office' },
                { value: 'TELEHEALTH', label: 'Telehealth' },
              ]}
            />
            <FunctionalDropdown
              onOptionChange={setSelectedReferringDoc}
              label="Referring Provider"
              currentSelection={selectedReferringDoc || undefined}
              disabled={disableFields}
              required={isRequired}
              dropdownOption={referringDoctorsOptions || []}
            />
          </Flex>
          {/* Address Information */}
          <Flex
            justifyContent="flex-start"
            alignItems="flex-end"
            flexWrap="wrap"
            className="row"
          >
            <Flex flexDirection="column">
              <TextField
                label="Street"
                placeholder="Street"
                white={isRequired}
                border
                error={formState.errors.street1}
                required={isRequired}
                {...text({
                  name: 'street1',
                  validate: requiredFieldNoLabel,
                  validateOnBlur: true,
                })}
                disabled={disableFields}
              />
            </Flex>
            <Flex flexDirection="column">
              <TextField
                label="City"
                placeholder="City"
                white={isRequired}
                border
                error={formState.errors.city}
                required={isRequired}
                {...text({
                  name: 'city',
                  validate: requiredFieldNoLabel,
                  validateOnBlur: true,
                })}
                disabled={disableFields}
              />
            </Flex>
            <Flex flexDirection="column">
              <TextField
                label="State"
                placeholder="State"
                white={isRequired}
                border
                error={formState.errors.state}
                required={isRequired}
                {...text({
                  name: 'state',
                  validate: requiredFieldNoLabel,
                  validateOnBlur: true,
                })}
                disabled={disableFields}
              />
            </Flex>
            <Flex flexDirection="column">
              <TextField
                label="Zip code"
                placeholder="Zip"
                white={isRequired}
                error={formState.errors.zip}
                required={isRequired}
                border
                {...text({
                  name: 'zip',
                  validate: requiredFieldNoLabel,
                  validateOnBlur: true,
                })}
                disabled={disableFields}
              />
            </Flex>
          </Flex>
        </>
      )}
    </StyledInsuranceCard>
  )
}
