import { Option } from '@ps-ui/components'
import * as React from 'react'
import { BinaryButtons } from 'src/pages/ClinicalNotesv2/components/fields/BinaryButtons'
import { BodyChartInput } from 'src/pages/ClinicalNotesv2/components/fields/BodyChartInput'
import { Dropdown } from 'src/pages/ClinicalNotesv2/components/fields/Dropdown'
import { RangeInput } from 'src/pages/ClinicalNotesv2/components/fields/RangeInput'
import { hasLeftOrRight } from 'src/pages/ClinicalNotesv2/utils'

interface PainBodyPartProps {
  name: string
  field: any
}

const dropdownOptions: Option[] = [
  {
    label: 'Sharp',
    value: 'sharp',
  },
  {
    label: 'Dull',
    value: 'dull',
  },
  {
    label: 'Aching',
    value: 'aching',
  },
  {
    label: 'Throbbing',
    value: 'throbbing',
  },
  {
    label: 'Shooting',
    value: 'shooting',
  },
  {
    label: 'Radiating',
    value: 'radiating',
  },
]

export const PainBodyPart: React.FC<PainBodyPartProps> = ({ name, field }) => {
  return (
    <div className="grid gap-3">
      <div className="flex flex-col">
        <BodyChartInput name={`${name}.body`} defaultValue={field['body']} />
        <BinaryButtons
          name={`${name}.side`}
          rangeOfMotionButtons={true}
          defaultValue={field['side']}
          isVisible={hasLeftOrRight(field['side'])}
          options={[
            { value: 'left', label: 'Left' },
            { value: 'right', label: 'Right' },
          ]}
        />
      </div>
      <div className="flex items-center">
        <label className="mr-56 font-bold">Type</label>
        <div className="w-44">
          <Dropdown
            isMulti
            name={`${name}.type`}
            defaultValue={field['type']}
            options={dropdownOptions}
          />
        </div>
      </div>
      <div className="flex items-center w-full">
        <label className="font-bold mr-52">Level</label>
        <RangeInput name={`${name}.range`} />
      </div>
    </div>
  )
}
