import { Flex, Typography } from '@ps-ui/components'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import {
  Args,
  BaseInputProps,
  FormState,
  InputInitializer,
  StateErrors,
} from 'react-use-form-state'
import { QueryClaimDataResponse } from 'src/gql/queries/queryClaimDataByAppointmentId'
import { PatientCard } from 'src/pages/ClinicalNotes/components/PatientCard'
import { PatientProfile, ProfilePicture } from 'src/schema-types'
import { calcAge, formatDate } from 'src/utils/formatDate'
import { TextFieldValues } from '../..'
import { StatusCard } from './styles'

type ClaimStatusProps = {
  text: InputInitializer<
    TextFieldValues,
    Args<string | number, void>,
    BaseInputProps<TextFieldValues>
  >
  formState: FormState<TextFieldValues, StateErrors<TextFieldValues, string>>
  patientProfile: PatientProfile | undefined | null
  profilePictureUrl: ProfilePicture | undefined | null
  setProfilePictureUrl: React.Dispatch<
    React.SetStateAction<ProfilePicture | undefined>
  >
  serviceDate: string
  claimData: QueryClaimDataResponse | undefined
}

export const ClaimStatus: React.FC<ClaimStatusProps> = ({
  patientProfile,
  formState,
  profilePictureUrl,
  setProfilePictureUrl,
  text,
  serviceDate,
  claimData,
}) => {
  const patientAge = calcAge(dayjs(patientProfile?.dateOfBirth).toDate())
  const [statusColor, setStatusColor] = useState('#ff7800')
  // Easy to consume object for provider information that populates at the top of the page
  const providerInfo = {
    name: `${claimData?.getAppointment.practiceUsers[0].firstName} ${claimData?.getAppointment.practiceUsers[0].lastName}`,
    npi: claimData?.getAppointment.practiceUsers[0].aithozProviderNPI,
  }

  useEffect(() => {
    if (formState.values.submitted) {
      if (
        formState.values.submitted === 'COMPLETED_CLAIM_PAID' ||
        'APPEAL_PAID'
      ) {
        setStatusColor('success')
      }
      if (
        formState.values.submitted === 'NEW' ||
        'REVIEW' ||
        'HOLD' ||
        'PARTIALLY_PAID' ||
        'BILLED_TO_PATIENT' ||
        'BILLED_TO_PRIMARY_OR_SECONDARY_INSURANCE'
      ) {
        setStatusColor('secondary')
      } else {
        setStatusColor('error')
      }
    }
  }, [formState.values.submitted])

  return (
    <>
      <Flex flexDirection="column">
        <Flex>
          <Typography as="h4" fontWeight="700" padding="15px 10px 0 0">
            Date of Service:
          </Typography>
          <Typography as="p" fontWeight="500" padding="15px 0 0 0">
            {formatDate(serviceDate)}
          </Typography>
        </Flex>
        <Flex>
          <Typography as="h4" fontWeight="700" padding="10px 10px 0 0">
            Service Provider:
          </Typography>
          <Typography as="p" fontWeight="500" padding="10px 0 0 0">
            {providerInfo.name}
          </Typography>
        </Flex>
        <Flex>
          <Typography as="h4" fontWeight="700" padding="10px 10px 0 0">
            Provider NPI:
          </Typography>
          <Typography as="p" fontWeight="500" padding="10px 0 0 0">
            {providerInfo.npi}
          </Typography>
        </Flex>
      </Flex>
      <Flex flexDirection="row" justifyContent="space-between">
        <Flex>
          <PatientCard
            url={profilePictureUrl}
            onError={() => setProfilePictureUrl(undefined)}
            patientName={
              `${patientProfile?.firstName} ${patientProfile?.lastName}` || ''
            }
            patientDOB={
              formatDate(patientProfile?.dateOfBirth) || 'No DOB entered'
            }
            patientAge={patientAge}
          />
        </Flex>
        {formState.values.submitted && (
          <>
            <Flex flexDirection="column" width="35%" margin="30px 0 0 0">
              <Typography as="h3" fontWeight="700" margin="0 0 10px 0">
                Claim Status:
              </Typography>
              <StatusCard>
                <Flex alignItems="center">
                  <Typography as="p" fontWeight="500" margin="0">
                    Status:
                  </Typography>
                  <Typography
                    as="h3"
                    fontWeight="700"
                    className="status-text"
                    color={statusColor}
                    padding="0 0 0 10px"
                  >
                    {formState.values.submitted
                      ?.split('_')
                      .join(' ')
                      .toLowerCase()}
                  </Typography>
                </Flex>
                <Typography
                  as="p"
                  className="border-none"
                  fontWeight="500"
                  margin="20px 0"
                  {...text({
                    name: 'returnedClaimID',
                  })}
                >
                  Claim ID:{' '}
                  {formState.values.returnedClaimID === '-- pending --'
                    ? 'Pending'
                    : formState.values.returnedClaimID}
                </Typography>
              </StatusCard>
            </Flex>
          </>
        )}
      </Flex>
    </>
  )
}
