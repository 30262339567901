import { gql } from 'apollo-boost'
import { LoginInput, Query } from 'src/schema-types'
import { publicApolloClient } from 'src/settings/apolloClient'

export interface LoginResponse {
  login: Query['login']
}

/** gets all the form entries */
export const LOGIN = gql`
  query login($input: LoginInput!) {
    login(input: $input) {
      AccessToken
      ExpiresIn
      IdToken
      RefreshToken
      TokenType
    }
  }
`
export const queryLogin = async (fields: LoginInput) => {
  const res = await publicApolloClient.query<LoginResponse>({
    query: LOGIN,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      input: {
        ...fields,
      },
    },
  })

  if (res.errors?.length) {
    throw new Error(res.errors[0].message)
  }
  return res.data.login
}
