import clsx from 'clsx'
import * as React from 'react'

interface TabSelectionElemProps {
  tabs: { id: string; name: string }[]
  onClick?: () => void
  value: string
  onChange: (value: string) => void
  markedIds?: string[]
}

export const TabSelectionElem: React.FC<TabSelectionElemProps> = ({
  tabs,
  value,
  onClick,
  onChange,
  markedIds = [],
}) => {
  return (
    <div className="my-5">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          className="block w-full focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"
          onChange={(e) => onChange(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.id} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200 w-max">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                type="button"
                className={clsx(
                  tab.id === value
                    ? 'border-green-400 text-green-500'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'flex items-center mr-4 py-4 px-1 text-center font-bold focus:outline-none border-b-2 text-sm'
                )}
                aria-current={tab.id === value ? 'page' : undefined}
                onClick={() => {
                  onClick && onClick()
                  onChange(tab.id)
                }}
              >
                {markedIds.includes(tab.id) && (
                  <div className="rounded-full bg-primary w-2 h-2 mr-2"></div>
                )}
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
