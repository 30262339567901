import clsx from 'clsx'
import React from 'react'
import { QueryPracticeClinicalNoteTemplatesQuery } from 'src/operations-types'

type ClinicalNotesTemplatesListProps = {
  pageBasedClinicalNoteTemplates: NonNullable<
    QueryPracticeClinicalNoteTemplatesQuery['getPractice']
  >['clinicalNoteModularTemplates']
  selectedTemplate: string | undefined
  isCreatingModal: boolean
  setSelectedTemplate: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const ClinicalNotesTemplatesList: React.FC<ClinicalNotesTemplatesListProps> =
  ({
    pageBasedClinicalNoteTemplates,
    selectedTemplate,
    setSelectedTemplate,
    isCreatingModal,
  }) => {
    const renderOption = (
      templateId: string,
      label: string,
      onClick: () => void
    ) => {
      return (
        <label
          onClick={onClick}
          className={clsx(
            'font-medium flex justify-start items-center space-x-2 cursor-pointer capitalize rounded-lg p-5',
            'active:bg-primary active:bg-opacity-20',
            'hover:bg-primary hover:bg-opacity-10'
          )}
        >
          <div
            className={clsx(
              'w-3 h-3 rounded-full border mr-3',
              selectedTemplate === templateId
                ? 'bg-primary border-primary'
                : 'border-gray-500'
            )}
          />
          {label}
        </label>
      )
    }

    return (
      <div>
        <div className="space-y-3">
          {pageBasedClinicalNoteTemplates
            .filter((template) => template.isActive)
            .map((template) =>
              renderOption(template.id, template.name, () =>
                setSelectedTemplate(template.id)
              )
            )}
        </div>
        {isCreatingModal && (
          <>
            <h3 className="font-medium mx-auto mt-7 mb-5">
              I prefer the old clinical notes
            </h3>
            {renderOption('legacy', 'Old clinical notes model', () =>
              setSelectedTemplate('legacy')
            )}
          </>
        )}
      </div>
    )
  }
