// Keeps the first character capitalized and lowercases the rest of the word
export const formatAllUppercaseText = (val: string) => {
  return val
    .split('_')
    .map((s) => s.charAt(0) + s.substring(1).toLowerCase())
    .join(' ')
}

export const formatPatientNames = (val: string | undefined) => {
  if (val) {
    const uppercaseFirstLetter = val[0].toUpperCase()
    return `${uppercaseFirstLetter}${val.slice(1)}`
  } else {
    return undefined
  }
}
