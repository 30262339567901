import * as React from 'react'
import { useToasts } from 'react-toast-notifications'
import { DialogModal } from 'src/components/molecules/DialogModal'
import { useStore } from 'src/stores/store'

interface AddAmendmentModalProps {
  isOpen: boolean
  onClose: () => void
}

export const AddAmendmentModal: React.FC<AddAmendmentModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { clinicalNotesStore } = useStore()
  const { addToast } = useToasts()
  const onPrimaryClick = async () => {
    try {
      await clinicalNotesStore.amendments.createAmendment()
      addToast('Amendment created', { appearance: 'success' })
      if (clinicalNotesStore.amendments.errorCreating) {
        addToast(`Cannot create amendment`, {
          appearance: 'error',
          onDismiss: clinicalNotesStore.amendments.cleanErrors,
        })
      }
    } catch (error) {
      addToast(`Cannot create amendment`, {
        appearance: 'error',
        onDismiss: clinicalNotesStore.amendments.cleanErrors,
      })
    } finally {
      onClose()
    }
  }
  return (
    <DialogModal
      headerText="Make amendments to clinical note"
      primaryButtonText="Continue"
      primaryButtonCallback={onPrimaryClick}
      isPrimaryButtonLoading={clinicalNotesStore.amendments.isCreating}
      bodyContent="This action and the adjustments made will be saved in the note records and can't be undone. Are you sure you want to continue?"
      open={isOpen}
      setOpen={() => onClose()}
    />
  )
}
