import { makeAutoObservable, runInAction } from 'mobx'
import { writeLog } from 'src/gql/mutations/writeToLog'
import * as TOperation from 'src/operations-types'
import { TAnswer, TModule } from 'src/pages/ClinicalNotesv2/types'
import * as T from 'src/schema-types'
import { ClinicalNoteServices } from 'src/services/clinicalNote'
import { RootStore } from 'src/stores/store'
import { sortByOrdinance } from 'src/utils/sorting'
import { ClinicalNoteAmendmentStore } from './clinicalNoteAmendments'
import { ClinicalNoteSpecialModulesStore } from './clinicalNoteSpecialModules'
import { ClinicalNoteTemplatesStore } from './clinicalNoteTemplates'
import { fakeClinicalNotes } from './mockData'

type TModuleField = TModule['fields'][number]

type TAmend = NonNullable<
  NonNullable<
    NonNullable<
      TOperation.QueryClinicalNoteV2Query['getClinicalNoteModular']
    >['amendments']
  >[number]
>

interface TParsedAmend extends TAmend {
  newField: ParsedClinicalNoteField
}

export interface ParsedClinicalNoteField extends TModuleField {
  answer: TAnswer
  amends?: (TParsedAmend | null)[] | null
}
export class ClinicalNotesStore {
  currentPageId = ''
  clinicalNotes?: T.ClinicalNote[]
  clinicalNote?: TOperation.QueryClinicalNoteV2Query['getClinicalNoteModular']
  isSubmittingClinicalNote = false
  fetching = false
  error = ''

  shouldResetAnswers = false

  diagnosisId? = ''
  referringDoctorNPI? = ''

  isSavingDiagnosis? = false

  creatingCN = false
  errorCreatingCN = ''

  isCountdownAfterCopy = false

  copyingCN = false
  errorCopyingCN = ''

  savingAnswer = false
  errorSavingAnswer = ''

  displayBilling = false

  progressFieldId = ''
  goalFieldId = ''

  rootStore: RootStore
  services: ClinicalNoteServices
  templates: ClinicalNoteTemplatesStore
  amendments: ClinicalNoteAmendmentStore
  specialModules: ClinicalNoteSpecialModulesStore

  constructor(rootStore: RootStore, services: ClinicalNoteServices) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
    this.services = services
    this.templates = new ClinicalNoteTemplatesStore(this, services)
    this.amendments = new ClinicalNoteAmendmentStore(this, services)
    this.specialModules = new ClinicalNoteSpecialModulesStore(this, services)
  }

  createClinicalNote = async (
    templateId: string,
    patientId: string,
    appointmentId: string
  ) => {
    try {
      runInAction(() => {
        this.creatingCN = true
      })

      const clinicalNote = await this.services.createClinicalNote({
        clinicalNoteModularTemplateId:
          templateId === 'legacy' ? '' : templateId,
        patientProfileId: patientId,
        appointmentId,
      })

      runInAction(() => {
        this.clinicalNote = clinicalNote
      })
    } catch (err) {
      writeLog((err as Error).message)

      runInAction(() => {
        this.errorCreatingCN = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.creatingCN = false
      })
    }
  }

  toggleShouldResetAnswer = () => {
    runInAction(() => {
      this.shouldResetAnswers = !this.shouldResetAnswers
    })
  }

  setDisplayBilling = (val: boolean) => {
    runInAction(() => {
      this.displayBilling = val
    })
  }

  copyClinicalNote = async (sourceCntId: string, targetCntId: string) => {
    try {
      runInAction(() => {
        this.copyingCN = true
      })
      const clinicalNote = await this.services.copyClinicalNote({
        sourceCntId,
        targetCntId,
      })
      runInAction(() => {
        this.clinicalNote = clinicalNote
        this.shouldResetAnswers = true
      })
    } catch (err) {
      writeLog((err as Error).message)

      runInAction(() => {
        this.errorCopyingCN = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.copyingCN = false
        this.isCountdownAfterCopy = true
        setTimeout(() => {
          this.isCountdownAfterCopy = false
        }, 3000)
      })
    }
  }

  fetchClinicalNotes = async () => {
    try {
      runInAction(() => {
        this.fetching = true
      })

      await new Promise((resolve) => setTimeout(resolve, 3000))

      runInAction(() => {
        this.clinicalNotes = fakeClinicalNotes as unknown as T.ClinicalNote[]
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.error = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  fetchClinicalNote = async (id: string) => {
    try {
      runInAction(() => {
        this.fetching = true
      })
      let clinicalNote = await this.services.getClinicalNote(id)
      let progressGoalField: TModuleField
      let goalField: TModuleField

      const hasOmtModules = clinicalNote?.template?.pages.some((page) => {
        return page.sections.some((section) => section.isModulePicker)
      })

      clinicalNote?.template?.pages.some((page) =>
        page.sections.some((section) =>
          section.modules.some((module) =>
            module.fields.some((field) => {
              if (
                field.elementType ===
                T.ClinicalNoteElementTypes.FormattedTextBlock
              ) {
                progressGoalField = field
              }
              if (field.elementType === T.ClinicalNoteElementTypes.Goal) {
                goalField = field
              }
              if (goalField && progressGoalField) {
                return true
              }
              return false
            })
          )
        )
      )

      if (hasOmtModules && clinicalNote && clinicalNote.template) {
        const omtModules = await this.services.getSpecialModules()

        // Sort OMT modules by label alphabetically
        omtModules.sort((a, b) => a.label.localeCompare(b.label))

        clinicalNote = {
          ...clinicalNote,
          template: {
            ...clinicalNote.template,
            pages: clinicalNote.template.pages.map((elem) => ({
              ...elem,
              sections: elem.sections.map((section) => {
                if (section.isModulePicker) {
                  return {
                    ...section,
                    modules: omtModules,
                  }
                }
                return section
              }),
            })),
          },
        }
      }

      runInAction(() => {
        this.clinicalNote = clinicalNote
        this.diagnosisId = clinicalNote?.icdCode?.id
        this.progressFieldId = progressGoalField ? progressGoalField.id : ''
        this.goalFieldId = goalField ? goalField.id : ''

        this.currentPageId =
          this.isClinicalNoteOwner &&
          (clinicalNote?.status === T.ClinicalNoteStatus.InProgress ||
            this.amendments.isAmendmentInProgress)
            ? clinicalNote?.template?.pages.slice().sort(sortByOrdinance)[0]
                .id || ''
            : 'REVIEW'

        if (this.displayBilling) {
          this.currentPageId = 'BILLING'
        }
      })
      this.specialModules.setFirstVisualModules()
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.error = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  // ICD codes (diagnosis code)
  saveDiagnosis = async (diagnosisId: string) => {
    try {
      if (!this.clinicalNote?.id) {
        return
      }

      runInAction(() => {
        this.isSavingDiagnosis = true
      })
      const icdCode = await this.services.saveDiagnosis(
        this.clinicalNote.id,
        diagnosisId
      )

      runInAction(() => {
        this.diagnosisId = icdCode?.id
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.error = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.isSavingDiagnosis = false
      })
    }
  }

  get diagnosis() {
    return this.rootStore.practiceStore.billingCodes?.find(
      (billingCode) => billingCode.id === this.diagnosisId
    )?.description
  }

  saveReferringDoctor = (referringDoctorNPI: string) => {
    runInAction(() => {
      this.referringDoctorNPI = referringDoctorNPI
    })
  }

  get referringDoctor() {
    const selectedDoctor = this.rootStore.practiceStore.referringDoctors?.find(
      (referringDoctor) => referringDoctor.NPI === this.referringDoctorNPI
    )
    return (
      selectedDoctor && {
        value: selectedDoctor.NPI,
        label: selectedDoctor.name,
      }
    )
  }

  saveClinicalNoteAnswer = async (fieldId: string, answer: string) => {
    const prevClinicalNote = this.clinicalNote && { ...this.clinicalNote }
    if (!answer || this.isCountdownAfterCopy) {
      return
    }
    try {
      runInAction(() => {
        this.savingAnswer = true
      })

      const newAnswer = await this.services.saveAnswer({
        clinicalNoteFieldId: fieldId,
        clinicalNoteModularId: this.clinicalNote?.id || '',
        answer,
      })

      const index =
        this.clinicalNote?.answers?.findIndex(
          (answer) => answer?.field.id === fieldId
        ) || -1

      const updatedAnswers =
        index > -1
          ? this.clinicalNote?.answers?.map((elem) => {
              if (elem?.field.id === fieldId) {
                return newAnswer
              }
              return elem
            })
          : [...(this.clinicalNote?.answers || []), newAnswer]

      runInAction(() => {
        if (this.clinicalNote) {
          this.clinicalNote = {
            ...this.clinicalNote,
            answers: updatedAnswers,
          }
        }
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.errorSavingAnswer = (err as Error).message
        this.clinicalNote = prevClinicalNote
      })
    } finally {
      runInAction(() => {
        this.savingAnswer = false
      })
    }
  }

  submitClinicalNote = async () => {
    try {
      runInAction(() => {
        this.isSubmittingClinicalNote = true
      })
      await this.services.submitClinicalNote(this.clinicalNote?.id || '')
      runInAction(() => {
        if (this.clinicalNote) {
          this.clinicalNote = {
            ...this.clinicalNote,
            status: T.ClinicalNoteStatus.Final,
          }
          this.currentPageId = 'REVIEW'
        }
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.error = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.isSubmittingClinicalNote = false
      })
    }
  }

  get fieldsWithParsedAnswers() {
    const data: Record<string, ParsedClinicalNoteField[]> = {}
    this.clinicalNote?.template?.pages.forEach((page) =>
      page.sections.forEach((section) =>
        section.modules.forEach((module) => {
          const fields: ParsedClinicalNoteField[] = module.fields.map(
            (field) => {
              const raw = this.clinicalNote?.answers?.find(
                (answer) => answer?.field.id === field.id
              )?.answer

              const answer = raw ? JSON.parse(raw) : ''
              if (!!this.clinicalNote?.amendments) {
                return {
                  ...field,
                  answer,
                  amends: this.clinicalNote.amendments
                    ?.filter((amend) =>
                      amend?.answers?.some(
                        (answer) => answer?.field.id === field.id
                      )
                    )
                    .map((amend) => {
                      if (amend) {
                        const raw = amend.answers?.find(
                          (answer) => answer?.field.id === field.id
                        )?.answer

                        const answer = raw ? JSON.parse(raw) : ''

                        return {
                          ...amend,
                          newField: { ...field, answer },
                          // answers: amend?.answers?.filter(answer => answer?.field.id === field.id) || []
                        }
                      }
                      return amend
                    }),
                }
              }
              return {
                ...field,
                answer,
              }
            }
          )
          data[module.id] = fields
        })
      )
    )
    return data
  }

  fieldsWithParsedAnswer(fieldGroup: TModule) {
    const fields: ParsedClinicalNoteField[] = fieldGroup.fields.map((field) => {
      const raw = this.clinicalNote?.answers?.find(
        (answer) => answer?.field.id === field.id
      )?.answer

      const answer = raw ? JSON.parse(raw) : ''

      return {
        ...field,
        answer,
      }
    })

    return fields
  }

  get defaultValues() {
    if (this.clinicalNote) {
      const answers = this.clinicalNote.answers
      const defaultAnswers: Record<string, string | string[]> = {}
      answers?.forEach((elem) => {
        if (elem && elem.answer?.length) {
          defaultAnswers[elem.field.id] = JSON.parse(elem.answer)
        }
      })
      this.clinicalNote.amendments
        ?.slice()
        .sort((a, b) => a?.createdOn - b?.createdOn)
        .forEach((amendment) => {
          amendment?.answers?.forEach((amendAnswer) => {
            if (amendAnswer && amendAnswer.answer.length) {
              defaultAnswers[amendAnswer.field.id] = JSON.parse(
                amendAnswer.answer
              )
            }
          })
        })
      return defaultAnswers
    }
    return null
  }

  updateVisibility = (
    modulesId: string[],
    action: 'hide' | 'display' = 'display'
  ) => {
    if (this.clinicalNote && this.clinicalNote.template) {
      const currentPageIndex = this.clinicalNote.template.pages.findIndex(
        (page) => page.id === this.currentPageId
      )

      const currentSectionsIds = this.currentPage?.sections
        .filter((section) => {
          const module = section.modules.find((module) =>
            modulesId.includes(module.id)
          )
          if (module) {
            return true
          }
          return false
        })
        .map((section) => section.id)

      if (currentSectionsIds?.length && currentPageIndex >= 0) {
        const updatedClinicalNote = {
          ...this.clinicalNote,
          clinicalNoteTemplate: {
            ...this.clinicalNote.template,
            pages: this.clinicalNote.template.pages.map((page) => {
              if (page.id === this.currentPageId) {
                return {
                  ...page,
                  sections: page.sections.map((section) => {
                    if (currentSectionsIds.includes(section.id)) {
                      return {
                        ...section,
                        modules: section.modules.map((module) => {
                          if (modulesId.includes(module.id)) {
                            return {
                              ...module,
                              isVisible: action === 'display',
                            }
                          }
                          return module
                        }),
                      }
                    }
                    return section
                  }),
                }
              }
              return page
            }),
          },
        }
        runInAction(() => {
          this.clinicalNote = updatedClinicalNote
        })
      }
    }
  }

  removeDefaultAnswers = (moduleId: string) => {
    if (
      this.clinicalNote &&
      this.clinicalNote.template &&
      this.allModulesInCurrentPage
    ) {
      const module = this.allModulesInCurrentPage.find(
        (module) => module.id === moduleId
      )

      if (!!module && this.clinicalNote.answers) {
        const fieldsId = module.fields.map((field) => field.id)
        const updatedAnswers = this.clinicalNote.answers.map((answer) => {
          if (answer && fieldsId.includes(answer.field.id)) {
            this.saveClinicalNoteAnswer(answer.field.id, '')
            return {
              ...answer,
              answer: '',
            }
          }
          return answer
        })
        const updatedClinicalNote = {
          ...this.clinicalNote,
          answers: updatedAnswers,
        }
        runInAction(() => {
          this.clinicalNote = updatedClinicalNote
        })
      }
    }
  }

  get clinicalNoteSections() {
    if (this.clinicalNote && this.clinicalNote.template) {
      return this.clinicalNote.template.pages
        .slice()
        .sort(sortByOrdinance)
        .flatMap((page) => page.sections)
    }
    return []
  }

  // Pages
  get clinicalNotePages() {
    if (this.clinicalNote && this.clinicalNote.template) {
      const pages = this.clinicalNote.template.pages
        .slice()
        .sort(sortByOrdinance)
      return pages
    }
    return []
  }

  get currentPage() {
    return this.clinicalNotePages.find((page) => page.id === this.currentPageId)
  }

  setCurrentPage(pageId: string) {
    runInAction(() => {
      this.currentPageId = pageId
    })
  }

  get allModulesInCurrentPage() {
    if (this.currentPage) {
      const data = this.currentPage.sections
        .slice()
        .sort(sortByOrdinance)
        .flatMap((section) => section.modules)
      return data
    }
    return []
  }

  get visibleModulesByPage() {
    if (this.clinicalNote && this.currentPage) {
      const answersFieldId =
        this.clinicalNote.answers
          ?.slice()
          .filter((answer) => !!answer?.answer)
          .map((answer) => answer?.field.id) || []

      return (
        this.allModulesInCurrentPage.filter((module) => {
          if (module.type !== TOperation.ClinicalNoteModuleType.Generic)
            return false
          if (!(module as any).isVisible) return true
          return !!module.fields.find((field) =>
            answersFieldId.includes(field.id)
          )
        }) || []
      )
    }
    return []
  }

  get hiddenModulesByPage() {
    const visibleIds = this.visibleModulesByPage.map((module) => module.id)
    return (
      this.allModulesInCurrentPage.filter(
        (module) => !visibleIds.includes(module.id)
      ) || []
    )
  }

  get isClinicalNoteOwner() {
    return (
      this.rootStore.profileStore.profile?.id ===
      this.clinicalNote?.createdBy?.id
    )
  }

  updateCreatingCN(val: boolean) {
    runInAction(() => {
      this.creatingCN = val
    })
  }

  cleanError = () => {
    runInAction(() => {
      this.error = ''
      this.errorCopyingCN = ''
      this.errorSavingAnswer = ''
      this.errorCreatingCN = ''
    })
  }
}
