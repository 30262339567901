import { Flex, Option, TextField, Typography } from '@ps-ui/components'
import React, { useCallback, useEffect, useState } from 'react'
import { Select } from 'react-functional-select'
import { useToasts } from 'react-toast-notifications'
import { ModalWindow } from 'src/components/molecules'
import { FunctionalDropdown } from 'src/components/molecules/FunctionalDropdown'
import { QueryPracticeBillingCodesResponse } from 'src/gql/queries/queryPracticeBillingCodes'
import { Scalars } from 'src/schema-types'
import { v4 as uuidv4 } from 'uuid'
import {
  CloseButton,
  CptCodeContainer,
  CptContainer,
  SelectedCodeContainer,
  StyledCard,
} from '../../../pages/CreateClaim/styles'
import {
  CptTextContainer,
  IcdCodeContainer,
  IcdContainer,
  InnerModalContent,
} from './styles'

type ListedBillingCodes = {
  id: Scalars['ID']
  code: string
  description: string | undefined | null
  type: string
}

type ChargeCodes = {
  procedureCode: ListedBillingCodes[] | undefined
  diagnosisCodes: ListedBillingCodes[] | undefined
  dateOfService: Scalars['AWSDate']
  chargeFee: string
  units: number
  modifiers?: string[]
}

type BillingCodesProps = {
  cptSelected: ListedBillingCodes[]
  icdSelected: ListedBillingCodes[]
  chargesList: ChargeCodes[]
  dummy: React.MutableRefObject<HTMLElement | null>
  billingCodes: QueryPracticeBillingCodesResponse | undefined
  setCptSelected: React.Dispatch<React.SetStateAction<ListedBillingCodes[]>>
  setIcdSelected: React.Dispatch<React.SetStateAction<ListedBillingCodes[]>>
  setChargesList: React.Dispatch<React.SetStateAction<ChargeCodes[]>>
  serviceDate: string
  setSelectedUnit: React.Dispatch<React.SetStateAction<Option>>
  selectedUnit: Option
  selectedModifier: Option[] | undefined
  setSelectedModifier: React.Dispatch<
    React.SetStateAction<Option[] | undefined>
  >
}

const UNIT_METHODS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
]

export const BillingCodes: React.FC<BillingCodesProps> = ({
  billingCodes,
  cptSelected,
  setIcdSelected,
  setCptSelected,
  icdSelected,
  dummy,
  serviceDate,
  chargesList,
  setChargesList,
  setSelectedUnit,
  selectedUnit,
  setSelectedModifier,
  selectedModifier,
}) => {
  const [displayICDCodes, setDisplayICDCodes] = useState(false)
  const [displayCPTCodes, setDisplayCPTCodes] = useState(false)
  const [icdSearchValue, setIcdSearchValue] = useState('')
  const [cptSearchValue, setCptSearchValue] = useState('')
  const [showIcdModal, setShowIcdModal] = useState(false)
  //Multiselect Values
  const getOptionValue = useCallback(
    (option: Option): string => option.value,
    []
  )
  const getOptionLabel = useCallback(
    (option: Option): string => option.label,
    []
  )
  const onModifierChange = useCallback((option: Option[]): void => {
    setSelectedModifier([...option])
    // eslint-disable-next-line
  }, [])

  const { addToast } = useToasts()

  // Scrolls to bottom of the page when lists populate to avoid the user having to double scroll
  useEffect(() => {
    if (displayCPTCodes) {
      dummy?.current?.scrollIntoView({ behavior: 'smooth' })
    }
    // eslint-disable-next-line
  }, [displayCPTCodes])

  //CPT/ICD codes
  const billCodes = billingCodes?.getPractice?.billingCodes
    ?.map((data) => {
      return {
        code: data.code,
        description: data.description,
        id: data.id,
        type: data.type,
      }
    })
    .sort((a, b) => {
      if (a.code < b.code) {
        return -1
      }
      if (a.code > b.code) {
        return 1
      }
      return 0
    })

  const cptModifierOptions = billCodes
    ?.filter((data) => data.type === 'CPT_MODIFIER')
    .map((mod) => {
      return { label: mod.code, value: mod.id }
    })

  //Add CPT/ICD codes to claim
  const handleCodeClick = (data: string) => {
    const selectedCode = billingCodes?.getPractice?.billingCodes
      ?.map(({ id, code, description, type }) => {
        return {
          code,
          description,
          id,
          type,
        }
      })
      .filter(({ id }) => id === data)
    //Check which type of code
    const cptCode = selectedCode?.filter((codeType) => codeType.type === 'CPT')
    const icdCode = selectedCode?.filter((codeType) => codeType.type === 'ICD')
    if (selectedCode && icdSelected.length) {
      const icdIndex =
        icdSelected && icdSelected.findIndex((item) => item.id === data)
      if (selectedCode[0].id === icdSelected[icdIndex]?.id) {
        addToast('No duplicate ICD codes', {
          appearance: 'error',
        })
        return
      }
    }

    //Update arrays
    if (cptCode?.length) {
      const item = chargesList?.find(
        (item) =>
          item.procedureCode?.length && item.procedureCode[0]?.id === data
      )
      if (
        selectedCode &&
        item?.procedureCode &&
        selectedCode[0].id === item.procedureCode[0]?.id
      ) {
        addToast('No duplicate CPT codes', {
          appearance: 'error',
        })
        return
      }

      cptCode && setCptSelected([...cptSelected, cptCode[0]])
      setShowIcdModal(true)
      setDisplayCPTCodes(false)
      setCptSearchValue('')
    }
    if (icdCode?.length && icdSelected.length <= 3) {
      setIcdSelected([...icdSelected, icdCode[0]])
    }
  }

  //Remove CPT/ICD code from the passed array
  const handleRemoveCptClick = (data: string | undefined) => {
    let oldList = chargesList
    const itemIndex =
      chargesList &&
      chargesList.findIndex(
        (item) => item.procedureCode && item.procedureCode[0]?.id === data
      )
    oldList.splice(itemIndex, 1)
    setChargesList([...oldList])
  }
  const handleRemoveIcdClick = (data: string) => {
    let oldList = icdSelected
    const itemIndex =
      icdSelected && icdSelected.findIndex((item) => item.id === data)
    oldList.splice(itemIndex, 1)
    setIcdSelected([...oldList])
  }

  const handleCodeSubmit = () => {
    let currentCharges = chargesList
    const newCharge = [
      {
        procedureCode: cptSelected,
        diagnosisCodes: icdSelected,
        dateOfService: serviceDate,
        chargeFee: '',
        units: Number(selectedUnit.value),
        modifiers: selectedModifier
          ? selectedModifier.map((mod) => mod.label)
          : undefined,
      },
    ]

    currentCharges.push(...newCharge)
    setChargesList([...currentCharges])
    setIcdSelected([])
    setCptSelected([])
    setSelectedUnit({ value: '1', label: '1' })
    setShowIcdModal(false)
    setSelectedModifier(undefined)
  }

  const handleClose = () => {
    setShowIcdModal(false)
    setIcdSearchValue('')
    setIcdSelected([])
    setCptSelected([])
    setSelectedUnit({ value: '1', label: '1' })
    setSelectedModifier(undefined)
  }

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" margin="25px 0">
        <Flex width="45%" flexDirection="column">
          <StyledCard>
            <Flex justifyContent="space-between">
              <Typography as="h3" fontWeight="700" padding="0 0 15px 0">
                Search CPT Codes
              </Typography>
              {displayCPTCodes && (
                <CloseButton
                  variant="filled"
                  onClick={() => {
                    setDisplayCPTCodes(false)
                    setCptSearchValue('')
                  }}
                  color="primary"
                >
                  Close List
                </CloseButton>
              )}
            </Flex>
            <TextField
              label=""
              id="cpt"
              placeholder="Start typing a code..."
              white
              border
              margin="15px 0"
              width="100%"
              value={cptSearchValue}
              onFocus={() => {
                setDisplayCPTCodes(true)
                if (displayICDCodes) {
                  setDisplayICDCodes(false)
                }
              }}
              onChange={(e) => setCptSearchValue(e.currentTarget.value)}
            />
            {displayCPTCodes && (
              <CptContainer>
                {billCodes
                  ?.map((data) => {
                    if (data.type === 'CPT') {
                      return (
                        <CptCodeContainer
                          key={data.id}
                          id={data.id}
                          onClick={(e) => handleCodeClick(e.currentTarget.id)}
                        >
                          {data.code} - {data.description}
                        </CptCodeContainer>
                      )
                    }
                    return <> </>
                  })
                  .filter((text) => {
                    return (
                      text?.props.children[0]
                        .toLowerCase()
                        .includes(cptSearchValue.toLowerCase()) ||
                      text?.props.children[2]
                        .toLowerCase()
                        .includes(cptSearchValue.toLowerCase())
                    )
                  })}
              </CptContainer>
            )}
          </StyledCard>
        </Flex>
        <Flex width="45%">
          <StyledCard>
            <Typography as="h3" fontWeight="700" padding="0 0 15px 0">
              CPT Codes
            </Typography>
            <>
              {chargesList &&
                chargesList?.map((codes, codesIdx) => {
                  return (
                    <SelectedCodeContainer
                      key={codesIdx}
                      id={codes.procedureCode && codes.procedureCode[0]?.id}
                    >
                      <Flex>
                        <button
                          className="clear-code-btn"
                          onClick={() =>
                            handleRemoveCptClick(
                              codes.procedureCode && codes.procedureCode[0]?.id
                            )
                          }
                        >
                          X
                        </button>
                        <Flex flexDirection="column" width="100%">
                          <CptTextContainer>
                            <Flex>
                              <Typography
                                as="h4"
                                fontWeight="700"
                                padding="5px"
                              >
                                {codes.procedureCode &&
                                  codes.procedureCode[0]?.code}{' '}
                                -
                                {codes.procedureCode &&
                                  codes.procedureCode[0]?.description}
                              </Typography>
                            </Flex>
                          </CptTextContainer>
                          <Typography as="h4" fontWeight="700" padding="5px">
                            ICD-10 Code(s)
                          </Typography>
                          {codes?.diagnosisCodes
                            ?.sort((a, b) => {
                              if (a.code < b.code) {
                                return -1
                              }
                              if (a.code > b.code) {
                                return 1
                              }
                              return 0
                            })
                            ?.map((diagData) => {
                              return (
                                <Typography
                                  key={diagData.code}
                                  as="p"
                                  fontWeight="500"
                                  padding="5px 15px 5px 20px"
                                >
                                  {diagData.code} - {diagData.description}
                                </Typography>
                              )
                            })}
                          <Flex padding="10px 0 0 0">
                            <Flex flexDirection="column" padding="0 20px 0 0">
                              <Typography
                                as="h4"
                                fontWeight="700"
                                padding="5px"
                              >
                                Units:
                              </Typography>
                              <Typography
                                as="h4"
                                fontWeight="700"
                                padding="5px"
                              >
                                {codes.units}
                              </Typography>
                            </Flex>
                            <Flex flexDirection="column">
                              <Typography
                                as="h4"
                                fontWeight="700"
                                padding="5px"
                              >
                                Modifier(s):
                              </Typography>
                              <Typography
                                as="h4"
                                fontWeight="700"
                                padding="5px"
                              >
                                {codes.modifiers &&
                                codes?.modifiers?.length >= 1
                                  ? codes?.modifiers?.join(', ')
                                  : '--'}
                              </Typography>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </SelectedCodeContainer>
                  )
                })}
            </>
          </StyledCard>
        </Flex>
      </Flex>
      <span ref={dummy}></span>
      <ModalWindow
        isOpen={showIcdModal}
        onPrimaryButtonClick={() => handleCodeSubmit()}
        textPrimaryButton="Add to claim"
        onSecondaryButtonClick={handleClose}
        onCloseModal={handleClose}
        textSecondaryButton="Close modal"
        isDisabled={icdSelected.length < 1}
        modalSteps={[
          {
            header: cptSelected.length
              ? `${cptSelected[0].code} - ${cptSelected[0].description}`
              : '',
            subheader: '',
            content: (
              <InnerModalContent>
                <Flex flexDirection="column">
                  {icdSelected.length <= 3 ? (
                    <Flex flexDirection="column">
                      <TextField
                        label=""
                        id="icd"
                        placeholder="Start typing a code..."
                        white
                        border
                        margin="10px 0 20px 0"
                        width="100%"
                        value={icdSearchValue}
                        onFocus={() => {
                          setDisplayICDCodes(true)
                          if (displayCPTCodes) {
                            setDisplayCPTCodes(false)
                          }
                        }}
                        onChange={(e) =>
                          setIcdSearchValue(e.currentTarget.value)
                        }
                      />

                      <IcdContainer>
                        {billCodes
                          ?.map((data) => {
                            if (data.type === 'ICD') {
                              return (
                                <CptCodeContainer
                                  key={data.id}
                                  id={data.id}
                                  onClick={(e) =>
                                    handleCodeClick(e.currentTarget.id)
                                  }
                                >
                                  {data.code} - {data.description}
                                </CptCodeContainer>
                              )
                            }
                            return <> </>
                          })
                          .filter((text) => {
                            return (
                              text?.props.children[0]
                                .toLowerCase()
                                .includes(icdSearchValue.toLowerCase()) ||
                              text?.props.children[2]
                                .toLowerCase()
                                .includes(icdSearchValue.toLowerCase())
                            )
                          })}
                      </IcdContainer>
                    </Flex>
                  ) : (
                    <div>
                      <Typography
                        as="p"
                        fontWeight="700"
                        color="subtext"
                        padding="20px 0"
                      >
                        You can only add a maximum of 4 ICD-10 codes per
                        procedure, if you would like to change your selections,
                        please remove one of the codes.
                      </Typography>
                    </div>
                  )}
                </Flex>
                <Flex flexDirection="column">
                  {icdSelected.length >= 1 && (
                    <Typography as="h4" fontWeight="700" padding="0 0 10px 0">
                      Selected ICD-10 Codes:
                    </Typography>
                  )}
                  {icdSelected?.map((data) => {
                    if (data.type === 'ICD') {
                      return (
                        <IcdCodeContainer key={data.id} id={data.id}>
                          <Flex>
                            <button
                              className="clear-code-btn"
                              onClick={(e) =>
                                handleRemoveIcdClick(e.currentTarget.id)
                              }
                            >
                              X
                            </button>
                            <Typography
                              as="p"
                              fontWeight="700"
                              padding="5px 15px"
                            >
                              {data.code} - {data.description}
                            </Typography>
                          </Flex>
                        </IcdCodeContainer>
                      )
                    }
                    return <> </>
                  })}
                  <Flex
                    flexDirection="column"
                    width="27%"
                    justifyContent="center"
                  >
                    <FunctionalDropdown
                      onOptionChange={(opt) => {
                        setSelectedUnit(opt as Option)
                      }}
                      placeholder="Select an Option"
                      label="Units"
                      dropdownOption={UNIT_METHODS}
                      currentSelection={selectedUnit}
                    />
                  </Flex>
                  <Flex
                    flexDirection="column"
                    width="100%"
                    justifyContent="center"
                  >
                    <Typography as="h4" padding="8px 0 8px 0">
                      Modifiers
                    </Typography>
                    <Select
                      key={uuidv4()}
                      isMulti
                      placeholder="Select modifier(s)"
                      closeMenuOnSelect={
                        selectedModifier && selectedModifier?.length >= 3
                          ? true
                          : false
                      }
                      options={cptModifierOptions}
                      initialValue={selectedModifier}
                      getOptionValue={getOptionValue}
                      getIsOptionDisabled={() =>
                        selectedModifier && selectedModifier?.length >= 4
                          ? true
                          : false
                      }
                      getOptionLabel={getOptionLabel}
                      onOptionChange={onModifierChange}
                    />
                    {selectedModifier && selectedModifier.length >= 4 && (
                      <div>
                        <Typography
                          as="p"
                          fontWeight="700"
                          color="subtext"
                          padding="20px 0"
                        >
                          You can only add a maximum of 4 modifiers per
                          procedure, if you would like to change your
                          selections, please remove one of the modifiers.
                        </Typography>
                      </div>
                    )}
                  </Flex>
                </Flex>
              </InnerModalContent>
            ),
          },
        ]}
      />
    </Flex>
  )
}
