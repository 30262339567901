import { TCheckAnswer } from 'src/pages/ClinicalNotesv2/types'
import { ClinicalNoteElementTypes } from 'src/schema-types'

export const bodyPartsWithoutLeftOrRight = [
  'Head',
  'Neck',
  'Chest',
  'Abdomen',
  'Pelvis / Groin',
]

export const hasLeftOrRight = (bodyPart: string) =>
  !bodyPartsWithoutLeftOrRight.includes(bodyPart)

export const getArrayDefaultValues = (
  type: ClinicalNoteElementTypes,
  body?: string
) => {
  switch (type) {
    case ClinicalNoteElementTypes.Motion:
      return {
        body,
        side: '',
        properties: {
          'flexion to 90°': '',
          'abduction to 90°': '',
          flexion: '',
          extension: '',
          hyperextension: '',
          abduction: '',
          adduction: '',
          'lateral rotation': '',
          'media rotation': '',
          'plantar flexion': '',
          dorsiflexion: '',
          inversion: '',
          eversion: '',
          pronation: '',
          supination: '',
        },
      }
    case ClinicalNoteElementTypes.Pain:
      return {
        body,
        side: '',
        type: '',
        range: '',
      }
    case ClinicalNoteElementTypes.Hep:
      return {
        cue: '',
        device: '',
        notes: '',
        term: {
          length: '',
          duration: '',
          durationUnit: '',
        },
      }
    case ClinicalNoteElementTypes.Balance:
      return {
        type: '',
        value: '',
      }
    case ClinicalNoteElementTypes.Strength:
      return {
        body,
        type: '',
        side: '',
      }
    case ClinicalNoteElementTypes.Goal:
      return {
        term: {
          length: '',
          duration: '',
          durationUnit: '',
        },
        goal: '',
      }
    case ClinicalNoteElementTypes.Exercise:
      return {
        category: '',
        location: '',
        exercise: '',
        position: '',
        repetitions: '',
        sets: '',
        side: '',
        muscle: '',
      }
    case ClinicalNoteElementTypes.EducationTraining:
      return {
        category: '',
        compliant: '',
        participants: '',
        understanding: '',
      }
    case ClinicalNoteElementTypes.HandwritingSkills:
      return {
        treatmentFocus: [],
        category: '',
        positionLowerExtremety: '',
        positionUpperExtremity: '',
        graspObserved: '',
        trainingCompleted: '',
        aides: [],
        accuracy: '',
        repetitions: '',
        duration: '',
        count: '',
        time: '',
        cueing: '',
        cueingType: '',
        independenceLevel: '',
      }
    case ClinicalNoteElementTypes.GaitTraining:
      return {
        terrain: '',
        weightBearing: '',
        weightBearingPercentage: '',
        weightBearingSide: '',
        repetitions: '',
        distance: '',
        assistiveDevice: '',
        gaitTrainingSkills: '',
        levelOfAssistance: '',
        time: '',
        tolerance: '',
        pattern: '',
        cadence: '',
        support: '',
      }
    default:
      return {}
  }
}

type THandleSingleOrMultiValueArgs = {
  isMulti: boolean
  value: string | string[]
  option: string
  handleChange: (value: string | string[]) => void
}

export const handleSingleOrMultiValue = ({
  isMulti,
  value,
  option,
  handleChange,
}: THandleSingleOrMultiValueArgs) => {
  let newValue: string | string[] | null = null
  if (isMulti) {
    if (Array.isArray(value)) {
      if (!!value) {
        if (value.includes(option)) {
          newValue = value.filter((elem: string) => elem !== option)
        } else {
          newValue = [...value, option]
        }
      }
    } else {
      newValue = [option]
    }
  } else {
    if (option === value) {
      newValue = ''
    } else {
      newValue = option
    }
  }
  if (newValue !== null) {
    handleChange(newValue)
  }
}

export const getLabelByType = (
  measuresCount: number,
  type: ClinicalNoteElementTypes
) => {
  let title = ''
  if (!measuresCount) {
    if (type === ClinicalNoteElementTypes.Motion) {
      title = 'Range of motion is within function limits'
    }
    if (type === ClinicalNoteElementTypes.Strength) {
      title = 'Strength is within function limits'
    }
  } else {
    if (type === ClinicalNoteElementTypes.Motion) {
      title = 'Range of motion is within functional limits except:'
    }
    if (type === ClinicalNoteElementTypes.Strength) {
      title = 'Strength is within functional limits except:'
    }
  }
  return title
}

export const turnIntoOption = (values: string[]) =>
  values.map((elem) => ({ label: elem, value: elem }))

export const rangeOfNumbersInOptions = (count: number) =>
  turnIntoOption(Array.from({ length: count }, (_, i) => `${i + 1}`))

export const justOneAmend = (amends: TCheckAnswer, checkName: string) => {
  const key = Object.keys(amends).find((elem) => elem.includes(checkName))
  return amends[key || '']
}
