import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryPracticeData } from 'src/gql/queries/queryAllDoctors'
import { AppointmentType, Form, PracticeUser, Scalars } from 'src/schema-types'
import { StoreonModule } from 'storeon'

dayjs.extend(utc)

interface PracticeLocation {
  id: string
  name: string
}

export interface SchedulingPracticeState {
  doctors: PracticeUser[]
  loadingPracticeData: boolean
  appointmentTypes: AppointmentType[]
  forms: Form[]
  practiceId: Scalars['ID']
  locations: PracticeLocation[]
  chosenLocationId: string | null
}

export interface SchedulingPracticeEvents {
  'scheduling/load-data': undefined
  'scheduling-data/loading': boolean
  'scheduling-data/set': any
  'schediling-data/set-location': string
}

export const schedulingModule: StoreonModule<
  SchedulingPracticeState,
  SchedulingPracticeEvents
> = (store) => {
  store.on('@init', (state) => ({
    ...state,
    loadingPracticeData: false,
    chosenLocationId: null,
  }))

  store.on('scheduling-data/loading', (state, status) => ({
    ...state,
    loadingPracticeData: status,
  }))
  store.on('scheduling-data/set', (state, data) => ({
    ...state,
    loadingPracticeData: false,
    ...data,
  }))

  store.on('schediling-data/set-location', (state, id) => ({
    ...state,
    chosenLocationId: id,
  }))

  store.on('scheduling/load-data', async (state) => {
    try {
      store.dispatch('scheduling-data/loading', true)
      const data = await queryPracticeData()
      store.dispatch('scheduling-data/set', data)
    } catch (e) {
      console.log(e)
    }
  })
}
