import { LoaderContainer } from '@ps-ui/components'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { Column, useFilters, useSortBy, useTable } from 'react-table'
import { ReactComponent as CaretIcon } from 'src/images/caret-icon.svg'

type TableProps = {
  columns: Column[]
  data: any[]
  filterValues?: { [column: string]: string } | {}
  onClickRow?: (value?: any) => void
  isLoading?: boolean
  onLoadMore?: () => void
  hasMoreData?: boolean
  defaultSortBy?: string
  defaultSortReverse?: boolean
  removeVerticalScroll?: boolean
}

export const Table: React.FC<TableProps> = ({
  columns,
  data,
  filterValues,
  onClickRow,
  isLoading = false,
  onLoadMore,
  hasMoreData,
  defaultSortBy,
  defaultSortReverse = false,
  removeVerticalScroll = false,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: defaultSortBy
        ? {
            sortBy: [
              {
                id: defaultSortBy,
                desc: defaultSortReverse,
              },
            ],
          }
        : undefined,
    },
    useFilters,
    useSortBy
  )

  useEffect(() => {
    if (filterValues) {
      Object.entries(filterValues).forEach(([key, value]) => {
        setFilter(key, value || undefined)
      })
    }
    // if data changes re-apply the filter
  }, [filterValues, setFilter, data])

  const onLoadMoreHandler = () => {
    if (!!onLoadMore) {
      onLoadMore()
    }
  }

  const infiniteRef = useInfiniteScroll({
    loading: !!isLoading,
    hasNextPage: !!hasMoreData,
    scrollContainer: 'window',
    onLoadMore: onLoadMoreHandler,
  })

  if (!data?.length) {
    return (
      <div className="text-center">
        <p>No Data Available</p>
      </div>
    )
  }

  return (
    <>
      <div
        style={{ minHeight: 50 }}
        ref={infiniteRef as React.RefObject<HTMLDivElement>}
        className={clsx(
          'relative w-full shadow border-b border-gray-200 sm:rounded-lg',
          removeVerticalScroll ? '' : 'overflow-y-auto '
        )}
      >
        <table
          {...getTableProps()}
          className="min-w-full divide-y divide-gray-200 whitespace-nowrap"
        >
          <thead className="bg-gray-50">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    scope="col"
                    className="p-3 text-sm text-left font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    <div className="flex">
                      {column.render('Header')}
                      {!column.disableSortBy ? (
                        <CaretIcon
                          className={clsx(
                            'ml-auto my-auto',
                            column.isSorted ? 'text-primary' : 'text-gray-400',
                            column.isSortedDesc && 'transform rotate-180'
                          )}
                        />
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row)
              return (
                <tr
                  onClick={() => (onClickRow ? onClickRow(row.original) : null)}
                  className={clsx(
                    rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                    'hover:bg-gray-100'
                  )}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="px-4 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <LoaderContainer loading={!!hasMoreData}>
        <div></div>
      </LoaderContainer>
    </>
  )
}
