import { Button } from '@ps-ui/components'
import styled from 'styled-components/macro'

export const ClaimWrapper = styled.div`
  padding: 0 60px;
`
export const StyledInsuranceCard = styled.div`
  border-radius: 12px;
  padding: 20px;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px 4px 10px -6px rgba(0, 0, 0, 0.25);
  min-height: 130px;
  &&& {
    div.Dropdown-control {
      border-radius: 5px;
      border: 1px solid rgba(218, 219, 222, 1);
      background-color: transparent;
    }
    div.is-open {
      div.Dropdown-control {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
    div.Dropdown-menu {
      border-radius: 0;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  label {
    opacity: 1;
  }

  input:disabled {
    opacity: 1;
    color: ${(props) => props.theme.colors.text};
  }

  .row > * {
    min-width: fit-content;
    flex: 1;
    margin: 0 8px 8px 0;
  }
`
export const StyledCard = styled(StyledInsuranceCard)`
  min-width: 100%;
  .codes {
    width: 45%;
  }
`

export const CptContainer = styled.div`
  position: absolute;
  max-height: 220px;
  padding: 0px 20px 15px 20px;
  width: 100%;
  overflow-y: auto;
  left: 0px;
  background: #ffffff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 100;
`
export const CptCodeContainer = styled.div`
  padding: 6px;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.primary};
    color: #ffffff;
  }
`

export const SelectedCodeContainer = styled.div`
  position: relative;
  max-width: 100%;
  margin: 10px 0;
  width: 100%;
  color: #ffffff;
  border-radius: 10px;
  border: 1px solid #000000;
  background: ${(props) => props.theme.colors.primary};
  text-overflow: ellipsis;
  button.clear-code-btn {
    background: none;
    color: #ffffff;
    outline: none;
    border: none;
    border-right: 1px solid black;
    padding: 8px 10px;
  }
`

export const CloseButton = styled(Button)`
  &&& {
    font-size: 10px;
    padding: 0 10px;
    border-radius: 10px;
  }
`
