import { Menu } from '@headlessui/react'
import { PlusSmIcon } from '@heroicons/react/outline'
import { Icon } from '@ps-ui/components'
import clsx from 'clsx'
import React, { useState } from 'react'
import { NavLink as RouterLink, useHistory } from 'react-router-dom'
import { PaymentModal } from 'src/components/organisms/AddPaymentModal'
import PSLogo from 'src/images/new-ps-logo.png'
import { ReactComponent as LogoutIcon } from 'src/images/power-icon.svg'

type HeaderProps = {
  name: string
  logout: () => void
  profileImage?: string
}

const NavLink: React.FC<{
  label: string
  to: string
  onClick?: () => void
}> = ({ label, to, onClick }) => {
  const history = useHistory()
  const isOnPage = history.location.pathname === to

  return (
    <RouterLink to={to}>
      <span
        onClick={() => !!onClick && onClick()}
        className={clsx(
          'font-medium flex items-center space-x-2 px-4 py-2 rounded',
          { 'text-indigo-500 bg-indigo-100': isOnPage },
          {
            'text-gray-500 hover:text-indigo-500 hover:bg-indigo-100 active:bg-transparent':
              !isOnPage,
          }
        )}
      >
        {label}
      </span>
    </RouterLink>
  )
}

export const Header = ({ name, logout }: HeaderProps) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const [isMenuMobileOpen, setIsMenuMobileOpen] = React.useState<boolean>(false)
  const menuRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (isMenuOpen && !!menuRef) {
      menuRef.current?.focus()
    }
  }, [isMenuOpen, menuRef])

  return (
    <>
      <div className="flex flex-col">
        <header
          id="page-header"
          className="flex flex-none items-center h-16 bg-white shadow-sm"
        >
          <div className="flex justify-between container xl:max-w-7xl mx-auto px-4 lg:px-8">
            <RouterLink to="/">
              <div className="inline-flex items-center space-x-2">
                <img src={PSLogo} className="h-10" alt="Patient Studio" />
              </div>
            </RouterLink>

            <div className="flex items-center space-x-2 lg:space-x-5">
              <nav className="hidden lg:flex lg:items-center lg:space-x-2">
                <NavLink label="Dashboard" to="/" />
                <NavLink label="Patients" to="/patients" />
                <NavLink label="Messages" to="/messages" />
                <NavLink label="Calendar" to="/scheduling" />
                <NavLink label="Tasks" to="/tasks" />
              </nav>

              <div className="relative flex items-center space-x-3">
                <Menu as="div" className="relative">
                  <Menu.Button className="flex items-center px-3 h-10 text-sm text-white font-semibold rounded shadow-sm border border-[#0E8474] bg-[#0E8474] focus:outline-none focus:ring focus:ring-[#11a28f]">
                    <PlusSmIcon className="w-6 h-6" />
                    <span>Add new</span>
                  </Menu.Button>
                  <Menu.Items className="absolute right-0 origin-top-right w-44 z-10 p-1 mt-1 rounded bg-white border border-gray-300 shadow-sm">
                    <Menu.Item
                      as="button"
                      className="flex w-full px-3 py-1 rounded hover:bg-gray-100 focus:outline-none"
                      onClick={() => setShowPaymentModal(true)}
                    >
                      <span className="text-gray-600 font-semibold">
                        Add new Payment
                      </span>
                    </Menu.Item>
                  </Menu.Items>
                </Menu>

                <button
                  type="button"
                  className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-3 h-10 leading-5 text-sm rounded border-gray-300 bg-white text-gray-800 shadow-sm hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
                  id="tk-dropdown-layouts-user"
                  aria-haspopup="true"
                  aria-expanded="true"
                  onClick={() => !isMenuOpen && setIsMenuOpen(true)}
                >
                  <span>{name}</span>
                  <svg
                    className={clsx(
                      'hi-solid hi-chevron-down inline-block w-5 h-5 opacity-50 transform duration-300 transition-transform',
                      { '-rotate-180': isMenuOpen }
                    )}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <div
                  role="menu"
                  tabIndex={0}
                  ref={menuRef}
                  onBlur={() => setIsMenuOpen(false)}
                  aria-labelledby="tk-dropdown-layouts-user"
                  className={clsx(
                    'absolute right-0 origin-top-right mt-2 w-48 shadow-xl rounded z-40 focus:outline-none transform duration-300 transition-all',
                    { 'opacity-0 scale-y-0': !isMenuOpen },
                    { 'scale-100 opacity-1': isMenuOpen }
                  )}
                >
                  <div className="bg-white ring-1 ring-black ring-opacity-5 rounded divide-y divide-gray-100">
                    <div className="p-2 space-y-1">
                      {/* <RouterLink to="/settings">
                      <div role="menuitem" className="flex items-center space-x-2 rounded py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-700">
                        <Icon Component={SettingsIcon} size="xs" />
                        <span className="ml-1">Settings</span>
                      </div>
                    </RouterLink> */}
                      <RouterLink to="/login">
                        <div
                          role="menuitem"
                          className="flex items-center space-x-2 rounded py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-700"
                          onClick={logout}
                        >
                          <Icon Component={LogoutIcon} size="xs" />
                          <span className="ml-1">Logout</span>
                        </div>
                      </RouterLink>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="lg:hidden"
                onClick={() => setIsMenuMobileOpen(!isMenuMobileOpen)}
              >
                <button
                  type="button"
                  className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-3 py-2 leading-6 rounded border-gray-300 bg-white text-gray-800 shadow-sm hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
                >
                  <svg
                    className="hi-solid hi-menu-alt-4 inline-block w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 7a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 13a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </header>

        <div
          className={clsx(
            'lg:hidden bg-white absolute top-16 w-full z-30 transform duration-300 origin-top transition-all',
            { 'opacity-0 scale-y-0': !isMenuMobileOpen },
            { 'scale-100 opacity-1': isMenuMobileOpen }
          )}
        >
          <div className="container xl:max-w-7xl mx-auto p-4 lg:p-8">
            <nav className="flex flex-col space-y-2">
              <NavLink
                label="Dashboard"
                to="/"
                onClick={() => setIsMenuMobileOpen(false)}
              />
              <NavLink
                label="Patients"
                to="/patients"
                onClick={() => setIsMenuMobileOpen(false)}
              />
              <NavLink
                label="Messages"
                to="/messages"
                onClick={() => setIsMenuMobileOpen(false)}
              />
              <NavLink
                label="Calendar"
                to="/scheduling"
                onClick={() => setIsMenuMobileOpen(false)}
              />
              <NavLink
                label="Tasks"
                to="/tasks"
                onClick={() => setIsMenuMobileOpen(false)}
              />
            </nav>
          </div>
        </div>
      </div>
      {showPaymentModal && (
        <PaymentModal
          open={showPaymentModal}
          onClose={() => setShowPaymentModal(false)}
        />
      )}
    </>
  )
}
