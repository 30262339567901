import { Typography } from '@ps-ui/components'
import React, { ReactElement, useEffect } from 'react'
import { StoreEvents, StoreState } from 'src/state/store'
import { useStoreon } from 'storeon/react'
import {
  InfoList,
  PatientLink,
  Popper,
  Subtitle,
  SuccessHeader,
  Wrappper,
} from './styles'

export function SchedulingSuccess(): ReactElement {
  const { dispatch, chosenPatient, newPatient } = useStoreon<
    StoreState,
    StoreEvents
  >('chosenPatient', 'newPatient')

  useEffect(() => {
    return () => {
      dispatch('scheduling/reset-form')
    }
  }, [dispatch])

  return (
    <Wrappper>
      <SuccessHeader>
        <Popper />
        <Typography as="h1" style={{ fontSize: 48, color: '#fff' }}>
          GREAT WORK!
        </Typography>
      </SuccessHeader>
      <Subtitle>YOU JUST SCHEDULED NEW PATIENT</Subtitle>
      {chosenPatient && (
        <PatientLink to={`/patients/${chosenPatient?.id}`}>
          {chosenPatient?.firstName} {chosenPatient?.lastName}
        </PatientLink>
      )}
      {newPatient && (
        <PatientLink to={`/patients/`}>
          {newPatient?.firstName} {newPatient?.lastName}
        </PatientLink>
      )}
      <InfoList>
        {/* notifications are not implemented yet  */}
        {/* <li>We have sent out the paperwork</li>
        <li>We will remind the patient of their appointment</li> */}
        <li>We will confirm their appointment 24 hours prior</li>
      </InfoList>
    </Wrappper>
  )
}
