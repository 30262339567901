import { gql } from 'apollo-boost'
import { PaginationInput, PatientProfile, Query } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface QueryPatientsInPracticeResponse {
  getPractice: Query['getPractice']
}

export interface QueryPatientsInPracticeRequest {
  id: string
}

export const QUERY_PATIENTS_IN_PRACTICE = gql`
  query queryPatientsInPractice($id: ID!, $pagination: PaginationInput) {
    getPractice(id: $id) {
      id
      forms {
        id
        name
        description
      }
      patients(pagination: $pagination) {
        entities {
          ... on PatientProfile {
            id
            firstName
            lastName
            phoneNumber
            email
            dateOfBirth
            createdOn
            appointments {
              entities {
                ... on Appointment {
                  id
                  dateTime
                }
              }
            }
            assignedForms {
              id
              isComplete
              isSubmitted
              form {
                name
              }
              updatedOn
            }
          }
        }
        pageInfo {
          totalEntities
          hasMoreResults
        }
      }
    }
  }
`

export const queryPatientsByPractice = async (
  id: string,
  pagination: PaginationInput
) => {
  const response = await apolloClient.query<QueryPatientsInPracticeResponse>({
    query: QUERY_PATIENTS_IN_PRACTICE,
    fetchPolicy: 'no-cache',
    variables: {
      id,
      pagination,
    },
  })
  return {
    patients: response.data.getPractice?.patients.entities.map(
      (patient) => patient as PatientProfile
    ),
    hasMore: response.data.getPractice?.patients.pageInfo.hasMoreResults,
  }
}
