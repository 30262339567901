import * as React from 'react'
import { ModuleContainerTitle } from '../ModuleContainerTitle'

interface ModuleContainerProps {
  title: string
  description?: string
  id: string
  withCollaped?: boolean
  withRemove?: boolean
  withContinue?: boolean
  onClickContinue?: () => void
}

export const ModuleContainer: React.FC<ModuleContainerProps> = ({
  children,
  id,
  title,
  description,
  withCollaped = true,
  withRemove = true,
  withContinue = false,
  onClickContinue,
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false)
  const onToggleModule = React.useCallback(() => {
    setIsCollapsed((prev) => !prev)
  }, [setIsCollapsed])
  return (
    <div className="flex flex-col rounded-lg shadow-sm bg-white relative mb-8">
      <ModuleContainerTitle
        id={id}
        title={title}
        description={description}
        withCollaped={withCollaped}
        withRemove={withRemove}
        withContinue={withContinue}
        onClickContinue={onClickContinue}
        isCollapsed={isCollapsed}
        onToggleState={onToggleModule}
      />
      {!isCollapsed && children}
    </div>
  )
}
