'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.StyledDatePicker = exports.InputIcon = exports.DatePickerWrapper = void 0

var _reactDatepicker = _interopRequireDefault(require('react-datepicker'))

require('react-datepicker/dist/react-datepicker.css')

var _styledComponents = _interopRequireDefault(require('styled-components'))

var _templateObject, _templateObject2, _templateObject3

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0)
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }))
}

var StyledDatePicker = (0, _styledComponents['default'])(_reactDatepicker['default'])(
  _templateObject ||
    (_templateObject = _taggedTemplateLiteral([
      '\n  width: 100%;\n  height: 100%;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  font-size: 16px;\n  padding-left: 60px;\n  padding-right: 16px;\n  outline: none;\n  background-color: #fff;\n  transition: background-color 0.1s, border-width 0.1s;\n',
    ]))
)
exports.StyledDatePicker = StyledDatePicker

var InputIcon = _styledComponents['default'].div(
  _templateObject2 ||
    (_templateObject2 = _taggedTemplateLiteral([
      '\n  position: absolute;\n  top: 9px;\n  display: flex;\n  align-items: center;\n  height: 100%;\n  color: ',
      ';\n  width: 25px;\n  height: 25px;\n  z-index: 1;\n  left: ',
      ';\n\n  & > svg {\n    width: 100%;\n    height: 100%;\n  }\n',
    ])),
  function (props) {
    return props.theme.colors.lightGrey
  },
  function (props) {
    return props.theme.spacing(2)
  }
)

exports.InputIcon = InputIcon

var DatePickerWrapper = _styledComponents['default'].div(
  _templateObject3 ||
    (_templateObject3 = _taggedTemplateLiteral([
      "\n  position: relative;\n  height: 45px;\n  margin-bottom: 10px;\n  .react-datepicker-popper {\n    z-index: 1000 !important;\n  }\n  .react-datepicker-wrapper {\n    width: 100%;\n    height: 100%;\n\n    .react-datepicker__input-container {\n      height: 100%;\n    }\n  }\n\n  .react-datepicker {\n    font-size: 0.8rem;\n    background-color: #fff;\n    color: #000;\n    border: none;\n    border-radius: 10px;\n    box-shadow: 0px 3px 20px rgba(83, 65, 142, 0.1);\n\n    .react-datepicker__header {\n      background: #fff;\n      border-top-left-radius: 10px;\n      border-top-right-radius: 10px;\n    }\n\n    .react-datepicker__triangle {\n      display: none;\n    }\n\n    .react-datepicker-popper[data-placement^='bottom'] {\n      margin-top: 0;\n    }\n\n    .react-datepicker__navigation--next,\n    .react-datepicker__navigation--previous {\n      top: 16px;\n      outline: none;\n    }\n\n    .react-datepicker__navigation--next {\n      border-left-color: ",
      ';\n    }\n\n    .react-datepicker__navigation--previous {\n      border-right-color: ',
      ';\n    }\n\n    .react-datepicker__current-month {\n      color: ',
      ';\n      font-weight: 500;\n      font-size: 20px;\n      padding: 6px 0px;\n    }\n\n    .react-datepicker__day {\n      color: ',
      ';\n      width: 30px;\n      line-height: 30px;\n    }\n\n    .react-datepicker__day--selected {\n      background-color: ',
      ';\n      border-radius: 50%;\n      color: ',
      ';\n    }\n\n    .react-datepicker__day--keyboard-selected {\n      background-color: unset;\n    }\n    .react-datepicker__day--today {\n      background-color: ',
      ';\n      border-radius: 50%;\n      color: #fff;\n    }\n  }\n',
    ])),
  function (props) {
    return props.theme.colors.primary
  },
  function (props) {
    return props.theme.colors.primary
  },
  function (props) {
    return props.theme.colors.grey
  },
  function (props) {
    return props.theme.colors.grey
  },
  function (props) {
    return props.selected.setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0)
      ? props.theme.colors.primary
      : ''
  },
  function (props) {
    return props.selected.setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0)
      ? '#fff'
      : '#000000'
  },
  function (props) {
    return props.selected.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
      ? props.theme.colors.primary
      : props.theme.colors.lightGrey
  }
)

exports.DatePickerWrapper = DatePickerWrapper
