import { observer } from 'mobx-react'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { ModalWindow } from 'src/components/molecules'
import { createPayment } from 'src/gql/mutations/createPayment'
import {
  Appointment,
  PaymentMethod as $PaymentMethod,
  PaymentType as $PaymentType,
} from 'src/schema-types'
import { useStore } from 'src/stores/store'
import { ModalContent } from './ModalContent'

type PaymentType = keyof typeof $PaymentType | undefined
type PaymentMethod = keyof typeof $PaymentMethod | undefined

export type Fields = {
  value: number
  note?: string
  paymentType: PaymentType
  paymentMethod: PaymentMethod
  appointmentId: string | undefined
}

type PaymentsModalProps = {
  open: boolean
  onClose: () => void
  /**
   * Toggles patient searchbox between read-only field & searchable list.
   * If you don't want allow the user to change the patient currently selected
   * on the PatientStore, set it false.
   */
  canSearchPatient?: boolean
}

const defaultFields: Fields = {
  value: 0,
  paymentType: undefined,
  paymentMethod: undefined,
  appointmentId: undefined,
  note: undefined,
}

/**
 * Creates a Modal with all required fields to add payments to a patient
 */
export const PaymentModal: FC<PaymentsModalProps> = observer(
  ({ open: _open, onClose: _onClose, canSearchPatient = true }) => {
    const { addToast } = useToasts()
    const [open, setOpen] = useState(false)
    const [sending, setSending] = useState(false)
    const [fields, setFields] = useState<Fields>(defaultFields)

    const { patientStore } = useStore()
    const patient = patientStore.patientProfile

    useEffect(() => {
      setOpen(_open)
    }, [_open])

    const onChangeFields = useCallback((id: string, value: string) => {
      setFields((prev: Fields) => ({
        ...prev,
        [id]: value,
      }))
    }, [])

    const close = useCallback(() => {
      setFields(defaultFields)
      setOpen(false)
      // callback fn
      _onClose()
    }, [_onClose])

    const onSave = useCallback(async () => {
      try {
        setSending(true)

        const { note, value, paymentType, paymentMethod, appointmentId } =
          fields

        await createPayment({
          patientProfileId: patient?.id as string,
          method: paymentMethod as $PaymentMethod,
          type: paymentType as $PaymentType,
          value: Number(value) * 100,
          comment: note,
          ...(paymentType === $PaymentType.Copay && { appointmentId }),
        })

        addToast('The Payment was successfully created', {
          appearance: 'success',
        })

        patientStore.fetchMorePayments()
        close()
      } catch (e) {
        //@ts-ignore
        addToast(`Cannot create Payment. Error: ${e.message}`, {
          appearance: 'error',
        })
      } finally {
        setSending(false)
      }
    }, [addToast, close, fields, patient?.id, patientStore])

    const { value, paymentType, paymentMethod, fullName, appointments } =
      useMemo(() => {
        const { value, paymentType, paymentMethod } = fields
        const fullName = `${patient?.firstName} ${patient?.lastName}`

        const appointments = patient?.appointments?.entities as Appointment[]
        const appointmentsWithoutPayments = appointments?.filter(
          (appointment) => {
            return !appointment.payments?.length
          }
        )

        return {
          value,
          paymentType,
          paymentMethod,
          fullName,
          appointments: appointmentsWithoutPayments,
        }
      }, [patient, fields])

    const shouldDisable = useCallback(() => {
      if (patientStore.fetching || sending) {
        return true
      }

      if (!patient?.id || !value || !paymentType || !paymentMethod) {
        return true
      }

      if (paymentType === $PaymentType.Copay && !fields.appointmentId) {
        return true
      }

      return false
    }, [
      patient?.id,
      fields.appointmentId,
      patientStore.fetching,
      paymentMethod,
      paymentType,
      sending,
      value,
    ])

    return (
      <ModalWindow
        isOpen={open}
        isLoading={sending}
        isDisabled={shouldDisable()}
        onPrimaryButtonClick={onSave}
        onSecondaryButtonClick={close}
        onCloseModal={close}
        textPrimaryButton="Save Payment"
        textSecondaryButton="Cancel"
        modalSteps={[
          {
            header: 'Add a Payment',
            content: (
              <ModalContent
                fullName={fullName}
                appointments={appointments || []}
                fields={fields}
                onChangeFields={onChangeFields}
                allowSearchPatient={canSearchPatient}
                isFetchingPatient={patientStore.fetching}
              />
            ),
          },
        ]}
      />
    )
  }
)
