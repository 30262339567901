import styled from 'styled-components/macro'

export const Empty = styled.p`
  font-weight: 400;
  text-align: center;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
