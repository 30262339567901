import React, { FunctionComponent, useMemo } from 'react'
import { AvatarText, AvatarWrapper, Image } from './styles'

interface Props {
  url: string
  width?: string | number
  height?: string | number
  bordered?: boolean
  firstName?: string
  margin?: string
  borderRadius?: string
  onError?: () => void
}

const randomColors: string[] = [
  '#E91E63',
  '#009688',
  '#03A9F4',
  '#FF9800',
  '#3F51B5',
]

const getRandomColor = (): string => {
  const index: number = Math.floor(
    Math.random() * Math.floor(randomColors.length)
  )
  return randomColors[index]
}

export const Avatar: FunctionComponent<Props> = ({
  url,
  firstName = '',
  ...rest
}) => {
  const renderEmptyAvatar = useMemo(() => {
    return <AvatarText>{firstName.slice(0, 1)}</AvatarText>
  }, [firstName])

  const getStyles = useMemo(() => {
    const color = getRandomColor()
    return firstName && !url
      ? {
          backgroundColor: color,
          color: color,
          border: `1px solid ${color}`,
        }
      : {}
  }, [firstName, url])

  return (
    <AvatarWrapper {...rest} style={getStyles}>
      {firstName && !url ? renderEmptyAvatar : <Image src={url} />}
    </AvatarWrapper>
  )
}
