import * as React from 'react'
import { ClinicalNotesInput } from 'src/pages/ClinicalNotesv2/components/fields/Input'
import { InputContainer } from 'src/pages/ClinicalNotesv2/components/InputContainer'

export const BloodPressure: React.FC<{ name: string; label: string }> = ({
  name,
  label,
}) => {
  return (
    <InputContainer name={`${name}.sbp`} isAmendContainer label={label}>
      <div className="flex items-center">
        <ClinicalNotesInput name={`${name}.sbp`} type="number" />
        <span className="font-bold mx-3 text-3xl">/</span>
        <ClinicalNotesInput name={`${name}.dbp`} type="number" />
      </div>
    </InputContainer>
  )
}
