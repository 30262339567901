import _ from 'lodash'
import { observer } from 'mobx-react'
import React from 'react'
import {
  BalanceAnswer,
  PainAnswer,
  PostureAnswer,
  RangeOfMotionAnswer,
  SignatureAnswer,
  StrengthAnswer,
  TermAnswer,
} from 'src/pages/ClinicalNotesv2/components/reviewFields'
import {
  TBalance,
  TBloodPressure,
  THep,
  TMotion,
  TPain,
  TPosture,
  TStrength,
  TTerm,
  WFLFElementTypes,
} from 'src/pages/ClinicalNotesv2/types'
import { ParsedClinicalNoteField } from 'src/stores/domain/clinicalNotes'
import { formatDate } from 'src/utils/formatDate'
import { TabFieldLabels } from '../../utils/tabsValues'
import { BloodPressureAnswer } from '../reviewFields/BloodPressureAnswer'
import { HepAnswer } from '../reviewFields/HepAnswer'

type ReviewAnswerProps = {
  field: ParsedClinicalNoteField
}

const getLabel = (label1: string | null | undefined, key2: string) => {
  if (label1) {
    return `${label1}: `
  }
  if (key2 === 'measures' || key2 === 'properties') {
    return null
  }
  return `${key2}: `
}

export const ParsedFieldAnswer: React.FC<ReviewAnswerProps> = observer(
  ({ field }) => {
    const renderAnswer = () => {
      if (WFLFElementTypes.includes(field.elementType)) {
        const answer = field.answer as TStrength | TMotion | undefined
        if (!answer?.measures?.length) {
          return 'Is within functional limits'
        }
      }

      if (!field.answer) {
        // return dash when direct answer is empty but has amends
        return '-'
      }

      switch (field.elementType) {
        case 'Motion':
          const motionAnswer = field.answer as TMotion
          return <RangeOfMotionAnswer motionAnswer={motionAnswer} />

        case 'Posture':
          const postureAnswer = field.answer as TPosture[]
          return <PostureAnswer postureAnswer={postureAnswer} />

        case 'Pain':
          const painAnswer = field.answer as TPain
          return <PainAnswer painAnswer={painAnswer} />

        case 'Balance':
          const balanceAnswer = field.answer as TBalance
          return <BalanceAnswer balanceAnswer={balanceAnswer} />

        case 'Strength':
          const strengthAnswer = field.answer as TStrength
          return <StrengthAnswer strengthAnswer={strengthAnswer} />

        case 'BloodPressure':
          const bloodPressureAnswer = field.answer as TBloodPressure
          return (
            <BloodPressureAnswer bloodPressureAnswer={bloodPressureAnswer} />
          )

        case 'Term':
          const termAnswer = field.answer as TTerm
          return <TermAnswer termAnswer={termAnswer} />

        case 'Radio_Buttons':
          return <p>{field.answer}</p>

        case 'Checkboxes':
          const checkboxAnswer = field.answer as string[]
          return (
            <p style={{ wordBreak: 'break-word' }}>
              {checkboxAnswer.join(', ')}
            </p>
          )

        case 'Text_Input':
          if (field.label === 'Signature') {
            const signatureAnswer = field.answer as string
            return <SignatureAnswer signatureAnswer={signatureAnswer} />
          }

          if (field.label === 'HEP') {
            const hepAnswer = field.answer as THep
            return <HepAnswer hepAnswer={hepAnswer} />
          }

          if (typeof field.answer === 'object') {
            return (
              <p>
                {Object.entries(field.answer).map(([key, value]) => {
                  if (Array.isArray(value)) {
                    return value.map((item) => {
                      return (
                        <p>
                          {Object.entries(item).map(([key, value]) => {
                            console.log(key)
                            return (
                              <>
                                <span
                                  className="font-semibold py-1 break-words"
                                  style={{ wordBreak: 'break-word' }}
                                >
                                  {key}: {value}
                                </span>
                              </>
                            )
                          })}
                        </p>
                      )
                    })
                  }
                  return (
                    <>
                      <span
                        className="font-semibold break-words"
                        style={{ wordBreak: 'break-word' }}
                      >
                        {key}: {value}
                      </span>
                    </>
                  )
                })}
              </p>
            )
          }

          return <p style={{ wordBreak: 'break-word' }}>{field.answer}</p>

        default:
          if (typeof field.answer === 'object') {
            return <p>{IterateRender(field.answer)}</p>
          }
          return (
            <p style={{ wordBreak: 'break-word' }}>
              {Array.isArray(field.answer)
                ? field.answer.join(', ')
                : field.answer}
            </p>
          )
      }
    }

    const IterateRender: any = React.useCallback(
      (elem: any, count = 0, tabField = null) => {
        if (_.isPlainObject(elem)) {
          return Object.entries(elem).map(([key, value]): any => {
            let field
            // Switch to handle date and time formatting
            const handleIterateAndDate = (
              key: string,
              value: any,
              field: any
            ) => {
              console.log(key)
              switch (key) {
                case 'start':
                  return formatDate(value as string, 'MM-DD-YYYY hh:mm a')

                case 'end':
                  return formatDate(value as string, 'MM-DD-YYYY hh:mm a')
                default:
                  return IterateRender(value, count + 1, field)
              }
            }

            // time-label is used as a complement for Time field
            if (key === 'time-label') {
              return null
            }

            const TabFieldUnits = {
              Time: elem['time-label'],
              Distance: 'Ft.',
            }

            let label
            let unit
            if (tabField) {
              label = TabFieldLabels[tabField]?.[key]
              unit = TabFieldUnits[label]
            }
            const optionsRegex = /^option[123]/g
            if (
              Object.keys(value as object).some((field) =>
                optionsRegex.test(field)
              )
            ) {
              // pass the field name down so it can be used to identify the labels
              field = key.toLowerCase()
            }

            return (
              <>
                <p
                  className="font-semibold py-1 break-words"
                  style={{ wordBreak: 'break-word' }}
                >
                  {getLabel(label, key)}
                  {handleIterateAndDate(key, value, field)}
                  {unit ? unit : null}
                </p>
              </>
            )
          })
        } else if (Array.isArray(elem)) {
          return elem.map((item) => (
            <>
              {IterateRender(item)}
              <br />
            </>
          ))
        } else if (!!elem) {
          return (
            <span
              className="font-normal break-words"
              style={{ wordBreak: 'break-word' }}
            >{` ${elem} `}</span>
          )
        }
      },
      []
    )

    return (
      <div className="ml-2.5 mb-3">
        <h4 className="font-bold">{field.label}</h4>
        <div className="ml-2.5">{renderAnswer()}</div>
      </div>
    )
  }
)
