import { gql } from 'apollo-boost'
import { Mutation, SmsMessageInput } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface SendSmsResponse {
  sendSmsMessage: Mutation['sendSmsMessage']
}

/** gets all the form entries */
export const SEND_SMS_MESSAGE = gql`
  mutation sendSmsMessage($input: SmsMessageInput!) {
    sendSmsMessage(input: $input) {
      to
      from
      body
      sendDate
      direction
      status
    }
  }
`

export const sendSmsMessage = async (input: SmsMessageInput) => {
  console.log(input)
  const res = await apolloClient.query<SendSmsResponse>({
    query: SEND_SMS_MESSAGE,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      input,
    },
  })

  if (res.errors?.length) {
    throw new Error(res.errors[0].message)
  }
  return res.data.sendSmsMessage
}
