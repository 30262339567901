import { useWindowSize } from '@ps-ui/components'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group' // ES6
import { ModalStep } from 'src/components/organisms/ModalStep'
import { StyledSingleStepContainer, StyledStepsContainer } from './styles'

type Step = {
  header: string
  subheader?: string
  content: JSX.Element
  type?: 'notification'
  disableMaxWidth?: boolean
}

type modalSteps = Step[]

type Props = {
  isOpen: boolean
  textSecondaryButton?: string
  onSecondaryButtonClick: () => void
  textPrimaryButton?: string
  onPrimaryButtonClick?: () => void
  modalSteps: modalSteps
  onCloseModal: () => void
  isLoading?: boolean
  isDisabled?: boolean
}

export const ModalWindow: React.FC<Props> = ({
  isOpen,
  isLoading,
  isDisabled,
  textPrimaryButton = 'Save',
  textSecondaryButton = 'Cancel',
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onCloseModal,
  modalSteps,
}) => {
  const [currentStep, setCurrentStep] = useState(1)

  const stepRef = React.useRef<HTMLDivElement>(null)
  const { width: windowWidth } = useWindowSize()
  const [stepWidth, setStepWidth] = useState(windowWidth / 2 - 300)

  useEffect(() => {
    if (stepRef.current && isOpen) {
      setStepWidth(stepRef.current?.offsetWidth)
    }
  }, [isOpen])

  const offsetX =
    windowWidth / 2 - stepWidth / 2 - stepWidth * (currentStep - 1)

  const modalBackground = document.getElementById('modal-background')
  const scrollToTop = () => {
    modalBackground?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [enableTransition, setEnableTransition] = useState(false)

  const appearanceTime = 200

  useEffect(() => {
    if (stepRef.current && isOpen) {
      setTimeout(() => {
        setEnableTransition(true)
      }, appearanceTime)
    }
  }, [isOpen])

  return (
    <CSSTransition
      in={isOpen}
      appear
      timeout={appearanceTime}
      classNames={{
        enter: 'transition ease-out duration-200 transform opacity-0',
        enterActive: 'transform opacity-100',
        exit: 'transition ease-in duration-200 block visible transform opacity-0',
      }}
    >
      <div
        className={clsx(
          'z-90 fixed inset-0 overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-75 top-16',
          !isOpen && 'hidden'
        )}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="tk-modal-simple"
        aria-hidden="false"
      >
        <StyledStepsContainer
          offsetX={offsetX}
          className={clsx(
            'flex flex-nowrap ',
            enableTransition && 'transition-transform duration-500 ease-in'
          )}
        >
          {modalSteps.map((step, index, steps) => {
            const isLast = index === steps.length - 1
            const isFirst = index === 0
            const isCurrent = index === currentStep - 1

            return (
              <StyledSingleStepContainer
                ref={stepRef}
                className={clsx(
                  'p-4 lg:p-8',
                  'transition ease-out duration-200',
                  isOpen
                    ? 'transform scale-100'
                    : 'transition ease-n duration-200 transform opacity-0 scale-125',
                  isCurrent ? 'opacity-100' : 'opacity-40'
                )}
                key={index}
              >
                <ModalStep
                  type={step.type}
                  header={step.header}
                  subheader={step.subheader ? step.subheader : ''}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  onClose={onCloseModal}
                  disableMaxWidth={step.disableMaxWidth}
                  textPrimaryButton={
                    isLast ? textPrimaryButton : `Step ${index + 2}`
                  }
                  textSecondaryButton={
                    isFirst ? textSecondaryButton : `Step ${index}`
                  }
                  onPrimaryButtonClick={
                    isLast
                      ? onPrimaryButtonClick
                      : () => {
                          scrollToTop()
                          setCurrentStep(currentStep + 1)
                        }
                  }
                  onSecondaryButtonClick={
                    isFirst
                      ? onSecondaryButtonClick
                      : () => {
                          scrollToTop()
                          setCurrentStep(currentStep - 1)
                        }
                  }
                >
                  {step.content}
                </ModalStep>
              </StyledSingleStepContainer>
            )
          })}
        </StyledStepsContainer>
      </div>
    </CSSTransition>
  )
}
