import { StyledInputProps } from '@ps-ui/components'
import styled, { css } from 'styled-components/macro'

export const InputStyles = css<StyledInputProps>`
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #dadbde;
  background-color: white;
  padding-left: 40px;
`

type IconContainerProps = {
  position?: 'left' | 'right'
}

export const IconContainer = styled.div<IconContainerProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  left: ${({ position = 'left' }) => (position === 'left' ? '13px' : 'auto')};
  right: ${({ position = 'left' }) => (position === 'left' ? 'auto' : '13px')};
  display: flex;
  align-items: center;
  justify-content: center;
`
