import { Flex } from '@ps-ui/components'
import React, { memo, useCallback } from 'react'
import { HiddenInput } from 'src/components/atoms'
import { AppointmentType } from 'src/schema-types'
import { StoreEvents, StoreState } from 'src/state/store'
import { useStoreon } from 'storeon/react'
import styled from 'styled-components/macro'

const ServiceBlock = styled.div`
  font-size: 22px;
  color: #202020;
  border: 1px solid #eff1f4;
  border-radius: 10px;
  padding: 12px 16px;
  margin: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
`

type InputEvent = (e: React.ChangeEvent<HTMLInputElement>) => void

interface IService {
  name: string
  id: string
  chosenId: string | undefined
  handleChange: InputEvent
  allowed: string[] | null | undefined
  noRules: boolean
}

const Service = memo(
  ({ name, id, handleChange, allowed, chosenId, noRules }: IService) => {
    const rulesDisabled = !noRules
    const noService = allowed && !allowed.includes(name)
    const isDisabled = !!(rulesDisabled && noService)
    return (
      <label style={{ height: 'auto' }}>
        <HiddenInput
          onChange={handleChange}
          className="vhidden"
          disabled={isDisabled}
          checked={!!(chosenId && chosenId === id)}
          type="radio"
          name="service"
          value={id}
        />
        <ServiceBlock>{name}</ServiceBlock>
      </label>
    )
  }
)

export const ServicesList = memo(() => {
  const { appointmentTypes, dispatch, chosenDoctor, chosenService, noRules } =
    useStoreon<StoreState, StoreEvents>(
      'appointmentTypes',
      'chosenDoctor',
      'chosenService',
      'noRules'
    )

  const handleService = useCallback(
    (a: AppointmentType) => {
      dispatch('scheduling-service/set', a)
    },
    [dispatch]
  )

  return (
    <Flex
      width="100%"
      margin="12px 0 0 0"
      padding="10px 24px"
      style={{
        backgroundColor: '#fff',
        borderRadius: 10,
      }}
    >
      <Flex margin="0 -12px" flexWrap="wrap">
        {appointmentTypes &&
          appointmentTypes.map((type) => (
            <Service
              allowed={chosenDoctor?.appointmentTypes?.map(
                (i) => i?.name as string
              )}
              noRules={noRules}
              handleChange={() => handleService(type)}
              key={type.id}
              name={type.name}
              id={type.id}
              chosenId={chosenService?.id}
            />
          ))}
      </Flex>
    </Flex>
  )
})
