import clsx from 'clsx'
import * as React from 'react'
import { ModalWindow } from 'src/components/molecules'
import { TModule } from '../../types'

interface AddModuleModalProps {
  isOpen: boolean
  closeModal: () => void
  title: string
  description: string
  successButtonLabel: string
  listOfModules: TModule[]
  updateListOfModules: (ids: string[]) => void
}

export const AddModuleModal: React.FC<AddModuleModalProps> = ({
  isOpen,
  closeModal,
  title,
  description,
  successButtonLabel,
  listOfModules,
  updateListOfModules,
}) => {
  const [listOfModulesToAdd, setModulesToAdd] = React.useState<string[]>([])
  const [searchValue, setSearchValue] = React.useState('')

  const toggleModule = (id: string) => {
    if (listOfModulesToAdd.includes(id)) {
      setModulesToAdd(listOfModulesToAdd.filter((elem) => elem !== id))
    } else {
      setModulesToAdd([...listOfModulesToAdd, id])
    }
  }

  return (
    <ModalWindow
      isOpen={isOpen}
      onCloseModal={() => {
        closeModal()
        setModulesToAdd([])
      }}
      onPrimaryButtonClick={() => {
        updateListOfModules(listOfModulesToAdd)
        setModulesToAdd([])
        closeModal()
      }}
      textPrimaryButton={successButtonLabel}
      textSecondaryButton="Cancel"
      onSecondaryButtonClick={() => {
        closeModal()
        setModulesToAdd([])
      }}
      modalSteps={[
        {
          header: title,
          content: (
            <div className="flex flex-col">
              <p className="text-center mb-2">{description}</p>
              <input
                type="text"
                placeholder="Search"
                className="w-full p-3"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <ul className="grid gap-2 mt-2">
                {listOfModules
                  .filter((module) =>
                    module.label.toLowerCase().includes(searchValue)
                  )
                  .map((module) => (
                    <li
                      key={module.id}
                      onClick={() => toggleModule(module.id)}
                      className="flex cursor-pointer px-2 py-1 items-center"
                    >
                      <div
                        className={clsx(
                          'w-4 h-4 rounded-full border flex justify-center items-center mr-1',
                          listOfModulesToAdd.includes(module.id)
                            ? 'bg-primary'
                            : 'bg-white'
                        )}
                      >
                        <div className="w-1 h-1 rounded-full bg-white"></div>
                      </div>
                      {module.label}
                    </li>
                  ))}
              </ul>
            </div>
          ),
        },
      ]}
    />
  )
}
