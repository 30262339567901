import { gql } from 'apollo-boost'
import { PatientProfile, PatientProfileCreateInput } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface CreatePatientProfileRequest {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  formCode?: string
}

export interface CreatePatientProfileResponse {
  createPatientProfile: PatientProfile
}

export const CREATE_PATIENT_PROFILE = gql`
  mutation createPatientProfile($input: PatientProfileCreateInput!) {
    createPatientProfile(input: $input) {
      id
      firstName
      lastName
      email
    }
  }
`

export const createPatientProfile = async (
  input: PatientProfileCreateInput
) => {
  const result = await apolloClient.query<CreatePatientProfileResponse>({
    query: CREATE_PATIENT_PROFILE,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      input,
    },
  })
  if (result.errors?.length) {
    throw new Error(result.errors[0].message)
  }
  return result.data.createPatientProfile
}
