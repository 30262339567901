import { gql } from 'apollo-boost'
import { InsuranceClaim } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface QueryExistingClaimResponse {
  getInsuranceClaim: InsuranceClaim
}

export const GET_INSURANCE_CLAIM = gql`
  query getInsuranceClaim($id: ID!) {
    getInsuranceClaim(id: $id) {
      id
      claimId
      serviceLocationType
      status
      processor
      workerCompensationClaimNumber
      authorizationNumber
      paymentMethods
      appointment {
        id
      }
      subscriber {
        firstName
        lastName
        dateOfBirth
        address {
          street1
          city
          state
          zip
        }
        genderCode
      }
      patientExtendedInformation {
        genderCode
        address {
          street1
          city
          state
          zip
        }
        relationshipToSubscriber
      }
      insuranceName
      insurancePolicyNumber
      serviceLocationType
      charges {
        procedureCode
        diagnosisCodes
        dateOfService
        chargeFee
        units
        modifiers
      }
      errors
    }
  }
`

export const queryClaimDataByClaimId = async (id: string) => {
  const response = await apolloClient.query<QueryExistingClaimResponse>({
    query: GET_INSURANCE_CLAIM,
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  })
  return response.data.getInsuranceClaim
}
