import { Typography } from '@ps-ui/components'
import React, { useEffect } from 'react'
import { PracticeLogo } from 'src/components/atoms/PracticeLogo'
import { Practice } from 'src/schema-types'
import { useStore } from 'src/stores/store'
import { formatDate } from 'src/utils/formatDate'

type PrintHeaderProps = { practice: Practice | undefined }

export const PrintHeader: React.FC<PrintHeaderProps> = ({ practice }) => {
  const { clinicalNotesStore, patientStore } = useStore()
  useEffect(() => {
    if (clinicalNotesStore.clinicalNote?.patient) {
      patientStore.fetchById(clinicalNotesStore.clinicalNote?.patient?.id)
    }
  }, [clinicalNotesStore.clinicalNote, patientStore])
  const patient = patientStore.patientProfile
  const gender =
    clinicalNotesStore.clinicalNote?.patient?.medicalInformation?.find(
      (info) => info?.label === 'Gender'
    )
  const race =
    clinicalNotesStore.clinicalNote?.patient?.medicalInformation?.find(
      (info) => info?.label === 'Race'
    )

  return (
    <div className="flex flex-col min-w-full border-2 mb-6 border-gray-300 px-4 py-2">
      <div className="flex justify-between w-full border-b-2 py-4 border-gray-200">
        <div className="flex items-center">
          <PracticeLogo src={practice?.branding?.logo.downloadUrl} />
          <Typography as="h2">{practice?.name}</Typography>
        </div>
        <Typography as="h2" fontWeight="700">
          Clinical Note
        </Typography>
      </div>
      <div className="flex">
        <div className="flex flex-col p-4 w-full">
          <p className="font-">
            First Name:{' '}
            <span className="font-semibold">{patient?.firstName}</span>
          </p>
          <p className="font-">
            Last Name:{' '}
            <span className="font-semibold">{patient?.lastName}</span>
          </p>
          <p className="font-">
            Dob:{' '}
            <span className="font-semibold">
              {formatDate(patient?.dateOfBirth, 'MM-DD-YYYY')}
            </span>
          </p>
        </div>
        <div className="flex flex-col px-4 py-4 w-full">
          <p className="font-">
            Gender:{' '}
            <span className="font-semibold">
              {gender?.value ? gender?.value[0] : 'N/A'}
            </span>
          </p>
          <p className="font-">
            Race:{' '}
            <span className="font-semibold">
              {race?.value?.length ? race?.value[0] : 'N/A'}
            </span>
          </p>
          <p className="font-">
            Primary Diagnosis:{' '}
            <span className="font-semibold">
              {' '}
              {clinicalNotesStore.diagnosis
                ? clinicalNotesStore.diagnosis
                : 'N/A'}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}
