export const exerciseCategories = [
  'PROM',
  'AAROM',
  'AROM',
  'Putty',
  'Isometric',
  'Theraband',
  'Weights',
  'Closed Chain Exercises',
]

export const exerciseOptions = [
  {
    name: '3 jaw chuck pinch',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: '5 jaw chuck pinch',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Ankle Circles',
    categories: ['AROM', 'Weights'],
    location: ['Ankle'],
    position: ['standing', 'supine', 'side lying', 'sitting'],
  },
  {
    name: 'Ankle Dorsiflexion',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Ankle'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Ankle Plantar Flexion',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Ankle'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Ankle Pumps',
    categories: ['PROM', 'AROM', 'AAROM', 'Weights', 'Theraband'],
    location: ['Ankle'],
    position: ['standing', 'supine', 'sitting', 'Prone', 'side lying'],
  },
  {
    name: 'Arm Circles',
    categories: ['AROM'],
    location: ['Shoulder'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Bent Over Row',
    categories: ['Weights', 'Theraband'],
    location: ['Compound'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Bicep Hammer Curl',
    categories: ['Weights'],
    location: ['Elbow'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Bird Dog',
    categories: ['Isometric'],
    location: ['Compound'],
    position: ['Quadruped'],
  },
  {
    name: 'Cane/Wand Shoulder Abduction',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'sitting'],
  },
  {
    name: 'Cane/Wand Shoulder Adduction',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'sitting'],
  },
  {
    name: 'Cane/Wand Shoulder Extension',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Cane/Wand Shoulder External Rotation',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Cane/Wand Shoulder Flexion',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'sitting'],
  },
  {
    name: 'Cane/Wand Shoulder Horizontal Abduction',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'sitting'],
  },
  {
    name: 'Cane/Wand Shoulder Horizontal Adduction',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'sitting'],
  },
  {
    name: 'Cane/Wand Shoulder Internal Rotation',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Cervical Extension',
    categories: ['AROM', 'AAROM', 'PROM', 'Weights', 'Theraband', 'Isometric'],
    location: ['Cervical'],
    position: [
      'standing',
      'supine',
      'side lying',
      'sitting',
      'Prone',
      'Quadruped',
    ],
  },
  {
    name: 'Cervical Flexion',
    categories: ['AROM', 'AAROM', 'PROM', 'Weights', 'Theraband', 'Isometric'],
    location: ['Cervical'],
    position: [
      'standing',
      'supine',
      'side lying',
      'sitting',
      'Prone',
      'Quadruped',
    ],
  },
  {
    name: 'Cervical Lateral Flexion',
    categories: ['AROM', 'AAROM', 'PROM', 'Weights', 'Theraband', 'Isometric'],
    location: ['Cervical'],
    position: [
      'standing',
      'side lying',
      'sitting',
      'supine',
      'Prone',
      'Quadruped',
    ],
  },
  {
    name: 'Cervical Retraction',
    categories: ['AROM', 'AAROM', 'PROM', 'Weights', 'Theraband', 'Isometric'],
    location: ['Cervical'],
    position: ['standing', 'side lying', 'sitting', 'Prone', 'Quadruped'],
  },
  {
    name: 'Cervical Rotation',
    categories: ['AROM', 'AAROM', 'PROM', 'Weights', 'Theraband', 'Isometric'],
    location: ['Cervical'],
    position: [
      'standing',
      'supine',
      'side lying',
      'sitting',
      'Prone',
      'Quadruped',
    ],
  },
  {
    name: 'Chest Press',
    categories: ['Weights', 'Theraband'],
    location: ['Chest'],
    position: ['sitting', 'supine'],
  },
  {
    name: 'Chin Tuck',
    categories: ['AROM', 'AAROM', 'PROM', 'Weights', 'Theraband', 'Isometric'],
    location: ['Cervical'],
    position: [
      'standing',
      'supine',
      'side lying',
      'sitting',
      'Prone',
      'Quadruped',
    ],
  },
  {
    name: 'Chop High/Low',
    categories: ['Theraband', 'Weights'],
    location: ['Compound'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Clamshell',
    categories: ['Isometric', 'AROM'],
    location: ['Hip'],
    position: ['side lying'],
  },
  {
    name: 'Codmans',
    categories: ['PROM'],
    location: ['Shoulder'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Composite Fist Push',
    categories: ['Putty'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Digit Abduction',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Digit Adduction',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Digit DIP Extension',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Digit DIP Flexion',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Digit MP Extension',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Digit MP Flexion',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Digit PIP Extension',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Digit PIP Flexion',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Double Leg Bridge',
    categories: ['Isometric', 'AROM'],
    location: ['Hip'],
    position: ['supine'],
  },
  {
    name: 'Elbow Extension',
    categories: ['PROM', 'AAROM', 'AROM', 'Theraband', 'Weights', 'Isometric'],
    location: ['Elbow'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Elbow Flexion',
    categories: ['PROM', 'AAROM', 'AROM', 'Theraband', 'Weights', 'Isometric'],
    location: ['Elbow'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Fire Hydrant',
    categories: ['AROM', 'Theraband', 'Weights'],
    location: ['Hip', 'Ankle'],
    position: ['Quadruped'],
  },
  {
    name: 'Fire Hydrants',
    categories: ['AROM'],
    location: ['Hip'],
    position: ['side lying'],
  },
  {
    name: 'Foot Eversion',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Ankle'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Foot Inversion',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Ankle'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Forearm Pronation',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Elbow'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Forearm Supination',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Elbow'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Gripper Gross Grasp Strengthening',
    categories: ['Weights'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Gross Squeeze',
    categories: ['Putty'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Hammer Curl',
    categories: ['Weights'],
    location: ['Elbow'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Hamstring Sets',
    categories: ['Isometric', 'AROM'],
    location: ['Hip'],
    position: ['supine'],
  },
  {
    name: 'Heel slide',
    categories: ['AROM'],
    location: ['Hip'],
    position: ['supine'],
  },
  {
    name: 'Heel Slide with Ankle Pump',
    categories: ['AROM', 'Weights'],
    location: ['Hip', 'Ankle'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Hip Abduction',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Hip'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Hip Adduction',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Hip'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Hip Adduction Ball squeezes',
    categories: ['Isometric'],
    location: ['Hip'],
    position: ['sitting', 'supine'],
  },
  {
    name: 'Hip Extension',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Hip'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Hip External Rotation',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Hip'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Hip Flexion',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Hip'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Hip Hiking',
    categories: ['AROM'],
    location: ['Hip'],
    position: ['standing'],
  },
  {
    name: 'Hip Internal Rotation',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Hip'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Hook Fist',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Knee Extension',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Knee'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Knee Flexion',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Knee'],
    position: ['standing', 'supine', 'sitting', 'side lying', 'Prone'],
  },
  {
    name: 'Lateral Grip',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Lateral Lifts',
    categories: ['AROM', 'Weights', 'Theraband'],
    location: ['Cervical'],
    position: ['side lying'],
  },
  {
    name: 'Lateral Plank',
    categories: ['Isometric'],
    location: ['Compound'],
    position: ['side lying'],
  },
  {
    name: 'Leg circles',
    categories: ['AROM'],
    location: ['Hip'],
    position: ['standing', 'supine', 'sitting'],
  },
  {
    name: 'Long Arc Quads',
    categories: ['Isometric', 'AROM', 'AAROM', 'PROM', 'Weights', 'Theraband'],
    location: ['Hip'],
    position: ['sitting', 'supine'],
  },
  {
    name: 'Lumbricals',
    categories: ['AROM', 'Putty'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Mountain Climber',
    categories: ['Isometric', 'AROM'],
    location: ['Compound'],
    position: [''],
  },
  {
    name: 'Overhead Press',
    categories: ['Weights', 'Theraband'],
    location: ['Shoulder'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Palmar Push',
    categories: ['Putty'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Pendulum',
    categories: ['PROM'],
    location: ['Shoulder'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Plank',
    categories: ['Isometric'],
    location: ['Compound'],
    position: [''],
  },
  {
    name: 'Posterior Pelvic Tilt',
    categories: ['AROM'],
    location: ['Lumbar/Thoracic'],
    position: ['standing', 'supine', 'sitting'],
  },
  {
    name: 'Prayer Stretch',
    categories: ['PROM'],
    location: ['Wrist'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Prone I, T, Y',
    categories: ['AROM'],
    location: ['Lumbar/Thoracic'],
    position: ['Prone'],
  },
  {
    name: 'Pulldown',
    categories: ['Theraband', 'Weights'],
    location: ['Compound', 'Back'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Putty Roll',
    categories: ['Putty'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Quad Set-Towel under knee',
    categories: ['Isometric'],
    location: ['Hip'],
    position: ['supine'],
  },
  {
    name: 'Quad Sets Long Sit',
    categories: ['Isometric'],
    location: ['Hip'],
    position: ['sitting'],
  },
  {
    name: 'Quadraped w/ Wrist Stretch',
    categories: ['Closed Chain Exercises'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Romanian Deadlift',
    categories: ['Weights', 'Theraband'],
    location: ['Compound'],
    position: ['standing'],
  },
  {
    name: 'Scaption',
    categories: ['PROM', 'AAROM', 'Theraband', 'AROM', 'Weights'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'sitting'],
  },
  {
    name: 'Scapular Retraction',
    categories: ['AROM'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'sitting'],
  },
  {
    name: 'Scapular Retraction',
    categories: ['AROM', 'Weights', 'Theraband'],
    location: ['Shoulder'],
    position: ['Prone', 'standing', 'sitting'],
  },
  {
    name: 'Scapular Stabilizers',
    categories: ['AROM', 'AAROM', 'Weights', 'Isometric', 'Theraband'],
    location: ['Shoulder'],
    position: ['Prone', 'sitting', 'standing'],
  },
  {
    name: 'Seated Rows',
    categories: ['Theraband', 'Weights'],
    location: ['Compound'],
    position: ['sitting'],
  },
  {
    name: 'Serratus Punch',
    categories: ['AROM', 'Weights', 'Theraband'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'sitting'],
  },
  {
    name: 'Short Arc Quads',
    categories: ['Isometric', 'AROM', 'Weights', 'Theraband', 'AAROM', 'PROM'],
    location: ['Hip'],
    position: ['supine', 'sitting'],
  },
  {
    name: 'Shoulder Abduction',
    categories: ['PROM', 'AAROM', 'AROM', 'Theraband', 'Isometric', 'Weights'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'side lying', 'sitting'],
  },
  {
    name: 'Shoulder Adduction',
    categories: ['PROM', 'AAROM', 'AROM', 'Theraband', 'Weights'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'side lying', 'sitting'],
  },
  {
    name: 'Shoulder Extension',
    categories: ['PROM', 'AAROM', 'Theraband', 'AROM', 'Weights'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'side lying', 'sitting'],
  },
  {
    name: 'Shoulder External Rotation',
    categories: ['PROM', 'AAROM', 'AROM', 'Theraband', 'Weights', 'Isometric'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'side lying', 'sitting'],
  },
  {
    name: 'Shoulder Flexion',
    categories: ['PROM', 'AAROM', 'AROM', 'Theraband', 'Weights', 'Isometric'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'side lying', 'sitting'],
  },
  {
    name: 'Shoulder Horizontal Abduction',
    categories: ['PROM', 'AROM', 'AAROM', 'Theraband', 'Weights', 'Isometric'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'side lying', 'sitting'],
  },
  {
    name: 'Shoulder Horizontal Adduction',
    categories: ['PROM', 'AAROM', 'Theraband', 'AROM', 'Weights', 'Isometric'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'side lying', 'sitting'],
  },
  {
    name: 'Shoulder Internal Rotation',
    categories: ['PROM', 'AAROM', 'Theraband', 'AROM', 'Weights', 'Isometric'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'side lying', 'sitting'],
  },
  {
    name: 'Shoulder Rolls',
    categories: ['AROM'],
    location: ['Shoulder'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Single Leg Bridge',
    categories: ['Isometric', 'AROM'],
    location: ['Hip'],
    position: ['supine'],
  },
  {
    name: 'Single Leg Deadlift',
    categories: ['Weights', 'Theraband'],
    location: ['Compound'],
    position: ['standing'],
  },
  {
    name: 'Single Leg Squat',
    categories: ['Isometric', 'AROM'],
    location: ['Hip'],
    position: ['supine'],
  },
  {
    name: 'Split Squat',
    categories: ['AROM', 'Theraband', 'Weights'],
    location: ['Compound'],
    position: ['standing'],
  },
  {
    name: 'Standing Heel Raise',
    categories: ['PROM', 'AROM', 'AAROM', 'Weights', 'Theraband'],
    location: ['Ankle'],
    position: ['standing'],
  },
  {
    name: 'Step Ups',
    categories: ['Isometric', 'AROM', 'Weights'],
    location: ['Compound'],
    position: ['standing'],
  },
  {
    name: 'Straight Leg Raise',
    categories: ['Isometric', 'AROM', 'AAROM', 'PROM', 'Weights', 'Theraband'],
    location: ['Hip'],
    position: ['supine', 'sitting'],
  },
  {
    name: 'Table Walk Outs Flexion',
    categories: ['PROM'],
    location: ['Shoulder'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Thoracic Matrix Lateral Side Bend',
    categories: ['AROM', 'Theraband'],
    location: ['Lumbar/Thoracic'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Thumb abduction',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Thumb Adduction',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Thumb Opposition',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Tip Pinch',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Tip Pinch',
    categories: ['PROM', 'AAROM', 'AROM', 'Putty', 'Isometric', 'Theraband'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Towel Curls',
    categories: ['AROM'],
    location: ['Ankle'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Towel Grip',
    categories: ['Isometric'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Towel Pinch',
    categories: ['Isometric'],
    location: ['Hand'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Towel Pronation',
    categories: ['PROM'],
    location: ['Wrist'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Towel Shoulder Abduction',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Towel Shoulder Adduction',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Towel Shoulder Extension',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'sitting'],
  },
  {
    name: 'Towel Shoulder External Rotation',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Towel Shoulder Flexion',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'supine', 'sitting'],
  },
  {
    name: 'Towel Shoulder Internal Rotation',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Towel Supination',
    categories: ['PROM'],
    location: ['Wrist'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Tricep Extension',
    categories: ['AAROM', 'Theraband', 'Weights', 'PROM', 'AROM'],
    location: ['Elbow'],
    position: ['standing', 'side lying', 'sitting'],
  },
  {
    name: 'Tricep Kickback',
    categories: ['Weights'],
    location: ['Elbow'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Tricep Pushdown',
    categories: ['Weights'],
    location: ['Elbow'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Trunk Extension',
    categories: ['AROM', 'Theraband'],
    location: ['Lumbar/Thoracic'],
    position: ['standing', 'Prone', 'sitting'],
  },
  {
    name: 'Trunk Flexion',
    categories: ['AROM', 'Theraband'],
    location: ['Lumbar/Thoracic'],
    position: ['standing', 'sitting'],
  },
  {
    name: 'Trunk Rotation',
    categories: ['AROM', 'Theraband'],
    location: ['Lumbar/Thoracic'],
    position: ['standing', 'Prone', 'sitting'],
  },
  {
    name: 'Wall Angels',
    categories: ['AROM'],
    location: ['Shoulder'],
    position: ['standing'],
  },
  {
    name: 'Wall Climbs',
    categories: ['PROM', 'AAROM', 'AROM'],
    location: ['Shoulder'],
    position: ['standing'],
  },
  {
    name: 'Wall Sits',
    categories: ['Isometric'],
    location: ['Compound'],
    position: ['standing'],
  },
  {
    name: 'Wand Military Press',
    categories: ['AROM', 'Weights'],
    location: ['Shoulder'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Woodchop',
    categories: ['Weights'],
    location: ['Compound'],
    position: ['standing'],
  },
  {
    name: 'Wrist Extension',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Theraband', 'Isometric'],
    location: ['Wrist'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Wrist Flexion',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Theraband', 'Isometric'],
    location: ['Wrist'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Wrist Radial Deviation',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Theraband', 'Isometric'],
    location: ['Wrist'],
    position: ['sitting', 'standing'],
  },
  {
    name: 'Wrist Ulnar Deviation',
    categories: ['PROM', 'AAROM', 'AROM', 'Weights', 'Theraband', 'Isometric'],
    location: ['Wrist'],
    position: ['sitting', 'standing'],
  },
]

export const locationsByCategory: Record<string, string[]> = {
  PROM: [
    'Hand',
    'Ankle',
    'Shoulder',
    'Cervical',
    'Elbow',
    'Hip',
    'Knee',
    'Wrist',
  ],
  AAROM: [
    'Hand',
    'Ankle',
    'Shoulder',
    'Cervical',
    'Elbow',
    'Hip',
    'Knee',
    'Wrist',
  ],
  AROM: [
    'Hand',
    'Ankle',
    'Shoulder',
    'Cervical',
    'Hip',
    'Elbow',
    'Knee',
    'Compound',
    'Lumbar/Thoracic',
    'Wrist',
  ],
  Putty: ['Hand'],
  Isometric: [
    'Hand',
    'Ankle',
    'Compound',
    'Cervical',
    'Hip',
    'Elbow',
    'Knee',
    'Shoulder',
    'Wrist',
  ],
  Theraband: [
    'Hand',
    'Ankle',
    'Compound',
    'Cervical',
    'Chest',
    'Elbow',
    'Hip',
    'Knee',
    'Shoulder',
    'Back',
    'Lumbar/Thoracic',
    'Wrist',
  ],
  Weights: [
    'Ankle',
    'Compound',
    'Elbow',
    'Cervical',
    'Chest',
    'Hip',
    'Hand',
    'Knee',
    'Shoulder',
    'Back',
    'Wrist',
  ],
  'Closed Chain Exercises': ['Hand'],
}

export const tools: string[] = [
  'Cane / Wand',
  'Rubber Band',
  'Ball',
  'TRX',
  'Pulley',
  'Gripper',
  'Bosu',
  'Putty',
  'Towel',
  'Foam Roller',
  'Glider',
]

export const recistanceValues: string[] = []
