import { makeAutoObservable, runInAction } from 'mobx'
import { writeLog } from 'src/gql/mutations/writeToLog'
import { RootStore } from 'src/stores/store'

export type TaskType = {
  id: string
  assignee: string
  content: string
  createdAt: string
  dueDate: string
  time: string
  isCompleted: boolean
}

const fakeTasks: TaskType[] = [
  {
    id: 'sadasdas',
    assignee: 'Diego Gonzalez',
    content:
      'This is my first note and it would have a lot of text just to see how it looks when the content is to long for one single line',
    createdAt: '05/04/21',
    dueDate: '05/04/21',
    time: '15:30',
    isCompleted: false,
  },
  {
    id: 'asdfggfhjcvb',
    assignee: 'Diego Gonzalez',
    content: 'This is my first note',
    createdAt: '05/04/21',
    dueDate: '05/04/21',
    time: '15:30',
    isCompleted: false,
  },
  {
    id: '96705967805967',
    assignee: 'Diego Gonzalez',
    content: 'This is my first note completed',
    createdAt: '05/04/21',
    dueDate: '05/04/21',
    time: '15:30',
    isCompleted: true,
  },
  {
    id: 'mnbc,vmnb,cvmb',
    assignee: 'Andres Gonzalez',
    content: 'This is my first note completed',
    createdAt: '05/04/21',
    dueDate: '05/04/21',
    time: '15:30',
    isCompleted: true,
  },
  {
    id: 'ads9d809834',
    assignee: 'Andres Gonzalez',
    content: 'This is my first note completed',
    createdAt: '05/04/21',
    dueDate: '05/04/21',
    time: '15:30',
    isCompleted: false,
  },
]

export class TasksStore {
  tasks?: TaskType[]
  fetchingTasks = false
  taskError = ''

  rootStore: RootStore

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
  }

  fetchTasks = async () => {
    try {
      runInAction(() => {
        this.fetchingTasks = true
      })

      await new Promise((resolve) => setTimeout(resolve, 3000))

      runInAction(() => {
        this.tasks = fakeTasks
      })
    } catch (err) {
      writeLog((err as Error).message)
      runInAction(() => {
        this.taskError = (err as Error).message
      })
    } finally {
      runInAction(() => {
        this.fetchingTasks = false
      })
    }
  }
  toggleTask = (id: string) => {
    const updatedTasks = this.tasks?.map((task) => {
      if (task.id === id) {
        return { ...task, isCompleted: !task.isCompleted }
      }
      return task
    })
    runInAction(() => {
      this.tasks = updatedTasks
    })
  }
  deleteTask = (id: string) => {
    const updatedTasks = this.tasks?.filter((task) => task.id !== id)
    runInAction(() => {
      this.tasks = updatedTasks
    })
  }
  createTask = (task: TaskType) => {
    runInAction(() => {
      this.tasks = this.tasks ? [...this.tasks, task] : [task]
    })
  }
  editTask = (id: string, editedTask: TaskType) => {
    const updatedTasks = this.tasks?.map((task) =>
      task.id === id ? editedTask : task
    )
    runInAction(() => {
      this.tasks = updatedTasks
    })
  }
}
