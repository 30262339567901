import * as React from 'react'
import { InterventionContainer } from 'src/pages/ClinicalNotesv2/components/InterventionContainer'
import { Row } from 'src/pages/ClinicalNotesv2/components/layoutForm/Row'
import * as options from 'src/pages/ClinicalNotesv2/types/intervention/gaitTraining'
import { ClinicalNoteElementTypes } from 'src/schema-types'
import { InputContainer } from '../../../InputContainer'
import { Dropdown } from '../../Dropdown'
import { ClinicalNotesInput } from '../../Input'
import { OptionList } from '../OptionList'

export const GaitTrainingInterventionElem: React.FC<{ name: string }> = ({
  name,
}) => {
  return (
    <div className="flex flex-col">
      <Row columns={2}>
        <Dropdown
          name={`${name}.terrain`}
          options={options.terrain}
          label="Terrain"
          placeholder="Select a Terrain"
          isMulti
        />
      </Row>

      <Row columns={1}>
        <div className="flex w-full items-end">
          <OptionList
            coreName={name}
            name="weightBearing"
            label="Weight Bearing"
            options={options.weightBearing}
            isRounded
          />
          <div className="ml-6 mb-3 w-[100px]">
            <Dropdown
              name={`${name}.percentageWeightBearing`}
              options={options.percentageWeightBearing}
              placeholder="%"
            />
          </div>
        </div>
      </Row>

      <Row columns={3}>
        <Dropdown
          name={`${name}.repetitions`}
          options={options.repetitions}
          placeholder="Select Repetitions"
          label="Repetitions"
        />
        <InputContainer name={`${name}.distance`} label="Distance">
          <ClinicalNotesInput
            name={`${name}.distance`}
            type="number"
            placeholder="0"
            isIntervention
          />
        </InputContainer>

        <InputContainer name={`${name}.time`} label="Time">
          <ClinicalNotesInput
            name={`${name}.time`}
            type="time"
            isIntervention
          />
        </InputContainer>
      </Row>

      <Row columns={1}>
        <OptionList
          coreName={name}
          name="equipmentUsed"
          label="Equipment Used"
          options={options.equipment}
        />
      </Row>

      <Row columns={1}>
        <OptionList
          coreName={name}
          name="gaitTrainingSkills"
          label="Gait Training Skills"
          options={options.gaitTrainingSkills}
          isMulti
        />
      </Row>

      <Row columns={1}>
        <OptionList
          coreName={name}
          name="independeceLevel"
          label="Independence Level"
          options={options.levelOfAssist}
          isRounded
        />
      </Row>

      <Row columns={1}>
        <OptionList
          coreName={name}
          name="tolerance"
          label="Tolerance"
          options={options.tolerance}
          isRounded
        />
      </Row>

      <Row columns={1}>
        <OptionList
          coreName={name}
          label="Pattern"
          name="pattern"
          options={options.pattern}
          isMulti
        />
      </Row>

      <Row columns={1}>
        <OptionList
          coreName={name}
          label="Cadence"
          name="cadence"
          options={options.cadence}
          isMulti
        />
      </Row>

      <Row columns={1}>
        <OptionList
          coreName={name}
          name="support"
          label="Support"
          options={options.support}
          isMulti
        />
      </Row>
    </div>
  )
}

export const GaitTrainingIntervention: React.FC<{ name: string }> = ({
  name,
}) => {
  return (
    <InterventionContainer
      name={name}
      type={ClinicalNoteElementTypes.GaitTraining}
      render={({ name }) => <GaitTrainingInterventionElem name={name} />}
    />
  )
}
