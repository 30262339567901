import { createNewAppointment } from 'src/gql/mutations/createAppointment'
import { rescheduleAppointment } from 'src/gql/mutations/rescheduleAppointment'
import { queryAllAppointments } from 'src/gql/queries/queryAllAppointments'
import { cancelAppointment } from './../../pages/Calendar/utils/appointmentActionFunctions'

export const appointmentsServices = {
  getPracticeWithAppointments: queryAllAppointments,
  createAppointment: createNewAppointment,
  rescheduleAppointment,
  cancelAppointment: cancelAppointment,
}

export type AppointmentsServices = typeof appointmentsServices
