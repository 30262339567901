import { gql } from 'apollo-boost'
import { Query } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface QueryPracticeBillingCodesResponse {
  getPractice: Query['getPractice']
}

export const QUERY_PRACTICE_BILLING_CODES = gql`
  query getPracticeBillingCodes($id: ID!) {
    getPractice(id: $id) {
      id
      name
      # aithozPracticeNPI
      billingCodes {
        id
        code
        type
        description
      }
    }
  }
`

export const queryPracticeBillingCodes = async (practiceId: string) => {
  const response = await apolloClient.query<QueryPracticeBillingCodesResponse>({
    query: QUERY_PRACTICE_BILLING_CODES,
    fetchPolicy: 'network-only',
    variables: {
      id: practiceId,
    },
  })

  return response.data.getPractice?.billingCodes
}
