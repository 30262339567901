import { TextField } from '@ps-ui/components'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ModalWindow } from 'src/components/molecules'
import { useStore } from 'src/stores/store'
import { sortByCode } from 'src/utils/sorting'

export enum CodeType {
  CPT = 'cpt',
  ICD = 'icd',
}

type DiagnosisModalProps = {
  isOpen: boolean
  onClose: () => void
  codeType?: CodeType
  clinicalNoteComponent?: boolean
  isLoading?: boolean
}

export const DiagnosisModal: React.FC<DiagnosisModalProps> = observer(
  ({
    isOpen,
    onClose,
    codeType = 'icd',
    clinicalNoteComponent = true,
    isLoading,
  }) => {
    const { practiceStore, clinicalNotesStore, billingStore } = useStore()

    const [selectedIcdCode, setSelectedIcdCode] = useState<string | undefined>()
    const [selectedCptCodes, setSelectedCptCodes] = useState<
      string[] | undefined
    >()

    const [codeSearchValue, setCodeSearchValue] = useState('')

    useEffect(() => {
      // reset codes on page load
      setSelectedCptCodes(undefined)
      setSelectedIcdCode(undefined)
      if (isOpen && billingStore.procedureIds?.length) {
        setSelectedCptCodes(billingStore.procedureIds)
      }
      if (
        isOpen &&
        billingStore.diagnosisId &&
        billingStore.clinicalNoteStatus === 'FINAL'
      ) {
        setSelectedIcdCode(billingStore.diagnosisId)
      }
      if (isOpen && clinicalNoteComponent && clinicalNotesStore.diagnosisId) {
        setSelectedIcdCode(clinicalNotesStore.diagnosisId)
      }
    }, [
      isOpen,
      billingStore.procedureIds,
      clinicalNotesStore.diagnosisId,
      billingStore.diagnosisId,
      clinicalNoteComponent,
      billingStore.clinicalNoteStatus,
    ])

    useEffect(() => {
      practiceStore.getPracticeBillingCodes()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAddClick = useCallback(async () => {
      if (selectedIcdCode) {
        if (clinicalNotesStore.clinicalNote?.status !== 'FINAL') {
          await clinicalNotesStore.saveDiagnosis(selectedIcdCode)
        } else {
          billingStore.saveDiagnosis(selectedIcdCode)
        }
      }
      if (selectedCptCodes) {
        billingStore.saveProcedureCodes(selectedCptCodes)
        handleClose()
      }
      handleClose()
      // eslint-disable-next-line
    }, [selectedIcdCode, selectedCptCodes, billingStore, clinicalNotesStore])

    const billingCodes = useMemo(
      () =>
        codeType === 'icd'
          ? practiceStore.icdBillingCodes
          : practiceStore.cptBillingCodes,
      [practiceStore.icdBillingCodes, practiceStore.cptBillingCodes, codeType]
    )

    const handleCptCodeClick = (code: string) => {
      if (selectedCptCodes === undefined || selectedCptCodes.length === 0) {
        setSelectedCptCodes([code])
      }
      if (selectedCptCodes?.includes(code)) {
        const codeIdx = selectedCptCodes.findIndex((d) => d === code)
        setSelectedCptCodes(
          selectedCptCodes.filter((d, index) => index !== codeIdx)
        )
        return
      }
      if (selectedCptCodes?.length && selectedCptCodes.length <= 3) {
        setSelectedCptCodes([...selectedCptCodes, code])
      }
    }

    const handleClose = () => {
      setSelectedIcdCode(undefined)
      setSelectedCptCodes(undefined)
      onClose()
    }

    const renderOption = (
      codeId: string,
      label: string,
      onClick: () => void,
      billingCodeIdx: number
    ) => {
      return (
        <label
          onClick={onClick}
          key={billingCodeIdx}
          className={clsx(
            'font-medium flex justify-start items-center space-x-2 cursor-pointer capitalize rounded-lg p-5',
            'active:bg-primary active:bg-opacity-20',
            'hover:bg-primary hover:bg-opacity-10'
          )}
        >
          <div className="w-auto">
            <div
              className={clsx(
                'w-3 h-3 mr-3 rounded-full border flex ',
                selectedCptCodes?.includes(codeId) || selectedIcdCode === codeId
                  ? 'bg-primary border-primary'
                  : 'border-gray-500'
              )}
            />
          </div>
          {label}
        </label>
      )
    }

    return (
      <ModalWindow
        isOpen={isOpen}
        onCloseModal={handleClose}
        isDisabled={
          codeType === 'icd' ? !selectedIcdCode : !selectedCptCodes?.length
        }
        textSecondaryButton="Cancel"
        onSecondaryButtonClick={handleClose}
        textPrimaryButton="Add"
        onPrimaryButtonClick={handleAddClick}
        modalSteps={[
          {
            header: `Add a ${
              codeType === 'icd' ? 'diagnosis code' : 'procedure code(s)'
            }`,
            content: (
              <div>
                <TextField
                  label=""
                  id={codeType}
                  placeholder="Start typing a code..."
                  white
                  border
                  margin="10px 0 20px 0"
                  width="100%"
                  value={codeSearchValue}
                  onChange={(e) => setCodeSearchValue(e.currentTarget.value)}
                />
                {selectedCptCodes && selectedCptCodes?.length > 3 && (
                  <div className="flex items-center">
                    <p className="text-orange-500 font-bold py-3 ml-3">
                      You can only add up to 4 Procedure codes per diagnosis
                    </p>
                  </div>
                )}
                <div className="space-y-3">
                  {billingCodes
                    ?.filter(
                      (billingCode) =>
                        billingCode.description
                          ?.toLowerCase()
                          .includes(codeSearchValue.toLowerCase()) ||
                        billingCode.code
                          ?.toLowerCase()
                          .includes(codeSearchValue.toLowerCase())
                    )
                    ?.slice()
                    .sort(sortByCode)
                    .map((billingCode, billingCodeIdx) =>
                      renderOption(
                        billingCode.id,
                        `${billingCode.code} - ${billingCode.description}`,
                        () => {
                          codeType === 'icd'
                            ? setSelectedIcdCode(billingCode.id)
                            : handleCptCodeClick(billingCode.id)
                        },
                        billingCodeIdx
                      )
                    )}
                </div>
              </div>
            ),
          },
        ]}
      />
    )
  }
)
