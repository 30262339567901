import clsx from 'clsx'
import * as React from 'react'
import { ModalWindow } from 'src/components/molecules'
import BodyArms from 'src/images/body-arms.png'
import BodyCore from 'src/images/body-core.png'
import BodyLegs from 'src/images/body-legs.png'
import {
  armButtons,
  coreButtons,
  legButtons,
} from '../../utils/bodyChartButtonsArray'

interface BodyChartModalProps {
  prevValue: string
  isShow: boolean
  hide: () => void
  setBodyPart: (val: string) => void
  enabledBodyParts?: string[]
}

// Button Styles
const buttonStyles =
  'mb-2 border-2 border-green-400 border-opacity-60 rounded h-20 w-20 text-xs font-bold bg-white text-green-600 hover:bg-green-200 active:bg-green-400 active:text-white whitespace-pre-wrap focus:outline-none focus:border-opacity-100 focus:bg-green-200'
const selectedStyle =
  'bg-green-500 text-green-50 hover:bg-green-500 hover:text-green-50 focus:bg-green-500'

export const BodyChartModal: React.FC<BodyChartModalProps> = ({
  prevValue,
  isShow,
  hide,
  setBodyPart,
  enabledBodyParts,
}) => {
  const [currentValue, setCurrentValue] = React.useState(prevValue)
  return (
    <ModalWindow
      isOpen={isShow}
      isDisabled={currentValue === 'Select a Body Part'}
      onCloseModal={() => {
        hide()
        setCurrentValue(prevValue)
      }}
      onPrimaryButtonClick={() => {
        setBodyPart(currentValue)
        hide()
      }}
      textPrimaryButton="Add To Note"
      onSecondaryButtonClick={() => {
        hide()
        setCurrentValue(prevValue)
      }}
      textSecondaryButton="Cancel"
      modalSteps={[
        {
          header: 'Choose Location on Body',
          disableMaxWidth: true,
          content: (
            <div className="flex flex-col">
              <div className="flex justify-around">
                <h2 className="pb-4">Core</h2>
                <h2 className="pb-4">Arms</h2>
                <h2 className="pb-4">Legs</h2>
              </div>
              <div className="flex justify-evenly">
                <div className="flex flex-col pr-5">
                  <div className="flex h-full">
                    <img
                      src={BodyCore}
                      alt="The core of the human body is highlighted"
                      className="w-32 h-1/2 pr-5 pt-5"
                    />
                    <div className="flex flex-col">
                      {coreButtons.map((bodyPart) => {
                        const isDisabled =
                          enabledBodyParts &&
                          !enabledBodyParts.includes(
                            bodyPart.label.toLowerCase()
                          )
                        return (
                          <button
                            type="button"
                            key={bodyPart.label}
                            className={clsx(
                              buttonStyles,
                              isDisabled &&
                                'bg-gray-200 hover:bg-gray-200 cursor-not-allowed',
                              currentValue === bodyPart.label && selectedStyle
                            )}
                            title={bodyPart.label}
                            name="core"
                            disabled={isDisabled}
                            onClick={() =>
                              !isDisabled && setCurrentValue(bodyPart.label)
                            }
                          >
                            {bodyPart.label.toUpperCase()}
                          </button>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-between pr-5">
                  <div className="flex h-full">
                    <img
                      src={BodyArms}
                      alt="The arms of the human body is highlighted"
                      className="w-32 h-1/2 pr-5 pt-5"
                    />
                    <div className="flex flex-col">
                      {armButtons.map((bodyPart) => {
                        const isDisabled =
                          enabledBodyParts &&
                          !enabledBodyParts.includes(
                            bodyPart.label.toLowerCase()
                          )
                        return (
                          <button
                            type="button"
                            key={bodyPart.label}
                            className={clsx(
                              buttonStyles,
                              isDisabled &&
                                'bg-gray-200 hover:bg-gray-200 cursor-not-allowed',
                              currentValue === bodyPart.label && selectedStyle
                            )}
                            title={bodyPart.label}
                            name="core"
                            disabled={isDisabled}
                            onClick={() =>
                              !isDisabled && setCurrentValue(bodyPart.label)
                            }
                          >
                            {bodyPart.label.toUpperCase()}
                          </button>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-between pr-5">
                  <div className="flex h-full">
                    <img
                      src={BodyLegs}
                      alt="The legs of the human body is highlighted"
                      className="w-32 h-1/2 pr-5 pt-5"
                    />
                    <div className="flex flex-col">
                      {legButtons.map((bodyPart) => {
                        const isDisabled =
                          enabledBodyParts &&
                          !enabledBodyParts.includes(
                            bodyPart.label.toLowerCase()
                          )
                        return (
                          <button
                            type="button"
                            key={bodyPart.label}
                            className={clsx(
                              buttonStyles,
                              isDisabled &&
                                'bg-gray-200 hover:bg-gray-200 cursor-not-allowed',
                              currentValue === bodyPart.label && selectedStyle
                            )}
                            title={bodyPart.label}
                            name="core"
                            disabled={isDisabled}
                            onClick={() =>
                              !isDisabled && setCurrentValue(bodyPart.label)
                            }
                          >
                            {bodyPart.label.toUpperCase()}
                          </button>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ),
        },
      ]}
    />
  )
}
