import styled from 'styled-components/macro'

export const AvatarWrapper = styled.div<AvatarProps>`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ width, height, bordered, theme, borderRadius, margin }: AvatarProps) => `
		min-width: ${width || 90}px;
		max-width: ${width || 90}px;
		height: ${height || 90}px;
    border-radius: ${borderRadius || 50}%;
    margin: ${margin || '0'};

		& > img {
			border: 1px solid ${bordered ? theme.colors.primary : 'transparent'};
			border-radius: ${borderRadius || 50}%;
		}
  `}
`

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
`

export const AvatarText = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
`

interface AvatarProps {
  theme: any
  width?: number | string
  height?: number | string
  bordered?: boolean
  borderRadius?: number | string
  margin?: string
}
