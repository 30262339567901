import { Option } from '@ps-ui/components'
import * as React from 'react'
import { RangeInput } from 'src/pages/ClinicalNotesv2/components/fields/RangeInput'
import { balanceOptions } from 'src/pages/ClinicalNotesv2/types'
import { TabModuleContainer } from '../../TabModuleContainer'

const BalanceElem: React.FC<{ name: string }> = ({ name }) => {
  const options: Option[] = React.useMemo(() => {
    return [
      {
        label: 'Normal',
        value: 'normal',
      },
      {
        label: 'Good +',
        value: 'good+',
      },
      {
        label: 'Good',
        value: 'good',
      },
      {
        label: 'Good -',
        value: 'good-',
      },
      {
        label: 'Fair +',
        value: 'fair+',
      },
      {
        label: 'Fair',
        value: 'fair',
      },
      {
        label: 'Fair -',
        value: 'fair-',
      },
      {
        label: 'Poor +',
        value: 'poor+',
      },
      {
        label: 'Poor',
        value: 'poor',
      },
      {
        label: 'Zero',
        value: 'zero',
      },
    ]
  }, [])

  return (
    <div className="flex flex-col mb-8">
      <div className="mt-5">
        <RangeInput name={`${name}.value`} options={options} />
      </div>
    </div>
  )
}

export const Balance: React.FC<{ name: string }> = ({ name }) => {
  return (
    <TabModuleContainer
      name={name}
      optionList={balanceOptions as unknown as string[]}
    >
      {({ currentTab }) => {
        return (
          <BalanceElem
            key={currentTab}
            name={`${name}.properties.${currentTab}`}
          />
        )
      }}
    </TabModuleContainer>
  )
}
