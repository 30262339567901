// helper functions to deal with forms using React Use Form State

export function formHasEmptyValues<Values>(
  values: Values,
  requiredFields: string[],
  optionalFieldGroup?: string[]
) {
  const filledOptionalField =
    optionalFieldGroup &&
    !!Object.keys(values).find((fieldId) => {
      return (
        !!optionalFieldGroup.includes(fieldId) &&
        //@ts-ignore
        values[fieldId] !== '' &&
        //@ts-ignore
        values[fieldId] !== undefined
      )
    })

  if (filledOptionalField && optionalFieldGroup) {
    requiredFields.push(...optionalFieldGroup)
  }

  const isValEmpty = (val: string) =>
    val === '' ||
    (Array.isArray(val) && !val.length) ||
    val === undefined ||
    val === null

  if (
    requiredFields.every(
      (requiredField) =>
        Object.keys(values).includes(requiredField) &&
        // @ts-ignore
        !isValEmpty(values[requiredField])
    )
  )
    return false
  return true
}

export function formHasErrors<Errors>(errs: Errors) {
  return !!(
    Object.values(errs) &&
    Object.values(errs).find((err) => (err !== 'undefined' ? err : null))
  )
}
