import { gql } from 'apollo-boost'
import { Query } from 'src/schema-types'

export interface QueryOtpResponse {
  login: Query['getOtp']
}

/** gets all the form entries */
export const QUERY_OTP = gql`
  query getOtp($input: OtpInput) {
    getOtp(input: $input) {
      destination
      medium
    }
  }
`
