import { gql } from 'apollo-boost'
import { AppointmentUpdateInput, Mutation } from 'src/schema-types'
import { apolloClient } from 'src/settings/apolloClient'

export interface UpdateAppointmentResponse {
  updateAppointment: Mutation['updateAppointment']
}

export const UPDATE_APPOINTMENT = gql`
  mutation updateAppointment($id: ID!, $input: AppointmentUpdateInput) {
    updateAppointment(id: $id, input: $input) {
      id
      status
    }
  }
`

export const cancelExistingAppointment = async (
  id: string,
  input: AppointmentUpdateInput
) => {
  const result = await apolloClient.query<UpdateAppointmentResponse>({
    query: UPDATE_APPOINTMENT,
    variables: { id, input },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })
  if (result.errors?.length) {
    throw new Error(result.errors[0].message)
  }

  return result.data.updateAppointment
}
